import type { NavigationListItem, GlobalLayoutProps, NavigationBarActionData, NavigationListGroupItem, NavigationListGroup } from "@octopusdeploy/design-system-components";
import { GlobalLayout, useIsLargerThanIpadResolution } from "@octopusdeploy/design-system-components";
import type { ProjectSummaryResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { AnalyticLinkLocationProvider } from "@octopusdeploy/portal-analytics";
import { links } from "@octopusdeploy/portal-routes";
import { exhaustiveCheck, isNotNull } from "@octopusdeploy/type-utils";
import React, { useEffect, useState } from "react";
import useIsMultiTenancyEnabledFeatureFlag from "~/areas/configuration/hooks/useIsMultiTenancyEnabledFeatureFlag";
import { WelcomeDialog } from "~/areas/dashboard/Onboarding/WelcomeDialog/WelcomeDialog";
import { client, repository, session } from "~/clientInstance";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import DeprecationNotificationsPoller from "~/components/Navbar/NotificationPoller/DeprecationNotificationsPoller";
import LicenceNotificationPoller from "~/components/Navbar/NotificationPoller/LicenceNotificationPoller";
import PageLayoutUpliftNotifications from "~/components/Navbar/NotificationPoller/PageLayoutUpliftNotification";
import UpdateAvailableNotificationPoller from "~/components/Navbar/NotificationPoller/UpdateAvailableNotificationPoller";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import type { SpaceContext } from "~/components/SpaceLoader/SpaceLoader";
import { isSpaceNotFound, isSpecificSpaceContext } from "~/components/SpaceLoader/SpaceLoader";
import SystemMessagesBanner from "~/components/SystemMessagesBanner/SystemMessagesBanner";
import SearchAndOpenPopover from "~/globalSearch/SearchAndOpenPopover";
import { usePageRegistration } from "~/routing/pageRegistrations/PageRegistrationContext";
import type { DoBusyTask } from "../DataBaseComponent";
import DataBaseComponent, { useDoBusyTaskEffect } from "../DataBaseComponent";
import { HelpNavigationActionButton } from "../Navbar/HelpNavigationActionButton";
import TargetTagsNotifications from "../Navbar/NotificationPoller/TargetTagsNotifications";
import NotificationsMenu from "../Navbar/NotificationsMenu";
import { SpaceSwitcherNavigationBarItem } from "../Navbar/SpaceSwitcherNavigationBarItem";
import { InPageNavVisibilityProvider } from "./InPageNavVisibilityContext";
import { UnhandledErrorPanel } from "./UnhandledErrorPanel";
import { getNavigationSideBarBottomItems, getSideNavigationAreas } from "./portalNavigationSiderBarItems";
interface PortalGlobalLayoutProps {
    spaceContext: SpaceContext;
    children: React.ReactNode;
}
export class PortalGlobalLayout extends DataBaseComponent<PortalGlobalLayoutProps> {
    render() {
        return <PortalGlobalLayoutInternal {...this.props} doBusyTask={this.doBusyTask}/>;
    }
    static displayName = "PortalGlobalLayout";
}
interface PortalGlobalLayoutInternalProps extends PortalGlobalLayoutProps {
    doBusyTask: DoBusyTask;
}
function PortalGlobalLayoutInternal({ spaceContext, children, doBusyTask }: React.PropsWithChildren<PortalGlobalLayoutInternalProps>) {
    const isLargerThanIpad = useIsLargerThanIpadResolution();
    const actions: NavigationBarActionData[] = [...getNavigationActions(isLargerThanIpad)];
    const [isInPageNavVisible, setIsInPageNavVisible] = React.useState(false);
    const areaNavItems = usePortalPageAreaNavItems(spaceContext, doBusyTask);
    const [isAreaNavPanelCollapsed, setIsAreaNavPanelCollapsed] = React.useState<boolean | undefined>(undefined);
    const isOverlayExpanded = isInPageNavVisible && !isAreaNavPanelCollapsed;
    const hasNavItems = areaNavItems.length > 0;
    React.useLayoutEffect(() => {
        setIsAreaNavPanelCollapsed(undefined);
    }, [isInPageNavVisible]);
    const onSideBarClickAway = React.useCallback(() => {
        setIsAreaNavPanelCollapsed((isAreaNavPanelCollapsed) => {
            if (isOverlayExpanded) {
                return true;
            }
            return isAreaNavPanelCollapsed;
        });
    }, [isOverlayExpanded]);
    const pageRegistration = usePageRegistration();
    const sideBarTopNavigationItems = pageRegistration ? getSideNavigationAreas(spaceContext, pageRegistration, hasNavItems, isAreaNavPanelCollapsed, setIsAreaNavPanelCollapsed) : [];
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const sideBarBottomNavigationItems = pageRegistration ? getNavigationSideBarBottomItems(session.currentUser!, spaceContext) : [];
    return (<InPageNavVisibilityProvider isVisible={isInPageNavVisible} setIsVisible={setIsInPageNavVisible}>
            <GlobalLayout areaNavItems={areaNavItems} topNavBar={{
            logo: isSpaceNotFound(spaceContext) ? undefined : <SpaceSwitcherNavigationBarItem spaceContext={spaceContext}/>,
            searchBar: isSpaceNotFound(spaceContext) ? undefined : <SearchAndOpenPopover isFullWidth={!isLargerThanIpad}/>,
            actions,
        }} sideNavBar={{
            topItems: sideBarTopNavigationItems,
            bottomItems: sideBarBottomNavigationItems,
        }} isInPageNavVisible={isInPageNavVisible} isAreaNavPanelVisible={true} isAreaNavPanelCollapsed={!isLargerThanIpad ? false : isAreaNavPanelCollapsed ?? isInPageNavVisible} onAreaNavPanelCollapseToggled={(isCollapsed: boolean) => setIsAreaNavPanelCollapsed(isCollapsed)} onSideBarClickAway={onSideBarClickAway}>
                <UpdateAvailableNotificationPoller />
                <LicenceNotificationPoller />
                <PageLayoutUpliftNotifications />
                <TargetTagsNotifications />
                <DeprecationNotificationsPoller />
                <WelcomeDialog />
                <AnalyticLinkLocationProvider location="Main Navigation Error">
                    <UnhandledErrorPanel />
                </AnalyticLinkLocationProvider>
                <SystemMessagesBanner />
                {children}
            </GlobalLayout>
        </InPageNavVisibilityProvider>);
}
function getNavigationActions(isLargerThanIpad: boolean): NavigationBarActionData[] {
    return [{ key: "notifications", content: <NotificationsMenu /> }, isLargerThanIpad ? { key: "help-menu", content: <HelpNavigationActionButton /> } : null].filter(isNotNull);
}
export function usePortalPageAreaNavItems(spaceContext: SpaceContext, doBusyTask: DoBusyTask): GlobalLayoutProps["areaNavItems"] {
    const registration = usePageRegistration();
    const isMultiTenancyEnabled = useIsMultiTenancyEnabledFeatureFlag();
    const isDynamicEnvironmentsEnabled = isFeatureToggleEnabled("DynamicEnvironmentsFeatureToggle");
    const favouriteProjects = useFavouriteProjects(doBusyTask);
    if (!registration) {
        return [];
    }
    switch (registration.verticalNavigationPageArea) {
        case "Projects":
            return getProjectsAreaNavPanelItems(spaceContext, isMultiTenancyEnabled, isDynamicEnvironmentsEnabled, favouriteProjects);
        case "Tasks":
        case "User Profile":
        case "Dev Tools":
        case "Configuration":
        case "Insights":
            return [];
        default:
            exhaustiveCheck(registration.verticalNavigationPageArea, "Not all page areas have been handled");
    }
}
function useFavouriteProjects(doBusyTask: DoBusyTask): ProjectSummaryResource[] | "not-loaded" {
    const [favouriteProjects, setFavouriteProjects] = useState<ProjectSummaryResource[] | "not-loaded">("not-loaded");
    const refresh = useDoBusyTaskEffect(doBusyTask, async () => {
        const favouriteProjectsResponse = await repository.Users.getProjectFavourites();
        setFavouriteProjects(favouriteProjectsResponse.Projects);
    }, []);
    useEffect(() => {
        return client.subscribe((event) => {
            if (event.type === "UserFavouriteProjectCreated" || event.type === "UserFavouriteProjectDeleted") {
                refresh();
            }
        });
    }, [refresh]);
    return favouriteProjects;
}
function getProjectsAreaNavPanelItems(spaceContext: SpaceContext, isMultiTenancyEnabled: boolean, isDynamicEnvironmentsEnabled: boolean, favouriteProjects: ProjectSummaryResource[] | "not-loaded"): ReadonlyArray<NavigationListItem> {
    if (isSpecificSpaceContext(spaceContext)) {
        const gitHubConnectionsEnabled = isFeatureToggleEnabled("GitHubConnectionsFeatureToggle");
        const favouriteProjectItems: NavigationListGroupItem[] = favouriteProjects === "not-loaded"
            ? [{ placeholderLabel: "..." }]
            : favouriteProjects.length === 0
                ? [{ placeholderLabel: "No favourite projects" }]
                : favouriteProjects.map((p) => ({
                    label: p.Name,
                    href: links.projectRootRedirect.generateUrl({ spaceId: spaceContext.Id, projectSlug: p.Slug }),
                }));
        return ([
            { label: "All Projects", href: links.dashboardPage.generateUrl({ spaceId: spaceContext.Id }) },
            "spacer",
            ...groupWithTrailingDivider("My Favourites", favouriteProjectItems),
            ...groupWithTrailingDivider(undefined, [
                isAllowed({ permission: Permission.TenantView, wildcard: true }, { spaceId: spaceContext.Id }) ? { label: "Tenants", href: links.tenantsPage.generateUrl({ spaceId: spaceContext.Id }) } : null,
                isMultiTenancyEnabled && isAllowed({ permission: Permission.VariableView, wildcard: true }, { spaceId: spaceContext.Id }) ? { label: "Tenant Tag Sets", href: links.tagSetsPage.generateUrl({ spaceId: spaceContext.Id }) } : null,
                isAllowed({ permission: [Permission.VariableView, Permission.LibraryVariableSetView], wildcard: true }, { spaceId: spaceContext.Id })
                    ? {
                        label: "Variable Sets",
                        href: links.variableSetsPage.generateUrl({ spaceId: spaceContext.Id }),
                    }
                    : null,
            ]),
            ...groupWithTrailingDivider("Infrastructure", [
                isAllowed({ permission: [Permission.EnvironmentView, Permission.MachineView, Permission.WorkerView], wildcard: true }, { spaceId: spaceContext.Id })
                    ? {
                        label: "Overview",
                        href: links.infrastructureOverviewPage.generateUrl({ spaceId: spaceContext.Id }),
                    }
                    : null,
                isAllowed({ permission: [Permission.MachineView], wildcard: true }, { spaceId: spaceContext.Id })
                    ? {
                        label: "Deployment Targets",
                        href: links.deploymentTargetsPage.generateUrl({ spaceId: spaceContext.Id }),
                    }
                    : null,
                isDynamicEnvironmentsEnabled && isAllowed({ permission: [Permission.EnvironmentView, Permission.MachineView] }, { spaceId: spaceContext.Id })
                    ? {
                        label: "Dynamic Environments",
                        href: links.dynamicEnvironmentsPage.generateUrl({ spaceId: spaceContext.Id }),
                    }
                    : null,
                isAllowed({ permission: [Permission.EnvironmentView, Permission.MachineView] }, { spaceId: spaceContext.Id })
                    ? {
                        label: "Environments",
                        href: links.infrastructureEnvironmentsPage.generateUrl({ spaceId: spaceContext.Id }),
                    }
                    : null,
                isAllowed({ permission: Permission.MachinePolicyView, wildcard: true }, { spaceId: spaceContext.Id })
                    ? {
                        label: "Machine Policies",
                        href: links.machinePoliciesPage.generateUrl({ spaceId: spaceContext.Id }),
                    }
                    : null,
                isAllowed({ permission: Permission.ProxyView, wildcard: true }, { spaceId: spaceContext.Id }) ? { label: "Machine Proxies", href: links.proxiesPage.generateUrl({ spaceId: spaceContext.Id }) } : null,
                isAllowed({ permission: [Permission.WorkerView], wildcard: true }, { spaceId: spaceContext.Id }) ? { label: "Workers", href: links.workerMachinesPage.generateUrl({ spaceId: spaceContext.Id }) } : null,
                isAllowed({ permission: [Permission.EnvironmentView] }, { spaceId: spaceContext.Id }) ? { label: "Worker Pools", href: links.workerPoolsPage.generateUrl({ spaceId: spaceContext.Id }) } : null,
            ]),
            groupWithNullableItems("Manage", [
                isAllowed({ permission: Permission.AccountView, wildcard: true }, { spaceId: spaceContext.Id })
                    ? {
                        label: "Accounts",
                        href: links.infrastructureAccountsPage.generateUrl({ spaceId: spaceContext.Id }),
                    }
                    : null,
                isAllowed({ permission: Permission.FeedView }, { spaceId: spaceContext.Id }) ? { label: "Build Information", href: links.buildInformationOverviewPage.generateUrl({ spaceId: spaceContext.Id }) } : null,
                isAllowed({ permission: Permission.CertificateView, wildcard: true }, { spaceId: spaceContext.Id }) ? { label: "Certificates", href: links.certificatesPage.generateUrl({ spaceId: spaceContext.Id }) } : null,
                isAllowed({ permission: Permission.FeedView }, { spaceId: spaceContext.Id }) ? { label: "External Feeds", href: links.feedsPage.generateUrl({ spaceId: spaceContext.Id }) } : null,
                isAllowed({ permission: [Permission.GitCredentialView], wildcard: true }, { spaceId: spaceContext.Id })
                    ? {
                        label: "Git Credentials",
                        href: links.gitCredentialsPage.generateUrl({ spaceId: spaceContext.Id }),
                    }
                    : null,
                gitHubConnectionsEnabled && isAllowed({ permission: [Permission.GitCredentialView], wildcard: true }, { spaceId: spaceContext.Id })
                    ? {
                        label: "GitHub Connections",
                        href: links.gitConnectionsPage.generateUrl({ spaceId: spaceContext.Id }),
                    }
                    : null,
                isAllowed({ permission: Permission.LifecycleView, wildcard: true }, { spaceId: spaceContext.Id }) ? { label: "Lifecycles", href: links.lifecyclesPage.generateUrl({ spaceId: spaceContext.Id }) } : null,
                isAllowed({ permission: Permission.FeedView }, { spaceId: spaceContext.Id }) ? { label: "Packages", href: links.builtInRepositoryPage.generateUrl({ spaceId: spaceContext.Id }) } : null,
                isAllowed({ permission: Permission.VariableView, wildcard: true }, { spaceId: spaceContext.Id }) ? { label: "Script Modules", href: links.scriptModulesPage.generateUrl({ spaceId: spaceContext.Id }) } : null,
                isAllowed({ permission: Permission.ActionTemplateView }, { spaceId: spaceContext.Id }) ? { label: "Step Templates", href: links.stepTemplatesPage.generateUrl({ spaceId: spaceContext.Id }) } : null,
            ]),
        ] as const).filter(isNotNull);
    }
    return [];
}
function groupWithTrailingDivider(heading: string | undefined, groupItems: (NavigationListGroupItem | null)[]): NavigationListItem[] {
    const group = groupWithNullableItems(heading, groupItems);
    if (group === undefined) {
        return [];
    }
    return [group, "divider"];
}
function groupWithNullableItems(heading: string | undefined, groupItems: (NavigationListGroupItem | null)[]): NavigationListGroup | undefined {
    const nonNullGroupItems = groupItems.filter(isNotNull);
    if (nonNullGroupItems.length === 0)
        return undefined;
    return {
        heading,
        items: nonNullGroupItems,
    };
}
