import type { TenantsFilterQueryParameters } from "@octopusdeploy/octopus-server-client";
import { booleanQueryParam, numberQueryParam, stringQueryParam, useQueryStringParams } from "@octopusdeploy/portal-routes";
import { useMemo } from "react";
export type TenantsFilterState = TenantsFilterQueryParameters & {
    pageNumber: number;
};
export function useTenantsFilterState(): [
    TenantsFilterState,
    (filter: TenantsFilterState) => void
] {
    const searchParameter = stringQueryParam("search");
    const excludedSearchParameter = stringQueryParam("excludedSearch");
    const projectParameter = stringQueryParam("project");
    const excludedProjectParameter = stringQueryParam("excludedProject");
    const environmentParameter = stringQueryParam("environment");
    const excludedEnvironmentParameter = stringQueryParam("excludedEnvironment");
    const tagsParameter = stringQueryParam("tags");
    const excludedTagsParameter = stringQueryParam("excludedTags");
    const pageNumberParameter = numberQueryParam("pageNumber");
    const includeMissingVariables = booleanQueryParam("includeMissingVariablesStatus");
    const [filter, setFilter] = useQueryStringParams([
        searchParameter,
        excludedSearchParameter,
        projectParameter,
        excludedProjectParameter,
        environmentParameter,
        excludedEnvironmentParameter,
        tagsParameter,
        excludedTagsParameter,
        pageNumberParameter,
        includeMissingVariables,
    ]);
    function setTenantsFilterState(filter: TenantsFilterState) {
        setFilter({
            search: filter.filterByName,
            excludedSearch: filter.filterByExcludedName,
            project: filter.filterByProject,
            excludedProject: filter.filterByExcludedProject,
            environment: filter.filterByEnvironment,
            excludedEnvironment: filter.filterByExcludedEnvironment,
            tags: filter.filterByTags.length > 0 ? filter.filterByTags.join(",") : undefined,
            excludedTags: filter.filterByExcludedTags.length > 0 ? filter.filterByExcludedTags.join(",") : undefined,
            includeMissingVariablesStatus: filter.includeMissingVariablesStatus,
            pageNumber: filter.pageNumber,
        });
    }
    const tenantsFilterState = useMemo((): TenantsFilterState => ({
        filterByName: filter.search,
        filterByExcludedName: filter.excludedSearch,
        filterByProject: filter.project,
        filterByExcludedProject: filter.excludedProject,
        filterByEnvironment: filter.environment,
        filterByExcludedEnvironment: filter.excludedEnvironment,
        filterByTags: filter.tags ? filter.tags.split(",") : [],
        filterByExcludedTags: filter.excludedTags ? filter.excludedTags.split(",") : [],
        includeMissingVariablesStatus: filter.includeMissingVariablesStatus ?? false,
        pageNumber: filter.pageNumber ?? 1,
    }), [filter.search, filter.excludedSearch, filter.project, filter.excludedProject, filter.environment, filter.excludedEnvironment, filter.tags, filter.excludedTags, filter.includeMissingVariablesStatus, filter.pageNumber]);
    return [tenantsFilterState, setTenantsFilterState];
}
