/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import cn from "classnames";
import * as React from "react";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import type { DialogProps } from "~/components/Dialog/Dialog";
import { DialogLayout } from "~/components/DialogLayout/DialogLayout";
import Dialog from "./Dialog";
import styles from "./style.module.less";
interface ConfirmationDialogLayoutProps {
    open: boolean;
    title: string;
    continueButtonLabel?: string;
    continueButtonBusyLabel?: string;
    continueButtonType?: ActionButtonType;
    onContinueClick(): any;
    onClose(): any;
}
export class ConfirmationDialogLayout extends DataBaseComponent<ConfirmationDialogLayoutProps, DataBaseComponentState> {
    constructor(props: ConfirmationDialogLayoutProps) {
        super(props);
        this.state = {};
    }
    onContinueClick() {
        return this.doBusyTask(async () => {
            const result = await this.props.onContinueClick();
            if (result) {
                this.props.onClose();
            }
        });
    }
    render() {
        const cont = (<ActionButton key="Continue" label={this.props.continueButtonLabel || "Continue"} type={this.props.continueButtonType || ActionButtonType.Save} busyLabel={this.props.continueButtonBusyLabel} disabled={this.state.busy} onClick={() => this.onContinueClick()}/>);
        const cancel = <ActionButton key="Cancel" label="Cancel" disabled={this.state.busy} onClick={() => this.props.onClose()}/>;
        return (<DialogLayout actions={[cancel, cont]} title={this.props.title} titleIcon={<em className={cn("fa-solid fa-triangle-exclamation", styles.warningIcon)}/>} headerClassName={styles.confirmationDialogHeader} busy={this.state.busy} closeDialog={this.props.onClose} errors={this.errors}>
                {this.props.open && this.props.children}
            </DialogLayout>);
    }
    static displayName = "ConfirmationDialogLayout";
}
export type ConfirmationDialogProps = React.PropsWithChildren<ConfirmationDialogLayoutProps & Pick<DialogProps, "wide">>;
//We need to ensure our dialog layout lives below the `Dialog` in the component hierarchy
//to ensure busy task errors bubble up to the correct error context as part of `DoBusyTask` usages.
export default ({ wide, children, ...rest }: ConfirmationDialogProps) => (<Dialog open={rest.open || false} wide={wide}>
        <ConfirmationDialogLayout {...rest}>{children}</ConfirmationDialogLayout>
    </Dialog>);
