/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { ActionButton, ActionButtonType, Tooltip, Callout } from "@octopusdeploy/design-system-components";
import type { ResourcesById, PackageNote, RunbookSnapshotResource, EnvironmentResource, EventResource, ArtifactResource, RunbookRunResource, ResourceCollection, ProjectResource, EventCategoryResource, RunbookRunTemplateResource, RunbookResource, } from "@octopusdeploy/octopus-server-client";
import { PackageReferenceNamesMatch, ProcessType, Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as _ from "lodash";
import { isEqual, compact } from "lodash";
import * as React from "react";
import { ProjectPageLayout } from "~/areas/projects/components/ProjectPageLayout";
import { GitResourcesList } from "~/areas/projects/components/Releases/GitResourcesList";
import type { GitReferenceModel } from "~/areas/projects/components/Releases/gitResourceModel";
import { PublishSnapshotDialogLayout } from "~/areas/projects/components/Runbooks/PublishSnapshotDialogLayout/PublishSnapshotDialogLayout";
import { getNotesForPackages, splitPackagesIntoBoundOrUnboundFeeds } from "~/areas/projects/components/releaseAndRunbookHelpers";
import { useProjectContext } from "~/areas/projects/context";
import ArtifactLink from "~/areas/tasks/components/Task/Artifacts/ArtifactLink";
import { repository, session } from "~/clientInstance";
import { RunbookSnapshotPublishedChip } from "~/components/Chips";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import EventListing from "~/components/EventListing/EventListing";
import Markdown from "~/components/Markdown/index";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { PageAction } from "~/components/PageActions/PageActions";
import { PagingList } from "~/components/PagingList/PagingList";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import Section from "~/components/Section";
import TimeFromNowLabel from "~/components/TimeLabels/TimeFromNowLabel";
import { FormSectionHeading, Note } from "~/components/form";
import DateFormatter from "~/utils/DateFormatter/DateFormatter";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import StringHelper from "~/utils/StringHelper";
import PackagesList from "../../Releases/PackagesList/PackagesList";
import UpdateVariables from "../../Releases/UpdateVariables/UpdateVariables";
import { VariableSnapshot } from "../../Releases/VariableSnapshot/VariableSnapshot";
import type { PackageModel } from "../../Releases/packageModel";
import { buildPartialReleaseNotes } from "../../Releases/releaseNoteHelper";
import { publishingExplainedElement } from "../PublishButton";
import { useRunbookContext } from "../RunbookContext";
import { isRunbookConsumerTryingToRunAnUnpublishedSnapshot } from "../isRunbookConsumerTryingToRunAnUnpublishedSnapshot";
import styles from "./RunbookSnapshotInfoPage.module.less";
interface RunbookSnapshotInfoPageState extends DataBaseComponentState {
    runbookSnapshot: RunbookSnapshotResource;
    showUnblockRunbookSnapshotDialog: boolean;
    environmentsById: ResourcesById<EnvironmentResource>;
    packages: PackageModel[];
    gitReferences: GitReferenceModel[];
    events: ResourceCollection<EventResource>;
    eventCategories: EventCategoryResource[];
    artifacts: ResourceCollection<ArtifactResource>;
    runbookRuns: RunbookRunResource[];
    showFullNotes: boolean;
    isInitialLoad: boolean;
    currentPageIndex?: number;
    currentSkip: number;
    variableSnapshotRefreshKey: string;
    deleted: boolean;
    runbookRunTemplate: RunbookRunTemplateResource;
}
class ArtifactsList extends PagingList<ArtifactResource> {
}
interface RunbookSnapshotInfoPageInternalProps extends RunbookSnapshotInfoPageProps {
    runbook: RunbookResource;
    project: ProjectResource;
    publishShapshot: (snapshot: RunbookSnapshotResource) => Promise<boolean>;
}
class RunbookSnapshotInfoPageInternal extends DataBaseComponent<RunbookSnapshotInfoPageInternalProps, RunbookSnapshotInfoPageState> {
    private packageResolveMessage: string = "Package will be resolved during runbook run";
    constructor(props: RunbookSnapshotInfoPageInternalProps) {
        super(props);
        this.state = {
            runbookSnapshot: null!,
            showUnblockRunbookSnapshotDialog: false,
            environmentsById: null!,
            packages: [],
            gitReferences: [],
            events: null!,
            eventCategories: null!,
            artifacts: null!,
            runbookRuns: [],
            showFullNotes: false,
            isInitialLoad: true,
            currentPageIndex: 0,
            currentSkip: 0,
            variableSnapshotRefreshKey: DateFormatter.timestamp(),
            deleted: false,
            runbookRunTemplate: null!,
        };
    }
    async componentDidMount() {
        await this.reload();
    }
    async componentDidUpdate(prevProps: RunbookSnapshotInfoPageInternalProps) {
        const nextRunbook = this.props.runbook;
        const currentRunbook = prevProps.runbook;
        if (!isEqual(currentRunbook, nextRunbook)) {
            await this.reload();
        }
    }
    reload = async () => {
        const project = this.props.project;
        return this.doBusyTask(async () => {
            const runbookSnapshot = await repository.RunbookSnapshots.get(this.props.runbookSnapshotId);
            const environmentsById = isAllowed({ permission: Permission.EnvironmentView, wildcard: true }) ? await repository.Environments.allById() : null!;
            await this.init(project, runbookSnapshot);
            this.setState(await this.refreshActiveComponents(project, runbookSnapshot, environmentsById));
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    };
    publishSnapshot = () => this.state.runbookSnapshot && this.props.publishShapshot(this.state.runbookSnapshot);
    render() {
        const project = this.props.project;
        const runbook = this.props.runbook;
        if (this.state.deleted) {
            return <InternalRedirect to={links.projectRunbookSnapshotsPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id })} push={true}/>;
        }
        const overflowActions: MenuItem[] = [
            OverflowMenuItems.navItem("Edit", links.projectRunbookSnapshotEditPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id, runbookSnapshotId: this.props.runbookSnapshotId }), {
                permission: Permission.RunbookEdit,
                project: project.Id,
                wildcard: true,
            }),
        ];
        if (this.state.runbookSnapshot) {
            overflowActions.push(OverflowMenuItems.dialogItem("Update Variables", <UpdateVariables processType={ProcessType.Runbook} onUpdateVariablesClicked={async () => {
                    const runbookSnapshot = await repository.RunbookSnapshots.snapshotVariables(this.state.runbookSnapshot);
                    this.setState({ runbookSnapshot, variableSnapshotRefreshKey: DateFormatter.timestamp() });
                }}/>, {
                permission: Permission.RunbookEdit,
                project: project.Id,
                wildcard: true,
            }));
            overflowActions.push(OverflowMenuItems.deleteItemDefault("runbook snapshot", this.handleDeleteConfirm, {
                permission: Permission.RunbookEdit,
                project: project.Id,
                wildcard: true,
            }, "The runbook snapshot and any of its runs will be permanently deleted and they will disappear from all dashboards."));
            overflowActions.push(OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ regardingAny: [this.state.runbookSnapshot.Id] }), {
                permission: Permission.EventView,
                wildcard: true,
            }));
        }
        const pageActions: PageAction[] = [];
        if (this.state.runbookSnapshot) {
            if (!runbook.PublishedRunbookSnapshotId || (runbook.PublishedRunbookSnapshotId && runbook.PublishedRunbookSnapshotId !== this.state.runbookSnapshot.Id)) {
                pageActions.push({
                    type: "custom",
                    key: "Publish",
                    hasPermissions: isAllowed({
                        permission: Permission.RunbookEdit,
                        project: project.Id,
                        wildcard: true,
                    }),
                    content: (<Tooltip content={publishingExplainedElement}>
                            <OpenDialogButton label="Publish..." type={ActionButtonType.Ternary} renderDialog={({ open, closeDialog }) => (<Dialog open={open} onRequestClose={closeDialog}>
                                        <PublishSnapshotDialogLayout close={closeDialog} onPublishSnapshotDialogClicked={this.publishSnapshot}/>
                                    </Dialog>)}></OpenDialogButton>
                        </Tooltip>),
                });
            }
        }
        if (!isRunbookConsumerTryingToRunAnUnpublishedSnapshot(project, runbook, this.state.runbookSnapshot && this.state.runbookSnapshot.Id)) {
            pageActions.push({
                type: "navigate",
                buttonType: "secondary",
                label: "Run...",
                hasPermissions: isAllowed({ permission: Permission.RunbookRunCreate, project: project.Id, wildcard: true }),
                path: links.createRunbookRunForSnapshotPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id, runbookSnapshotId: this.props.runbookSnapshotId }),
            });
        }
        return (<ProjectPageLayout title={this.runbookSnapshotTitle()} titleChip={this.runbookSnapshotTitleChip()} breadcrumbTitle={`${runbook && runbook.Name} snapshots`} breadcrumbPath={links.projectRunbookSnapshotsPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id })} breadcrumbsItems={runbook && [
                { label: "Runbooks", pageUrl: links.projectRunbooksPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }) },
                { label: runbook.Name, pageUrl: links.projectRunbookOverviewPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id }) },
                { label: "Snapshots", pageUrl: links.projectRunbookSnapshotsPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id }) },
            ]} busy={this.state.busy} errors={this.errors} pageActions={pageActions} overflowActions={this.state.runbookSnapshot ? overflowActions : undefined}>
                <div className={styles.runbookSnapshotDetailsLayout}>
                    {this.state.runbookSnapshot && (<div className={styles.runbookSnapshotDetailsLayoutContent}>
                            {this.state.runbookSnapshot && this.state.runbookSnapshot.Notes && this.getRunbookSnapshotNoteSection()}
                            <FormSectionHeading key="packages" title="Packages"/>
                            <Section key="sectionPackages" sectionHeader="">
                                <PermissionCheck permission={Permission.FeedView} alternate={<Callout type={"information"} title={"Permission required"}>
                                            The {Permission.FeedView} permission is required to view packages
                                        </Callout>}>
                                    <PermissionCheck permission={Permission.RunbookView} project={this.state.runbookSnapshot.ProjectId} wildcard={true} alternate={<Callout type={"information"} title={"Permission required"}>
                                                The {Permission.RunbookView} permission is required to view packages
                                            </Callout>}>
                                        <div className={styles.runbookSnapshotPackagesLayout}>
                                            <PackagesList packages={this.state.packages} buildInformation={null}/>
                                        </div>
                                    </PermissionCheck>
                                </PermissionCheck>
                            </Section>
                            <FormSectionHeading key="gitReferences" title="Git References"/>
                            <Section key="sectionGitReferences" sectionHeader="">
                                <PermissionCheck permission={Permission.FeedView} alternate={<Callout type={"information"} title={"Permission required"}>
                                            The {Permission.FeedView} permission is required to view git references
                                        </Callout>}>
                                    <PermissionCheck permission={Permission.RunbookView} project={this.state.runbookSnapshot.ProjectId} wildcard={true} alternate={<Callout type={"information"} title={"Permission required"}>
                                                The {Permission.RunbookView} permission is required to view git references
                                            </Callout>}>
                                        <div className={styles.runbookSnapshotPackagesLayout}>
                                            <GitResourcesList gitReferences={this.state.gitReferences}/>
                                        </div>
                                    </PermissionCheck>
                                </PermissionCheck>
                            </Section>
                            {/*Include the VariableSnapshot outside of the Section so that the table can extend to the edge of the paper element*/}
                            {this.state.runbookSnapshot && (<PermissionCheck permission={Permission.VariableView} project={this.state.runbookSnapshot.ProjectId} wildcard={true}>
                                    <VariableSnapshot spaceId={this.state.runbookSnapshot.SpaceId} projectId={this.state.runbookSnapshot.ProjectId} snapshot={this.state.runbookSnapshot} doBusyTask={this.doBusyTask} updateVariablesRefreshKey={this.state.variableSnapshotRefreshKey} onUpdate={this.reload}/>
                                </PermissionCheck>)}
                            {this.state.artifacts && (<>
                                    <FormSectionHeading key="artifacts" title="Artifacts"/>
                                    <div className={styles.runbookSnapshotArtifactsLayout}>
                                        <ArtifactsList initialData={this.state.artifacts} onRow={(artifact: ArtifactResource) => (<>
                                                    <ArtifactLink artifact={artifact} key="link"/>
                                                    <div key="time" className={styles.time}>
                                                        <TimeFromNowLabel time={artifact.Created}/>
                                                    </div>
                                                </>)} showPagingInNumberedStyle={true} currentPageIndex={this.state.currentPageIndex} onPageSelected={this.handleArtifactsPageSelected} empty={<Note>
                                                    No artifacts have been added. Learn more about <ExternalLink href="Artifacts">collecting artifacts</ExternalLink>.
                                                </Note>}/>
                                    </div>
                                </>)}
                            <FormSectionHeading key="runbookRunHistory" title="Run history"/>
                            <PermissionCheck permission={Permission.RunbookRunView} project={this.state.runbookSnapshot.ProjectId} wildcard={true} alternate={<Callout type={"information"} title={"Permission required"}>
                                        The {Permission.RunbookRunView} permission is required to view the runbook run history
                                    </Callout>}>
                                <EventListing data={this.state.events} regarding={[this.state.runbookSnapshot.Id]} eventCategories={this.state.eventCategories}/>
                            </PermissionCheck>
                        </div>)}
                </div>
            </ProjectPageLayout>);
    }
    private async init(project: ProjectResource, runbookSnapshot: RunbookSnapshotResource) {
        const runbookProcess = isAllowed({ permission: Permission.RunbookView, project: project.Id, wildcard: true }) ? await repository.RunbookProcess.get(runbookSnapshot.FrozenRunbookProcessId) : null;
        const template = runbookProcess && (await repository.RunbookProcess.getRunbookSnapshotTemplate(runbookProcess, runbookSnapshot.Id));
        const allPackages = template
            ? compact(template.Packages.map((packageTemplate) => {
                const selectionForStep = runbookSnapshot.SelectedPackages.find((selected) => selected.ActionName === packageTemplate.ActionName && PackageReferenceNamesMatch(selected!.PackageReferenceName!, packageTemplate.PackageReferenceName!));
                if (selectionForStep) {
                    return {
                        ActionName: packageTemplate.ActionName,
                        PackageId: packageTemplate.PackageId,
                        PackageReferenceName: packageTemplate.PackageReferenceName!,
                        ProjectName: packageTemplate.ProjectName,
                        FeedName: packageTemplate.FeedName,
                        FeedId: packageTemplate.FeedId,
                        Version: (selectionForStep as any).Version,
                        Notes: {
                            Notes: null,
                            Succeeded: true,
                            FailureReason: null,
                            Published: null,
                        },
                    };
                }
            }))
            : [];
        const allGitReferences = template
            ? compact(template.GitResources?.map((gitResource) => {
                const selectionForStep = runbookSnapshot.SelectedGitResources.find((selected) => selected.ActionName === gitResource.ActionName);
                if (selectionForStep) {
                    return {
                        ActionName: gitResource.ActionName,
                        RepositoryUri: gitResource.RepositoryUri,
                        FilePathFilters: gitResource.FilePathFilters,
                        GitResource: selectionForStep.GitReferenceResource ?? gitResource.GitResourceSelectedLastRelease,
                    };
                }
            }))
            : [];
        this.setState(() => ({ packages: allPackages, gitReferences: allGitReferences }));
        if (isAllowed({ permission: Permission.FeedView, project: project.Id, wildcard: true })) {
            // `loadPackages` is not awaited to prevent the page from freezing while waiting for package notes to load.
            this.loadPackages(allPackages);
        }
    }
    private async refreshActiveComponents(project: ProjectResource, runbookSnapshot: RunbookSnapshotResource, environmentsById: ResourcesById<EnvironmentResource>) {
        const useOptimization = session.featureToggles?.includes("PermissionCheckOptimizationFeatureToggle");
        const [artifacts, events, eventCategories, runbookRunsCollection, runbookRunTemplate] = await Promise.all([
            this.loadArtifactsPromise(runbookSnapshot, this.state.currentSkip)!,
            isAllowed({ permission: Permission.EventView, project: project.Id, wildcard: true })
                ? useOptimization
                    ? repository.Events.getUnpaginatedEvents({ regarding: [runbookSnapshot.Id] })
                    : repository.Events.list({ regarding: [runbookSnapshot.Id] })
                : null!,
            repository.Events.categories({}),
            isAllowed({ permission: Permission.RunbookRunView, project: project.Id, wildcard: true }) ? repository.RunbookSnapshots.getRunbookRuns(runbookSnapshot, { take: 1000 }) : null!,
            repository.RunbookSnapshots.getRunbookRunTemplate(runbookSnapshot),
        ]);
        const runbookRuns = runbookRunsCollection && runbookRunsCollection.Items;
        const resultForState: RunbookSnapshotInfoPageState = {
            ...this.state,
            runbookSnapshot,
            environmentsById,
            artifacts,
            events,
            eventCategories,
            runbookRuns,
            runbookRunTemplate,
            isInitialLoad: false,
        };
        return resultForState;
    }
    private async loadPackages(allPackages: PackageModel[]) {
        const boundUnbound = splitPackagesIntoBoundOrUnboundFeeds(allPackages);
        // Bound packages all get a standard release notes string
        this.setState((existingState) => {
            boundUnbound.bound.forEach((bound) => (bound.Notes.Notes = this.packageResolveMessage));
            return {
                packages: [..._.differenceWith(existingState.packages, boundUnbound.bound, this.packageNoteEquals), ...boundUnbound.bound],
            };
        });
        try {
            (await getNotesForPackages(boundUnbound.unBound)).forEach((notes) => {
                this.setState((existingState) => {
                    // for every package that was returned, update the existing package
                    // with the returned notes.
                    const updated = existingState.packages.map((existing) => _.assign(existing, this.findMatchingNotesPackage(existing, notes.Packages)));
                    return {
                        packages: updated,
                    };
                });
            });
        }
        catch (err) {
            this.setState((existingState) => {
                // for every package that was requested, set the state to error.
                // Possible bug: The Success property looks like it should be referring to PackageNoteResult.Succeeded.
                const updated = existingState.packages.map((existing) => _.assign(existing, this.findMatchingNotesPackage(existing, boundUnbound.unBound, { Notes: { Success: false, FailureMessage: err.ErrorMessage } })));
                return {
                    packages: updated,
                };
            });
        }
    }
    /**
     * Finding runbookSnapshot notes in a bulk fashion from the server means:
     * 1. Requesting the package details (in a request with a bunch of other packages)
     * 2. Assigning the returned details back to the matching packages from the state
     * 3. Optionally setting the some additional field, typically when a batch request failed and all packages need to show an error
     * This function will attempt to find a matching package from the list of returned packages, and if so assign the values from 3
     * to it, and then return it. Otherwise it will return an empty object. The returned object is expected to be assigned to
     * the package in the state to result in an updated package object that can be displayed to the user.
     * @param {PackageNote} original The original package details to match against the package returned by the server
     * @param {PackageNote[]} packages The list of packages returned by the server
     * @param assign An object that is assigned to the matching package, if one was found. It is like an "overlay" on matching packages.
     * @returns {(PackageNote | undefined) | {}} An empty object if no match was found, and the returned package
     * with the assign object assigned to it.
     */
    private findMatchingNotesPackage(original: PackageNote, packages: PackageNote[], assign: any = null) {
        const packageWithNotes = packages.find((pkgWithNotes) => this.packageNoteEquals(pkgWithNotes, original));
        if (packageWithNotes) {
            if (assign) {
                _.assign(packageWithNotes, assign);
            }
        }
        return packageWithNotes || {};
    }
    private packageNoteEquals(a: PackageNote, b: PackageNote) {
        return a.PackageId === b.PackageId && a.Version === b.Version && a.FeedId === b.FeedId;
    }
    private runbookSnapshotTitle(): string {
        return this.state.runbookSnapshot?.Name ?? StringHelper.ellipsis;
    }
    private runbookSnapshotTitleChip(): React.ReactElement | undefined {
        return this.state.runbookSnapshot && this.state.runbookSnapshot.Id === this.props.runbook.PublishedRunbookSnapshotId ? <RunbookSnapshotPublishedChip noMargin={true}/> : undefined;
    }
    private buildNotes() {
        if (this.state.showFullNotes) {
            return <Markdown markup={this.state.runbookSnapshot.Notes}/>;
        }
        const [runbookSnapshotNotes, isTruncated] = buildPartialReleaseNotes(this.state.runbookSnapshot.Notes, 10);
        return (<div>
                <Markdown markup={runbookSnapshotNotes}/>
                {isTruncated && <ActionButton type={ActionButtonType.Ternary} onClick={() => this.setState({ showFullNotes: true })} label="Show more"/>}
            </div>);
    }
    private getRunbookSnapshotNoteSection() {
        return [
            <FormSectionHeading key="runbookSnapshotNoteHeading" title="Notes"/>,
            <Section key="runbookSnapshotSection" sectionHeader="">
                <div className={styles.runbookSnapshotNoteLayout}>{this.buildNotes()}</div>
            </Section>,
        ];
    }
    private loadArtifactsPromise = (runbookSnapshot: RunbookSnapshotResource, skip: number) => isAllowed({ permission: Permission.ArtifactView, wildcard: true })
        ? repository.Artifacts.list({
            regarding: runbookSnapshot.Id,
            skip,
            take: 10,
            order: "asc",
        })
        : null;
    private handleArtifactsPageSelected = async (skip: number, p: number) => {
        this.setState({ currentPageIndex: p, currentSkip: skip });
        this.setState({ artifacts: await this.loadArtifactsPromise(this.state.runbookSnapshot, skip)! });
    };
    private handleDeleteConfirm = async (): Promise<boolean> => {
        if (this.state.runbookSnapshot) {
            await repository.RunbookSnapshots.del(this.state.runbookSnapshot);
            this.setState({ deleted: true });
            return true;
        }
        else {
            return false;
        }
    };
    static displayName = "RunbookSnapshotInfoPageInternal";
}
interface RunbookSnapshotInfoPageProps {
    runbookSnapshotId: string;
}
export function RunbookSnapshotInfoPage({ runbookSnapshotId }: RunbookSnapshotInfoPageProps) {
    const runbookContext = useRunbookContext();
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    const runbook = runbookContext.state.runbook;
    if (!project || !runbook) {
        return <ProjectPageLayout busy={true}/>;
    }
    return <RunbookSnapshotInfoPageInternal project={project} runbook={runbook} runbookSnapshotId={runbookSnapshotId} publishShapshot={runbookContext.actions.publishSnapshot}/>;
}
