/* eslint-disable @typescript-eslint/no-explicit-any */
import type { GetReleaseDeploymentBff, EnvironmentResource, PhaseResource, TaskResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import LoadMoreWrapper from "~/components/LoadMoreWrapper/LoadMoreWrapper";
import { DataTableBody, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import type { DataBaseComponentState } from "../../../../../components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "../../../../../components/DataBaseComponent/DataBaseComponent";
import type { LifecycleStatus } from "../../../../../utils/MapProgressionToStatus/MapProgressionToStatus";
import DeploymentChildRow from "./DeploymentChildRow";
import DeploymentRow from "./DeploymentRow";
import styles from "./style.module.less";
interface PhaseDeploymentsProps {
    title?: string;
    phase: PhaseResource;
    lifecycleStatus: LifecycleStatus;
    deployments: GetReleaseDeploymentBff[];
    deploymentTasks: Array<TaskResource<{
        DeploymentId: string;
    }>>;
    environmentsById: {
        [id: string]: EnvironmentResource;
    };
    actionButton?: JSX.Element | null;
    loadMoreDeployments: (phaseName: string, environmentId: string, takePerEnvironment: number) => Promise<number>;
    [others: string]: any;
}
interface PhaseDeploymentsState extends DataBaseComponentState {
    showChildRows: boolean;
}
export default class PhaseDeployments extends DataBaseComponent<PhaseDeploymentsProps, PhaseDeploymentsState> {
    constructor(props: PhaseDeploymentsProps) {
        super(props);
        this.state = {
            showChildRows: false,
        };
    }
    handleToggleChildRows = () => {
        this.setState({
            showChildRows: !this.state.showChildRows,
        });
    };
    render() {
        return <DataTableBody>{this.props.deploymentTasks && this.renderDeploymentRows()}</DataTableBody>;
    }
    private onLoadMore = async (takePerEnvironment: number) => {
        const environmentId = this.props.deployments[0].EnvironmentId;
        const phaseName = this.props.phase.Name;
        let numberOfDeploymentsLoaded = 0;
        await this.doBusyTask(async () => {
            numberOfDeploymentsLoaded = await this.props.loadMoreDeployments(phaseName, environmentId, takePerEnvironment);
        });
        return numberOfDeploymentsLoaded;
    };
    private renderDeploymentRows() {
        return (<LoadMoreWrapper items={this.props.deployments} alwaysShowLoadMore={true} showLoadAll={true} onLoadMore={this.onLoadMore} loadMoreDisabled={this.state.busy} renderLoadMore={(children) => (<DataTableRow className={this.state.showChildRows ? styles.visible : styles.hidden}>
                        <DataTableRowColumn colSpan={4}>{children}</DataTableRowColumn>
                    </DataTableRow>)} renderItem={(deployment, index) => {
                const task = this.props.deploymentTasks.filter((deploymentTask) => deploymentTask.Arguments.DeploymentId === deployment.Id)[0];
                return index === 0 ? (<DeploymentRow key={index} deployment={deployment} task={task} onToggleChildRows={this.handleToggleChildRows} showChildRows={this.state.showChildRows} actionButton={this.props.actionButton} {...this.props}/>) : (<DeploymentChildRow key={index} deployment={deployment} task={task} showChildRows={this.state.showChildRows} {...this.props}/>);
            }}/>);
    }
    static displayName = "PhaseDeployments";
}
