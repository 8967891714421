import { css } from "@emotion/css";
import { Divider } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import { Permission, Repository } from "@octopusdeploy/octopus-server-client";
import type { GitHubAppConnections, GitHubAppInstallations } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useMemo, useState } from "react";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import { DataBaseComponent, useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import PaperLayout from "../../../../components/PaperLayout/PaperLayout";
import { LibraryLayout } from "../LibraryLayout/LibraryLayout";
import { GitConnectionAnalyticView } from "./Analytics/GitConnectionAnalyticView";
import { GitHubAppAuthCheck } from "./GitHubAppAuthCheck";
import GitHubAppConnectionList from "./GitHubAppConnectionList";
import { GitConnectionAuthorizeOnboarding, GitConnectionInstallOnboarding } from "./Onboarding/GitConnectionOnboarding";
import { ConfigureGitHubAppLink } from "./Onboarding/GitHubAppLinks";
interface GitConnectionsPageInternalProps extends GitConnectionsPageProps {
    doBusyTask: DoBusyTask;
    busy?: Promise<unknown> | boolean;
    errors?: Errors;
}
function GitConnectionsPageInternal({ spaceId, doBusyTask, busy, errors }: GitConnectionsPageInternalProps) {
    const [connections, setConnections] = useState<GitHubAppConnections | undefined>(undefined);
    const [notConnectedInstallations, setNotConnectedInstallations] = useState<GitHubAppInstallations | undefined>(undefined);
    const [userIsAuthorized, setUserIsAuthorized] = useState<boolean | undefined>(undefined);
    const userHasCreatePermission = useMemo(() => isAllowed({ permission: Permission.GitCredentialEdit }), []);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const connections = await repository.GitHubApp.getConnections(0, Repository.takeAll);
        setConnections(connections);
    }, []);
    useDoBusyTaskEffect(doBusyTask, async () => {
        if (userIsAuthorized) {
            const installations = await repository.GitHubApp.getInstallations(true); // Skips any installations that are already connected.
            setNotConnectedInstallations(installations);
        }
        else {
            setNotConnectedInstallations(undefined);
        }
    }, [userIsAuthorized]);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const authorizationStatus = await repository.GitHubApp.getAuthorizationStatus();
        setUserIsAuthorized(authorizationStatus.IsAuthorized);
    }, []);
    return (<LibraryLayout spaceId={spaceId}>
            <GitConnectionAnalyticView name="View GitHub connections list"/>
            <PaperLayout title="GitHub Connections" busy={busy} errors={errors}>
                <GitConnectionsPageDisplay spaceId={spaceId} connections={connections} notConnectedInstallations={notConnectedInstallations} userIsAuthorized={userIsAuthorized} userHasCreatePermission={userHasCreatePermission}/>
            </PaperLayout>
        </LibraryLayout>);
}
interface GitConnectionsPageDisplayProps {
    spaceId: string;
    connections: GitHubAppConnections | undefined;
    notConnectedInstallations: GitHubAppInstallations | undefined;
    userIsAuthorized: boolean | undefined;
    userHasCreatePermission: boolean;
}
export function GitConnectionsPageDisplay({ spaceId, connections, notConnectedInstallations, userIsAuthorized, userHasCreatePermission }: GitConnectionsPageDisplayProps) {
    if (!connections || userIsAuthorized === undefined) {
        // Don't render anything until we've loaded the connections and know if the user is authorized or not.
        // They should always load first anyway because they don't require us to connect to any external systems.
        return null;
    }
    if (connections.Connections.length === 0) {
        // If we don't have any existing connections we want to show some sort of full-screen onboarding
        // because it's likely this is the first time they've hit this page. This covers all of the cases
        // where the generic list handling in the main return would leave users a little bit lost.
        if (!userIsAuthorized) {
            // If we have no existing connections and the user is not authorized, show the authorize onboarding.
            return <GitConnectionAuthorizeOnboarding />;
        }
        if (notConnectedInstallations === undefined) {
            // If we have no connections, and the user is authorized but haven't finished loading the installations
            // from GitHub, don't show anything yet.
            return null;
        }
        if (notConnectedInstallations.Installations.length === 0) {
            // If we have no connections or installstions, show the install onboarding
            return <GitConnectionInstallOnboarding />;
        }
    }
    return (<>
            <GitHubAppAuthCheck actionDescription={"view other organisations and accounts"}/>
            <GitHubAppConnectionList spaceId={spaceId} connections={connections} notConnectedInstallations={notConnectedInstallations}/>
            <Divider />
            <GitConnectionsInstallFooter userIsAuthorized={userIsAuthorized}/>
        </>);
}
interface GitConnectionsInstallFooterProps {
    userIsAuthorized: boolean;
}
function GitConnectionsInstallFooter({ userIsAuthorized }: GitConnectionsInstallFooterProps) {
    const styles = {
        footer: css({
            marginLeft: space[16],
            marginRight: space[16],
            marginTop: space[24],
            marginBottom: space[24],
            textAlign: "center",
        }),
    };
    if (!userIsAuthorized) {
        return null;
    }
    return (<div className={styles.footer}>
            <p>Install the GitHub app to connect to other organizations or users</p>
            <p>
                <ConfigureGitHubAppLink />
            </p>
        </div>);
}
interface GitConnectionsPageProps {
    spaceId: string;
}
export class GitConnectionsPage extends DataBaseComponent<GitConnectionsPageProps> {
    constructor(props: GitConnectionsPageProps) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.clearErrors();
    }
    render() {
        return <GitConnectionsPageInternal spaceId={this.props.spaceId} doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors}/>;
    }
    static displayName = "GitConnectionsPage";
}
