/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { css, cx } from "@emotion/css";
import { Divider, useIsLargerThanIpadResolution } from "@octopusdeploy/design-system-components";
import { themeTokens, text, space } from "@octopusdeploy/design-system-tokens";
import { AnalyticLinkLocationProvider } from "@octopusdeploy/portal-analytics";
import type { ReactNode } from "react";
import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { ContextualHelpLayout } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import { RouteAwareErrorBoundary } from "~/components/ErrorBoundary/index";
import { useSetInPageNavVisible } from "~/components/GlobalLayout/InPageNavVisibilityContext";
import type { MenuNodeItem } from "~/components/LinksMenu/MenuNode";
import Markdown from "~/components/Markdown/index";
import InternalNavLink from "~/components/Navigation/InternalNavLink/InternalNavLink";
import type { NavLink } from "~/components/NavigationSidebarLayout/index";
import styles from "~/components/NavigationSidebarLayout/style.module.less";
import PriorityNavigation from "~/components/PriorityNavigation/index";
import { useIsVerticalNavigationEnabled } from "../RootRoutes/useIsVerticalNavigationEnabled";
export type NavDivider = "divider";
export type NavItemVNext = NavLink | NavDivider;
export interface NavigationSideBarLayoutVNextProps {
    navItems: NavItemVNext[];
    content: ReactNode;
    header?: ReactNode;
    tourProgress?: ReactNode;
    description?: string;
}
export function PortalPageLayout({ navItems, content, description, header, tourProgress }: NavigationSideBarLayoutVNextProps) {
    const setIsInPageNavVisible = useSetInPageNavVisible();
    const isVerticalNavigationEnabled = useIsVerticalNavigationEnabled();
    const isLargerThanDesktop = useMediaQuery({ query: `(min-width: 1279px)` });
    const isLargerThanIpad = useIsLargerThanIpadResolution();
    const hasItems = navItems.length > 0;
    React.useEffect(() => {
        setIsInPageNavVisible?.(hasItems);
        return () => setIsInPageNavVisible?.(false);
    }, [hasItems, setIsInPageNavVisible]);
    const desktopView = (<div className={sideBarLayoutStyles}>
            <AnalyticLinkLocationProvider location="Sidebar navigation">
                {hasItems && (<nav className={cx(sideNavBarStyles, { [sideNavBarStylesVNext]: isVerticalNavigationEnabled })}>
                        {tourProgress && <div className={resumeTourButtonDesktopContainerStyles}>{tourProgress}</div>}
                        {navItems.map((item, index) => (<div key={index}>{renderSideNavItem(item)}</div>))}
                        {description && (<div className={descriptionStyles}>
                                <Markdown markup={description}/>
                            </div>)}
                    </nav>)}
            </AnalyticLinkLocationProvider>
            <div className={sideBarLayoutContentStyles} key="content">
                <RouteAwareErrorBoundary>{content}</RouteAwareErrorBoundary>
            </div>
        </div>);
    const ipadView = (<div className={sideBarLayoutContentMobileStyles}>
            <div className={isLargerThanIpad ? resumeTourButtonIpadContainerStyles : resumeTourButtonMobileContainerStyles}>{tourProgress}</div>
            <div>
                <AnalyticLinkLocationProvider location="Sidebar navigation">
                    <PriorityNavigation className={styles.horizontalLinks} activeItemClassName={styles.selected} maxNavigationItems={99} navigationItems={navItems.filter(isNavLink).map(convertNavLinkToMenuNodeItem)}/>
                </AnalyticLinkLocationProvider>
            </div>
            <div key="content">
                <RouteAwareErrorBoundary>{content}</RouteAwareErrorBoundary>
            </div>
        </div>);
    return (<ContextualHelpLayout>
            {header}
            {isLargerThanDesktop ? desktopView : ipadView}
        </ContextualHelpLayout>);
}
function renderSideNavItem(item: NavItemVNext) {
    return isNavLink(item) ? (<SideNavLink label={item.label} path={item.path} exact={item.exact}/>) : (<div className={dividerWrapper}>
            <Divider />
        </div>);
}
function SideNavLink({ label, path, exact }: NavLink) {
    return (<InternalNavLink to={path} activeClassName={selectedLinkStyles} className={linkStyles} exact={exact}>
            {label}
        </InternalNavLink>);
}
function convertNavLinkToMenuNodeItem(navLink: NavLink): MenuNodeItem {
    return { url: navLink.path, text: navLink.label as string, exact: navLink.exact };
}
function isNavLink(item: NavItemVNext): item is NavLink {
    return item !== "divider";
}
const sideBarLayoutStyles = css({
    height: "100%",
    display: "flex",
});
const sideNavBarStyles = css({
    color: themeTokens.color.text.primary,
    font: text.interface.body.default.medium,
    display: "flex",
    flexDirection: "column",
    width: "14.5rem",
    flexShrink: 0,
    padding: `${space["16"]} ${space["16"]} ${space["32"]} ${space["32"]}`,
    gap: space["2"],
});
const sideNavBarStylesVNext = css({
    padding: `${space["16"]} ${space["8"]} ${space["32"]} ${space["16"]}`,
});
const dividerWrapper = css({
    padding: `${space["16"]} 0`,
    marginLeft: space["8"],
    maxWidth: "5.125rem",
});
const descriptionStyles = css({
    padding: `${space["40"]} 0 ${space["24"]} ${space["8"]}`,
    overflowWrap: "break-word", // The description can contain words that are too long to fit in the sidebar, we should break these words to avoid overflowing
    font: text.interface.body.default.small,
});
const sideBarLayoutContentStyles = css({
    padding: `${space["16"]} ${space["32"]} 0 ${space["16"]}`,
    display: "flex",
    flex: "1 1 auto",
    // https://www.w3.org/TR/css-flexbox-1/#min-size-auto
    // This prevents the child from growing larger than the parent flexbox container if the childs content is large
    minWidth: 0,
});
const sideBarLayoutContentMobileStyles = css({
    display: "flex",
    flexDirection: "column",
    padding: "0 1rem",
});
const linkStyles = css({
    color: themeTokens.color.text.primary,
    display: "block",
    padding: `${space["4"]} ${space["8"]}`,
    borderRadius: space["4"],
    width: "11.5rem",
    ":hover": {
        color: themeTokens.color.navigation.link.text.hover,
        backgroundColor: themeTokens.color.navigation.link.background.hover,
    },
    ":active": {
        color: themeTokens.color.navigation.link.text.pressed,
        background: themeTokens.color.navigation.link.background.pressed,
    },
});
const selectedLinkStyles = css({
    color: themeTokens.color.text.selected,
    backgroundColor: themeTokens.color.background.secondary.hovered,
    ":hover": {
        // We need to re-apply the same styles here because we have global css trying to style this hover state
        color: themeTokens.color.text.selected,
        backgroundColor: themeTokens.color.background.secondary.hovered,
    },
});
const resumeTourButtonDesktopContainerStyles = css({
    marginBottom: space[16],
});
const resumeTourButtonIpadContainerStyles = css({
    maxWidth: 254,
    padding: `0 0 ${space["16"]} ${space["32"]}`,
});
const resumeTourButtonMobileContainerStyles = css({
    maxWidth: 254,
    padding: `0 0 ${space["16"]} ${space["16"]}`,
});
