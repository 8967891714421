import { ProcessType } from "@octopusdeploy/octopus-server-client";
import type { InsightsReportResource } from "@octopusdeploy/octopus-server-client";
import type { RouteTemplate, QueryParamValues, UnknownQueryParam, RedirectRouteDefinition, QueryParamValuesSetter } from "@octopusdeploy/portal-routes";
import { links, allRoutes } from "@octopusdeploy/portal-routes";
import type { ComponentType } from "react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch, useLocation } from "react-router-dom";
import ConfigurationLayout from "~/areas/configuration/components/ConfigurationLayout/ConfigurationLayout";
import DeploymentToProjectTaskRedirect from "~/areas/deployments/DeploymentToProjectTaskRedirect";
import { InsightsReportLoader } from "~/areas/insights/components/InsightsReportLayout/InsightsReportLoader";
import LicenseChecker from "~/areas/insights/components/InsightsRoutes/LicenseChecker";
import { RedirectFeedBasedOnType } from "~/areas/library/components/ExternalFeeds/RedirectFeedBasedOnType";
import { ProjectLayout } from "~/areas/projects/components/ProjectLayout/ProjectLayout";
import BranchAwareRedirect from "~/areas/projects/components/ProjectsRoutes/BranchAwareRedirect";
import { ProjectToOverviewRedirect } from "~/areas/projects/components/ProjectsRoutes/ProjectToOverviewRedirect";
import { VariablesBranchAwareRedirect } from "~/areas/projects/components/ProjectsRoutes/VariablesBranchAwareRedirect";
import RunbookContextLayout from "~/areas/projects/components/Runbooks/RunbookContextLayout";
import RunbookRunToRunbookTaskRedirect from "~/areas/runbookRuns/RunbookRunToRunbookTaskRedirect";
import { InterruptionToProjectRedirect } from "~/areas/tasks/components/TaskRoutes/InterruptionToTaskRedirect";
import TenantLayout from "~/areas/tenants/TenantLayout/TenantLayout";
import UserProfileLayout from "~/areas/users/UserProfileLayout";
import { session } from "~/clientInstance";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { FullWidthPageLayout } from "~/components/FullWidthPageLayout/FullWidthPageLayout";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { NoPermissionsPage } from "~/components/NoPermissionsPage/NoPermissionsPage";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import RedirectToProcessEditorViaSlug from "~/components/RedirectToProcessEditorViaSlug/RedirectToProcessEditorViaSlug";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import { ChannelRedirect, ReleaseRedirect, RunbookRedirect, TriggerRedirect } from "~/components/RootRoutes/ProjectChildRedirect";
import { RunbookSnapshotRedirect } from "~/components/RootRoutes/ProjectRunbookChildRedirect";
import { RedirectProjectIfNotSlug, RedirectReleaseVersion } from "~/components/SlugSafeRedirect/SlugSafeRedirect";
import type { SpaceContext } from "~/components/SpaceLoader/SpaceLoader";
import { isSpaceNotFound, isSpecificSpaceContext } from "~/components/SpaceLoader/SpaceLoader";
import { SpaceNotFound } from "~/components/SpaceNotFound/SpaceNotFound";
import type { InsightsReportPageRegistration } from "~/routing/pageRegistrations/InsightsPageRegistration";
import type { Level1PageLayoutProps } from "~/routing/pageRegistrations/Level1PageLayoutProps";
import type { Level2PageLayoutProps } from "~/routing/pageRegistrations/Level2PageLayoutProps";
import { formatRoutePath } from "~/routing/pageRegistrations/formatRoutePath";
import { routeSegment } from "~/routing/pageRegistrations/routeSegment";
import { DefaultConfigurationPageRedirect } from "~/routing/redirects/DefaultConfigurationPageRedirect";
import { ScopedUserRoleToTeamRedirect } from "~/routing/redirects/ScopedUserRoleToTeamRedirect";
import { RunbooksBranchAwareRedirect } from "../areas/projects/components/ProjectsRoutes/RunbooksBranchAwareRedirect";
import type { PageRegistration, UnknownPageRegistration } from "./pageRegistrations/PageRegistration";
import { RoutePage } from "./pageRegistrations/RoutePage";
import { allPages } from "./pageRegistrations/allPageRegistrations";
import { DefaultInsightsPageRedirect } from "./redirects/DefaultInsightsPageRedirect";
import { DefaultLibraryPageRedirect } from "./redirects/DefaultLibraryPageRedirect";
interface AllPageRoutesProps {
    spaceContext: SpaceContext;
}
export function AllPageRoutes({ spaceContext }: AllPageRoutesProps) {
    return (<Switch>
            {getDefaultRootRedirectRoute(spaceContext)}
            {getSpaceRoutes(spaceContext)}
            {getSystemOrMixedScopeRoutes(spaceContext)}

            {routeSegment(allPages.childGroups.system.childGroups.currentUser, (routeProps, pages) => (<CurrentUserRoutes pages={pages}/>))}
            {renderPage(allPages.childGroups.system.pages.styleGuidePage)}

            {getNoMatchRoute(spaceContext)}
        </Switch>);
}
function getDefaultRootRedirectRoute(spaceContext: SpaceContext) {
    if (isSpecificSpaceContext(spaceContext)) {
        // The SpaceLoader redirects to the root of a space, means that this route is never hit. We should consider whether this is still needed.
        return renderRedirect(allRoutes.redirects.rootRedirect, () => <InternalRedirect to={links.dashboardPage.generateUrl({ spaceId: spaceContext.Id })}/>);
    }
    if (session.currentPermissions?.hasAnyPermissions()) {
        return renderRedirect(allRoutes.redirects.rootRedirect, () => <InternalRedirect to={links.configurationRootRedirect.generateUrl()}/>);
    }
    return undefined;
}
function getSpaceRoutes(spaceContext: SpaceContext) {
    if (!isSpecificSpaceContext(spaceContext)) {
        // If you are not in a space, then it does not make sense to render any space specific components
        // We are better off letting this fall through to a 404,
        // rather than try and fail to render a space specific component, and probably end up with undefined behaviour
        return [];
    }
    return [
        ...renderPages(allPages.childGroups.space.pages),
        routeSegment(allPages.childGroups.space.childGroups.projects, (routeProps, pages, childGroups) => <ProjectsRoutes projectsPages={pages} projectsPageGroups={childGroups}/>),
        routeSegment(allPages.childGroups.space.childGroups.infrastructure, (routeProps, pages) => <InfrastructureRoutes pages={pages}/>),
        routeSegment(allPages.childGroups.space.childGroups.library, (routeProps, pages) => <LibraryRoutes pages={pages}/>),
        routeSegment(allPages.childGroups.space.childGroups.tenants, (routeProps, pages, childGroups) => <TenantRoutes tenantsPages={pages} tenantsPageGroups={childGroups}/>),
        routeSegment(allPages.childGroups.space.childGroups.insights, (routeProps, pages, childGroups) => <InsightsRoutes pages={pages} childGroups={childGroups} spaceId={routeProps.spaceId}/>),
        renderRedirect(allRoutes.childRouteSegments.space.redirects.deploymentToProjectTaskRedirect, ({ spaceId, deploymentId }) => <DeploymentToProjectTaskRedirect spaceId={spaceId} deploymentId={deploymentId}/>),
        renderRedirect(allRoutes.childRouteSegments.space.redirects.spaceRootRedirect, ({ spaceId }) => <InternalRedirect to={links.dashboardPage.generateUrl({ spaceId })}/>),
        renderRedirect(allRoutes.childRouteSegments.space.redirects.releaseRedirect, ({ spaceId, releaseId }) => <ReleaseRedirect spaceId={spaceId} releaseId={releaseId}/>),
        renderRedirect(allRoutes.childRouteSegments.space.redirects.triggerRedirect, ({ spaceId, triggerId }) => <TriggerRedirect spaceId={spaceId} triggerId={triggerId}/>),
        renderRedirect(allRoutes.childRouteSegments.space.redirects.channelRedirect, ({ spaceId, channelId }) => <ChannelRedirect spaceId={spaceId} channelId={channelId}/>),
        renderRedirect(allRoutes.childRouteSegments.space.redirects.runbookSnapshotRedirect, ({ spaceId, runbookSnapshotId }) => <RunbookSnapshotRedirect spaceId={spaceId} runbookSnapshotId={runbookSnapshotId}/>),
        renderRedirect(allRoutes.childRouteSegments.space.redirects.runbookRedirect, ({ spaceId, runbookId }) => <RunbookRedirect spaceId={spaceId} runbookId={runbookId}/>),
        renderRedirect(allRoutes.childRouteSegments.space.redirects.runbookRunRedirect, ({ spaceId, runbookRunId }) => <RunbookRunToRunbookTaskRedirect spaceId={spaceId} runbookRunId={runbookRunId}/>),
    ];
}
function getSystemOrMixedScopeRoutes(spaceContext: SpaceContext) {
    if (isSpaceNotFound(spaceContext)) {
        // If the space was not found, we don't want to show routes that are available in the system context.
        // We want to direct people to go to either a space-less route, or to step into a space.
        // So we should only show the Space Switcher in the navbar and disallow other routing.
        return [];
    }
    const { scriptConsolePage, taskPage, styleGuidePage, ...systemPages } = allPages.childGroups.system.pages;
    return [
        // Render the script console page before the task page so /console is not interpreted as a taskId
        renderPage(scriptConsolePage),
        renderPage(taskPage),
        ...renderPages(systemPages),
        routeSegment(allPages.childGroups.system.childGroups.configuration, (routeProps, pages, childGroups) => <ConfigurationRoutes pages={pages} childGroups={childGroups}/>),
        renderRedirect(allRoutes.childRouteSegments.system.redirects.interruptionToTaskRedirect, ({ interruptionId }) => <InterruptionToProjectRedirect interruptionId={interruptionId}/>),
    ];
}
function getNoMatchRoute(spaceContext: SpaceContext) {
    if (isSpaceNotFound(spaceContext)) {
        return <ReloadableRoute key="space not found" render={() => <SpaceNotFound spaceNotFoundContext={spaceContext}/>}/>;
    }
    else if (!session.currentPermissions?.hasAnyPermissions()) {
        return <ReloadableRoute key="no permissions" component={NoPermissionsPage}/>;
    }
    return <RedirectAs404 key="404 redirect"/>;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RouteParamsFromRouteTemplate<Template extends RouteTemplate<any>> = Template extends RouteTemplate<infer RouteParams> ? RouteParams : never;
const branchNameKey: Extract<keyof RouteParamsFromRouteTemplate<typeof allPages.childGroups.space.childGroups.projects.childGroups.specificProject.childGroups.branch.partialRoute>, "branchName"> = "branchName";
function InfrastructureRoutes({ pages }: {
    pages: typeof allPages.childGroups.space.childGroups.infrastructure.pages;
}) {
    const dynamicEnvironmentsEnabled = isFeatureToggleEnabled("DynamicEnvironmentsFeatureToggle");
    const { createProxyPage, editProxyPage, createMachinePolicyPage, editMachinePolicyPage, createInfrastructureAccountPage, editInfrastructureAccountPage, dynamicEnvironmentsPage, dynamicEnvironmentPage, ...otherPages } = pages;
    return (<Switch>
            {renderPage(createProxyPage)}
            {renderPage(editProxyPage)}
            {renderPage(createMachinePolicyPage)}
            {renderPage(editMachinePolicyPage)}
            {renderPage(createInfrastructureAccountPage)}
            {renderPage(editInfrastructureAccountPage)}
            {dynamicEnvironmentsEnabled && renderPages({ dynamicEnvironmentsPage, dynamicEnvironmentPage })}
            {renderPages(otherPages)}

            <ReloadableRoute path={formatRoutePath(allRoutes.childRouteSegments.space.childRouteSegments.infrastructure.redirects.infrastructureRootRedirect.completeRoute.template)} exact={true} render={(props: RouteComponentProps<RouteParamsFromRouteTemplate<typeof allRoutes.childRouteSegments.space.childRouteSegments.infrastructure.redirects.infrastructureRootRedirect.completeRoute.template>>) => (<InternalRedirect to={links.infrastructureOverviewPage.generateUrl({ spaceId: props.match.params.spaceId })}/>)}/>
            <RedirectAs404 />
        </Switch>);
}
function LibraryRoutes({ pages }: {
    pages: typeof allPages.childGroups.space.childGroups.library.pages;
}) {
    const { archiveCertificatePage, createCertificatePage, editCertificatePage, createFeedPage, editFeedPage, createLifecyclePage, editLifecyclePage, createScriptModulePage, editScriptModulePage, createTagSetPage, editTagSetPage, createGitCredentialPage, editGitCredentialPage, gitConnectionsPage, createGitHubConnectionPage, editGitHubConnectionPage, ...otherPages } = pages;
    const gitHubConnectionsEnabled = isFeatureToggleEnabled("GitHubConnectionsFeatureToggle");
    const gitHubConnectionPages = {
        gitConnectionsPage,
        createGitHubConnectionPage,
        editGitHubConnectionPage,
    };
    return (<Switch>
            <ReloadableRoute path={formatRoutePath(allRoutes.childRouteSegments.space.childRouteSegments.library.redirects.libraryRootRedirect.completeRoute.template)} exact={true} render={({ match }: RouteComponentProps<RouteParamsFromRouteTemplate<typeof allRoutes.childRouteSegments.space.childRouteSegments.library.redirects.libraryRootRedirect.completeRoute.template>>) => (<DefaultLibraryPageRedirect spaceId={match.params.spaceId}/>)}/>

            <ReloadableRoute path={formatRoutePath(allRoutes.childRouteSegments.space.childRouteSegments.library.redirects.redirectFeedBasedOnType.completeRoute.template)} exact={true} render={({ match }: RouteComponentProps<RouteParamsFromRouteTemplate<typeof allRoutes.childRouteSegments.space.childRouteSegments.library.redirects.redirectFeedBasedOnType.completeRoute.template>>) => (<RedirectFeedBasedOnType spaceId={match.params.spaceId} feedId={match.params.feedId}/>)}/>

            {/* Explicit ordering here, so /archive and /create doesn't get caught by the /{id} route. */}
            {renderPage(archiveCertificatePage)}
            {renderPage(createCertificatePage)}
            {renderPage(editCertificatePage)}
            {renderPage(createFeedPage)}
            {renderPage(editFeedPage)}
            {renderPage(createLifecyclePage)}
            {renderPage(editLifecyclePage)}
            {renderPage(createScriptModulePage)}
            {renderPage(editScriptModulePage)}
            {renderPage(createTagSetPage)}
            {renderPage(editTagSetPage)}
            {renderPage(createGitCredentialPage)}
            {renderPage(editGitCredentialPage)}
            {gitHubConnectionsEnabled && renderPages(gitHubConnectionPages)}
            {renderPages(otherPages)}
            <RedirectAs404 />
        </Switch>);
}
function ProjectsRoutes({ projectsPages, projectsPageGroups }: {
    projectsPages: typeof allPages.childGroups.space.childGroups.projects.pages;
    projectsPageGroups: typeof allPages.childGroups.space.childGroups.projects.childGroups;
}) {
    const location = useLocation();
    const dynamicEnvironmentsEnabled = isFeatureToggleEnabled("DynamicEnvironmentsFeatureToggle");
    const { projectsPage, ...otherProjectsPages } = projectsPages;
    return (<Switch>
            {/*The projectsPage has a level 1 layout so is rendered separately to the other root project pages*/}
            {renderPageWithLevel1Layout(projectsPage, FullWidthPageLayout)}
            {renderPages(otherProjectsPages)}

            {routeSegment(projectsPageGroups.importExports, (routeProps, importExportPages) => (<Switch>
                    {renderPages(importExportPages)}
                    <RedirectAs404 />
                </Switch>))}

            {routeSegment(projectsPageGroups.specificProject, (specificProjectRouteProps, specificProjectPages, specificProjectChildGroups) => (<RedirectProjectIfNotSlug spaceId={specificProjectRouteProps.spaceId}>
                    <Switch>
                        {routeSegment(specificProjectChildGroups.branch, (branchRouteProps, branchPages, branchChildGroups) => (<ProjectLayout spaceId={branchRouteProps.spaceId} projectSlug={branchRouteProps.projectSlug} branchName={branchRouteProps.branchName} isNewlyCreatedProject={new URLSearchParams(location.search).get("newlyCreatedProject")}>
                                    <Switch>
                                        {routeSegment(branchChildGroups.deployments, (_, branchDeploymentPages) => (<ErrorContextProvider>
                                                <Switch>
                                                    <ReloadableRoute path={formatRoutePath(allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.branch.childRouteSegments.deployments.redirects
                        .branchDeploymentProcessStepByStepSlugRedirect.completeRoute.template)} exact={true} render={({ match, }: RouteComponentProps<RouteParamsFromRouteTemplate<typeof allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.branch.childRouteSegments.deployments.redirects.branchDeploymentProcessStepByStepSlugRedirect.completeRoute.template>>) => <RedirectToProcessEditorViaSlug processType={ProcessType.Deployment} projectSlug={match.params.projectSlug} stepSlug={match.params.stepSlug}/>}/>

                                                    {renderPages(branchDeploymentPages)}

                                                    <RedirectAs404 />
                                                </Switch>
                                            </ErrorContextProvider>))}

                                        {routeSegment(branchChildGroups.variables, (_, branchVariablesPages) => (<ErrorContextProvider>
                                                <Switch>
                                                    {renderPages(branchVariablesPages)}
                                                    <RedirectAs404 />
                                                </Switch>
                                            </ErrorContextProvider>))}

                                        {routeSegment(branchChildGroups.specificRunbook, ({ runbookId }, runbookPages, runbookChildGroups) => (<SpecificGitRunbookRoutes runbookId={runbookId} pages={runbookPages} childGroups={runbookChildGroups}/>))}

                                        {renderPages(branchPages)}

                                        <RedirectAs404 />
                                    </Switch>
                                </ProjectLayout>), undefined, [branchNameKey])}
                        {routeSegment(specificProjectChildGroups.withoutBranch, (branchRouteParams, nonBranchPages, nonBranchChildGroups) => {
                const { projectDynamicEnvironmentSettingsPage, ...otherNonBranchPages } = nonBranchPages;
                return (<ProjectLayout spaceId={branchRouteParams.spaceId} projectSlug={branchRouteParams.projectSlug} branchName={undefined} isNewlyCreatedProject={new URLSearchParams(location.search).get("newlyCreatedProject")}>
                                    <Switch>
                                        <ReloadableRoute path={formatRoutePath(allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.withoutBranch.redirects.projectRootRedirect.completeRoute.template)} exact={true} render={() => <ProjectToOverviewRedirect />}/>
                                        <ReloadableRoute path={formatRoutePath(allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.withoutBranch.redirects.projectOverviewRedirect.completeRoute.template)} exact={true} render={({ match, }: RouteComponentProps<RouteParamsFromRouteTemplate<typeof allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.withoutBranch.redirects.projectOverviewRedirect.completeRoute.template>>) => <InternalRedirect to={links.deploymentsPage.generateUrl({ spaceId: match.params.spaceId, projectSlug: match.params.projectSlug })}/>}/>

                                        {routeSegment(nonBranchChildGroups.deployments, (deploymentRouteParams, { releasesPage, createReleasePage, ...otherDeploymentPages }, deploymentChildGroups) => (<Switch>
                                                {routeSegment(deploymentChildGroups.process, (_, deploymentProcessPages) => (<BranchAwareRedirect>
                                                        <ErrorContextProvider>
                                                            <Switch>
                                                                <ReloadableRoute path={formatRoutePath(allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.withoutBranch.childRouteSegments.deployments
                                .childRouteSegments.process.redirects.deploymentProcessStepByStepSlugRedirect.completeRoute.template)} exact={true} render={({ match, }: RouteComponentProps<RouteParamsFromRouteTemplate<typeof allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.withoutBranch.childRouteSegments.deployments.childRouteSegments.process.redirects.deploymentProcessStepByStepSlugRedirect.completeRoute.template>>) => <RedirectToProcessEditorViaSlug processType={ProcessType.Deployment} projectSlug={match.params.projectSlug} stepSlug={match.params.stepSlug}/>}/>

                                                                {renderPages(deploymentProcessPages)}

                                                                <RedirectAs404 />
                                                            </Switch>
                                                        </ErrorContextProvider>
                                                    </BranchAwareRedirect>))}
                                                {routeSegment(deploymentChildGroups.settings, (_, deploymentSettingsPages) => (<BranchAwareRedirect>
                                                        <ErrorContextProvider>
                                                            <Switch>
                                                                {renderPages(deploymentSettingsPages)}
                                                                <RedirectAs404 />
                                                            </Switch>
                                                        </ErrorContextProvider>
                                                    </BranchAwareRedirect>))}

                                                {renderPage(createReleasePage)}

                                                {routeSegment(deploymentChildGroups.specificRelease, (_, { editReleasePage, releasePage, createDeploymentPage, deploymentDetailsPage, ...otherReleasePages }) => (<RedirectReleaseVersion>
                                                        <Switch>
                                                            {renderPage(editReleasePage)}
                                                            {renderPage(releasePage)}
                                                            {renderPage(createDeploymentPage)}
                                                            {renderPage(deploymentDetailsPage)}
                                                            {renderPages(otherReleasePages)}
                                                            <RedirectAs404 />
                                                        </Switch>
                                                    </RedirectReleaseVersion>))}

                                                {renderPage(releasesPage)}
                                                {renderPages(otherDeploymentPages)}
                                                <RedirectAs404 />
                                            </Switch>))}
                                        {routeSegment(nonBranchChildGroups.variables, (_, variablesPages, variablesChildGroups) => (<ErrorContextProvider>
                                                <Switch>
                                                    {routeSegment(variablesChildGroups.wrapper, (_, variablesWrapperPages) => (<VariablesBranchAwareRedirect>
                                                                <Switch>
                                                                    {renderPages(variablesWrapperPages)}
                                                                    <RedirectAs404 />
                                                                </Switch>
                                                            </VariablesBranchAwareRedirect>), true)}

                                                    {routeSegment(variablesChildGroups.all, (_, allVariablesPages) => (<VariablesBranchAwareRedirect>
                                                            <Switch>
                                                                {renderPages(allVariablesPages)}
                                                                <RedirectAs404 />
                                                            </Switch>
                                                        </VariablesBranchAwareRedirect>))}

                                                    {routeSegment(variablesChildGroups.preview, (_, variablePreviewPages) => (<VariablesBranchAwareRedirect>
                                                            <Switch>
                                                                {renderPages(variablePreviewPages)}
                                                                <RedirectAs404 />
                                                            </Switch>
                                                        </VariablesBranchAwareRedirect>))}

                                                    {renderPages(variablesPages)}
                                                    <RedirectAs404 />
                                                </Switch>
                                            </ErrorContextProvider>))}

                                        {routeSegment(nonBranchChildGroups.specificRunbook, ({ runbookId }, runbookPages, runbookChildGroups) => (<RunbooksBranchAwareRedirect>
                                                <SpecificRunbookRoutes runbookId={runbookId} pages={runbookPages} childGroups={runbookChildGroups}/>
                                            </RunbooksBranchAwareRedirect>))}

                                        {routeSegment(nonBranchChildGroups.projectRunbooks, (_, projectRunbookPages) => (<RunbooksBranchAwareRedirect>
                                                <ErrorContextProvider>
                                                    <Switch>
                                                        {renderPages(projectRunbookPages)}
                                                        <RedirectAs404 />
                                                    </Switch>
                                                </ErrorContextProvider>
                                            </RunbooksBranchAwareRedirect>))}

                                        {dynamicEnvironmentsEnabled && renderPages({ projectDynamicEnvironmentSettingsPage })}
                                        {renderPages(otherNonBranchPages)}
                                        <RedirectAs404 />
                                    </Switch>
                                </ProjectLayout>);
            })}
                        <RedirectAs404 />
                    </Switch>
                </RedirectProjectIfNotSlug>))}
            <RedirectAs404 />
        </Switch>);
}
type SpecificRunbookPages = typeof allPages.childGroups.space.childGroups.projects.childGroups.specificProject.childGroups.withoutBranch.childGroups.specificRunbook.pages;
type SpecificRunbookChildGroups = typeof allPages.childGroups.space.childGroups.projects.childGroups.specificProject.childGroups.withoutBranch.childGroups.specificRunbook.childGroups;
function SpecificRunbookRoutes({ runbookId, pages, childGroups }: {
    runbookId: string;
    pages: SpecificRunbookPages;
    childGroups: SpecificRunbookChildGroups;
}) {
    const { projectRunbookSnapshotInfoPage, projectRunbookSnapshotEditPage, projectRunbookSnapshotCreatePage, projectRunbookSnapshotsPage, runbookRunNowPage, runbookRunSnapshotNowPage, createRunbookRunForSnapshotPage, projectRunbookRunDetailPage, ...otherSpecificRunbookPages } = pages;
    return (<RunbookContextLayout runbookId={runbookId}>
            {(_) => (<Switch>
                    <ReloadableRoute exact={true} path={formatRoutePath(allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.withoutBranch.childRouteSegments.specificRunbook.redirects.runbookRootRedirect.completeRoute.template)} render={({ match, }: RouteComponentProps<RouteParamsFromRouteTemplate<typeof allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.withoutBranch.childRouteSegments.specificRunbook.redirects.runbookRootRedirect.completeRoute.template>>) => <InternalRedirect to={links.projectRunbookOverviewPage.generateUrl({ spaceId: match.params.spaceId, projectSlug: match.params.projectSlug, runbookId: match.params.runbookId })}/>}/>

                    {routeSegment(childGroups.process, (_, runbookProcessPages) => (<ErrorContextProvider>
                            <Switch>{renderPages(runbookProcessPages)}</Switch>
                        </ErrorContextProvider>))}

                    {/* Explicit ordering here, so that snapshot routes don't interfere with each other. */}
                    {renderPage(projectRunbookSnapshotCreatePage)}
                    {renderPage(projectRunbookSnapshotEditPage)}
                    {renderPage(projectRunbookSnapshotInfoPage)}
                    {renderPage(projectRunbookSnapshotsPage)}
                    {renderPage(runbookRunSnapshotNowPage)}
                    {renderPage(runbookRunNowPage)}
                    {renderPage(createRunbookRunForSnapshotPage)}
                    {renderPage(projectRunbookRunDetailPage)}
                    {renderPages(otherSpecificRunbookPages)}

                    <RedirectAs404 />
                </Switch>)}
        </RunbookContextLayout>);
}
type SpecificGitRunbookPages = typeof allPages.childGroups.space.childGroups.projects.childGroups.specificProject.childGroups.branch.childGroups.specificRunbook.pages;
type SpecificGitRunbookChildGroups = typeof allPages.childGroups.space.childGroups.projects.childGroups.specificProject.childGroups.branch.childGroups.specificRunbook.childGroups;
function SpecificGitRunbookRoutes({ runbookId, pages, childGroups }: {
    runbookId: string;
    pages: SpecificGitRunbookPages;
    childGroups: SpecificGitRunbookChildGroups;
}) {
    return (<RunbookContextLayout runbookId={runbookId}>
            {(_) => (<Switch>
                    <ReloadableRoute exact={true} path={formatRoutePath(allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.branch.childRouteSegments.specificRunbook.redirects.branchRunbookRootRedirect.completeRoute.template)} render={({ match, }: RouteComponentProps<RouteParamsFromRouteTemplate<typeof allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.branch.childRouteSegments.specificRunbook.redirects.branchRunbookRootRedirect.completeRoute.template>>) => <InternalRedirect to={links.projectRunbookOverviewPage.generateUrl({ spaceId: match.params.spaceId, projectSlug: match.params.projectSlug, runbookId: match.params.runbookId })}/>}/>

                    {routeSegment(childGroups.branchProcess, (_, runbookProcessPages) => (<ErrorContextProvider>
                            <Switch>{renderPages(runbookProcessPages)}</Switch>
                        </ErrorContextProvider>))}

                    {renderPages(pages)}

                    <RedirectAs404 />
                </Switch>)}
        </RunbookContextLayout>);
}
function ConfigurationRoutes({ pages, childGroups }: {
    pages: typeof allPages.childGroups.system.childGroups.configuration.pages;
    childGroups: typeof allPages.childGroups.system.childGroups.configuration.childGroups;
}) {
    const allPages = {
        ...pages,
        ...childGroups.settings.pages,
    };
    return (<ConfigurationLayout>
            {(PageLayout) => {
            const { createSubscriptionPage, editSubscriptionPage, createUserRolePage, editUserRolePage, createUserPage, editUserPage, ...otherPages } = allPages;
            return (<Switch>
                        <ReloadableRoute exact={true} path={formatRoutePath(allRoutes.childRouteSegments.system.childRouteSegments.configuration.redirects.configurationRootRedirect.completeRoute.template)} render={() => <DefaultConfigurationPageRedirect />}/>
                        <ReloadableRoute path={formatRoutePath(allRoutes.childRouteSegments.system.childRouteSegments.configuration.redirects.scopedUserRoleToTeamRedirect.completeRoute.template)} exact={true} render={(props: RouteComponentProps<RouteParamsFromRouteTemplate<typeof allRoutes.childRouteSegments.system.childRouteSegments.configuration.redirects.scopedUserRoleToTeamRedirect.completeRoute.template>>) => (<ScopedUserRoleToTeamRedirect scopedUserRoleId={props.match.params.scopedRoleId}/>)}/>
                        <ReloadableRoute exact={true} path={formatRoutePath(allRoutes.childRouteSegments.system.childRouteSegments.configuration.redirects.diagnosticMachineCleanupRedirect.completeRoute.template)} render={() => <InternalRedirect to={links.auditPage.generateUrl({ documentTypes: ["Machines"], users: ["users-system"], eventCategories: ["Deleted"] })}/>}/>
                        {/* The routes for some create and edit pages overlap.
                 Depending on the order in which they are rendered, the /create segment of the create page
                 could be interpreted as the route parameter of the edit page.
                 Here we need to explicitly render the create page first to avoid this. */}
                        {renderPageWithLevel2Layout(createSubscriptionPage, PageLayout)}
                        {renderPageWithLevel2Layout(editSubscriptionPage, PageLayout)}
                        {renderPageWithLevel2Layout(createUserRolePage, PageLayout)}
                        {renderPageWithLevel2Layout(editUserRolePage, PageLayout)}
                        {renderPageWithLevel2Layout(createUserPage, PageLayout)}
                        {renderPageWithLevel2Layout(editUserPage, PageLayout)}
                        {renderPagesWithLevel2Layout(otherPages, PageLayout)}
                        <RedirectAs404 />
                    </Switch>);
        }}
        </ConfigurationLayout>);
}
function CurrentUserRoutes({ pages }: {
    pages: typeof allPages.childGroups.system.childGroups.currentUser.pages;
}) {
    return (<UserProfileLayout>
            <Switch>
                <ReloadableRoute exact={true} path={formatRoutePath(allRoutes.childRouteSegments.system.childRouteSegments.currentUser.redirects.currentUserRootRedirect.completeRoute.template)} render={() => <InternalRedirect to={links.currentUserDetailsPage.generateUrl()}/>}></ReloadableRoute>
                {renderPages(pages)}

                <RedirectAs404 />
            </Switch>
        </UserProfileLayout>);
}
function InsightsRoutes({ pages, childGroups }: {
    pages: typeof allPages.childGroups.space.childGroups.insights.pages;
    childGroups: typeof allPages.childGroups.space.childGroups.insights.childGroups;
    spaceId: string;
}) {
    const { insightsUpsellPage, ...rootInsightsPages } = pages;
    return (<Switch>
            {renderRedirect(allRoutes.childRouteSegments.space.childRouteSegments.insights.redirects.insightsRootRedirect, ({ spaceId }) => (<DefaultInsightsPageRedirect spaceId={spaceId}/>))}

            {renderPage(insightsUpsellPage)}
            {renderPages(pages)}

            {routeSegment(childGroups.reports, ({ spaceId }, reportsPages, reportsPagesChildren) => (<LicenseChecker spaceId={spaceId}>
                    <Switch>
                        {renderPages(reportsPages)}
                        {routeSegment(reportsPagesChildren.specificReport, ({ spaceId, reportId }, reportPages) => (<InsightsReportLoader spaceId={spaceId} reportId={reportId}>
                                {({ report, refreshReport }) => (<Switch>
                                        {renderInsightsReportPages(reportPages, report, refreshReport)}
                                        <RedirectAs404 />
                                    </Switch>)}
                            </InsightsReportLoader>))}
                    </Switch>
                </LicenseChecker>))}
        </Switch>);
}
function renderInsightsReportPages(pages: typeof allPages.childGroups.space.childGroups.insights.childGroups.reports.childGroups.specificReport.pages, report: InsightsReportResource, refreshReport: () => Promise<void>) {
    return Object.values(pages).map((p: UnknownInsightsReportPageRegistration) => renderInsightsReportPage(p, report, refreshReport));
}
function renderInsightsReportPage<RouteParams, QueryParams extends UnknownQueryParam[]>(pageRegistration: InsightsReportPageRegistration<RouteParams, QueryParams>, report: InsightsReportResource, refreshReport: () => Promise<void>) {
    const routePath = formatRoutePath(pageRegistration.route.template);
    return (<RoutePage key={routePath} path={routePath} exact={true} pageRegistration={pageRegistration} render={(routeParams, queryParams, setQueryParams) => pageRegistration.render(report, refreshReport, routeParams, queryParams, setQueryParams)}/>);
}
function TenantRoutes({ tenantsPages, tenantsPageGroups }: {
    tenantsPages: typeof allPages.childGroups.space.childGroups.tenants.pages;
    tenantsPageGroups: typeof allPages.childGroups.space.childGroups.tenants.childGroups;
}) {
    return (<Switch>
            {routeSegment(tenantsPageGroups.specificTenant, ({ spaceId, tenantId }, specificTenantPages) => (<TenantLayout spaceId={spaceId} tenantId={tenantId}>
                    <Switch>
                        {renderPages(specificTenantPages)}
                        <RedirectAs404 />
                    </Switch>
                </TenantLayout>))}
            {/*The tenant root pages need to be rendered after more specific pages to allow those to be matched first. */}
            {renderPages(tenantsPages)}
            <RedirectAs404 />
        </Switch>);
}
function renderPage<RouteParams, QueryParams extends UnknownQueryParam[]>(pageRegistration: PageRegistrationWithoutLayout<RouteParams, QueryParams>) {
    const routePath = formatRoutePath(pageRegistration.route.template);
    return <RoutePage key={routePath} path={routePath} exact={true} pageRegistration={pageRegistration} render={(routeParams, queryParams, setQueryParams) => pageRegistration.render(routeParams, queryParams, setQueryParams)}/>;
}
function renderPageWithLevel1Layout<RouteParams, QueryParams extends UnknownQueryParam[]>(pageRegistration: PageRegistrationWithLevel1Layout<RouteParams, QueryParams>, PageLayout: ComponentType<Level1PageLayoutProps>) {
    const routePath = formatRoutePath(pageRegistration.route.template);
    return <RoutePage key={routePath} path={routePath} exact={true} pageRegistration={pageRegistration} render={(routeParams, queryParams, setQueryParams) => pageRegistration.render(PageLayout, routeParams, queryParams, setQueryParams)}/>;
}
function renderPageWithLevel2Layout<RouteParams, QueryParams extends UnknownQueryParam[]>(pageRegistration: PageRegistrationWithLevel2Layout<RouteParams, QueryParams>, PageLayout: ComponentType<Level2PageLayoutProps>) {
    const routePath = formatRoutePath(pageRegistration.route.template);
    return <RoutePage key={routePath} path={routePath} exact={true} pageRegistration={pageRegistration} render={(routeParams, queryParams, setQueryParams) => pageRegistration.render(PageLayout, routeParams, queryParams, setQueryParams)}/>;
}
// TODO: Can we constrain these types better?
function renderPages<PageRegistrations extends Record<string, UnknownPageRegistration>>(pageRegistrations: PageRegistrations) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return Object.values(pageRegistrations).map((p) => renderPage(p as UnknownPageRegistrationWithoutLayout));
}
function renderPagesWithLevel1Layout<PageRegistrations extends Record<string, UnknownPageRegistration>>(pageRegistrations: PageRegistrations, PageLayout: ComponentType<Level1PageLayoutProps>) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return Object.values(pageRegistrations).map((p) => renderPageWithLevel1Layout(p as UnknownPageRegistrationWithLevel1Layout, PageLayout));
}
// TODO: Can we constrain these types better?
function renderPagesWithLevel2Layout<PageRegistrations extends Record<string, UnknownPageRegistration>>(pageRegistrations: PageRegistrations, PageLayout: ComponentType<Level2PageLayoutProps>) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return Object.values(pageRegistrations).map((p) => renderPageWithLevel2Layout(p as UnknownPageRegistrationWithLevel2Layout, PageLayout));
}
function renderRedirect<TRouteParams>(path: RedirectRouteDefinition<TRouteParams>, render: (params: TRouteParams) => React.ReactNode) {
    const formattedPath = formatRoutePath(path.completeRoute.template);
    return <ReloadableRoute key={formattedPath} path={formattedPath} exact={true} render={({ match }) => render({ ...match.params })}/>;
}
interface PageRegistrationWithoutLayout<RouteParams, QueryParams extends UnknownQueryParam[]> extends PageRegistration<RouteParams, QueryParams> {
    render: (routeParams: RouteParams, queryParams: QueryParamValues<QueryParams>, setQueryParams: QueryParamValuesSetter<QueryParamValues<QueryParams>>) => React.ReactNode;
}
interface PageRegistrationWithLevel1Layout<RouteParams, QueryParams extends UnknownQueryParam[]> extends PageRegistration<RouteParams, QueryParams> {
    render: (PageLayout: ComponentType<Level1PageLayoutProps>, routeParams: RouteParams, queryParams: QueryParamValues<QueryParams>, setQueryParams: QueryParamValuesSetter<QueryParamValues<QueryParams>>) => React.ReactNode;
}
interface PageRegistrationWithLevel2Layout<RouteParams, QueryParams extends UnknownQueryParam[]> extends PageRegistration<RouteParams, QueryParams> {
    render: (PageLayout: ComponentType<Level2PageLayoutProps>, routeParams: RouteParams, queryParams: QueryParamValues<QueryParams>, setQueryParams: QueryParamValuesSetter<QueryParamValues<QueryParams>>) => React.ReactNode;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnknownPageRegistrationWithLevel2Layout = PageRegistrationWithLevel2Layout<any, UnknownQueryParam[]>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnknownPageRegistrationWithLevel1Layout = PageRegistrationWithLevel1Layout<any, UnknownQueryParam[]>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnknownPageRegistrationWithoutLayout = PageRegistrationWithoutLayout<any, UnknownQueryParam[]>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnknownInsightsReportPageRegistration = InsightsReportPageRegistration<any, UnknownQueryParam[]>;
