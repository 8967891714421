import { css } from "@emotion/css";
import { space, themeTokens, borderRadius, fontSize, lineHeight, letterSpacing, text } from "@octopusdeploy/design-system-tokens";
import type { InitialDashboardProjectGroup, DetailedDashboardProjectGroup, DetailedDashboardItemResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import TaskStatusIcon from "~/areas/projects/components/TaskStatusIcon";
interface ProjectGroupsProps {
    projectGroups: InitialDashboardProjectGroup[] | DetailedDashboardProjectGroup[];
}
export function DashboardProjectGroups({ projectGroups }: ProjectGroupsProps) {
    return (<div className={projectGroupsStyles}>
            {projectGroups.map((group) => (<DashboardProjectGroup key={group.Id} projectGroup={group}/>))}
        </div>);
}
interface DashboardProjectGroupProps {
    projectGroup: InitialDashboardProjectGroup | DetailedDashboardProjectGroup;
}
const projectGroupsStyles = css({
    display: "flex",
    flexDirection: "column",
    gap: space[24],
});
function DashboardProjectGroup({ projectGroup }: DashboardProjectGroupProps) {
    return (<div className={projectGroupStyles}>
            <ProjectGroupHeader title={projectGroup.Name} projectCount={projectGroup.Projects.length}/>
            <ProjectGroupDeploymentsTable group={projectGroup}/>
        </div>);
}
const projectGroupStyles = css({
    display: "flex",
    flexDirection: "column",
});
const projectGroupHeaderStyles = {
    root: css({
        display: "flex",
        flexDirection: "column",
        border: `solid 1px ${themeTokens.color.border.primary}`,
        borderTopLeftRadius: borderRadius.large,
        borderTopRightRadius: borderRadius.large,
        padding: `${space[16]} ${space[16]} ${space[12]} ${space[16]}`,
    }),
    groupName: css({
        fontSize: fontSize.base,
        lineHeight: lineHeight.small,
        letterSpacing: letterSpacing.wide,
    }),
};
interface ProjectGroupHeaderProps {
    title: string;
    description?: string;
    projectCount: number;
}
function ProjectGroupHeader({ title, description, projectCount }: ProjectGroupHeaderProps) {
    return (<div className={projectGroupHeaderStyles.root}>
            <h1 className={projectGroupHeaderStyles.groupName}>{title}</h1>
            {description && <p>{description}</p>}
        </div>);
}
interface ProjectGroupDeploymentsTableProps {
    group: InitialDashboardProjectGroup | DetailedDashboardProjectGroup;
}
const projectGroupDeploymentsTableStyles = {
    root: css({
        border: `solid 1px ${themeTokens.color.border.primary}`,
        borderTop: "none",
    }),
    table: css({
        width: "100%",
    }),
};
function isDetailedDashboardProjectGroup(group: InitialDashboardProjectGroup | DetailedDashboardProjectGroup): group is DetailedDashboardProjectGroup {
    return group && "Environments" in group && Boolean(group.Environments);
}
function ProjectGroupDeploymentsTable({ group }: ProjectGroupDeploymentsTableProps) {
    return <div className={projectGroupDeploymentsTableStyles.root}>{isDetailedDashboardProjectGroup(group) ? <ProjectGroupDetailedDeploymentsTable group={group}/> : <ProjectGroupInitialDeploymentsTable group={group}/>}</div>;
}
interface ProjectGroupDetailedDeploymentsTableProps {
    group: DetailedDashboardProjectGroup;
}
function ProjectGroupDetailedDeploymentsTable({ group }: ProjectGroupDetailedDeploymentsTableProps) {
    return (<table className={projectGroupDeploymentsTableStyles.table}>
            <tr>
                <th>&nbsp;</th>
                {group.Environments.map((environment) => (<EnvironmentColumnHeader key={environment.Id} name={environment.Name}/>))}
            </tr>
            {group.Projects.map((project) => (<tr key={project.Id}>
                    <th>{project.Name}</th>
                    {project.Environments.map((environment) => {
                const latestDeployment = environment.Deployments[environment.Deployments.length - 1];
                if (!latestDeployment) {
                    return (<td key={environment.Id}>
                                    <NoData />
                                </td>);
                }
                return (<td key={latestDeployment.DeploymentId}>
                                <DeploymentTaskStatus deployment={latestDeployment}/>
                            </td>);
            })}
                </tr>))}
        </table>);
}
interface ProjectGroupInitialDeploymentsTableProps {
    group: InitialDashboardProjectGroup;
}
function ProjectGroupInitialDeploymentsTable({ group }: ProjectGroupInitialDeploymentsTableProps) {
    return (<table className={projectGroupDeploymentsTableStyles.table}>
            <tr>
                <th>&nbsp;</th>
            </tr>
            {group.Projects.map((project) => (<tr key={project.Id}>
                    <td>{project.Name}</td>
                </tr>))}
        </table>);
}
function NoData() {
    return <>No Data</>;
}
interface EnvironmentColumnHeaderProps {
    name: string;
}
const environmentColumnHeaderStyles = css({
    color: themeTokens.color.text.tertiary,
    font: text.table.sort.xSmall,
    letterSpacing: letterSpacing.wider,
    textTransform: "uppercase",
    textAlign: "start",
});
function EnvironmentColumnHeader(props: EnvironmentColumnHeaderProps) {
    return <th className={environmentColumnHeaderStyles}>{props.name}</th>;
}
const deploymentTaskStatusStyles = {
    root: css({
        display: "flex",
        flexDirection: "row",
    }),
    icon: css({
        flex: "auto",
        display: "flex",
    }),
    task: css({
        display: "flex",
        flexDirection: "column",
        flex: 1,
    }),
};
function DeploymentTaskStatus({ deployment }: {
    deployment: DetailedDashboardItemResource;
}) {
    return (<div className={deploymentTaskStatusStyles.root}>
            <div className={deploymentTaskStatusStyles.icon}>
                <TaskStatusIcon item={deployment} smallIcon={false}/>
            </div>
            <div className={deploymentTaskStatusStyles.task}>
                <span></span>
            </div>
        </div>);
}
