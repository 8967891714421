import type { Logger } from "@octopusdeploy/logging";
import { logger } from "@octopusdeploy/logging";
import type { AuthenticationProviderElement, LoginState } from "@octopusdeploy/octopus-server-client";
import { OctopusError, AuthenticationError } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import $script from "scriptjs";
import { client } from "~/clientInstance";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import extensionManager from "~/extensionsManager";
interface AuthProviderInternalProps {
    provider: AuthenticationProviderElement;
    loginState: LoginState;
    shouldAutoSignIn: boolean;
    autoSignInProviderName: string;
    onError(error: AuthenticationError): void;
    isDarkMode: boolean;
}
class AuthProviderInternal extends React.Component<AuthProviderInternalProps, {}> {
    rootNode: React.RefObject<HTMLDivElement> = React.createRef();
    async componentDidMount() {
        const { provider, loginState, shouldAutoSignIn, autoSignInProviderName, isDarkMode } = this.props;
        const contextualLogger = logger.forContext({ provider, loginState, shouldAutoSignIn, autoSignInProviderName });
        await Promise.all(loadCSS(provider.CSSLinks, contextualLogger));
        $script(provider.JavascriptLinks.map((link) => loadJS(link, contextualLogger)), provider.Name);
        $script.ready(provider.Name, () => {
            const extension = extensionManager.getExtension(provider.Name, "auth_provider");
            if (!extension) {
                logger.error("Could not find authentication {providerName} extension", { providerName: provider.Name });
            }
            //Changes of dark mode won't get reflected by the extension, however this is
            //acceptable for now since we can't change the theme on the login page. Once this
            //changes, we will need to call this multiple times.
            const authProvider = extension(client, provider, loginState, this.onExtensionError, isDarkMode);
            if (!shouldAutoSignIn) {
                const node = this.rootNode.current;
                if (node) {
                    node.innerHTML = authProvider.LinkHtml;
                    node.addEventListener("click", authProvider.SignIn);
                }
            }
            if (shouldAutoSignIn && provider.Name === autoSignInProviderName) {
                authProvider.SignIn();
            }
        });
    }
    render() {
        return <div ref={this.rootNode}/>;
    }
    private onExtensionError = (error: OctopusError | Error) => {
        if (error instanceof OctopusError) {
            this.props.onError(new AuthenticationError(error.ErrorMessage, error.Errors, error.ParsedHelpLinks));
        }
        else {
            this.props.onError(new AuthenticationError(error.message));
        }
    };
    static displayName = "AuthProviderInternal";
}
type AuthProviderProps = Omit<AuthProviderInternalProps, "isDarkMode">;
function AuthProvider(props: AuthProviderProps) {
    const themePalette = useThemePaletteType();
    return <AuthProviderInternal {...props} isDarkMode={themePalette === "dark"}/>;
}
function loadCSS(urls: string[], contextualLogger: Logger): Promise<void>[] {
    return urls.map((url) => {
        contextualLogger.info("Loading CSS {url}", { url });
        return new Promise<void>((resolve, reject) => {
            const linkElement = document.createElement("link");
            linkElement.setAttribute("rel", "stylesheet");
            linkElement.setAttribute("type", "text/css");
            linkElement.setAttribute("href", client.resolve(url));
            document.getElementsByTagName("head")[0].appendChild(linkElement);
            resolve();
        });
    });
}
function loadJS(link: string, contextualLogger: Logger): string {
    contextualLogger.info("Loading JS {link}", { link });
    return client.resolve(link);
}
export default AuthProvider;
