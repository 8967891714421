import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
export const getImportExportMenuItems = (spaceId: string) => [
    OverflowMenuItems.navItem("Import Projects", links.importProjectsPage.generateUrl({ spaceId })),
    OverflowMenuItems.navItem("Export Projects", links.exportProjectsPage.generateUrl({ spaceId })),
    OverflowMenuItems.navItem("View Import Export Tasks", links.importExportProjectTasksPage.generateUrl({ spaceId })),
];
interface ImportExportMenuProps {
    spaceId: string;
}
export function ImportExportMenu({ spaceId }: ImportExportMenuProps) {
    return <OverflowMenu tabIndex={-1} menuItems={getImportExportMenuItems(spaceId)}/>;
}
