import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { Route } from "react-router-dom";
import { NotFound, stateFor404Redirect } from "~/components/NotFound/NotFound";
import type { SpaceContext } from "~/components/SpaceLoader/SpaceLoader";
import { AllPageRoutes } from "~/routing/AllPageRoutes";
interface RootRoutesProps extends RouteComponentProps<{}> {
    spaceContext: SpaceContext;
}
function RootRoutes({ spaceContext }: RootRoutesProps) {
    return (<Route render={({ location }) => {
            if (location.state === stateFor404Redirect) {
                return <NotFound />;
            }
            return <AllPageRoutes spaceContext={spaceContext}/>;
        }}/>);
}
export default withRouter(RootRoutes);
