import type { PageRouteDefinition, RouteSegment, RouteTemplate, UnknownQueryParam, RedirectRouteDefinition } from "@octopusdeploy/portal-routes";
import type { PageRegistration } from "~/routing/pageRegistrations/PageRegistration";
export function createPageMap<RouteParams, ChildRouteSegments extends RouteSegmentsConstraint, ChildPages extends PagesConstraint, Redirects extends RedirectsConstraint, PageRegistrationDefinitions extends PageGroupRegistrationDefinition<RouteParams, ChildRouteSegments, ChildPages>>(routeSegment: RouteSegment<RouteParams, ChildRouteSegments, ChildPages, Redirects>, pageRegistrations: PageRegistrationDefinitions): PageGroupRegistration<PageRegistrationDefinitions> {
    const childGroups = Object.entries(routeSegment.childRouteSegments).reduce<PageGroupsRegistration<PageRegistrationDefinitions["childGroups"]>>((p, [key, childRouteSegment]) => ({
        ...p,
        [key]: createPageMap(childRouteSegment, pageRegistrations.childGroups[key]),
    }), 
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    {} as PageGroupsRegistration<PageRegistrationDefinitions["childGroups"]>);
    const pages = Object.entries(routeSegment.pages).reduce<Pages<PageRegistrationDefinitions["pages"]>>((p, [key, pageRoute]) => ({
        ...p,
        [key]: pageRegistrations.pages[key](pageRoute),
    }), 
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    {} as Pages<PageRegistrationDefinitions["pages"]>);
    return {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        partialRoute: pageRegistrations.partialRoute(routeSegment.partialRoute) as RouteTemplate<ExtractRouteParams<PageRegistrationDefinitions["partialRoute"]>>,
        childGroups,
        pages,
    };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PageGroupsRegistration<PageGroups extends Record<string, PageGroupRegistrationDefinition<any, any, any>>> = {
    [K in keyof PageGroups]: PageGroupRegistration<PageGroups[K]>;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PageGroupRegistration<RegistrationDefinition extends PageGroupRegistrationDefinition<any, any, any>> = PageGroup<ExtractRouteParams<RegistrationDefinition["partialRoute"]>, PageGroupsRegistration<RegistrationDefinition["childGroups"]>, Pages<RegistrationDefinition["pages"]>>;
export interface PageGroup<RouteParams, ChildGroups, Pages> {
    partialRoute: RouteTemplate<RouteParams>;
    childGroups: ChildGroups;
    pages: Pages;
}
type Pages<Pages> = {
    [K in keyof Pages]: Page<Pages[K]>;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Page<SinglePageRegistration> = SinglePageRegistration extends PageRegistrationDefinition<any, any, infer ConcreteRegistration> ? ConcreteRegistration : never;
export interface PageGroupRegistrationDefinition<RouteParams, RouteSegments, Pages> {
    partialRoute: PartialRouteGetter<RouteParams>;
    pages: ChildPages<Pages>;
    childGroups: PageGroupsRegistrationDefinititons<RouteSegments>;
}
type PageGroupsRegistrationDefinititons<RouteSegments> = {
    [K in keyof RouteSegments]: RouteSegments[K] extends RouteSegment<infer ChildRouteParams, infer ChildRouteSegments, infer ChildPages, any> ? PageGroupRegistrationDefinition<ChildRouteParams, ChildRouteSegments, ChildPages> : never;
};
type ChildPages<Pages> = {
    [K in keyof Pages]: Pages[K] extends PageRouteDefinition<infer RouteParams, infer QueryParams> ? PageRegistrationDefinition<RouteParams, QueryParams, any> : never;
};
type ExtractRouteParams<PartialRouteGetterType> = PartialRouteGetterType extends PartialRouteGetter<infer RouteParams> ? RouteParams : never;
type PartialRouteGetter<RouteParams> = (partialRoute: RouteTemplate<RouteParams>) => RouteTemplate<RouteParams>;
type PageRegistrationDefinition<RouteParams, QueryParams extends UnknownQueryParam[], ConcretePageRegistration extends PageRegistration<RouteParams, QueryParams>> = (route: PageRouteDefinition<RouteParams, QueryParams>) => ConcretePageRegistration;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PageConstraint = PageRouteDefinition<any, any>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RouteSegmentConstraint = RouteSegment<any, any, any, any>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RedirectConstraint = RedirectRouteDefinition<any>;
export type RouteSegmentsConstraint = Record<string, RouteSegmentConstraint>;
export type PagesConstraint = Record<string, PageConstraint>;
type RedirectsConstraint = Record<string, RedirectConstraint>;
