import { ActionButtonType } from "@octopusdeploy/design-system-components";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { VariableSetContentType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { useCommonTemplatesFilterState } from "~/areas/projects/components/Variables/TenantVariables/useCommonTemplatesFilterState";
import VariableSetSelector from "~/areas/variables/VariableSetSelector/index";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
interface AddProjectTemplateButtonProps {
    project: ProjectResource;
    doBusyTask: DoBusyTask;
    openSnackbar: (text: string) => void;
}
export function IncludeLibraryVariableSetsButton({ project, doBusyTask, openSnackbar }: AddProjectTemplateButtonProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const [filterState, setFilterState] = useCommonTemplatesFilterState();
    const handleSelection = async (selectedSets: ReadonlyArray<string>) => {
        await doBusyTask(async () => {
            const allSets = await loadAllVariableSetIdentifiers();
            const nonVariableSets = project.IncludedLibraryVariableSetIds.filter((set) => !allSets.has(set));
            await repository.Projects.modify({ ...project, IncludedLibraryVariableSetIds: [...selectedSets, ...nonVariableSets] });
            openSnackbar("Included library variable sets updated");
            dispatchAction("Include Library Variable Sets", { resource: "Variable", action: Action.Add });
            reloadVariables();
        });
    };
    const reloadVariables = () => {
        setFilterState({
            ...filterState,
            filterByLibraryVariableSetId: undefined,
            pageNumber: 1,
        });
    };
    return (<OpenDialogButton label={"Include Library Variable Sets"} type={ActionButtonType.Secondary} accessibleName={"Include library variable sets"}>
            <VariableSetSelector spaceId={project.SpaceId} selectedVariableSetIds={project.IncludedLibraryVariableSetIds} saveVariableSetsSelection={handleSelection}/>
        </OpenDialogButton>);
}
async function loadAllVariableSetIdentifiers() {
    const allIdentifiers = await repository.LibraryVariableSets.all({ contentType: VariableSetContentType.Variables });
    return new Set(allIdentifiers.map((set) => set.Id));
}
