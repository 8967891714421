import type { ResourceCollection, OctopusPackageVersionBuildInformationMappedResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import BaseComponent from "~/components/BaseComponent";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
import PagingDataTable from "~/components/PagingDataTable";
import styles from "./style.module.less";
class BuildInformationResourceDataTable extends PagingDataTable<OctopusPackageVersionBuildInformationMappedResource> {
}
interface LatestBuildInformationListProps {
    spaceId: string;
    initialPackages: ResourceCollection<OctopusPackageVersionBuildInformationMappedResource>;
}
export class LatestBuildInformationList extends BaseComponent<LatestBuildInformationListProps> {
    render() {
        return (!!this.props.initialPackages && (<BuildInformationResourceDataTable initialData={this.props.initialPackages} onRow={(item) => this.buildRow(item)} onFilter={this.filter} filterSearchEnabled={true} apiSearchParams={["filter"]} headerColumns={["Package ID", "Highest version", "Source", "Branch", "Build"]} headerColumnClassNames={["", ""]} onEmpty={this.handleOnEmpty}/>));
    }
    private handleOnEmpty = () => {
        return <div>No build information found</div>;
    };
    private filter(filter: string, resource: OctopusPackageVersionBuildInformationMappedResource) {
        return !filter || filter.length === 0 || (resource.PackageId ? resource.PackageId.toLowerCase().includes(filter.toLowerCase()) : false);
    }
    private buildRow = (resource: OctopusPackageVersionBuildInformationMappedResource) => {
        return [
            <InternalLink to={links.buildInformationVersionsPage.generateUrl({ spaceId: this.props.spaceId, packageId: resource.PackageId })}>{resource.PackageId}</InternalLink>,
            <div className={styles.row}>
                <InternalLink to={links.buildInformationDetailPage.generateUrl({ spaceId: this.props.spaceId, id: resource.Id })}>{resource.Version}</InternalLink>
            </div>,
            resource.BuildEnvironment,
            resource.Branch,
            <ExternalLink href={resource.BuildUrl}>{resource.BuildNumber}</ExternalLink>,
        ];
    };
    static displayName = "LatestBuildInformationList";
}
export default LatestBuildInformationList;
