import * as React from "react";
import Tasks from "~/areas/tasks/components/Tasks/Tasks";
import PaperLayout from "~/components/PaperLayout";
interface TenantTasksProps {
    tenantId: string;
}
export const TenantTasksPage: React.FC<TenantTasksProps> = ({ tenantId }) => {
    return (<Tasks restrictToTenantId={tenantId} renderLayout={({ busy, errors, hasLoadedOnce, children }) => {
            return (<PaperLayout title={"Tasks"} busy={busy} enableLessIntrusiveLoadingIndicator={hasLoadedOnce} errors={errors} fullWidth={true}>
                        {children}
                    </PaperLayout>);
        }}/>);
};
TenantTasksPage.displayName = "TenantTasksPage"
