import type { InsightsReportResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { InsightsReportLoadingLayout } from "~/areas/insights/components/InsightsReportLayout/InsightsReportLayout";
import { repository } from "~/clientInstance";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
interface RenderProps {
    report: InsightsReportResource;
    refreshReport: () => Promise<void>;
}
interface InsightsReportLoaderProps {
    spaceId: string;
    reportId: string;
    children: (renderProps: RenderProps) => React.ReactNode;
}
interface State {
    report: InsightsReportResource | null;
}
export class InsightsReportLoader extends DataBaseComponent<InsightsReportLoaderProps, State> {
    constructor(props: InsightsReportLoaderProps) {
        super(props);
        this.state = {
            report: null,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(() => this.load(), { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    async refreshReport() {
        await this.doBusyTask(() => this.load(), { timeOperationOptions: timeOperationOptions.for("RefreshByLayout") });
    }
    private async load() {
        const report = await repository.InsightsReports.get(this.props.reportId);
        this.setState({ report });
    }
    render() {
        return this.state.report ? this.props.children({ report: this.state.report, refreshReport: () => this.refreshReport() }) : <InsightsReportLoadingLayout spaceId={this.props.spaceId} busy={Boolean(this.state.busy)} errors={this.errors}/>;
    }
    static displayName = "InsightsReportLoader";
}
