import { isEqual } from "lodash";
import * as React from "react";
import { QueryStringFilters } from "~/components/QueryStringFilters/QueryStringFilters";
import { useRequiredContext } from "~/hooks";
import type { ProcessFilter, ProcessQuery } from "../../types";
interface ProcessQueryStringContextLookupState {
    queryFilter: ProcessFilter;
}
export type ProcessQueryStringContextProps = {
    state: ProcessQueryStringContextLookupState;
    actions: ProcessQueryStringContextProviderSetupActions;
};
export const ProcessQueryStringContext = React.createContext<ProcessQueryStringContextProps | undefined>(undefined);
export const useProcessQueryStringContext = () => {
    return useRequiredContext(ProcessQueryStringContext, "ProcessQueryString");
};
export const useOptionalProcessQueryStringContext = () => {
    return React.useContext(ProcessQueryStringContext);
};
const useProcessState = (initialQueryFilter: ProcessFilter) => {
    return React.useState<ProcessQueryStringContextLookupState>({
        queryFilter: initialQueryFilter,
    });
};
const getStateUpdaters = (setState: React.Dispatch<React.SetStateAction<ProcessQueryStringContextLookupState>>) => {
    return {
        onQueryFilterChange: (callback: (prev: ProcessFilter) => ProcessFilter) => setState((current) => ({ ...current, queryFilter: callback(current.queryFilter) })),
    };
};
export interface ProcessQueryStringContextProviderSetup {
    state: ProcessQueryStringContextLookupState;
    setState: React.Dispatch<React.SetStateAction<ProcessQueryStringContextLookupState>>;
    actions: ProcessQueryStringContextProviderSetupActions;
}
export interface ProcessQueryStringContextProviderSetupActions {
    onQueryFilterChange: (callback: (prev: ProcessFilter) => ProcessFilter) => void;
    showEmptyStepEditor: () => void;
    showProcessStepTemplates: () => void;
    showProcessAction: (actionId: string) => void;
    showProcessParentStep: (parentStepId: string) => void;
}
interface ProcessQueryStringControllerProps {
    initialQueryFilter: ProcessFilter;
    children: (renderProps: ProcessQueryStringContextProps) => React.ReactNode;
}
const ProcessQueryStringFilters = QueryStringFilters.For<ProcessFilter, ProcessQuery>();
export const ProcessQueryStringController: React.FC<ProcessQueryStringControllerProps> = ({ children, initialQueryFilter }) => {
    const [lookupsState, setState] = useProcessState(initialQueryFilter);
    const stateUpdaters = React.useMemo(() => getStateUpdaters(setState), [setState]);
    const showEmptyStepEditor = () => {
        const filter = createDefaultFilter();
        stateUpdaters.onQueryFilterChange((prev) => ({ ...prev, ...filter }));
    };
    const showProcessStepTemplates = () => {
        const filter = createDefaultFilter();
        filter.stepTemplates = "1";
        stateUpdaters.onQueryFilterChange((prev) => ({ ...prev, ...filter }));
    };
    const showProcessAction = (actionId: string) => {
        const filter = createDefaultFilter();
        filter.actionId = actionId;
        stateUpdaters.onQueryFilterChange((prev) => ({ ...prev, ...filter }));
    };
    const showProcessParentStep = (parentStepId: string) => {
        const filter = createDefaultFilter();
        filter.parentStepId = parentStepId;
        stateUpdaters.onQueryFilterChange((prev) => ({ ...prev, ...filter }));
    };
    const contextValue: ProcessQueryStringContextProps = {
        state: lookupsState,
        actions: {
            onQueryFilterChange: stateUpdaters.onQueryFilterChange,
            showEmptyStepEditor,
            showProcessStepTemplates,
            showProcessAction,
            showProcessParentStep,
        },
    };
    const onFilterUpdated = (filter: ProcessFilter) => {
        if (!isEqual(filter, lookupsState.queryFilter)) {
            stateUpdaters.onQueryFilterChange((prev) => ({ ...prev, ...filter }));
        }
    };
    return (<ProcessQueryStringContext.Provider value={contextValue}>
            <ProcessQueryStringFilters filter={lookupsState.queryFilter} getQuery={queryFromFilters} getFilter={getFilterFromQuery} onFilterChange={(filter) => onFilterUpdated(filter)}/>
            {children(contextValue)}
        </ProcessQueryStringContext.Provider>);
};
ProcessQueryStringController.displayName = "ProcessQueryStringController"
export const createDefaultFilter = (): ProcessFilter => {
    return {
        actionId: "",
        actionType: "",
        reloadKey: "",
        stepTemplates: "",
        childStepTemplates: "",
        templateId: "",
        parentStepId: "",
        new: "",
    };
};
const getFilterFromQuery = (query: ProcessQuery): ProcessFilter => {
    return {
        actionId: query.actionId || "",
        actionType: query.actionType || "",
        reloadKey: query.reloadKey || "",
        stepTemplates: query.stepTemplates || "",
        childStepTemplates: query.childStepTemplates || "",
        templateId: query.templateId || "",
        parentStepId: query.parentStepId || "",
        new: query.new || "",
    };
};
const queryFromFilters = (filter: ProcessFilter): ProcessQuery => {
    return {
        actionId: filter.actionId,
        actionType: filter.actionType,
        reloadKey: filter.reloadKey,
        stepTemplates: filter.stepTemplates,
        childStepTemplates: filter.childStepTemplates,
        templateId: filter.templateId,
        parentStepId: filter.parentStepId,
        new: filter.new,
    };
};
export interface WithProcessQueryStringContextInjectedProps {
    processQueryStringContext: ProcessQueryStringContextProps;
}
export const withProcessQueryStringContext = <T>(Component: React.ComponentType<T & WithProcessQueryStringContextInjectedProps>) => {
    const WithProcessQueryStringContext: React.FC<T> = (props) => {
        const context = useProcessQueryStringContext();
        return <Component processQueryStringContext={context} {...props}/>;
    };
    WithProcessQueryStringContext.displayName = "WithProcessQueryStringContext"
    return WithProcessQueryStringContext;
};
