import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import type { NotificationItem } from "~/components/Navbar/notifications.redux";
import { notificationActions, NotificationType } from "~/components/Navbar/notifications.redux";
import { getCurrentValue } from "./notificationLocalState";
const notificationId = "Notification/PageLayoutUplift";
function usePageLayoutUpliftNotification(): NotificationItem | undefined {
    const notificationWasRead = getCurrentValue(notificationId);
    const pageLayoutUpliftNotificationsAreEnabled = isFeatureToggleEnabled("PageHeaderUpliftEverywhereFeatureToggle");
    if (!pageLayoutUpliftNotificationsAreEnabled || notificationWasRead) {
        return undefined;
    }
    return {
        id: notificationId,
        text: "Page layout improvements",
        description: "We've updated our layouts to make your experience more intuitive. Let us know your thoughts in the feedback link below.",
        links: [
            { type: "External", label: "Learn more", href: "https://oc.to/yuPr3P" },
            { type: "External", label: "Feedback", href: "https://oc.to/fcLHgH" },
        ],
        type: NotificationType.info,
    };
}
export default function PageLayoutUpliftNotifications() {
    const dispatch = useDispatch();
    const notification = usePageLayoutUpliftNotification();
    useEffect(() => {
        if (notification !== undefined) {
            dispatch(notificationActions.raiseNotification(notification));
        }
    }, [notification, dispatch]);
    return <></>;
}
