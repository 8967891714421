/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { RouteArgs, DynamicExtensionsFeaturesMetadataResource, DynamicExtensionsScriptsResource } from "@octopusdeploy/octopus-server-client";
import _ from "lodash";
import { session, client, repository } from "~/clientInstance";
import type { OctopusEventName, EventHandler } from "./octopusDynamicExtensions";
import { OctopusEventSubscriptions } from "./octopusDynamicExtensions";
export class ScriptAdapter {
    subscriptions: Record<string, OctopusEventSubscriptions>;
    scriptsResource: DynamicExtensionsScriptsResource;
    metadataResource: DynamicExtensionsFeaturesMetadataResource;
    apiDocumentRootUrl: string;
    constructor(subscriptions: Record<string, OctopusEventSubscriptions>, scripts: DynamicExtensionsScriptsResource, metadata: DynamicExtensionsFeaturesMetadataResource, apiDocumentRootUrl: string) {
        this.subscriptions = subscriptions;
        this.scriptsResource = scripts;
        this.metadataResource = metadata;
        this.apiDocumentRootUrl = apiDocumentRootUrl;
    }
    public subscribeToEvent(eventName: OctopusEventName, handler: EventHandler): () => void {
        if (!this.subscriptions[eventName]) {
            const s = new OctopusEventSubscriptions();
            this.subscriptions[eventName] = s;
            return s.subscribe(handler);
        }
        else {
            return this.subscriptions[eventName].subscribe(handler);
        }
    }
    public getServerVersion(): string {
        return this.scriptsResource.ServerVersion;
    }
    public getServerApiVersion(): string {
        return this.scriptsResource.ServerApiVersion;
    }
    public getInstallationId(): string {
        return this.scriptsResource.InstallationId;
    }
    public getSerialHash(): string {
        return this.scriptsResource.SerialHash;
    }
    public getUserId(): string {
        if (!session.currentUser) {
            return null!;
        }
        return session.currentUser.Id;
    }
    public getApiDocumentRootUrl(): string {
        return this.apiDocumentRootUrl;
    }
    public apiGet<TResource>(path: string, args?: RouteArgs): Promise<TResource> {
        return client.get<TResource>(path, args);
    }
    public async getFeatureConfiguration(key: string): Promise<string> {
        const apiValue = await this.getFeatureConfigurationFromApi(key);
        return _.isNil(apiValue) ? this.getFeatureConfigurationFromDefaults(key) : apiValue;
    }
    private async getFeatureConfigurationFromApi(key: string): Promise<string> {
        const values = await repository.DynamicExtensions.getFeaturesValues();
        return values.Values[key];
    }
    private getFeatureConfigurationFromDefaults(key: string): string {
        const feature = this.metadataResource.Features.find((f) => f.Key === key);
        if (feature) {
            return feature.Default;
        }
        return undefined!;
    }
}
export default ScriptAdapter;
