import { stringQueryParam, useQueryStringParam } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { ControlledTabsContainer } from "./ControlledTabsContainer";
import type { RenderTabCallback } from "./useTabConversion";
export type UrlNavigationTabsContainerProps<TValue extends string = string> = React.PropsWithChildren<{
    defaultValue: TValue;
    urlParameterName?: string;
    renderTab?: RenderTabCallback;
}>;
export function UrlNavigationTabsContainer<TValue extends string>({ defaultValue, urlParameterName = "activeTab", renderTab, children }: UrlNavigationTabsContainerProps<TValue>) {
    const urlParameterNameParameter = stringQueryParam(urlParameterName);
    const [value, setValue] = useQueryStringParam(urlParameterNameParameter);
    return (<ControlledTabsContainer renderTab={renderTab} value={value ?? defaultValue} onChange={setValue}>
            {children}
        </ControlledTabsContainer>);
}
export default UrlNavigationTabsContainer;
