/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Callout } from "@octopusdeploy/design-system-components";
import type { ActionsUpdateProcessResource, ActionTemplateResource, ActionTemplateUsageResource, ActionUpdateResultResource, ProjectSummaryResource } from "@octopusdeploy/octopus-server-client";
import { ActionUpdateOutcome } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import { debounce, groupBy, isEqual, memoize, orderBy, reduce } from "lodash";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import type { AnyAction } from "redux";
import type { ThunkDispatch } from "redux-thunk";
import { repository } from "~/clientInstance";
import ConfirmationDialog from "~/components/Dialog/ConfirmationDialog";
import DialogOpener from "~/components/Dialog/DialogOpener";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import PaperLayout from "~/components/PaperLayout";
import { Section } from "~/components/Section/Section";
import type { LogoEditorSettings } from "~/components/form/LogoEditor/LogoEditor";
import { timeOperation, timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { actionTemplateFetch } from "../../reducers/libraryArea";
import type { ActionTemplateUsageVersionControlledFilter } from "./ActionTemplateUsageTable";
import { ActionTemplateUsageTable } from "./ActionTemplateUsageTable";
import MergeConflictResolutionDialog from "./MergeConflictResolutionDialog";
interface ActionTemplateModel {
    template: ActionTemplateResource;
    logo: LogoEditorSettings;
}
interface ActionTemplateUsagePageState extends OptionalFormBaseComponentState<ActionTemplateModel> {
    redirectTo: LinkHref;
    usages: ActionTemplateUsageResource[];
    pendingUpdates: number;
    isLoaded: boolean;
    showSaveAs: boolean;
    mergeDetails: {
        usages: ActionTemplateUsageResource[];
        mergeResults: ActionUpdateResultResource[];
    };
    showConfirmation: boolean;
    vcsUsagesExist: boolean;
    vcsProjects: ProjectSummaryResource[];
    projects: ProjectSummaryResource[];
}
interface ActionTemplateUsageInternalProps extends ActionTemplateUsagePageProps {
    usages: ActionTemplateUsageResource[];
    pendingUpdates: number;
    onFetchActionTemplate(actionTemplate: ActionTemplateResource): void;
}
class ActionTemplateUsagePageInternal extends FormBaseComponent<ActionTemplateUsageInternalProps, ActionTemplateUsagePageState, ActionTemplateModel> {
    constructor(props: ActionTemplateUsageInternalProps) {
        super(props);
        this.state = {
            redirectTo: null!,
            usages: props.usages,
            pendingUpdates: props.pendingUpdates,
            isLoaded: false,
            showSaveAs: false,
            mergeDetails: null!,
            showConfirmation: false,
            vcsUsagesExist: false,
            projects: null!,
            vcsProjects: null!,
        };
    }
    private getUsages = memoize(async (template: Partial<ActionTemplateResource>, args?: unknown) => orderBy(await repository.ActionTemplates.getUsage(template, args), [(x) => x.ProjectName, (x) => x.StepName]), (template: Partial<ActionTemplateResource>, args?: unknown) => JSON.stringify([template, args]));
    async componentDidMount() {
        await this.doBusyTask(() => {
            return timeOperation(timeOperationOptions.forInitialLoad(), () => this.initializeUsages());
        });
    }
    async componentDidUpdate(prevProps: ActionTemplateUsageInternalProps) {
        if (!isEqual(this.props.usages, prevProps.usages)) {
            await this.initializeUsages();
        }
    }
    async initializeUsages() {
        const vcsUsagesExist = !!this.props.usages && this.props.usages.filter((u) => u.Branch).length > 0;
        const vcsProjects = await repository.Projects.summariesVersionControlled();
        const projects = await repository.Projects.summaries();
        const model = await this.getExistingTemplate();
        this.setState({ ...this.state, pendingUpdates: this.props.pendingUpdates ?? 0, vcsUsagesExist, vcsProjects, projects, usages: this.props.usages ?? [], isLoaded: true, mergeDetails: null!, model });
    }
    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={false}/>;
        }
        const filter = debounce(async (f: ActionTemplateUsageVersionControlledFilter) => {
            if (this.state?.model?.template) {
                const usages = await this.getUsages(this.state?.model.template, f);
                this.setState({ ...this.state, ...{ usages } });
            }
        }, 800);
        return (<PaperLayout title="Usage" busy={this.state.busy} errors={this.errors}>
                {this.state.isLoaded && (<div>
                        <DialogOpener open={!!this.state.mergeDetails} onClose={() => this.load()} wideDialog={true}>
                            {this.state.mergeDetails && <MergeConflictResolutionDialog usages={this.state.mergeDetails.usages} mergeResults={this.state.mergeDetails.mergeResults} actionTemplate={this.state.model!.template}/>}
                        </DialogOpener>
                        <Section>
                            <p>
                                Current version: <b>{this.state.model!.template.Version}</b>
                            </p>
                            {this.state.pendingUpdates > 0 && (<Callout title={"Updates available"} type={"information"}>
                                    {this.state.pendingUpdates} step{this.state.pendingUpdates === 1 ? "" : "s"} may be using an old version of this template. Consider updating to get the latest changes.
                                </Callout>)}
                            <p>
                                {this.state.usages.length > 0 ? (<span>This template is in use by the following projects:</span>) : (<span>
                                        This template is not used by any projects. {this.state.vcsProjects.length ? "Note: For version controlled projects, step template usages are only shown for branches that have releases created from them." : ""}
                                    </span>)}
                            </p>
                        </Section>
                        <ConfirmationDialog title="Update all usages" continueButtonLabel="Update all" open={this.state.showConfirmation} onClose={() => this.setState({ showConfirmation: false })} onContinueClick={async () => this.handleUpdateAll(this.state.usages)}>
                            <p>Are you sure that you want to update all usages of this template?</p>
                        </ConfirmationDialog>
                        <ActionTemplateUsageTable spaceId={this.state.model!.template.SpaceId} templateVersion={this.state.model!.template.Version} usages={this.state.usages} onUpdateAction={this.handleUpdateAction} onUpdateAll={this.confirmUpdateAll} onFilterUsages={filter} showVcsUsages={this.state.vcsUsagesExist} projects={this.state.projects} vcsProjects={this.state.vcsProjects}/>
                    </div>)}
            </PaperLayout>);
    }
    private async getExistingTemplate(): Promise<ActionTemplateModel> {
        const actionTemplate = await repository.ActionTemplates.get(this.props.templateId);
        return {
            template: actionTemplate,
            logo: {
                file: null!,
                reset: false,
            },
        };
    }
    private handleUpdateAction = async (usage: ActionTemplateUsageResource) => {
        const updates: ActionsUpdateProcessResource[] = [{ ProcessId: usage.ProcessId, ProcessType: usage.ProcessType, ActionIds: [usage.ActionId] }];
        return this.updateActions(this.state.model!.template, updates, [usage]);
    };
    private confirmUpdateAll = async () => {
        this.setState({ showConfirmation: true });
    };
    private handleUpdateAll = async (usages: ActionTemplateUsageResource[]) => {
        this.setState({ showConfirmation: false });
        const actionTemplateVersion = this.state.model!.template.Version.toString();
        const usagesToUpdate = usages.filter((usage) => usage.Version !== actionTemplateVersion && !usage.Branch);
        const usagesByProcessId = groupBy(usagesToUpdate, (x) => x.ProcessId);
        const initialUpdateValue: ActionsUpdateProcessResource = null!;
        const updates = Object.keys(usagesByProcessId).reduce((prev: ActionsUpdateProcessResource[], processId) => {
            return [
                ...prev,
                reduce(usagesByProcessId[processId], (update, usage) => {
                    return !update ? { ProcessType: usage.ProcessType, ProcessId: usage.ProcessId, ActionIds: [usage.ActionId] } : { ...update, ActionIds: [...update.ActionIds, usage.ActionId] };
                }, initialUpdateValue),
            ];
        }, []);
        if (isFeatureToggleEnabled("UpdateStepTemplateUsageInTaskFeatureToggle")) {
            return this.bulkUpdateActions(this.state.model!.template, updates, usagesToUpdate);
        }
        else {
            return this.updateActions(this.state.model!.template, updates, usagesToUpdate);
        }
    };
    private updateActions = async (actionTemplate: Partial<ActionTemplateResource>, actionsToUpdate: ActionsUpdateProcessResource[], usagesToUpdate: ActionTemplateUsageResource[]) => {
        await this.doBusyTask(async () => {
            try {
                await repository.ActionTemplates.updateActions(actionTemplate, actionsToUpdate);
                await this.load();
            }
            catch (error) {
                if (error.StatusCode !== 400) {
                    throw error;
                }
                this.resolveMergeConflicts(usagesToUpdate, error.Details);
            }
        });
    };
    private bulkUpdateActions = async (actionTemplate: Partial<ActionTemplateResource>, actionsToUpdate: ActionsUpdateProcessResource[], usagesToUpdate: ActionTemplateUsageResource[]) => {
        await this.doBusyTask(async () => {
            const response = await repository.ActionTemplates.bulkUpdateActions(actionTemplate, actionsToUpdate);
            if (response.Outcome === ActionUpdateOutcome.Success && response.TaskId !== undefined) {
                this.setState({ redirectTo: links.taskPage.generateUrl({ taskId: response.TaskId }) });
                return;
            }
            if (response.Outcome !== ActionUpdateOutcome.Success) {
                this.resolveMergeConflicts(usagesToUpdate, response.Results);
            }
        });
    };
    private resolveMergeConflicts(usagesToUpdate: ActionTemplateUsageResource[], mergeResults: ActionUpdateResultResource[]) {
        this.setState({
            mergeDetails: { usages: usagesToUpdate, mergeResults },
        });
    }
    private load = async (refreshUsage: boolean = true, filter: string = "") => {
        await this.doBusyTask(async () => {
            if (!this.props.templateId) {
                this.setState({ redirectTo: links.stepTemplatesPage.generateUrl({ spaceId: this.props.spaceId }) });
                return;
            }
            const model = this.state.model ?? (await this.getExistingTemplate());
            this.setState({ model });
            if (refreshUsage) {
                this.props.onFetchActionTemplate(model.template);
            }
            else {
                let usages: ActionTemplateUsageResource[] = null!;
                let pendingUpdates = 0;
                if (model.template.Id) {
                    usages = await this.getUsages(model.template, filter);
                    pendingUpdates = usages.filter((u) => u.Version.toString() !== model.template.Version.toString()).length;
                }
                this.setState({
                    usages,
                    pendingUpdates,
                });
            }
            this.setState({
                mergeDetails: null!,
                isLoaded: true,
            });
        });
    };
    static displayName = "ActionTemplateUsagePageInternal";
}
interface ActionTemplateUsagePageProps {
    spaceId: string;
    templateId: string;
}
export function ActionTemplateUsagePage({ spaceId, templateId }: ActionTemplateUsagePageProps) {
    const dispatch: ThunkDispatch<GlobalState, void, AnyAction> = useDispatch();
    const currentActionTemplate = useSelector((state: GlobalState) => state.libraryArea.currentActionTemplate);
    const dispatchFetchActionTemplate = React.useCallback((actionTemplate: ActionTemplateResource) => {
        return dispatch(actionTemplateFetch(actionTemplate));
    }, [dispatch]);
    return <ActionTemplateUsagePageInternal spaceId={spaceId} templateId={templateId} usages={currentActionTemplate?.usages ?? []} pendingUpdates={currentActionTemplate?.pendingUpdates ?? 0} onFetchActionTemplate={dispatchFetchActionTemplate}/>;
}
