import type { GitReference } from "@octopusdeploy/octopus-server-client";
/**
 * Used to display the selected git reference for a release.
 */
export interface GitReferenceModel {
    ActionName: string;
    RepositoryUri: string;
    FilePathFilters: string[];
    GitResource: GitReference;
}
/**
 * Used when creating/editing a release.
 * Maps to ReleaseTemplateGitResource on the server.
 */
export interface GitReferenceEditInfo {
    ActionName: string;
    GitCredentialId?: string;
    RepositoryUri: string;
    DefaultBranch: string;
    IsResolvable: boolean;
    FilePathFilters: string[];
    LastGitResource?: GitReference;
    SpecificGitResource: GitReference;
    GitReferenceType: GitReferenceType;
}
export enum GitReferenceType {
    last = "last",
    specific = "specific"
}
