import { Tooltip } from "@octopusdeploy/design-system-components";
import { LicenseMessageDisposition, type LicenseLimitStatus } from "@octopusdeploy/octopus-server-client";
import React from "react";
import Card from "~/components/Card";
import type { OctopusTheme } from "~/components/Theme";
import styles from "./style.module.less";
interface UsageOverviewCardProps {
    licenseLimitStatus?: LicenseLimitStatus;
    theme: OctopusTheme;
}
const TASK_CAP_TEXT = "Task Cap";
const MACHINE_TEXT = "Machines";
const MACHINE_DESCRIPTION = "Machines include active Linux Tentacles, Windows Tentacles, and SSH connection deployment targets.";
const UsageOverviewCard: React.FC<UsageOverviewCardProps> = ({ licenseLimitStatus, theme }) => {
    if (!licenseLimitStatus)
        return null;
    const { EffectiveLimitDescription, CurrentUsage, Name, Disposition, Message } = licenseLimitStatus;
    const usageDisplay = Name === TASK_CAP_TEXT ? EffectiveLimitDescription : `${CurrentUsage}/${EffectiveLimitDescription}`;
    return (<Card className={styles.overviewItem} content={<>
                    <div className={styles.overviewCount}>
                        <span style={{ paddingRight: "0.5Rem" }}>{usageDisplay}</span>
                        {(Disposition === LicenseMessageDisposition.Warning || Disposition === LicenseMessageDisposition.Notice) && (<Tooltip content={Message}>
                                <em className={"fa-solid fa-triangle-exclamation"} style={{ color: theme.alertText }}/>
                            </Tooltip>)}
                        {Disposition === LicenseMessageDisposition.Error && (<Tooltip content={Message} position="bottom">
                                <em className={"fa-solid fa-exclamation-triangle"} style={{ color: theme.dangerText }}/>
                            </Tooltip>)}
                    </div>

                    <div className={styles.overviewDescription}>
                        <span style={{ paddingRight: "0.5Rem" }}>{Name}</span>
                        {Name === MACHINE_TEXT && (<Tooltip content={MACHINE_DESCRIPTION} position="bottom">
                                <em className={"fa-solid fa-circle-info"} style={{ color: theme.infoText }}/>
                            </Tooltip>)}
                    </div>
                </>} logo={<></>} header={""}/>);
};
UsageOverviewCard.displayName = "UsageOverviewCard"
export { UsageOverviewCard };
