import type { GoogleCloudAccountResource, SensitiveValue } from "@octopusdeploy/octopus-server-client";
import { AccountType } from "@octopusdeploy/octopus-server-client";
import React from "react";
import Dialog from "~/components/Dialog/Dialog";
import type { PageAction } from "~/components/PageActions/PageActions";
import { ExpandableFormSection, Summary, FormSectionHeading, SensitiveFileUpload, ErrorPanel } from "~/components/form";
import { ObfuscatedPlaceholder } from "~/components/form/Sensitive/Sensitive";
import { repository } from "../../../../clientInstance";
import ExternalLink from "../../../../components/Navigation/ExternalLink";
import Note from "../../../../primitiveComponents/form/Note/Note";
import type { AccountEditBaseProps, AccountEditModel } from "./AccountEditBase";
import AccountEditBaseDrawer from "./AccountEditBaseDrawer";
import AccountEditBasePaper from "./AccountEditBasePaper";
import SaveAndTestAccountDialog from "./SaveAndTestAccountDialog";
import type { SaveAndTestAccountProps } from "./SaveAndTestAccountDialog";
interface GoogleCloudAccountModel extends AccountEditModel {
    jsonKey: SensitiveValue;
}
export default function GoogleCloudAccountEdit(props: AccountEditBaseProps<GoogleCloudAccountResource>) {
    switch (props.type) {
        case "paper":
            return <GoogleCloudAccountEditPaper {...props}/>;
        case "drawer":
            return <GoogleCloudAccountEditDrawer {...props}/>;
        default:
            return <ErrorPanel message="Invalid layout"/>;
    }
}
class GoogleCloudAccountEditPaper extends AccountEditBasePaper<GoogleCloudAccountResource, GoogleCloudAccountModel> {
    getPartialModel(account?: GoogleCloudAccountResource) {
        return getPartialModel(account);
    }
    getPartialResource() {
        return getPartialResource(this.state.model);
    }
    getPageActions() {
        return getPageActions((performTest: boolean) => this.handleSaveClick(performTest));
    }
    getTestDialog() {
        return getTestDialog(this.state.accountData?.account?.Id, this.state.showTestDialog, (success: boolean) => this.onTestDone(success));
    }
    customExpandableFormSections() {
        return customExpandableFormSections(this.state.model, (jsonKey) => this.setModelState({ jsonKey }), this.getFieldError);
    }
}
class GoogleCloudAccountEditDrawer extends AccountEditBaseDrawer<GoogleCloudAccountResource, GoogleCloudAccountModel> {
    getPartialModel(account?: GoogleCloudAccountResource) {
        return getPartialModel(account);
    }
    getPartialResource() {
        return getPartialResource(this.state.model);
    }
    getPageActions() {
        return getPageActions((performTest: boolean) => this.handleSaveClick(performTest));
    }
    getTestDialog() {
        return getTestDialog(this.state.accountData?.account?.Id, this.state.showTestDialog, (success: boolean) => this.onTestDone(success));
    }
    customExpandableFormSections() {
        return customExpandableFormSections(this.state.model, (jsonKey) => this.setModelState({ jsonKey }), this.getFieldError);
    }
}
function getPartialModel(account?: GoogleCloudAccountResource): Partial<GoogleCloudAccountModel> {
    if (!account) {
        return {
            jsonKey: {
                HasValue: false,
            },
        };
    }
    return {
        jsonKey: account.JsonKey,
    };
}
function getPartialResource(modelState: GoogleCloudAccountModel): (Partial<GoogleCloudAccountResource> & {
    AccountType: AccountType;
}) | undefined {
    return {
        AccountType: AccountType.GoogleCloudAccount,
        JsonKey: modelState.jsonKey,
    };
}
function jsonKeySummary(modelState: GoogleCloudAccountModel) {
    return modelState.jsonKey && modelState.jsonKey.HasValue ? Summary.summary(ObfuscatedPlaceholder) : Summary.placeholder("No key file provided");
}
function getPageActions(handleSaveClick: (performTest: boolean) => void): PageAction[] {
    return [{ type: "button", buttonType: "secondary", onClick: () => handleSaveClick(true), label: "Save and test" }];
}
function getTestDialog(accountId: string | undefined, showTestDialog: boolean, onOkClick: (success: boolean) => void): React.ReactNode {
    return (accountId && (<Dialog open={showTestDialog}>
                <SaveAndTestGoogleCloudAccountDialog onOkClick={onOkClick} accountId={accountId}/>
            </Dialog>));
}
function customExpandableFormSections(modelState: GoogleCloudAccountModel, onJsonKeyChange: (newValue: SensitiveValue) => void, getFieldError: (field: string) => string): React.ReactElement[] {
    const baseElements: React.ReactElement[] = [];
    baseElements.push(<FormSectionHeading title="Credentials" key={"header"}/>, <ExpandableFormSection key="keyFile" errorKey="keyFile" title="Key File" summary={jsonKeySummary(modelState)} help="The json key file to use when authenticating against Google Cloud." containerKey="AccountEdit">
            <SensitiveFileUpload value={modelState.jsonKey} onChange={onJsonKeyChange} label="Key File" error={getFieldError("KeyFile")}/>
            <Note>
                Refer to the <ExternalLink href="GoogleCloudServiceAccountKey">Google cloud documentation</ExternalLink> for information on generating json key for service accounts.
            </Note>
        </ExpandableFormSection>);
    return baseElements;
}
const SaveAndTestGoogleCloudAccountDialog: React.SFC<SaveAndTestAccountProps> = ({ accountId, onOkClick }) => {
    return <SaveAndTestAccountDialog accountId={accountId} onOkClick={onOkClick} createTask={(accId) => repository.Tasks.createTestGoogleCloudAccountTask(accId)}/>;
};
SaveAndTestGoogleCloudAccountDialog.displayName = "SaveAndTestGoogleCloudAccountDialog"
