/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { NavigationButton, NavigationButtonType, Callout } from "@octopusdeploy/design-system-components";
import type { ActionTemplateCategoryResource, ActionTemplateSearchResource } from "@octopusdeploy/octopus-server-client";
import { ActionHandlerCategory, ProcessType, Permission } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { flatten } from "lodash";
import * as React from "react";
import type { AnalyticStepDispatcher, StepEvent } from "~/analytics/Analytics";
import { Action, useAnalyticStepDispatch } from "~/analytics/Analytics";
import { StepCard } from "~/areas/projects/components/Process/ActionTemplateSelector/StepCard";
import { useOptionalProcessContext } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import ActionTemplateCard from "~/components/ActionTemplates/ActionTemplateCard";
import ActionTemplateCardList from "~/components/ActionTemplates/ActionTemplateCardList";
import ActionTemplateCategory from "~/components/ActionTemplates/ActionTemplateCategory";
import filterActionTemplates from "~/components/ActionTemplates/filterActionTemplates";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import Section from "~/components/Section";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { hidePrereleaseStepPackages } from "~/utils/FeatureFlags/hidePrereleaseStepPackages";
import styles from "./InstalledActionTemplateList.module.less";
interface InstalledActionTemplateListProps {
    templates: ActionTemplateSearchResource[];
    communityTemplates: ActionTemplateSearchResource[];
    categories: ActionTemplateCategoryResource[];
    filter?: string;
    /** This property should be exclusively utilized when the ImproveKubernetesStepSelectionFeatureToggle is enabled. */
    activeCategory?: ActionHandlerCategory;
    onPostSelectionUrlRequested?: (template: {
        Type: string;
        Id: string;
    }) => LinkHref;
    onDetailsUrlRequested?: (template: ActionTemplateSearchResource) => LinkHref;
    onCategorySelected?: (category: ActionHandlerCategory) => void;
}
interface InstalledActionTemplateListPropsInternal extends InstalledActionTemplateListProps {
    dispatchStep: AnalyticStepDispatcher;
    resource: string;
    navigate?: (url: LinkHref) => void;
    openInNewTab?: (url: LinkHref) => void;
}
interface InstalledActionTemplateListState {
    categoriesLookup?: Array<{
        name: string;
        templates: ActionTemplateSearchResource[];
    }>;
    categoryTemplates?: ActionTemplateSearchResource[];
    activeCategory?: ActionHandlerCategory;
}
//eslint-disable-next-line react/no-unsafe
export class InstalledActionTemplateListInternal extends React.Component<InstalledActionTemplateListPropsInternal, InstalledActionTemplateListState> {
    constructor(props: InstalledActionTemplateListPropsInternal) {
        super(props);
        this.state = {
            categoriesLookup: this.categoriseTemplates(props.templates),
            categoryTemplates: [],
            activeCategory: undefined!,
        };
    }
    categoriseTemplates(templates: ActionTemplateSearchResource[]): Array<{
        name: string;
        templates: ActionTemplateSearchResource[];
    }> {
        const sortedCategories = this.props.categories.sort((a, b) => {
            return a.DisplayOrder - b.DisplayOrder;
        });
        return sortedCategories
            .filter((x) => !!x) // In case there's any bad data from the API / null categories.
            .map((category) => ({ name: category.Id, templates: templates.filter((t) => t.Categories.includes(category.Id)) }));
    }
    showCategoryTemplates(category: ActionHandlerCategory, templates: ActionTemplateSearchResource[]) {
        this.setState({ activeCategory: category, categoryTemplates: templates }, () => {
            if (this.props.onCategorySelected) {
                this.props.onCategorySelected(category);
            }
        });
    }
    UNSAFE_componentWillUpdate(nextProps: InstalledActionTemplateListProps) {
        if (!isFeatureToggleEnabled("ImproveKubernetesStepSelectionFeatureToggle") && nextProps.filter !== this.props.filter) {
            // Clear any active selection if we receive new props.
            this.showCategoryTemplates(null!, null!);
            if (this.props.onCategorySelected) {
                this.props.onCategorySelected(null!);
            }
        }
    }
    componentWillReceiveProps(nextProps: InstalledActionTemplateListProps) {
        if (isFeatureToggleEnabled("ImproveKubernetesStepSelectionFeatureToggle") && nextProps.activeCategory !== this.state.activeCategory) {
            const categoryTemplates = nextProps.templates.filter((x) => x.Categories.includes(nextProps.activeCategory!));
            this.showCategoryTemplates(nextProps.activeCategory!, categoryTemplates?.length ? categoryTemplates : null!);
        }
    }
    render() {
        const isFiltering = !!this.props.filter;
        const filteredResults = filterActionTemplates(this.props.templates, this.props.filter!).filter(hidePrereleaseStepPackages);
        return (<div className={styles.container}>
                <React.Fragment>
                    {!isFeatureToggleEnabled("ImproveKubernetesStepSelectionFeatureToggle") && !isFiltering && (<>
                            <Section sectionHeader={"What type of step do you want to add?"}/>
                            <Section className={styles.sectionDivider}>
                                {!isFiltering && this.state.categoriesLookup && this.state.categoriesLookup.length > 0 && (<ol className={styles.categories}>
                                        <div>
                                            {this.state.categoriesLookup.map((categoryLookup) => {
                        if (categoryLookup.templates.length === 0 && categoryLookup.name !== ActionHandlerCategory.Community) {
                            // For step templates (or bad API data) that may not have any templates installed yet.
                            return;
                        }
                        const category = this.props.categories.find((c) => c.Id === categoryLookup.name);
                        const categoryEnum = categoryLookup.name as ActionHandlerCategory;
                        let isBlurred = isFiltering &&
                            flatten(filteredResults.map((x) => x.Categories))
                                .map((x) => x.toLowerCase())
                                .filter((x) => x === category!.Id.toLowerCase()).length === 0;
                        if (category!.Id === ActionHandlerCategory.Community) {
                            // Special case to cater for the Community category (it filters on a different set of templates).
                            const filteredCommunityResults = filterActionTemplates(this.props.communityTemplates, this.props.filter!);
                            isBlurred =
                                isFiltering &&
                                    flatten(filteredCommunityResults.map((x) => x.Categories))
                                        .map((x) => x.toLowerCase())
                                        .filter((x) => x === category!.Id.toLowerCase()).length === 0;
                        }
                        return (<div key={category!.Id}>
                                                        <ActionTemplateCategory category={categoryEnum} name={category!.Name} active={this.state.activeCategory ? this.state.activeCategory === categoryLookup.name : false} isBlurred={isBlurred} isSelectable={isFiltering} templates={categoryLookup.templates} onCategorySelected={() => {
                                if (this.state.activeCategory && this.state.activeCategory === categoryLookup.name) {
                                    // De-select.
                                    this.showCategoryTemplates(null!, null!);
                                }
                                else {
                                    const ev: StepEvent = {
                                        action: Action.Select,
                                        resource: this.props.resource,
                                        stepCategory: categoryLookup.name,
                                        stepTemplate: undefined,
                                    };
                                    this.showCategoryTemplates(categoryEnum, categoryLookup.templates);
                                    // DO NOT SEND THIS EVENT
                                    // this.props.dispatchStep("Select Step Category", ev);
                                }
                            }}/>
                                                    </div>);
                    })}
                                        </div>
                                    </ol>)}
                            </Section>
                        </>)}
                    {!isAllowed({ permission: Permission.ActionTemplateView }) && (<Section className={styles.sectionDivider}>
                            <Callout type={"information"} title={"Permission required"}>
                                You can't see custom installed step templates because you don't have <ExternalLink href="UserRoles">{Permission.ActionTemplateView}</ExternalLink> permission.
                            </Callout>
                        </Section>)}
                    {this.state.categoryTemplates && this.state.categoryTemplates.length > 0 && (<TransitionAnimation key={this.state.activeCategory}>
                            {this.props.activeCategory !== ActionHandlerCategory.Featured ? <Section sectionHeader={`Installed Step Templates (${this.state.categoryTemplates.length})`} className={styles.stepTemplatesHeader}/> : <></>}
                            <ActionTemplateCardList>{this.state.categoryTemplates.map((template) => this.renderSingle(template))}</ActionTemplateCardList>
                        </TransitionAnimation>)}
                </React.Fragment>
                {isFiltering && this.renderFilteredTemplates(filteredResults)}
            </div>);
    }
    private renderFilteredTemplates(filteredResults: ActionTemplateSearchResource[]) {
        if (filteredResults.length === 0 && this.props.filter) {
            return (<Section sectionHeader={`Installed Step Templates (${filteredResults.length})`} className={styles.stepTemplatesHeader} bodyClassName={styles.stepTemplatesNoResult}>
                    No results found
                </Section>);
        }
        return (<TransitionAnimation key="filteredSteps">
                <Section sectionHeader={`Installed Step Templates (${filteredResults.length})`} className={styles.stepTemplatesHeader}/>
                <ActionTemplateCardList>{filteredResults.map((template) => this.renderSingle(template))}</ActionTemplateCardList>
            </TransitionAnimation>);
    }
    private renderSingle(template: ActionTemplateSearchResource) {
        const isImproveKubernetesStepSelectionFeatureEnabled = isFeatureToggleEnabled("ImproveKubernetesStepSelectionFeatureToggle");
        const ev: StepEvent = {
            action: Action.Add,
            stepCategory: template.Categories[1] ?? template.Category,
            stepTemplate: template.Name,
            resource: this.props.resource,
        };
        const dispatchStep = () => {
            this.props.dispatchStep("Add step template", ev);
        };
        const stepCategory = template.Categories.find((x) => x !== ActionHandlerCategory.Featured && x !== ActionHandlerCategory.BuiltInStep);
        const category = this.props.categories.find((x) => x.Id === stepCategory);
        const secondaryLabel = template.HasUpdate ? "Update Available" : template.CommunityActionTemplateId && template.Categories.includes(ActionHandlerCategory.StepTemplate) ? "More Info" : "";
        if (isImproveKubernetesStepSelectionFeatureEnabled) {
            return (<StepCard key={template.Type + template.Id} heading={template.Name} subHeading={template.IsBuiltIn ? "By Octopus Deploy" : "Installed"} logo={template.Links.Logo} chipLabel={this.getChipLabel(template)} chipType={this.getChipType(template)} description={template.Description} dotpoints={template.Features} primaryButtonLabel="Add Step" onPrimaryButtonClick={() => {
                    dispatchStep();
                    this.props.navigate?.(this.props.onPostSelectionUrlRequested!(template));
                }} secondaryButtonLabel={secondaryLabel} onSecondaryButtonClick={() => {
                    this.props.openInNewTab?.(this.props.onDetailsUrlRequested!(template));
                }}/>);
        }
        return (<ActionTemplateCard key={template.Type + template.Id} template={template} primaryAction={<NavigationButton label="Add" type={NavigationButtonType.Primary} href={this.props.onPostSelectionUrlRequested!(template)}/>} primaryRedirect={this.props.onPostSelectionUrlRequested!(template)} onClick={dispatchStep} secondaryAction={template.HasUpdate && (<InternalLink size={0.75} to={this.props.onDetailsUrlRequested!(template)}>
                            Update available
                        </InternalLink>)}/>);
    }
    private isRecommendedStep(step: ActionTemplateSearchResource): boolean {
        const recommendedActionTypes = ["Octopus.KubernetesDeployRawYaml", "kustomize", "Octopus.Kubernetes.Kustomize", "Octopus.HelmChartUpgrade"];
        return step.IsBuiltIn && recommendedActionTypes.some((x) => x.toLowerCase() === step.Type.toLowerCase());
    }
    private getChipLabel(step: ActionTemplateSearchResource) {
        if (step.IsBuiltIn && !!(this.props.filter || this.state.activeCategory === ActionHandlerCategory.Featured)) {
            return "";
        }
        if (this.isRecommendedStep(step)) {
            return "Recommended";
        }
        if (step.CommunityActionTemplateId) {
            return "Community Contributed";
        }
        if (!step.CommunityActionTemplateId && step.Categories.includes(ActionHandlerCategory.StepTemplate)) {
            return "Custom Template";
        }
        return "";
    }
    private getChipType(step: ActionTemplateSearchResource) {
        if (this.isRecommendedStep(step)) {
            return "success";
        }
        if (step.CommunityActionTemplateId) {
            return "primary";
        }
        if (!step.CommunityActionTemplateId && step.Categories.includes(ActionHandlerCategory.StepTemplate)) {
            return "info";
        }
        return "primary";
    }
    static displayName = "InstalledActionTemplateListInternal";
}
export function InstalledActionTemplateList(props: InstalledActionTemplateListProps) {
    const optionalProcessContext = useOptionalProcessContext();
    const processType = optionalProcessContext?.state.processType;
    const resource = !processType ? "Step Template" : processType === ProcessType.Runbook ? "Runbook" : "Deployment Process";
    const projectId = optionalProcessContext?.state.model.process?.ProjectId;
    const navigation = useSpaceAwareNavigation();
    const dispatchStep = useAnalyticStepDispatch(projectId);
    return <InstalledActionTemplateListInternal {...props} resource={resource} dispatchStep={dispatchStep} navigate={navigation.navigate} openInNewTab={navigation.open}/>;
}
