import { css } from "@emotion/css";
import { ActionButtonType, Button } from "@octopusdeploy/design-system-components";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type { ReactElement } from "react";
import React from "react";
import Dialog from "~/components/Dialog/Dialog";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import { DialogLayout } from "~/components/DialogLayout/DialogLayout";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { AddProjectTriggerCard } from "./AddProjectTriggerCard";
import { BuiltInFeedIcon } from "./Icons/BuiltInFeedIcon";
import { DeploymentIcon } from "./Icons/DeploymentIcon";
import { ExternalFeedIcon } from "./Icons/ExternalFeedIcon";
import { ScheduledIcon } from "./Icons/ScheduledIcon";
interface AddProjectTriggerDialogLayoutProps {
    closeDialog(): void;
    project: ProjectResource;
}
function AddProjectTriggerDialogLayout({ closeDialog, project }: AddProjectTriggerDialogLayoutProps) {
    const { SpaceId: spaceId, Slug: projectSlug } = project;
    const canCreateBuiltInFeedTrigger = !project.AutoCreateRelease && !project.IsVersionControlled;
    const builtInFeedTriggerDisabledMessage = project.IsVersionControlled ? "Built-in package repository triggers are not supported in version controlled projects" : "This trigger type only supports one trigger per project";
    const cancel = <Button key="Cancel" label="Cancel" onClick={() => closeDialog()} importance={"quiet"}/>;
    return (<div className={styles.wrapper}>
            <DialogLayout actions={[cancel]} title={<h2 className={styles.title}>Select trigger type</h2>} extraHeaderContent={() => (<div className={styles.description}>
                        Triggers allow you to create, deploy or promote releases based on specific events or schedules. Get started by selecting the type of trigger you want to create. Learn more about&nbsp;
                        <ExternalLink href="ProjectTriggers">triggers</ExternalLink>
                    </div>)} closeDialog={closeDialog}>
                <div className={styles.cardList}>
                    <AddProjectTriggerCard title="External feed" description="Create a release when Helm charts or container images are updated in an external feed." icon={<TriggerCardIcon icon={<ExternalFeedIcon size={40}/>}/>} path={links.createReleaseCreationFeedTriggerPage.generateUrl({ spaceId, projectSlug })}></AddProjectTriggerCard>
                    <AddProjectTriggerCard title="Built-in package repository" description="Create a release when a package is pushed to the built-in package repository." icon={<TriggerCardIcon icon={<BuiltInFeedIcon size={40}/>}/>} path={links.createBuiltInFeedTriggerPage.generateUrl({ spaceId, projectSlug })} disabled={!canCreateBuiltInFeedTrigger} disabledMessage={builtInFeedTriggerDisabledMessage}></AddProjectTriggerCard>
                    <AddProjectTriggerCard title="Deployment target" description="Trigger a deployment when machine-related events occur." icon={<TriggerCardIcon icon={<DeploymentIcon size={40}/>}/>} path={links.createDeploymentTriggerPage.generateUrl({ spaceId, projectSlug })}></AddProjectTriggerCard>
                    <AddProjectTriggerCard title="Scheduled" description="Create, deploy, and promote releases on a defined schedule." icon={<TriggerCardIcon icon={<ScheduledIcon size={40}/>}/>} path={links.createDeploymentScheduledTriggerPage.generateUrl({ spaceId, projectSlug })}></AddProjectTriggerCard>
                </div>
            </DialogLayout>
        </div>);
}
const styles = {
    wrapper: css({
        padding: space[16], // Accounts for additional 16px padding in DialogLayout
        paddingBottom: space[12], // Account for slightly less padding around actions in DialogLayout
    }),
    title: css({ font: text.interface.heading.medium, margin: 0 }),
    description: css({ font: text.interface.body.default.base, marginBottom: space[24] }),
    cardList: css({
        display: "flex",
        flexWrap: "wrap",
        gap: space[16],
    }),
};
interface AddProjectTriggerDialogButtonProps {
    project: ProjectResource;
}
export function AddProjectTriggerDialogButton({ project }: AddProjectTriggerDialogButtonProps) {
    return (<OpenDialogButton type={ActionButtonType.Primary} label="Add Trigger" renderDialog={({ open, closeDialog }) => (<Dialog open={open} dialogWidth="800px">
                    <AddProjectTriggerDialogLayout closeDialog={closeDialog} project={project}/>
                </Dialog>)}/>);
}
interface TriggerCardIconProps {
    icon: ReactElement;
}
function TriggerCardIcon({ icon }: TriggerCardIconProps) {
    return <div className={iconStyles}>{icon}</div>;
}
const iconStyles = css({
    height: space[40],
    width: space[40],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: themeTokens.color.icon.info,
});
