import type { TomcatDeployProperties } from "@octopusdeploy/legacy-action-properties";
import { ActionExecutionLocation } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import Roles from "~/components/Actions/Roles";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import type { ActionSummaryProps } from "../actionSummaryProps";
import type { ActionEditProps } from "../pluginRegistry";
import pluginRegistry from "../pluginRegistry";
import TomcatFeatureTemplate from "./tomcatFeatureTemplate";
class ChangeStateInTomcatActionSummary extends BaseComponent<ActionSummaryProps> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }
    render() {
        return (<div>
                Change the state of an application in Tomcat 7+{" "}
                {this.props.targetRolesAsCSV && (<span>
                        {" "}
                        on deployment targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV}/>{" "}
                    </span>)}
            </div>);
    }
    static displayName = "ChangeStateInTomcatActionSummary";
}
export class ChangeStateInTomcatActionEdit extends BaseComponent<ActionEditProps<TomcatDeployProperties>, {}> {
    render() {
        return (<TomcatFeatureTemplate properties={this.props.properties} packages={this.props.packages} plugin={this.props.plugin} setProperties={this.props.setProperties} setPackages={this.props.setPackages} doBusyTask={this.props.doBusyTask} busy={this.props.busy} getFieldError={this.props.getFieldError} errors={this.props.errors} expandedByDefault={this.props.expandedByDefault} projectId={this.props.projectId} gitRef={this.props.gitRef}/>);
    }
    static displayName = "ChangeStateInTomcatActionEdit";
}
pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnTarget,
    actionType: "Octopus.TomcatState",
    summary: (properties, targetRolesAsCSV) => <ChangeStateInTomcatActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV}/>,
    editSections: { default: ChangeStateInTomcatActionEdit },
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => true,
});
