import React from "react";
import DisplayDiff from "~/components/DisplayDiff";
interface DirtyStateDetailProps {
    model?: object;
    cleanModel?: object;
}
export function DirtyStateDetail({ cleanModel, model }: DirtyStateDetailProps) {
    const oldValue = JSON.stringify(cleanModel, null, 4);
    const newValue = JSON.stringify(model, null, 4);
    return <DisplayDiff oldValue={oldValue} newValue={newValue} splitView={true}/>;
}
