import { Tooltip } from "@octopusdeploy/design-system-components";
import type { GitBranchResource, GitRef } from "@octopusdeploy/octopus-server-client";
import { toGitRefShort } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { useNotifyCreateBranch } from "~/areas/projects/components/BranchSelector/Analytics/useNotifyCreateBranch";
import { GitRefSelector } from "~/areas/projects/components/GitRefSelector/GitRefSelector";
import { useProjectContext } from "~/areas/projects/context";
export function BranchSelectorVNext() {
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    const createBranchDispatcher = useNotifyCreateBranch();
    const onCreateBranch = async (newBranchName: string, parentGitRef: GitRef): Promise<GitBranchResource> => {
        const newBranchResource = await createBranchDispatcher({
            source: "Branch switcher",
            createBranchAction: () => projectContext.state.projectContextRepository.Branches.createBranch(project, newBranchName, parentGitRef),
        });
        await projectContext.actions.onBranchSelected(project, newBranchResource.CanonicalName);
        return newBranchResource;
    };
    const onChange = (gitRef: GitRef) => {
        // Note: When we have a newly selected branch, all we need to do is push it into our router history
        // Note: The props will then flow back into BranchSelector and its initialization useEffect will be triggered
        // Note: As it has a dependency on props.projectContext.state.branch, which in turn changes with there is a new branchName route match
        if (gitRef) {
            projectContext.actions.changeGitRef(gitRef);
        }
    };
    const initialGitRef = projectContext.state.gitRef?.CanonicalName;
    const errorMessage = initialGitRef ? undefined : "unknown git ref";
    const gitRefSelector = <GitRefSelector onChange={onChange} project={projectContext.state.model} initialGitRef={initialGitRef} onCreateBranch={onCreateBranch} errorMessage={errorMessage}/>;
    const shortName = toGitRefShort(initialGitRef);
    return shortName ? <Tooltip content={shortName}>{gitRefSelector}</Tooltip> : gitRefSelector;
}
