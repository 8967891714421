/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Permission, VariableSetContentType } from "@octopusdeploy/octopus-server-client";
import type { LibraryVariableSetResource, ResourceCollection } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import MarkdownDescription from "~/components/MarkdownDescription";
import type { PrimaryPageAction } from "~/components/PageActions/PageActions";
import { PagingList } from "~/components/PagingList/PagingList";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import PaperLayout from "../../../../components/PaperLayout/PaperLayout";
import { LibraryLayout } from "../LibraryLayout/LibraryLayout";
interface ScriptModulesPageProps {
    spaceId: string;
}
interface ScriptModulesPageState extends DataBaseComponentState {
    scriptModulesResponse: ResourceCollection<LibraryVariableSetResource>;
}
class LibraryVariableSetResourceList extends PagingList<LibraryVariableSetResource> {
}
export class ScriptModulesPage extends DataBaseComponent<ScriptModulesPageProps, ScriptModulesPageState> {
    constructor(props: ScriptModulesPageProps) {
        super(props);
        this.state = {
            scriptModulesResponse: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const getScriptModules = repository.LibraryVariableSets.list({ contentType: VariableSetContentType.ScriptModule });
            const scriptModulesResponse = await getScriptModules;
            this.setState({ scriptModulesResponse });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    render() {
        const addScriptModulePageAction: PrimaryPageAction = {
            type: "navigate",
            hasPermissions: isAllowed({ permission: Permission.LibraryVariableSetCreate }),
            label: "Add Script Module",
            path: links.createScriptModulePage.generateUrl({ spaceId: this.props.spaceId }),
        };
        return (<LibraryLayout spaceId={this.props.spaceId}>
                <PaperLayout title="Script Modules" primaryAction={addScriptModulePageAction} busy={this.state.busy} errors={this.errors}>
                    {this.state.scriptModulesResponse && (<LibraryVariableSetResourceList initialData={this.state.scriptModulesResponse} onRow={(module) => this.buildRow(module)} onRowRedirectUrl={(module) => links.editScriptModulePage.generateUrl({ spaceId: this.props.spaceId, scriptModuleId: module.Id })} onFilter={this.filterThings} filterSearchEnabled={true} apiSearchParams={["partialName"]} filterHintText="Filter by name..." additionalRequestParams={new Map([["contentType", ["ScriptModule"]]])}/>)}
                </PaperLayout>
            </LibraryLayout>);
    }
    private buildRow = (scriptModule: LibraryVariableSetResource) => {
        return [
            <div key={scriptModule.Id}>
                <ListTitle>{scriptModule.Name}</ListTitle>
                <MarkdownDescription markup={scriptModule.Description}/>
            </div>,
        ];
    };
    private filterThings(filter: string, resource: LibraryVariableSetResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }
    static displayName = "ScriptModulesPage";
}
