import { css } from "@emotion/css";
import { OctopusIcon } from "@octopusdeploy/design-system-components";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ProjectResource, SpaceResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { UserOnboardingResource } from "@octopusdeploy/octopus-server-client/src/resources/userOnboardingResource";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import { links } from "@octopusdeploy/portal-routes";
import React, { useEffect, useState } from "react";
import { Action, useAnalyticActionDispatch, useAnalyticSampleProjectDispatch } from "~/analytics/Analytics";
import { generateGuid } from "~/areas/projects/components/Process/generation";
import { InitializeSampleProjectTour } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTourUtils";
import { repository, session } from "~/clientInstance";
import type { DataBaseComponentState, Errors } from "~/components/DataBaseComponent";
import DataBaseComponent from "~/components/DataBaseComponent";
import InternalLink from "~/components/Navigation/InternalLink";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import OnboardingDialog from "~/components/OnboardingDialog/OnboardingDialog";
import { OnboardingDialogLayout } from "~/components/OnboardingDialog/OnboardingDialogLayout";
import { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import AddNewProjectDialog from "~/components/ProjectBasedActivation/AddNewProjectDialog";
import { ErrorPanel } from "~/components/form";
import { createWelcomeDialogDismissedAnalyticsEvent, createWelcomeDialogSelectionAnalyticsEvent, createViewWelcomeDialogViewAnalyticsEvent } from "./Analytics/WelcomeDialogAnalytics";
import { NewProjectCard } from "./Cards/NewProjectCard";
import { SampleProjectCard } from "./Cards/SampleProjectCard";
import { ViewProjectsCard } from "./Cards/ViewProjectsCard";
import { ViewSampleInstanceCard } from "./Cards/ViewSampleInstanceCard";
import { WelcomeDialogHelpPanelContent } from "./WelcomeDialogHelpPanelContent";
type WelcomeDialogStates = "none" | "welcome-dialog" | "new-project-dialog";
export interface WelcomeDialogInternalProps {
    userOnboarding: UserOnboardingResource | undefined;
    currentSpace: SpaceResource | undefined;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
    onDialogClosed: () => void;
    onCreateSampleProject: (sampleProjectReference: string) => Promise<ProjectResource | undefined>;
}
export function WelcomeDialogInternal(props: WelcomeDialogInternalProps) {
    const { userOnboarding, currentSpace, busy, errors, onDialogClosed, onCreateSampleProject } = props;
    const navigate = useSpaceAwareNavigation();
    const dispatchAction = useAnalyticActionDispatch();
    const trackEvent = useTrackEvent();
    const sampleProjectDispatchAction = useAnalyticSampleProjectDispatch();
    const [activeDialog, setActiveDialog] = useState<WelcomeDialogStates>("welcome-dialog");
    const setState = (value: WelcomeDialogStates) => {
        if (value === "none")
            onDialogClosed();
        setActiveDialog(value);
    };
    const dismissDialog = () => {
        trackEvent(createWelcomeDialogDismissedAnalyticsEvent());
        setState("none");
    };
    const clickNewProject = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        trackEvent(createWelcomeDialogSelectionAnalyticsEvent("Create new project"));
        setState("new-project-dialog");
    };
    const clickViewProjects = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        trackEvent(createWelcomeDialogSelectionAnalyticsEvent("View projects"));
        setState("none");
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        navigate.navigate(links.projectsPage.generateUrl({ spaceId: currentSpace?.Id ?? repository.spaceId! }));
    };
    const clickSampleProject = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        trackEvent(createWelcomeDialogSelectionAnalyticsEvent("Create sample project"));
        const sampleProjectReference = generateGuid();
        sampleProjectDispatchAction("Request Sample Project", {
            sampleProjectReference,
            source: "Welcome Dialog",
        });
        const project = await onCreateSampleProject(sampleProjectReference);
        if (project) {
            setState("none");
            InitializeSampleProjectTour(project.Slug);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            navigate.navigate(links.projectRootRedirect.generateUrl({ spaceId: currentSpace?.Id ?? repository.spaceId!, projectSlug: project.Slug }));
        }
    };
    const clickViewSampleInstance = (e: React.MouseEvent<HTMLElement>) => {
        trackEvent(createWelcomeDialogSelectionAnalyticsEvent("View samples instance"));
        setState("none");
    };
    if (!userOnboarding || !userOnboarding.IsTrialInstance || userOnboarding.WelcomeDialogDismissedDate !== null)
        return <></>;
    const cards = [];
    if (hasPermission(Permission.ProjectCreate)) {
        cards.push(<NewProjectCard busy={!!busy} onClick={clickNewProject} key={"NewProjectCard"}/>);
    }
    else {
        cards.push(<ViewProjectsCard busy={!!busy} onClick={clickViewProjects} key={"ViewProjectsCard"}/>);
    }
    if (currentSpace && session.currentPermissions?.isSpaceManager(currentSpace)) {
        cards.push(<SampleProjectCard busy={!!busy} onClick={clickSampleProject} key={"SampleProjectCard"}/>);
    }
    else {
        cards.push(<ViewSampleInstanceCard busy={!!busy} onClick={clickViewSampleInstance} key={"ViewSampleInstanceCard"}/>);
    }
    return (<>
            <OnboardingDialog name="welcome-dialog" open={activeDialog === "welcome-dialog"} close={dismissDialog} helpPanelContent={<WelcomeDialogHelpPanelContent />}>
                <OnboardingDialogLayout actions={<InternalLink to={"#"} onClick={dismissDialog} size={0.875} weight={600} className={dialogStyles.skipLink} isDisabled={!!busy}>
                            Skip and start using Octopus
                        </InternalLink>} onCloseDialog={() => dismissDialog()} title={<DialogTitle />} busy={busy}>
                    <TrackViewWelcomeDialogAnalyticsEvent displayedCards={cards.map((card) => card.key?.toString() || "Unknown card")}/>
                    <div className={dialogStyles.prompt}>Where would you like to start?</div>
                    <div className={dialogStyles.cardList}>{cards}</div>
                    {errors && <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink} statusCode={errors.statusCode}/>}
                </OnboardingDialogLayout>
            </OnboardingDialog>

            <AddNewProjectDialog open={activeDialog === "new-project-dialog"} close={(project) => {
            if (project) {
                dispatchAction("Save Welcome Dialog Project", { action: Action.Save, resource: "Project" });
                setState("none");
            }
            else {
                dispatchAction("Back to Welcome Dialog from New Project Dialog", { action: Action.Cancel, resource: "Project" });
                setState("welcome-dialog");
            }
        }} cancelButtonLabel="Back"/>
        </>);
}
function TrackViewWelcomeDialogAnalyticsEvent(props: {
    displayedCards: string[];
}) {
    const trackEvent = useTrackEvent();
    useEffect(() => {
        trackEvent(createViewWelcomeDialogViewAnalyticsEvent(props.displayedCards));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <></>;
}
const dialogStyles = {
    prompt: css({ font: text.interface.body.default.base, marginBottom: space[4] }),
    skipLink: css({ paddingRight: space[16] }),
    cardList: css({
        display: "flex",
        flexWrap: "wrap",
        "& div:first-child": {
            marginLeft: "0.125rem",
        },
        "& div:last-child": {
            marginLeft: "0.125rem",
        },
    }),
};
interface WelcomeDialogState extends DataBaseComponentState {
    userOnboarding?: UserOnboardingResource;
    currentSpace?: SpaceResource;
}
export class WelcomeDialog extends DataBaseComponent<{}, WelcomeDialogState> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            if (!repository.spaceId)
                return;
            const [response, space] = await Promise.all([repository.UserOnboarding.getForCurrentUser(), repository.Spaces.get(repository.spaceId)]);
            this.setState({ userOnboarding: response.UserOnboardingResource, currentSpace: space });
        });
    }
    async handleDialogClosed() {
        if (this.state.userOnboarding) {
            const updated: UserOnboardingResource = { ...this.state.userOnboarding, WelcomeDialogDismissedDate: new Date() };
            this.setState({ userOnboarding: updated });
            this.doBusyTask(async () => await repository.UserOnboarding.modifyForCurrentUser(updated));
        }
    }
    async handleCreateSampleProject(sampleProjectReference: string): Promise<ProjectResource | undefined> {
        let project: ProjectResource | undefined = undefined;
        await this.doBusyTask(async () => {
            const sampleProject = await repository.Projects.createSampleProject("Sample Project", sampleProjectReference);
            project = sampleProject.Project;
        });
        return project;
    }
    render() {
        const { currentSpace, userOnboarding } = this.state;
        if (!currentSpace) {
            return null;
        }
        return (<WelcomeDialogInternal userOnboarding={userOnboarding} currentSpace={currentSpace} busy={this.state.busy} errors={this.errors} onDialogClosed={() => this.handleDialogClosed()} onCreateSampleProject={(sampleProjectReference: string) => this.handleCreateSampleProject(sampleProjectReference)}/>);
    }
    static displayName = "WelcomeDialog";
}
function DialogTitle() {
    return (<div className={titleStyles.dialogTitle}>
            <div className={titleStyles.titleIcon}>
                <OctopusIcon />
            </div>
            <div className={titleStyles.titleContainer}>
                <h2 className={titleStyles.title}>Welcome to Octopus</h2>
                <div className={titleStyles.subtitle}>Simple, reliable, and auditable deployments</div>
            </div>
        </div>);
}
const titleStyles = {
    dialogTitle: css({
        marginBottom: space[32],
        display: "flex",
        flexWrap: "wrap",
        gap: space[12],
        alignItems: "center",
    }),
    titleContainer: css({
        "@media (max-width: 600px)": {
            flexBasis: "100%",
        },
    }),
    titleIcon: css({
        height: "46px",
        width: "43px",
    }),
    title: css({
        color: themeTokens.color.text.primary,
        font: text.interface.heading.medium,
        margin: 0,
    }),
    subtitle: css({
        font: text.interface.body.default.medium,
        color: themeTokens.color.text.secondary,
    }),
};
