import { css } from "@emotion/css";
import { colorScales } from "@octopusdeploy/design-system-tokens";
import cn from "classnames";
import React from "react";
interface ActiveStatusIconProps {
    active: boolean;
    style?: React.CSSProperties;
}
export const ActiveStatusIcon = ({ active, style }: ActiveStatusIconProps): JSX.Element => (<svg className={cn({ [iconStyles]: true, [activeIconStyles]: active })} style={style} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4" cy="4" r="4"/>
    </svg>);
const iconStyles = css({
    fill: colorScales.grey[400],
});
const activeIconStyles = css({
    fill: colorScales.green[500],
});
