import { css } from "@emotion/css";
import { fontWeight, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import ConfigureHelpImage from "~/areas/infrastructure/components/MachineSettings/Endpoints/Images/k8s-agent-configure-help.svg";
import InstallHelpImage from "~/areas/infrastructure/components/MachineSettings/Endpoints/Images/k8s-agent-install-help.svg";
import NfsHelpImage from "~/areas/infrastructure/components/MachineSettings/Endpoints/Images/k8s-agent-nfs-help.svg";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
const helpStyles = {
    content: css({ marginTop: 0, h2: { marginTop: 0, fontWeight: fontWeight["700"], color: themeTokens.color.dialog.helpPanel.text }, p: { color: themeTokens.color.dialog.helpPanel.text } }),
};
export function TentacleConfigurationHelpContent() {
    return (<span className={helpStyles.content}>
            <h2>Connect seamlessly to Kubernetes </h2>
            <p>Installing the Kubernetes agent in your cluster eliminates the need for firewall adjustments or extra authentication.</p>
        </span>);
}
export function tentacleConfigurationHelpImage() {
    return { src: ConfigureHelpImage, altText: "" };
}
export function TentacleInstallationHelpContent() {
    return (<span className={helpStyles.content}>
            <h2>What happens after installation?</h2>
            <p>After running the Helm command, your new Kubernetes deployment target will automatically be registered in Octopus Deploy.</p>
            <p>You can then deploy to it using our pre-built steps for YAML, Helm or Kustomize.</p>
            <p>The agent automatically updates itself during regular health checks to keep things running smoothly and securely.</p>
            <p>
                Need help installing Helm? <ExternalLink href={"https://helm.sh/docs/intro/install/"}>Refer to the Helm docs</ExternalLink>
            </p>
        </span>);
}
export function tentacleInstallationHelpImage() {
    return { src: InstallHelpImage, altText: "" };
}
export function NfsDriverInstallationHelpContent() {
    return (<span className={helpStyles.content}>
            <h2>Simplify installation with NFS</h2>
            <p>Network File System (NFS) provides a simple way to host shared storage that doesn’t require you to create volumes in your cluster.</p>
            <p>Run the command to install the NFS CSI driver, then click next for the Helm command to install the Octopus Kubernetes agent. </p>
        </span>);
}
export function nfsDriverInstallationHelpImage() {
    return { src: NfsHelpImage, altText: "" };
}
