import { css, cx } from "@emotion/css";
import { resetStyles } from "@octopusdeploy/design-system-components";
import { borderRadius, borderWidth, colorScales, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import React, { useState } from "react";
import { LifecycleContextualHelp } from "~/components/ContextualHelp/ContextualHelpSnippets";
import { CustomDialogContent } from "~/components/DialogLayout/Custom";
import { CustomDialogActions, CustomSaveDialogActions } from "~/components/DialogLayout/Custom/CustomDialogActions";
import CustomSaveDialogLayout, { CustomSaveDialogTitleBar } from "~/components/DialogLayout/Custom/CustomSaveDialogLayout";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon";
import styles from "./styles.module.less";
interface CreateEnvironmentsDialogLayoutProps {
    onSaveClick: (environments: Array<string>) => Promise<boolean>;
    onSkipClick: () => boolean;
}
const suggestions = ["Development", "Staging", "Production", "Test", "QA"];
const initialEnvironments = ["Development", "Staging", "Production"];
export default function CreateEnvironmentsDialogLayout(props: CreateEnvironmentsDialogLayoutProps) {
    const [selectedEnvironments, setSelectedEnvironments] = useState<Array<string>>(initialEnvironments);
    return (<CustomSaveDialogLayout frame={NoFrame} onSaveClick={async () => await props.onSaveClick(selectedEnvironments)} close={props.onSkipClick} open={true} renderTitle={() => <CustomSaveDialogTitleBar title="You'll need at least one environment to deploy to"/>} renderActions={(renderProps) => (<CustomDialogActions actions={<CustomSaveDialogActions saveButtonLabel={"Save"} onSaveClick={renderProps.onSaveClick} close={(save: boolean) => save || props.onSkipClick?.()} cancelButtonLabel={"Skip"}/>}/>)} renderContent={() => (<CustomDialogContent>
                    <p>Select from common environments:</p>
                    <CheckboxGrid options={suggestions} value={selectedEnvironments} onChange={setSelectedEnvironments}/>
                    {selectedEnvironments.length >= 2 && <LifecycleSummary environments={selectedEnvironments} className={styles.lifecycleSummary}/>}
                </CustomDialogContent>)}></CustomSaveDialogLayout>);
}
const NoFrame: React.FC<{}> = ({ children }) => <div className={styles.noFrameFlex}>{children}</div>;
NoFrame.displayName = "NoFrame"
const LifecycleSummary: React.FC<{
    environments: Array<string>;
    className?: string;
}> = ({ environments, className }) => (<div className={className}>
        <div>
            Your selected environments will appear in your <strong>Lifecycle</strong>
            <LifecycleContextualHelp /> in this order:
        </div>
        <ol className={styles.lifecycleList}>
            {environments.map((name, index) => (<li key={name} className={styles.lifecycleListItem}>
                    {name}
                </li>))}
        </ol>
    </div>);
LifecycleSummary.displayName = "LifecycleSummary"
interface CheckboxGridProps<T> {
    options: Array<T>;
    value?: Array<T>;
    onChange?: (values: Array<T>) => void;
}
function CheckboxGrid<T>(props: React.PropsWithChildren<CheckboxGridProps<T>>) {
    const isSelected = (value: T): boolean => props.value?.includes(value) ?? false;
    const toString = (v: T) => `${v}`;
    const toggleSelected = (value: T) => {
        const selectedValues = props.value ?? [];
        const newSelectedValues = isSelected(value) ? selectedValues.filter((env) => env !== value) : [...selectedValues, value];
        props.onChange?.(newSelectedValues);
    };
    return (<div className={styles.checkboxGrid}>
            {props.options.map((name) => (<button key={toString(name)} className={cx(checkboxGridItemStyles, { [checkboxGridItemSelectedStyles]: isSelected(name) })} aria-label={toString(name)} onClick={() => toggleSelected(name)}>
                    {isSelected(name) ? <ThirdPartyIcon iconType={ThirdPartyIconType.CheckBox} color={colorScales.blue[500]}/> : <ThirdPartyIcon iconType={ThirdPartyIconType.CheckBoxOutlineBlank} color={themeTokens.color.border.primary}/>}
                    <span>{toString(name)}</span>
                </button>))}
        </div>);
}
const checkboxGridItemStyles = css({
    ...resetStyles.button,
    display: "flex",
    gap: space[8],
    alignItems: "center",
    width: "100%",
    height: "100%",
    padding: space[8],
    borderRadius: borderRadius.small,
    borderWidth: borderWidth[2],
    borderStyle: "solid",
    borderColor: themeTokens.color.border.primary,
});
const checkboxGridItemSelectedStyles = css({
    borderColor: colorScales.blue[500],
});
