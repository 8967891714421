import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { css } from "@emotion/css";
import { SortHandleIcon } from "@octopusdeploy/design-system-components";
import { borderRadius, borderWidth, colorScales, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { CSSProperties } from "react";
import * as React from "react";
export default function SortableItem({ id, name }: {
    id: string;
    name: string;
}) {
    const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const style: CSSProperties = {
        transform: CSS.Translate.toString(transform),
        transition,
    };
    return isDragging ? (<li className={styles.sortableItemDropArea} {...attributes} {...listeners} ref={setNodeRef} style={style}>
            <div className={styles.sortableItemDropBar}/>
        </li>) : (<li className={styles.sortableItem} {...attributes} {...listeners} ref={setNodeRef} style={style}>
            <SortHandleIcon />
            <span className={styles.itemName}>{name}</span>
        </li>);
}
const styles = {
    sortableItem: css({
        padding: space[12],
        border: `${borderWidth[1]} solid ${themeTokens.color.border.primary}`,
        borderRadius: borderRadius["medium"],
        display: "flex",
        alignItems: "center",
        gap: space[8],
        backgroundColor: themeTokens.color.background.primary.default,
        cursor: "pointer",
        touchAction: "manipulation",
    }),
    sortableItemDropArea: css({
        padding: space[12],
        height: "50px",
        width: "100%",
        display: "flex",
        alignItems: "center",
    }),
    sortableItemDropBar: css({
        border: `${borderWidth[1]} solid ${colorScales.blue[500]}`,
        borderRadius: borderRadius["medium"],
        backgroundColor: colorScales.blue[500],
        height: space[4],
        width: "100%",
    }),
    itemName: css({
        font: text.interface.body.default.base,
    }),
};
