/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { LinearProgress } from "@octopusdeploy/design-system-components";
import type { ActivityElement, ActivityLogElement, GitRefResource } from "@octopusdeploy/octopus-server-client";
import { ActivityLogEntryCategory, ActivityStatus, TaskState } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import _, { isEqual, startsWith } from "lodash";
import moment from "moment/moment";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { RetryCountdown } from "~/areas/tasks/components/Task/RetryCountdown";
import { generateUriForElement, getStatusIcon } from "~/areas/tasks/components/Task/TaskLog/TaskLogUtil";
import { StepEditorLink } from "~/components/StepEditorLink/StepEditorLink";
import { TaskLogBlockLoader } from "~/components/TaskLogLines/TaskLogBlockLoader";
import DurationBetweenLabel from "~/components/TimeLabels/DurationBetweenLabel";
import { DurationSinceLabel } from "~/components/TimeLabels/DurationSinceLabel";
import TaskLogLines from "./TaskLogLines";
import styles from "./taskLogBlock.module.less";
interface TaskLogBlockComponentProps {
    element: UniqueActivityElement;
    taskState: TaskState;
    expandedIds: string[];
    collapsible: boolean;
    focusId?: string;
    showRunTime: boolean;
    showTimestamps: boolean;
    parentDuration: number;
    onFetchRange(element: ActivityElement, start: number, end: number): void;
    isFetchDisabled: boolean;
    setExpanded(id: string, expanded: boolean): void;
    stepsCorrelationIds?: {
        [key: string]: string;
    };
    gitRef?: GitRefResource;
}
export type UniqueActivityElement = ActivityElement & {
    uniqueId: string;
    Children: UniqueActivityElement[];
};
type TaskLogBlockProps = TaskLogBlockComponentProps & RouteComponentProps<{
    spaceId?: string;
    projectId?: string;
}>;
class TaskLogBlockInternal extends React.Component<TaskLogBlockProps> {
    constructor(props: TaskLogBlockProps) {
        super(props);
    }
    shouldComponentUpdate(nextProps: TaskLogBlockProps) {
        return (this.props.showTimestamps !== nextProps.showTimestamps ||
            this.props.isFetchDisabled !== nextProps.isFetchDisabled ||
            !isEqual({ element: this.props.element, taskState: this.props.taskState, expandedIds: this.props.expandedIds, focusId: this.props.focusId }, { element: nextProps.element, taskState: nextProps.taskState, expandedIds: nextProps.expandedIds, focusId: nextProps.focusId }));
    }
    renderProgress(element: ActivityElement) {
        let percent = element.ProgressPercentage;
        let message = element.ProgressMessage;
        // Guess the progress based on how many child tasks have completed
        if (!percent && !element.Ended) {
            if (element.Children.length > 1) {
                const complete = element.Children.filter((e) => e.Ended).length;
                percent = (complete / element.Children.length) * 100;
                if (percent == 0) {
                    percent = 1;
                }
                message = complete.toLocaleString() + "/" + element.Children.length.toLocaleString() + " steps complete";
            }
        }
        return (percent && (<div className={styles.progress}>
                    {message} ({percent.toFixed(0)}%)
                    <LinearProgress show={true} variant="determinate" value={percent}/>
                </div>));
    }
    renderTimeImpact() {
        const thisDuration = this.getDuration();
        if (!thisDuration)
            return undefined;
        let impact = (thisDuration / (this.props.parentDuration || thisDuration)) * 100;
        impact = impact < 1 ? 0 : impact < 3 ? 3 : impact;
        return <div className={styles.blockTimeImpact} style={{ width: impact }}></div>;
    }
    getDuration() {
        let thisDuration = 0;
        const started = this.props.element.Started;
        const ended = this.props.element.Ended;
        if (!ended || this.props.taskState === TaskState.Executing || this.props.element.Status === ActivityStatus.Running) {
            return undefined;
        }
        const from = moment(started);
        const to = moment(ended);
        thisDuration = to.diff(from);
        return thisDuration;
    }
    render() {
        const element = this.props.element;
        const expanded = !this.props.collapsible || this.props.expandedIds.indexOf(element.uniqueId) >= 0 || this.props.expandedIds.indexOf(element.Id) >= 0;
        if (!element.Status) {
            return null;
        }
        const hasContent = element.Children.length > 0 || element.LogElements.length > 0;
        const collapsible = this.props.collapsible && hasContent;
        const onClickHandler = (e: React.MouseEvent) => {
            e.preventDefault();
            if (hasContent && this.props.collapsible) {
                this.props.setExpanded(element.uniqueId, !expanded);
            }
        };
        const lastLogElement = _.last(element.LogElements);
        const showRetryCountdown = lastLogElement && lastLogElement.Category === ActivityLogEntryCategory.Retry;
        return (<div className={cn(styles.logEntryChild)}>
                <div className={cn(styles.blockHeader, expanded ? styles.expanded : "")}>
                    <a className={styles.blockTitle} onClick={onClickHandler} href={"#" + generateUriForElement(this.props, element.uniqueId)}>
                        <span className={cn(styles.blockExpanderIcon, expanded ? cn(styles.blockExpanderIconExpanded) : "")}>{collapsible ? <em className={cn("fa-solid", "fa-chevron-right")}/> : null}</span>
                        <span className={styles.blockStatusIcon}>{getStatusIcon(element.Status)}</span>
                        <span className={styles.blockTitleName}>{element.Name}</span>
                    </a>
                    <StepEditorLink element={element} stepsCorrelationIds={this.props.stepsCorrelationIds} branch={this.props.gitRef} className={styles.stepEditorLink}/>
                    <div className={styles.blockTime}>
                        {this.renderProgress(element)}
                        {this.renderTimeImpact()}
                        <div>{showRetryCountdown ? this.getRetryCountdown(lastLogElement) : this.getRunTime()}</div>
                    </div>
                </div>
                {expanded && hasContent && (<div className={styles.body}>
                        <TaskLogLines lines={element.LogElements} showTimestamps={this.props.showTimestamps} onFetchRange={(s, e) => this.props.onFetchRange(this.props.element, s, e)} isFetchDisabled={this.props.isFetchDisabled} parent={element}/>

                        {element.Status == ActivityStatus.Running && element.LogElements.length >= 1 && element.Children.length == 0 && <TaskLogBlockLoader />}

                        {showRetryCountdown && <div className={styles.lines}>{this.getRetryCountdown(lastLogElement)}</div>}

                        {element.Children && element.Children.length > 0 && (<div className={styles.children}>
                                {(element.Children as UniqueActivityElement[]).map((e) => (<TaskLogBlock key={e.uniqueId} focusId={this.props.focusId} element={e} expandedIds={this.getExpandedIds(e)} collapsible={this.props.collapsible} taskState={this.props.taskState} showRunTime={this.props.showRunTime} showTimestamps={this.props.showTimestamps} setExpanded={this.props.setExpanded} onFetchRange={this.props.onFetchRange} stepsCorrelationIds={this.props.stepsCorrelationIds} gitRef={this.props.gitRef} isFetchDisabled={this.props.isFetchDisabled} parentDuration={this.props.parentDuration || this.getDuration() || 0}/>))}
                            </div>)}
                    </div>)}
            </div>);
    }
    getExpandedIds(element: UniqueActivityElement) {
        return this.props.expandedIds.filter((i) => startsWith(i, element.uniqueId) || startsWith(i, element.Id));
    }
    getRetryCountdown(element: ActivityLogElement) {
        const to = element.MessageText;
        return <RetryCountdown to={element.MessageText}/>;
    }
    getRunTime() {
        if (!this.props.showRunTime) {
            return null;
        }
        const started = this.props.element.Started;
        const ended = this.props.element.Ended;
        if (this.props.taskState === TaskState.Executing && this.props.element.Status === ActivityStatus.Running) {
            return ended ? (<span>
                    <DurationBetweenLabel from={started} to={ended} short/>
                </span>) : (<span>
                    <DurationSinceLabel from={started} short/>
                </span>);
        }
        if (!ended) {
            return null;
        }
        return (<span>
                <DurationBetweenLabel from={started} to={ended} short/>
            </span>);
    }
    static displayName = "TaskLogBlockInternal";
}
const TaskLogBlock = withRouter(TaskLogBlockInternal);
export default TaskLogBlock;
