/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { css } from "@emotion/css";
import { space, text } from "@octopusdeploy/design-system-tokens";
import { TriggerActionType, toDeploymentActionPackage, triggerActionTypeIs, triggerIdIs } from "@octopusdeploy/octopus-server-client";
import type { DeploymentProcessResource, DeploymentActionPackageResource, TriggerFeedFilterResource, TriggerResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import { DeploymentActionChip } from "~/components/Chips";
import InternalLink from "~/components/Navigation/InternalLink";
interface TriggerNameAndLinkProps {
    trigger: TriggerResource;
    projectSlug: string;
}
export function TriggerNameAndLink({ trigger, projectSlug }: TriggerNameAndLinkProps) {
    return (<div className={styles.nameWrapper}>
            <InternalLink to={getTriggerRedirectUrl(trigger, projectSlug)}>
                <span className={styles.triggerName}>{trigger.Name}</span>
            </InternalLink>
        </div>);
}
interface FeedTriggerDescriptionProps {
    trigger: TriggerResource;
    builtInFeedPackageActions: DeploymentActionPackageResource[];
    projectSlug: string;
    deploymentProcess: DeploymentProcessResource;
}
export function FeedTriggerDescription({ trigger, builtInFeedPackageActions, projectSlug, deploymentProcess }: FeedTriggerDescriptionProps) {
    const packages = (trigger.Filter as TriggerFeedFilterResource).Packages;
    if (packages.length === 0)
        return <>No packages monitored</>;
    if (trigger.Id === "built-in-feed" && builtInFeedPackageActions?.length > 0) {
        const builtInFeedPackage = builtInFeedPackageActions[0];
        return (<span className={styles.description}>
                Create a release when the package {builtInFeedPackage.PackageReference} is pushed to the built-in feed connected to <DeploymentActionChip stepName={builtInFeedPackage.DeploymentAction}/>
            </span>);
    }
    const pkg = toDeploymentActionPackage(packages[0], deploymentProcess);
    if (packages.length > 1)
        return (<span className={styles.description}>
                Create a release when a package is pushed to the external feeds connected to <DeploymentActionChip stepName={pkg.DeploymentAction}/> <InternalLink to={getTriggerRedirectUrl(trigger, projectSlug)}>Show&nbsp;more</InternalLink>
            </span>);
    return (<span className={styles.description}>
            Create a release when the {packageName(pkg)} is pushed to the external feed connected to <DeploymentActionChip stepName={pkg.DeploymentAction}/>
        </span>);
}
const packageName = (pkg: DeploymentActionPackageResource) => (pkg.PackageReference ? "package " + pkg.PackageReference : "primary package");
const getTriggerRedirectUrl = (trigger: TriggerResource, projectSlug: string) => {
    const urlParams = { spaceId: trigger.SpaceId, projectSlug: projectSlug, triggerId: trigger.Id };
    switch (true) {
        case triggerIdIs(trigger, "built-in-feed"):
            return links.editBuiltInFeedTriggerPage.generateUrl({ spaceId: trigger.SpaceId, projectSlug: projectSlug });
        case triggerActionTypeIs(trigger, TriggerActionType.CreateRelease):
            return links.editReleaseCreationFeedTriggerPage.generateUrl(urlParams);
        case triggerActionTypeIs(trigger, TriggerActionType.RunRunbook):
            return links.projectRunbookEditScheduledTriggerPage.generateUrl(urlParams);
        case triggerActionTypeIs(trigger, TriggerActionType.AutoDeploy):
            return links.editDeploymentTriggerPage.generateUrl(urlParams);
        default:
            return links.editDeploymentScheduledTriggerPage.generateUrl(urlParams);
    }
};
const styles = {
    triggerName: css({ font: text.interface.body.bold.base }),
    nameWrapper: css({
        "@media (min-width: 900px)": {
            minWidth: 200,
        },
        "@media (min-width: 1536px)": {
            minWidth: 300,
        },
    }),
    description: css({ lineHeight: space[24] }),
};
