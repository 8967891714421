/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
// eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
import { ClickAwayListener } from "@material-ui/core";
import { ActionButton, ActionButtonType, IconButton, Switch, Theme, Tooltip } from "@octopusdeploy/design-system-components";
import { OctopusError, ScriptingLanguage } from "@octopusdeploy/octopus-server-client";
import { noOp } from "@octopusdeploy/utilities";
import CodeMirror from "@skidding/react-codemirror";
import cn from "classnames";
import "codemirror/lib/codemirror.css";
import fuzzysort from "fuzzysort";
import { debounce, merge } from "lodash";
import * as React from "react";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { codeEditorAutocompleteNoteStyles, codeEditorAutocompleteNoteContainerStyles, codeEditorCodeMirrorStyles, codeEditorContainerFocusedStyles, codeEditorContainerInADialogStyles, codeEditorContainerStyles, codeEditorCustomDialogActionsStyles, codeEditorFuzzySearchTooltipStyles, codeEditorOuterContainerStyles, codeEditorTitleSectionStyles, codeEditorToolbarButtonsContainerStyles, codeEditorToolbarButtonStyles, codeEditorToolbarIconButtonStyles, codeEditorToolbarStyles, codeEditorNoToolbarStyles, codeEditorHintHighlightStyles, codeEditorCustomDialogStyles, codeEditorSettingsPopoverStyles, codeEditorSettingsSwitchStyles, codeEditorToolbarIconButtonNoLabelStyles, hideCodeEditorToolbarButtonLabelsBreakpoint, } from "~/components/CodeEditor/codeEditorStyles";
import { CustomDialog } from "~/components/Dialog/CustomDialog";
import CustomSaveDialogLayout from "~/components/DialogLayout/Custom/CustomSaveDialogLayout";
import { CustomDialogActions, CustomFlexDialogContent, LargeDialogFrame } from "~/components/DialogLayout/Custom/index";
import type { IconButtonWithTooltipProps } from "~/components/IconButtonWithTooltip/index";
import { SupportedLanguage } from "~/components/ScriptingLanguageSelector/ScriptingLanguageSelector";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import { Note } from "~/components/form";
import InputLabel from "~/components/form/InputLabel/InputLabel";
import InsertVariableButton from "~/components/form/InsertVariableButton/InsertVariableButton";
import type { VariableLookupProps } from "~/components/form/VariableLookup/VariableLookup";
import useLocalStorage from "~/hooks/useLocalStorage";
import { InsertVariableIcon } from "~/primitiveComponents/dataDisplay/Icon/index";
import { Popover } from "~/primitiveComponents/dataDisplay/Popover/Popover";
import PopoverHelp from "~/primitiveComponents/dataDisplay/PopoverHelp/PopoverHelp";
import type { ThemePaletteType } from "~/theme/index";
import { isTooltipHoverable } from "~/utils/TooltipHelper/isTooltipHoverable";
import CopyToClipboardButton from "../CopyToClipboardButton";
import type FormFieldProps from "../form/FormFieldProps";
import { CodeEditorSelect } from "./CodeEditorSelect";
import { ArrowDownLeftUpRightIcon } from "./Icons/ArrowDownLeftUpRightIcon";
import { ArrowUpRightDownLeftIcon } from "./Icons/ArrowUpRightDownLeftIcon";
import "codemirror/mode/powershell/powershell";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/clike/clike";
import "codemirror/mode/mllike/mllike";
import "codemirror/mode/shell/shell";
import "codemirror/mode/xml/xml";
import "codemirror/mode/htmlmixed/htmlmixed";
import "codemirror/mode/css/css";
import "codemirror/mode/properties/properties";
import "codemirror/mode/coffeescript/coffeescript";
import "codemirror/mode/markdown/markdown";
import "codemirror/mode/dockerfile/dockerfile";
import "codemirror/mode/yaml/yaml";
import "codemirror/mode/python/python";
import "codemirror/lib/codemirror";
import "codemirror/addon/display/fullscreen";
import "codemirror/addon/display/placeholder";
import "codemirror/addon/fold/foldgutter";
import "codemirror/addon/fold/foldcode";
import "codemirror/addon/fold/brace-fold.js";
import "codemirror/addon/fold/xml-fold.js";
import "codemirror/addon/fold/indent-fold.js";
import "codemirror/addon/fold/markdown-fold.js";
import "codemirror/addon/fold/comment-fold.js";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/hint/show-hint.js";
export type CodeEditorLanguage = ScriptingLanguage[keyof ScriptingLanguage] | Language[keyof Language] | TextFormat[keyof TextFormat];
interface CodeEditorProps extends VariableLookupProps, FormFieldProps<string> {
    containerClassName?: string;
    onToggleFullScreen?: () => void;
    language: CodeEditorLanguage;
    allowFullScreen?: boolean;
    readOnly?: boolean;
    label?: string | JSX.Element;
    autoComplete?: Array<{
        display: string;
        code: string;
    }>;
    autoFocus?: boolean;
    autoExpand?: boolean;
    fullHeight?: boolean;
    showToolbar?: boolean;
    scriptingLanguageSelectorOptions?: ScriptingLanguageSelectorOptions;
    showCopyButton?: boolean;
    showInsertVariableButton?: boolean;
    showLineNumbers?: boolean;
    lineWrapping?: boolean;
    onEscPressed?(): void;
    children?(props: CommonToolbarButtonProps): React.ReactElement<any>;
    validate?(value: string): Promise<OctopusError> | OctopusError | Error | null;
    settingsOverride?: Partial<CodeEditorSettings>;
    showButtonLabels?: boolean;
}
export enum Language {
    HTML = "HTML",
    CSS = "CSS",
    Markdown = "Markdown",
    DockerFile = "DockerFile",
    INI = "INI",
    CoffeeScript = "CoffeeScript"
}
export enum TextFormat {
    JSON = "JSON",
    PlainText = "PlainText",
    XML = "XML",
    YAML = "YAML"
}
const helloWorldInLanguage = {
    [ScriptingLanguage.CSharp]: "Console.WriteLine(\"Hello World!\");",
    [ScriptingLanguage.FSharp]: "printfn \"Hello World!\"",
    [ScriptingLanguage.PowerShell]: "Write-Host \"Hello World!\"",
    [ScriptingLanguage.Python]: "print(\"Hello World!\")",
    [ScriptingLanguage.Bash]: "echo \"Hello World!\"",
};
const autoCompleteNote = () => {
    return (<Note className={codeEditorAutocompleteNoteStyles}>
            Insert variables with <code>control</code> + <code>i</code>.&nbsp; Fuzzy search supported.
            <PopoverHelp trigger="click" placement={"top-end"}>
                <div style={{ textAlign: "left" }} className={codeEditorFuzzySearchTooltipStyles}>
                    You can type things like <code>machineid</code> followed by <code>control</code> + <code>i</code> to quickly narrow down to <code>Octopus.Machine.Id</code>.
                    <br />
                    Or try <code>ospn</code> followed by <code>control</code> + <code>i</code> to insert <code>Octopus.Space.Name</code>.
                    <br />
                    You can also narrow the selection by typing in while the selection list is opened.
                </div>
            </PopoverHelp>
        </Note>);
};
export function languageToMode(language: CodeEditorLanguage) {
    switch (language) {
        case ScriptingLanguage.Bash:
            return "shell";
        case ScriptingLanguage.CSharp:
            return "text/x-csharp";
        case ScriptingLanguage.FSharp:
            return "text/x-fsharp";
        case ScriptingLanguage.Python:
            return "text/x-python";
        case TextFormat.JSON:
            return "application/json";
        case TextFormat.PlainText:
            return "null";
        case ScriptingLanguage.PowerShell:
            return "powershell";
        case TextFormat.XML:
            return "text/html";
        case TextFormat.YAML:
            return "text/x-yaml";
        case Language.CoffeeScript:
            return "application/vnd.coffeescript";
        case Language.CSS:
            return "text/css";
        case Language.DockerFile:
            return "text/x-dockerfile";
        case Language.HTML:
            return "text/html";
        case Language.INI:
            return "text/x-ini";
        case Language.Markdown:
            return "text/x-markdown";
        default:
            return "null";
    }
}
export interface CodeEditorElement {
    blur(): void;
}
export const CodeEditor = forwardRef<CodeEditorElement, CodeEditorProps>(({ containerClassName: containerClassNameProp, language, allowFullScreen, readOnly = false, label, autoComplete = [], autoFocus, onEscPressed, value, validate, onChange, children, autoExpand = false, syntax, localNames, showToolbar = true, showCopyButton = false, showInsertVariableButton = false, showLineNumbers = true, lineWrapping = true, scriptingLanguageSelectorOptions, settingsOverride, showButtonLabels = true, fullHeight = false, }, ref) => {
    const themePalette = useThemePaletteType();
    const [containerClassName, setContainerClassName] = useState(codeEditorContainerStyles);
    const [focused, setFocused] = useState(false);
    const [protectFocus, setProtectFocus] = useState(false);
    const [isInFullScreen, setIsInFullScreen] = useState(false);
    const [localStorageSettings, setLocalStorageSettings] = useLocalStorage<CodeEditorSettings>("Octopus.CodeEditor.Settings", { theme: themePalette, wordWrap: lineWrapping });
    const [errors, setErrors] = React.useState<OctopusError | Error | null>(null);
    const [sourceCode, setSourceCode] = React.useState<string>(value);
    const [showToolbarButtonLabelsToggle, setShowToolbarButtonLabelsToggle] = React.useState<boolean>(true);
    const [toolbarWidth, setToolbarWidth] = React.useState<number>(0);
    const codeEditorToolbarRef = React.useRef<HTMLDivElement>(null);
    const settings = settingsOverride === undefined ? localStorageSettings : merge(localStorageSettings, settingsOverride); // this way the override settings will "win" over the stored settings
    const theme = settings.theme === "default" ? themePalette : settings.theme;
    let codeMirrorInstance: any = {};
    let cursorPosition: {
        line: number;
        ch: number;
    } | null = null;
    useEffect(() => {
        // update local storage settings to match the new type - delete the darkTheme boolean and set the theme value to match the previous setting
        const oldSettings: {
            darkTheme: boolean;
        } = localStorageSettings as any; // a workaround for type inference incorrectly assuming that localStorageSettings is not a CodeEditorSettings type
        if (!("theme" in oldSettings) && "darkTheme" in localStorageSettings) {
            const themeToSet = "darkTheme" in localStorageSettings ? (localStorageSettings["darkTheme"] ? "dark" : "light") : themePalette;
            delete localStorageSettings["darkTheme"];
            setLocalStorageSettings({ ...localStorageSettings, theme: themeToSet });
        }
    }, [localStorageSettings, setLocalStorageSettings, themePalette]);
    useImperativeHandle(ref, () => ({
        blur: () => {
            blur();
        },
    }));
    useEffect(() => {
        requestAnimationFrame(() => {
            //Code mirror has some issues with refreshing things, so queue things to focus and refresh as soon as we are done mounting
            //this fixes issues where the editor isn't rendered, focused or line numbers don't align.
            if (autoFocus) {
                focus();
            }
            codeMirrorInstance ? codeMirrorInstance.getCodeMirror().refresh() : noOp();
        });
        return () => {
            if (isInFullScreen) {
                toggleFullScreen();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (!codeEditorToolbarRef.current)
            return;
        const resizeObserver = new ResizeObserver(
        // The callback executed whenever an observed resize occurs
        debounce(() => {
            if (codeEditorToolbarRef.current) {
                const newWidth = codeEditorToolbarRef.current.offsetWidth;
                if (newWidth !== toolbarWidth) {
                    setShowToolbarButtonLabelsToggle(newWidth > hideCodeEditorToolbarButtonLabelsBreakpoint);
                    setToolbarWidth(newWidth);
                }
            }
        }, 100));
        resizeObserver.observe(codeEditorToolbarRef.current);
        return () => {
            resizeObserver.disconnect();
        };
    }, [toolbarWidth]);
    const data = autoComplete;
    const options: {
        mode: string;
        lineNumbers: boolean;
        lineWrapping: boolean;
        extraKeys: {
            [id: string]: string | ((editor: any) => void);
        };
        readOnly: boolean | undefined;
        gutters: string[];
        foldOptions: {
            widget: string;
        };
        closeCharacters: RegExp;
        foldGutter: boolean;
        hintOptions: {
            async: boolean;
            hint: (editor: any, callback: any) => void;
        };
        placeholder: string;
    } = {
        mode: languageToMode(language),
        lineNumbers: showLineNumbers,
        lineWrapping: settings.wordWrap,
        extraKeys: {
            "Ctrl-I": "autocomplete",
            Tab: (cm) => {
                const spaces = " ".repeat(cm.getOption("indentUnit"));
                cm.replaceSelection(spaces);
            },
        },
        readOnly: readOnly,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
        foldOptions: {
            widget: "...",
        },
        closeCharacters: /[\s;:,]/,
        foldGutter: true,
        placeholder: helloWorldInLanguage[language as ScriptingLanguage],
        hintOptions: {
            hint: (editor: any) => {
                const cur = editor.getCursor();
                const textBeforeCursor: string = editor.getLine(cur.line).substr(0, cur.ch);
                const wordStartIndex = textBeforeCursor.replace(/[^a-zA-Z0-9_#]/g, " ").lastIndexOf(" ") + 1;
                const wordBeforeCursor = textBeforeCursor.substr(wordStartIndex, cur.ch);
                const filter = wordBeforeCursor;
                if (filter) {
                    const results = fuzzysort.go(filter, data, {
                        limit: 100,
                        threshold: -10000,
                        key: "display",
                    });
                    return {
                        list: results.map((result) => ({
                            displayText: result.obj.display,
                            text: result.obj.code,
                            matches: fuzzysort.indexes(result).map((n) => n.valueOf()),
                            render: (elt: any, _: any, item: {
                                displayText: string;
                                text: string;
                                matches: number[];
                            }) => {
                                const hOpen = `<strong class="${codeEditorHintHighlightStyles}">`;
                                const hClose = "</strong>";
                                let highlighted = "";
                                let matchesIndex = 0;
                                let opened = false;
                                const target = item.displayText;
                                const targetLen = target.length;
                                const matchesBest = item.matches;
                                for (let i = 0; i < targetLen; ++i) {
                                    const char = target[i];
                                    if (matchesBest[matchesIndex] === i) {
                                        ++matchesIndex;
                                        if (!opened) {
                                            opened = true;
                                            highlighted += hOpen;
                                        }
                                        if (matchesIndex === matchesBest.length) {
                                            highlighted += char + hClose + target.substr(i + 1);
                                            break;
                                        }
                                    }
                                    else {
                                        if (opened) {
                                            opened = false;
                                            highlighted += hClose;
                                        }
                                    }
                                    highlighted += char;
                                }
                                elt.innerHTML = highlighted;
                            },
                        })),
                        from: { line: cur.line, ch: wordStartIndex },
                        to: { line: cur.line, ch: cur.ch },
                    };
                }
                else {
                    return {
                        list: data.map((result) => ({
                            displayText: result.display,
                            text: result.code,
                        })) ?? [],
                        from: { line: cur.line, ch: wordStartIndex },
                        to: { line: cur.line, ch: cur.ch },
                    };
                }
            },
            async: false,
        },
    };
    if (allowFullScreen) {
        options.extraKeys["Esc"] = (cm: any) => {
            if (isInFullScreen) {
                toggleFullScreen();
            }
        };
    }
    // This one override the full screen rule as user wants to handle it explicitly
    if (onEscPressed) {
        options.extraKeys["Esc"] = () => {
            if (onEscPressed) {
                onEscPressed();
            }
        };
    }
    const val = value ? value : "";
    function focus() {
        if (codeMirrorInstance) {
            codeMirrorInstance.focus();
            if (cursorPosition) {
                codeMirrorInstance.getCodeMirror().setCursor(cursorPosition);
            }
        }
    }
    function blur() {
        if (codeMirrorInstance) {
            codeMirrorInstance.getCodeMirror().getInputField().blur();
        }
    }
    function insertAtCursor(value: string) {
        codeMirrorInstance.getCodeMirror().replaceSelection(value);
        cursorPosition = codeMirrorInstance.getCodeMirror().getCursor();
    }
    const onFocusChange = (focused: boolean) => {
        if (focused)
            setFocused(true);
        if (!focused && codeMirrorInstance) {
            cursorPosition = codeMirrorInstance.getCodeMirror().getCursor();
        }
    };
    const handleClickAway = () => {
        if (focused) {
            // eslint-disable-next-line no-empty
            if (codeMirrorInstance && codeMirrorInstance.getCodeMirror().hasFocus()) {
            }
            else if (!protectFocus) {
                setFocused(false);
            }
        }
    };
    const handleChange = (value: string) => {
        if (onChange) {
            onChange(value);
        }
    };
    const save = async () => {
        if (validate !== undefined) {
            const validationErrors = await validate(sourceCode);
            if (validationErrors) {
                setErrors(validationErrors);
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return true;
        }
    };
    const toggleFullScreen = () => {
        setIsInFullScreen(!isInFullScreen);
    };
    const fullScreenDialog = () => {
        return (<CustomDialog open={isInFullScreen} close={toggleFullScreen} render={(customDialogRenderProps) => (<Theme themeName={theme}>
                            <div className={codeEditorCustomDialogStyles}>
                                <CustomSaveDialogLayout {...customDialogRenderProps} renderTitle={() => <></>} {...(errors && OctopusError.isOctopusError(errors) && errors.Errors ? { errors: { errors: errors.Errors.map((x: string) => x.toString()) ?? [], message: errors.ErrorMessage, fieldErrors: {}, details: {} } } : {})} onSaveClick={() => save()} frame={DialogFrame} renderActions={(renderProps) => (<CustomDialogActions className={codeEditorCustomDialogActionsStyles} actions={<ActionButton label="Close" onClick={async () => ((await renderProps.onSaveClick()) ? renderProps.close() : {})} type={ActionButtonType.Secondary}/>} additionalActions={<>
                                                    {autoComplete?.length > 0 && (<div style={{ flexGrow: 0 }} className={codeEditorAutocompleteNoteContainerStyles}>
                                                            {autoCompleteNote()}
                                                        </div>)}
                                                </>}/>)} renderContent={() => <CustomFlexDialogContent>{mainBody({ isInADialog: true })}</CustomFlexDialogContent>}/>
                            </div>
                        </Theme>)}/>);
    };
    const insert = (value: string) => {
        insertAtCursor(value);
        focus();
    };
    const mainBody = ({ isInADialog = false }) => {
        const childProps = {
            // Your IDE is lying, these ARE used!
            isInFullScreen,
            onToggleFullScreen: () => toggleFullScreen(),
            insert,
            onToolbarClick: () => {
                if (codeMirrorInstance && codeMirrorInstance.getCodeMirror().hasFocus())
                    setFocused(true);
            },
            onToolbarButtonClick: () => setFocused(true),
            settings: localStorageSettings,
            onUpdateSettings: (settings: CodeEditorSettings) => setLocalStorageSettings(settings),
            showLabel: showButtonLabels,
        };
        return (<React.Fragment>
                    {label && <InputLabel label={label}/>}
                    {showToolbar && (<CodeEditorToolbar {...childProps} scriptingLanguageSelector={scriptingLanguageSelectorOptions &&
                    ((Array.isArray(scriptingLanguageSelectorOptions.supportedLanguages) && scriptingLanguageSelectorOptions.supportedLanguages.length > 1) ||
                        scriptingLanguageSelectorOptions.supportedLanguages === SupportedLanguage.All) ? (<CodeEditorScriptingLanguageSelector scriptingLanguage={syntax ? (syntax as ScriptingLanguage) : (language as ScriptingLanguage | Language | TextFormat)} supportedLanguages={scriptingLanguageSelectorOptions.supportedLanguages} onScriptingLanguageChanged={scriptingLanguageSelectorOptions.onScriptingLanguageChanged} onClose={() => setProtectFocus(false)} onOpen={() => setProtectFocus(true)} {...childProps}/>) : undefined} ref={codeEditorToolbarRef}>
                            {/* For the below buttons, we use dynamic values for `key` to ensure the buttons re-render if the values of `showHoverTooltip` change */}
                            {showCopyButton && <CodeEditorCopyToClipboardButton key={`showClipboardButtonLabel-${showToolbarButtonLabelsToggle}`} value={value} {...childProps} showHoverTooltip={!showToolbarButtonLabelsToggle}/>}
                            {showInsertVariableButton && (<CodeEditorInsertVariableButton key={`showInsertVariableButtonLabel-${showToolbarButtonLabelsToggle}`} syntax={syntax as ScriptingLanguage} localNames={localNames} {...childProps} showHoverTooltip={!showToolbarButtonLabelsToggle}/>)}
                            {allowFullScreen && <CodeEditorToggleFullScreenButton key={`showToggleFullScreenButtonLabel-${showToolbarButtonLabelsToggle}`} {...childProps} showHoverTooltip={!showToolbarButtonLabelsToggle}/>}
                            {!settingsOverride && <CodeEditorSettingsToolbarButton {...childProps}/>}
                        </CodeEditorToolbar>)}
                    <div className={cn(containerClassName, containerClassNameProp, { [codeEditorContainerInADialogStyles]: isInADialog, [codeEditorContainerFocusedStyles]: focused && autoExpand, [codeEditorNoToolbarStyles]: !showToolbar })}>
                        <CodeMirror ref={(codeEditorRef: any) => {
                if (!isInADialog || isInFullScreen)
                    codeMirrorInstance = codeEditorRef;
            }} className={cn(`${codeEditorCodeMirrorStyles}`, { readonly: readOnly })} preserveScrollPosition={true} value={val} onFocusChange={(state: boolean) => {
                if (!isInADialog) {
                    onFocusChange(state);
                }
            }} onChange={handleChange} options={options}/>
                    </div>
                </React.Fragment>);
    };
    return (<Theme themeName={theme} isFullHeight={fullHeight}>
                {isInFullScreen ? (fullScreenDialog()) : (<ClickAwayListener onClickAway={handleClickAway}>
                        <div className={codeEditorOuterContainerStyles}>
                            {mainBody({ isInADialog: false })}{" "}
                            {autoComplete.length > 0 && (<div className={codeEditorAutocompleteNoteContainerStyles} style={{ marginTop: "8px" }}>
                                    {autoCompleteNote()}
                                </div>)}
                        </div>
                    </ClickAwayListener>)}
            </Theme>);
});
const DialogFrame = ({ children }: {
    children?: React.ReactNode;
}) => {
    return <LargeDialogFrame className={codeEditorCustomDialogStyles}>{children}</LargeDialogFrame>;
};
interface CodeEditorSettings {
    wordWrap: boolean;
    theme: ThemePaletteType | "default";
}
interface CommonToolbarButtonProps {
    onToggleFullScreen?: (value: boolean) => void;
    isInFullScreen?: boolean;
    insert?: (value: string) => void;
    onToolbarClick?: () => void;
    onToolbarButtonClick?: () => void;
    settings: CodeEditorSettings;
    onUpdateSettings: (settings: CodeEditorSettings) => void;
    showLabel: boolean;
    showHoverTooltip?: boolean;
}
interface ToolbarProps {
    children: React.ReactNode;
    scriptingLanguageSelector?: React.ReactElement<typeof CodeEditorScriptingLanguageSelector>;
}
const CodeEditorToolbar = forwardRef<HTMLDivElement, ToolbarProps & CommonToolbarButtonProps>((props, ref) => {
    return (<div className={cn({ [codeEditorToolbarStyles]: true, [codeEditorContainerInADialogStyles]: props.isInFullScreen })} onClick={props.onToolbarClick} ref={ref}>
            {(props.isInFullScreen || props.scriptingLanguageSelector) && (<div className={codeEditorTitleSectionStyles}>
                    {props.isInFullScreen && <div>Edit Source Code</div>}
                    {props.scriptingLanguageSelector && <div>{props.scriptingLanguageSelector}</div>}
                </div>)}
            <div className={codeEditorToolbarButtonsContainerStyles}>{props.children}</div>
        </div>);
});
const CodeEditorCopyToClipboardButton = (props: {
    value: string;
} & CommonToolbarButtonProps) => {
    return (<CopyToClipboardButton value={props.value} showHoverTooltip={props.showHoverTooltip}>
            <div className={codeEditorToolbarButtonStyles}>
                <ActionButton className={cn(codeEditorToolbarIconButtonStyles, { [codeEditorToolbarIconButtonNoLabelStyles]: !props.showLabel })} label={props.showLabel ? "Copy to clipboard" : ""} icon={<em className={cn("fa", `fa-clone`)} aria-hidden="true"/>} iconPosition="left" aria-label={`Copy to clipboard`} type={ActionButtonType.Ternary}/>
            </div>
        </CopyToClipboardButton>);
};
type ScriptingLanguageSelectorOptions = {
    onScriptingLanguageChanged: (scriptingLanguage: ScriptingLanguage | Language | TextFormat) => void;
    supportedLanguages: (ScriptingLanguage | Language | TextFormat)[] | SupportedLanguage;
};
type ScriptingLanguageSelectorProps = CommonToolbarButtonProps & ScriptingLanguageSelectorOptions & {
    scriptingLanguage: ScriptingLanguage | Language | TextFormat;
    onClose?: () => void;
    onOpen?: () => void;
};
const CodeEditorScriptingLanguageSelector = (props: ScriptingLanguageSelectorProps) => {
    const getSupportedLanguages = (enumValue: SupportedLanguage): ScriptingLanguage[] => {
        return enumValue === SupportedLanguage.All
            ? [ScriptingLanguage.PowerShell, ScriptingLanguage.Bash, ScriptingLanguage.CSharp, ScriptingLanguage.FSharp, ScriptingLanguage.Python]
            : enumValue === SupportedLanguage.PowerShellAndBash
                ? [ScriptingLanguage.PowerShell, ScriptingLanguage.Bash]
                : [ScriptingLanguage.PowerShell];
    };
    const supportedLanguages = Array.isArray(props.supportedLanguages) ? props.supportedLanguages : getSupportedLanguages(props.supportedLanguages);
    return (<CodeEditorSelect supportedLanguages={supportedLanguages} scriptingLanguage={props.scriptingLanguage} onChange={(value) => {
            props.onScriptingLanguageChanged?.(value);
            props.onToolbarButtonClick?.();
        }} onFocus={() => props.onToolbarButtonClick?.()} onClose={props.onClose} onOpen={props.onOpen}></CodeEditorSelect>);
};
const CodeEditorToggleFullScreenButton = (props: CommonToolbarButtonProps) => {
    if (props.isInFullScreen) {
        return <></>;
    }
    return (<Tooltip content={`${props.isInFullScreen ? "Exit" : "Enter"} Full Screen`} open={isTooltipHoverable(props.showHoverTooltip)}>
            <div className={codeEditorToolbarButtonStyles} onClick={() => props.onToggleFullScreen?.(!props.isInFullScreen)}>
                <ActionButton className={cn(codeEditorToolbarIconButtonStyles, { [codeEditorToolbarIconButtonNoLabelStyles]: !props.showLabel })} label={props.showLabel ? `${props.isInFullScreen ? "Exit" : "Enter"} full screen` : ""} icon={props.isInFullScreen ? <ArrowDownLeftUpRightIcon /> : <ArrowUpRightDownLeftIcon />} iconPosition="left" aria-label={`${props.isInFullScreen ? "Exit" : "Enter"} full screen`} type={ActionButtonType.Ternary}/>
            </div>
        </Tooltip>);
};
const CodeEditorSettingsToolbarButton = (props: CommonToolbarButtonProps) => {
    const [showPopover, setShowPopover] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null);
    const ref = React.createRef<HTMLDivElement>();
    useEffect(() => {
        setAnchorEl(ref.current);
    }, [ref]);
    const handleSettingsChange = (settings: CodeEditorSettings) => {
        props.onUpdateSettings?.(settings);
    };
    return (<>
            <Tooltip content={"Settings"}>
                <div ref={ref} className={codeEditorToolbarButtonStyles} onClick={() => {
            setShowPopover(!showPopover);
        }}>
                    <IconButton className={codeEditorToolbarIconButtonStyles} icon="Settings" accessibleName={`Settings`}/>
                </div>
            </Tooltip>
            <Popover open={showPopover} anchorEl={anchorEl} onClose={() => setShowPopover(false)} anchorOrigin={{ horizontal: "right", vertical: "bottom" }} transformOrigin={{ horizontal: "right", vertical: "top" }}>
                <div className={codeEditorSettingsPopoverStyles}>
                    <div className={codeEditorSettingsSwitchStyles}>
                        <div>Dark Theme</div>
                        <Switch value={props.settings.theme === "dark"} onChange={(value) => handleSettingsChange({ ...props.settings, theme: value ? "dark" : "light" })}/>
                    </div>
                    <div className={codeEditorSettingsSwitchStyles}>
                        <div>Word Wrap</div>
                        <Switch value={props.settings.wordWrap} onChange={(value) => handleSettingsChange({ ...props.settings, wordWrap: value })}/>
                    </div>
                </div>
            </Popover>
        </>);
};
const InsertVariableButtonInternal = ({ onClick, showLabel }: IconButtonWithTooltipProps & {
    showLabel: boolean;
}) => {
    return (<div className={codeEditorToolbarButtonStyles} onClick={onClick}>
            <ActionButton className={cn(codeEditorToolbarIconButtonStyles, { [codeEditorToolbarIconButtonNoLabelStyles]: !showLabel })} label={showLabel ? "Insert variable" : ""} icon={<InsertVariableIcon />} iconPosition="left" aria-label={`Insert variable`} type={ActionButtonType.Ternary}/>
        </div>);
};
interface InsertVariableButtonProps {
    localNames?: string[];
    syntax?: ScriptingLanguage;
}
const CodeEditorInsertVariableButton = (props: InsertVariableButtonProps & CommonToolbarButtonProps) => {
    return (<InsertVariableButton syntax={props.syntax} anchorOrigin={{ horizontal: "right", vertical: "bottom" }} transformOrigin={{ horizontal: "right", vertical: "top" }} localNames={props.localNames} onSelected={(v) => props.insert?.(v)} button={(buttonProps) => (<InsertVariableButtonInternal {...buttonProps} showLabel={props.showLabel ?? true} onClick={(e) => {
                props.onToolbarButtonClick?.();
                buttonProps.onClick?.(e);
            }}/>)} prompt={autoCompleteNote()} showHoverTooltip={props.showHoverTooltip}/>);
};
