import type { UnknownQueryParam, PageRouteDefinition, QueryParamValues, QueryParamValuesSetter } from "@octopusdeploy/portal-routes";
import type { ReactElement } from "react";
import type { Level1PageLayoutProps } from "~/routing/pageRegistrations/Level1PageLayoutProps";
import type { PageRegistration } from "~/routing/pageRegistrations/PageRegistration";
import type { PageIdentity } from "./PageIdentity";
export interface ProjectPageRegistration<RouteParams, QueryParams extends UnknownQueryParam[]> extends PageRegistration<RouteParams, QueryParams> {
    render: (parameters: RouteParams, queryParams: QueryParamValues<QueryParams>, setQueryParams: QueryParamValuesSetter<QueryParamValues<QueryParams>>) => ReactElement;
}
export interface ProjectLevel1PageRegistration<RouteParams, QueryParams extends UnknownQueryParam[]> extends PageRegistration<RouteParams, QueryParams> {
    render: (PageLayout: React.ComponentType<Level1PageLayoutProps>, parameters: RouteParams, queryParams: QueryParamValues<QueryParams>, setQueryParams: QueryParamValuesSetter<QueryParamValues<QueryParams>>) => ReactElement;
}
export function createProjectLevel1PageRegistration<RouteParams, QueryParams extends UnknownQueryParam[]>(route: PageRouteDefinition<RouteParams, QueryParams>, render: (PageLayout: React.ComponentType<Level1PageLayoutProps>, parameters: RouteParams, queryParams: QueryParamValues<QueryParams>, setQueryParams: QueryParamValuesSetter<QueryParamValues<QueryParams>>) => ReactElement, pageIdentity: PageIdentity): ProjectLevel1PageRegistration<RouteParams, QueryParams> {
    return {
        route,
        render,
        pageIdentity,
        verticalNavigationPageArea: "Projects",
        searchKeywords: [],
    };
}
export function createProjectPageRegistration<RouteParams, QueryParams extends UnknownQueryParam[]>(route: PageRouteDefinition<RouteParams, QueryParams>, render: (parameters: RouteParams, queryParams: QueryParamValues<QueryParams>, setQueryParams: QueryParamValuesSetter<QueryParamValues<QueryParams>>) => ReactElement, pageIdentity: PageIdentity): ProjectPageRegistration<RouteParams, QueryParams> {
    return {
        route,
        render,
        pageIdentity,
        verticalNavigationPageArea: "Projects",
        searchKeywords: [],
    };
}
