import { css, cx } from "@emotion/css";
import { GitBranchIcon as GitBranchIconVNext } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { useIsPageHeaderVNextEnabled } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import { withTheme } from "~/components/Theme";
import WarningIcon from "~/components/WarningIcon/index";
import { GitBranchIcon } from "~/primitiveComponents/dataDisplay/Icon";
import styles from "./style.module.less";
export function GitProjectLinkWithErrorStatus(props: {
    label: string | JSX.Element;
    hasError: boolean;
}) {
    return withTheme((theme) => {
        const icon = props.hasError ? <WarningIcon color={theme.errorText}/> : undefined;
        return <IconProjectLink {...props} icon={icon} forceIndented={true}></IconProjectLink>;
    });
}
export function GitProjectLink(props: {
    label: string | JSX.Element;
}) {
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabled();
    const icon = isPageHeaderVNextEnabled ? <GitBranchIconVNext emphasis={"muted"}/> : <GitBranchIcon />;
    return <IconProjectLink {...props} icon={icon}></IconProjectLink>;
}
export function IndentedProjectLink(props: {
    label: string | JSX.Element;
}) {
    return <IconProjectLink {...props} icon={undefined} forceIndented={true}></IconProjectLink>;
}
export function IconProjectLink(props: {
    label: string | JSX.Element;
    icon: JSX.Element | undefined;
    forceIndented?: boolean;
}) {
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabled();
    const noIconStyle = props.forceIndented ? styles.noIconIndented : styles.noIcon;
    const icon = props.icon === undefined ? (<div className={noIconStyle}/>) : (React.cloneElement(props.icon, {
        fontSize: "small",
        className: styles.icon,
    }));
    return (<div className={cx(linkContainerStyles, { [linkContainerStylesVNext]: isPageHeaderVNextEnabled })}>
            {icon}
            <span className={cx({ [labelOrder]: isPageHeaderVNextEnabled })}>{props.label}</span>
        </div>);
}
const linkContainerStyles = css({
    display: "flex",
    alignItems: "center",
});
const linkContainerStylesVNext = css({
    gap: space["8"],
});
const labelOrder = css({
    order: -1,
});
