import type { UnknownQueryParam, PageRouteDefinition, QueryParamValues, QueryParamValuesSetter } from "@octopusdeploy/portal-routes";
import type { ReactElement } from "react";
import type { PageRegistration, VerticalNavigationPageArea } from "~/routing/pageRegistrations/PageRegistration";
import type { PageIdentity } from "./PageIdentity";
export interface SpaceRootPageRegistration<RouteParams, QueryParams extends UnknownQueryParam[]> extends PageRegistration<RouteParams, QueryParams> {
    render: (parameters: RouteParams, queryParams: QueryParamValues<QueryParams>, setQueryParams: QueryParamValuesSetter<QueryParamValues<QueryParams>>) => ReactElement;
}
export function createSpaceRootPageRegistration<RouteParams, QueryParams extends UnknownQueryParam[]>(route: PageRouteDefinition<RouteParams, QueryParams>, render: (parameters: RouteParams, queryParams: QueryParamValues<QueryParams>, setQueryParams: QueryParamValuesSetter<QueryParamValues<QueryParams>>) => ReactElement, pageIdentity: PageIdentity, verticalNavigationPageArea: VerticalNavigationPageArea): SpaceRootPageRegistration<RouteParams, QueryParams> {
    return {
        route,
        render,
        pageIdentity,
        verticalNavigationPageArea: verticalNavigationPageArea,
        searchKeywords: [],
    };
}
