import { Permission } from "@octopusdeploy/octopus-server-client";
import type { UnknownQueryParam, Url } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import { RawAccountTypeDetailsMap } from "~/areas/infrastructure/InfrastructureDetails";
import { client } from "~/clientInstance";
import type { UrlResolver } from "~/components/Navigation/useUrlResolver";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import type { PageRegistration } from "~/routing/pageRegistrations/PageRegistration";
import { allPages } from "~/routing/pageRegistrations/allPageRegistrations";
export interface GlobalSearchPageRegistration {
    Id(urlResolver: UrlResolver): string;
    name: string;
    area: string;
    areaAndKeywords: string;
    isAuthorized(): boolean;
    resolvedUrl(urlResolver: UrlResolver): string;
}
export function createGlobalSearchPageRegistration<RouteParams, QueryParams extends UnknownQueryParam[]>({ RouteLink, RoutePermission, pageRegistration, customLabel, }: {
    pageRegistration: PageRegistration<RouteParams, QueryParams>;
    customLabel?: string;
    RouteLink: () => Url;
    RoutePermission?: PermissionCheckProps;
}): GlobalSearchPageRegistration {
    return {
        Id(urlResolver: UrlResolver) {
            // Assume links are unique across global search. Every page we link to should have a single entry in global search.
            return urlResolver.resolve(RouteLink());
        },
        areaAndKeywords: pageRegistration.searchKeywords.length > 0 ? `${pageRegistration.pageIdentity.pageArea} (${pageRegistration.searchKeywords.join(", ")})` : pageRegistration.pageIdentity.pageArea,
        area: pageRegistration.pageIdentity.pageArea,
        name: customLabel ?? pageRegistration.pageIdentity.pageName,
        resolvedUrl(urlResolver: UrlResolver) {
            return urlResolver.resolve(RouteLink());
        },
        isAuthorized() {
            return RoutePermission === undefined || isAllowed(RoutePermission);
        },
    };
}
const dashboardPages: GlobalSearchPageRegistration[] = [
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.space.pages.dashboardPage,
        RouteLink: () => links.dashboardPage.generateUrl({ spaceId: client.spaceId ?? "" }),
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.space.pages.dashboardConfigurationPage,
        RouteLink: () => links.dashboardConfigurationPage.generateUrl({ spaceId: client.spaceId ?? "" }),
    }),
];
const libraryPages: GlobalSearchPageRegistration[] = [
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.space.childGroups.library.pages.feedsPage,
        RouteLink: () => links.feedsPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.FeedView },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.space.childGroups.library.pages.createFeedPage,
        RouteLink: () => links.createFeedPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.FeedEdit },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.space.childGroups.library.pages.stepTemplatesPage,
        RouteLink: () => links.stepTemplatesPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.ActionTemplateView },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.space.childGroups.library.pages.builtInStepTemplatesPage,
        RouteLink: () => links.builtInStepTemplatesPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.ActionTemplateView },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.space.childGroups.library.pages.communityStepTemplatesPage,
        RouteLink: () => links.communityStepTemplatesPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.ActionTemplateView },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.space.childGroups.library.pages.lifecyclesPage,
        RouteLink: () => links.lifecyclesPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.LifecycleView, wildcard: true },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.space.childGroups.library.pages.createLifecyclePage,
        RouteLink: () => links.createLifecyclePage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.LifecycleCreate, wildcard: true },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.space.childGroups.library.pages.certificatesPage,
        RouteLink: () => links.certificatesPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.CertificateView, wildcard: true },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.space.childGroups.library.pages.archiveCertificatePage,
        RouteLink: () => links.archiveCertificatePage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.CertificateView, wildcard: true },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.space.childGroups.library.pages.createCertificatePage,
        RouteLink: () => links.createCertificatePage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.CertificateCreate, wildcard: true },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.space.childGroups.library.pages.variableSetsPage,
        RouteLink: () => links.variableSetsPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: [Permission.VariableView, Permission.LibraryVariableSetView], wildcard: true },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.space.childGroups.library.pages.tagSetsPage,
        RouteLink: () => links.tagSetsPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.VariableView, wildcard: true },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.space.childGroups.library.pages.createTagSetPage,
        RouteLink: () => links.createTagSetPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.TagSetCreate },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.space.childGroups.library.pages.builtInRepositoryPage,
        RouteLink: () => links.builtInRepositoryPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.FeedView },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.space.childGroups.library.pages.buildInformationOverviewPage,
        RouteLink: () => links.buildInformationOverviewPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.FeedView },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.space.childGroups.library.pages.scriptModulesPage,
        RouteLink: () => links.scriptModulesPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.LibraryVariableSetView, wildcard: true },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.space.childGroups.library.pages.createScriptModulePage,
        RouteLink: () => links.createScriptModulePage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.LibraryVariableSetCreate, wildcard: true },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.space.childGroups.library.pages.gitCredentialsPage,
        RouteLink: () => links.gitCredentialsPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: [Permission.GitCredentialView], wildcard: true },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.space.childGroups.library.pages.createGitCredentialPage,
        RouteLink: () => links.createGitCredentialPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: [Permission.GitCredentialEdit], wildcard: true },
    }),
];
const configurationPages: GlobalSearchPageRegistration[] = [
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.userInvitesPage,
        RouteLink: () => links.userInvitesPage.generateUrl(),
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.UserInvite] },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.auditPage,
        RouteLink: () => links.auditPage.generateUrl(),
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.EventView], wildcard: true },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.auditArchivePage,
        RouteLink: () => links.auditArchivePage.generateUrl(),
        RoutePermission: { permission: [Permission.EventRetentionView, Permission.EventRetentionDelete], wildcard: true },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.backupPage,
        RouteLink: () => links.backupPage.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.featuresPage,
        RouteLink: () => links.featuresPage.generateUrl(),
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.ConfigureServer] },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.licensePage,
        RouteLink: () => links.licensePage.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.licenseUsagePage,
        RouteLink: () => links.licenseUsagePage.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.smtpPage,
        RouteLink: () => links.smtpPage.generateUrl(),
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.ConfigureServer] },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.nodesPage,
        RouteLink: () => links.nodesPage.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.serverSettingsPage,
        RouteLink: () => links.serverSettingsPage.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.thumbprintPage,
        RouteLink: () => links.thumbprintPage.generateUrl(),
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.MachineEdit], wildcard: true },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.maintenancePage,
        RouteLink: () => links.maintenancePage.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.letsEncryptPage,
        RouteLink: () => links.letsEncryptPage.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.configureLetsEncryptPage,
        RouteLink: () => links.configureLetsEncryptPage.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.testPermissionsPage,
        RouteLink: () => links.testPermissionsPage.generateUrl(),
        RoutePermission: { permission: [Permission.TeamEdit, Permission.UserView] },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.subscriptionsPage,
        RouteLink: () => links.subscriptionsPage.generateUrl(),
        RoutePermission: { permission: [Permission.SubscriptionView] },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.createSubscriptionPage,
        RouteLink: () => links.createSubscriptionPage.generateUrl(),
        RoutePermission: { permission: [Permission.SubscriptionCreate] },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.usersPage,
        RouteLink: () => links.usersPage.generateUrl(),
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.UserView] },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.createUserPage,
        RouteLink: () => links.createUserPage.generateUrl(),
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.UserEdit] },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.spacesPage,
        RouteLink: () => links.spacesPage.generateUrl(),
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.SpaceView] },
    }),
    // Excluding creating spaces as an edge case - our UI creates these via a popover.
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.teamsPage,
        RouteLink: () => links.teamsPage.generateUrl(),
        RoutePermission: { permission: [Permission.TeamEdit, Permission.TeamView] },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.userRolesPage,
        RouteLink: () => links.userRolesPage.generateUrl(),
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.UserRoleView] },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.createUserRolePage,
        RouteLink: () => links.createUserRolePage.generateUrl(),
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.UserRoleEdit] },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.diagnosticsPage,
        RouteLink: () => links.diagnosticsPage.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.diagnosticLogsPage,
        RouteLink: () => links.diagnosticLogsPage.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.diagnosticAutoDeployLogsPage,
        RouteLink: () => links.diagnosticAutoDeployLogsPage.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.diagnosticScheduledDeployLogsPage,
        RouteLink: () => links.diagnosticScheduledDeployLogsPage.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.diagnosticSubscriptionLogsPage,
        RouteLink: () => links.diagnosticSubscriptionLogsPage.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.childGroups.settings.pages.settingsListPage,
        RouteLink: () => links.settingsListPage.generateUrl(),
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.ConfigureServer] },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.performancePage,
        RouteLink: () => links.performancePage.generateUrl(),
        RoutePermission: { permission: Permission.ConfigureServer },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.configuration.pages.telemetryPage,
        RouteLink: () => links.telemetryPage.generateUrl(),
        RoutePermission: { permission: Permission.ConfigureServer },
    }),
];
const taskPages: GlobalSearchPageRegistration[] = [
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.pages.tasksPage,
        RouteLink: () => links.tasksPage.generateUrl(),
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.TaskView], wildcard: true },
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.pages.scriptConsolePage,
        RouteLink: () => links.scriptConsolePage.generateUrl(),
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.TaskCreate], wildcard: true },
    }),
];
const infrastructurePages: () => GlobalSearchPageRegistration[] = () => {
    const results: GlobalSearchPageRegistration[] = [
        createGlobalSearchPageRegistration({
            pageRegistration: allPages.childGroups.space.childGroups.infrastructure.pages.infrastructureOverviewPage,
            RouteLink: () => links.infrastructureOverviewPage.generateUrl({ spaceId: client.spaceId ?? "" }),
            RoutePermission: { permission: [Permission.EnvironmentView, Permission.MachineView, Permission.WorkerView], wildcard: true },
        }),
        createGlobalSearchPageRegistration({
            pageRegistration: allPages.childGroups.space.childGroups.infrastructure.pages.infrastructureEnvironmentsPage,
            RouteLink: () => links.infrastructureEnvironmentsPage.generateUrl({ spaceId: client.spaceId ?? "" }),
            RoutePermission: { permission: [Permission.EnvironmentView, Permission.MachineView], wildcard: true },
        }),
        // Excluding creating environments as an edge case - our UI creates these via a popover.
        createGlobalSearchPageRegistration({
            pageRegistration: allPages.childGroups.space.childGroups.infrastructure.pages.deploymentTargetsPage,
            RouteLink: () => links.deploymentTargetsPage.generateUrl({ spaceId: client.spaceId ?? "" }),
            RoutePermission: { permission: [Permission.MachineView], wildcard: true },
        }),
        createGlobalSearchPageRegistration({
            pageRegistration: allPages.childGroups.space.childGroups.infrastructure.pages.newDeploymentTargetPage,
            RouteLink: () => links.newDeploymentTargetPage.generateUrl({ spaceId: client.spaceId ?? "" }),
            RoutePermission: {
                permission: Permission.MachineCreate,
                wildcard: true,
            },
        }),
        createGlobalSearchPageRegistration({
            pageRegistration: allPages.childGroups.space.childGroups.infrastructure.pages.proxiesPage,
            RouteLink: () => links.proxiesPage.generateUrl({ spaceId: client.spaceId ?? "" }),
            RoutePermission: { permission: Permission.ProxyView, wildcard: true },
        }),
        createGlobalSearchPageRegistration({
            pageRegistration: allPages.childGroups.space.childGroups.infrastructure.pages.createProxyPage,
            RouteLink: () => links.createProxyPage.generateUrl({ spaceId: client.spaceId ?? "" }),
            RoutePermission: { permission: Permission.ProxyCreate, wildcard: true },
        }),
        createGlobalSearchPageRegistration({
            pageRegistration: allPages.childGroups.space.childGroups.infrastructure.pages.machinePoliciesPage,
            RouteLink: () => links.machinePoliciesPage.generateUrl({ spaceId: client.spaceId ?? "" }),
            RoutePermission: { permission: Permission.MachinePolicyView, wildcard: true },
        }),
        createGlobalSearchPageRegistration({
            pageRegistration: allPages.childGroups.space.childGroups.infrastructure.pages.createMachinePolicyPage,
            RouteLink: () => links.createMachinePolicyPage.generateUrl({ spaceId: client.spaceId ?? "" }),
            RoutePermission: { permission: Permission.MachinePolicyCreate, wildcard: true },
        }),
        createGlobalSearchPageRegistration({
            pageRegistration: allPages.childGroups.space.childGroups.infrastructure.pages.infrastructureAccountsPage,
            RouteLink: () => links.infrastructureAccountsPage.generateUrl({ spaceId: client.spaceId ?? "" }),
            RoutePermission: { permission: Permission.AccountView, wildcard: true },
        }),
        // Excluding creating accounts as an edge case - our UI creates these via a popover.
        createGlobalSearchPageRegistration({
            pageRegistration: allPages.childGroups.space.childGroups.infrastructure.pages.workerMachinesPage,
            RouteLink: () => links.workerMachinesPage.generateUrl({ spaceId: client.spaceId ?? "" }),
            RoutePermission: { permission: Permission.WorkerView, wildcard: true },
        }),
        createGlobalSearchPageRegistration({
            pageRegistration: allPages.childGroups.space.childGroups.infrastructure.pages.newWorkerMachinePage,
            RouteLink: () => links.newWorkerMachinePage.generateUrl({ spaceId: client.spaceId ?? "" }),
            RoutePermission: {
                permission: Permission.WorkerEdit,
                wildcard: true,
            },
        }),
        createGlobalSearchPageRegistration({
            pageRegistration: allPages.childGroups.space.childGroups.infrastructure.pages.workerPoolsPage,
            RouteLink: () => links.workerPoolsPage.generateUrl({ spaceId: client.spaceId ?? "" }),
            RoutePermission: { permission: Permission.WorkerView, wildcard: true },
        }),
        // Excluding creating worker pools as an edge case - our UI creates these via a popover.
    ];
    // Inject our account types dynamically.
    const accountTypeDetailsMap = RawAccountTypeDetailsMap;
    accountTypeDetailsMap.forEach((accountTypeDetails) => {
        if (accountTypeDetails.types.length === 0) {
            return null;
        }
        results.push(createGlobalSearchPageRegistration({
            customLabel: `Add New ${accountTypeDetails.name} ${accountTypeDetails.name.endsWith("Account") ? "" : "Account"}`,
            pageRegistration: allPages.childGroups.space.childGroups.infrastructure.pages.createInfrastructureAccountPage,
            RouteLink: () => links.createInfrastructureAccountPage.generateUrl({ spaceId: client.spaceId ?? "" }, { accountType: accountTypeDetails.types[0] }),
            RoutePermission: {
                permission: Permission.AccountCreate,
                wildcard: true,
            },
        }));
    });
    return results;
};
const projectPages = (): GlobalSearchPageRegistration[] => {
    return [
        createGlobalSearchPageRegistration({
            pageRegistration: allPages.childGroups.space.childGroups.projects.pages.projectsPage,
            RouteLink: () => links.projectsPage.generateUrl({ spaceId: client.spaceId ?? "" }),
            RoutePermission: { permission: Permission.ProjectView, wildcard: true },
        }),
        createGlobalSearchPageRegistration({
            pageRegistration: allPages.childGroups.space.childGroups.projects.childGroups.importExports.pages.importExportProjectTasksPage,
            RouteLink: () => links.importExportProjectTasksPage.generateUrl({ spaceId: client.spaceId ?? "" }),
            RoutePermission: { permission: Permission.ProjectView, wildcard: true },
        }),
        createGlobalSearchPageRegistration({
            pageRegistration: allPages.childGroups.space.childGroups.projects.childGroups.importExports.pages.importProjectsPage,
            RouteLink: () => links.importProjectsPage.generateUrl({ spaceId: client.spaceId ?? "" }),
            RoutePermission: { permission: Permission.ProjectView, wildcard: true },
        }),
        createGlobalSearchPageRegistration({
            pageRegistration: allPages.childGroups.space.childGroups.projects.childGroups.importExports.pages.exportProjectsPage,
            RouteLink: () => links.exportProjectsPage.generateUrl({ spaceId: client.spaceId ?? "" }),
            RoutePermission: { permission: Permission.ProjectView, wildcard: true },
        }),
    ];
};
const tenantPages: GlobalSearchPageRegistration[] = [
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.space.childGroups.tenants.pages.tenantsPage,
        RouteLink: () => links.tenantsPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.TenantView, wildcard: true },
    }),
];
const userProfilePages: GlobalSearchPageRegistration[] = [
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.currentUser.pages.currentUserLoginsPage,
        RouteLink: () => links.currentUserLoginsPage.generateUrl(),
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.currentUser.pages.currentUserDetailsPage,
        RouteLink: () => links.currentUserDetailsPage.generateUrl(),
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.currentUser.pages.currentUserApiKeysPage,
        RouteLink: () => links.currentUserApiKeysPage.generateUrl(),
    }),
    createGlobalSearchPageRegistration({
        pageRegistration: allPages.childGroups.system.childGroups.currentUser.pages.currentUserActivityPage,
        RouteLink: () => links.currentUserActivityPage.generateUrl(),
        RoutePermission: { permission: Permission.EventView, wildcard: true },
    }),
];
export const pagesToIncludeInSearch = () => {
    return [...dashboardPages, ...libraryPages, ...configurationPages, ...taskPages, ...infrastructurePages(), ...projectPages(), ...tenantPages, ...userProfilePages];
};
