import React from "react";
import type { ReactNode } from "react";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import type { UnknownPageRegistration } from "~/routing/pageRegistrations/PageRegistration";
import { useSetPageRegistrationEffect } from "~/routing/pageRegistrations/PageRegistrationContext";
interface PageProps {
    pageRegistration: UnknownPageRegistration;
    children: ReactNode;
}
export function Page({ pageRegistration, children }: PageProps) {
    useSetPageRegistrationEffect(pageRegistration);
    return <ErrorContextProvider>{children}</ErrorContextProvider>;
}
