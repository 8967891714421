/* eslint-disable @typescript-eslint/no-non-null-assertion,@typescript-eslint/consistent-type-assertions */
import { ActionButton, Checkbox } from "@octopusdeploy/design-system-components";
import type { ChannelResource, ChannelVersionRuleResource, DeploymentActionPackageResource, DeploymentActionResource, DeploymentProcessRepository, LifecycleResource, ProjectResource } from "@octopusdeploy/octopus-server-client";
import { HasGitPersistenceSettings, OctopusError, deploymentActionPackages, displayName, isGitCommit, isGitTag, Permission, TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import * as _ from "lodash";
import * as React from "react";
import type { ActionEvent, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action, useProjectScopedAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import DesignRule from "~/areas/projects/components/Channels/DesignRule";
import { ProjectPageLayout } from "~/areas/projects/components/ProjectPageLayout";
import { useProjectContext } from "~/areas/projects/context";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/withProjectContext";
import { withProjectContext } from "~/areas/projects/context/withProjectContext";
import { repository } from "~/clientInstance";
import { AdvancedTenantTagsSelector } from "~/components/AdvancedTenantSelector/AdvancedTenantSelector";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import { Feature, FeatureToggle } from "~/components/FeatureToggle";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent";
import { Form } from "~/components/FormPaperLayout/Form";
import Markdown from "~/components/Markdown";
import ExternalLink from "~/components/Navigation/ExternalLink";
import type { OverflowMenuDeleteItem, OverflowMenuDialogItem, OverflowMenuDisabledItem, OverflowMenuNavLink } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import RemovableExpandersList from "~/components/RemovableExpandersList";
import TagsList from "~/components/TagsList/TagsList";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import WarningPanel from "~/components/WarningPanel/WarningPanel";
import { ExpandableFormSection, FormSectionHeading, Note, Summary, Text } from "~/components/form";
import MarkdownEditor from "~/components/form/MarkdownEditor/MarkdownEditor";
import { required } from "~/components/form/Validators";
import * as tenantTagsets from "~/components/tenantTagsets";
import type { TagIndex } from "~/components/tenantTagsets";
import Select from "~/primitiveComponents/form/Select/Select";
import NameSummaryWithSlug from "~/primitiveComponents/form/Slugs/NameSummaryWithSlug";
import SlugEditor from "~/primitiveComponents/form/Slugs/SlugEditor";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import StringHelper from "~/utils/StringHelper";
import DeploymentActionPackageMultiSelect, { DeploymentActionPackageReferenceDataItem } from "../../../../components/MultiSelect/DeploymentActionPackageMultiSelect";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import styles from "./style.module.less";
interface EditState extends OptionalFormBaseComponentState<ChannelResource> {
    lifecycles: LifecycleResource[];
    project: ProjectResource;
    deploymentActionsError?: OctopusError;
    deploymentActions: DeploymentActionResource[];
    packageActions: DeploymentActionPackageResource[];
    tagIndex: TagIndex;
    redirectTo?: LinkHref;
}
interface NewChannelEditProps {
    create: true;
}
interface ExistingChannelEditProps {
    create: false;
    channelId: string;
}
type EditProps = (NewChannelEditProps | ExistingChannelEditProps) & WithProjectContextInjectedProps;
type EditPropsInternal = {
    trackAction: AnalyticTrackedActionDispatcher;
} & EditProps;
class EditInternal extends FormBaseComponent<EditPropsInternal, EditState, ChannelResource> {
    private static NoId = "-1";
    constructor(props: EditPropsInternal) {
        super(props);
        this.state = {
            lifecycles: [],
            project: null!,
            deploymentActions: [],
            packageActions: [],
            tagIndex: null!,
        };
        this.throttledVersionRange = _.throttle(this.throttledVersionRange, 500);
        this.throttledTag = _.throttle(this.throttledTag, 500);
    }
    async componentDidMount() {
        const { model: project, projectContextRepository } = this.props.projectContext.state;
        await this.doBusyTask(async () => {
            const lookupData = await Promise.all([this.getDeploymentActionsWithErrorHandlingForGitProjects(project, projectContextRepository.DeploymentProcesses), repository.Lifecycles.all(), tenantTagsets.getTagIndex()]);
            const [deploymentActions, lifecycles, tagIndex] = lookupData;
            let channel: ChannelResource | undefined = undefined;
            if (this.props.create) {
                channel = {
                    Id: null!,
                    ProjectId: project.Id,
                    SpaceId: "",
                    Name: "",
                    Description: "",
                    IsDefault: false,
                    LifecycleId: null!,
                    Rules: [],
                    TenantTags: [],
                    Links: null!,
                };
            }
            else {
                const channels = await repository.Projects.getChannels(project);
                channel = channels.Items.filter((c) => c.Id === (this.props as ExistingChannelEditProps).channelId)[0];
            }
            const packageActions = deploymentActionPackages(deploymentActions);
            const sortedLifecycles = _.sortBy(lifecycles, (l) => l.Name);
            sortedLifecycles.unshift({
                Phases: [],
                Name: "Inherit from project",
                Id: EditInternal.NoId,
                SpaceId: channel.SpaceId,
                ReleaseRetentionPolicy: null!,
                TentacleRetentionPolicy: null!,
                Links: null!,
            });
            this.setState({
                model: channel,
                cleanModel: _.cloneDeep(channel),
                lifecycles: sortedLifecycles,
                deploymentActions,
                packageActions,
                project,
                tagIndex,
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad(this.props.projectContext.state.model.IsVersionControlled) });
    }
    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true}/>;
        }
        const title = this.props.create ? "New Channel" : this.state.model ? this.state.model.Name : StringHelper.ellipsis;
        const overFlowActions = this.getOverflowMenuItems();
        const saveText: string = this.props.create ? "Channel created" : "Channel details updated";
        return (<Form saveText={saveText} model={this.state.model} cleanModel={this.state.cleanModel} onSaveClick={this.saveChannel}>
                {({ FormContent, createSaveAction }) => (<ProjectPageLayout busy={this.state.busy} errors={this.errors} title={title} breadcrumbTitle={"Channels"} breadcrumbPath={links.channelsPage.generateUrl({ spaceId: this.props.projectContext.state.model.SpaceId, projectSlug: this.props.projectContext.state.model.Slug })} breadcrumbsItems={[{ label: "Channels", pageUrl: links.channelsPage.generateUrl({ spaceId: this.props.projectContext.state.model.SpaceId, projectSlug: this.props.projectContext.state.model.Slug }) }]} primaryAction={createSaveAction({ saveButtonLabel: "Save", saveButtonBusyLabel: "Saving" })} overflowActions={overFlowActions}>
                        <FormContent expandAllOnMount={this.props.create}>
                            {this.state.model && (<TransitionAnimation>
                                    <ExpandableFormSection errorKey="Name" title="Name" focusOnExpandAll summary={this.state.model.Name ? Summary.summary(<NameSummaryWithSlug name={this.state.model.Name} slug={this.state.model.Slug}/>) : Summary.placeholder("Please enter a name for your channel")} help="Enter a name for your channel.">
                                        <Text value={this.state.model.Name || ""} onChange={(Name) => this.setModelState({ Name })} label="Channel name" validate={required("Please enter a channel name")} error={this.getFieldError("Name")} autoFocus={true}/>
                                        <Note>
                                            A short, memorable, unique name for this channel. Example: <em>1.x Normal, 2.x Beta</em>
                                        </Note>

                                        {!this.props.create && (<SlugEditor value={this.state.model.Slug || ""} name={this.state.model.Name} originalSlug={this.state.cleanModel?.Slug ?? ""} onChange={(Slug) => this.setModelState({ Slug })} label="Channel slug" validate={required("Please enter a channel slug")} error={this.getFieldError("Slug")}/>)}
                                    </ExpandableFormSection>
                                    <ExpandableFormSection errorKey="description" title="Description" summary={this.descriptionSummary()} help="Enter a description for your channel.">
                                        <MarkdownEditor value={this.state.model.Description} label="Channel description" onChange={(Description) => this.setModelState({ Description })}/>
                                    </ExpandableFormSection>
                                    <ExpandableFormSection errorKey="lifecycle" title="Lifecycle" summary={this.lifecycleSummary()} help="Select a lifecycle for your channel.">
                                        <Select value={this.state.model.LifecycleId || EditInternal.NoId} onChange={this.handleLifecycleChanged} items={this.state.lifecycles.map((pg) => ({ value: pg.Id, text: pg.Name }))} label="Lifecycle" sortItems={false}/>
                                        <Note>
                                            The lifecycle defines how releases can be promoted between environments. Lifecycles can be defined in the{" "}
                                            <InternalLink to={links.lifecyclesPage.generateUrl({ spaceId: this.props.projectContext.state.model.SpaceId })}>Library</InternalLink>. If no lifecycle is selected, the default lifecycle for the project
                                            will be used.
                                        </Note>
                                    </ExpandableFormSection>
                                    <ExpandableFormSection errorKey="defaultChannel" title="Is Default Channel" summary={this.state.model.IsDefault
                        ? Summary.summary(<span>
                                                          <strong>This channel</strong> will be chosen by default when creating releases
                                                      </span>)
                        : Summary.summary(<span>
                                                          <strong>A different channel</strong> will be chosen by default when creating releases
                                                      </span>)} help="This channel will be selected by default when creating releases.">
                                        <Checkbox label="Default channel" value={this.state.model.IsDefault} onChange={(IsDefault) => this.setModelState({ IsDefault })}/>
                                    </ExpandableFormSection>

                                    <FormSectionHeading title="Version Rules"/>
                                    {this.state.deploymentActionsError && <WarningPanel message={`Cannot populate package steps: ${this.state.deploymentActionsError.ErrorMessage}`} warnings={this.state.deploymentActionsError.Errors ?? []}/>}
                                    <RemovableExpandersList helpElement={<div>
                                                Define package version rules that will be enforced when creating releases in this channel.{" "}
                                                <Note>
                                                    Learn about <ExternalLink href={"ChannelVersionRules"}>channel version rules</ExternalLink>.
                                                </Note>
                                            </div>} typeDisplayName={"Version Rule"} data={this.state.model.Rules} listActions={[<ActionButton key="AddVersion" label="Add version rule" onClick={() => this.addVersionRule()}/>]} onRow={(item: ChannelVersionRuleResource, index: number) => {
                        return this.renderVersionRule(item, index, !this.state.deploymentActionsError);
                    }} onRowSummary={(item: ChannelVersionRuleResource) => {
                        return this.ruleSummary(item);
                    }} onRowHelp={() => {
                        return "Define package version rules that will be enforced when creating releases in this channel.";
                    }} onRemoveRowByIndex={this.handleVersionRuleDeleteByIndex}/>

                                    <FeatureToggle feature={Feature.MultiTenancy}>
                                        {((this.state.project && this.state.project.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted) || this.state.model.TenantTags.length > 0) && (<React.Fragment>
                                                <FormSectionHeading title="Tenants"/>
                                                <ExpandableFormSection errorKey="tenantTags" title="Tenants" summary={this.state.model.TenantTags.length > 0
                            ? Summary.summary(<span>
                                                                      Releases in this channel can only be deployed to certain tenants:
                                                                      <TagsList canonicalNames={this.state.model.TenantTags} tagIndex={this.state.tagIndex}/>
                                                                  </span>)
                            : Summary.default("Releases in this channel can be deployed to any tenants")} help={"Choose which tenants the releases in this channel apply to."}>
                                                    <Note>
                                                        Releases in this channel will only be deployed to tenants matching this filter. Clear the filter to make releases in this channel available to all tenants. Learn about{" "}
                                                        <ExternalLink href={"TenantsAndChannels"}>tenants and channels</ExternalLink>.
                                                    </Note>
                                                    <AdvancedTenantTagsSelector emptyFilterMeansAllTenants={true} selectedTenantTags={this.state.model.TenantTags} doBusyTask={this.doBusyTask} onChange={(TenantTags) => this.setModelState({ TenantTags })} showPreviewButton={true}/>
                                                </ExpandableFormSection>
                                            </React.Fragment>)}
                                    </FeatureToggle>
                                </TransitionAnimation>)}
                        </FormContent>
                    </ProjectPageLayout>)}
            </Form>);
    }
    private async getDeploymentActionsWithErrorHandlingForGitProjects(project: ProjectResource, repository: DeploymentProcessRepository) {
        try {
            const process = await repository.get();
            return _.flatMap(process.Steps, (step) => step.Actions);
        }
        catch (exception) {
            if (HasGitPersistenceSettings(project.PersistenceSettings) && OctopusError.isOctopusError(exception)) {
                // We only want to catch the error if it's a Git project and an Octopus exception. Otherwise, error
                // as usual
                this.setState((current) => ({ ...current, deploymentActionsError: exception }));
                return [];
            }
            else {
                throw exception;
            }
        }
    }
    private getOverflowMenuItems() {
        const overFlowActions: (OverflowMenuDialogItem | OverflowMenuDeleteItem | OverflowMenuDisabledItem | OverflowMenuNavLink[])[] = !this.props.create && this.state.model
            ? [
                OverflowMenuItems.deleteItemDefault("channel", this.deleteChannel, {
                    permission: Permission.ProcessEdit,
                    project: this.state.project && this.state.project.Id,
                    tenant: "*",
                }, "The channel and all steps and variables scoped only to this channel will be permanently deleted."),
                [
                    OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ regardingAny: [this.state.model.Id] }), {
                        permission: Permission.EventView,
                        wildcard: true,
                    }),
                ],
            ]
            : [];
        return overFlowActions;
    }
    private ruleSummary(rule: ChannelVersionRuleResource) {
        const summarySpan = (<span>
                Applies to <strong>{rule.ActionPackages.map((pkg) => displayName(pkg)).join(", ")}</strong>
                &nbsp;with a version range matching <strong>{rule.VersionRange}</strong>
                {!!rule.Tag && (<span>
                        {" "}
                        and a pre-release tag matching <strong>{rule.Tag}</strong>
                    </span>)}
            </span>);
        return Summary.summary(summarySpan);
    }
    private lifecycleSummary() {
        if (this.state.model!.LifecycleId) {
            return Summary.summary(this.state.lifecycles.find((l) => l.Id === this.state.model!.LifecycleId)!.Name);
        }
        return Summary.default("Inherited from project");
    }
    private handleLifecycleChanged = (lifecycleId: string | undefined) => {
        this.setModelState({
            LifecycleId: lifecycleId === EditInternal.NoId ? null! : lifecycleId!,
        });
    };
    private addVersionRule() {
        const rule: ChannelVersionRuleResource = {
            Id: null!,
            Tag: "",
            VersionRange: "",
            ActionPackages: [],
            Links: null!,
        };
        this.setState((state) => {
            return {
                model: {
                    ...state.model,
                    Rules: [...state.model!.Rules, rule],
                },
            };
        });
    }
    private handleVersionRuleDeleteByIndex = (index: number) => {
        this.setState((state) => {
            const Rules = state.model!.Rules.filter((x, i) => i !== index);
            return {
                model: {
                    ...state.model,
                    Rules,
                },
            };
        });
    };
    private renderVersionRule(item: ChannelVersionRuleResource, versionRuleIndex: number, hasLoadedSteps: boolean) {
        const allSelectedActionPackages = this.state.model!.Rules.map((r) => r.ActionPackages).reduce((a, b) => a.concat(b), []); // All of the selected packages across all version rules
        function actionIsEqual(a: DeploymentActionPackageResource, b: DeploymentActionPackageResource) {
            return a.DeploymentAction === b.DeploymentAction && (a.PackageReference || "") === (b.PackageReference || "");
        }
        // Once a package is selected it shouldn't show for any other version rules. This is the list
        // of all packages that are not currently selected in any version rules for this channel.
        const autoCompleteActionPackages = this.state.packageActions
            .filter((a) => !allSelectedActionPackages.some((b) => actionIsEqual(a, b)))
            .map((x) => new DeploymentActionPackageReferenceDataItem(x, String(this.state.packageActions.findIndex((y) => actionIsEqual(x, y)))));
        const itemSelectedActionPackages = this.state.packageActions
            .filter((a) => item.ActionPackages.some((b) => actionIsEqual(a, b)))
            .map((x) => new DeploymentActionPackageReferenceDataItem(x, String(this.state.packageActions.findIndex((y) => actionIsEqual(x, y)))));
        // All of the packages that are not available in any steps. This will only be populated for
        // version controlled projects where steps might not exist on the current selected branch.
        const selectedActionPackagesNotInTheProcess = item.ActionPackages.filter((a) => !autoCompleteActionPackages.some((b) => actionIsEqual(a, b.ActionPackage)) && !itemSelectedActionPackages.some((b) => actionIsEqual(a, b.ActionPackage))).map((x) => new DeploymentActionPackageReferenceDataItem(x, displayName(x)));
        const allSelectedActionPackagesForRule = [...itemSelectedActionPackages, ...selectedActionPackagesNotInTheProcess];
        return (<div>
                <DeploymentActionPackageMultiSelect disabled={!hasLoadedSteps} error={this.state.deploymentActionsError && "Error loading package steps"} items={[...autoCompleteActionPackages, ...allSelectedActionPackagesForRule]} value={allSelectedActionPackagesForRule.map((x) => x.Id)} label="Package step(s)" onChange={(actionPackageIndexes) => {
                return this.updateRuleProperty(versionRuleIndex, "ActionPackages", actionPackageIndexes.map((i) => {
                    const actionPackageIndex = Number(i);
                    if (Number.isNaN(actionPackageIndex)) {
                        // Action packages references for steps that are not in the process will have the action + package
                        // name as the identifier (Action Name/PackageName). This will only ever happen for version controlled
                        // processes. We need to keep the selection in place, or the save will wipe the rules that were set
                        // on a different branch.
                        return selectedActionPackagesNotInTheProcess.find((ap) => displayName(ap.ActionPackage) == i)!.ActionPackage;
                    }
                    else {
                        return this.state.packageActions[actionPackageIndex];
                    }
                }));
            }} openOnFocus={false}/>
                {this.props.projectContext.state.model.IsVersionControlled && !this.state.deploymentActionsError && this.renderMessage()}
                <Text value={item.VersionRange || ""} onChange={(value) => this.updateRuleProperty(versionRuleIndex, "VersionRange", value)} label="Version range"/>
                <Note>
                    Use the <ExternalLink href="NuGetVersioning">NuGet</ExternalLink> or <ExternalLink href="MavenVersioning">Maven</ExternalLink> versioning syntax (depending on the feed type) to specify the range of versions to include.
                </Note>
                <Text value={item.Tag || ""} onChange={(value) => this.updateRuleProperty(versionRuleIndex, "Tag", value)} label="Pre-release tag"/>
                <Note>
                    A regular-expression which will select on the <ExternalLink href="NuGetVersioning">SemVer</ExternalLink> pre-release tag or the <ExternalLink href="MavenVersionParser"> Maven</ExternalLink> qualifier.
                </Note>
                <Note>
                    Check our <ExternalLink href="ChannelVersionRuleTags">documentation</ExternalLink> for more information on tags along with examples.
                </Note>
                <div className={styles.designRuleButton}>
                    <OpenDialogButton label="Design rule">
                        <DesignRule model={item} project={this.state.project} deploymentActions={this.state.deploymentActions} onOkClick={(rule) => this.updateRule(versionRuleIndex, rule)}/>
                    </OpenDialogButton>
                </div>
            </div>);
    }
    private renderMessage() {
        const gitRefCanonical = this.props.projectContext.state.gitRef?.CanonicalName;
        let gitRefType = "branch";
        if (isGitCommit(gitRefCanonical)) {
            gitRefType = "commit";
        }
        else if (isGitTag(gitRefCanonical)) {
            gitRefType = "tag";
        }
        return (<Note>
                Showing steps from the <code>{this.props.projectContext.state.gitRef?.Name}</code> {gitRefType}. A different {gitRefType} can be selected from the deployment process screen.
            </Note>);
    }
    private updateRuleProperty = <K extends keyof ChannelVersionRuleResource>(versionRuleIndex: number, propertyName: K, value: ChannelVersionRuleResource[K]) => {
        this.setState((state) => {
            const rules = [...state.model!.Rules];
            rules[versionRuleIndex][propertyName] = value;
            return {
                model: {
                    ...state.model,
                    Rules: rules,
                },
            };
        });
    };
    private updateRule = (index: number, rule: ChannelVersionRuleResource) => {
        this.setState((state) => {
            const rules = [...state.model!.Rules];
            rules[index] = rule;
            return {
                model: {
                    ...state.model,
                    Rules: rules,
                },
            };
        });
    };
    private throttledVersionRange(idx: number, value: string) {
        this.updateRuleProperty(idx, "VersionRange", value);
    }
    private throttledTag(idx: number, value: string) {
        this.updateRuleProperty(idx, "Tag", value);
    }
    private descriptionSummary() {
        return this.state.model!.Description ? Summary.summary(<Markdown markup={this.state.model!.Description}/>) : Summary.placeholder("No channel description provided");
    }
    private deleteChannel = async () => {
        return this.doBusyTask(async () => {
            await repository.Channels.del(this.state.model!);
            this.setState({ redirectTo: links.channelsPage.generateUrl({ spaceId: this.state.project.SpaceId, projectSlug: this.state.project.Slug }) });
        });
    };
    private saveChannel = async () => {
        await this.doBusyTask(async () => {
            const actionEvent: ActionEvent = {
                action: Action.Save,
                resource: "Channel",
            };
            await this.props.trackAction("Save Channel", actionEvent, async () => {
                const result = await repository.Channels.saveToProject(this.state.project, this.state.model!);
                if (this.props.create) {
                    this.setState({ redirectTo: links.channelsPage.generateUrl({ spaceId: this.state.project.SpaceId, projectSlug: this.state.project.Slug }) });
                }
                else {
                    this.setState({
                        model: result,
                        cleanModel: _.cloneDeep(result),
                    });
                }
            });
        });
    };
    static displayName = "EditInternal";
}
// For some reason, assigning this directly confuses typescript, but if we give it an explicit type of React.ComponentType<EditProps> then it works :shrug:
const EditInternalWithExplicitProps: React.ComponentType<EditPropsInternal> = EditInternal;
const EditWithProjectContext = withProjectContext(EditInternalWithExplicitProps);
const Edit = (props: NewChannelEditProps | ExistingChannelEditProps) => {
    const projectContext = useProjectContext();
    const trackAction = useProjectScopedAnalyticTrackedActionDispatch(projectContext.state.model.Id);
    return <EditWithProjectContext trackAction={trackAction} {...props}/>;
};
export { Edit };
