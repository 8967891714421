/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Callout } from "@octopusdeploy/design-system-components";
import type { ActionTemplateParameterResource, CommunityActionTemplateResource } from "@octopusdeploy/octopus-server-client";
import { Permission, ScriptingLanguage } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import { noOp } from "@octopusdeploy/utilities";
import cn from "classnames";
import * as React from "react";
import URI from "urijs";
import { repository } from "~/clientInstance";
import { CodeEditor } from "~/components/CodeEditor/CodeEditor";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import Logo from "~/components/Logo/Logo";
import Markdown from "~/components/Markdown/index";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import type { PrimaryPageAction } from "~/components/PageActions/PageActions";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { Section } from "~/components/Section/Section";
import SimpleDataTable from "~/components/SimpleDataTable/SimpleDataTable";
import Note from "~/primitiveComponents/form/Note/Note";
import { UrlNavigationTabsContainer } from "~/primitiveComponents/navigation/Tabs";
import TabItem from "~/primitiveComponents/navigation/Tabs/TabItem";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import LibraryLayout from "../LibraryLayout/LibraryLayout";
import styles from "./style.module.less";
interface CommunityTemplateProps {
    spaceId: string;
    templateId: string;
}
class ParameterTable extends SimpleDataTable<ActionTemplateParameterResource> {
}
export interface CommunityTemplateState extends DataBaseComponentState {
    redirectTo: LinkHref;
    template?: CommunityActionTemplateResource;
    isLoaded: boolean;
    currentlyInstalledVersion?: number;
    isUpdateAvailable: boolean;
    projectIdToComeBackTo?: string;
}
export class CommunityTemplatePage extends DataBaseComponent<CommunityTemplateProps, CommunityTemplateState> {
    constructor(props: CommunityTemplateProps) {
        super(props);
        this.state = {
            redirectTo: null!,
            isLoaded: false,
            currentlyInstalledVersion: null!,
            isUpdateAvailable: false,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const projectId = this.extractProjectId();
            this.setState({ projectIdToComeBackTo: projectId });
            const template = await repository.CommunityActionTemplates.get(this.props.templateId);
            const currentlyInstalledVersion = (await this.currentlyInstalledVersion(template))!;
            const isUpdateAvailable = !!currentlyInstalledVersion && currentlyInstalledVersion < template.Version;
            this.setState({
                isLoaded: true,
                template,
                currentlyInstalledVersion,
                isUpdateAvailable,
            });
        });
    }
    render() {
        const { busy, template, redirectTo, isUpdateAvailable, currentlyInstalledVersion } = this.state;
        const installOrUpdatePageAction: PrimaryPageAction = {
            type: "button",
            hasPermissions: isAllowed({ permission: Permission.ActionTemplateDelete }),
            label: this.getLabel(),
            onClick: this.installOrUpdate,
        };
        return (<LibraryLayout spaceId={this.props.spaceId}>
                <PaperLayout title={this.isTemplateLoaded(template) ? template.Name : undefined} titleLogo={this.isTemplateLoaded(template) ? <Logo url={template.Links.Logo}/> : undefined} busy={busy} errors={this.errors} primaryAction={installOrUpdatePageAction}>
                    {this.isTemplateLoaded(template) && (<div>
                            {redirectTo && <InternalRedirect to={redirectTo} push={true}/>}
                            <Section>{this.renderTemplateExternalResources(template)}</Section>
                            {isUpdateAvailable ? (<Callout type={"warning"} title={"Update available"}>
                                    Please review <ExternalLink href={template.HistoryUrl}>the history of this template</ExternalLink> to find out what's changed between currently installed version <strong>{currentlyInstalledVersion}</strong> and new
                                    version <strong>{template.Version}</strong>.
                                </Callout>) : (<Section>
                                    <Note>
                                        This is a community contributed step that can be installed into your <InternalLink to={links.stepTemplatesPage.generateUrl({ spaceId: this.props.spaceId })}>step templates</InternalLink> library. Learn more
                                        about <ExternalLink href="CommunityContributedStepTemplates">community steps</ExternalLink>.
                                    </Note>
                                </Section>)}
                            <Section>
                                <Markdown markup={template.Description}/>
                            </Section>
                            <UrlNavigationTabsContainer defaultValue="parameters">
                                <TabItem label="Parameters" value="parameters">
                                    <Section>
                                        <ParameterTable data={template.Parameters} headerColumns={["Name", "Description"]} headerColumnClassNames={[styles.headerColumn, styles.headerColumn]} onRow={(parameter) => [
                    <span>{parameter.Label ? parameter.Label : parameter.Name}</span>,
                    <div>
                                                    <Markdown markup={parameter.HelpText}/>
                                                </div>,
                ]}/>
                                    </Section>
                                </TabItem>
                                <TabItem label="Source code" value="sourceCode">
                                    <Section>
                                        <CodeEditor value={this.getSourceCode(template)} language={this.getSourceCodeSyntax(template)} readOnly={true} allowFullScreen={true} onChange={noOp} showToolbar={true}/>
                                    </Section>
                                </TabItem>
                            </UrlNavigationTabsContainer>
                            <p className={styles.licence}>
                                Provided under the <ExternalLink href="CommunityLibraryLicense">Apache License version 2.0.</ExternalLink>
                            </p>
                        </div>)}
                </PaperLayout>
            </LibraryLayout>);
    }
    private isTemplateLoaded = (template: CommunityActionTemplateResource | undefined): template is CommunityActionTemplateResource => {
        return this.state.isLoaded;
    };
    private renderTemplateExternalResources = (template: CommunityActionTemplateResource) => {
        return (<div className="template-external-resources">
                <small>
                    <span>
                        <strong>Version {template.Version}</strong>
                    </span>
                    <span className={styles.divider}>|</span>
                    <span>
                        By <ExternalLink href={`https://github.com/${template.Author}`}>{template.Author}</ExternalLink>
                        <em className={cn("fa", "fa-users", styles.icon)}/>
                    </span>
                    <span className={styles.divider}>|</span>
                    <span>
                        <ExternalLink href={template.Website}>View website</ExternalLink> <em className={cn("fa", "fa-globe", styles.icon)}/>
                    </span>
                </small>
            </div>);
    };
    private extractProjectId(): string {
        const fullUrl = new URI(window.location);
        const relativeUrl = new URI(fullUrl.fragment());
        let projectId: string = null!;
        relativeUrl.hasQuery("projectId", (value: string) => {
            projectId = value;
        });
        return projectId;
    }
    private installOrUpdate = async () => {
        await this.doBusyTask(async () => {
            const actionTemplate = this.state.isUpdateAvailable ? await repository.CommunityActionTemplates.updateInstallation(this.state.template!) : await repository.CommunityActionTemplates.install(this.state.template!);
            const url = this.state.projectIdToComeBackTo
                ? links.deploymentProcessStepsPage.generateUrl({ spaceId: actionTemplate.SpaceId, projectSlug: this.state.projectIdToComeBackTo }, { actionType: actionTemplate.ActionType, templateId: actionTemplate.Id })
                : links.editStepTemplatePage.generateUrl({ spaceId: actionTemplate.SpaceId, templateId: actionTemplate.Id });
            this.setState({ redirectTo: url });
        });
    };
    private getSourceCode(template: CommunityActionTemplateResource) {
        if (template.Type !== "Octopus.Script") {
            return "Source code is not available for this step template because it is not a script template.";
        }
        return template.Properties["Octopus.Action.Script.ScriptBody"] ? (template.Properties["Octopus.Action.Script.ScriptBody"] as string) : "Not available";
    }
    private getSourceCodeSyntax(template: CommunityActionTemplateResource) {
        return template.Properties["Octopus.Action.Script.Syntax"] ? (template.Properties["Octopus.Action.Script.Syntax"] as ScriptingLanguage) : ScriptingLanguage.PowerShell;
    }
    private async currentlyInstalledVersion(template: CommunityActionTemplateResource) {
        try {
            const actionTemplate = await repository.ActionTemplates.getByCommunityTemplate(template);
            return actionTemplate.Version;
        }
        catch (error) {
            if (error.StatusCode === 404) {
                return null;
            }
            else {
                throw error;
            }
        }
    }
    private getLabel() {
        const primaryAction = this.state.isUpdateAvailable ? "Update" : "Install";
        const secondaryAction = this.state.projectIdToComeBackTo ? " and add step" : "";
        return primaryAction + secondaryAction;
    }
    static displayName = "CommunityTemplatePage";
}
