/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { TaskResource, GetReleaseDeploymentBff } from "@octopusdeploy/octopus-server-client";
import { getTaskStartTime } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import * as React from "react";
import { TaskStatusIcon } from "~/areas/projects/components/TaskStatusIcon/TaskStatusIcon";
import DateFormatter from "~/utils/DateFormatter";
import styles from "./style.module.less";
interface DeploymentDetailsIconProps {
    task: TaskResource<{
        DeploymentId: string;
    }>;
    deployment: GetReleaseDeploymentBff;
    stripTopBottomPadding?: boolean;
}
const DeploymentDetailsIcon: React.StatelessComponent<DeploymentDetailsIconProps> = (props: DeploymentDetailsIconProps) => {
    const task = props.task;
    const deployment = props.deployment;
    return (<div className={cn(styles.taskDetailsContainer, { [styles.stripTopBottomPadding]: props.stripTopBottomPadding })}>
            <TaskStatusIcon item={task} smallIcon={false}/>
            <div className={styles.details}>
                <span className={styles.description}>{deployment.Name}</span>
                <span title={DateFormatter.dateToLongFormat(getTaskStartTime(task))!} className={styles.date}>
                    {DateFormatter.dateToCustomFormat(getTaskStartTime(task), "MMMM Do YYYY")}
                </span>
            </div>
        </div>);
};
DeploymentDetailsIcon.displayName = "DeploymentDetailsIcon"
DeploymentDetailsIcon.displayName = "DeploymentDetailsIcon";
export default DeploymentDetailsIcon;
