/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import AreaTitle from "~/components/AreaTitle/index";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import PaperLayout from "~/components/PaperLayout";
import { useIsPageHeaderVNextEnabled } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import { SkeletonLoadingLayout } from "~/components/SkeletonLoadingLayout/SkeletonLoadingLayout";
import { repository } from "../../clientInstance";
import InternalRedirect from "../../components/Navigation/InternalRedirect/InternalRedirect";
interface RunbookRunToRunbookTaskRedirectProps {
    spaceId: string;
    runbookRunId: string;
}
interface RunbookRunToRunbookTaskRedirectInternalProps extends RunbookRunToRunbookTaskRedirectProps {
    isPageHeaderVNextEnabled: boolean;
}
interface RunbookRunToRunbookTaskRedirectState extends DataBaseComponentState {
    redirectTo: LinkHref;
}
class RunbookRunToRunbookTaskRedirectInternal extends DataBaseComponent<RunbookRunToRunbookTaskRedirectInternalProps, RunbookRunToRunbookTaskRedirectState> {
    constructor(props: RunbookRunToRunbookTaskRedirectInternalProps) {
        super(props);
        this.state = {
            redirectTo: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const runbookRunId = this.props.runbookRunId;
            const runbookRun = await repository.RunbookRuns.get(runbookRunId);
            const runbookSnapshot = await repository.RunbookSnapshots.get(runbookRun.RunbookSnapshotId);
            const path = links.projectRunbookRunDetailPage.generateUrl({
                spaceId: this.props.spaceId,
                projectSlug: runbookSnapshot.ProjectId,
                runbookId: runbookSnapshot.RunbookId,
                runbookRunId: runbookRun.Id,
                runbookSnapshotId: runbookSnapshot.Id,
            });
            this.setState({
                redirectTo: path,
            });
        });
    }
    render() {
        const redirectTo = this.state.redirectTo;
        if (!redirectTo) {
            return this.props.isPageHeaderVNextEnabled ? (<main>
                    <SkeletonLoadingLayout errors={this.errors}/>
                </main>) : (<main>
                    <AreaTitle link={links.projectsPage.generateUrl({ spaceId: this.props.spaceId })} title="Projects"/>
                    <PaperLayout busy={this.state.busy} fullWidth={true} errors={this.errors}/>
                </main>);
        }
        return <InternalRedirect push={false} to={redirectTo}/>;
    }
    static displayName = "RunbookRunToRunbookTaskRedirectInternal";
}
export default function RunbookRunToRunbookTaskRedirect(props: RunbookRunToRunbookTaskRedirectProps) {
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabled();
    return <RunbookRunToRunbookTaskRedirectInternal {...props} isPageHeaderVNextEnabled={isPageHeaderVNextEnabled}/>;
}
