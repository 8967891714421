import * as React from "react";
import styles from "./style.module.less";
type Props = {
    name: string;
    number: string;
    stepType: string;
};
const StepName = ({ name, number, stepType }: Props) => (<div className={styles.container}>
        <div className={styles.stepTitle}>
            <div className={styles.stepType}>{stepType}</div>
            <div>
                <span className={styles.stepNumber}>{number}.</span>
                <span>{name}</span>
            </div>
        </div>
    </div>);
export default StepName;
