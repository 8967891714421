import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import type { ProjectCreatedOption } from "~/areas/projects/components/Projects/AddProject";
import AddProject from "~/areas/projects/components/Projects/AddProject";
import { repository } from "~/clientInstance";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import OnboardingDialog from "~/components/OnboardingDialog/OnboardingDialog";
import addProjectImage from "./assets/img-addproject.svg";
interface AddNewProjectDialogProps {
    open: boolean;
    close: (project?: ProjectResource) => void;
    projectGroupId?: string;
    cancelButtonLabel?: string;
}
function AddNewProjectDialog({ open, close, projectGroupId, cancelButtonLabel }: AddNewProjectDialogProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const navigate = useSpaceAwareNavigation();
    const onProjectCreated = (project: ProjectResource, option?: ProjectCreatedOption) => {
        close(project);
        const projectProcessUrl = option?.vcsRedirect
            ? links.projectVersionControlSettingsPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }, { newlyCreatedProject: true })
            : links.deploymentProcessPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }, { newlyCreatedProject: true });
        navigate.navigate(projectProcessUrl);
    };
    const onToggleHelp = () => {
        dispatchAction("Toggle First Project Dialog Help Section", { action: Action.Toggle, resource: "Project" });
    };
    const helpPanelContent = (<span>
            <strong>Projects</strong> let you manage multiple software applications, each with its own deployment process.
        </span>);
    return (<OnboardingDialog name="add-new-project" helpPanelImage={{ src: addProjectImage, altText: "Add New Project" }} helpPanelContent={helpPanelContent} open={open} close={close} onToggleHelp={onToggleHelp} useBackwardsCompatiblePadding={true}>
            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
            <AddProject spaceId={repository.spaceId!} projectCreated={onProjectCreated} hideEnvironmentConfigWarning={true} projectNameTextBoxLabel={"Project Name"} groupId={projectGroupId} onCancelled={close} cancelButtonLabel={cancelButtonLabel}/>
        </OnboardingDialog>);
}
export default AddNewProjectDialog;
