import React from "react";
import { YouTubePlayer } from "~/components/ContextualHelpLayout/YouTubePlayer";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Note } from "~/components/form";
import image from "../../Images/Variables.svg";
import { OverviewContainer, OverviewHighlightText, TermsText } from "./CommonViews";
const video = {
    youtubeId: "Hd71uhcD61E",
    shortlink: "OnboardingProjectVariablesVideo",
};
export const CommonProjectVariableOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <YouTubePlayer videoId={video.youtubeId}/>
                <p>
                    Define <ExternalLink href="DocumentationVariables">variables</ExternalLink> with values that change based on the scope you've assigned to the variables and the scope of your deployments.
                </p>
                <ImageWithPlaceholder src={image} alt={"Variables"}/>
            </OverviewHighlightText>
            <TermsText>
                <Note heading={"Sharing variables between projects?"}>
                    Instead of defining variables for each project, you can define a set of variables into a <ExternalLink href="LibraryVariableSets">Library Variable Set</ExternalLink> and then access them from every project that needs them.
                </Note>
            </TermsText>
        </OverviewContainer>);
};
