import { CommunicationStyle } from "@octopusdeploy/octopus-server-client";
import { EndpointRegistrationKeyValues } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { EndpointDialogCard } from "~/areas/infrastructure/components/MachineSettings/Endpoints/EndpointCard";
import { KubernetesAgentConfigurationDialog } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/KubernetesAgentConfigurationDialog";
import kubernetesCategory from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesCategoryDefinition";
import type { MachineRegistration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import styles from "~/areas/infrastructure/components/MachineSettings/Endpoints/styles.module.less";
import { EarlyAccessChip } from "~/components/Chips";
import { KubernetesAgent } from "~/components/Images/MachineSettings/KubernetesAgent";
const kubernetesAgentEndpointRegistration: MachineRegistration = {
    key: EndpointRegistrationKeyValues.KubernetesTentacle,
    displayOrder: 10,
    communicationStyle: CommunicationStyle.KubernetesTentacle,
    name: "Kubernetes Agent",
    categories: [kubernetesCategory],
    discoverable: false,
    visibilityFeatureToggle: "KubernetesTentacleFeatureToggle",
    renderCard: ({ registration, category }) => (<EndpointDialogCard logo={<KubernetesAgent className={styles.centreThumbnail} title={registration.name}/>} registrationName={registration.name} description="Install an agent to execute deployments on your cluster. No extra workers needed." chip={<EarlyAccessChip noMargin/>} renderDialog={(renderProps) => <KubernetesAgentConfigurationDialog open={renderProps.open} closeDialog={renderProps.closeDialog} openDialog={renderProps.openDialog}/>}/>),
};
export { kubernetesAgentEndpointRegistration };
