/* eslint-disable @typescript-eslint/no-explicit-any */
import { DatePicker as DatePickerPopover } from "@octopusdeploy/design-system-components";
import moment from "moment";
import type { Moment } from "moment";
import * as React from "react";
import type { Errors } from "~/components/DataBaseComponent";
import combineDateTime from "../combineDateTime";
import styles from "./style.module.less";
interface DatePickerProps {
    date: Moment;
    onDateChanged(date: Moment): void;
    utcOffset: number;
}
interface DatePickerState {
    datePopupValue: Moment;
    busy?: Promise<void>;
    errors?: Errors;
}
//eslint-disable-next-line react/no-unsafe
export default class DatePicker extends React.Component<DatePickerProps, DatePickerState> {
    constructor(props: DatePickerProps) {
        super(props);
        this.state = {
            datePopupValue: moment(props.date),
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps: DatePickerProps) {
        this.setState({
            datePopupValue: moment(nextProps.date),
        });
    }
    handleDateChanged = (date: string) => {
        const combined = combineDateTime(date, this.props.date, this.props.utcOffset);
        this.props.onDateChanged(combined);
    };
    render() {
        const datePopupValue = moment(this.state.datePopupValue).toDate();
        return (<div className={styles.datePicker}>
                <DatePickerPopover value={datePopupValue} onChange={(value: Date) => {
                this.setState({ datePopupValue: moment(value) });
                // Don't change the time component
                const justDate = moment(value).format("YYYY-MM-DD");
                this.handleDateChanged(justDate);
            }} variant="dialog" format={"yyyy-MM-dd"}/>
            </div>);
    }
    static displayName = "DatePicker";
}
