/* eslint-disable no-eq-null */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Callout } from "@octopusdeploy/design-system-components";
import type { CommunicationStyle, NewWorkerMachineResource, WorkerMachineResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import URI from "urijs";
import { useOnMachineSavedDispatch } from "~/areas/infrastructure/hooks/useOnMachineSavedDispatch";
import { repository } from "~/clientInstance";
import { workerPoolChipList } from "~/components/Chips";
import FormPage from "~/components/FormPage/FormPage";
import { WorkerPoolMultiSelect } from "~/components/MultiSelect/WorkerPoolMultiSelect";
import ExternalLink from "~/components/Navigation/ExternalLink";
import type { PageAction } from "~/components/PageActions/PageActions";
import type { BreadcrumbProps } from "~/components/PaperLayout";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { ExpandableFormSection, FormSectionHeading } from "~/components/form";
import Summary from "~/components/form/Sections/Summary";
import type { BaseMachineSettingsProps, MachineSettingsInitialData } from "../BaseMachineSettings/BaseMachineSettings";
import { BaseMachineSettingsLayout, LoadMachineSettingsData } from "../BaseMachineSettings/BaseMachineSettings";
import CreateNewMachineResource from "../BaseMachineSettings/NewMachineResourceFactory";
interface WorkerMachineSettingsPageProps {
    spaceId: string;
    machineId?: string;
}
interface WorkerMachineSettingsInternalProps extends BaseMachineSettingsProps<WorkerMachineResource, NewWorkerMachineResource> {
    spaceId: string;
}
const IsNew = "IsNew";
const MachineSettingsFormPage = FormPage<MachineSettingsInitialData>();
export function WorkerMachineSettingsPage({ spaceId, machineId }: WorkerMachineSettingsPageProps) {
    const location = useLocation();
    const query = URI(location.search);
    const querySearch = query.search(true);
    const breadcrumbs: BreadcrumbProps = machineId
        ? {}
        : {
            breadcrumbPath: querySearch.workerPoolId ? links.newWorkerMachineWithWorkerPoolPage.generateUrl({ spaceId, workerPoolId: querySearch.workerPoolId }) : links.newWorkerMachinePage.generateUrl({ spaceId }),
            breadcrumbTitle: "New Worker",
        };
    const isBuiltInWorkerEnabled = useSelector((state: GlobalState) => state.configurationArea.features.isBuiltInWorkerEnabled);
    const onMachineSaved = useOnMachineSavedDispatch();
    return (<MachineSettingsFormPage title="Settings" load={async () => {
            const machine = machineId ? await repository.Workers.get(machineId) : IsNew;
            const communicationStyle = machine === IsNew ? (querySearch.type as CommunicationStyle) : machine.Endpoint.CommunicationStyle;
            const machineSettingData = await LoadMachineSettingsData(machine, communicationStyle);
            return {
                ...machineSettingData,
                workerPools: (await repository.WorkerPools.all()).filter((x) => x.CanAddWorkers),
            };
        }} renderWhenLoaded={(initialData) => (<WorkerMachineSettingsLayoutInternal initialData={initialData} query={query} breadcrumbs={breadcrumbs} rootLink={links.workerMachinesPage.generateUrl({ spaceId })} isBuiltInWorkerEnabled={isBuiltInWorkerEnabled} isMultiTenancyEnabled={false} isWorkerMachine={true} repository={repository.Workers} onMachineSaved={onMachineSaved} spaceId={spaceId}/>)}/>);
}
class WorkerMachineSettingsLayoutInternal extends BaseMachineSettingsLayout<WorkerMachineSettingsInternalProps, WorkerMachineResource, NewWorkerMachineResource> {
    constructor(props: WorkerMachineSettingsInternalProps) {
        super(props);
    }
    protected enableDisablePermission(): Permission {
        return Permission.WorkerEdit;
    }
    protected createPermission(): Permission {
        return Permission.WorkerEdit;
    }
    protected editPermission(): Permission {
        return Permission.WorkerEdit;
    }
    protected deletePermission(): Permission {
        return Permission.WorkerEdit;
    }
    protected machineLink(spaceId: string, machineId: string): LinkHref {
        return links.workerMachineSettingsPage.generateUrl({ spaceId, machineId });
    }
    protected getModel(location: URI, defaultMachinePolicyId: string): NewWorkerMachineResource {
        const query = location.search(true);
        const workerPoolIds = query.workerPool;
        return {
            ...CreateNewMachineResource(location, defaultMachinePolicyId),
            WorkerPoolIds: workerPoolIds ? [workerPoolIds] : [],
        };
    }
    protected mapToModel(model: WorkerMachineResource): NewWorkerMachineResource {
        return {
            ...model,
        };
    }
    protected renderTenantComponent(): JSX.Element | null {
        return null;
    }
    protected renderTypeSpecificComponents(): JSX.Element {
        return (<div>
                <FormSectionHeading title="Workers"/>

                <ExpandableFormSection errorKey="WorkerPoolIds" title="Worker Pool" summary={this.workerPoolsSummary()} help={"Choose at least one worker pool for the machine."}>
                    <WorkerPoolMultiSelect items={this.props.initialData.workerPools} onChange={(WorkerPoolIds) => this.setModelState({ WorkerPoolIds })} value={this.state.model.WorkerPoolIds}/>

                    {this.selectedPoolsChangesBuiltinSetting() && (<Callout type={"warning"} title={"Built-in Worker will be disabled"}>
                            Adding Workers to the default pool turns off the Built-in Worker and changes where some steps are run. Learn about the <ExternalLink href="AddingWorkerToDefaultPool">Built-in Worker</ExternalLink>.
                        </Callout>)}
                </ExpandableFormSection>
            </div>);
    }
    protected getSecondaryPageActions(): PageAction[] {
        return [
            {
                type: "navigate",
                buttonType: "secondary",
                path: links.newWorkerMachinePage.generateUrl({ spaceId: this.props.spaceId }),
                label: "Add another",
                hasPermissions: isAllowed({ permission: Permission.MachineCreate, environment: "*", tenant: "*" }),
            },
        ];
    }
    private selectedPoolsChangesBuiltinSetting(): boolean {
        if (this.props.initialData.workerPoolSummaries == null) {
            return false;
        }
        const defaultPoolSummary = this.props.initialData.workerPoolSummaries.WorkerPoolSummaries.filter((wp) => wp.WorkerPool.IsDefault)[0];
        return defaultPoolSummary.TotalMachines === 0 && this.state.model.WorkerPoolIds.includes(defaultPoolSummary.WorkerPool.Id);
    }
    private workerPoolsSummary() {
        return this.state.model.WorkerPoolIds.length > 0 ? Summary.summary(workerPoolChipList(this.props.initialData.workerPools, this.state.model.WorkerPoolIds)) : Summary.placeholder("No worker pools");
    }
}
