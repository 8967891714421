/* eslint-disable @typescript-eslint/no-explicit-any */
import type { TaskResource, GetReleaseDeploymentBff } from "@octopusdeploy/octopus-server-client";
import { getTaskStartTime } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import * as React from "react";
import { DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import DateFormatter from "~/utils/DateFormatter";
import InternalLink from "../../../../../components/Navigation/InternalLink/InternalLink";
import DeploymentDetailsIcon from "./DeploymentDetailsIcon";
import styles from "./style.module.less";
interface DeploymentChildRowProps {
    deployment: GetReleaseDeploymentBff;
    task: TaskResource<{
        DeploymentId: string;
    }>;
    showChildRows: boolean;
    [others: string]: any;
}
const DeploymentChildRow: React.StatelessComponent<DeploymentChildRowProps> = (props) => {
    const { className, showChildRows, deployment, task } = props;
    return (<DataTableRow key={`${deployment.Id}`} className={cn(className, showChildRows ? styles.visible : styles.hidden, styles.deploymentsTableRow)}>
            <DataTableRowColumn />
            <DataTableRowColumn>
                <InternalLink to={links.taskPage.generateUrl({ taskId: deployment.TaskId })} accessibleName={`View deployment task for ${deployment.Name} queued for or started at ${getTaskStartTime(task)}`}>
                    <DeploymentDetailsIcon task={task} deployment={deployment} stripTopBottomPadding={true}/>
                </InternalLink>
            </DataTableRowColumn>
            <DataTableRowColumn>
                <span className={styles.deploymentDate}>{DateFormatter.dateToLongFormat(getTaskStartTime(task))}</span>
            </DataTableRowColumn>
            <DataTableRowColumn />
        </DataTableRow>);
};
DeploymentChildRow.displayName = "DeploymentChildRow"
DeploymentChildRow.displayName = "DeploymentChildRow";
export default DeploymentChildRow;
