/* eslint-disable @typescript-eslint/no-explicit-any */
import { ProjectFormPageLayout } from "app/areas/projects/components/ProjectFormPageLayout";
import * as React from "react";
import type { FormPaperLayoutProps } from "~/components/FormPaperLayout/FormPaperLayout";
export type TabbedFormPaperLayoutProps = FormPaperLayoutProps & {
    tabNavigation?: React.ComponentType<any>;
    hideTabNavigation?: boolean;
};
export const TabbedFormPaperLayout: React.FC<TabbedFormPaperLayoutProps> = (props) => {
    const { tabNavigation: TabNavigationComponent, hideTabNavigation = false, disableAnimations = true, children, ...rest } = props;
    return (<ProjectFormPageLayout disableAnimations {...rest}>
            {TabNavigationComponent && !hideTabNavigation && <TabNavigationComponent key="tabs"/>}
            {children}
        </ProjectFormPageLayout>);
};
TabbedFormPaperLayout.displayName = "TabbedFormPaperLayout"
TabbedFormPaperLayout.defaultProps = {
    fullWidth: true,
};
export default TabbedFormPaperLayout;
