/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { DeployToWildflyProperties } from "@octopusdeploy/legacy-action-properties";
import { ActionExecutionLocation, FeedType, GetPrimaryPackageReference, InitialisePrimaryPackageReference, PackageSelectionMode, SetPrimaryPackageReference } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { ProcessFeedLookup, useFeedsFromContext, useRefreshFeedsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { repository } from "~/clientInstance";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import PackageDownloadOptions from "~/components/PackageDownloadOptions/PackageDownloadOptions";
import DeferredPackageSelector from "~/components/PackageSelector/DeferredPackageSelector";
import ExpandableFormSection from "~/components/form/Sections/ExpandableFormSection";
import ExpanderSectionHeading from "~/components/form/Sections/FormSectionHeading";
import CommonSummaryHelper from "~/utils/CommonSummaryHelper/CommonSummaryHelper";
import { DataBaseComponent } from "../../DataBaseComponent";
import type { ActionSummaryProps } from "../actionSummaryProps";
import type { ActionWithFeeds } from "../commonActionHelpers";
import { getChangesToPackageReference } from "../getChangesToPackageReference";
import type { ActionEditProps } from "../pluginRegistry";
import pluginRegistry from "../pluginRegistry";
class DeployToWildflyActionSummary extends BaseComponent<ActionSummaryProps> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }
    render() {
        return <div>Deploy a Java application to Wildfly 10+ and Red Hat JBoss EAP 6+</div>;
    }
    static displayName = "DeployToWildflyActionSummary";
}
type DeployToWildflyActionEditState = {};
type DeployToWildflyActionEditProps = ActionEditProps<DeployToWildflyProperties>;
type DeployToWildflyActionEditInternalProps = DeployToWildflyActionEditProps & ActionWithFeeds;
class DeployToWildflyActionEditInternal extends DataBaseComponent<DeployToWildflyActionEditInternalProps, DeployToWildflyActionEditState> {
    constructor(props: DeployToWildflyActionEditInternalProps) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, this.props.feeds), true);
    }
    render() {
        // The package is initialized in componentDidMount, but render gets called before the update is propagated
        if (!this.props.packages || this.props.packages.length === 0) {
            return null;
        }
        const pkg = GetPrimaryPackageReference(this.props.packages);
        const help = this.props.feeds.length > 0 ? (<span>
                    This step is used to deploy a package to one or more machines which may be sourced from an external feed or the Octopus built-in feed. You can configure the remote machines to deploy to in the{" "}
                    <InternalLink to={links.infrastructureRootRedirect.generateUrl({ spaceId: repository.spaceId! })} openInSelf={false}>
                        Infrastructure
                    </InternalLink>{" "}
                    tab.
                </span>) : (<span>Choose the package you wish to deploy.</span>);
        return (<div>
                <ExpanderSectionHeading title="Package Details"/>
                <ExpandableFormSection errorKey="Octopus.Action.Package.PackageId|Octopus.Action.Package.FeedId" isExpandedByDefault={this.props.expandedByDefault} title="Package" summary={CommonSummaryHelper.deferredPackageSummary(pkg, this.props.feeds)} help={help}>
                    <DeferredPackageSelector packageId={pkg.PackageId} feedId={pkg.FeedId} onPackageIdChange={(packageId) => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))} onFeedIdChange={(feedId) => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))} packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")} feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")} projectId={this.props.projectId!} feeds={this.props.feeds} localNames={this.props.localNames!} feedType={[FeedType.Nuget, FeedType.BuiltIn, FeedType.Maven, FeedType.ArtifactoryGeneric]} refreshFeeds={this.loadFeeds} parameters={this.props.parameters!} packageSelectionMode={pkg.Properties["SelectionMode"]} packageSelectionModeError={this.props.getFieldError("SelectionMode")} onPackageSelectionModeChange={(value) => this.props.setPackages(SetPrimaryPackageReference(getChangesToPackageReference(value), this.props.packages))} packageParameterName={pkg.Properties["PackageParameterName"]} packageParameterError={this.props.getFieldError("PackageParameterName")} onPackageParameterChange={(packageParameter) => this.props.setPackages(SetPrimaryPackageReference({ Properties: { ...pkg.Properties, PackageParameterName: packageParameter } }, this.props.packages))}/>
                    {pkg.Properties["SelectionMode"] === PackageSelectionMode.Immediate && (<ProcessFeedLookup feedId={pkg.FeedId}>
                            {(feed) => (<PackageDownloadOptions packageAcquisitionLocation={pkg.AcquisitionLocation} onPackageAcquisitionLocationChanged={(acquisitionLocation) => this.props.setPackages(SetPrimaryPackageReference({ AcquisitionLocation: acquisitionLocation }, this.props.packages))} feed={feed!} projectId={this.props.projectId!} localNames={this.props.localNames!}/>)}
                        </ProcessFeedLookup>)}
                </ExpandableFormSection>
            </div>);
    }
    private loadFeeds = async () => {
        await this.loadFeeds();
    };
    static displayName = "DeployToWildflyActionEditInternal";
}
export function DeployToWildflyActionEdit(props: React.PropsWithChildren<DeployToWildflyActionEditProps>) {
    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();
    return <DeployToWildflyActionEditInternal {...props} feeds={feeds} refreshFeeds={refreshFeeds}/>;
}
pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnTarget,
    actionType: "Octopus.WildFlyDeploy",
    summary: (properties, targetRolesAsCSV, packages) => <DeployToWildflyActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} packages={packages}/>,
    editSections: { default: DeployToWildflyActionEdit },
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => true,
    features: {
        optional: ["Octopus.Features.CustomScripts", "Octopus.Features.JsonConfigurationVariables", "Octopus.Features.SubstituteInFiles"],
        permanent: ["Octopus.Features.WildflyDeployCLI"],
    },
});
