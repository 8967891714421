import type { GitBranchResource, ProjectResource, GitRef } from "@octopusdeploy/octopus-server-client";
import { HasGitPersistenceSettings, isGitBranch, toGitBranch } from "@octopusdeploy/octopus-server-client";
import type { GitRefOption } from "~/areas/projects/components/GitRefDropDown/GitRefOption";
import { repository } from "~/clientInstance";
const getTextValuePairForBranch = (branch: GitBranchResource, project: Readonly<ProjectResource>): GitRefOption => {
    if (!HasGitPersistenceSettings(project.PersistenceSettings))
        throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");
    const isDefaultBranch = branch.Name === project.PersistenceSettings.DefaultBranch;
    return {
        text: `${branch.Name} ${isDefaultBranch ? "(default)" : ""}`,
        value: branch.CanonicalName,
        canWrite: !branch.IsProtected,
    };
};
const buildBranchNamesList = (branches: GitBranchResource[], project: Readonly<ProjectResource>): GitRefOption[] => {
    if (!HasGitPersistenceSettings(project.PersistenceSettings))
        throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");
    return branches.map((branch) => getTextValuePairForBranch(branch, project));
};
const getFilteredBranchesList = async (project: Readonly<ProjectResource>, filterTerm: string): Promise<GitRefOption[]> => {
    if (!HasGitPersistenceSettings(project.PersistenceSettings))
        throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");
    const filteredBranchResources = await repository.Projects.searchBranches(project, filterTerm);
    return buildBranchNamesList(filteredBranchResources.Items, project);
};
const getBranchesList = async (project: Readonly<ProjectResource>, branchResources: GitBranchResource[], selectedRef: GitRef | undefined): Promise<GitRefOption[]> => {
    if (!HasGitPersistenceSettings(project.PersistenceSettings))
        throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");
    const vcsPersistenceSettings = project.PersistenceSettings;
    let branches = branchResources;
    // Add the currently selected branch to the list (if necessary)
    if (selectedRef && isGitBranch(selectedRef) && !branches.find((item) => item.CanonicalName === selectedRef)) {
        const selectedBranchResource = await repository.Projects.tryGetBranch(project, selectedRef);
        // It may be that the current branch has been deleted directly in the repo. Therefore, only add it if it was found.
        if (selectedBranchResource !== null) {
            branches = [selectedBranchResource, ...branches];
        }
    }
    // Add the default branch to the list (if necessary)
    if (!branches.find((item) => item.CanonicalName === vcsPersistenceSettings.DefaultBranch || item.CanonicalName === toGitBranch(vcsPersistenceSettings.DefaultBranch))) {
        // It may be that the default branch has been deleted directly in the repo. Therefore, only add it if it was found.
        const branchResource = await repository.Projects.tryGetBranch(project, vcsPersistenceSettings.DefaultBranch);
        if (branchResource !== null) {
            branches = [branchResource, ...branches];
        }
    }
    return buildBranchNamesList(branches, project);
};
export { getBranchesList, getFilteredBranchesList, getTextValuePairForBranch, buildBranchNamesList };
