import { css } from "@emotion/css";
import { borderWidth, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { DeploymentFreezeDetailEnvironment, DeploymentFreezeDetailProject } from "@octopusdeploy/octopus-server-client";
import React, { useEffect, useState } from "react";
import { DeploymentFreezeScopeTableRow } from "~/areas/configuration/components/DeploymentFreezes/ProjectScopeTable/DeploymentFreezeScopeTableRow";
import NumberedPagingBar from "~/components/PagingBaseComponent/NumberedPagingBar";
import { DataTable, DataTableBody, DataTableHeader, DataTableRow, DataTableRowHeaderColumn } from "~/primitiveComponents/dataDisplay/DataTable/index";
interface DeploymentFreezeScopeTableProps {
    projectDetails: DeploymentFreezeDetailProject[];
    onRemove: (project: DeploymentFreezeDetailProject) => void;
    onEnvironmentUpdate: (project: DeploymentFreezeDetailProject, selectedEnvironments: DeploymentFreezeDetailEnvironment[]) => void;
}
export const DeploymentFreezeScopeTable = ({ projectDetails, onRemove, onEnvironmentUpdate }: DeploymentFreezeScopeTableProps) => {
    const pageSize = 30;
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
    const [paginatedProjects, setPaginatedProjects] = useState<DeploymentFreezeDetailProject[]>(projectDetails);
    useEffect(() => {
        const projects = projectDetails.slice(pageSize * currentPageIndex, pageSize * (currentPageIndex + 1));
        setPaginatedProjects(projects);
    }, [projectDetails, currentPageIndex]);
    return (<div>
            <DataTable className={tableStyles} title="Deployment Freeze Scope Table">
                <DataTableHeader>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Project</DataTableRowHeaderColumn>
                        <DataTableRowHeaderColumn>Environments</DataTableRowHeaderColumn>
                        <DataTableRowHeaderColumn />
                    </DataTableRow>
                </DataTableHeader>
                <DataTableBody>
                    {paginatedProjects.map((project) => (<DeploymentFreezeScopeTableRow key={project.Id} project={project} onRemove={onRemove} onEnvironmentUpdate={onEnvironmentUpdate}/>))}
                </DataTableBody>
            </DataTable>
            <NumberedPagingBar totalItems={projectDetails.length} currentPageIndex={currentPageIndex} pageSize={pageSize} onPageSelected={(_, index) => setCurrentPageIndex(index)}/>
        </div>);
};
const tableBorder = `${borderWidth[1]} solid ${themeTokens.color.border.primary}`;
const tableStyles = css({
    width: "100%",
    th: {
        padding: `${space[12]} ${space[16]}`,
        paddingRight: 0,
        textAlign: "left",
        font: text.table.cell.default.medium,
        position: "relative",
    },
    td: {
        padding: `${space[8]} ${space[12]}`,
        borderTop: tableBorder,
        verticalAlign: "middle",
    },
});
