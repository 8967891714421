import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { BranchSelectorVNext } from "~/areas/projects/components/BranchSelector/BranchSelectorVNext";
import { ProjectCreatePullRequestLink } from "~/areas/projects/components/ProjectCurrentBranchDisplay/ProjectCreatePullRequestLink";
import { ProjectCurrentBranchDisplay } from "~/areas/projects/components/ProjectCurrentBranchDisplay/ProjectCurrentBranchDisplay";
import { useIsPageVersionControlled } from "~/areas/projects/context/useIsPageVersionControlled";
import { useIsPageHeaderVNextEnabled } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
export function ProjectPageTitleAccessory() {
    const isVersionControlled = useIsPageVersionControlled();
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabled();
    if (!isVersionControlled) {
        return null;
    }
    return isPageHeaderVNextEnabled ? <PageHeaderBranchSelector /> : <ProjectCurrentBranchDisplay />;
}
function PageHeaderBranchSelector() {
    return (<div className={branchSelectorContainer}>
            <BranchSelectorVNext />
            <ProjectCreatePullRequestLink />
        </div>);
}
const branchSelectorContainer = css({
    display: "flex",
    gap: space["16"],
    alignItems: "center",
});
