import type { ServiceAccountOidcIdentityResource } from "@octopusdeploy/octopus-server-client/src/repositories/serviceAccountOidcIdentitiesRepository";
import * as React from "react";
import Dialog from "~/components/Dialog/Dialog";
import { ConfigureServiceAccountOidcIdentityDialogLayout } from "./ConfigureServiceAccountOidcIdentityDialogLayout";
type EditServiceAccountOidcIdentityDialogProps = {
    externalId: string;
    oidcIdentity: ServiceAccountOidcIdentityResource;
    onSave: (identity: ServiceAccountOidcIdentityResource) => Promise<unknown>;
    onCancel: () => unknown;
};
export function EditServiceAccountOidcIdentityDialog({ externalId, oidcIdentity, onSave, onCancel }: EditServiceAccountOidcIdentityDialogProps) {
    return (<Dialog open wide>
            <ConfigureServiceAccountOidcIdentityDialogLayout externalId={externalId} oidcIdentity={oidcIdentity} onSave={onSave} onCancel={onCancel}/>
        </Dialog>);
}
