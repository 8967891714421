import type { EnvironmentResource, FeatureToggleResource, TenantResource, ToggleResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import _ from "lodash";
import { useState } from "react";
import * as React from "react";
import { FeatureTogglesDataTableNameCell } from "~/areas/projects/components/FeatureToggles/FeatureTogglesDataTableNameCell";
import ToggleScope from "~/areas/projects/components/FeatureToggles/ToggleScope";
import { ProjectPageLayout } from "~/areas/projects/components/ProjectPageLayout";
import { useProjectContext } from "~/areas/projects/context/index";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent";
import { useDoBusyTaskEffect, DataBaseComponent } from "~/components/DataBaseComponent";
import { GroupedDataTable } from "~/components/GroupedDataTable/GroupedDataTable";
import type { PrimaryPageAction } from "~/components/PageActions/PageActions";
interface FeatureTogglesPageProps {
    spaceId: string;
}
export default class FeatureTogglesPage extends DataBaseComponent<FeatureTogglesPageProps> {
    render() {
        return <FeatureTogglesLayout spaceId={this.props.spaceId} doBusyTask={this.doBusyTask}/>;
    }
    static displayName = "FeatureTogglesPage";
}
interface FeatureTogglesLayoutProps {
    spaceId: string;
    doBusyTask: DoBusyTask;
}
interface FeatureTogglesState {
    features: FeatureToggleResource[];
}
function FeatureTogglesLayout({ spaceId, doBusyTask }: FeatureTogglesLayoutProps) {
    const projectContext = useProjectContext();
    const project = projectContext.state && projectContext.state.model;
    const [featureTogglesState, setFeatureTogglesState] = useState<FeatureTogglesState | null>(null);
    const [environmentsById, setEnvironmentsById] = useState<_.Dictionary<EnvironmentResource>>({});
    const [tenantsById, setTenantsById] = useState<_.Dictionary<TenantResource>>({});
    useDoBusyTaskEffect(doBusyTask, async () => {
        const featureTogglesResponse = await repository.FeatureToggles.list(projectContext.state.model);
        const environments = await repository.Environments.all();
        // Fetch all the tenants that are configured on toggles. Because we don't need to select tenants on this page, we can avoid tenants.all()
        const tenantIds = _.chain(featureTogglesResponse.Features)
            .flatMap((f) => f.Toggles)
            .flatMap((t) => t.TenantIds)
            .uniq()
            .value();
        const tenants = await repository.Tenants.all({ ids: tenantIds });
        setEnvironmentsById(_.keyBy(environments, (env) => env.Id));
        setTenantsById(_.keyBy(tenants, (t) => t.Id));
        setFeatureTogglesState({ features: featureTogglesResponse.Features });
    }, []);
    if (!project) {
        throw new Error("Failed to find project from context. This should not happen.");
    }
    if (!featureTogglesState)
        return null;
    const featureToggleRows = buildToggleRows(featureTogglesState.features);
    const primaryAction: PrimaryPageAction = {
        type: "navigate",
        label: "Add Feature Toggle",
        path: links.createFeatureTogglePage.generateUrl({ spaceId: projectContext.state.model.SpaceId, projectSlug: project.Slug }),
    };
    const toggleRow = (toggle: ToggleResource) => {
        const environment = environmentsById[toggle.DeploymentEnvironmentId];
        const tenants = _.map(toggle.TenantIds, (id) => tenantsById[id]);
        return <ToggleScope toggle={toggle} environmentName={environment.Name} tenants={tenants}/>;
    };
    return (<ProjectPageLayout title="Feature Toggles" fullWidth={true} pageActions={undefined} primaryAction={primaryAction}>
            <GroupedDataTable data={featureToggleRows} columns={[
            {
                title: "Feature",
                width: "20%",
                render: (data) => renderFeatureNameCell(data),
            },
            {
                title: "Enabled For",
                width: "20%",
                render: (data) => (data.Toggle ? toggleRow(data.Toggle) : <div />),
            },
        ]} getRowKey={(data) => data.RowKey}/>
        </ProjectPageLayout>);
    function buildToggleRows(features: FeatureToggleResource[]): FeatureToggleRow[] {
        const rows: FeatureToggleRow[] = [];
        for (const feature of features) {
            const enabledToggles = _.filter(feature.Toggles, (f) => f.IsEnabled);
            const firstToggle = _.first(enabledToggles);
            rows.push({
                Feature: feature,
                Toggle: firstToggle ? firstToggle : undefined,
                RowKey: `${feature.Id}${firstToggle?.DeploymentEnvironmentId}`,
            });
            for (const toggle of _.tail(enabledToggles)) {
                rows.push({
                    Feature: undefined,
                    Toggle: toggle,
                    RowKey: `${feature.Id}${toggle.DeploymentEnvironmentId}`,
                });
            }
        }
        return rows;
    }
}
function renderFeatureNameCell(row: FeatureToggleRow) {
    if (row.Feature) {
        return <FeatureTogglesDataTableNameCell featureToggleName={row.Feature.Name} featureToggleSlug={row.Feature.Slug}/>;
    }
    return <div></div>;
}
export interface FeatureToggleRow {
    Feature?: FeatureToggleResource;
    Toggle?: ToggleResource;
    RowKey: string;
}
