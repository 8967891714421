import { useIsOptionalFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { useUIOnlyFeatureFlag } from "~/hooks/useUIOnlyFeatureFlag";
export function useIsVerticalNavigationEnabled(): boolean {
    const [isVerticalNavigationEnabled] = useVerticalNavigationFeatureFlag();
    return useIsOptionalFeatureToggleEnabled("VerticalNavigationFeatureToggle") === true || isVerticalNavigationEnabled;
}
export function useVerticalNavigationFeatureFlag(): ReturnType<typeof useUIOnlyFeatureFlag> {
    const [uiVerticalNavFeatureFlagEnabled, setFeatureFlagEnabled] = useUIOnlyFeatureFlag("Vertical Navigation", { isInitiallyEnabled: false, scope: "machine" });
    return [uiVerticalNavFeatureFlagEnabled, setFeatureFlagEnabled];
}
