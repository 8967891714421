import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { OverviewContainer, OverviewHighlightText } from "../../CommonComponents/CommonViews";
export const ProjectTenantCommonTemplatesRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().variables.tenant.commonTemplates,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return (<OverviewContainer>
                <OverviewHighlightText>
                    <p>Common templates define which variable values are required for each tenant connected to this project.</p>
                    <br />
                    <p>
                        Common templates are defined in library variable sets and allow tenants to provide a common variable value that is <b>shared</b> across projects and environments.
                    </p>
                    <br />
                    <p>
                        Learn more about&nbsp;
                        <ExternalLink href="MultiTenantVariables">tenant-specific variables</ExternalLink>.
                    </p>
                </OverviewHighlightText>
            </OverviewContainer>);
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
