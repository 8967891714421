import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles } from "@material-ui/core/styles";
import type { CSSProperties } from "react";
import * as React from "react";
import { useCallback, useMemo, useEffect } from "react";
import type { OctopusTheme } from "~/components/Theme";
import { useOctopusTheme } from "~/components/Theme";
import { useTabConversion } from "./useTabConversion";
import type { RenderTabCallback } from "./useTabConversion";
export interface ControlledTabComponentProps {
    value: string;
    afterTabsElement?: JSX.Element;
    onChange: (value: string) => void;
    renderTab?: RenderTabCallback;
    tabContainerStyle?: CSSProperties;
    variant?: "standard" | "scrollable" | "fullWidth";
}
interface PropsWithOctopusTheme {
    octopusTheme: OctopusTheme;
}
const useMuiStyles = makeStyles(() => ({
    root: (props: PropsWithOctopusTheme) => ({ color: props.octopusTheme.primary, width: "100%" }),
    indicator: (props: PropsWithOctopusTheme) => ({ backgroundColor: props.octopusTheme.tabActiveIndicator }),
}));
const useTabStyles = makeStyles(() => ({
    tabsContainer: (props: {
        tabContainerStyle?: CSSProperties;
    }) => ({
        ...props.tabContainerStyle,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    }),
}));
const rootTabStyle: CSSProperties = {
    minWidth: "7.23rem", // Needed for DrawerWrapperLayout.
};
export const ControlledTabsContainer: React.FC<ControlledTabComponentProps> = ({ onChange, variant, afterTabsElement, renderTab, tabContainerStyle, children, ...rest }) => {
    const octopusTheme = useOctopusTheme();
    const classes = useMuiStyles({ octopusTheme });
    const tabStyles = useTabStyles({ tabContainerStyle });
    const memoizedRenderTab = useMemo(() => {
        const defaultTabRender: RenderTabCallback = (renderProps) => <Tab style={rootTabStyle} {...renderProps} key={renderProps.value}/>;
        return renderTab || defaultTabRender;
    }, [renderTab]);
    const { tabs, content, value, getTabDefinition } = useTabConversion(children, rest.value, memoizedRenderTab);
    useEffect(() => {
        const tab = getTabDefinition(rest.value);
        if (tab?.onActive)
            tab.onActive();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleTabChange = useCallback((event: React.ChangeEvent<{}>, tabValue: string) => {
        const tab = getTabDefinition(tabValue);
        if (tab && tab.onActive && tabValue !== value) {
            tab.onActive();
        }
        if (onChange) {
            onChange(tabValue);
        }
    }, [getTabDefinition, onChange, value]);
    //MUI won't handle an undefined value as not provided and will log errors to the console, we therefore only provide the value
    //when it is defined
    return (<React.Fragment>
            <div className={tabStyles.tabsContainer}>
                <Tabs classes={classes} {...(value !== undefined ? { value } : {})} onChange={handleTabChange} variant={variant ?? "scrollable"} scrollButtons="off">
                    {tabs}
                </Tabs>
                {afterTabsElement}
            </div>
            {content}
        </React.Fragment>);
};
ControlledTabsContainer.displayName = "ControlledTabsContainer"
