/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { compact } from "lodash";
import * as React from "react";
import useIsMultiTenancyEnabledFeatureFlag from "~/areas/configuration/hooks/useIsMultiTenancyEnabledFeatureFlag";
import AreaTitle from "~/components/AreaTitle";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import NavigationSidebarLayout, { Navigation } from "~/components/NavigationSidebarLayout";
import { PageHeaderPrimary } from "~/components/PageHeaderPrimary";
import { PortalPageLayout } from "~/components/PortalPageLayout/PortalPageLayout";
import { useIsPageHeaderVNextEnabledOutsideOfProjects } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import { useIsVerticalNavigationEnabled } from "~/components/RootRoutes/useIsVerticalNavigationEnabled";
interface LibraryLayoutProps {
    spaceId: string;
    children: React.ReactNode;
}
export function LibraryLayout({ spaceId, children }: LibraryLayoutProps) {
    const gitHubConnectionsEnabled = isFeatureToggleEnabled("GitHubConnectionsFeatureToggle");
    const isVerticalNavigationEnabled = useIsVerticalNavigationEnabled();
    const isMultiTenancyEnabled = useIsMultiTenancyEnabledFeatureFlag();
    const isPageHeaderVNextEnabledOutsideOfProjects = useIsPageHeaderVNextEnabledOutsideOfProjects();
    const navLinks = compact([
        Navigation.navItem("Certificates", links.certificatesPage.generateUrl({ spaceId }), undefined, { permission: Permission.CertificateView, wildcard: true }),
        Navigation.navItem("External Feeds", links.feedsPage.generateUrl({ spaceId }), undefined, { permission: Permission.FeedView }),
        Navigation.navItem("Git Credentials", links.gitCredentialsPage.generateUrl({ spaceId }), undefined, { permission: [Permission.GitCredentialView], wildcard: true }),
        gitHubConnectionsEnabled && Navigation.navItem("GitHub Connections", links.gitConnectionsPage.generateUrl({ spaceId }), undefined, { permission: [Permission.GitCredentialView], wildcard: true }),
        Navigation.navItem("Lifecycles", links.lifecyclesPage.generateUrl({ spaceId }), undefined, { permission: Permission.LifecycleView, wildcard: true }),
        Navigation.navItem("Packages", links.builtInRepositoryPage.generateUrl({ spaceId }), undefined, { permission: Permission.FeedView }),
        Navigation.navItem("Build Information", links.buildInformationOverviewPage.generateUrl({ spaceId }), undefined, { permission: Permission.FeedView }),
        Navigation.navItem("Script Modules", links.scriptModulesPage.generateUrl({ spaceId }), undefined, { permission: Permission.VariableView, wildcard: true }),
        Navigation.navItem("Step Templates", links.stepTemplatesPage.generateUrl({ spaceId }), undefined, { permission: Permission.ActionTemplateView }),
        isMultiTenancyEnabled && Navigation.navItem("Tenant Tag Sets", links.tagSetsPage.generateUrl({ spaceId }), undefined, { permission: Permission.VariableView, wildcard: true }),
        Navigation.navItem("Variable Sets", links.variableSetsPage.generateUrl({ spaceId }), undefined, { permission: [Permission.VariableView, Permission.LibraryVariableSetView], wildcard: true }),
    ]);
    if (isVerticalNavigationEnabled) {
        return <PortalPageLayout navItems={[]} content={children}/>;
    }
    if (isPageHeaderVNextEnabledOutsideOfProjects) {
        return (<main>
                <PortalPageLayout navItems={navLinks} content={children} header={<PageHeaderPrimary title="Library"/>}/>
            </main>);
    }
    return (<main>
            <AreaTitle link={links.libraryRootRedirect.generateUrl({ spaceId })} title="Library"/>
            <NavigationSidebarLayout navLinks={navLinks} content={children}/>
        </main>);
}
export default LibraryLayout;
