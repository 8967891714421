import { css } from "@emotion/css";
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { ConfigurationMessages } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationMessages";
import type { KubernetesAgentConfigurationResource } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/KubernetesAgentConfigurationDialog";
import { KubernetesAgentStorageContextualHelp } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/KubernetesAgentStorageContextualHelp";
import { EnvironmentMultiSelect } from "~/components/MultiSelect/EnvironmentMultiSelect";
import { TargetTagMultiSelect } from "~/components/MultiSelect/TargetTagMultiSelect";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { required, Text } from "~/components/form/index";
import StringHelper from "~/utils/StringHelper/index";
type AgentConfigurationPageProps = {
    model: KubernetesAgentConfigurationResource;
    onModelChange: <K extends keyof KubernetesAgentConfigurationResource>(model: Pick<KubernetesAgentConfigurationResource, K>) => void;
    environments: EnvironmentResource[];
    machineRoles: string[];
    canModifyServerUrls: boolean;
    showAdvanced: boolean;
    toggleShowAdvanced: () => void;
    getFieldError: (fieldName: string) => string;
    usePersistentVolumeStorage: boolean;
    onUsePersistentVolumeStorageChanged: (value: boolean) => void;
};
const showAdvancedButtonStyle = css({
    alignSelf: "flex-start",
    margin: "0.5rem 0 0.5rem 0",
});
export function AgentConfigurationPage(props: AgentConfigurationPageProps) {
    const { getFieldError } = props;
    return (<>
            <Text value={props.model.Name} helperText={ConfigurationMessages.Name.HelperText} onChange={(Name) => props.onModelChange({ Name })} label={"Name"} error={getFieldError("Name")} validate={required(ConfigurationMessages.Name.Validation.NotProvided)} autoFocus={true}/>
            <EnvironmentMultiSelect environments={props.environments} hideFloatingLabel={false} openOnFocus={false} error={getFieldError("EnvironmentIds")} onChange={(EnvironmentIds) => props.onModelChange({ EnvironmentIds })} helperText={getFieldError("EnvironmentIds") ? "" : ConfigurationMessages.EnvironmentIds.HelperText} value={props.model.EnvironmentIds}/>
            <TargetTagMultiSelect items={props.machineRoles} onChange={(MachineRoles) => props.onModelChange({ MachineRoles })} error={getFieldError("MachineRoles")} value={props.model.MachineRoles} canAdd={true} helperText={ConfigurationMessages.MachineRoles.HelperText} label="Tags (type to add new)" analyticsArea="Kubernetes Agent Configuration"/>

            <div>
                <Text value={props.model.StorageClassName} onChange={(val) => {
            props.onModelChange({ StorageClassName: val });
            props.onUsePersistentVolumeStorageChanged(!StringHelper.isNullOrWhiteSpace(val));
        }} helperText={<span>
                            Provide an optional Storage Class for <strong>agent storage</strong> <KubernetesAgentStorageContextualHelp /> or leave this field blank to use the default NFS storage.{" "}
                            <ExternalLink href={"KubernetesAgentStorage"}>Learn more</ExternalLink> about storage options for the agent.
                        </span>} placeholder={"Storage class"}/>
            </div>
            <>
                <div className={showAdvancedButtonStyle}>
                    <ActionButton label={props.showAdvanced ? "Hide advanced" : "Show advanced"} type={ActionButtonType.Ternary} onClick={(e) => {
            e.preventDefault();
            props.toggleShowAdvanced();
        }}/>
                </div>
                {props.showAdvanced && (<>
                        <div>
                            <Text value={props.model.DefaultNamespace} onChange={(n) => props.onModelChange({ DefaultNamespace: n })} placeholder="default" error={props.getFieldError("DefaultNamespace")} label="Default Kubernetes namespace" helperText="Provide an optional default namespace that can be overridden by your step configuration and YAML."/>
                        </div>
                        {props.canModifyServerUrls && (<>
                                <Text value={props.model.ServerUri} onChange={(ServerUri) => props.onModelChange({ ServerUri })} error={getFieldError("ServerUri")} label="Octopus Deploy Server URL" helperText={ConfigurationMessages.ServerUri.HelperText} validate={required(ConfigurationMessages.ServerUri.Validation.NotProvided)}/>
                                <Text value={props.model.ServerCommsAddress} onChange={(ServerCommsAddress) => props.onModelChange({ ServerCommsAddress })} error={getFieldError("ServerCommsAddress")} label="Octopus Deploy Server Communications URL" helperText={ConfigurationMessages.ServerCommsAddress.HelperText} validate={required(ConfigurationMessages.ServerCommsAddress.Validation.NotProvided)}/>
                            </>)}
                    </>)}
            </>
        </>);
}
