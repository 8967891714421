/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { ResourceCollection, OctopusPackageVersionBuildInformationMappedResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import PaperLayout from "~/components/PaperLayout";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { LibraryLayout } from "../LibraryLayout/LibraryLayout";
import LatestBuildInformationList from "./LatestBuildInformationList";
interface BuildInformationOverviewProps {
    spaceId: string;
}
interface BuildInformationOverviewState extends DataBaseComponentState {
    loaded: boolean;
    initialPackages: ResourceCollection<OctopusPackageVersionBuildInformationMappedResource>;
    totalPackages: number;
}
export class BuildInformationOverviewPage extends DataBaseComponent<BuildInformationOverviewProps, BuildInformationOverviewState> {
    private defaultTake = 50;
    constructor(props: BuildInformationOverviewProps) {
        super(props);
        this.state = {
            loaded: false,
            initialPackages: null!,
            totalPackages: 0,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(() => this.initialLoad(), { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    async initialLoad() {
        const initialPackages = await this.getInitialBuildInformation("", this.defaultTake);
        this.setState({
            initialPackages,
            totalPackages: initialPackages.TotalResults,
            loaded: true,
        });
    }
    async getInitialBuildInformation(filter: string, take: number) {
        return repository.BuildInformationRepository.list({ filter, latest: true, take });
    }
    render() {
        const loaded = this.state.loaded;
        return (<LibraryLayout spaceId={this.props.spaceId}>
                <PaperLayout title="Build Information Repository" busy={this.state.busy} errors={this.errors}>
                    {loaded && <LatestBuildInformationList spaceId={this.props.spaceId} initialPackages={this.state.initialPackages}/>}
                </PaperLayout>
            </LibraryLayout>);
    }
    static displayName = "BuildInformationOverviewPage";
}
