/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-eq-null */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { CircularProgress, LinearProgress, ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { ResourceCollection, PagingCollection } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { each } from "lodash";
import * as React from "react";
import { client } from "~/clientInstance";
import BaseComponent from "~/components/BaseComponent";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import { Section } from "~/components/Section/Section";
import { timeOperation, timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import RequestRaceConditioner from "~/utils/RequestRaceConditioner";
import NumberedPagingBar from "./NumberedPagingBar";
import styles from "./style.module.less";
export interface HasId {
    Id: string;
}
export interface PagingPage {
    index: number;
    isActive: boolean;
    skip: number;
    number: string;
}
export interface PagingBaseProps<R extends HasId> {
    showFilterWithinSection?: boolean;
    filterHintText?: string;
    filterSearchEnabled?: boolean; // Filtering/searching is opt-in. Search will only show if you supply `apiSearchParams` also.
    autoFocusOnFilterSearch?: boolean;
    apiSearchParams?: string[]; // Various endpoints may support one or more search-specific parameters. eg. Search by "partialName" and/or "packageVersion".
    // ^ This guides what we do with our keywordSearch parameter onSearch.
    additionalRequestParams?: Map<string, any>; // For any additional parameters you need to supply to the request.
    showPagingInNumberedStyle?: boolean; // Lets you toggle between the numbered paging controls vs the 'load more' style.
    initialData?: ResourceCollection<R> | PagingCollection<R>; // List may internally mutate the data during paging, so this gets passed to state.
    currentPageIndex?: number; // Only use this if you want to manage paging yourself, otherwise this is handled automatically.
    pageSize?: number; // Allows user to set a custom page size that's different from the API default.
    onLoadMore?(): Promise<void>; // Only specify this if you want to override the default 'load more' behaviour.
    onPageSelected?(skip: number, p: number): Promise<void>; // Only specify this if you want to override the default 'paging' behaviour.
    onSearch?(keywordSearch: string): Promise<void>; // This will trigger an API search (using the `apiSearchParams` you've told it to).
    onFilter?(filter: string, item: R): boolean; // Filtering only occurs on the data we have in memory (different to search).
    onNewItems?(items: R[]): Promise<R[]>; // Manipulate new items before they are added to the new state.
    onRow(item: R): React.ReactNode;
    onRowRedirectUrl?(item: R): LinkHref | null;
}
export interface PagingBaseState<R extends HasId> {
    filter: string;
    keywordSearch: string;
    isShowingSearchResults: boolean;
    loadingMore: boolean;
    isSearching: boolean;
    redirectTo: LinkHref;
    data?: ResourceCollection<R> | PagingCollection<R>;
    currentPageIndex?: number;
    itemsPerPage?: number;
}
export abstract class PagingBaseComponent<R extends HasId, Props extends PagingBaseProps<R>, State extends PagingBaseState<R>> extends BaseComponent<Props, State> {
    private requestRaceConditioner = new RequestRaceConditioner();
    constructor(props: Props) {
        super(props);
        this.onFilter = props.onFilter || this.onFilter;
        this.provideErrorHandling(this.onLoadMore);
        this.provideErrorHandling(this.onPageSelected);
        this.provideErrorHandling(this.onSearch);
        this.state = {
            ...this.state, //Appease the TS typing gods.
            filter: "",
            keywordSearch: "",
            isShowingSearchResults: false,
            loadingMore: true,
            isSearching: false,
            currentPageIndex: this.props.currentPageIndex ? this.props.currentPageIndex : 0,
            itemsPerPage: this.props.pageSize ?? this.props.initialData!.ItemsPerPage, // Uses the default returned by the API. Do not hardcode this or there
            // can be mismatches between the first and subsequent pages.
        };
    }
    async componentDidMount() {
        if (this.state.data != null) {
            return;
        }
        const initialData = this.props.initialData;
        if (this.props.onNewItems) {
            const onNewItems = this.props.onNewItems;
            const updatedItems = await timeOperation(timeOperationOptions.for("OnNewItems"), () => onNewItems(initialData!.Items));
            initialData!.Items = updatedItems || [];
        }
        this.setState({
            data: initialData,
            loadingMore: false,
        });
    }
    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (this.props.initialData !== nextProps.initialData) {
            this.setState({
                data: nextProps.initialData,
                loadingMore: false,
                currentPageIndex: this.props.currentPageIndex ? this.props.currentPageIndex : 0,
            });
        }
        if (this.props.pageSize !== nextProps.pageSize) {
            this.setState({ itemsPerPage: nextProps.pageSize });
        }
    }
    isResourceCollection(collection: ResourceCollection<R> | PagingCollection<R> | undefined): collection is ResourceCollection<R> {
        return (collection as ResourceCollection<R>).Links !== undefined;
    }
    protected onFilter: (filter: string, item: R) => boolean = (_) => true;
    protected onLoadMore = () => timeOperation(timeOperationOptions.for("LoadMore"), async () => {
        this.setState({ loadingMore: true });
        try {
            // Have they provided an override?
            if (this.props.onLoadMore != null) {
                await this.props.onLoadMore();
                return;
            }
            if (!this.isResourceCollection(this.state.data)) {
                return;
            }
            const nextPageIndex = this.state.currentPageIndex! + 1;
            this.setState({
                currentPageIndex: nextPageIndex,
            });
            const skip = nextPageIndex * this.state.itemsPerPage!;
            const requestUri = this.state.data.Links["Template"];
            let requestParams = {
                // Don't skip, just increase the take size.
                take: skip + this.state.itemsPerPage!,
            };
            requestParams = this.addCommonRequestParameters(requestParams);
            await this.requestRaceConditioner.avoidStaleResponsesForRequest(client.get<ResourceCollection<any>>(requestUri, requestParams), async (response) => {
                if (this.props.onNewItems) {
                    const updatedItems = await this.props.onNewItems(response.Items);
                    response.Items = updatedItems || [];
                }
                this.setState({
                    data: response,
                });
            });
        }
        finally {
            this.setState({ loadingMore: false });
        }
    });
    protected onPageSelected = async (skip: number, p: number) => {
        if (this.state.data) {
            this.setState({ loadingMore: true });
            try {
                // Have they provided an override?
                if (this.props.onPageSelected != null) {
                    await this.props.onPageSelected(skip, p);
                    this.setState({
                        currentPageIndex: p,
                    });
                    return;
                }
                if (!this.isResourceCollection(this.state.data)) {
                    return;
                }
                const requestUri = this.state.data.Links["Template"];
                let requestParams = {
                    skip,
                    take: this.state.itemsPerPage,
                };
                requestParams = this.addCommonRequestParameters(requestParams);
                await this.requestRaceConditioner.avoidStaleResponsesForRequest(client.get<ResourceCollection<R>>(requestUri, requestParams), async (response) => {
                    if (this.props.onNewItems) {
                        const updatedItems = await this.props.onNewItems(response.Items);
                        response.Items = updatedItems || [];
                    }
                    this.setState({
                        data: response!,
                        currentPageIndex: p!,
                    });
                });
            }
            finally {
                this.setState({ loadingMore: false });
            }
        }
    };
    protected onSearch = async (keywordSearch: any) => {
        this.setState({ keywordSearch, filter: keywordSearch, isSearching: true }, async () => {
            await this.onPageSelected(0, 0); // New search should reset to page 0.
            // Now figure out if we're searching (or have cleared our search).
            let isShowingSearchResults = true;
            if (!keywordSearch) {
                isShowingSearchResults = false;
            }
            this.setState({ isShowingSearchResults, isSearching: false });
        });
    };
    protected renderFilterSearchBox() {
        if (!this.props.filterSearchEnabled) {
            return null;
        }
        // We switch between a search box that either "filters" or "searches". ie. Filtering data in-line vs searching the API.
        const needToShowSearch = this.state.isShowingSearchResults || this.state.data!.Items.length < this.state.data!.TotalResults;
        const searchBoxHintText = this.props.filterHintText || (this.props.filterSearchEnabled && !needToShowSearch ? "Filter..." : "Search...");
        return (<div key="filterSearch">
                <FilterSearchBox autoFocus={this.props.autoFocusOnFilterSearch} placeholder={searchBoxHintText} debounceDelay={500} onChange={(keyword) => this.onFilterSearch(keyword)}/>
                {this.props.filterSearchEnabled && needToShowSearch && this.props.apiSearchParams && this.props.apiSearchParams.length > 0 && this.state.isSearching && <CircularProgress size="small"/>}
            </div>);
    }
    protected async onFilterSearch(keyword: string) {
        const needToShowSearch = this.state.isShowingSearchResults || this.state.data!.Items.length < this.state.data!.TotalResults;
        if (this.props.filterSearchEnabled && !needToShowSearch) {
            this.setState({ filter: keyword });
        }
        else {
            await this.onSearch(keyword);
        }
    }
    protected renderFilterSearchComponents() {
        if (!this.props.filterSearchEnabled) {
            return null;
        }
        return this.props.showFilterWithinSection ? <Section>{this.renderFilterSearchBox()}</Section> : this.renderFilterSearchBox();
    }
    protected showPagingInNumberedStyle() {
        const data = this.state.data;
        return <NumberedPagingBar totalItems={data!.TotalResults} currentPageIndex={this.state.currentPageIndex!} pageSize={data!.ItemsPerPage} onPageSelected={(s, p) => this.onPageSelected(s, p)}/>;
    }
    protected showPagingInLoadMoreStyle() {
        return (<div className={styles.loadMoreContainer}>
                <div className={styles.loadMoreActions}>
                    {!this.state.loadingMore && (<React.Fragment>
                            <ActionButton type={ActionButtonType.Secondary} label="Load more" onClick={() => this.onLoadMore()}/>
                            <div className={styles.loadMoreSubText}>Or use filters to narrow the search results</div>
                        </React.Fragment>)}
                    {this.state.loadingMore && <LinearProgress variant={"indeterminate"} show={true}/>}
                </div>
            </div>);
    }
    protected navigate(item: R) {
        const redirectTo = getNavigationUrl(this.props, item);
        if (!redirectTo) {
            return;
        }
        this.setState({ redirectTo });
    }
    private addCommonRequestParameters(requestParams: any) {
        // If a keywordSearch has been provided, supply it for all potential apiSearchParams.
        // Eg. Some APIs have filters for both "name", "description" and "packageVersion" etc. So this lets us
        // quickly search them all for the given keyword.
        if (this.state.keywordSearch) {
            each(this.props.apiSearchParams, (param) => {
                requestParams[param] = this.state.keywordSearch;
            });
        }
        // Consumers may want to supply additional request params for filters etc.
        if (this.props.additionalRequestParams) {
            this.props.additionalRequestParams.forEach((value: any, key: string) => {
                requestParams[key] = value; // Do not encodeURIComponent here. That is the responsibility of our client's url resolving code.
            });
        }
        return requestParams;
    }
    static displayName = "PagingBaseComponent";
}
function getNavigationUrl<R>({ onRowRedirectUrl }: {
    onRowRedirectUrl?(item: R): LinkHref | null;
}, item: R): LinkHref | null {
    return onRowRedirectUrl?.(item) ?? null;
}
export { getNavigationUrl };
