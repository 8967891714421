import type { GetDeploymentFreezes, GetDeploymentFreezesResponse } from "@octopusdeploy/octopus-server-client/src/resources/deploymentFreezes/getDeploymentFreezesResponse";
import type { Moment } from "moment";
import moment from "moment";
function isActive(freeze: GetDeploymentFreezes, selectedEnvironmentIds: string[], queueTime: Moment | undefined) {
    const targetDeployTime = queueTime ?? moment();
    const startMoment = moment(freeze.Start);
    const endMoment = moment(freeze.End);
    return (targetDeployTime.isAfter(startMoment) &&
        targetDeployTime.isBefore(endMoment) &&
        Object.values(freeze.ProjectEnvironmentScope)
            .flatMap((e) => e)
            .some((environment) => selectedEnvironmentIds.includes(environment)));
}
export function getActiveFreezes(freezes: GetDeploymentFreezesResponse | null, selectedEnvironmentIds: string[], queueTime: Moment | undefined): {
    Id: string;
    Name: string;
    Environments: string[];
}[] {
    if (freezes === null) {
        return [];
    }
    return freezes.DeploymentFreezes.filter((f) => isActive(f, selectedEnvironmentIds, queueTime)).map((freeze) => ({
        Id: freeze.Id,
        Name: freeze.Name,
        Environments: Object.values(freeze.ProjectEnvironmentScope)
            .flat()
            .filter((environment) => selectedEnvironmentIds.includes(environment)),
    }));
}
