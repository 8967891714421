/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Callout } from "@octopusdeploy/design-system-components";
import type { LicenseStatusResource, ResourceCollection, ScopedUserRoleResource, SpaceResource, TeamResource } from "@octopusdeploy/octopus-server-client";
import { Permission, PermissionsMode } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { fromPairs, isEqual } from "lodash";
import MobileDetect from "mobile-detect";
import * as React from "react";
import { useState } from "react";
import { repository, session } from "~/clientInstance";
import { AdvancedFilterCheckbox } from "~/components/AdvancedFilterLayout";
import AdvancedFilterLayout from "~/components/AdvancedFilterLayout/AdvancedFilterLayout";
import { SpaceChip } from "~/components/Chips";
import type { Errors } from "~/components/DataBaseComponent";
import { DataBaseComponent, useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import FilterSearchBox from "~/components/FilterSearchBox";
import { SpaceMultiSelect } from "~/components/MultiSelect/SpaceMultiSelect";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import type { PrimaryPageAction } from "~/components/PageActions/PageActions";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import type { IQuery } from "~/components/QueryStringFilters/QueryStringFilters";
import { QueryStringFilters } from "~/components/QueryStringFilters/QueryStringFilters";
import { RestrictedPermissionsCallout } from "~/components/RestrictedPermissionsCallout";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { arrayValueFromQueryString } from "~/utils/ParseHelper/ParseHelper";
import AddTeam from "./AddTeam";
import TeamList from "./TeamList";
import styles from "./style.module.less";
interface Filter {
    spaces: string[];
    name: string;
    includeSystem: boolean;
}
interface TeamsQuery extends IQuery {
    spaces?: string[];
    name?: string;
    includeSystem?: string;
}
class FilterLayout extends AdvancedFilterLayout<Filter> {
}
const TeamsQueryStringFilters = QueryStringFilters.For<Filter, TeamsQuery>();
export class TeamsPage extends DataBaseComponent<{}> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }
    render() {
        return <TeamsPageInternal doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors}/>;
    }
    static displayName = "TeamsPage";
}
interface TeamsPageInternalProps {
    doBusyTask: DoBusyTask;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
}
export function TeamsPageInternal({ doBusyTask, busy, errors }: TeamsPageInternalProps) {
    const initialData = useInitialData(doBusyTask);
    const [filter, setFilter] = useState<Filter>(createDefaultFilter());
    const teamsAndScopedUserRoles = useTeamsAndScopedUserRoles(doBusyTask, filter);
    const primaryPageAction = useAddTeamPageAction(initialData?.spaces);
    const isFiltered = !isEqual(filter, createDefaultFilter());
    const filterResult = teamsAndScopedUserRoles !== null && isFiltered ? { numberOfMatches: teamsAndScopedUserRoles.teams.TotalResults, singleText: "team", pluralText: "teams" } : null;
    return (<PaperLayout title="Teams" busy={busy} errors={errors} primaryAction={primaryPageAction}>
            <RestrictedPermissionsCallout isVisible={(initialData !== null && initialData.licenseStatus.PermissionsMode === PermissionsMode.Restricted)!}/>

            {teamsAndScopedUserRoles !== null && (<>
                    <TeamsQueryStringFilters filter={filter} getQuery={queryFromFilters} getFilter={getFilterFromQuery} onFilterChange={setFilter}/>
                    <FilterLayout filter={filter} defaultFilter={createDefaultFilter()} additionalHeaderFilters={[
                <FilterSearchBox placeholder="Filter by name..." value={filter.name} autoFocus={!new MobileDetect(window.navigator.userAgent).isPhoneSized()} onChange={(name: string) => setFilter((prev) => ({ ...prev, name }))}/>,
            ]} onFilterReset={setFilter} filterSections={[
                {
                    render: (<div>
                                        {session.currentPermissions!.scopeToSystem().isAuthorized({ permission: Permission.TeamView }) && (<AdvancedFilterCheckbox label="Include system teams" value={filter.includeSystem} onChange={(includeSystem) => setFilter((prev) => ({ ...prev, includeSystem }))}/>)}
                                        <SpaceSelector spaces={initialData?.spaces ?? []} onChange={(spaces) => setFilter((prev) => ({ ...prev, spaces }))} selectedSpaces={filter.spaces}/>
                                    </div>),
                },
            ]} filterResult={filterResult!} filterByChips={<FilterSummary spaces={initialData?.spaces} selectedSpaces={filter.spaces} includeSystem={filter.includeSystem}/>} renderContent={() => (<div className={styles.lists}>
                                {initialData !== null && teamsAndScopedUserRoles !== null && (<TeamList initialTeams={teamsAndScopedUserRoles.teams} additionalRequestParams={getAdditionalRequestParams(filter)} initialScopedUserRolesLookup={teamsAndScopedUserRoles.initialTeamsScopedUserRoles!} spaces={initialData.spaces} loadScopedUserRolesForTeams={loadScopedUserRolesForTeams}/>)}
                            </div>)}/>
                </>)}
        </PaperLayout>);
}
function useAddTeamPageAction(spaces: SpaceResource[] | undefined): PrimaryPageAction | undefined {
    const { navigate } = useSpaceAwareNavigation();
    if (spaces === undefined)
        return undefined;
    return {
        type: "dialog",
        hasPermissions: isAllowed({ permission: Permission.TeamCreate }),
        label: "Add Team",
        renderDialog: ({ isOpen }) => (<Dialog open={isOpen}>
                <AddTeam currentSpace={getCurrentSpace(spaces)!} onTeamCreated={(id) => navigate(links.editTeamPage.generateUrl({ teamId: id }))}/>
            </Dialog>),
    };
}
function useInitialData(doBusyTask: DoBusyTask) {
    const [initialData, setInitialData] = useState<{
        spaces: SpaceResource[];
        licenseStatus: LicenseStatusResource;
    } | null>(null);
    useDoBusyTaskEffect(doBusyTask, async () => {
        // Spaces here are used as a lookup for scoped user roles
        // You will only be able to see a scoped user role if you have access *within* the space
        // for which the scoped user role applies
        // Therefore only need to load spaces that you have access *within*
        // rather than any space you can see (i.e. if you had SpaceView permission)
        const spacesAsync = repository.Users.getSpaces(session.currentUser!);
        const currentStatus = repository.Licenses.getCurrentStatus();
        setInitialData({ spaces: await spacesAsync, licenseStatus: await currentStatus });
    }, [], { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    return initialData;
}
function useTeamsAndScopedUserRoles(doBusyTask: DoBusyTask, filter: Filter) {
    const [teamsAndScopedUserRoles, setTeamsAndScopedUserRoles] = useState<{
        teams: ResourceCollection<TeamResource>;
        initialTeamsScopedUserRoles: Record<string, ScopedUserRoleResource[]>;
    } | null>(null);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const result = await loadTeamsAndScopedUserRoles(filter);
        setTeamsAndScopedUserRoles(result);
    }, [filter]);
    return teamsAndScopedUserRoles;
}
function queryFromFilters(filter: Filter): TeamsQuery {
    return {
        spaces: filter.spaces,
        name: filter.name,
        includeSystem: filter.includeSystem ? "true" : "false",
    };
}
function getFilterFromQuery(query: TeamsQuery): Filter {
    return {
        spaces: arrayValueFromQueryString(query.spaces),
        name: query.name || "",
        includeSystem: query.includeSystem === "true",
    };
}
function createDefaultFilter(): Filter {
    const hasTeamViewInCurrentSpace = session.currentPermissions!.scopeToSpace(repository.spaceId).isAuthorized({ permission: Permission.TeamView });
    const shouldFilterToCurrentSpace = repository.spaceId && hasTeamViewInCurrentSpace;
    return {
        includeSystem: true,
        name: "",
        spaces: shouldFilterToCurrentSpace ? [repository.spaceId!] : [],
    };
}
function getCurrentSpace(spaces: SpaceResource[] | undefined) {
    const currentSpace = spaces!.find((s) => s.Id === repository.spaceId);
    return currentSpace;
}
async function loadTeamsAndScopedUserRoles(filter: Filter) {
    const teams = await repository.Teams.list({
        includeSystem: filter.includeSystem,
        partialName: filter.name,
        spaces: getSpacesFilter(filter),
    });
    const initialTeamsScopedUserRoles = await loadScopedUserRolesForTeams(teams.Items);
    return {
        teams,
        initialTeamsScopedUserRoles,
    };
}
//eslint-disable-next-line @typescript-eslint/no-explicit-any
function getAdditionalRequestParams(filter: Filter): Map<string, any> {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const additionalRequestParams = new Map<string, any>();
    additionalRequestParams.set("includeSystem", filter.includeSystem);
    additionalRequestParams.set("spaces", getSpacesFilter(filter));
    additionalRequestParams.set("partialName", filter.name);
    return additionalRequestParams;
}
function getSpacesFilter(filter: Filter) {
    const hasTeamViewInAnySpace = session.currentPermissions!.isAuthorizedInAnySpace({ permission: Permission.TeamView });
    if (filter.spaces.length === 0) {
        return hasTeamViewInAnySpace ? ["all"] : [];
    }
    return filter.spaces;
}
async function loadScopedUserRolesForTeams(team: TeamResource[]): Promise<Record<string, ScopedUserRoleResource[]>> {
    const teamsAndScopedUserRoles = await Promise.all(team.map(async (t) => {
        const scopedUserRoles = await repository.Teams.listScopedUserRoles(t);
        return { team: t, scopedUserRoles };
    }));
    return fromPairs<ScopedUserRoleResource[]>(teamsAndScopedUserRoles.map<[
        string,
        ScopedUserRoleResource[]
    ]>((a) => [a.team.Id, a.scopedUserRoles.Items]));
}
function FilterSummary({ spaces, selectedSpaces, includeSystem }: {
    spaces: SpaceResource[] | undefined;
    selectedSpaces: string[];
    includeSystem: boolean;
}) {
    const nodes: React.ReactNode[] = [];
    if (spaces) {
        selectedSpaces.map((spaceId) => {
            const teamSpace: SpaceResource = spaces!.find((s) => s.Id === spaceId) || null!;
            if (teamSpace) {
                nodes.push(<SpaceChip key={spaceId} space={teamSpace}/>);
            }
        });
    }
    if (nodes.length > 0) {
        nodes.push(<div className={styles.filterText}>space{nodes.length === 1 ? "" : "s"}</div>);
        nodes.push(<div className={styles.filterText}>and</div>);
    }
    nodes.push(<div className={styles.filterText}>
            <strong>{includeSystem ? "includes" : "excludes"}</strong>
        </div>);
    nodes.push(<div className={styles.filterText}>system teams</div>);
    return <React.Fragment>{nodes}</React.Fragment>;
}
function SpaceSelector({ spaces, onChange, selectedSpaces }: {
    spaces: SpaceResource[];
    selectedSpaces: string[];
    onChange: (spaces: string[]) => void;
}) {
    const hasTeamViewInAnySpace = session.currentPermissions!.isAuthorizedInAnySpace({ permission: Permission.TeamView });
    if (!hasTeamViewInAnySpace) {
        return (<div style={{ margin: "1rem 0 0 0" }}>
                <Callout type={"information"} title={"Permission required"}>
                    You do not have {Permission.TeamView} permission in any given Space.
                </Callout>
            </div>);
    }
    return <SpaceMultiSelect items={spaces} onChange={onChange} value={selectedSpaces}/>;
}
