import { BooleanRadioButtonGroup, BooleanRadioButton } from "@octopusdeploy/design-system-components";
import React from "react";
import { ExpandableFormSection, Note, Summary } from "~/components/form";
type HelpSidebarFeatureProps = {
    isEnabled: boolean;
    onChange: (isEnabled: boolean) => void;
};
export function HelpSidebarFeature({ isEnabled, onChange }: HelpSidebarFeatureProps) {
    return (<ExpandableFormSection key="IsHelpSidebarEnabled" errorKey="IsHelpSidebarEnabled" title="Toggle the Help Sidebar" summary={isEnabled ? Summary.default("Enabled") : Summary.summary("Disabled")} help="Enable/disable the contextual help sidebar in the Octopus web-portal UI.">
            <BooleanRadioButtonGroup value={isEnabled} onChange={onChange}>
                <BooleanRadioButton value={true} label="Enabled"/>
                <BooleanRadioButton value={false} label="Disabled"/>
            </BooleanRadioButtonGroup>
            <Note style={{ marginTop: "1rem" }}>
                This feature provides your users with contextual-help to improve their understanding of Octopus' key concepts and includes easy access to the support and documentation resources. Users can dismiss the sidebar themselves.{" "}
                <em>Disabling this feature is not recommended.</em>
            </Note>
        </ExpandableFormSection>);
}
