import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import { QueryStateMode, stringArrayQueryParam, stringQueryParam, useQueryStringParams } from "@octopusdeploy/portal-routes";
import { useMemo } from "react";
import type { DeploymentOverviewFilters } from "~/areas/projects/components/DashboardDataSource/DataCube";
import { DimensionTypes } from "~/areas/projects/components/DashboardDataSource/DataCube";
const tagsParameter = stringArrayQueryParam("tags");
const tenantsParameter = stringArrayQueryParam("tenants");
const releaseParameter = stringQueryParam("release");
const groupByParameter = stringQueryParam("groupBy");
const groupByExtraParameter = stringQueryParam("groupByExtra");
const environmentsParameter = stringArrayQueryParam("environments");
export default function useDeploymentsOverviewFilters(project: ProjectResource): [
    DeploymentOverviewFilters,
    (newFilters: DeploymentOverviewFilters) => void
] {
    const [filters, setFilters] = useQueryStringParams([tagsParameter, tenantsParameter, releaseParameter, groupByParameter, groupByExtraParameter, environmentsParameter], QueryStateMode.ReplaceHistory);
    const isProjectTenanted = project.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted;
    const deploymentOverviewFiltersState: DeploymentOverviewFilters = useMemo((): DeploymentOverviewFilters => {
        const filtersForProject: DeploymentOverviewFilters = {
            [DimensionTypes.Project]: { [project.Id]: true },
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            groupBy: filters.groupBy ? DimensionTypes[filters.groupBy as keyof typeof DimensionTypes] : undefined,
            groupByExtra: filters.groupByExtra,
            columnDimension: DimensionTypes.Environment,
            rowDimension: isProjectTenanted ? DimensionTypes.Tenant : DimensionTypes.Release,
        };
        if (filters.release) {
            filtersForProject[DimensionTypes.Release] = { [filters.release]: true };
        }
        if (filters.tags.length > 0) {
            filtersForProject[DimensionTypes.TagSet] = filters.tags.reduce((acc: {
                [key: string]: boolean;
            }, cur) => {
                acc[cur] = true;
                return acc;
            }, {});
        }
        if (filters.tenants.length > 0) {
            filtersForProject[DimensionTypes.Tenant] = filters.tenants.reduce((acc: {
                [key: string]: boolean;
            }, cur) => {
                acc[cur] = true;
                return acc;
            }, {});
        }
        if (filters.environments.length > 0) {
            filtersForProject[DimensionTypes.Environment] = filters.environments.reduce((acc: {
                [key: string]: boolean;
            }, cur) => {
                acc[cur] = true;
                return acc;
            }, {});
        }
        return filtersForProject;
    }, [filters.environments, filters.groupBy, filters.groupByExtra, filters.release, filters.tags, filters.tenants, isProjectTenanted, project.Id]);
    const setDeploymentOverviewFiltersState = (newFilter: DeploymentOverviewFilters) => {
        setFilters({
            tags: newFilter[DimensionTypes.TagSet] ? Object.keys(newFilter[DimensionTypes.TagSet]) : undefined,
            tenants: newFilter[DimensionTypes.Tenant] ? Object.keys(newFilter[DimensionTypes.Tenant]) : undefined,
            release: newFilter[DimensionTypes.Release] ? Object.keys(newFilter[DimensionTypes.Release])[0] : undefined,
            groupBy: newFilter.groupBy ? DimensionTypes[newFilter.groupBy] : undefined,
            groupByExtra: newFilter.groupByExtra,
            environments: newFilter[DimensionTypes.Environment] ? Object.keys(newFilter[DimensionTypes.Environment]) : undefined,
        });
    };
    return [deploymentOverviewFiltersState, setDeploymentOverviewFiltersState];
}
