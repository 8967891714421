import { css } from "@emotion/css";
import { IconButton } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { ServiceAccountOidcIdentityResource } from "@octopusdeploy/octopus-server-client/src/repositories/serviceAccountOidcIdentitiesRepository";
import * as React from "react";
import { useEffect, useState } from "react";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { useFieldErrors } from "~/components/FieldErrorContext/FieldErrorContext";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Note, Text, and, required, startsWith, url } from "~/components/form";
type ConfigureOtherIssuerTypeOidcIdentityProps = {
    externalId: string;
    oidcIdentity: ServiceAccountOidcIdentityResource;
    setOidcIdentity: (oidcIdentity: ServiceAccountOidcIdentityResource) => void;
};
export function ConfigureOtherIssuerOidcIdentity({ externalId, oidcIdentity, setOidcIdentity }: ConfigureOtherIssuerTypeOidcIdentityProps) {
    const { getFieldError } = useFieldErrors();
    const [useCustomAudience, setUseCustomAudience] = useState<boolean>(!!oidcIdentity.Audience && oidcIdentity.Audience !== externalId);
    useEffect(() => {
        if (!useCustomAudience && oidcIdentity.Audience !== undefined) {
            setOidcIdentity({
                ...oidcIdentity,
                Audience: undefined,
            });
        }
    }, [externalId, oidcIdentity, setOidcIdentity, useCustomAudience]);
    const styles = {
        generatedProperty: css({
            display: "flex",
            gap: space[8],
        }),
        generatedPropertyIcon: css({
            marginTop: space[16],
        }),
    };
    function getAudienceDescription() {
        if (useCustomAudience) {
            return <>The audience of the OIDC identity. This must match the audience provided in the OIDC token. Click the cancel icon to use the default audience for the service account.</>;
        }
        else {
            return (<>
                    The audience of the OIDC identity. This must match the audience provided in the OIDC token. This should only be used in advanced scenarios where the OIDC provider is unable to set the audience of the token to the id of the service
                    account. Click the edit icon to set a custom audience.
                </>);
        }
    }
    const oidcIdentitiesCustomAudienceEnabled = isFeatureToggleEnabled("OidcIdentitiesCustomAudienceFeatureToggle");
    return (<div>
            <Text key="Name" label="Name" value={oidcIdentity.Name} onChange={(name) => setOidcIdentity({
            ...oidcIdentity,
            Name: name,
        })} validate={required("Name is required")} error={getFieldError("Name")} autoFocus helperText="A unique name for the OIDC identity within this service account."/>
            <Text key="Issuer" label="Issuer" value={oidcIdentity.Issuer} onChange={(issuer) => setOidcIdentity({
            ...oidcIdentity,
            Issuer: issuer,
        })} validate={and([required("Issuer is required"), url("Issuer must be a valid url"), startsWith("Issuer must be a valid url using HTTPS", "https")])} error={getFieldError("Issuer")} helperText="The URL where OIDC tokens will be issued from. This must match exactly the issuer provided in the OIDC token."/>
            <Text key="Subject" label="Subject" value={oidcIdentity.Subject} onChange={(subject) => setOidcIdentity({
            ...oidcIdentity,
            Subject: subject,
        })} validate={required("Subject is required")} error={getFieldError("Subject")} helperText="The subject of the OIDC identity. This must match the subject provided in the OIDC token and can use '*' or '?' wildcard characters."/>
            {oidcIdentitiesCustomAudienceEnabled && (<div className={styles.generatedProperty}>
                    <Text key="Audience" label="Audience" value={oidcIdentity.Audience ?? externalId} onChange={(audience) => {
                setOidcIdentity({
                    ...oidcIdentity,
                    Audience: audience,
                });
            }} disabled={!useCustomAudience} error={getFieldError("Audience")} helperText={getAudienceDescription()}/>
                    <div className={styles.generatedPropertyIcon}>
                        <IconButton accessibleName="OtherIssuerUseCustomAudience" icon={useCustomAudience ? "Cancel" : "Edit"} onClick={() => setUseCustomAudience(!useCustomAudience)}/>
                    </div>
                </div>)}
            <Note>
                <ExternalLink href="ServiceAccountOidcIdentitiesOtherIssuers">Learn more about configuring OIDC identities for other issuers.</ExternalLink>
            </Note>
        </div>);
}
