import type { PageRouteDefinition, QueryParamValues, QueryParamValuesSetter, UnknownQueryParam } from "@octopusdeploy/portal-routes";
import type { ComponentType, ReactElement } from "react";
import type { Level2PageLayoutProps } from "~/routing/pageRegistrations/Level2PageLayoutProps";
import type { PageRegistration } from "~/routing/pageRegistrations/PageRegistration";
import type { PageIdentity } from "./PageIdentity";
export interface ConfigurationPageRegistration<RouteParams, QueryParams extends UnknownQueryParam[]> extends PageRegistration<RouteParams, QueryParams> {
    render: (layout: ComponentType<Level2PageLayoutProps>, parameters: RouteParams, queryParams: QueryParamValues<QueryParams>, setQueryParams: QueryParamValuesSetter<QueryParamValues<QueryParams>>) => ReactElement;
}
export function createConfigurationPageRegistration<RouteParams, QueryParams extends UnknownQueryParam[]>(route: PageRouteDefinition<RouteParams, QueryParams>, render: (layout: ComponentType<Level2PageLayoutProps>, parameters: RouteParams, queryParams: QueryParamValues<QueryParams>, setQueryParams: QueryParamValuesSetter<QueryParamValues<QueryParams>>) => ReactElement, pageIdentity: PageIdentity): ConfigurationPageRegistration<RouteParams, QueryParams> {
    return {
        route,
        render,
        pageIdentity,
        verticalNavigationPageArea: "Configuration",
        searchKeywords: [],
    };
}
