import { TaskState } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import styles from "./style.module.less";
interface IconStyleProps {
    State: TaskState;
    IsCompleted: boolean;
    HasPendingInterruptions: boolean;
    HasWarningsOrErrors: boolean;
    IsPrevious?: boolean;
    IsCurrent?: boolean;
}
export default (item: IconStyleProps, small: boolean = false, className?: string, isPageHeaderVNextEnabled?: boolean) => {
    let stateClass: string = item.State;
    if (item.HasPendingInterruptions && !item.IsCompleted) {
        stateClass = "Paused";
    }
    else if (item.State === TaskState.Success && item.HasWarningsOrErrors) {
        stateClass = "SuccessWithWarnings";
    }
    let iconStyles = cn(styles.status, styles[stateClass], small ? styles.small : null, className);
    if (item.IsPrevious) {
        iconStyles = cn(iconStyles, "status-previous");
    }
    else if (item.IsCurrent === false && item.IsPrevious === false) {
        iconStyles = cn(iconStyles, "status-archive");
    }
    if (isPageHeaderVNextEnabled) {
        iconStyles = cn(iconStyles, styles.vNext);
    }
    return iconStyles;
};
export { IconStyleProps };
