import { css } from "@emotion/css";
import type { DeploymentFreezeDetailEnvironment, DeploymentFreezeDetailProject } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import { EnvironmentChip } from "~/components/Chips/index";
import Logo from "~/components/Logo/index";
import InternalLink from "~/components/Navigation/InternalLink/index";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/index";
import EditDeploymentFreezeEnvironmentsDialogLayout from "../EditDeploymentFreezeEnvironmentsDialog";
interface DeploymentFreezeScopeTableRowProps {
    project: DeploymentFreezeDetailProject;
    onRemove: (project: DeploymentFreezeDetailProject) => void;
    onEnvironmentUpdate: (project: DeploymentFreezeDetailProject, selectedEnvironments: DeploymentFreezeDetailEnvironment[]) => void;
}
export const DeploymentFreezeScopeTableRow = ({ project, onRemove, onEnvironmentUpdate }: DeploymentFreezeScopeTableRowProps) => {
    return (<DataTableRow>
            <DataTableRowColumn className={styles.projectNameCell}>
                <InternalLink to={links.deploymentsPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Id })}>
                    <div style={{ display: "flex" }}>
                        <div style={{ marginRight: "12px" }}>
                            <Logo url={project.LogoLink} size="2.5em"/>
                        </div>
                        <div style={{ overflowWrap: "anywhere", alignSelf: "center" }}>{project.Name}</div>
                    </div>
                </InternalLink>
            </DataTableRowColumn>
            <DataTableRowColumn>
                <div className={styles.environmentRow}>
                    {project.Environments.map((e) => (<EnvironmentChip environmentName={e.Name} key={e.Id}/>))}
                </div>
            </DataTableRowColumn>
            <DataTableRowColumn>
                <div className={styles.overflowMenu}>
                    <OverflowMenu accessibleName="deploymentFreezesScopeOverflowMenu" menuItems={[
            OverflowMenuItems.dialogItem("Edit", <EditDeploymentFreezeEnvironmentsDialogLayout project={project} onEnvironmentUpdate={onEnvironmentUpdate}/>),
            OverflowMenuItems.deleteItem("Remove", `Are you sure you want to disassociate project '${project.Name}'?`, () => onRemove(project), <div>
                                    <p>Do you wish to continue?</p>
                                </div>),
        ]}/>
                </div>
            </DataTableRowColumn>
        </DataTableRow>);
};
const projectNameCellStyle = css({
    width: "30%",
});
const styles = {
    projectNameCell: css({
        width: "30%",
    }),
    environmentRow: css({
        display: "flex",
    }),
    overflowMenu: css({
        display: "flex",
        justifyContent: "flex-end",
    }),
};
