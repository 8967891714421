import { LinearProgress } from "@octopusdeploy/design-system-components";
import * as React from "react";
import type { PropsWithChildren, ReactNode } from "react";
import BusyFromPromise from "~/components/BusyFromPromise/index";
import type { Errors } from "~/components/DataBaseComponent/index";
import ErrorPanel from "~/components/ErrorPanel/index";
import SectionTitle from "~/components/SectionTitle/index";
export interface ProcessSubPageLayoutProps {
    title: ReactNode;
    titleLogo?: ReactNode;
    sectionControl?: ReactNode;
    busy?: Promise<unknown> | boolean;
    errors?: Errors;
}
export function ProcessSubPageLayout({ title, titleLogo, sectionControl, busy, errors, children }: PropsWithChildren<ProcessSubPageLayoutProps>) {
    const header = (<>
            <BusyFromPromise promise={busy}>{(isBusy: boolean) => <LinearProgress variant={"indeterminate"} show={isBusy}/>}</BusyFromPromise>
            <SectionTitle title={title} titleLogo={titleLogo} sectionControl={sectionControl}/>
            {errors && <ErrorPanel errors={errors.errors} message={errors.message} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink} statusCode={errors.statusCode}/>}
        </>);
    return (<>
            {header}
            {children}
        </>);
}
