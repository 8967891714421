/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { EndpointsHelper } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { EndpointRegistrationKeyValues } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { CommunicationStyle } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import InternalLink from "~/components/Navigation/InternalLink";
import { getCommunicationStyleIconUrl } from "~/utils/MachineIconUrlFetchers/machineIconUrlFetchers";
import styles from "../style.module.less";
interface MachinesContentRowProps {
    deploymentTargetType: string;
    summaries: Record<string, number>;
    link: (commStyle: string) => LinkHref;
}
export const MachinesContentRow = ({ deploymentTargetType, summaries, link }: MachinesContentRowProps) => {
    const machinesCount = summaries[deploymentTargetType] || 0;
    if (!machinesCount || machinesCount === 0) {
        return null;
    }
    let commStyle = deploymentTargetType as CommunicationStyle;
    if (!(commStyle in CommunicationStyle) || commStyle === CommunicationStyle.StepPackage) {
        commStyle = CommunicationStyle.None;
    }
    const endpointName = EndpointsHelper.getFriendlyName(commStyle);
    const endpointIcon = getCommunicationStyleIconUrl(commStyle);
    const endpointIconImage = endpointIcon && <img src={endpointIcon} className={styles.healthStatusIcon} alt="Health status"/>;
    const linkFriendlyCommStyle = getLinkFriendlyCommStyle(commStyle);
    return (<div className={styles.endpointRowsContainer} key={commStyle}>
            <div className={styles.endpointIcon}>{endpointIconImage}</div>
            <div className={styles.endpointName}>
                <InternalLink to={link(linkFriendlyCommStyle)}>{endpointName}</InternalLink>
            </div>
            <div className={styles.endpointMachinesCount}>{machinesCount.toLocaleString()}</div>
        </div>);
};
function getLinkFriendlyCommStyle(commStyle: CommunicationStyle): string {
    if (commStyle === CommunicationStyle.None) {
        return EndpointRegistrationKeyValues.CloudRegion;
    }
    return commStyle;
}
