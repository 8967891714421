import { ConfigurationIcon, InsightsIcon, DeployIcon, TasksIcon } from "@octopusdeploy/design-system-components";
import type { NavigationSideBarProps } from "@octopusdeploy/design-system-components";
import type { UserResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { compact } from "lodash";
import React from "react";
import { repository, session } from "~/clientInstance";
import { UserAccountMenu } from "~/components/Navbar/UserAccountMenu";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import type { SpaceContext } from "~/components/SpaceLoader/SpaceLoader";
import { isSpaceNotFound, isSpecificSpaceContext } from "~/components/SpaceLoader/SpaceLoader";
import type { UnknownPageRegistration } from "~/routing/pageRegistrations/PageRegistration";
export function getNavigationSideBarBottomItems(currentUser: UserResource, spaceContext: SpaceContext): NavigationSideBarProps["bottomItems"] {
    return compact([
        hasConfigurationPermissions(spaceContext) ? { type: "tab", icon: <ConfigurationIcon />, href: links.configurationRootRedirect.generateUrl(), accessibleName: "Configuration", showLinkAsActive: "if path partially matches" } : null,
        { type: "custom", key: "user-menu", content: <UserAccountMenu currentUser={currentUser} spaceContext={spaceContext}/> },
    ]);
}
export function getSideNavigationAreas(spaceContext: SpaceContext, pageRegistration: UnknownPageRegistration, hasNavItems: boolean, isAreaNavPanelCollapsed: boolean | undefined, onAreaNavPanelCollapseToggled: (isCollapsed: boolean | undefined) => void): NavigationSideBarProps["topItems"] {
    return compact([
        ...(isSpecificSpaceContext(spaceContext) ? getSpaceSpecificNavigationAreas(spaceContext.Id, pageRegistration, hasNavItems, isAreaNavPanelCollapsed, onAreaNavPanelCollapseToggled) : []),
        isAllowed({ permission: Permission.TaskView }) ? { type: "tab", label: "Tasks", icon: <TasksIcon />, href: links.tasksPage.generateUrl(), showLinkAsActive: "if path partially matches" } : null,
    ]);
}
function getSpaceSpecificNavigationAreas(spaceId: string, pageRegistration: UnknownPageRegistration, hasNavItems: boolean, isAreaNavPanelCollapsed: boolean | undefined, setAreaNavPanelCollapsed: (isCollapsed: boolean | undefined) => void): NavigationSideBarProps["topItems"] {
    return compact([
        {
            type: "tab",
            label: "Deploy",
            icon: <DeployIcon />,
            href: links.dashboardPage.generateUrl({ spaceId }),
            showLinkAsActive: pageRegistration.verticalNavigationPageArea === "Projects" ? "always" : "never",
            onClick: (e: React.MouseEvent | undefined) => {
                // Q. How do we know when they're routing to a different top-level area?
                // If they're coming from Tasks or Configuration, and trying to open Projects,
                // we want to redirect them to the Dashboard.
                // We really only want to toggle the AreaNavPanel if they're navigating within
                // the same area.
                // HACK - We can hack this for now by asking if hasNavItems, because currently only
                // Projects has NavItems. This will break if/when we have another top-level area
                // menu with NavItems :(
                if (hasNavItems) {
                    // If the user is clicking "Projects" because they can't see the AreaNavPanel, toggle
                    // the AreaNavPanel to let them peek the menu contents.
                    setAreaNavPanelCollapsed(isAreaNavPanelCollapsed === undefined ? false : !isAreaNavPanelCollapsed);
                    e?.preventDefault();
                }
                // Else let this click bubble through to the regular route/link onClick.
            },
        },
        isAllowed({ permission: Permission.InsightsReportView }) ? { type: "tab", label: "Insights", icon: <InsightsIcon />, href: links.insightsRootRedirect.generateUrl({ spaceId }), showLinkAsActive: "if path partially matches" } : null,
    ]);
}
function hasConfigurationPermissions(spaceContext: SpaceContext) {
    if (!isSpaceNotFound(spaceContext) || spaceContext.isAlsoInSystemContext) {
        return (session.currentPermissions?.scopeToSpace(repository.spaceId).hasAnyPermissions() ||
            isAllowed({
                permission: [Permission.AdministerSystem, Permission.EventView, Permission.TeamView, Permission.UserView, Permission.UserRoleView, Permission.SpaceView, Permission.SpaceEdit, Permission.SpaceCreate, Permission.SpaceDelete],
                wildcard: true,
            }));
    }
    return false;
}
