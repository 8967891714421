/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { css, cx } from "@emotion/css";
import type { SvgIconProps } from "@material-ui/core";
import type { IconButtonElement } from "@octopusdeploy/design-system-components";
import { CaretUpIcon, GitCommitIcon, GitTagIcon, TriangleExclamationIcon, GitBranchIcon, CaretDownIcon, IconButton, LockIcon } from "@octopusdeploy/design-system-components";
import { borderRadius, borderWidth, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { GitRef } from "@octopusdeploy/octopus-server-client";
import { isGitCommit, isGitTag, toGitRefShort } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { useIsPageHeaderVNextEnabled } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import type { OctopusTheme } from "~/components/Theme";
import { useOctopusTheme } from "~/components/Theme";
import { GitBranchIcon as LegacyGitBranchIcon, GitCommitIcon as LegacyGitCommitIcon, GitTagIcon as LegacyGitTagIcon } from "~/primitiveComponents/dataDisplay/Icon";
import { LockIcon as LegacyLockIcon } from "./LockIcon";
import styles from "./style.module.less";
interface GitRefButtonProps {
    value: GitRef | undefined;
    errorMessage?: string;
    disabled?: boolean;
    refIsLocked: boolean;
    dropdownArrowButtonRef: React.Ref<IconButtonElement>;
    onClick: React.MouseEventHandler<HTMLElement>;
    onKeyDown: React.KeyboardEventHandler<HTMLElement>;
    style?: "grey" | "white";
    isOpen: boolean;
}
export function GitRefButton(props: GitRefButtonProps) {
    const theme = useOctopusTheme();
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabled();
    if (isPageHeaderVNextEnabled) {
        return <GitRefButtonVNext {...props}/>;
    }
    const { value, errorMessage, disabled = false, refIsLocked, dropdownArrowButtonRef, onClick, onKeyDown, style = "grey" } = props;
    const inlineStyles = getStyles(disabled, theme);
    const gitIconClasses = errorMessage ? cn(styles.gitIcon, styles.error) : styles.gitIcon;
    const valueClasses = errorMessage ? cn(styles.value, styles.error) : styles.value;
    const buttonStyles = styles["dropDownMenu-" + style];
    const buttonContentStyles = styles.inputContent;
    const buttonErrorStyles = errorMessage ? styles.error : undefined;
    return (<div className={cn(buttonStyles, buttonErrorStyles, buttonContentStyles)} role="button" onClick={disabled ? undefined : onClick} aria-label="Switch branch" aria-disabled={disabled} onKeyDown={disabled ? undefined : onKeyDown}>
            <div style={inlineStyles.label} className={styles.label}>
                {value && <LegacyGitIcon forRef={value} className={gitIconClasses} style={inlineStyles.gitIcon}/>}
                {<span className={valueClasses}>{errorMessage ?? (value && toGitRefShort(value))}</span>}
                {refIsLocked && <LegacyLockIcon />}
            </div>
            <div style={inlineStyles.buttons} className={styles.buttonDropDown}>
                <IconButton icon={"ArrowDown"} disabled={disabled} ref={dropdownArrowButtonRef} accessibleName={"ToggleDropDown"}/>
            </div>
        </div>);
}
function GitRefButtonVNext({ value, errorMessage, refIsLocked, onClick, onKeyDown, isOpen }: GitRefButtonProps) {
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    const hasError = Boolean(errorMessage);
    const styles = cx(gitRefButtonStyles, { [gitRefButtonErrorStyles]: hasError, [gitRefButtonActiveStyles]: isOpen, [gitRefButtonMobileStyles]: !isLargerThanIpad });
    return (<button className={styles} onClick={onClick} onKeyDown={onKeyDown}>
            <GitIcon gitRef={value}/>
            <span className={gitRefButtonTextStyles}>{errorMessage ?? toGitRefShort(value)}</span>
            <div className={iconContainerStyles}>
                {refIsLocked && <LockIcon />}
                {hasError && <TriangleExclamationIcon size={20} color="danger"/>}
                {isOpen ? <CaretUpIcon /> : <CaretDownIcon />}
            </div>
        </button>);
}
const gitRefButtonStyles = css({
    width: "200px",
    height: "36px",
    borderRadius: borderRadius.small,
    border: `${borderWidth[1]} solid ${themeTokens.color.border.tertiary}`,
    padding: `6px ${space[12]}`,
    display: "flex",
    gap: space[8],
    backgroundColor: themeTokens.color.background.primary.default,
    alignItems: "center",
    cursor: "pointer",
    ":focus-visible": {
        outline: "none",
        boxShadow: themeTokens.shadow.focus.default,
    },
});
const gitRefButtonMobileStyles = css({
    width: "100%",
});
const gitRefButtonErrorStyles = css({
    borderColor: themeTokens.color.border.danger,
    backgroundColor: themeTokens.color.background.danger,
});
const gitRefButtonActiveStyles = css({
    borderColor: themeTokens.color.border.selected,
});
const gitRefButtonTextStyles = css({
    flex: 1,
    font: text.interface.body.default.medium,
    color: themeTokens.color.text.primary,
    textAlign: "left",
    textOverflow: "ellipsis",
    overflow: "clip",
    whiteSpace: "nowrap",
});
const iconContainerStyles = css({
    display: "flex",
    gap: space[2],
    alignItems: "center",
});
const LegacyGitIcon = (props: SvgIconProps & {
    forRef: GitRef;
}) => {
    const { forRef, ...rest } = props;
    const Icon = isGitCommit(forRef) ? LegacyGitCommitIcon : isGitTag(forRef) ? LegacyGitTagIcon : LegacyGitBranchIcon;
    return <Icon {...rest}/>;
};
const GitIcon = ({ gitRef }: {
    gitRef: GitRef | undefined;
}) => {
    if (isGitCommit(gitRef)) {
        return <GitCommitIcon />;
    }
    if (isGitTag(gitRef)) {
        return <GitTagIcon />;
    }
    return <GitBranchIcon emphasis="muted"/>;
};
function getStyles(disabled: boolean, theme: OctopusTheme) {
    const color = disabled ? theme.disabledButtonText : theme.iconNeutral;
    return {
        gitIcon: {
            fill: color,
        },
        label: {
            display: "flex" as const,
            justifyContent: "flex-start",
            alignItems: "center" as const,
            color: color,
            overflow: "hidden" as const,
            opacity: 1,
            position: "relative" as const,
            textOverflow: "ellipsis" as const,
            gap: "0.2rem",
            flex: 1,
        },
        buttons: {
            display: "flex",
        },
    };
}
