/* eslint-disable @typescript-eslint/no-non-null-assertion */
import cn from "classnames";
import * as React from "react";
import { GitRefChip } from "~/areas/projects/components/Releases/GitRefChip/GitRefChip";
import { FilesList } from "~/areas/projects/components/Releases/GitResources/FilesList";
import type { GitReferenceModel } from "~/areas/projects/components/Releases/gitResourceModel";
import { GitResourcePathContextualHelp, GitResourceReleaseGitReferenceContextualHelp } from "~/components/ContextualHelp/ContextualHelpSnippets";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/index";
import DataBaseComponent from "~/components/DataBaseComponent/index";
import Section from "~/components/Section";
import { withTheme } from "~/components/Theme/index";
import { DataTable, DataTableBody, DataTableHeader, DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import Note from "~/primitiveComponents/form/Note/Note";
import styles from "./Edit/style.module.less";
interface GitResourcesListProps {
    gitReferences: GitReferenceModel[];
}
export class GitResourcesList extends DataBaseComponent<GitResourcesListProps, DataBaseComponentState> {
    constructor(props: GitResourcesListProps) {
        super(props);
        this.state = {};
    }
    render() {
        const showFilePaths = this.props.gitReferences.some((g) => g.FilePathFilters.length > 0);
        return withTheme((theme) => (<div>
                {this.props.gitReferences && this.props.gitReferences.length > 0 ? (<DataTable className={styles.packageTable}>
                        <DataTableHeader>
                            <DataTableRow>
                                <DataTableHeaderColumn>
                                    <div className={styles.actionName}>Step Repository</div>
                                </DataTableHeaderColumn>
                                {showFilePaths && (<DataTableHeaderColumn>
                                        Paths <GitResourcePathContextualHelp />
                                    </DataTableHeaderColumn>)}
                                <DataTableHeaderColumn>
                                    Git Reference <GitResourceReleaseGitReferenceContextualHelp />
                                </DataTableHeaderColumn>
                            </DataTableRow>
                        </DataTableHeader>
                        <DataTableBody>
                            {this.props.gitReferences.map((r, ix) => (<DataTableRow key={r.ActionName + ix}>
                                    <DataTableRowColumn key={r.ActionName + ix} className={cn(styles.repositoryColumn, styles.repositoryColumn)}>
                                        <div className={styles.actionName}>{r.ActionName}</div>
                                        <span>{r.RepositoryUri}</span>
                                    </DataTableRowColumn>
                                    {showFilePaths && (<DataTableRowColumn key={`rowForLast${ix}`} className={cn(styles.pathsColumn)}>
                                            <FilesList filePaths={r.FilePathFilters} actionName={r.ActionName}/>
                                        </DataTableRowColumn>)}
                                    <DataTableRowColumn key={`rowForGitReference${ix}`} className={cn(styles.packageTableRowColumn, styles.specificColumn)}>
                                        {r.GitResource !== null && <GitRefChip key={`${r.GitResource.GitRef} ${r.GitResource.GitCommit} ${ix}`} vcsRef={r.GitResource}></GitRefChip>}
                                    </DataTableRowColumn>
                                </DataTableRow>))}
                        </DataTableBody>
                    </DataTable>) : (<Section>
                        <Note>There are no Git resource references associated with any steps.</Note>
                    </Section>)}
            </div>));
    }
    static displayName = "GitResourcesList";
}
