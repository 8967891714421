/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Tooltip } from "@octopusdeploy/design-system-components";
import type { PackageFromBuiltInFeedResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import BuildInformationTable from "~/components/BuildInformation/BuildInformationTable";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import Markdown from "~/components/Markdown";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { PageAction, PrimaryPageAction } from "~/components/PageActions/PageActions";
import PaperLayout from "~/components/PaperLayout";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { Section } from "~/components/Section/Section";
import { FormSectionHeading } from "~/components/form";
import { DataTable, DataTableBody, DataTableRow, DataTableRowColumn, DataTableRowHeaderColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon";
import ByteSizeFormatter from "~/utils/ByteSizeFormatter";
import DateFormatter from "~/utils/DateFormatter";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { client } from "../../../../clientInstance";
import { LibraryLayout } from "../LibraryLayout/LibraryLayout";
interface PackageDetailsPageProps {
    spaceId: string;
    packageId: string;
}
interface PackageDetailsPageState extends DataBaseComponentState {
    package?: PackageFromBuiltInFeedResource;
    deleted?: boolean;
}
export class PackageDetailsPage extends DataBaseComponent<PackageDetailsPageProps, PackageDetailsPageState> {
    constructor(props: PackageDetailsPageProps) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(() => this.load(), { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    async load() {
        const pkg = await repository.Packages.get(this.props.packageId, { includeNotes: true });
        let errors = null;
        if (pkg && pkg.PackageVersionBuildInformation && pkg.PackageVersionBuildInformation.IncompleteDataWarning) {
            errors = { message: `Unable to retrieve part of the package build information for package '${pkg.PackageId}'.`, errors: { PackageVersionBuildInformationIncomplete: pkg.PackageVersionBuildInformation.IncompleteDataWarning } };
        }
        this.setState({ package: pkg });
        if (!errors) {
            this.clearErrors();
        }
        else {
            this.setValidationErrors(errors.message, errors.errors);
        }
    }
    render() {
        const pkg = this.state.package;
        const title = this.state.package && pkg!.PackageId + " " + pkg!.Version;
        const uploadPackagePageAction: PageAction = {
            type: "navigate",
            hasPermissions: isAllowed({ permission: Permission.BuiltInFeedDownload, project: "*" }),
            buttonType: "secondary",
            label: "Upload another package",
            path: links.builtInRepositoryPage.generateUrl({ spaceId: this.props.spaceId }),
        };
        const pageActions: PageAction[] = pkg ? [uploadPackagePageAction] : [];
        const downloadPackagePageAction: PrimaryPageAction | undefined = pkg
            ? {
                type: "navigate",
                hasPermissions: isAllowed({ permission: Permission.BuiltInFeedDownload, project: "*" }),
                label: "Download",
                path: client.resolve(pkg.Links["Raw"]),
                icon: <ThirdPartyIcon iconType={ThirdPartyIconType.GetApp}/>,
                external: true,
            }
            : undefined;
        const overflowActions = [OverflowMenuItems.deleteItemDefault(title!, this.handleDeleteConfirm, { permission: Permission.BuiltInFeedAdminister, project: "*" }, "Deleting packages will permanently remove files from disk")];
        return (<LibraryLayout spaceId={this.props.spaceId}>
                <PaperLayout title={title} breadcrumbTitle={pkg && pkg.PackageId} 
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        breadcrumbPath={links.packageVersionsListPage.generateUrl({ spaceId: this.props.spaceId, packageId: pkg?.PackageId! })} primaryAction={downloadPackagePageAction} pageActions={pageActions} overflowActions={overflowActions} busy={this.state.busy} errors={this.errors}>
                    {this.state.deleted && <InternalRedirect to={links.builtInRepositoryPage.generateUrl({ spaceId: this.props.spaceId })}/>}
                    {pkg && (<Section>
                            <p>
                                The information below is from the specification embedded in the <strong>{`${pkg.PackageId}.${pkg.Version}${pkg.FileExtension}`}</strong> file.
                            </p>
                        </Section>)}
                    {pkg && (<DataTable>
                            <DataTableBody>
                                <DataTableRow>
                                    <DataTableRowHeaderColumn>ID</DataTableRowHeaderColumn>
                                    <DataTableRowColumn>{pkg.PackageId}</DataTableRowColumn>
                                </DataTableRow>
                                <DataTableRow>
                                    <DataTableRowHeaderColumn>Version</DataTableRowHeaderColumn>
                                    <DataTableRowColumn>{pkg.Version}</DataTableRowColumn>
                                </DataTableRow>
                                <DataTableRow>
                                    <DataTableRowHeaderColumn>Published</DataTableRowHeaderColumn>
                                    <DataTableRowColumn>
                                        <Tooltip content={DateFormatter.dateToLongFormat(pkg.Published)!}>{DateFormatter.dateToShortFormat(pkg.Published)}</Tooltip>
                                    </DataTableRowColumn>
                                </DataTableRow>
                                {pkg.Title && (<DataTableRow>
                                        <DataTableRowHeaderColumn>Title</DataTableRowHeaderColumn>
                                        <DataTableRowColumn>{pkg.Title}</DataTableRowColumn>
                                    </DataTableRow>)}
                                {pkg.Summary && (<DataTableRow>
                                        <DataTableRowHeaderColumn>Summary</DataTableRowHeaderColumn>
                                        <DataTableRowColumn>{pkg.Summary}</DataTableRowColumn>
                                    </DataTableRow>)}
                                {pkg.Description && (<DataTableRow>
                                        <DataTableRowHeaderColumn>Description</DataTableRowHeaderColumn>
                                        <DataTableRowColumn>{pkg.Description}</DataTableRowColumn>
                                    </DataTableRow>)}
                                {pkg.ReleaseNotes && (<DataTableRow>
                                        <DataTableRowHeaderColumn>Release Notes</DataTableRowHeaderColumn>
                                        <DataTableRowColumn>
                                            <Markdown markup={pkg.ReleaseNotes}/>
                                        </DataTableRowColumn>
                                    </DataTableRow>)}
                                {pkg.FileExtension && (<DataTableRow>
                                        <DataTableRowHeaderColumn>File Extension</DataTableRowHeaderColumn>
                                        <DataTableRowColumn>{pkg.FileExtension}</DataTableRowColumn>
                                    </DataTableRow>)}
                                <DataTableRow>
                                    <DataTableRowHeaderColumn>Size</DataTableRowHeaderColumn>
                                    <DataTableRowColumn>{ByteSizeFormatter(pkg.PackageSizeBytes!)}</DataTableRowColumn>
                                </DataTableRow>
                                <DataTableRow>
                                    <DataTableRowHeaderColumn>SHA1</DataTableRowHeaderColumn>
                                    <DataTableRowColumn>{pkg.Hash}</DataTableRowColumn>
                                </DataTableRow>
                            </DataTableBody>
                        </DataTable>)}
                    {pkg && pkg.PackageVersionBuildInformation && (<>
                            <FormSectionHeading title="Build Information"/>
                            <BuildInformationTable data={pkg.PackageVersionBuildInformation}/>
                        </>)}
                </PaperLayout>
            </LibraryLayout>);
    }
    private handleDeleteConfirm = async () => {
        await repository.Packages.deleteMany([this.props.packageId]);
        this.setState({ deleted: true });
        return true;
    };
    static displayName = "PackageDetailsPage";
}
