import type { WorkerPoolResource, ReferenceDataItem } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { ChipIcon, MissingChip, WorkerPoolChip } from "~/components/Chips";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { FocusableComponent } from "~/components/VirtualListWithKeyboard/FocusableComponent";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { FormFieldProps } from "~/components/form";
import Lookup from "../Lookup";
export const WorkerPoolTypedMultiSelect = MultiSelect<ReferenceDataItem>();
interface WorkerPoolMultiSelectProps extends FormFieldProps<string[]> {
    items: WorkerPoolResource[];
    openOnFocus?: boolean;
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    accessibleName?: string;
    multiSelectRef?(component: FocusableComponent | null): void;
}
export const WorkerPoolMultiSelect: React.FC<WorkerPoolMultiSelectProps> = (props) => {
    const chipRenderer = (r: WorkerPoolResource | SelectItem, onRequestDelete: () => void) => {
        return (<Lookup lookupCollection={props.items} lookupId={r.Id} getIdFromElement={(element) => element.Id} render={(item) => <WorkerPoolChip workerPoolType={item.WorkerPoolType} onRequestDelete={onRequestDelete} deleteButtonAccessibleName={`Delete ${item.Name}`} workerPoolName={item.Name}/>} renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.Environment} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete}/>}/>);
    };
    return <WorkerPoolTypedMultiSelect fieldName="worker pools" renderChip={chipRenderer} {...props}/>;
};
WorkerPoolMultiSelect.displayName = "WorkerPoolMultiSelect"
