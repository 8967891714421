import { Snackbar } from "@octopusdeploy/design-system-components";
import { AnalyticLinkLocationProvider } from "@octopusdeploy/portal-analytics";
import { BaseComponent } from "app/components/BaseComponent/BaseComponent";
import type * as History from "history";
import { isEqual } from "lodash";
import * as React from "react";
import { Prompt, useLocation } from "react-router";
import type { FieldErrors } from "~/components/DataBaseComponent/Errors";
import { DevToolsTab } from "~/components/DevTools/DevToolsContext";
import { useErrors } from "~/components/ErrorContext/ErrorContext";
import FormComponent from "~/components/FormComponent/FormComponent";
import { DirtyStateDetail } from "~/components/FormPaperLayout/DirtyStateTracking/DirtyStateDetail";
import { createFormDirtyChangedAction, createFormMountedAction } from "~/components/FormPaperLayout/reducers";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import ExpansionButtons, { toggleExpandos } from "~/components/form/Sections/ExpansionButtons";
import store from "~/store";
import { timeOperation, timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
export interface FormProps {
    model: object | undefined;
    cleanModel?: object;
    saveText?: string;
    savePermission?: PermissionCheckProps | undefined;
    disableDirtyFormChecking?: boolean;
    disableKeyboardFormSubmission?: boolean;
    forceDisableFormSaveButton?: boolean;
    devToolsDirtyTrackingKey?: string;
    devToolsDirtyTrackingDisabled?: boolean;
    onSaveClick(isNavigationConfirmation: boolean): Promise<{} | void> | void;
    confirmNavigateSaveLabel?: string;
}
interface FormState {
    dirty: boolean;
    showSnackbar: boolean;
}
export interface PrimarySaveActionOptions {
    saveButtonLabel?: string;
    saveButtonBusyLabel?: string;
}
type PrimarySaveActionOptionsWithDefaults = Required<PrimarySaveActionOptions>;
interface RenderFormProps {
    createSaveAction: (options: PrimarySaveActionOptions) => PrimarySavePageAction;
    FormContent: React.ComponentType<FormContentProps>;
    isDisabled: boolean;
    save: (isNavigationConfirmation: boolean) => Promise<boolean>;
}
interface FormPropsWithChildren extends FormProps {
    children: (props: RenderFormProps) => React.ReactNode;
}
export function Form({ children, ...rest }: FormPropsWithChildren) {
    const errors = useErrors();
    return (<FormInternal {...rest} errors={errors?.fieldErrors}>
            {children}
        </FormInternal>);
}
interface FormPropsInternal extends FormPropsWithChildren {
    errors?: FieldErrors;
}
class FormInternal extends BaseComponent<FormPropsInternal, FormState> {
    public static defaultProps: Partial<FormPropsInternal> = {
        saveText: "Details updated",
        devToolsDirtyTrackingKey: "Form",
    };
    constructor(props: FormPropsInternal) {
        super(props);
        this.state = {
            dirty: false,
            showSnackbar: false,
        };
    }
    handleSnackbarClose = () => {
        this.setState({
            showSnackbar: false,
        });
    };
    UNSAFE_componentWillReceiveProps(nextProps: FormPropsInternal) {
        if (!nextProps.disableDirtyFormChecking) {
            const dirty = !isEqual(nextProps.model, nextProps.cleanModel);
            this.setState({ dirty }, () => store.dispatch(createFormDirtyChangedAction(dirty)));
        }
    }
    componentDidMount() {
        store.dispatch(createFormMountedAction(this.saveOnConfirmNavigation, this.props.confirmNavigateSaveLabel));
        store.dispatch(createFormDirtyChangedAction(this.state.dirty));
    }
    componentWillUnmount() {
        store.dispatch(createFormMountedAction(undefined, undefined));
        store.dispatch(createFormDirtyChangedAction(false));
    }
    createSaveAction = ({ saveButtonLabel = "Save", saveButtonBusyLabel = "Saving" }: PrimarySaveActionOptions): PrimarySavePageAction => {
        const optionsWithDefaults = { saveButtonLabel, saveButtonBusyLabel } satisfies PrimarySaveActionOptionsWithDefaults;
        return {
            type: "button" as const,
            label: this.getSaveActionLabel(optionsWithDefaults),
            busyLabel: optionsWithDefaults.saveButtonBusyLabel,
            disabled: this.shouldBeDisabled(this.props.savePermission),
            onClick: (e: React.MouseEvent | undefined) => {
                if (e) {
                    e.preventDefault();
                }
                return this.save(false);
            },
        };
    };
    getSaveActionLabel({ saveButtonLabel }: PrimarySaveActionOptionsWithDefaults): string {
        const disabledDueToPermission = this.isDisableDueToPermission(this.props.savePermission);
        const permissionLabel = this.getPermissionLabel(this.props.savePermission);
        return disabledDueToPermission ? `${permissionLabel} permission required` : saveButtonLabel;
    }
    private isDisableDueToPermission = (permission: PermissionCheckProps | undefined) => {
        return !!permission ? !isAllowed(permission) : false;
    };
    private getPermissionLabel(permission: PermissionCheckProps | undefined): string {
        if (permission === undefined) {
            return "No";
        }
        if (Array.isArray(permission.permission)) {
            return permission.permission.join(", ");
        }
        return permission.permission;
    }
    private shouldBeDisabled = (permission: PermissionCheckProps | undefined): boolean => {
        const isDisabledDueToPermission = this.isDisableDueToPermission(permission);
        const isDisabledFromBeingDirty = (!this.state.dirty || !this.props.model) && !this.props.disableDirtyFormChecking;
        return isDisabledDueToPermission || isDisabledFromBeingDirty || Boolean(this.props.forceDisableFormSaveButton);
    };
    private onCtrlEnterPressed = async () => {
        if (!this.shouldBeDisabled(this.props.savePermission) && !this.props.disableKeyboardFormSubmission) {
            await this.save(false);
        }
    };
    private saveOnConfirmNavigation = async () => {
        await this.save(true);
    };
    private save = (isNavigationConfirmation: boolean): Promise<boolean> => timeOperation(timeOperationOptions.forSave(), async () => {
        await this.props.onSaveClick(isNavigationConfirmation);
        if (!this.props.errors) {
            if (this.props.saveText) {
                this.setState({ showSnackbar: true });
            }
        }
        if (!this.props.errors) {
            toggleExpandos(false);
        }
        return !this.props.errors;
    });
    render() {
        return (<AnalyticLinkLocationProvider location="Paper Form">
                <FormComponent onFormSubmit={this.onCtrlEnterPressed}>
                    {!this.props.devToolsDirtyTrackingDisabled && (<DevToolsTab name={`Dirty state: ${this.props.devToolsDirtyTrackingKey ?? "Form"}`}>
                            <DirtyStateDetail cleanModel={this.props.cleanModel} model={this.props.model}/>
                        </DevToolsTab>)}
                    <NavigateAwayPrompt when={this.state.dirty && !this.props.disableDirtyFormChecking}/>
                    {this.props.children({ createSaveAction: this.createSaveAction, save: this.save, FormContent, isDisabled: Boolean(this.shouldBeDisabled(this.props.savePermission)) })}
                    {this.props.saveText && <Snackbar open={this.state.showSnackbar} content={this.props.saveText} autoHideDuration={3500} onClose={this.handleSnackbarClose} textAlign="center"/>}
                </FormComponent>
            </AnalyticLinkLocationProvider>);
    }
    static displayName = "FormInternal";
}
export interface PrimarySavePageAction {
    type: "button";
    label: string;
    disabled: boolean;
    busyLabel: string;
    onClick: (event: React.MouseEvent | undefined) => Promise<boolean>;
}
interface FormContentProps {
    hideExpandAll?: boolean;
    expandAllOnMount?: boolean;
    children: React.ReactNode;
    containerKey?: string;
}
function FormContent({ expandAllOnMount, hideExpandAll, children, containerKey }: FormContentProps) {
    const errors = useErrors();
    return (<>
            {!hideExpandAll && <ExpansionButtons errors={errors?.fieldErrors} expandAllOnMount={expandAllOnMount} containerKey={containerKey}/>}
            {children}
        </>);
}
interface NavigateAwayPromptProps {
    when: boolean;
}
function NavigateAwayPrompt({ when }: NavigateAwayPromptProps) {
    const currentLocation = useLocation();
    // If the pathname hasn't changed, return true which will allow the transition.
    // This is so we can ignore filter changes which only modify the query string.
    const getPromptMessage = React.useCallback((nextLocation: History.Location) => {
        if (nextLocation.pathname === currentLocation.pathname) {
            return true;
        }
        return "If you leave this page, any changes you have made will be lost. Are you sure you wish to leave this page?";
    }, [currentLocation.pathname]);
    return <Prompt when={when} message={getPromptMessage}/>;
}
