import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/index";
import { withProjectContext } from "~/areas/projects/context/index";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
interface FeatureTogglesDataTableNameCellProps {
    featureToggleSlug: string;
    featureToggleName: string;
}
function FeatureTogglesDataTableNameCellInternal(props: FeatureTogglesDataTableNameCellProps & WithProjectContextInjectedProps) {
    const project = props.projectContext.state.model;
    return <InternalLink to={links.editFeatureTogglePage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, featureToggleSlug: props.featureToggleSlug })}>{props.featureToggleName}</InternalLink>;
}
export const FeatureTogglesDataTableNameCell = withProjectContext(FeatureTogglesDataTableNameCellInternal);
