import { Callout } from "@octopusdeploy/design-system-components";
import type { GitRefResource } from "@octopusdeploy/octopus-server-client";
import { isProtectedBranch, isGitCommit, isGitTag, toGitCommitShort } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
type Props = {
    gitRef?: GitRefResource | undefined;
};
const CannotCommitCallout = ({ gitRef }: Props) => {
    const branchProtectionsAreEnabled = isFeatureToggleEnabled("BranchProtectionsFeatureToggle");
    if (!gitRef || !branchProtectionsAreEnabled) {
        return <></>;
    }
    const renderWithTypeName = (title: string, message: string) => (<Callout title={title} type={"information"} canClose={false}>
            {message}
        </Callout>);
    const canonicalName = gitRef.CanonicalName;
    if (isProtectedBranch(gitRef)) {
        return renderWithTypeName(`${canonicalName} is a protected branch`, "Protected branches cannot be modified.");
    }
    else if (isGitTag(canonicalName)) {
        return renderWithTypeName(`${canonicalName} is a tag`, "Tags cannot be modified.");
    }
    else if (isGitCommit(canonicalName)) {
        return renderWithTypeName(`${toGitCommitShort(canonicalName)} is a commit`, "Commits cannot be modified.");
    }
    else {
        return <></>;
    }
};
export default CannotCommitCallout;
