import cn from "classnames";
import * as React from "react";
import styles from "./CustomDialogFrames.module.less";
export type Frame = React.ComponentType<{}>;
export interface DialogFrameProp {
    frame?: Frame;
}
export const FrameWrapper: React.SFC<BaseFrameProps> = React.forwardRef(({ children, className }, ref) => (<div ref={ref} className={cn(className, styles.wrapper, styles.frame, styles.padded)}>
        {children}
    </div>));
FrameWrapper.displayName = "FrameWrapper"
interface BaseFrameProps {
    className?: string;
    ref?: React.Ref<HTMLDivElement>;
}
export const DialogFrame: React.SFC<BaseFrameProps> = React.forwardRef(({ children, className }, ref) => {
    return (<div ref={ref} className={cn(styles.frame, styles.shadow, className)}>
            {children}
        </div>);
});
DialogFrame.displayName = "DialogFrame"
export const FullScreenDialogFrame: React.SFC<BaseFrameProps> = React.forwardRef(({ children, className }, ref) => {
    return (<DialogFrame ref={ref} className={cn(styles.fullscreen, styles.commonFrame, styles.opaque, styles.shadow, className)}>
            {children}
        </DialogFrame>);
});
FullScreenDialogFrame.displayName = "FullScreenDialogFrame"
export const LargeDialogFrame: React.SFC<BaseFrameProps> = React.forwardRef(({ children, className }, ref) => {
    return (<DialogFrame ref={ref} className={cn(styles.huge, styles.commonFrame, styles.opaque, styles.shadow, className)}>
            {children}
        </DialogFrame>);
});
LargeDialogFrame.displayName = "LargeDialogFrame"
export const MediumDialogFrame: React.SFC<BaseFrameProps> = React.forwardRef(({ children, className }, ref) => (<FrameWrapper>
        <div ref={ref} className={cn(styles.medium, styles.commonFrame, styles.opaque, styles.shadow, className)}>
            {children}
        </div>
    </FrameWrapper>));
MediumDialogFrame.displayName = "MediumDialogFrame"
export const SmallDialogFrame: React.SFC<BaseFrameProps> = React.forwardRef(({ children, className }, ref) => (<MediumDialogFrame ref={ref} className={cn(styles.small, className)}>
        {children}
    </MediumDialogFrame>));
SmallDialogFrame.displayName = "SmallDialogFrame"
export const ExtraSmallDialogFrame: React.SFC<BaseFrameProps> = React.forwardRef(({ children, className }, ref) => (<MediumDialogFrame ref={ref} className={cn(styles.extraSmall, className)}>
        {children}
    </MediumDialogFrame>));
ExtraSmallDialogFrame.displayName = "ExtraSmallDialogFrame"
