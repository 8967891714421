import type { TenantResource, ToggleResource } from "@octopusdeploy/octopus-server-client";
import _ from "lodash";
import React from "react";
import Chip from "~/components/Chips/Chip";
import { EnvironmentChip, tenantChipList } from "~/components/Chips/index";
import Tag from "~/components/Tag/index";
import { withTheme } from "~/components/Theme/index";
interface ToggleScopeProps {
    toggle: ToggleResource;
    environmentName: string;
    tenants: TenantResource[];
}
export default ({ toggle, environmentName, tenants }: ToggleScopeProps) => (<div>
        <EnvironmentChip environmentName={environmentName}/>
        {toggle.TenantTargetingStrategy === "SpecificTenants" &&
        tenantChipList(tenants, _.map(tenants, (t) => t.Id))}
        {toggle.TenantTargetingStrategy === "PercentageOfTenants" && withTheme((theme) => <Chip backgroundColor={theme.experimentBackground}>{toggle.RolloutPercentage}% of tenants</Chip>)}
        {toggle.Segments.map((segment) => withTheme((theme) => <Tag tagName={segment} description={segment} tagColor={theme.info}/>))}
    </div>);
