import { LinearProgress } from "@octopusdeploy/design-system-components";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import styles from "app/components/FullWidthPageLayout/style.module.less";
import type { PropsWithChildren } from "react";
import React from "react";
import AreaTitle from "~/components/AreaTitle/index";
import BusyFromPromise from "~/components/BusyFromPromise/index";
import { ContextualHelpLayout } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import type { Errors } from "~/components/DataBaseComponent/index";
import ErrorPanel from "~/components/ErrorPanel/ErrorPanel";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { PageActions } from "~/components/PageActions/PageActions";
import type { PrimaryPageAction, PageAction } from "~/components/PageActions/PageActions";
import { PageHeaderPrimary } from "~/components/PageHeaderPrimary";
import { useIsPageHeaderVNextEnabled, useIsPageHeaderVNextEnabledOutsideOfProjects, useIsProjectsPage } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import { SkeletonLoadingLayout } from "~/components/SkeletonLoadingLayout/SkeletonLoadingLayout";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
interface FullWidthPageLayoutProps {
    areaTitle?: string;
    areaTitleLink?: LinkHref;
    primaryAction?: PrimaryPageAction;
    pageActions?: PageAction[];
    overflowActions?: Array<MenuItem>;
    busy: Promise<void> | boolean | undefined;
    errors: Errors | undefined;
}
export function FullWidthPageLayout({ children, areaTitleLink, areaTitle, primaryAction, pageActions, overflowActions, errors, busy }: PropsWithChildren<FullWidthPageLayoutProps>) {
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabled();
    const isPageHeaderVNextEnabledOutsideOfProjects = useIsPageHeaderVNextEnabledOutsideOfProjects();
    const isProjectsPage = useIsProjectsPage();
    const currentPageShouldApplyVNextHeaders = isProjectsPage || isPageHeaderVNextEnabledOutsideOfProjects;
    const shouldShowPageHeaderVNext = isPageHeaderVNextEnabled && currentPageShouldApplyVNextHeaders;
    return (<main>
            {!shouldShowPageHeaderVNext && <AreaHeaderCurrent areaTitle={areaTitle} areaTitleLink={areaTitleLink} primaryAction={primaryAction} pageActions={pageActions} overflowActions={overflowActions}/>}
            <ContextualHelpLayout>
                {shouldShowPageHeaderVNext && (<BusyFromPromise promise={busy}>
                        {(busy: boolean) => (busy ? <SkeletonLoadingLayout errors={errors}/> : <AreaHeaderVNext areaTitle={areaTitle} primaryAction={primaryAction} pageActions={pageActions} overflowActions={overflowActions}/>)}
                    </BusyFromPromise>)}
                <div className={styles.container}>
                    <div className={styles.content}>
                        {!shouldShowPageHeaderVNext && <BusyFromPromise promise={busy}>{(isBusy: boolean) => <LinearProgress variant={"indeterminate"} show={isBusy}/>}</BusyFromPromise>}
                        {errors && <ErrorPanel errors={errors.errors} message={errors.message} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink} statusCode={errors.statusCode}/>}
                        <ConsumerContent>{children}</ConsumerContent>
                    </div>
                </div>
            </ContextualHelpLayout>
        </main>);
}
function ConsumerContent({ children }: React.PropsWithChildren<{}>) {
    return (<div className={styles.consumerContent}>
            <TransitionAnimation>{children}</TransitionAnimation>
        </div>);
}
interface AreaHeaderCurrentProps {
    areaTitle?: string;
    areaTitleLink?: LinkHref;
    primaryAction?: PrimaryPageAction;
    pageActions?: PageAction[];
    overflowActions?: Array<MenuItem>;
}
function AreaHeaderCurrent({ areaTitle, areaTitleLink, pageActions, primaryAction, overflowActions }: AreaHeaderCurrentProps) {
    return (<AreaTitle link={areaTitleLink} title={areaTitle}>
            <PageActions primaryAction={primaryAction} actions={pageActions} overflowActions={overflowActions}/>
        </AreaTitle>);
}
function AreaHeaderVNext({ areaTitle, primaryAction, pageActions, overflowActions }: Pick<FullWidthPageLayoutProps, "areaTitle" | "primaryAction" | "pageActions" | "overflowActions">) {
    return <PageHeaderPrimary title={areaTitle ? areaTitle : ""} primaryAction={primaryAction} actions={pageActions} overflowActions={overflowActions}></PageHeaderPrimary>;
}
