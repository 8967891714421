import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import { repository } from "~/clientInstance";
import type { Errors } from "~/components/DataBaseComponent/index";
import DataBaseComponent from "~/components/DataBaseComponent/index";
import { FullWidthPageLayout } from "~/components/FullWidthPageLayout/FullWidthPageLayout";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
export interface LicenseGuardProps {
    spaceId: string;
    children: JSX.Element;
}
interface State {
    licenseChecked: boolean;
    hasInsightsLicenseFeature: boolean;
}
function LoadingLayout({ errors, spaceId }: {
    spaceId: string;
    errors: Errors | undefined;
}) {
    return <FullWidthPageLayout areaTitle={"Insights"} areaTitleLink={links.insightReportListPage.generateUrl({ spaceId })} busy={true} errors={errors}/>;
}
class LicenseChecker extends DataBaseComponent<LicenseGuardProps, State> {
    constructor(props: LicenseGuardProps) {
        super(props);
        this.state = {
            licenseChecked: false,
            hasInsightsLicenseFeature: false,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const licenseCheck = await repository.InsightsReports.getInsightsLicenseCheck();
            this.setState({ licenseChecked: true, hasInsightsLicenseFeature: licenseCheck.HasInsightsLicenseFeature });
        });
    }
    render() {
        const { hasInsightsLicenseFeature } = this.state;
        if (this.state.busy || !this.state.licenseChecked)
            return <LoadingLayout spaceId={this.props.spaceId} errors={this.errors}/>;
        if (hasInsightsLicenseFeature)
            return this.props.children;
        return <InternalRedirect to={links.insightsUpsellPage.generateUrl({ spaceId: this.props.spaceId })}/>;
    }
    static displayName = "LicenseChecker";
}
export default LicenseChecker;
