import type { WellKnownPropertyValues, GlobalLoggingContextProvider } from "@octopusdeploy/logging";
import type { PageDefinition } from "@octopusdeploy/portal-routes";
type PortalGlobalLoggingContext = WellKnownPropertyValues<{
    PageId?: string;
    PageName?: string;
    PageArea?: string;
    BrowserSessionId?: string;
}>;
let portalLoggerContext: PortalGlobalLoggingContext = {};
export const portalLoggingContextProvider: GlobalLoggingContextProvider<PortalGlobalLoggingContext> = {
    getProperties: () => ({ ...portalLoggerContext }),
    reset: () => globalPortalLogContext.resetContext(),
};
export const globalPortalLogContext = {
    resetContext() {
        portalLoggerContext = {};
    },
    setPage(page: PageDefinition) {
        portalLoggerContext.PageId = page.Id;
        portalLoggerContext.PageName = page.Name;
        portalLoggerContext.PageArea = page.Area;
    },
    setBrowserSessionId(browserSessionId: string) {
        portalLoggerContext.BrowserSessionId = browserSessionId;
    },
};
