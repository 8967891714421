/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { nameForLink, resolveStringPathWithSpaceId, useDispatchLinkClicked } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import type { LinkEvent } from "~/analytics/Analytics";
import { useUrlResolver } from "~/components/Navigation/useUrlResolver";
import { exposeComponentAsClass } from "../exposeComponentAsClass";
import { useIsPortalUrlActive } from "../useIsPortalUrlActive";
interface InternalNavLinkComponentProps extends React.HTMLProps<HTMLElement> {
    className?: string;
    activeClassName?: string;
    to: LinkHref;
    exact?: boolean;
}
type InternalNavLinkProps = InternalNavLinkComponentProps & RouteComponentProps<{
    spaceId: string | undefined;
}>;
const InternalNavLink: React.SFC<InternalNavLinkProps> = (props: InternalNavLinkProps) => {
    const urlResolver = useUrlResolver();
    const resolvedTo = typeof props.to === "string" ? resolveStringPathWithSpaceId(props.to, props.match.params.spaceId) : urlResolver.resolve(props.to);
    const dispatchLink = useDispatchLinkClicked();
    const isUrlActive = useIsPortalUrlActive();
    /* eslint-disable react/forbid-elements */
    return (<NavLink className={props.className} key={resolvedTo} to={resolvedTo} exact={props.exact} isActive={() => isUrlActive(props.to, props.exact)} activeClassName={props.activeClassName} onClick={(e) => {
            const ev: LinkEvent = {
                linkLabel: e.currentTarget.innerText ?? "",
                documentUrl: undefined,
            };
            dispatchLink(nameForLink(), ev);
        }}>
            {props.children}
        </NavLink>);
};
InternalNavLink.displayName = "InternalNavLink"
export default exposeComponentAsClass(withRouter(InternalNavLink));
