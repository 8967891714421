import type { DeploymentTargetResource, ResourceCollection, TaskResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import List from "~/components/PagingList";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import TaskDetails from "~/components/TaskDetails/TaskDetails";
class DeploymentTasksList extends List<TaskResource<{}>> {
}
interface MachineDeploymentsPageProps {
    machineId: string;
}
interface MachineDeploymentsInternalProps {
    initialData: InitialData;
}
interface InitialData {
    machine: DeploymentTargetResource;
    tasksResponse: ResourceCollection<TaskResource<{}>>;
}
interface MachineDeploymentsState extends DataBaseComponentState {
    tasksResponse: ResourceCollection<TaskResource<{}>>;
}
const Title = "Deployments";
const MachineDeploymentsLayoutFormPage = FormPage<InitialData>();
export function MachineDeploymentsPage({ machineId }: MachineDeploymentsPageProps) {
    return (<MachineDeploymentsLayoutFormPage title={Title} load={async () => {
            const machine = await repository.Machines.get(machineId);
            const tasksResponse = await repository.Machines.getDeployments(machine, { skip: 0 });
            return { machine, tasksResponse };
        }} renderWhenLoaded={(data) => <MachineDeploymentsLayoutInner initialData={data}/>}/>);
}
class MachineDeploymentsLayoutInner extends DataBaseComponent<MachineDeploymentsInternalProps, MachineDeploymentsState> {
    constructor(props: MachineDeploymentsInternalProps) {
        super(props);
        this.state = {
            tasksResponse: props.initialData.tasksResponse,
        };
    }
    render() {
        return (<PaperLayout title={Title} busy={this.state.busy} errors={this.errors}>
                <DeploymentTasksList initialData={this.state.tasksResponse} onRow={(item: TaskResource<{}>) => this.buildDeploymentTaskRow(item)} onRowRedirectUrl={(task: TaskResource<{}>) => links.taskPage.generateUrl({ taskId: task.Id })} showPagingInNumberedStyle={true} onPageSelected={async (skip: number, p: number) => {
                await this.loadDeploymentTasks(skip);
            }}/>
            </PaperLayout>);
    }
    private async loadDeploymentTasks(skip: number) {
        const tasksResponse = await repository.Machines.getDeployments(this.props.initialData.machine, { skip });
        this.setState({ tasksResponse });
    }
    private buildDeploymentTaskRow(task: TaskResource<{}>) {
        return <TaskDetails task={task} stripTopBottomPadding={true}/>;
    }
    static displayName = "MachineDeploymentsLayoutInner";
}
