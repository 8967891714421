/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { Repository } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import AreaTitle from "~/components/AreaTitle/index";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import PaperLayout from "~/components/PaperLayout/index";
import { ProjectTriggerRedirect } from "~/components/RootRoutes/ProjectTriggerRedirect";
import { repository } from "../../clientInstance";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import { SkeletonLoadingLayout } from "../SkeletonLoadingLayout/SkeletonLoadingLayout";
import { useIsPageHeaderVNextEnabled } from "./useIsPageHeaderVNextEnabled";
interface ProjectChildRedirectState extends DataBaseComponentState {
    redirectTo: LinkHref;
}
interface ProjectChildRedirectProps {
    spaceId: string;
    getProjectId: (repository: Repository) => Promise<string>;
    getRedirectPath: (projectId: string) => LinkHref;
}
interface ProjectChildRedirectPropsInternal extends ProjectChildRedirectProps {
    isPageHeaderVNextEnabled: boolean;
}
class ProjectChildRedirectInternal extends DataBaseComponent<ProjectChildRedirectPropsInternal, ProjectChildRedirectState> {
    constructor(props: ProjectChildRedirectPropsInternal) {
        super(props);
        this.state = {
            redirectTo: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const projectId = await this.props.getProjectId(repository);
            const redirectTo = this.props.getRedirectPath(projectId);
            this.setState({
                redirectTo,
            });
        });
    }
    render() {
        const redirectTo = this.state.redirectTo;
        if (!redirectTo) {
            return this.props.isPageHeaderVNextEnabled ? (<main>
                    <SkeletonLoadingLayout errors={this.errors}/>
                </main>) : (<main>
                    <AreaTitle link={links.projectsPage.generateUrl({ spaceId: this.props.spaceId })} title="Projects"/>
                    <PaperLayout busy={this.state.busy} fullWidth={true} errors={this.errors}/>
                </main>);
        }
        return <InternalRedirect push={false} to={redirectTo}/>;
    }
    static displayName = "ProjectChildRedirectInternal";
}
function ProjectChildRedirect(props: ProjectChildRedirectProps) {
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabled();
    return <ProjectChildRedirectInternal {...props} isPageHeaderVNextEnabled={isPageHeaderVNextEnabled}/>;
}
export const ReleaseRedirect: React.FC<{
    spaceId: string;
    releaseId: string;
}> = (props) => {
    return (<ProjectChildRedirect spaceId={props.spaceId} getProjectId={async (r) => (await r.Releases.get(props.releaseId)).ProjectId} getRedirectPath={(projectId: string) => links.releasePage.generateUrl({ spaceId: props.spaceId, projectSlug: projectId, releaseVersion: props.releaseId })}/>);
};
ReleaseRedirect.displayName = "ReleaseRedirect"
export const TriggerRedirect: React.FC<{
    spaceId: string;
    triggerId: string;
}> = (props) => {
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabled();
    return <ProjectTriggerRedirect spaceId={props.spaceId} triggerId={props.triggerId} isPageHeaderVNextEnabled={isPageHeaderVNextEnabled}/>;
};
TriggerRedirect.displayName = "TriggerRedirect"
export const ChannelRedirect: React.FC<{
    spaceId: string;
    channelId: string;
}> = (props) => {
    return (<ProjectChildRedirect spaceId={props.spaceId} getProjectId={async (r) => (await r.Channels.get(props.channelId)).ProjectId} getRedirectPath={(projectId: string) => links.editChannelPage.generateUrl({ spaceId: props.spaceId, projectSlug: projectId, channelId: props.channelId })}/>);
};
ChannelRedirect.displayName = "ChannelRedirect"
export const RunbookRedirect: React.FC<{
    spaceId: string;
    runbookId: string;
}> = (props) => {
    return (<ProjectChildRedirect spaceId={props.spaceId} getProjectId={async (r) => (await r.Runbooks.get(props.runbookId)).ProjectId} getRedirectPath={(projectId: string) => links.runbookRootRedirect.generateUrl({ spaceId: props.spaceId, projectSlug: projectId, runbookId: props.runbookId })}/>);
};
RunbookRedirect.displayName = "RunbookRedirect"
