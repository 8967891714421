/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButtonType, NavigationButton, NavigationButtonType } from "@octopusdeploy/design-system-components";
import type { ActionTemplateResource, ResourceCollection } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import ImportActionTemplateDialog from "~/areas/library/components/ActionTemplates/ImportActionTemplateDialog";
import { repository } from "~/clientInstance";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import PaperLayout from "~/components/PaperLayout";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import LibraryLayout from "../LibraryLayout/LibraryLayout";
import ActionTemplateList from "./ActionTemplateList";
import styles from "./style.module.less";
interface ActionTemplatesPageProps {
    spaceId: string;
}
interface ActionTemplatesPageState extends DataBaseComponentState {
    templates: ResourceCollection<ActionTemplateResource>;
    isLoaded: boolean;
    importedTemplateId?: string;
}
export class ActionTemplatesPage extends DataBaseComponent<ActionTemplatesPageProps, ActionTemplatesPageState> {
    constructor(props: ActionTemplatesPageProps) {
        super(props);
        this.state = {
            templates: null!,
            isLoaded: false,
            importedTemplateId: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const templates = await repository.ActionTemplates.list({ take: 250 });
            this.setState({
                templates,
                isLoaded: true,
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    render() {
        if (this.state.importedTemplateId) {
            return <InternalRedirect to={links.editStepTemplatePage.generateUrl({ spaceId: this.props.spaceId, templateId: this.state.importedTemplateId })} push={true}/>;
        }
        return (<LibraryLayout spaceId={this.props.spaceId}>
                <PaperLayout title="Step Templates" busy={this.state.busy} errors={this.errors}>
                    {this.state.isLoaded && (<div className={styles.lists}>
                            <ActionTemplateList templates={this.state.templates} autoFocusOnFilterSearch={true} filter={(t) => this.isCustomTemplate(t)} title="Custom Step Templates" actions={[
                    <OpenDialogButton key="import" label="Import" type={ActionButtonType.Secondary}>
                                        <ImportActionTemplateDialog onSaveDone={(template) => this.setState({ importedTemplateId: template.Id })}/>
                                    </OpenDialogButton>,
                    <NavigationButton key="add" label="Add" type={NavigationButtonType.Primary} href={links.builtInStepTemplatesPage.generateUrl({ spaceId: this.props.spaceId })}/>,
                ]} onPrimaryActionRequested={(template) => template.ActionType === "Octopus.Script" && (<NavigationButton label="Run" type={NavigationButtonType.Ternary} icon={<ThirdPartyIcon iconType={ThirdPartyIconType.PlayArrow}/>} href={links.runStepTemplatePage.generateUrl({ spaceId: template.SpaceId, templateId: template.Id })}/>)}/>
                            <ActionTemplateList templates={this.state.templates} autoFocusOnFilterSearch={false} filter={(t) => !this.isCustomTemplate(t)} actions={[<NavigationButton key="browse" label="Browse library" type={NavigationButtonType.Primary} href={links.communityStepTemplatesPage.generateUrl({ spaceId: this.props.spaceId })}/>]} title="Installed Community Step Templates"/>
                        </div>)}
                </PaperLayout>
            </LibraryLayout>);
    }
    private isCustomTemplate = (template: ActionTemplateResource) => {
        return !template.CommunityActionTemplateId;
    };
    static displayName = "ActionTemplatesPage";
}
