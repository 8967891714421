import type { DeploymentActionResource, DeploymentStepResource, GitRefResource, IProcessResource, PropertyValueResource } from "@octopusdeploy/octopus-server-client";
import { PackageRequirement } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import { compact } from "lodash";
import type { ActionPlugin } from "~/components/Actions/pluginRegistry";
import pluginRegistry from "~/components/Actions/pluginRegistry";
import { isRunbookProcessState } from "../Common/CommonProcessHelpers";
import type { ProcessContextModelState, ProcessState, StoredAction, StoredModelState, StoredStep } from "../types";
export const getProcessResource = (state: StoredModelState) => {
    return (): Readonly<IProcessResource> => {
        if (!state.process) {
            throw Error("The current process has not been set");
        }
        const stepResources = getAllSteps(state)().map((storedStep) => {
            return getStepResource(state)(storedStep.Id);
        });
        return {
            ...state.process,
            Steps: stepResources,
        };
    };
};
export const canActionRunOnWorker = (getPlugin: (id: string) => ActionPlugin) => {
    return (actionId: string) => {
        const action = getPlugin(actionId);
        //If we don't specify whether an action can run on a worker, then we default to true.
        const { canRunOnWorker = true } = action;
        return canRunOnWorker ?? false;
    };
};
export const shouldShowRunTrigger = (state: StoredModelState) => {
    return (actionId: string) => {
        return !isFirstStep(state)(getParentStep(state)(actionId).Id) && !isChildAction(state)(actionId);
    };
};
export const canBeRetried = (getPlugin: (id: string) => ActionPlugin) => {
    return (actionId: string) => {
        const action = getPlugin(actionId);
        const { canBeRetried = true } = action;
        return canBeRetried ?? false;
    };
};
export const canUseExecutionTimeouts = (getPlugin: (id: string) => ActionPlugin) => {
    return (actionId: string) => {
        const action = getPlugin(actionId);
        const { canUseExecutionTimeouts = true } = action;
        return canUseExecutionTimeouts ?? false;
    };
};
export const isMerging = (state: ProcessContextModelState) => {
    return (): boolean => {
        return hasSteps(state.mergeModel.staged)();
    };
};
export const isProcessMerged = (state: ProcessContextModelState) => {
    return (): boolean => {
        return state.mergeModel.processMerged;
    };
};
export const isMergeDialogClosed = (state: ProcessContextModelState) => {
    return (): boolean => {
        return state.mergeModel.dialogClosed;
    };
};
export const getStepResource = (state: StoredModelState) => {
    return (stepId: string): DeploymentStepResource => {
        const { ActionIds, ...stepResource } = getStepById(state)(stepId);
        return {
            ...stepResource,
            Actions: getChildActionResource(state)(stepId),
        };
    };
};
export const getActionResource = (state: StoredModelState) => {
    return (actionId: string): DeploymentActionResource => {
        const { ParentId, plugin, ...action } = getActionById(state)(actionId);
        return { ...action };
    };
};
const getChildActionResource = (state: StoredModelState) => {
    return (stepId: string) => {
        const childActions: DeploymentActionResource[] = getChildActions(state)(stepId).map((x) => getActionResource(state)(x.Id));
        return childActions;
    };
};
export const hasChildActions = (state: StoredModelState) => {
    return (): boolean => getChildActions(state).length > 0;
};
export const hasSteps = (state: StoredModelState) => {
    return (): boolean => state.steps.allIds.length > 0;
};
export const hasUnsavedSteps = (state: ProcessContextModelState) => {
    return (): boolean => state.model.steps.allIds.length > 0;
};
export const getStoredProcess = (state: StoredModelState) => {
    return (): NonNullable<ProcessState> => {
        const resource = state.process;
        if (!resource) {
            throw Error("Resource has not been initialized");
        }
        return resource;
    };
};
export const getOptionalStoredProcess = (state: StoredModelState) => {
    return (): ProcessState | null => {
        return state.process;
    };
};
export const getStepProperty = (state: StoredModelState) => {
    return (stepId: string, propertyName: string): PropertyValueResource => {
        return state.steps.byId[stepId]?.Properties[propertyName] ?? null;
    };
};
export const getActionProperty = (state: StoredModelState) => {
    return (actionId: string, propertyName: string): PropertyValueResource => {
        return state.actions.byId[actionId]?.Properties[propertyName] ?? null;
    };
};
export const isNewAction = (state: ProcessContextModelState) => {
    return (actionId: string) => {
        //Since the action may not exist in the clean model we won't use a selector for it
        const existsInCleanModel = Object.prototype.hasOwnProperty.call(state.cleanModel.actions.byId, actionId);
        return !existsInCleanModel && hasAction(state.model)(actionId);
    };
};
export const initiallyHadSteps = (state: ProcessContextModelState) => {
    return (): boolean => state.cleanModel.steps.allIds.length > 0;
};
export const getActionById = (state: StoredModelState) => {
    return (actionId: string): StoredAction => {
        const action = state.actions.byId[actionId];
        if (!action) {
            throw Error(`Action with id ${actionId} was not found`);
        }
        return action;
    };
};
export const tryGetActionById = (state: StoredModelState) => {
    return (actionId: string): StoredAction | null => {
        const action = state.actions.byId[actionId];
        return action;
    };
};
export const tryGetCleanActionById = tryGetActionById;
export const tryGetActionByName = (state: StoredModelState) => {
    return (name: string): StoredAction | null => {
        const action = Object.values(state.actions.byId).find((x) => x.Name === name);
        return action ?? null;
    };
};
export const getStepById = (state: StoredModelState) => {
    return (stepId: string): StoredStep => {
        const step = state.steps.byId[stepId];
        if (!step) {
            throw Error(`Step with id ${stepId} was not found`);
        }
        return step;
    };
};
export const getStepBySlug = (state: StoredModelState) => {
    return (slug: string): StoredStep => {
        const step = Object.values(state.steps.byId).find((x) => x.Slug === slug);
        if (!step) {
            throw Error(`Step with slug ${slug} was not found`);
        }
        return step;
    };
};
export const tryGetStepById = (state: StoredModelState) => {
    return (stepId: string): StoredStep | null => {
        const step = state.steps.byId[stepId];
        return step;
    };
};
export const tryGetCleanStepById = tryGetStepById;
export const tryGetStepByName = (state: StoredModelState) => {
    return (name: string): StoredStep | null => {
        const step = Object.values(state.steps.byId).find((x) => x.Name === name);
        return step ?? null;
    };
};
export const getChildActions = (state: StoredModelState) => {
    return (stepId: string): StoredAction[] => {
        const actionIds = getStepById(state)(stepId)?.ActionIds ?? [];
        return compact(actionIds.map(getActionById(state)));
    };
};
export const getAllSteps = (state: StoredModelState) => {
    return () => compact(state.steps.allIds.map((x) => state.steps.byId[x]));
};
export const getAllActions = (state: StoredModelState) => {
    return () => compact(state.actions.allIds.map((x) => state.actions.byId[x]));
};
export const getParentStep = (state: StoredModelState) => {
    return (actionId: string): StoredStep => {
        const action = getActionById(state)(actionId);
        return getStepById(state)(action.ParentId);
    };
};
export const isFirstStep = (state: StoredModelState) => {
    return (stepId: string): boolean => {
        return state.steps.allIds[0] === stepId;
    };
};
export const isFirstChildAction = (state: StoredModelState) => {
    return (actionId: string): boolean => {
        const action = getActionById(state)(actionId);
        const step = getStepById(state)(action.ParentId);
        return step.ActionIds[0] === actionId;
    };
};
export const isChildAction = (state: StoredModelState) => {
    return (actionId: string): boolean => {
        const action = getActionById(state)(actionId);
        const step = getStepById(state)(action.ParentId);
        return step.ActionIds.length > 1;
    };
};
export const isOnlyChildAction = (state: StoredModelState) => {
    return (actionId: string): boolean => {
        const step = getParentStep(state)(actionId);
        return step.ActionIds.length === 1;
    };
};
export const isPackageAction = (state: StoredModelState) => {
    return (actionId: string): boolean => {
        const action = getActionById(state)(actionId);
        const actionPlugin = getActionPlugin(state)(action.Id);
        const isContainerConfigured = !!action.Container.FeedId && !!action.Container.Image;
        const isPackageRequired = !!actionPlugin && actionPlugin.hasPackages && actionPlugin.hasPackages(action);
        return action.Packages.length > 0 || isPackageRequired || isContainerConfigured;
    };
};
export const isStepPackageAction = (state: StoredModelState) => {
    return (actionId: string): boolean => {
        const action = getActionById(state)(actionId);
        const actionPlugin = getActionPlugin(state)(action.Id);
        return actionPlugin.stepPackage !== undefined;
    };
};
export const actionHasFeatures = (state: StoredModelState) => {
    return (actionId: string): boolean => {
        const action = getActionById(state)(actionId);
        //This is impure, however based on our current usage it may as well be. If we ever start registering action plugins dynamically
        //such as via extensions this assumption won't hold. That is particuarly important if we end up memoizing the results based on the arguments for this selector
        return pluginRegistry.hasFeaturesForAction(action.ActionType);
    };
};
export const getActionPlugin = (state: StoredModelState) => {
    return (actionid: string): ActionPlugin => {
        const action = getActionById(state)(actionid);
        return action.plugin;
    };
};
export const getCleanActionPlugin = getActionPlugin;
export const getStepRightAfterPackageAcquisition = (state: StoredModelState) => {
    return (): StoredStep | undefined => {
        const allSteps = getAllSteps(state)();
        return allSteps.find((step) => step.PackageRequirement === PackageRequirement.AfterPackageAcquisition || isPackageStep(state)(step.Id));
    };
};
export const shouldShowPackageRequirementOptionForAction = (state: StoredModelState) => {
    return (actionId: string) => {
        return !isChildAction(state)(actionId) && shouldShowPackageRequirementOptionForStep(state)(getParentStep(state)(actionId).Id);
    };
};
export const shouldShowPackageRequirementOptionForStep = (state: StoredModelState) => {
    return (stepId: string) => {
        // Step packages can only execute after package acquisition
        if (isFromStepPackage(state)(stepId))
            return false;
        //
        // Note that this isn't quite enough!  If there's a rolling deploy with manual intervention followed by a package step
        // that needs a worker, we need to inject package acquisition into the list of actions for that step.
        // see: https://github.com/OctopusDeploy/Issues/issues/4713
        //
        const currentStep = getStepById(state)(stepId);
        const stepRightAfterPackageAcquisition = getStepRightAfterPackageAcquisition(state)();
        const allSteps = getAllSteps(state)();
        const indexOfCurrentStep = allSteps.indexOf(currentStep);
        const indexOfTheStepRightAfterPackageAcquisition = !stepRightAfterPackageAcquisition ? -1 : allSteps.indexOf(stepRightAfterPackageAcquisition);
        return indexOfTheStepRightAfterPackageAcquisition === -1 || indexOfTheStepRightAfterPackageAcquisition > indexOfCurrentStep || (indexOfCurrentStep === indexOfTheStepRightAfterPackageAcquisition && !isPackageStep(state)(currentStep.Id));
    };
};
export const getStepNumber = (state: StoredModelState) => {
    return (stepId: string): number => {
        return state.steps.allIds.indexOf(stepId) + 1;
    };
};
export const canActionHaveChildren = (state: StoredModelState) => {
    return (actionId: string) => {
        const action = getActionById(state)(actionId);
        const step = getStepById(state)(action.ParentId);
        return action.plugin.canHaveChildren(step) && step.ActionIds.length === 1;
    };
};
export const canActionBeChild = (state: StoredModelState) => {
    return (actionId: string) => {
        const action = getActionById(state)(actionId);
        return action.plugin.canBeChild;
    };
};
export const canStepBeChild = (state: StoredModelState) => {
    return (stepId: string) => {
        const step = getStepById(state)(stepId);
        return step.ActionIds.length === 1 && canActionBeChild(state)(step.ActionIds[0]);
    };
};
export const isStepDisabled = (state: StoredModelState) => {
    return (stepId: string) => {
        const storedActions = getChildActions(state)(stepId);
        return storedActions.every((x) => !!x.IsDisabled);
    };
};
export const getActionNumber = (state: StoredModelState) => {
    return (actionId: string) => {
        const action = getActionById(state)(actionId);
        const step = getStepById(state)(action.ParentId);
        return step.ActionIds.indexOf(actionId) + 1;
    };
};
export const getAddChildStepUrl = (state: StoredModelState) => {
    return (projectSlug: string, gitRef: GitRefResource | undefined, stepId: string) => {
        const process = getStoredProcess(state)();
        if (isRunbookProcessState(process)) {
            return links.projectRunbookProcessStepsPage.generateUrl({ spaceId: process.SpaceId, projectSlug, runbookId: process.RunbookId, processId: process.Id }, { childStepTemplates: true, parentStepId: stepId });
        }
        return gitRef
            ? links.branchDeploymentProcessStepsPage.generateUrl({ spaceId: process.SpaceId, projectSlug, branchName: gitRef.CanonicalName }, { childStepTemplates: true, parentStepId: stepId })
            : links.deploymentProcessStepsPage.generateUrl({ spaceId: process.SpaceId, projectSlug }, { childStepTemplates: true, parentStepId: stepId });
    };
};
export const getStepDetailsUrl = (state: StoredModelState) => {
    return (projectSlug: string, gitRef: GitRefResource | undefined, parentStepId: string, actionId: string | null): LinkHref => {
        const process = getStoredProcess(state)();
        if (isRunbookProcessState(process)) {
            return links.projectRunbookProcessStepsPage.generateUrl({ spaceId: process.SpaceId, projectSlug, runbookId: process.RunbookId, processId: process.Id }, { actionId: actionId ?? undefined, parentStepId: parentStepId ?? undefined });
        }
        else {
            return gitRef
                ? links.branchDeploymentProcessStepsPage.generateUrl({ spaceId: process.SpaceId, projectSlug, branchName: gitRef.CanonicalName }, { actionId: actionId ?? undefined, parentStepId: parentStepId ?? undefined })
                : links.deploymentProcessStepsPage.generateUrl({ spaceId: process.SpaceId, projectSlug }, { actionId: actionId ?? undefined, parentStepId: parentStepId ?? undefined });
        }
    };
};
export const isActionDisabled = (state: StoredModelState) => {
    return (actionId: string) => {
        const action = getActionById(state)(actionId);
        return action.IsDisabled;
    };
};
export const isRollingStep = (state: StoredModelState) => {
    return (stepId: string) => {
        const storedActions = getChildActions(state)(stepId);
        return storedActions.length > 1;
    };
};
export const isPackageStep = (state: StoredModelState) => {
    return (stepId: string) => {
        const step = getStepById(state)(stepId);
        return step.ActionIds.some((actionId) => isPackageAction(state)(actionId));
    };
};
export const isFromStepPackage = (state: StoredModelState) => {
    return (stepId: string) => {
        const step = getStepById(state)(stepId);
        return step.ActionIds.some((actionId) => isStepPackageAction(state)(actionId));
    };
};
//This seems to be a bit of an existential question for poor mr step.
export const canStepHaveChildren = (state: StoredModelState) => {
    return (stepId: string) => {
        if (isRollingStep(state)(stepId)) {
            return true;
        }
        const step = getStepById(state)(stepId);
        const actions = getChildActions(state)(stepId);
        return actions.every((action) => {
            return action.plugin.canHaveChildren(step);
        });
    };
};
export const hasStep = (state: StoredModelState) => {
    return (stepId: string) => {
        return Object.prototype.hasOwnProperty.call(state.steps.byId, stepId) && !!state.steps.byId[stepId];
    };
};
export const hasAction = (state: StoredModelState) => {
    return (actionId: string) => {
        return Object.prototype.hasOwnProperty.call(state.actions.byId, actionId) && !!state.actions.byId[actionId];
    };
};
export const getStepByIndex = (state: StoredModelState) => {
    return (index: number) => {
        return getStepById(state)(state.steps.allIds[index]);
    };
};
export const getActionParentStep = (state: StoredModelState) => {
    return (actionId: string) => {
        const action = getActionById(state)(actionId);
        return getStepById(state)(action.ParentId);
    };
};
