/* eslint-disable no-eq-null */
import { LinearProgress } from "@octopusdeploy/design-system-components";
import type { TenantMissingVariableResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { compact } from "lodash";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import { PageHeaderPrimary } from "~/components/PageHeaderPrimary";
import { PortalPageLayout } from "~/components/PortalPageLayout/PortalPageLayout";
import { useIsPageHeaderVNextEnabledOutsideOfProjects } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import { SkeletonLoadingLayout } from "~/components/SkeletonLoadingLayout/SkeletonLoadingLayout";
import type { Errors } from "../../../components/DataBaseComponent";
import DataLoader from "../../../components/DataLoader";
import ErrorPanel from "../../../components/ErrorPanel";
import NavigationSidebarLayout, { Navigation } from "../../../components/NavigationSidebarLayout";
import MissingVariablesIcon from "../MissingVariablesIcon/MissingVariablesIcon";
import { tenantsActions } from "../tenantsArea";
import type { TenantState } from "../tenantsArea";
interface TenantLayoutInternalProps {
    spaceId: string;
    tenant: TenantState;
}
interface TenantLayoutLoaderProps {
    tenantId: string;
    spaceId: string;
}
const TenantLoader = DataLoader<{}>();
const TenantLayoutLoader: React.FC<TenantLayoutLoaderProps> = (props) => {
    const tenantState = useSelector<GlobalState, TenantState | null>((s) => s.tenantsArea.currentTenant);
    const dispatch = useDispatch();
    return (<TenantLoader load={async () => {
            if (tenantState?.id !== props.tenantId) {
                const tenant = await repository.Tenants.get(props.tenantId);
                onTenantFetched(tenant);
                const variables = await repository.Tenants.missingVariables({ tenantId: tenant.Id }, false);
                onTenantVariablesFetched(variables.find((t) => t.TenantId === tenant.Id));
            }
            return {};
        }} operationName="TenantLayout" renderWhenLoaded={(_) => {
            if (tenantState == null || tenantState.id !== props.tenantId) {
                throw new Error("Tenant loaded and stored in redux does not exist, or does not match the current route");
            }
            return (<TenantLayoutInternal spaceId={props.spaceId} tenant={tenantState}>
                        {props.children}
                    </TenantLayoutInternal>);
        }} renderAlternate={({ busy, errors }) => {
            return <TenantLoadingLayout spaceId={props.spaceId} busy={busy} errors={errors}/>;
        }}/>);
    function onTenantFetched(tenant: TenantResource) {
        dispatch(tenantsActions.tenantFetched(tenant));
    }
    function onTenantVariablesFetched(tenantMissingVariables?: TenantMissingVariableResource) {
        dispatch(tenantsActions.tenantMissingVariablesFetched(tenantMissingVariables));
    }
};
TenantLayoutLoader.displayName = "TenantLayoutLoader"
interface TenantLoadingLayoutProps {
    spaceId: string;
    errors: Errors | undefined;
    busy: boolean;
}
const TenantLoadingLayout: React.FC<TenantLoadingLayoutProps> = ({ spaceId, busy, errors }) => {
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabledOutsideOfProjects();
    const errorPanel = errors ? <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink}/> : undefined;
    const busyIndicator = <LinearProgress variant={"indeterminate"} show={busy}/>;
    if (isPageHeaderVNextEnabled) {
        return (<main>
                <SkeletonLoadingLayout errors={errors}/>
            </main>);
    }
    return (<main>
            <AreaTitle link={links.tenantsPage.generateUrl({ spaceId })} title="Tenants" busyIndicator={busyIndicator}/>
            {errorPanel}
        </main>);
};
TenantLoadingLayout.displayName = "TenantLoadingLayout"
const TenantLayoutInternal: React.FC<TenantLayoutInternalProps> = ({ spaceId, tenant, children }) => {
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabledOutsideOfProjects();
    const variableLink = tenant.hasMissingVariables ? (<span>
            Variables <MissingVariablesIcon show={true}/>
        </span>) : ("Variables");
    const sidebarLinks = compact([
        Navigation.navItem("Overview", links.tenantOverviewPage.generateUrl({ spaceId, tenantId: tenant.id })),
        Navigation.navItem(variableLink, links.tenantVariablesPage.generateUrl({ spaceId, tenantId: tenant.id })),
        Navigation.navItem("Tasks", links.tenantTasksPage.generateUrl({ spaceId, tenantId: tenant.id }), undefined, {
            permission: Permission.TaskView,
            tenant: tenant.id,
        }),
        Navigation.navItem("Settings", links.tenantSettingsPage.generateUrl({ spaceId, tenantId: tenant.id })),
    ]);
    if (isPageHeaderVNextEnabled) {
        return (<main>
                <PortalPageLayout navItems={sidebarLinks} content={children} description={tenant.description ?? undefined} header={<PageHeaderPrimary title={tenant.name} logo={{ href: tenant.logoUrl, accessibleName: `${tenant.name} logo}` }} breadcrumbs={[{ label: "Tenants", pageUrl: links.tenantsPage.generateUrl({ spaceId }) }]}/>}/>
            </main>);
    }
    return (<main>
            <AreaTitle link={links.tenantsPage.generateUrl({ spaceId })} title="Tenants"/>
            <NavigationSidebarLayout logoUrl={tenant.logoUrl} name={tenant.name} navLinks={sidebarLinks} content={children} description={tenant.description ?? undefined}/>
        </main>);
};
TenantLayoutInternal.displayName = "TenantLayoutInternal"
export default TenantLayoutLoader;
