/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { Drawer } from "@material-ui/core";
import { IconButton } from "@octopusdeploy/design-system-components";
import { usePage } from "@octopusdeploy/portal-routes";
import classNames from "classnames";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import configurationSelectors from "~/areas/configuration/reducers/selectors";
import drawerActions from "~/components/ContextualHelpLayout/reducers/actions";
import * as drawerSelectors from "~/components/ContextualHelpLayout/reducers/selectors";
import styles from "./ContextualHelpLayout.module.less";
import { HelpSidebarHandle } from "./HelpSidebarHandle";
import { HelpSidebarResources } from "./HelpSidebarResources";
import { PageHelpOverviewView, PageHelpTopicsView } from "./PageHelpRegistry/PageHelpSelector";
export function ContextualHelpLayout(props: React.PropsWithChildren<{}>) {
    const isDrawerOpen = useSelector(drawerSelectors.isDrawerOpen);
    const isHelpSidebarEnabled = useSelector(configurationSelectors.createFeatureEnabledSelector((x) => x.isHelpSidebarEnabled));
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    return (<div id="drawerwrapper">
            <div className={styles.root}>
                <main className={classNames(styles.mainContent, {
            [styles.mainContentShift]: isLargerThanIpad && isHelpSidebarEnabled && isDrawerOpen,
        })}>
                    {props.children}
                </main>
                <ContextualHelpDrawer open={isLargerThanIpad && isHelpSidebarEnabled ? isDrawerOpen : false}/>
            </div>
        </div>);
}
function ContextualHelpSidebarContent() {
    const page = usePage();
    const dispatch = useDispatch();
    const toggleDrawer: () => void = React.useCallback(() => dispatch(drawerActions.toggleDrawer()), [dispatch]);
    return (<>
            <div className={styles.header}>
                <h4 className={styles.heading}>Help Sidebar</h4>
                <IconButton icon="Cancel" onClick={() => toggleDrawer()}/>
            </div>
            <div className={styles.content}>
                <>
                    <PageHelpOverviewView pageId={page?.Id}/>
                    <PageHelpTopicsView pageId={page?.Id}/>
                </>
            </div>
            <HelpSidebarResources />
        </>);
}
interface ContextualHelpDrawerProps {
    open: boolean;
}
function ContextualHelpDrawer({ open }: ContextualHelpDrawerProps) {
    const [height, setHeight] = React.useState(0);
    const drawerDiv = React.useRef<HTMLDivElement>(null);
    const calculateHeight = React.useCallback(() => {
        if (drawerDiv.current !== null) {
            const top = drawerDiv.current.getBoundingClientRect().top;
            const approxHeightOfPaddingAndOtherJunk = 0;
            setHeight(window.innerHeight - top - approxHeightOfPaddingAndOtherJunk);
        }
    }, []);
    // The material drawer in this layout uses a static position and a height of 100% shifts things like footers off screen
    // we therefore calculate the necessary height and set it
    React.useLayoutEffect(calculateHeight, [calculateHeight]);
    // Since we are manually handling the height, we also need to adjust things based on when the browser resizes
    React.useEffect(() => {
        window.addEventListener("resize", calculateHeight);
        return () => window.removeEventListener("resize", calculateHeight);
    }, [calculateHeight]);
    return (<div className={classNames(styles.wrapper, {
            [styles.drawerHidden]: !open,
        })} style={{ minHeight: `${height - 1}px` }}>
            {open ? <HelpSidebarHandle /> : null}
            <Drawer className={styles.drawer} variant="persistent" anchor="right" open={open} classes={{
            paper: styles.drawerPaper,
        }}>
                <div className={styles.container} ref={drawerDiv} style={{ height }}>
                    {open && <ContextualHelpSidebarContent />}
                </div>
            </Drawer>
        </div>);
}
