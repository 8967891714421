/* eslint-disable @typescript-eslint/no-non-null-assertion,@typescript-eslint/consistent-type-assertions */
import type { ActionTemplateSearchResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import BuiltinActionTemplateList from "~/areas/library/components/ActionTemplates/BuiltinActionTemplateList";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import PaperLayout from "~/components/PaperLayout";
import Section from "~/components/Section/index";
import LibraryLayout from "../LibraryLayout/LibraryLayout";
import styles from "./style.module.less";
interface BuiltInTemplatesPageProps {
    spaceId: string;
}
interface BuiltinTemplatesState extends DataBaseComponentState {
    templates: ActionTemplateSearchResource[];
    isLoaded: boolean;
    filterText?: string;
}
export class BuiltinTemplatesPage extends DataBaseComponent<BuiltInTemplatesPageProps, BuiltinTemplatesState> {
    constructor(props: BuiltInTemplatesPageProps) {
        super(props);
        this.state = {
            templates: null!,
            isLoaded: false,
            filterText: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const templates = await repository.ActionTemplates.search();
            this.setState({
                templates: templates.filter((t) => t.IsBuiltIn),
                isLoaded: true,
            });
        });
    }
    render() {
        return (<LibraryLayout spaceId={this.props.spaceId}>
                <PaperLayout title="Choose Step Template" breadcrumbTitle={"Step Templates"} breadcrumbPath={links.stepTemplatesPage.generateUrl({ spaceId: this.props.spaceId })} fullWidth={true} busy={this.state.busy} errors={this.errors}>
                    {this.state.isLoaded && (<>
                            <Section>
                                <FilterSearchBox placeholder="Filter by name, category or description..." onChange={(value) => this.setState({ filterText: value.toLowerCase() })} fullWidth={true} autoFocus={true} containerClassName={styles.filterSearchBoxContainer}/>
                            </Section>
                            <BuiltinActionTemplateList templates={this.state.templates} onPostSelectionUrlRequested={(template) => {
                    const actionType = typeof template === "object" ? template.Type : template;
                    return links.createStepTemplatePage.generateUrl({ spaceId: this.props.spaceId, actionType });
                }} filter={this.state.filterText}/>
                        </>)}
                </PaperLayout>
            </LibraryLayout>);
    }
    static displayName = "BuiltinTemplatesPage";
}
