/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import type { PaperLayoutProps } from "~/components/PaperLayout";
import SidebarLayout from "~/components/SidebarLayout/SidebarLayout";
import { ProjectPageLayout } from "../../ProjectPageLayout";
export type TabbedPaperLayoutProps = PaperLayoutProps & {
    tabNavigation?: React.ComponentType<any>;
    hideTabNavigation?: boolean;
    sidebar?: React.ReactNode;
};
export const TabbedPaperLayout: React.FC<TabbedPaperLayoutProps> = (props) => {
    const { tabNavigation: TabNavigationComponent, hideTabNavigation = false, disableAnimations = true, children, ...rest } = props;
    return (<ProjectPageLayout disableAnimations={disableAnimations} {...rest}>
            {TabNavigationComponent && !hideTabNavigation && <TabNavigationComponent />}
            <SidebarLayout sideBar={props.sidebar} hideTopDivider={true}>
                {children}
            </SidebarLayout>
        </ProjectPageLayout>);
};
TabbedPaperLayout.displayName = "TabbedPaperLayout"
TabbedPaperLayout.defaultProps = {
    fullWidth: true,
};
export default TabbedPaperLayout;
