/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Tooltip, Callout } from "@octopusdeploy/design-system-components";
import type { ResourceCollection, FeedResource, PackageVersionResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import DeleteDialog from "~/components/Dialog/DeleteDialog";
import InternalLink from "~/components/Navigation/InternalLink";
import type { PageAction } from "~/components/PageActions/PageActions";
import PagingDataTable from "~/components/PagingDataTable";
import PaperLayout from "~/components/PaperLayout";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import SidebarLayout from "~/components/SidebarLayout/SidebarLayout";
import { Text } from "~/components/form";
import ByteSizeFormatter from "~/utils/ByteSizeFormatter";
import DateFormatter from "~/utils/DateFormatter";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import { LibraryLayout } from "../LibraryLayout/LibraryLayout";
import styles from "./style.module.less";
class PackageItemDataTable extends PagingDataTable<PackageVersionResource> {
}
interface PackageVersionsListPageProps {
    spaceId: string;
    packageId: string;
}
interface PackageVersionsListPageState extends DataBaseComponentState {
    packagesResponse?: ResourceCollection<PackageVersionResource>;
    packagesToDelete: string[];
    feed?: FeedResource;
    isSure: boolean;
    confirmationInputValue: string;
}
export class PackageVersionsListPage extends DataBaseComponent<PackageVersionsListPageProps, PackageVersionsListPageState> {
    private packageId: string;
    private isFeedAdmin = isAllowed({ permission: Permission.BuiltInFeedAdminister, project: "*" });
    constructor(props: PackageVersionsListPageProps) {
        super(props);
        this.packageId = this.props.packageId;
        this.state = {
            packagesToDelete: [],
            isSure: false,
            confirmationInputValue: "",
        };
    }
    async componentDidMount() {
        await this.doBusyTask(() => this.load(), { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    async load() {
        const feed = await repository.Feeds.getBuiltIn();
        const packagesResponse = await repository.Feeds.searchPackageVersions(feed, this.packageId, { take: 30 });
        this.setState({ packagesResponse, feed });
    }
    async delete() {
        await this.doBusyTask(async () => {
            await repository.Packages.deleteMany(this.state.packagesToDelete);
            await this.load();
        });
        return true;
    }
    render() {
        if (this.state.packagesResponse && this.state.packagesResponse.Items.length === 0) {
            return <InternalRedirect to={links.builtInRepositoryPage.generateUrl({ spaceId: this.props.spaceId })}/>;
        }
        const sideBar = <p>If any projects depend on these packages, they will not be able to be deployed should you delete required versions from the repository.</p>;
        const table = this.state.packagesResponse && this.state.packagesResponse.Items.length > 0 ? (<PackageItemDataTable initialData={this.state.packagesResponse} onRow={this.buildRow} apiSearchParams={["filter"]} filterSearchEnabled={true} onFilter={this.filter} headerColumns={["Version", "Published", "Size"]} onEmpty={this.handleOnEmpty} rowColumnClassName={styles.packageVersionCell} onItemsChecked={this.isFeedAdmin ? (packagesToDelete: string[]) => this.setState({ packagesToDelete }) : null!}/>) : null;
        return (<LibraryLayout spaceId={this.props.spaceId}>
                <PaperLayout title={this.packageId} breadcrumbTitle={"Packages"} breadcrumbPath={links.builtInRepositoryPage.generateUrl({ spaceId: this.props.spaceId })} pageActions={[this.deletePageAction()]} busy={this.state.busy} errors={this.errors}>
                    <SidebarLayout sideBar={sideBar}>{table}</SidebarLayout>
                </PaperLayout>
            </LibraryLayout>);
    }
    private filter(filter: string, resource: PackageVersionResource) {
        return (!filter ||
            filter.length === 0 ||
            (resource.PackageId ? resource.PackageId.toLowerCase().includes(filter.toLowerCase()) : false) ||
            (resource.Title ? resource.Title.toLowerCase().includes(filter.toLowerCase()) : false) ||
            (resource.Version ? resource.Version.toLowerCase().includes(filter.toLowerCase()) : false));
    }
    private handleOnEmpty = () => {
        return <div>No packages found</div>;
    };
    private deletePageAction = (): PageAction => {
        const multipleSelected = this.state.packagesToDelete.length > 1;
        const selectedPackageCount = this.state.packagesToDelete.length;
        const message = `Are you sure you want to delete ${multipleSelected ? `these ${selectedPackageCount}` : "this"} package${multipleSelected ? "s" : ""}?`;
        const isSure = this.state.isSure;
        return {
            type: "dialog",
            buttonType: "destructive",
            hasPermissions: isAllowed({ permission: Permission.BuiltInFeedAdminister, project: "*" }),
            label: "Delete selected",
            disabled: !this.state.packagesResponse || selectedPackageCount === 0,
            renderDialog: ({ isOpen, closeDialog }) => (<DeleteDialog deleteButtonDisabled={!isSure} title={message} open={isOpen} onClose={() => {
                    closeDialog();
                    this.setState({ confirmationInputValue: "", isSure: false });
                }} onDeleteClick={() => this.delete()} renderContent={() => (<>
                            <Callout title="This is a destructive action" type={"danger"}>
                                This action <strong>cannot</strong> be undone. This will permanently delete files from disk and break releases relying on {multipleSelected ? "these" : "this"} package{multipleSelected ? "s" : ""}.
                            </Callout>
                            <div>
                                <p>
                                    Please type in <strong>Delete</strong> to confirm.
                                </p>
                                <Text value={this.state.confirmationInputValue} onChange={this.confirmationOnChange}/>
                            </div>
                        </>)}/>),
        };
    };
    private confirmationOnChange = (value: string) => {
        let isSure = false;
        if (value === "Delete") {
            isSure = true;
        }
        this.setState({ confirmationInputValue: value, isSure });
    };
    private buildRow = (pkg: PackageVersionResource) => {
        return [
            <div className={styles.row}>
                <PermissionCheck permission={Permission.BuiltInFeedDownload} project="*">
                    <a href={pkg.Links["Raw"]}>
                        <em className="fa-solid fa-download"/>
                    </a>
                    &nbsp;
                </PermissionCheck>
                <InternalLink to={links.packageDetailsPage.generateUrl({ spaceId: this.props.spaceId, id: pkg.Id })}>{pkg.Version}</InternalLink>
            </div>,
            <Tooltip content={DateFormatter.dateToLongFormat(pkg.Published)!}>{DateFormatter.dateToShortFormat(pkg.Published)}</Tooltip>,
            ByteSizeFormatter(pkg.SizeBytes!),
        ].filter((c) => !!c);
    };
    static displayName = "PackageVersionsListPage";
}
