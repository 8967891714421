import { Callout } from "@octopusdeploy/design-system-components";
import type { NewEndpointResource, CloudConnectionType } from "@octopusdeploy/octopus-server-client";
import { EndpointsHelper } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useState } from "react";
import { IsStepPackageEndpoint } from "~/areas/infrastructure/components/BaseMachineSettings/IsStepPackageEndpoint";
import endpointRegistry, { getEndpointRegistrationKey } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import useLocalStorage from "~/hooks/useLocalStorage";
type TargetDiscoveryCalloutProps = {
    endpoint: NewEndpointResource;
    doBusyTask: DoBusyTask;
    isNew: boolean;
};
export const TargetDiscoveryCallout: React.FC<TargetDiscoveryCalloutProps> = ({ endpoint, doBusyTask, isNew }) => {
    const storageKey = IsStepPackageEndpoint(endpoint) ? endpoint.DeploymentTargetTypeId : endpoint.CommunicationStyle;
    const [dismissed, setDismissed] = useLocalStorage(`Octopus.Callout.TargetDiscovery.${storageKey}.Dismissed`, false);
    const [cloudConnectionTypes, setCloudConnectionTypes] = useState<Array<CloudConnectionType>>([]);
    const [label, setLabel] = useState<string>("");
    const onCloseCallout = () => {
        setDismissed(true);
    };
    useDoBusyTaskEffect(doBusyTask, async () => {
        if (IsStepPackageEndpoint(endpoint)) {
            const stepPackage = await endpointRegistry.getStepPackageDeploymentTarget(endpoint.DeploymentTargetTypeId, endpoint.StepPackageVersion);
            setCloudConnectionTypes(stepPackage.targetDiscoveryCloudConnectionTypes);
            setLabel(stepPackage.name);
        }
        else {
            const key = getEndpointRegistrationKey(endpoint.CommunicationStyle);
            const registration = endpointRegistry.getEndpoint(key);
            if (endpointRegistry.isBuiltInEndpoint(registration) && registration.targetDiscoveryCloudConnectionTypes) {
                setCloudConnectionTypes(registration.targetDiscoveryCloudConnectionTypes());
                setLabel(registration.customTargetDiscoveryLabel ?? EndpointsHelper.getFriendlyName(endpoint.CommunicationStyle));
            }
        }
    }, [doBusyTask, endpoint]);
    if (isNew && !dismissed && cloudConnectionTypes.length > 0) {
        return (<Callout title={"Discover your targets at deployment time"} type={"information"} canClose={true} onClose={onCloseCallout}>
                Octopus can discover {label} targets during deployments using tags added to your cloud resources.
                <br />
                <ExternalLink href="CloudTargetDiscovery">Learn more about cloud target discovery</ExternalLink>
            </Callout>);
    }
    return null;
};
TargetDiscoveryCallout.displayName = "TargetDiscoveryCallout"
