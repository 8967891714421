/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { Callout } from "@octopusdeploy/design-system-components";
import { borderRadius, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import { logger } from "@octopusdeploy/logging";
import type { ChannelResource, DeploymentActionResource, DeploymentProcessResource, EnvironmentResource, GitRefResource, LifecycleResource, NewTriggerResourceTyped, ProjectResource, ReleaseResource, ResourceCollection, ResourcesById, ScopedDeploymentActionResource, TriggerResource, TriggerResourceTyped, } from "@octopusdeploy/octopus-server-client";
import { CreateReleaseActionResource, toDeploymentActionSlugPackage, toDeploymentActionPackage, FeedType, isExistingTriggerResource, Permission, TriggerActionCategory, TriggerActionType, TriggerFeedFilterResource, TriggerFilterType, } from "@octopusdeploy/octopus-server-client";
import type { TriggerExecutionResource } from "@octopusdeploy/octopus-server-client/src/resources/triggerExecutionResource";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import { ProjectFormPageLayout } from "app/areas/projects/components/ProjectFormPageLayout";
import { cloneDeep } from "lodash";
import pluralize from "pluralize";
import * as React from "react";
import LifecycleMap from "~/areas/library/components/Lifecycle/LifecycleMap";
import { FeedTriggerFeedbackCallout } from "~/areas/projects/components/Triggers/Feed/FeedTriggerFeedbackCallout";
import { useProjectContext } from "~/areas/projects/context/index";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/withProjectContext";
import { repository } from "~/clientInstance";
import type { Refresh } from "~/components/DataBaseComponent/DataBaseComponent";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import { FormBaseComponent } from "~/components/FormBaseComponent";
import type { DeploymentActionPackageTableSelectItem } from "~/components/MultiSelect/DeploymentActionPackageTableMultiSelect";
import { buildItemList, createDeploymentActionPackageTableItemId, DeploymentActionPackageTableMultiSelect } from "~/components/MultiSelect/DeploymentActionPackageTableMultiSelect";
import { ExternalLinkIcon } from "~/components/Navigation/ExternalLink/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { PageAction } from "~/components/PageActions/PageActions";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { ExpandableFormSection, ExpansionButtons, FormSectionHeading, MarkdownEditor, Note, required, Select, Summary, Text, UnstructuredFormSection } from "~/components/form";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import StringHelper from "~/utils/StringHelper/StringHelper";
import toResourceLookup from "~/utils/toResourceLookup";
import { LastTriggerExecutionSummary } from "./LastExecutionSummary";
import { LastReleaseCreatedSummary } from "./LastReleaseCreatedSummary";
// We currently support only helm chart and container image feeds, but this can be expanded in the future
const supportedFeedTypes = [FeedType.AwsElasticContainerRegistry, FeedType.AzureContainerRegistry, FeedType.Docker, FeedType.GoogleContainerRegistry, FeedType.Helm, FeedType.OciRegistry];
type Model = TriggerResourceTyped<TriggerFeedFilterResource, CreateReleaseActionResource> | NewTriggerResourceTyped<TriggerFeedFilterResource, CreateReleaseActionResource>;
interface EditState extends OptionalFormBaseComponentState<Model> {
    channels: ChannelResource[];
    project: ProjectResource;
    redirectTo?: LinkHref;
    filterType: TriggerFilterType;
    supportedFeedIds: string[];
    deploymentProcess: DeploymentProcessResource;
    channelLookup: ResourcesById<ChannelResource>;
    lifecycleLookup: ResourcesById<LifecycleResource>;
    environmentLookup: ResourcesById<EnvironmentResource>;
    projectTriggerLastExecution: TriggerExecutionResource | "never-run" | undefined;
    lastCreatedRelease: ReleaseResource | undefined;
    disabledChannelIds: string[];
}
export type EditReleaseTriggersInternalProps = EditReleaseCreationFeedTriggerProps & WithProjectContextInjectedProps;
class EditReleaseTriggersInternalPage extends FormBaseComponent<EditReleaseTriggersInternalProps, EditState, Model> {
    public static defaultProps: Partial<EditReleaseTriggersInternalProps> = {
        triggerActionCategory: TriggerActionCategory.Deployment,
    };
    constructor(props: EditReleaseTriggersInternalProps) {
        super(props);
        this.state = {
            model: null!,
            cleanModel: null!,
            channels: [],
            project: null!,
            filterType: null!,
            supportedFeedIds: [],
            deploymentProcess: null!,
            channelLookup: null!,
            lifecycleLookup: null!,
            environmentLookup: null!,
            projectTriggerLastExecution: undefined,
            lastCreatedRelease: undefined,
            disabledChannelIds: [],
        };
    }
    private refreshCount: number = 0;
    private doRefresh: Refresh = () => Promise.resolve();
    async componentDidMount() {
        let newTrigger: NewTriggerResourceTyped<TriggerFeedFilterResource, CreateReleaseActionResource> = null!;
        const { model: project, gitRef } = this.props.projectContext.state;
        if (this.props.create) {
            const triggerFeedResource = new TriggerFeedFilterResource([]);
            const triggerActionResource = new CreateReleaseActionResource();
            newTrigger = {
                ProjectId: project.Id,
                Name: null!,
                Description: null!,
                IsDisabled: false,
                Filter: triggerFeedResource,
                Action: triggerActionResource,
            };
        }
        await this.doBusyTask(async () => {
            const { projectContextRepository } = this.props.projectContext.state;
            let deploymentProcess: DeploymentProcessResource = null!;
            let channels: ChannelResource[] = [];
            let projectTriggers: ResourceCollection<TriggerResource> | undefined = undefined;
            let disabledChannelIds: string[] = [];
            let channelLookup: ResourcesById<ChannelResource> = {};
            let lifecyclePreviewLookup: ResourcesById<LifecycleResource> = {};
            let environmentLookup: ResourcesById<EnvironmentResource> = {};
            let projectTriggerLastExecution: TriggerExecutionResource | "never-run" | undefined = undefined;
            let lastCreatedRelease: ReleaseResource | undefined = undefined;
            const trigger: TriggerResource | undefined = isEditingExistingReleaseTrigger(this.props) ? await repository.ProjectTriggers.get(this.props.triggerId) : undefined;
            const getChannelIdsThatAreReferencedByOtherTriggers = (triggers: TriggerResource[], thisTrigger?: TriggerResource) => triggers
                .filter((trigger) => trigger.Filter.FilterType === TriggerFilterType.FeedFilter && trigger.Action.ActionType === TriggerActionType.CreateRelease && (!thisTrigger || trigger.Id !== thisTrigger.Id))
                .map((trigger) => (trigger.Action as CreateReleaseActionResource).ChannelId);
            if (isAllowed({ permission: Permission.ProcessView, project: project.Id, tenant: "*" })) {
                // We grab the deployment process for the default branch only as that is all that external feed
                // and built-in package triggers support right now. This will need to be updated if/when it is extended.
                const deploymentProcessTask = projectContextRepository.DeploymentProcesses.getForDefaultBranch();
                const requiredTasks = Promise.all([deploymentProcessTask, this.loadChannels(project), this.loadProjectTriggers(project, gitRef)]);
                [deploymentProcess, channels, projectTriggers] = await requiredTasks;
                if (projectTriggers) {
                    disabledChannelIds = getChannelIdsThatAreReferencedByOtherTriggers(projectTriggers.Items, trigger);
                }
                try {
                    const optionalTasks = Promise.all([this.loadLifecyclePreviews(channels, project), this.loadEnvironments()]);
                    [lifecyclePreviewLookup, environmentLookup] = await optionalTasks;
                }
                catch {
                    // Lifecycle previews are not required but we want to know if they fail
                    logger.error("Failed to load lifecycle previews");
                }
                if (trigger) {
                    const lastExecution = await this.loadLastExecution(trigger);
                    projectTriggerLastExecution = lastExecution.projectTriggerLastExecution;
                    lastCreatedRelease = lastExecution.lastCreatedRelease;
                }
                channelLookup = toResourceLookup(channels);
                if (!trigger) {
                    newTrigger.Action.ChannelId = channels.map((channel) => channel.Id).find((channelId) => !disabledChannelIds.includes(channelId)) ?? "";
                }
            }
            const includedFeeds = await repository.Feeds.list({ feedType: supportedFeedTypes });
            this.setState({
                model: newTrigger || trigger,
                channels,
                project,
                cleanModel: cloneDeep(newTrigger || trigger),
                filterType: trigger?.Filter.FilterType ?? TriggerFilterType.FeedFilter,
                deploymentProcess,
                channelLookup,
                lifecycleLookup: lifecyclePreviewLookup,
                environmentLookup,
                supportedFeedIds: includedFeeds.Items.map((feed) => feed.Id),
                projectTriggerLastExecution,
                lastCreatedRelease,
                disabledChannelIds,
            });
            this.doRefresh = await this.startRefreshLoop(async () => {
                const trigger = this.state.model as TriggerResource;
                if (trigger) {
                    const lastTriggerExecution = await this.loadLastExecution(trigger);
                    projectTriggerLastExecution = lastTriggerExecution.projectTriggerLastExecution;
                    lastCreatedRelease = lastTriggerExecution.lastCreatedRelease;
                    this.setState({
                        projectTriggerLastExecution: lastTriggerExecution.projectTriggerLastExecution,
                        lastCreatedRelease: lastTriggerExecution.lastCreatedRelease,
                    });
                }
                this.refreshCount++;
                return this.state;
            }, this.getRefreshInterval, false, timeOperationOptions.forRefresh());
        });
    }
    getRefreshInterval = (_: boolean) => {
        // Refresh frequently for 15 minutes and then back off a bit
        if (this.refreshCount < 90) {
            return 10000;
        }
        return 60000;
    };
    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true}/>;
        }
        const title = this.props.create ? "New External Feed Trigger" : this.state.model ? this.state.model.Name : StringHelper.ellipsis;
        const overFlowActions = [];
        if (this.state.model && isExistingTriggerResource(this.state.model)) {
            const model: TriggerResource = this.state.model;
            overFlowActions.push(OverflowMenuItems.item(this.state.model && this.state.model.IsDisabled ? "Enable" : "Disable", () => (this.state.model && this.state.model.IsDisabled ? this.enableTrigger() : this.disableTrigger()), {
                permission: Permission.TriggerEdit,
                project: this.state.project && this.state.project.Id,
            }));
            overFlowActions.push(OverflowMenuItems.deleteItemDefault("Release trigger", () => this.deleteTrigger(model), { permission: Permission.TriggerDelete, project: this.state.project && this.state.project.Id }));
            overFlowActions.push([
                OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ regardingAny: [this.state.model.Id] }), {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ]);
        }
        const saveText: string = this.props.create ? "Release trigger created" : "Release trigger details updated";
        const breadcrumbTitle = "Triggers";
        const breadcrumbPath = links.deploymentTriggersPage.generateUrl({ spaceId: this.props.spaceId, projectSlug: this.props.projectSlug });
        const pageActions: PageAction[] = [];
        const lifecycle = this.getLifecycleOrDefault(this.selectedChannelId(), this.state.project);
        const packageSelectItems = this.state.deploymentProcess ? buildItemList(this.state.deploymentProcess, this.state.supportedFeedIds) : [];
        const allChannelAlreadyUsed = this.state.channels.length === this.state.disabledChannelIds.length;
        return (<ProjectFormPageLayout busy={this.state.busy} errors={this.errors} title={title} breadcrumbTitle={breadcrumbTitle} breadcrumbPath={breadcrumbPath} breadcrumbsItems={[{ label: breadcrumbTitle, pageUrl: breadcrumbPath }]} model={this.state.model} cleanModel={this.state.cleanModel} savePermission={{
                permission: this.props.create ? Permission.TriggerCreate : Permission.TriggerEdit,
                project: this.state.project && this.state.project.Id,
            }} onSaveClick={() => this.saveTrigger()} overFlowActions={overFlowActions} hideExpandAll={true} saveText={saveText} pageActions={pageActions}>
                {this.state.model && (<TransitionAnimation>
                        <ExpansionButtons errors={this.errors?.fieldErrors} expandAllOnMount={this.props.create}/>
                        {this.state.cleanModel && this.state.cleanModel.IsDisabled && (<UnstructuredFormSection stretchContent={true}>
                                <Callout type={"warning"} title={"This trigger is currently disabled"}/>
                            </UnstructuredFormSection>)}
                        <FeedTriggerFeedbackCallout />
                        <ExpandableFormSection errorKey="Name" title="Name" focusOnExpandAll summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your release trigger")} help="Enter a name for your release trigger.">
                            <Text value={this.state.model.Name || ""} onChange={(Name) => this.setModelState({ Name })} label="Release trigger name" validate={required("Please enter a release trigger name")} error={this.getFieldError("Name")} autoFocus={true}/>
                            <Note>
                                A short, memorable, unique name for this release trigger. Example: <i>Watch for new versions of our docker image</i>
                            </Note>
                        </ExpandableFormSection>
                        <ExpandableFormSection errorKey="Description" title="Description" summary={this.state.model.Description ? Summary.summary(this.state.model.Description) : Summary.placeholder("Please enter a description for your trigger")} help="Enter a description for your trigger.">
                            <MarkdownEditor label="Trigger description" value={this.state.model.Description} onChange={(Description) => this.setModelState({ Description })}/>
                        </ExpandableFormSection>
                        <FormSectionHeading title="Trigger Action"/>
                        {this.props.projectContext.state.model.IsVersionControlled && (<Callout type={"generic"} hideTitle>
                                External feed triggers create releases on the default branch in version controlled projects.
                            </Callout>)}
                        <ExpandableFormSection errorKey="Channel" title="Channel" focusOnExpandAll summary={this.buildChannelSummary()} help={"Select the channel to use when selecting packages for the release"}>
                            <Select allowClear={false} items={this.state.channels.map((c) => ({ text: c.Name, value: c.Id, disabled: this.state.disabledChannelIds.includes(c.Id), getDisabledText: (text) => `${text} - Already in use` }))} value={this.selectedChannelId()} onChange={async (channelId) => await this.onChannelChange({ ChannelId: channelId! })} error={allChannelAlreadyUsed ? "No available channels" : undefined}/>
                            <Note>Releases will use this channel and the pushed package must satisfy the selected channel's versioning rules.</Note>
                            {lifecycle && <TriggerLifecyclePreview lifecycle={lifecycle} environmentLookup={this.state.environmentLookup} spaceId={this.props.spaceId}/>}
                        </ExpandableFormSection>
                        {packageSelectItems && (<ExpandableFormSection errorKey="Trigger sources" title="Trigger sources" focusOnExpandAll summary={this.getTriggerSourcesSummary(packageSelectItems)} help="Select the packages that will be used to trigger release creation.">
                                <DeploymentActionPackageTableMultiSelect items={packageSelectItems} value={this.state.model.Filter.Packages.map((pkg) => createDeploymentActionPackageTableItemId(toDeploymentActionPackage(pkg, this.state.deploymentProcess)))} onChange={(itemIds) => {
                        const packages = packageSelectItems
                            .map((item) => ({ DeploymentAction: item.deploymentActionName, PackageReference: item.packageReference }))
                            .filter((pkg) => itemIds.includes(createDeploymentActionPackageTableItemId(pkg)));
                        this.setModelState({ Filter: new TriggerFeedFilterResource(packages.map((pkg) => toDeploymentActionSlugPackage(pkg, this.state.deploymentProcess))) });
                    }} rowDisabled={(item) => item.deploymentActionResource.IsDisabled || !this.actionIsEnabledForSelectedChannel(item.deploymentActionResource)} disabledMessage={(item) => this.stepDisabledMessage(item.deploymentActionResource)}/>
                                <Note>
                                    New versions of <strong>any</strong> of the container images or Helm Charts you select will trigger release creation.
                                </Note>
                            </ExpandableFormSection>)}
                        {this.state.projectTriggerLastExecution && <FormSectionHeading title="History"/>}
                        {this.state.projectTriggerLastExecution && <LastTriggerExecutionSummary lastExecution={this.state.projectTriggerLastExecution}/>}
                        {this.state.projectTriggerLastExecution && <LastReleaseCreatedSummary spaceId={this.props.projectContext.state.model.SpaceId} projectSlug={this.props.projectSlug} release={this.state.lastCreatedRelease}/>}
                    </TransitionAnimation>)}
            </ProjectFormPageLayout>);
    }
    private async loadLastExecution(projectTrigger: TriggerResource) {
        let projectTriggerLastExecution: TriggerExecutionResource | "never-run" | undefined = undefined;
        let lastCreatedRelease: ReleaseResource | undefined = undefined;
        try {
            projectTriggerLastExecution = await repository.ProjectTriggers.getLastExecution(projectTrigger);
            if (projectTriggerLastExecution?.LastCreatedReleaseId) {
                lastCreatedRelease = await this.loadRelease(projectTriggerLastExecution?.LastCreatedReleaseId);
            }
        }
        catch (error) {
            if (error.StatusCode === 404 && !this.props.create) {
                projectTriggerLastExecution = "never-run";
            }
            else {
                logger.error("Failed to load trigger history");
            }
        }
        return {
            lastCreatedRelease,
            projectTriggerLastExecution,
        };
    }
    private async loadRelease(releaseId: string): Promise<ReleaseResource> {
        return await repository.Releases.get(releaseId);
    }
    private async loadChannels(project: ProjectResource): Promise<ChannelResource[]> {
        const resourceCollection = await repository.Projects.getChannels(project);
        return resourceCollection.Items;
    }
    private async loadLifecyclePreviews(channels: ChannelResource[], project: ProjectResource): Promise<ResourcesById<LifecycleResource>> {
        const allLifecycleIds = channels.map((channel) => channel.LifecycleId ?? project?.LifecycleId);
        const lifecycles = await repository.Lifecycles.previews([...new Set(allLifecycleIds)]);
        return toResourceLookup(lifecycles);
    }
    private async loadEnvironments(): Promise<ResourcesById<EnvironmentResource>> {
        return await repository.Environments.allById();
    }
    private async loadProjectTriggers(project: ProjectResource, gitRef: Readonly<GitRefResource> | undefined): Promise<ResourceCollection<TriggerResource>> {
        return await repository.Projects.getTriggers(project, gitRef, 0, 30, TriggerActionType.CreateRelease);
    }
    private getLifecycleOrDefault(channelId: string, project: ProjectResource) {
        if (!channelId || !this.state.lifecycleLookup || !this.state.environmentLookup)
            return undefined;
        const channel = this.state.channelLookup[channelId];
        const defaultLifecycle = this.state.lifecycleLookup[project.LifecycleId];
        if (!channel.LifecycleId) {
            return defaultLifecycle;
        }
        return this.state.lifecycleLookup[channel.LifecycleId] || defaultLifecycle;
    }
    private getTriggerSourcesSummary(packageSelectItems: DeploymentActionPackageTableSelectItem[]) {
        const allSelectedPackageNames = this.state.model?.Filter.Packages.map((pkg) => createDeploymentActionPackageTableItemId(toDeploymentActionPackage(pkg, this.state.deploymentProcess))) ?? [];
        const selectedDeploymentActionResources = packageSelectItems
            .filter((selectItem) => allSelectedPackageNames.includes(createDeploymentActionPackageTableItemId({
            DeploymentAction: selectItem.deploymentActionName,
            PackageReference: selectItem.packageReference,
        })))
            .map((x) => x.deploymentActionResource);
        const count = selectedDeploymentActionResources.filter((action) => this.actionIsEnabledForSelectedChannel(action)).length;
        return Summary.summary(`${pluralize("package", count, true)} will be watched for updates`);
    }
    private actionIsEnabledForSelectedChannel = (action: DeploymentActionResource) => action.Channels.length === 0 || action.Channels.includes(this.selectedChannelId());
    private stepDisabledMessage = (action: DeploymentActionResource) => (action.IsDisabled ? "This step is disabled" : `This step is disabled for the ${this.selectedChannelName()} channel`);
    private selectedChannelId = () => (this.state.model?.Action as CreateReleaseActionResource)?.ChannelId;
    private selectedChannelName = () => {
        const channelId = this.selectedChannelId();
        const channel = this.state.channelLookup[channelId];
        if (channel) {
            return channel.Name;
        }
        return channelId;
    };
    private buildChannelSummary = () => Summary.summary(<span>
                Latest packages in channel <strong>{this.selectedChannelName()}</strong> will be used to create the release
            </span>);
    private onChannelChange = async <K extends keyof ScopedDeploymentActionResource>(state: Pick<ScopedDeploymentActionResource, K>, callback?: () => void) => {
        this.setChildState2("model", "Action", state, callback);
    };
    private async saveTrigger() {
        await this.doBusyTask(async () => {
            const result = (await repository.ProjectTriggers.save(this.state.model!)) as TriggerResourceTyped<TriggerFeedFilterResource, ScopedDeploymentActionResource>;
            if (this.props.create) {
                const redirectTo = links.editReleaseCreationFeedTriggerPage.generateUrl({ spaceId: result.SpaceId, projectSlug: this.props.projectSlug, triggerId: result.Id });
                this.setState({ redirectTo });
            }
            else {
                this.setState({
                    model: result,
                    cleanModel: cloneDeep(result),
                });
            }
        });
    }
    private async enableTrigger() {
        this.setChildState1("model", { IsDisabled: false }, () => this.saveTrigger());
    }
    private async disableTrigger() {
        this.setChildState1("model", { IsDisabled: true }, () => this.saveTrigger());
    }
    private async deleteTrigger(model: TriggerResource) {
        await this.doBusyTask(async () => {
            await repository.ProjectTriggers.del(model);
            const redirectTo = links.deploymentTriggersPage.generateUrl({ spaceId: this.state.project.SpaceId, projectSlug: this.state.project.Slug });
            this.setState({ redirectTo });
        });
        return true;
    }
    static displayName = "EditReleaseTriggersInternalPage";
}
interface SharedProps {
    spaceId: string;
    projectSlug: string;
    triggerActionCategory: TriggerActionCategory.Deployment;
}
interface EditProps extends SharedProps {
    create: false;
    triggerId: string;
}
interface CreateProps extends SharedProps {
    create: true;
}
type EditReleaseCreationFeedTriggerProps = EditProps | CreateProps;
interface TriggerLifecyclePreviewProps {
    lifecycle: LifecycleResource;
    environmentLookup: ResourcesById<EnvironmentResource>;
    spaceId: string;
}
function TriggerLifecyclePreview({ lifecycle, environmentLookup, spaceId }: TriggerLifecyclePreviewProps) {
    return (<div className={styles.container}>
            <div className={styles.box}>
                <div className={styles.content}>
                    <div className={styles.heading}>Lifecycle preview</div>
                    <div>Here’s how releases created by this trigger will be deployed:</div>
                </div>
                <LifecycleMap lifecyclePreview={lifecycle} environmentsById={environmentLookup}/>
            </div>
            <InternalLink size={0.875} to={links.editLifecyclePage.generateUrl({ spaceId, lifecycleId: lifecycle.Id })} openInSelf={false}>
                Edit lifecycle <ExternalLinkIcon />
            </InternalLink>
        </div>);
}
const styles = {
    container: css({ display: "flex", flexDirection: "column", gap: space[8], marginTop: space[16] }),
    content: css({ display: "flex", flexDirection: "column", gap: space[8] }),
    box: css({ borderColor: themeTokens.color.border.primary, borderWidth: space[1], borderStyle: "solid", borderRadius: borderRadius.small, padding: space[16], paddingBottom: 0 }),
    heading: css({ font: text.interface.body.bold.base }),
};
export function EditReleaseCreationFeedTrigger(props: EditReleaseCreationFeedTriggerProps) {
    const projectContext = useProjectContext();
    return <EditReleaseTriggersInternalPage projectContext={projectContext} {...props}/>;
}
function isEditingExistingReleaseTrigger(props: EditReleaseCreationFeedTriggerProps): props is EditProps {
    return !props.create;
}
export default EditReleaseCreationFeedTrigger;
