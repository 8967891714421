import type { DeploymentFreezeFilterQueryParameters, DeploymentFreezeOverviewBffResponse, DeploymentFreezeSummary, PagingCollection } from "@octopusdeploy/octopus-server-client";
import { DeploymentFreezeStatus, Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { DeploymentFreezeTableContent } from "~/areas/configuration/components/DeploymentFreezes/DataTable/DeploymentFreezeTableContent";
import { DeploymentFreezeOnboardingPage } from "~/areas/configuration/components/DeploymentFreezes/DeploymentFreezeOnboardingPage";
import { repository } from "~/clientInstance";
import type { FilterSection } from "~/components/AdvancedFilterLayout/index";
import AdvancedFilterLayout from "~/components/AdvancedFilterLayout/index";
import type { DataBaseComponentState, DoBusyTask } from "~/components/DataBaseComponent/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import type { DeploymentFreezeStatusResource } from "~/components/MultiSelect/DeploymentFreezeStatusMultiSelect";
import { DeploymentFreezeStatusMultiSelect } from "~/components/MultiSelect/DeploymentFreezeStatusMultiSelect";
import { EnvironmentMultiSelect } from "~/components/MultiSelect/EnvironmentMultiSelect";
import { ProjectMultiSelect } from "~/components/MultiSelect/ProjectMultiSelect";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { PrimaryPageAction } from "~/components/PageActions/PageActions";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import type { IQuery } from "~/components/QueryStringFilters/QueryStringFilters";
import { QueryStringFilters } from "~/components/QueryStringFilters/QueryStringFilters";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { arrayValueFromQueryString } from "~/utils/ParseHelper/ParseHelper";
import RequestRaceConditioner from "~/utils/RequestRaceConditioner/index";
interface DeploymentFreezeListProps {
    initialData: PagingCollection<DeploymentFreezeSummary>;
}
interface DeploymentFreezesListState extends DataBaseComponentState, DeploymentFreezeOverviewBffResponse {
    QueryFilter?: DeploymentFreezeFilter;
    Query: DeploymentFreezeQuery;
    Filter: DeploymentFreezeFilter;
    PageNumber: number;
    PageSize: number;
}
export interface DeploymentFreezeQuery extends IQuery {
    filterByEnvironments?: string[];
    filterByProjects?: string[];
    filterByName?: string;
    filterByStatus?: string[];
    pageNumber: string;
    pageSize: string;
}
class FilterLayout extends AdvancedFilterLayout<DeploymentFreezeFilter> {
}
export interface DeploymentFreezeFilter {
    Name: string | undefined;
    Environments: string[];
    Projects: string[];
    Status: DeploymentFreezeStatus[];
}
const DeploymentFreezeDefaultFilter = {
    Environments: [],
    Projects: [],
    Name: "",
    Status: [],
};
export interface DeploymentFreezeFilterLayoutProps {
    doBusyTask: DoBusyTask;
    queryFilter: DeploymentFreezeFilter | undefined;
    deploymentFreezes: DeploymentFreezeOverviewBffResponse;
}
const initialFilterData = {
    Environments: [],
    Projects: [],
    Status: [],
    Name: "",
};
export const DeploymentFreezesListPage = () => <DeploymentFreezesListPageInner initialData={{ Items: [], ItemsPerPage: 0, TotalResults: 0 }}/>;
export interface DeploymentFreezeQuery extends IQuery {
    environmentIds?: string[];
    projectIds?: string[];
    name?: string;
    status?: string[];
    pageNumber: string;
    pageSize: string;
}
interface DeploymentFreezeQueryStringFilterProps {
    filterSelections: DeploymentFreezeFilter;
    query: DeploymentFreezeQuery;
    updateQueriesOnFilterChange: (filters: DeploymentFreezeFilter) => void;
}
export const DeploymentFreezeQueryStringFilters = QueryStringFilters.For<DeploymentFreezeFilter, DeploymentFreezeQuery>();
class DeploymentFreezesListPageInner extends DataBaseComponent<DeploymentFreezeListProps, DeploymentFreezesListState> {
    constructor(props: DeploymentFreezeListProps) {
        super(props);
        this.state = {
            Filters: initialFilterData,
            DeploymentFreezes: props.initialData.Items,
            TotalDeploymentFreezeCount: 0,
            FilteredDeploymentFreezeCount: 0,
            Filter: DeploymentFreezeDefaultFilter,
            PageNumber: 0,
            PageSize: 30,
            Query: { pageNumber: "0", pageSize: "30" },
        };
    }
    componentDidMount() {
        this.doBusyTask(async () => {
            await this.reload();
        });
    }
    private filterSections(): FilterSection[] {
        return [
            {
                render: (<div>
                        <EnvironmentMultiSelect accessibleName="environmentFilter" key="filterEnvironment" items={this.state.Filters.Environments} value={this.state.Filter.Environments} onChange={(Environments) => this.onFiltersChanged({ ...this.state.Filter, Environments })}/>
                        <ProjectMultiSelect accessibleName="projectFilter" key="filterProject" items={this.state.Filters.Projects} value={this.state.Filter.Projects} onChange={(Projects) => this.onFiltersChanged({ ...this.state.Filter, Projects })}/>
                        <DeploymentFreezeStatusMultiSelect accessibleName="freezeStatusFilter" key="filterFreezeStatus" items={this.getStatusMultiSelectItems()} value={this.state.Filter.Status} onChange={(Status) => this.onFiltersChanged({ ...this.state.Filter, Status })}/>
                    </div>),
            },
        ];
    }
    getStatusMultiSelectItems = (): DeploymentFreezeStatusResource[] => {
        return this.state.Filters.Status.map((s) => ({ Id: s, Name: s.toString() }));
    };
    requestRaceConditioner = new RequestRaceConditioner();
    onFiltersChanged = (filters: DeploymentFreezeFilter) => {
        this.setState({ Filter: filters }, async () => {
            await this.reload();
        });
    };
    onPaginationChanged = (index: number) => {
        this.setState({ ...this.state, PageNumber: index }, async () => {
            await this.reload();
        });
    };
    setQueries = (filters: DeploymentFreezeFilter) => {
        this.setState({
            ...this.state,
            Query: {
                filterByEnvironments: filters.Environments.length > 0 ? filters.Environments : undefined,
                filterByProjects: filters.Projects.length > 0 ? filters.Projects : undefined,
                filterByName: filters.Name,
                filterByStatus: filters.Status.length > 0 ? filters.Status : undefined,
                pageNumber: this.state.PageNumber.toString(),
                pageSize: this.state.PageSize.toString(),
            },
        });
    };
    reload = async () => {
        const filter = this.state.Filter;
        const queryParams: DeploymentFreezeFilterQueryParameters = {
            filterByEnvironments: filter.Environments,
            filterByName: filter.Name,
            filterByProjects: filter.Projects,
            filterByStatus: filter.Status,
            pageSize: this.state.PageSize.toString(),
            pageNumber: (this.state.PageNumber + 1).toString(),
        };
        await this.doBusyTask(async () => {
            const freezes = repository.DeploymentFreezes.getOverviewBff(queryParams);
            await this.requestRaceConditioner.avoidStaleResponsesForRequest(freezes, (response) => {
                this.setState({
                    DeploymentFreezes: response.DeploymentFreezes,
                    TotalDeploymentFreezeCount: response.TotalDeploymentFreezeCount,
                    FilteredDeploymentFreezeCount: response.FilteredDeploymentFreezeCount,
                    Filters: response.Filters,
                });
            });
        }, { timeOperationOptions: timeOperationOptions.forRefresh() });
    };
    convertToStatusEnum = (query: string | string[] | undefined): DeploymentFreezeStatus[] => {
        if (query === undefined)
            return [];
        if (typeof query === "string")
            return [this.convertStringToFreezeStatus(query)];
        return query.map((q) => this.convertStringToFreezeStatus(q));
    };
    convertStringToFreezeStatus = (q: string): DeploymentFreezeStatus => {
        switch (q) {
            case "Active":
                return DeploymentFreezeStatus.Active;
            case "Scheduled":
                return DeploymentFreezeStatus.Scheduled;
            default:
                return DeploymentFreezeStatus.Expired;
        }
    };
    getFilterFromQuery = (query: DeploymentFreezeQuery): DeploymentFreezeFilter => {
        return {
            Projects: arrayValueFromQueryString(query.filterByProjects),
            Environments: arrayValueFromQueryString(query.filterByEnvironments),
            Name: query.filterByName,
            Status: this.convertToStatusEnum(arrayValueFromQueryString(query.filterByStatus)),
        };
    };
    getQueryFromFilters = (filter: DeploymentFreezeFilter): DeploymentFreezeQuery => {
        return {
            filterByProjects: filter.Projects,
            filterByEnvironments: filter.Environments,
            filterByName: filter.Name,
            filterByStatus: filter.Status,
            pageNumber: this.state.PageNumber.toString(),
            pageSize: this.state.PageSize.toString(),
        };
    };
    render() {
        const AddDeploymentFreezeAction = (): PrimaryPageAction => {
            return {
                type: "navigate",
                label: "Add Deployment Freeze",
                hasPermissions: isAllowed({ permission: Permission.DeploymentFreezeAdminister }),
                path: links.deploymentFreezesCreatePage.generateUrl(),
            };
        };
        const overflowActions = [];
        if (this.state.DeploymentFreezes.length > 0)
            overflowActions.push(OverflowMenuItems.navItem("Audit trail", links.auditPage.generateUrl({ documentTypes: ["DeploymentFreezes"], includeSystem: true }), { permission: Permission.EventView }));
        const renderPage = () => {
            if (!this.state.busy && this.state.TotalDeploymentFreezeCount === 0)
                return <DeploymentFreezeOnboardingPage />;
            return (<>
                    <DeploymentFreezeQueryStringFilters filter={this.state.Filter} getFilter={this.getFilterFromQuery} onFilterChange={(filters) => {
                    this.setState({ ...this.state, QueryFilter: filters });
                    this.onFiltersChanged(filters);
                }} getQuery={(filter) => this.getQueryFromFilters(filter)}/>

                    <FilterLayout defaultFilter={DeploymentFreezeDefaultFilter} filterSections={this.filterSections()} filter={this.state.Filter} queryFilter={this.state.QueryFilter} onFilterReset={() => {
                    this.onFiltersChanged(DeploymentFreezeDefaultFilter);
                }} additionalHeaderFilters={[<FilterSearchBox placeholder={"Filter by name..."} value={this.state.Filter.Name} onChange={(Name) => this.onFiltersChanged({ ...this.state.Filter, Name })} autoFocus={true}/>]} renderContent={() => {
                    const freezeSummaryPagingCollection = {
                        Items: this.state.DeploymentFreezes,
                        ItemsPerPage: this.state.PageSize,
                        TotalResults: this.state.FilteredDeploymentFreezeCount ?? this.state.TotalDeploymentFreezeCount,
                    };
                    return <DeploymentFreezeTableContent deploymentFreezes={freezeSummaryPagingCollection} currentPage={this.state.PageNumber} onPageSelected={(currentIndex) => this.onPaginationChanged(currentIndex)}/>;
                }}/>
                </>);
        };
        return (<PaperLayout title={"Deployment Freezes"} busy={this.state.busy} errors={this.errors} primaryAction={AddDeploymentFreezeAction()} overflowActions={overflowActions}>
                {renderPage()}
            </PaperLayout>);
    }
    static displayName = "DeploymentFreezesListPageInner";
}
export default DeploymentFreezesListPage;
