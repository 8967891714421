import React from "react";
export interface DynamicDevToolsTabRegistration {
    name: string;
    children: React.ReactNode;
}
export type DrawerDockLocation = "left" | "right" | "bottom";
interface DevDrawerState {
    open: boolean;
    dockLocation: DrawerDockLocation;
}
type TabRegistrationState = Map<string, React.ReactNode>;
const DevToolsDrawerStateContext = React.createContext<DevDrawerState | null>(null);
const DevToolsSetDrawerStateContext = React.createContext<React.Dispatch<React.SetStateAction<DevDrawerState>> | null>(null);
const TabRegistrationContext = React.createContext<TabRegistrationState | null>(null);
const SetTabRegistrationStateContext = React.createContext<React.Dispatch<React.SetStateAction<TabRegistrationState>> | null>(null);
export const DevToolsContextProvider: React.FC = (props) => {
    const [drawerState, setDrawerState] = React.useState<DevDrawerState>({ open: false, dockLocation: "bottom" });
    const [tabState, setTabState] = React.useState<TabRegistrationState>(new Map<string, React.ReactNode>());
    return (<DevToolsSetDrawerStateContext.Provider value={setDrawerState}>
            <SetTabRegistrationStateContext.Provider value={setTabState}>
                <DevToolsDrawerStateContext.Provider value={drawerState}>
                    <TabRegistrationContext.Provider value={tabState}>{props.children}</TabRegistrationContext.Provider>
                </DevToolsDrawerStateContext.Provider>
            </SetTabRegistrationStateContext.Provider>
        </DevToolsSetDrawerStateContext.Provider>);
};
DevToolsContextProvider.displayName = "DevToolsContextProvider"
function useSetDrawerState() {
    return React.useContext(DevToolsSetDrawerStateContext);
}
function useSetTabRegistrationState() {
    return React.useContext(SetTabRegistrationStateContext);
}
function useTabRegistrationState() {
    return React.useContext(TabRegistrationContext);
}
DevToolsContextProvider.displayName = "DevToolsContextProvider";
function useRegisterDevToolsTab() {
    const setRegistrations = useSetTabRegistrationState();
    return React.useCallback((registration: DynamicDevToolsTabRegistration) => {
        setRegistrations?.((prev) => {
            prev.set(registration.name, registration.children);
            return prev;
        });
    }, [setRegistrations]);
}
export function useRegisteredDevToolTabs() {
    const state = useTabRegistrationState();
    return state ? Array.from(state.entries()).map(([key, value]) => ({ label: key, content: value })) : [];
}
function useUnregisterDevToolsTab() {
    const setRegistrations = useSetTabRegistrationState();
    return React.useCallback((registrationName: string) => {
        setRegistrations?.((prev) => {
            if (prev.has(registrationName)) {
                prev.delete(registrationName);
            }
            return prev;
        });
    }, [setRegistrations]);
}
const useDrawerState = () => {
    return React.useContext(DevToolsDrawerStateContext);
};
export const useToggleDevTools = () => {
    const setDrawerState = useSetDrawerState();
    return React.useCallback(() => {
        setDrawerState?.((prev) => ({ ...prev, open: !prev.open }));
    }, [setDrawerState]);
};
export function useIsDevToolsOpen() {
    return !!useDrawerState()?.open;
}
export function useDevToolsDockLocation() {
    return useDrawerState()?.dockLocation;
}
export function useSetDevToolsDrawerDockLocation() {
    const setDrawerState = useSetDrawerState();
    return React.useCallback((location: DrawerDockLocation) => {
        setDrawerState?.((prev) => {
            if (prev.dockLocation !== location) {
                return { ...prev, dockLocation: location };
            }
            return prev;
        });
    }, [setDrawerState]);
}
type DevToolsTabProps = DynamicDevToolsTabRegistration;
export const DevToolsTab: React.FC<DevToolsTabProps> = ({ name, children }) => {
    useDevToolsTabEffect(name, children);
    return null;
};
DevToolsTab.displayName = "DevToolsTab"
export const useDevToolsTabEffect = (name: string, children: React.ReactNode) => {
    const registerTab = useRegisterDevToolsTab();
    const unregisterTab = useUnregisterDevToolsTab();
    React.useEffect(() => {
        registerTab({ name, children });
        return () => {
            unregisterTab(name);
        };
    }, [children, name, registerTab, unregisterTab]);
};
