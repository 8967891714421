import * as React from "react";
import Dialog from "~/components/Dialog/Dialog";
import type { OpenDialogProps } from "~/components/Dialog/OpenDialogConnect";
import { OpenDialogConnect } from "~/components/Dialog/OpenDialogConnect";
import dialogIdGenerator from "~/components/Dialog/dialogIdGenerator";
interface DialogOpenerProps {
    open: boolean;
    wideDialog?: boolean;
    repositionOnUpdate?: boolean;
    onClose: () => void; //must reset open on close or you can't re-open
}
type DialogOpenerInternalProps = DialogOpenerProps & OpenDialogProps;
//eslint-disable-next-line react/no-unsafe
export class DialogOpenerInternal extends React.Component<DialogOpenerInternalProps> {
    private uniqueId: string;
    constructor(props: DialogOpenerInternalProps) {
        super(props);
        this.uniqueId = dialogIdGenerator.next();
    }
    componentDidMount() {
        if (!this.props.openDialogs[this.uniqueId] && this.props.open) {
            this.props.openDialog(this.uniqueId);
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps: DialogOpenerInternalProps) {
        if (!this.props.openDialogs[this.uniqueId] && !nextProps.openDialogs[this.uniqueId] && !this.props.open && nextProps.open) {
            this.props.openDialog(this.uniqueId);
        }
        if (this.props.openDialogs[this.uniqueId] && !nextProps.openDialogs[this.uniqueId] && this.props.onClose) {
            this.props.onClose();
        }
    }
    render() {
        return (<Dialog open={this.props.openDialogs[this.uniqueId]} wide={this.props.wideDialog} repositionOnUpdate={this.props.repositionOnUpdate}>
                {this.props.children}
            </Dialog>);
    }
    static displayName = "DialogOpenerInternal";
}
const DialogOpener = OpenDialogConnect.to(DialogOpenerInternal, true);
export default DialogOpener;
