import type { TenantsOverviewTenant } from "@octopusdeploy/octopus-server-client";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import type { TenantsFilterState } from "~/areas/tenants/Tenants/hooks/useTenantsFilterState";
import { useTenantsFilterState } from "~/areas/tenants/Tenants/hooks/useTenantsFilterState";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import useLocalStorage from "~/hooks/useLocalStorage";
interface TenantsTableState {
    tenants: TenantsOverviewTenant[];
    totalTenantCount: number;
    filteredTenantCount: number | null;
}
interface TenantsStateActions {
    setPageNumber: (newPageNumber: number) => void;
    setPageSize: (newPageSize: number) => void;
    setTenantsFilter: (newTenantsFilter: TenantsFilterState) => void;
}
interface TenantsState {
    tableState: TenantsTableState | null;
    pageSize: number;
    filterState: TenantsFilterState;
    tenantsActions: TenantsStateActions;
    dataKey: string;
}
const tenantsPagingSelectionKey = "octoTenantsPagingSelection";
export function useTenantsState(doBusyTask: DoBusyTask): TenantsState {
    const [tableState, setTableState] = useState<TenantsTableState | null>(null);
    const [pageSize, setPageSize] = useLocalStorage(tenantsPagingSelectionKey, 30);
    const [filterState, setFilterState] = useTenantsFilterState();
    const [dataKey, setDataKey] = useState("initial-data");
    useDoBusyTaskEffect(doBusyTask, async () => {
        const response = await repository.Tenants.tenantsOverview(filterState.pageNumber, pageSize, filterState);
        setTableState({ tenants: response.Tenants, totalTenantCount: response.TotalTenantCount, filteredTenantCount: response.FilteredTenantCount });
    }, [pageSize, filterState]);
    const actions: TenantsStateActions = {
        setPageNumber: (newPageNumber) => setFilterState({ ...filterState, pageNumber: newPageNumber }),
        setPageSize: (newPageSize) => setPageSize(newPageSize),
        setTenantsFilter: (newTenantsFilter) => {
            setFilterState({ ...newTenantsFilter, pageNumber: 1 });
            setDataKey(uuidv4()); // reset state of the data rows - collapse rows and force to reload details when expanded again
        },
    };
    return {
        tableState,
        pageSize,
        filterState,
        tenantsActions: actions,
        dataKey,
    };
}
