/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/init-declarations */
import { OctopusError } from "@octopusdeploy/octopus-server-client";
import type React from "react";
import type { TimeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { timeOperation } from "~/utils/OperationTimer/timeOperation";
import { RefreshLoop } from "~/utils/RefreshLoop/refresh-loop";
import { PromiseCancelledError } from "../../utils/PromiseCancelledError";
import BaseComponent from "../BaseComponent";
import { DataBaseComponentContext } from "./DataBaseComponentContext";
import type { Errors } from "./Errors";
import { createErrorsFromOctopusError } from "./Errors";
export interface DataBaseComponentState {
    busy?: Promise<void>;
}
export interface DoBusyTaskOptions {
    preserveCurrentErrors?: boolean;
    onError?: (errors: Errors) => void;
    onSuccess?: () => void;
    timeOperationOptions?: TimeOperationOptions;
}
export type DoBusyTask = (action: () => Promise<any>, options?: DoBusyTaskOptions) => Promise<boolean>;
export type Refresh = () => Promise<void>;
export class DataBaseComponent<Props, State = {}> extends BaseComponent<Props, DataBaseComponentState & State> {
    private busies: Array<Promise<void>> = [];
    private stopRefreshLoop: (() => void) | undefined;
    get errors(): Errors | undefined {
        return this.getContext().errors;
    }
    static contextType = DataBaseComponentContext;
    context: React.ContextType<typeof DataBaseComponentContext>;
    constructor(props: Props) {
        super(props);
        this.provideErrorHandling(this.doBusyTaskInternal);
    }
    componentWillUnmount() {
        if (this.stopRefreshLoop !== undefined) {
            this.stopRefreshLoop();
        }
    }
    private getContext = () => {
        if (!this.context) {
            throw Error("DataBaseComponent context or the associated contexts it relies on has not been setup. Please add the associated ErrorContext and DataBaseComponentContexts above this component.");
        }
        return this.context;
    };
    public doBusyTask: DoBusyTask = async (action: () => Promise<any>, options?: DoBusyTaskOptions): Promise<boolean> => {
        return this.doBusyTaskInternal(action, options ?? {});
    };
    protected getFieldError = (fieldName: string) => {
        return this.getContext().getFieldError(fieldName) ?? "";
    };
    protected async startRefreshLoop<K extends keyof (State & DataBaseComponentState)>(getData: () => Promise<Pick<State & DataBaseComponentState, K> | null | (State & DataBaseComponentState)>, refreshInterval: number | ((hidden: boolean) => number), noBusyIndicator = false, timeOperationOptions?: TimeOperationOptions): Promise<Refresh> {
        if (this.stopRefreshLoop !== undefined) {
            throw new Error("Can't create more than one loop in a component");
        }
        const refreshIntervalFunc = typeof refreshInterval === "function" ? refreshInterval : (hidden: boolean) => (hidden ? refreshInterval * 12 : refreshInterval);
        const loop = new RefreshLoop(async (isLoopStillRunning) => {
            const refreshData = async () => {
                const innerData = await getData();
                if (isLoopStillRunning()) {
                    this.setState(innerData);
                }
            };
            if (!noBusyIndicator) {
                await this.doBusyTask(async () => refreshData(), { timeOperationOptions: timeOperationOptions });
            }
            else if (timeOperationOptions) {
                await timeOperation(timeOperationOptions, () => refreshData());
            }
            else {
                await refreshData();
            }
        }, refreshIntervalFunc);
        this.stopRefreshLoop = loop.stop;
        const data = await getData();
        if (this.unmounted) {
            throw new PromiseCancelledError("Component unmounted before loop could start");
        }
        this.setState(data);
        loop.start();
        return loop.refresh;
    }
    protected setValidationErrors = (message: string, fieldErrors: {
        [other: string]: string;
    } = {}) => {
        this.getContext().actions.setValidationErrors(message, fieldErrors);
    };
    protected clearErrors = () => {
        this.getContext().actions.clearErrors();
    };
    protected mapToOctopusError(err: OctopusError) {
        // we override this in subclasses so don't remove
        return createErrorsFromOctopusError(err);
    }
    protected setStateAsync(state: State & DataBaseComponentState) {
        return new Promise<void>((resolve) => {
            this.setState(state, resolve);
        });
    }
    private doBusyTaskInternal = async (action: () => Promise<any>, options: DoBusyTaskOptions): Promise<boolean> => {
        let busy: Promise<void>;
        try {
            // Sometimes child components will load some lookup data while a parent component
            // is displaying an error. The child uses the parent's doBusyTask so that the busy
            // indicator and errors display correctly. But we shouldn't clear existing errors
            // from that child load.
            if (!options.preserveCurrentErrors) {
                this.context!.actions.clearErrors();
            }
            busy = options.timeOperationOptions ? timeOperation(options.timeOperationOptions, action) : action();
            this.busies = [busy, ...this.busies];
            const singlePromise = Promise.all(this.busies).then((v) => {
                /* */
            }); //the .then gives us Promise<void> instead of Promise<void[]>
            this.setState((prev) => ({ ...prev, busy: singlePromise }));
            await busy;
            // There were no errors in this case.
            if (options.onSuccess) {
                options.onSuccess();
            }
            return true;
        }
        catch (e) {
            if (e instanceof OctopusError) {
                const errors = this.mapToOctopusError(e);
                this.getContext().actions.setErrors(e);
                if (options.onError) {
                    options.onError(errors);
                }
                return false;
            }
            if (e instanceof PromiseCancelledError) {
                // swallow it, no point bubbling this up any further since we intentionally cancelled the promise
                return false;
            }
            throw e;
        }
        finally {
            this.busies = this.busies.filter((b) => b !== busy);
            // we need to return null here when done
            // because some buttons etc just check for the
            // existance of a busy promise
            this.setState((prev) => ({
                ...prev,
                busy: this.busies.length > 0
                    ? Promise.all(this.busies).then((v) => {
                        /* */
                    })
                    : null!,
            }));
        }
    };
    static displayName = "DataBaseComponent";
}
