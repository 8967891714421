/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { css, cx } from "@emotion/css";
import SvgRoleIcon from "@material-ui/icons/LocalOffer";
import { IconButton } from "@octopusdeploy/design-system-components";
import { borderRadius, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { DeploymentTargetResource, EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import { isStepPackageEndpointResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import pluralize from "pluralize";
import type { ReactNode } from "react";
import * as React from "react";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Action, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import { BuiltInEndpointLogo, createStepPackageEndpointLogo } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { useOptionalProjectContext } from "~/areas/projects/context/index";
import { repository } from "~/clientInstance";
import { EnvironmentChip } from "~/components/Chips/index";
import InternalLink from "~/components/Navigation/InternalLink/index";
import { useOctopusTheme } from "~/components/Theme/index";
import { DataTable, DataTableBody, DataTableHeader, DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/index";
import PopoverHelp from "~/primitiveComponents/dataDisplay/PopoverHelp/PopoverHelp";
export const RoleChipTextWithPopover = (props: {
    role: string;
    triggerElement: ReactNode;
}) => {
    const [machines, setMachines] = React.useState<DeploymentTargetResource[]>([]);
    const [environments, setEnvironments] = React.useState<EnvironmentResource[]>([]);
    const [totalCount, setTotalCount] = React.useState<number>(0);
    const projectContext = useOptionalProjectContext();
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 79.9rem)` });
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectContext?.state.model.Id);
    const tableContainer = css({
        borderLeft: `1px solid ${useOctopusTheme().dividerLight}`,
        borderRight: `1px solid ${useOctopusTheme().dividerLight}`,
        borderTop: `1px solid ${useOctopusTheme().dividerLight}`,
        borderRadius: borderRadius.small,
    });
    const container = css({
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
    });
    const roleIcon = css({ position: "relative", top: "4px", display: "inline", marginRight: "-4px" });
    useEffect(() => {
        (async () => {
            const loadedMachines = await repository.Machines.list({ roles: props.role, take: 5 });
            setTotalCount(loadedMachines.TotalResults);
            // get all distinct environments
            const environmentIds = new Set<string>();
            loadedMachines.Items.forEach((m) => {
                m.EnvironmentIds.forEach((e) => environmentIds.add(e));
            });
            const environments = await repository.Environments.all({ ids: Array.from(environmentIds) });
            // sort by SortOrder
            environments.sort((a, b) => a.SortOrder - b.SortOrder);
            setMachines(loadedMachines.Items);
            setEnvironments(environments);
        })();
    }, [props.role]);
    const machinesLeftToList = totalCount - machines.length;
    return (<PopoverHelp trigger="click" placement={isLargerThanIpad ? "left-end" : "top-end"} absolutePosition={false} label={props.triggerElement} size="medium" onOpen={() => dispatchAction("Role Chip Contextual Help Opened", { action: Action.Toggle, resource: "Role Chip Contextual Help" })}>
            <div className={container}>
                <div>
                    <div className={roleIcon}>
                        <SvgRoleIcon style={{ height: "16px" }}/>
                    </div>{" "}
                    <span>
                        {totalCount > 0 && (<>
                                <b>{props.role}</b>&nbsp;target tag connects to {totalCount} deployment {pluralize("target", totalCount)}:
                            </>)}
                        {totalCount === 0 && (<>
                                <b>{props.role}</b>&nbsp;target tag is not connected to any deployment targets yet. You can assign target tag to a deployment target later, in{" "}
                                <InternalLink to={links.infrastructureOverviewPage.generateUrl({ spaceId: repository.spaceId! })} weight="bold" openInSelf={false}>
                                    Infrastructure.
                                </InternalLink>
                            </>)}
                    </span>
                </div>
                {environments && machines && (<>
                        <div className={tableContainer}>
                            <DataTable>
                                <DataTableHeader>
                                    <DataTableRow>
                                        <DataTableHeaderColumn>Deployment target</DataTableHeaderColumn>
                                        <DataTableHeaderColumn>Environment</DataTableHeaderColumn>
                                    </DataTableRow>
                                </DataTableHeader>
                                <DataTableBody>
                                    {machines.map((m) => (<MachineRow machine={m} key={m.Id} environments={environments.filter((e) => m.EnvironmentIds.includes(e.Id))}/>))}
                                </DataTableBody>
                            </DataTable>
                        </div>
                        {machinesLeftToList > 0 && (<div>
                                <InternalLink to={links.deploymentTargetsPage.generateUrl({ spaceId: repository.spaceId! }, { roles: [props.role] })} weight="bold" openInSelf={false} onClick={() => dispatchAction("Role Chip Contextual Help View All Clicked", { action: Action.View, resource: "Deployment Target" })}>
                                    See {machinesLeftToList} more {pluralize("target", machinesLeftToList)} in Infrastructure
                                </InternalLink>
                            </div>)}
                    </>)}
            </div>
        </PopoverHelp>);
};
function ExpandableEnvironmentList(props: {
    environments: EnvironmentResource[];
}) {
    const projectContext = useOptionalProjectContext();
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectContext?.state.model.Id);
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const { environments } = props;
    const expanderContainer = css({
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
    });
    const divider = css({
        borderTop: `1px solid ${themeTokens.color.border.primary}`,
        paddingTop: "0.5rem",
    });
    const container = css({
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
    });
    const toggleExpanded = (e: React.MouseEvent) => {
        if (!expanded) {
            dispatchAction("Role Chip Contextual Help Multiple Environments Expanded", { action: Action.View, resource: "Environment" });
        }
        setExpanded(!expanded);
    };
    if (environments.length === 1) {
        return (<div>
                <EnvironmentChip environmentName={environments[0].Name}/>
            </div>);
    }
    if (!expanded) {
        return (<div className={expanderContainer}>
                <EnvironmentChip environmentName={"Multiple Environments"}/>
                <IconButton icon={"ArrowDown"} onClick={toggleExpanded}/>
            </div>);
    }
    return (<div className={container}>
            {environments.map((e, i) => (<div key={e.Id} className={cx({ [expanderContainer]: i === 0, [divider]: i > 0 })}>
                    <div>
                        <InternalLink to={links.infrastructureEnvironmentPage.generateUrl({ spaceId: e.SpaceId, environmentId: e.Id })} openInSelf={false}>
                            <EnvironmentChip environmentName={e.Name}/>
                        </InternalLink>
                    </div>
                    {i === 0 && <IconButton icon={"ArrowUp"} onClick={toggleExpanded}/>}
                </div>))}
        </div>);
}
const MachineRow = ({ machine, environments }: {
    machine: DeploymentTargetResource;
    environments: EnvironmentResource[];
}) => {
    const RegistrationLogo = isStepPackageEndpointResource(machine.Endpoint) ? createStepPackageEndpointLogo(machine.Endpoint) : BuiltInEndpointLogo;
    const machineContainer = css({
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        minHeight: "34px",
    });
    const machineIcon = css({
        height: "16px",
        marginRight: "0.5em",
    });
    const machineName = css({
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
    });
    const machineColumn = css({
        padding: "0.0rem 0.75rem !important",
        verticalAlign: "middle !important",
    });
    const environmentColumn = css({
        width: "250px",
        verticalAlign: "middle !important",
    });
    return (<DataTableRow>
            <DataTableRowColumn className={machineColumn}>
                <InternalLink to={links.deploymentTargetSettingsPage.generateUrl({ spaceId: machine.SpaceId, machineId: machine.Id })} openInSelf={false}>
                    <div className={machineContainer}>
                        <div className={machineIcon}>
                            <RegistrationLogo machine={machine} variant={"small"}/>
                        </div>{" "}
                        <div className={machineName}>{machine.Name}</div>
                    </div>
                </InternalLink>
            </DataTableRowColumn>
            <DataTableRowColumn className={environmentColumn}>
                <ExpandableEnvironmentList environments={environments}/>
            </DataTableRowColumn>
        </DataTableRow>);
};
