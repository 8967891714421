/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import type { ResourcesById, AccountResource, AccountUsageResource, StepUsage, ReleaseUsage, ReleaseUsageEntry, LibraryVariableSetUsageEntry, ProjectVariableSetUsage, TargetUsageEntry, RunbookStepUsage, ProjectResource, RunbookResource, RunbookSnapshotUsage, } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import { sortBy, uniq, keys, reduce, groupBy, compact, flatten, flatMap, uniqBy } from "lodash";
import * as React from "react";
import { repository } from "~/clientInstance";
import Chip from "~/components/Chips/Chip";
import NavigationChip from "~/components/Chips/NavigationChip";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import DataBaseComponent from "~/components/DataBaseComponent";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import { ExpandableFormSection, Summary, FormSectionHeading } from "~/components/form";
import { CardFill } from "~/components/form/Sections/ExpandableFormSection";
import { DataTable } from "~/primitiveComponents/dataDisplay/DataTable/DataTable";
import { DataTableBody } from "~/primitiveComponents/dataDisplay/DataTable/DataTableBody";
import { DataTableHeader } from "~/primitiveComponents/dataDisplay/DataTable/DataTableHeader";
import { DataTableHeaderColumn } from "~/primitiveComponents/dataDisplay/DataTable/DataTableHeaderColumn";
import { DataTableRow } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRow";
import { DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRowColumn";
import type { Theme } from "~/theme";
import { useTheme } from "~/theme";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import toResourceLookup from "~/utils/toResourceLookup";
class AccountUsageProps {
    account: AccountResource = undefined!;
    accountUsages: AccountUsageResource = undefined!;
}
enum UsageType {
    Target = "Target",
    LibraryVariableSet = "LibraryVariableSet"
}
type ProjectAccountUsage = Pick<AccountUsageResource, "DeploymentProcesses" | "Releases" | "ProjectVariableSets" | "RunbookProcesses" | "RunbookSnapshots">;
type RunbookAccountUsage = Pick<AccountUsageResource, "RunbookProcesses" | "RunbookSnapshots">;
type UsageByProjectIdLookup = {
    [key: string]: ProjectAccountUsage;
};
type UsageByRunbookIdLookup = {
    [key: string]: RunbookAccountUsage;
};
type ProjectLookup = ResourcesById<ProjectResource>;
type RunbookLookup = ResourcesById<RunbookResource>;
function lookupUsage<T>(lookup: () => T[], fallback: () => T[]) {
    const result = lookup();
    return result ? result : fallback();
}
function getProjectUsageGroupings(usage: AccountUsageResource): UsageByProjectIdLookup {
    const deploymentProcesses = groupBy(usage.DeploymentProcesses, (x) => x.ProjectId);
    const projectVariables = groupBy(usage.ProjectVariableSets.filter((x) => x.IsCurrentlyBeingUsedInProject === true), (x) => x.ProjectId);
    const releaseUsages = getCombinedReleaseUsages(usage.Releases, usage.ProjectVariableSets.filter((x) => x.IsCurrentlyBeingUsedInProject === false));
    const releases = groupBy(releaseUsages, (x) => x.ProjectId);
    const runbookProcesses = groupBy(usage.RunbookProcesses, (x) => x.ProjectId);
    const runbookSnapshots = groupBy(usage.RunbookSnapshots, (x) => x.ProjectId);
    const initial: {
        [key: string]: ProjectAccountUsage;
    } = {};
    const projectIds = uniq([...keys(deploymentProcesses), ...keys(projectVariables), ...keys(releases), ...keys(runbookProcesses), ...keys(runbookSnapshots)]);
    return reduce(projectIds, (prev, id) => {
        return {
            ...prev,
            [id]: {
                DeploymentProcesses: lookupUsage(() => deploymentProcesses[id], () => [] as StepUsage[]),
                ProjectVariableSets: lookupUsage(() => projectVariables[id], () => [] as ProjectVariableSetUsage[]),
                Releases: lookupUsage(() => releases[id], () => [] as ReleaseUsage[]),
                RunbookProcesses: lookupUsage(() => runbookProcesses[id], () => [] as RunbookStepUsage[]),
                RunbookSnapshots: lookupUsage(() => runbookSnapshots[id], () => [] as RunbookSnapshotUsage[]),
            },
        };
    }, initial);
}
function getCombinedReleaseUsages(releases: ReleaseUsage[], projectVariableSetUsagesViaReleaseSnapshots: ProjectVariableSetUsage[]): ReleaseUsage[] {
    const releaseProjectIds = uniq([...projectVariableSetUsagesViaReleaseSnapshots.map((x) => x.ProjectId), ...releases.map((x) => x.ProjectId)]);
    const releaseUsages = releaseProjectIds.reduce<ReleaseUsage[]>((acc, projectId) => {
        const p1 = projectVariableSetUsagesViaReleaseSnapshots.find((a) => a.ProjectId === projectId);
        const p2 = releases.find((a) => a.ProjectId === projectId);
        const releasesInProjects = uniqBy([...(p1?.Releases ?? []), ...(p2?.Releases ?? [])], (p) => p.ReleaseId);
        const projectName = p1?.ProjectName ?? p2?.ProjectName ?? "";
        acc.push({ ProjectName: projectName, ProjectId: projectId, Releases: releasesInProjects });
        return acc;
    }, []);
    return releaseUsages;
}
function getRunbookUsageGroupings(usage: AccountUsageResource): UsageByRunbookIdLookup {
    const runbookSnapshots = groupBy(usage.RunbookSnapshots, (x) => x.RunbookId);
    const runbookProcesses = groupBy(usage.RunbookProcesses, (x) => x.RunbookId);
    const runbookIds = uniq([...keys(runbookSnapshots), ...keys(runbookProcesses)]);
    const initial: {
        [key: string]: RunbookAccountUsage;
    } = {};
    return reduce(runbookIds, (prev, id) => {
        return {
            ...prev,
            [id]: {
                RunbookProcesses: lookupUsage(() => runbookProcesses[id], () => [] as RunbookStepUsage[]),
                RunbookSnapshots: lookupUsage(() => runbookSnapshots[id], () => [] as RunbookSnapshotUsage[]),
            },
        };
    }, initial);
}
interface AccountUsageState extends DataBaseComponentState {
    projectUsageLookup: UsageByProjectIdLookup;
    runbookUsageLookup: UsageByRunbookIdLookup;
    projects: ProjectLookup;
    runbooks: RunbookLookup;
    runbooksByProjectId: {
        [key: string]: RunbookResource[];
    };
}
interface UsageSection {
    className?: string;
    children: React.ReactNode;
}
const UsageSection: React.FC<UsageSection> = ({ className, children, ...rest }) => {
    return (<div className={cn(className)} {...rest}>
            {children}
        </div>);
};
UsageSection.displayName = "UsageSection"
const UsageSectionHeading = styled("h1")<Theme, {}>(({ theme }) => ({
    fontSize: "1rem",
    fontWeight: theme.typography.fontWeightMedium,
}));
interface GroupedUsageSectionProps {
    className?: string;
    title: React.ReactNode;
    description?: React.ReactNode;
}
const GroupedUsageSection: React.FC<GroupedUsageSectionProps> = ({ className, title: name, description, children, ...rest }) => {
    return (<Box className={cn(className)} {...rest}>
            <Box display={"flex"} alignItems="center" flexDirection="row">
                <Box width={1 / 6}>
                    <UsageSectionHeading>{name}</UsageSectionHeading>
                </Box>
                <Box>{description}</Box>
            </Box>

            {children}
        </Box>);
};
GroupedUsageSection.displayName = "GroupedUsageSection"
const UsageGroupHeading = styled("h1")<Theme, {}>(({ theme }) => ({
    background: theme.palette.action.hover,
    padding: theme.spacing(1),
    fontSize: "1rem",
    fontWeight: theme.typography.fontWeightMedium,
    borderBottom: `solid 1px ${theme.palette.divider}`,
    borderTop: `solid 1px ${theme.palette.divider}`,
}));
interface UsageGroupProps {
    name: React.ReactNode;
}
const UsageGroup: React.FC<UsageGroupProps> = ({ name, children }) => {
    return (<Box>
            <UsageGroupHeading>{name}</UsageGroupHeading>
            {children}
        </Box>);
};
UsageGroup.displayName = "UsageGroup"
interface UsageRowProps {
    name: string;
    children: React.ReactNode;
}
const UsageRow: React.FC<UsageRowProps> = ({ name, children }) => {
    const theme = useTheme();
    return (<Box alignItems="center" display="flex" flexDirection="row" paddingX={1} paddingY={2} borderBottom={`solid 1px ${theme.palette.divider}`}>
            <Box width={1 / 6}>{name}</Box>
            <Box>{children}</Box>
        </Box>);
};
UsageRow.displayName = "UsageRow"
interface ReleaseUsageRowProps {
    spaceId: string;
    usages: ReleaseUsage[];
}
const ReleaseUsageRow: React.FC<ReleaseUsageRowProps> = ({ spaceId, usages }) => {
    return (<React.Fragment>
            {usages && usages.length > 0 && (<UsageRow name={"Releases"}>
                    {usages.map((usageEntry: ReleaseUsage) => usageEntry.Releases.map((release: ReleaseUsageEntry, i) => (<NavigationChip to={links.releasePage.generateUrl({ spaceId, projectSlug: usageEntry.ProjectId, releaseVersion: release.ReleaseVersion })} key={`${release.ReleaseId}`} accessibleName={`Release version ${release.ReleaseVersion} for project ${usageEntry.ProjectName}`}>
                                {release.ReleaseVersion}
                            </NavigationChip>)))}
                </UsageRow>)}
        </React.Fragment>);
};
ReleaseUsageRow.displayName = "ReleaseUsageRow"
interface RunbookSnapshotUsageRowProps {
    spaceId: string;
    usages: RunbookSnapshotUsage[];
}
const RunbookSnapshotUsageRow: React.FC<RunbookSnapshotUsageRowProps> = ({ spaceId, usages }) => {
    return (<React.Fragment>
            {usages && usages.length > 0 && (<UsageRow name={"Snapshots"}>
                    {usages.map((usageEntry) => usageEntry.Snapshots.map((snapshot) => (<NavigationChip to={links.projectRunbookSnapshotInfoPage.generateUrl({ spaceId, projectSlug: usageEntry.ProjectId, runbookId: usageEntry.RunbookId, runbookSnapshotId: snapshot.SnapshotId })} key={snapshot.SnapshotId} accessibleName={`Runbook snapshot ${snapshot.SnapshotName} for runbook ${usageEntry.RunbookName} in project ${usageEntry.ProjectName}`}>
                                {snapshot.SnapshotName}
                            </NavigationChip>)))}
                </UsageRow>)}
        </React.Fragment>);
};
RunbookSnapshotUsageRow.displayName = "RunbookSnapshotUsageRow"
interface DeploymentProcessStepsUsageRowProps {
    spaceId: string;
    usages: StepUsage[];
}
const DeploymentProcessStepsUsageRow: React.FC<DeploymentProcessStepsUsageRowProps> = ({ spaceId, usages }) => {
    return (<React.Fragment>
            {usages && usages.length > 0 && (<UsageRow name={"Steps"}>
                    {usages.map((usageEntry) => usageEntry.Steps.map((step) => (<NavigationChip to={links.deploymentProcessStepsPage.generateUrl({ spaceId, projectSlug: usageEntry.ProjectSlug }, { actionId: step.StepId })} key={`${step.StepId}`} accessibleName={`Deployment process step ${step.StepName} for project ${usageEntry.ProjectName}`}>
                                {step.StepName}
                            </NavigationChip>)))}
                </UsageRow>)}
        </React.Fragment>);
};
DeploymentProcessStepsUsageRow.displayName = "DeploymentProcessStepsUsageRow"
interface RunbookProcessStepsUsageRowProps {
    spaceId: string;
    usages: RunbookStepUsage[];
}
const RunbookProcessStepsUsageRow: React.FC<RunbookProcessStepsUsageRowProps> = ({ spaceId, usages }) => {
    return (<React.Fragment>
            {usages && usages.length > 0 && (<UsageRow name={"Steps"}>
                    {usages.map((usageEntry: RunbookStepUsage) => usageEntry.Steps.map((step) => (<NavigationChip to={links.projectRunbookProcessStepsPage.generateUrl({ spaceId, projectSlug: usageEntry.ProjectSlug, processId: usageEntry.ProcessId, runbookId: usageEntry.RunbookId }, { actionId: step.StepId })} key={`${step.StepId}`} accessibleName={`Runbook step ${step.StepName} for runbook ${usageEntry.RunbookName} in project ${usageEntry.ProjectName}`}>
                                {step.StepName}
                            </NavigationChip>)))}
                </UsageRow>)}
        </React.Fragment>);
};
RunbookProcessStepsUsageRow.displayName = "RunbookProcessStepsUsageRow"
const getCountSummary = (items: ArrayLike<{}>, singular: string, plural: string) => {
    return (items &&
        items.length > 0 && (<Chip>
                {items.length} {items.length === 1 ? singular : plural}
            </Chip>));
};
const getProjectSummary = (usages: ProjectAccountUsage & RunbookAccountUsage = { DeploymentProcesses: [], ProjectVariableSets: [], Releases: [], RunbookProcesses: [], RunbookSnapshots: [] }) => {
    const usageSummary = flatten(compact([
        getCountSummary(flatMap(usages.DeploymentProcesses || [], (x) => x.Steps), "deployment process step", "deployment process steps"),
        getCountSummary(flatMap(usages.Releases || [], (x) => x.Releases), "release", "releases"),
        getCountSummary(flatMap(usages.RunbookProcesses || [], (x) => x.Steps), "runbook process step", "runbook process steps"),
        getCountSummary(flatMap(usages.RunbookSnapshots || [], (x) => x.Snapshots), "runbook snapshot", "runbook snapshots"),
        usages.ProjectVariableSets && usages.ProjectVariableSets.length > 0 && `is used in variables`,
    ]).map((value, index) => (index === 0 ? [value] : [" and ", value])));
    return <React.Fragment>This account is used in {usageSummary}</React.Fragment>;
};
export default class AccountUsage extends DataBaseComponent<AccountUsageProps, AccountUsageState> {
    constructor(props: AccountUsageProps) {
        super(props);
        this.state = {
            projectUsageLookup: {},
            projects: {},
            runbooks: {},
            runbooksByProjectId: {},
            runbookUsageLookup: {},
        };
    }
    async componentDidMount() {
        return this.doBusyTask(async () => {
            const projectUsageLookup = getProjectUsageGroupings(this.props.accountUsages);
            const runbookUsageLookup = getRunbookUsageGroupings(this.props.accountUsages);
            const runbooks = await repository.Runbooks.all({ ids: keys(runbookUsageLookup) });
            const projects = await repository.Projects.all({ ids: uniq([...keys(projectUsageLookup), ...this.props.accountUsages.RunbookProcesses.map((x) => x.ProjectId), ...this.props.accountUsages.RunbookSnapshots.map((x) => x.ProjectId)]) });
            const runbookByProjectId = groupBy(runbooks, (x) => x.ProjectId);
            this.setState({
                projects: toResourceLookup(projects),
                projectUsageLookup,
                runbooks: toResourceLookup(runbooks),
                runbooksByProjectId: runbookByProjectId,
                runbookUsageLookup,
            });
        }, { timeOperationOptions: timeOperationOptions.for("AccountUsage") });
    }
    render() {
        const accountUsages = this.props.accountUsages;
        return (<div>
                <ExpandableFormSection key="usageInLibraryVariableSets" errorKey="usageInLibraryVariableSets" title="Library Variable Sets" summary={this.accountUsageSummary(UsageType.LibraryVariableSet, accountUsages)} help={this.accountUsageHelp(UsageType.LibraryVariableSet, accountUsages)}>
                    {this.accountUsageInLibraryVariableSets(accountUsages)}
                </ExpandableFormSection>
                <ExpandableFormSection key="usageInTargets" errorKey="usageInTargets" title="Targets" summary={this.accountUsageSummary(UsageType.Target, accountUsages)} help={this.accountUsageHelp(UsageType.Target, accountUsages)}>
                    {this.accountUsageInTargets(accountUsages)}
                </ExpandableFormSection>

                <FormSectionHeading title={"Projects"}/>
                {keys(this.state.projects).map((x) => {
                return (<ExpandableFormSection fillCardWidth={CardFill.FillRight} key={x} errorKey={x} title={this.state.projects[x].Name} summary={Summary.summary(getProjectSummary({ ...this.state.projectUsageLookup[x] }))} help={"Areas of this project linked to this account."}>
                            {this.state.projectUsageLookup[x] && (<GroupedUsageSection title={"Deployment"}>
                                    <UsageGroup name={<InternalLink to={links.deploymentProcessPage.generateUrl({
                                spaceId: this.props.account.SpaceId,
                                projectSlug: this.state.projects[x].Slug,
                            })}>
                                                Process
                                            </InternalLink>}>
                                        <ReleaseUsageRow spaceId={this.props.account.SpaceId} usages={this.state.projectUsageLookup[x].Releases}/>
                                        <DeploymentProcessStepsUsageRow spaceId={this.props.account.SpaceId} usages={this.state.projectUsageLookup[x].DeploymentProcesses}/>
                                    </UsageGroup>
                                </GroupedUsageSection>)}
                            {this.state.runbooksByProjectId[x] && (<GroupedUsageSection title={"Runbooks"}>
                                    {this.state.runbooksByProjectId[x].map(({ Id: runbookId, Name: name, RunbookProcessId: runbookProcessId, SpaceId: spaceId }) => (<UsageGroup name={<InternalLink to={links.projectRunbookProcessListPage.generateUrl({ spaceId, projectSlug: this.state.projects[x].Slug, runbookId, processId: runbookProcessId })}>{name}</InternalLink>} key={runbookId}>
                                            <RunbookSnapshotUsageRow spaceId={spaceId} usages={this.state.runbookUsageLookup[runbookId].RunbookSnapshots}/>
                                            <RunbookProcessStepsUsageRow spaceId={spaceId} usages={this.state.runbookUsageLookup[runbookId].RunbookProcesses}/>
                                        </UsageGroup>))}
                                </GroupedUsageSection>)}
                            {this.state.projectUsageLookup[x] && this.state.projectUsageLookup[x].ProjectVariableSets.length > 0 && (<GroupedUsageSection title={"Variables"} description={<InternalLink to={links.variablesPage.generateUrl({ spaceId: this.state.projects[x].SpaceId, projectSlug: this.state.projects[x].Slug })}>This account is being used in this projects variables</InternalLink>}/>)}
                        </ExpandableFormSection>);
            })}
            </div>);
    }
    accountUsageSummary(usageType: UsageType, accountUsages: AccountUsageResource) {
        switch (usageType) {
            case UsageType.Target:
                return accountUsages.Targets.length > 0
                    ? accountUsages.Targets.length > 1
                        ? Summary.summary(<span>
                                  This account is being used in <b>{accountUsages.Targets.length}</b> targets
                              </span>)
                        : Summary.summary(<span>
                                  This account is being used in <b>1</b> target
                              </span>)
                    : Summary.placeholder("This account is not being used in any targets");
            case UsageType.LibraryVariableSet:
                return accountUsages.LibraryVariableSets.length > 0
                    ? accountUsages.LibraryVariableSets.length > 1
                        ? Summary.summary(<span>
                                  This account is being used in <b>{accountUsages.LibraryVariableSets.length}</b> library variable sets
                              </span>)
                        : Summary.summary(<span>
                                  This account is being used in <b>1</b> library variable set
                              </span>)
                    : Summary.placeholder("This account is not being used in any library variable sets");
        }
    }
    accountUsageHelp(usageType: UsageType, accountUsages: AccountUsageResource) {
        switch (usageType) {
            case UsageType.Target:
                return accountUsages.Targets.length === 0
                    ? "This account is not being used in any targets"
                    : accountUsages.Targets.length === 1
                        ? "This account is being used in the following target"
                        : "This account is being used in the following targets";
            case UsageType.LibraryVariableSet:
                return accountUsages.LibraryVariableSets.length === 0
                    ? "This account is not being used in any library variable sets"
                    : accountUsages.LibraryVariableSets.length === 1
                        ? "This account is being used in the following library variable set"
                        : "This account is being used in the following library variable sets";
        }
    }
    private accountUsageInLibraryVariableSets(accountUsages: AccountUsageResource) {
        return (<div>
                {accountUsages.LibraryVariableSets.length > 0 && (<DataTable>
                        <DataTableHeader>
                            <DataTableRow>
                                <DataTableHeaderColumn>Library Variable Set</DataTableHeaderColumn>
                            </DataTableRow>
                        </DataTableHeader>
                        <DataTableBody>
                            {sortBy(accountUsages.LibraryVariableSets, [(x) => x.LibraryVariableSetName, "asc"]).map((usageEntry: LibraryVariableSetUsageEntry, idx) => {
                    const rowKey = `AULVS-${usageEntry.LibraryVariableSetId}`;
                    return (<DataTableRow key={rowKey}>
                                        <DataTableRowColumn>
                                            <InternalLink to={links.editVariableSetPage.generateUrl({ spaceId: this.props.account.SpaceId, variableSetId: usageEntry.LibraryVariableSetId })}>{usageEntry.LibraryVariableSetName}</InternalLink>
                                        </DataTableRowColumn>
                                    </DataTableRow>);
                })}
                        </DataTableBody>
                    </DataTable>)}
            </div>);
    }
    private accountUsageInTargets(accountUsages: AccountUsageResource) {
        return (<div>
                {accountUsages.Targets.length > 0 && (<DataTable>
                        <DataTableHeader>
                            <DataTableRow>
                                <DataTableHeaderColumn>Target Name</DataTableHeaderColumn>
                            </DataTableRow>
                        </DataTableHeader>
                        <DataTableBody>
                            {sortBy(accountUsages.Targets, [(x) => x.TargetName, "asc"]).map((usageEntry: TargetUsageEntry, idx) => {
                    const rowKey = `AUT-${usageEntry.TargetId}`;
                    return (<DataTableRow key={rowKey}>
                                        <DataTableRowColumn>
                                            <InternalLink to={links.deploymentTargetSettingsPage.generateUrl({ spaceId: this.props.account.SpaceId, machineId: usageEntry.TargetId })}>{usageEntry.TargetName}</InternalLink>
                                        </DataTableRowColumn>
                                    </DataTableRow>);
                })}
                        </DataTableBody>
                    </DataTable>)}
            </div>);
    }
    static displayName = "AccountUsage";
}
export { UsageType };
