/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Checkbox, Callout } from "@octopusdeploy/design-system-components";
import type { UserResource, SpaceResource, IconSvgResource, IconMetadataResource, GetTeamsLookupBffResponseTeam } from "@octopusdeploy/octopus-server-client";
import { TeamConstants, Permission } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import IconAndLogoEditLayout, { LogoTypeSelection } from "~/areas/infrastructure/components/IconAndLogoEditLayout/IconAndLogoEditLayout";
import { client, repository, session } from "~/clientInstance";
import { TeamChip, DefaultSpaceChip } from "~/components/Chips";
import buildValueList from "~/components/EventFilter/buildValueList";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent";
import FormPaperLayout from "~/components/FormPaperLayout/FormPaperLayout";
import Logo from "~/components/Logo/Logo";
import { TeamMultiSelect } from "~/components/MultiSelect/TeamMultiSelect";
import { UserMultiSelect } from "~/components/MultiSelect/UserMultiSelect";
import InternalLink from "~/components/Navigation/InternalLink";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { withTheme } from "~/components/Theme";
import type { LogoEditorSettings, SummaryNode } from "~/components/form";
import { ExpandableFormSection, LogoEditor, Note, Summary, Text } from "~/components/form";
import type { IconEditorSettings } from "~/components/form/IconEditor/IconEditor";
import IconEditor, { IconEditorDefaultColor } from "~/components/form/IconEditor/IconEditor";
import { required } from "~/components/form/Validators";
import NameSummaryWithSlug from "~/primitiveComponents/form/Slugs/NameSummaryWithSlug";
import SlugEditor from "~/primitiveComponents/form/Slugs/SlugEditor";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import StringHelper from "~/utils/StringHelper";
import { UserChip } from "../../../../components/Chips/UserChip";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import DeleteSpace from "./DeleteSpace";
import ToggleDefaultSpaceDialog from "./ToggleDefaultSpaceDialog";
import styles from "./style.module.less";
interface SpaceEditModel {
    name: string;
    slug: string;
    description: string;
    spaceManagersTeams: string[];
    spaceManagerUsers: string[];
    logo: LogoEditorSettings;
    icon: IconEditorSettings;
    taskQueueStopped: boolean;
}
interface EditSpacePageProps {
    currentSpaceId: string;
}
interface EditSpacePageState extends OptionalFormBaseComponentState<SpaceEditModel> {
    space: SpaceResource;
    allSpaces: SpaceResource[];
    usersSpaces: SpaceResource[];
    teams: GetTeamsLookupBffResponseTeam[];
    users: UserResource[];
    redirectTo?: LinkHref;
    canDelete: boolean;
    iconSvgResources: IconSvgResource[];
    iconMetadata: IconMetadataResource;
    logoTypeSelection: LogoTypeSelection;
}
export class EditSpacePage extends FormBaseComponent<EditSpacePageProps, EditSpacePageState, SpaceEditModel> {
    constructor(props: EditSpacePageProps) {
        super(props);
        this.state = {
            space: null!,
            model: null!,
            allSpaces: [],
            usersSpaces: [],
            cleanModel: null!,
            teams: [],
            users: [],
            canDelete: false,
            iconSvgResources: [],
            iconMetadata: {
                categories: {},
            },
            logoTypeSelection: LogoTypeSelection.NotSet,
        };
    }
    spaceIdToEdit(): string {
        return this.props.currentSpaceId;
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const usersSpacesPromise = repository.Users.getSpaces(session.currentUser!);
            const usersPromise = repository.Users.all();
            const teamsPromise = this.getTeams();
            const allSpacesPromise = repository.Spaces.all();
            const spacePromise = repository.Spaces.get(this.spaceIdToEdit());
            const featuresConfigurationPromise = repository.FeaturesConfiguration.get();
            const [usersSpaces, users, teams, allSpaces, space, featuresConfiguration] = await Promise.all([usersSpacesPromise, usersPromise, teamsPromise, allSpacesPromise, spacePromise, featuresConfigurationPromise]);
            this.setState({
                teams: teams.sort(this.sortTeams),
                users,
                space,
                allSpaces,
                usersSpaces,
                model: this.buildModel(space),
                cleanModel: this.buildModel(space),
            });
            await this.loadIconsAndMetadata();
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    buildModel(space: SpaceResource): SpaceEditModel {
        return {
            name: space.Name,
            slug: space.Slug,
            description: space.Description!,
            spaceManagersTeams: space.SpaceManagersTeams,
            spaceManagerUsers: space.SpaceManagersTeamMembers,
            logo: { file: null!, reset: false },
            icon: {
                iconId: space.Icon?.Id || "",
                iconColor: space.Icon?.Color || IconEditorDefaultColor,
            },
            taskQueueStopped: space.TaskQueueStopped,
        };
    }
    handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const space: SpaceResource = {
                ...this.state.space,
                Name: this.state.model!.name,
                Slug: this.state.model!.slug,
                Description: this.state.model!.description,
                SpaceManagersTeams: this.state.model!.spaceManagersTeams,
                SpaceManagersTeamMembers: this.state.model!.spaceManagerUsers,
                TaskQueueStopped: this.state.model!.taskQueueStopped,
            };
            if (this.state.logoTypeSelection === LogoTypeSelection.Icon) {
                await repository.Logos.saveIcon(space, this.state.model!.icon.iconId, this.state.model!.icon.iconColor);
            }
            else if (this.state.logoTypeSelection === LogoTypeSelection.CustomImage) {
                await repository.Logos.saveLogo(space, this.state.model!.logo.file!, this.state.model!.logo.reset);
            }
            await this.saveSpace(space);
        });
    };
    async saveSpace(space: SpaceResource) {
        space = await repository.Spaces.save(space);
        this.setState({
            space,
            model: this.buildModel(space),
            cleanModel: this.buildModel(space),
        });
        return space;
    }
    shouldForceDisableFormSaveButton() {
        return this.state.logoTypeSelection === LogoTypeSelection.Icon && !this.state.model?.icon?.iconId;
    }
    nameSummary() {
        return this.state.model && this.state.model.name ? Summary.summary(<NameSummaryWithSlug name={this.state.model.name} slug={this.state.model.slug}/>) : Summary.placeholder("Please enter a name for your space");
    }
    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true}/>;
        }
        return withTheme((theme) => (<FormPaperLayout title={this.state.model?.name ?? StringHelper.ellipsis} titleChip={this.state.space?.IsDefault ? <DefaultSpaceChip noMargin={true}/> : undefined} breadcrumbTitle="Spaces" breadcrumbPath={links.spacesPage.generateUrl()} saveText="Space details changed" busy={this.state.busy} errors={this.errors} model={this.state.model} savePermission={session.currentPermissions!.isSpaceManager(this.state.space) ? undefined : { permission: Permission.SpaceEdit }} cleanModel={this.state.cleanModel} expandAllOnMount={!this.spaceIdToEdit()} onSaveClick={this.handleSaveClick} overFlowActions={this.getOverflowItems()} forceDisableFormSaveButton={this.shouldForceDisableFormSaveButton()}>
                {this.state.model && (<div>
                        {this.showSpaceSwitcherHint() && (<Callout hideTitle={true} type={"information"}>
                                You are not currently in Space: <strong>{this.state.space.Name}</strong>. <InternalLink to={links.spaceRootRedirect.generateUrl({ spaceId: this.state.space.Id })}>Change to this Space.</InternalLink>
                            </Callout>)}

                        <ExpandableFormSection errorKey="name" title="Name" focusOnExpandAll summary={this.nameSummary()} help={"Enter a name for your space."}>
                            <Text value={this.state.model.name} onChange={(name) => this.setModelState({ name })} label="Name" validate={required("Please enter a space name")} error={this.getFieldError("name")} autoFocus={true}/>
                            <Note>A short, memorable, unique name for this space.</Note>
                            <SlugEditor value={this.state.model.slug} name={this.state.model.name} originalSlug={this.state.cleanModel?.slug ?? ""} onChange={(slug) => this.setModelState({ slug })} label="Slug" validate={required("Please enter a space slug")} error={this.getFieldError("slug")}/>
                        </ExpandableFormSection>

                        {/* Only show this section if they have both TeamView and UserView
            Otherwise they might only get a partial view of the teams/users which could be confusing */}
                        {this.canViewTeams() && this.canViewUsers() && (<ExpandableFormSection errorKey="Managers" title="Space Managers" summary={this.spaceManagersSummary()} help="Select members and teams to be managers of this space.">
                                <div className={styles.spaceManagersCalloutContainer}>
                                    <Callout title="Space Managers are responsible for which users can access this space" type={"information"}>
                                        <strong>How to give users access:</strong>
                                        <ol className={styles.addTeamInstructionsList}>
                                            <li>
                                                Create a new <InternalLink to={links.teamsPage.generateUrl()}>team</InternalLink> or edit an <InternalLink to={links.teamsPage.generateUrl()}>existing team</InternalLink>.
                                            </li>
                                            <li>Include user roles (eg. Project Deployer) and scope them to this space to grant permissions.</li>
                                            <li>Add or modify the members of the team.</li>
                                        </ol>
                                    </Callout>
                                </div>
                                <UserMultiSelect label={"Select space managers (members)"} items={this.state.users} onChange={(spaceManagerUsers) => this.setModelState({ spaceManagerUsers })} value={this.state.model.spaceManagerUsers}/>
                                <TeamMultiSelect label={"Select space managers (teams)"} items={this.state.teams} onChange={(ownerTeams) => this.setModelState({ spaceManagersTeams: ownerTeams })} value={this.state.model.spaceManagersTeams} canBeDeleted={(team) => !this.isSpaceManagersTeam(team.Id)} descriptionPostfix={(team) => (this.isSpaceManagersTeam(team.Id) ? " (this team cannot be removed; each space must have a Space Manager team)" : null!)}/>
                            </ExpandableFormSection>)}

                        <ExpandableFormSection errorKey="description" title="Description" summary={this.state.model.description ? Summary.summary(this.state.model.description) : Summary.placeholder("No space description provided")} help={"Enter a short description for this space."}>
                            <Text value={this.state.model.description} onChange={(description) => this.setModelState({ description })} label="Space description" validate={required("Please enter a space description")} error={this.getFieldError("description")}/>
                        </ExpandableFormSection>

                        <ExpandableFormSection errorKey="logo" title="Logo" summary={this.logoSummary()} help="Choose an icon or upload a custom image.">
                            <IconAndLogoEditLayout iconEditor={<IconEditor icons={this.state.iconSvgResources} iconMetadata={this.state.iconMetadata} selectedIconId={this.state.model.icon?.iconId} selectedIconColor={this.state.model.icon?.iconColor} onIconIdChange={(iconId) => {
                        this.setState({ logoTypeSelection: LogoTypeSelection.Icon });
                        this.setModelState({ icon: { iconId, iconColor: this.state.model?.icon?.iconColor ?? IconEditorDefaultColor }, logo: { file: undefined, reset: false } });
                    }} onIconColorChange={(iconColor) => {
                        this.setState({ logoTypeSelection: LogoTypeSelection.Icon });
                        this.setModelState({ icon: { iconId: this.state.model?.icon?.iconId ?? "", iconColor } });
                    }}/>} logoEditor={<LogoEditor value={this.state.model.logo} onChange={(logo) => {
                        this.setState({ logoTypeSelection: LogoTypeSelection.CustomImage });
                        this.setModelState({ logo, icon: { iconId: "", iconColor: IconEditorDefaultColor } });
                    }}/>} onTabChange={(logoType) => this.setState({ logoTypeSelection: logoType })}/>
                        </ExpandableFormSection>

                        <ExpandableFormSection errorKey="status" title="Task Processing Status" summary={this.state.model.taskQueueStopped
                    ? Summary.summary(<span>
                                              Task processing is <span style={{ color: theme.alert }}>Stopped</span>
                                          </span>)
                    : Summary.placeholder(<span>
                                              Task processing is <span style={{ color: theme.success }}>Running</span>
                                          </span>)} help="Stop processing tasks for this space">
                            <Checkbox label="Stop processing tasks" value={this.state.model.taskQueueStopped} onChange={(taskQueueStopped) => this.setModelState({ taskQueueStopped })}/>
                        </ExpandableFormSection>
                    </div>)}
            </FormPaperLayout>));
    }
    private showSpaceSwitcherHint(): boolean {
        return this.state.space && this.state.space.Id !== client.spaceId && this.state.usersSpaces && this.state.usersSpaces.filter((x) => x.Id === this.state.space.Id).length > 0;
    }
    private isSpaceManagersTeam(teamId: string): boolean {
        return teamId === this.spaceManagersTeamId();
    }
    private spaceManagersTeamId = () => {
        return `${TeamConstants.SpaceManagersTeamIdPrefix}${this.spaceIdToEdit()}`;
    };
    private onDeleteSpaceChange = (canDelete: boolean) => {
        this.setState({ canDelete });
    };
    private deleteSpace = async () => {
        await repository.Spaces.del(this.state.space);
        this.setState({ redirectTo: links.spacesPage.generateUrl() });
        return true;
    };
    /*
        When a system admin creates a new space they can select a list of users that will become Space Managers.
        These users will be assigned to Space Managers team created in the newly created space. This means that
        the system admin will not be able to view this new team on Space Edit page and will be faced with the red
        resource not accessible chip. To avoid that we add a fake team to the list of teams that we pass to Team selector.
    */
    private createFakeSpaceManagersTeam(): GetTeamsLookupBffResponseTeam {
        return {
            Name: "Space Managers",
            Id: this.spaceManagersTeamId(),
            SpaceId: this.spaceIdToEdit(),
        };
    }
    private logoSummary(): SummaryNode {
        if (!this.state.space || this.state.model!.logo.reset) {
            return Summary.placeholder("Default logo");
        }
        if (this.state.model!.logo.file) {
            return Summary.summary(this.state.model!.logo.file.name);
        }
        return Summary.summary(<Logo url={this.state.space.Links.Logo} size="2.5em"/>);
    }
    private sortTeams(team1: GetTeamsLookupBffResponseTeam, team2: GetTeamsLookupBffResponseTeam) {
        return team1.Name < team2.Name ? -1 : team1.Name > team2.Name ? 1 : 0;
    }
    private spaceManagersSummary() {
        return this.managersSummary(this.state.model!.spaceManagersTeams, this.state.model!.spaceManagerUsers);
    }
    private managersSummary(teamIds: string[], userIds: string[]) {
        const userChips = this.state.users.filter((u) => userIds.includes(u.Id)).map((u) => <UserChip key={u.Id} user={u}/>);
        const teamChips = this.state.teams.filter((t) => teamIds.includes(t.Id)).map((t) => <TeamChip key={t.Id} team={t}/>);
        const allChips = userChips.concat(teamChips);
        return Summary.summary(<div>{buildValueList(allChips)}</div>);
    }
    private getDisableDefaultSpaceDialog(): JSX.Element {
        return <ToggleDefaultSpaceDialog disableDefaultSpace={true} onDefaultSpaceChanged={this.setIsDefaultSpace}/>;
    }
    private getEnableDefaultSpaceDialog(): JSX.Element {
        return <ToggleDefaultSpaceDialog disableDefaultSpace={false} onDefaultSpaceChanged={this.setIsDefaultSpace}/>;
    }
    private setIsDefaultSpace = async (isEnabled: boolean) => {
        const updatedSpace = await repository.Spaces.modify({ ...this.state.space, IsDefault: isEnabled });
        this.setState({
            space: updatedSpace,
            model: this.buildModel(updatedSpace),
            cleanModel: this.buildModel(updatedSpace),
        });
    };
    private stopTaskQueue = async () => {
        const updatedSpace = await repository.Spaces.modify({ ...this.state.space, TaskQueueStopped: true });
        const model: SpaceEditModel = {
            ...this.buildModel(updatedSpace),
            ...this.state.model,
            taskQueueStopped: updatedSpace.TaskQueueStopped,
        };
        this.setState({
            space: updatedSpace,
            model,
            cleanModel: this.buildModel(updatedSpace),
        });
    };
    private getOverflowItems() {
        if (!this.state.space) {
            return [];
        }
        const overflowItems: MenuItem[] = [];
        const isDefaultSpaceEnabledForAnySpace = this.state.allSpaces.map((space) => (space.Id === this.state.space.Id ? this.state.space : space)).some((space) => space.IsDefault);
        if (!isDefaultSpaceEnabledForAnySpace) {
            overflowItems.push(OverflowMenuItems.dialogItem("Set as the default space", this.getEnableDefaultSpaceDialog(), {
                permission: Permission.SpaceEdit,
            }));
        }
        if (this.state.space.IsDefault) {
            overflowItems.push(OverflowMenuItems.dialogItem("Disable the default space", this.getDisableDefaultSpaceDialog(), {
                permission: Permission.SpaceEdit,
            }));
        }
        if (this.state.allSpaces.length > 1) {
            overflowItems.push(OverflowMenuItems.deleteItem("Delete", "Are you sure you want to delete this space?", this.deleteSpace, (dialogDoBusyTask) => (<DeleteSpace doBusyTask={dialogDoBusyTask} spaceName={this.state.space.Name} spaceId={this.state.space.Id} isDefaultSpace={this.state.space.IsDefault} isTaskQueueStopped={this.state.space.TaskQueueStopped} onChange={this.onDeleteSpaceChange} stopTaskQueue={this.stopTaskQueue}/>), { permission: Permission.SpaceDelete }, !this.state.canDelete));
        }
        overflowItems.push(OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ regardingAny: [this.state.space.Id], includeSystem: true }), {
            permission: Permission.EventView,
            wildcard: true,
        }));
        return overflowItems;
    }
    private async getTeams(): Promise<GetTeamsLookupBffResponseTeam[]> {
        const hasTeamView = this.canViewTeams();
        const spaceRepository = await repository.forSpace(this.spaceIdToEdit());
        const teams = hasTeamView ? await spaceRepository.Teams.lookupBff() : [];
        return teams.some((t) => t.Id === this.spaceManagersTeamId()) ? teams : [this.createFakeSpaceManagersTeam(), ...teams];
    }
    private canViewTeams() {
        return session.currentPermissions!.scopeToSpaceAndSystem(this.spaceIdToEdit()).isAuthorized({ permission: Permission.TeamView });
    }
    private canViewUsers() {
        return session.currentPermissions!.scopeToSpaceAndSystem(this.spaceIdToEdit()).isAuthorized({ permission: Permission.UserView });
    }
    private async loadIconsAndMetadata() {
        const [icons, metadata] = await Promise.all([repository.Icons.getIcons(), repository.Icons.getIconMetadata()]);
        this.setState({ iconSvgResources: icons, iconMetadata: metadata });
    }
    static displayName = "EditSpacePage";
}
