import { type FeedResource, Permission } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { generateDefaultActionContainer, isRunOnBuiltInWorker, isRunOnServerOrWorkerPool } from "~/areas/projects/components/Process/Common/CommonProcessHelpers";
import { type RunOn, type RunOnServerOrWorkerPool } from "~/areas/projects/components/Process/types";
import { ExecutionContainerImageSelector, StepExecutionOption } from "~/components/ContainerSelector/index";
import { ExecutionContainerImageContextualHelp } from "~/components/ContextualHelp/ContextualHelpSnippets";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { CardFill } from "~/components/form/Sections/ExpandableFormSection";
import { ExpandableFormSection } from "~/components/form/index";
import CommonSummaryHelper from "~/utils/CommonSummaryHelper/index";
type ActionContainerImageSelectorFormSectionProps = {
    projectId: string;
    runOn: RunOn;
    canRunInContainer: boolean;
    expandedByDefault: boolean;
    feeds: FeedResource[];
    containerImageRecommendation?: JSX.Element[];
    loadFeeds: (callback?: (feeds: FeedResource[]) => void) => Promise<void>;
    onRunOnChanged<T extends RunOn>(runOn: T): void;
    imageNameError: string;
    doBusyTask: (action: () => Promise<void>) => Promise<boolean>;
    disableInlineExecutionContainers: boolean;
};
export const ActionContainerImageSelectorFormSection = (props: ActionContainerImageSelectorFormSectionProps) => {
    const runOn = props.runOn;
    if (!isRunOnServerOrWorkerPool(runOn) || !props.canRunInContainer) {
        return null;
    }
    const feedViewPermissionGranted = isAllowed({ permission: Permission.FeedView, project: props.projectId, wildcard: true });
    const onFeedChanged = (feedId: string, runOn: RunOnServerOrWorkerPool) => {
        //TODO: SM We currently store the FeedId like this. Determine if we should change this.
        const updatedContainer = { ...runOn.container, FeedId: feedId };
        props.onRunOnChanged({ runningInContainer: runOn.runningInContainer, executionLocation: runOn.executionLocation, container: updatedContainer });
    };
    const onImageNameChanged = (imageName: string, runOn: RunOnServerOrWorkerPool) => {
        const updatedContainer = { ...runOn.container, Image: imageName };
        props.onRunOnChanged({ runningInContainer: runOn.runningInContainer, executionLocation: runOn.executionLocation, container: updatedContainer });
    };
    const onContainerGitUrlChanged = (gitUrl: string, runOn: RunOnServerOrWorkerPool) => {
        const updatedContainer = { ...runOn.container, GitUrl: gitUrl };
        props.onRunOnChanged({ runningInContainer: runOn.runningInContainer, executionLocation: runOn.executionLocation, container: updatedContainer });
    };
    const onContainerDockerfileChanged = (dockerfile: string, runOn: RunOnServerOrWorkerPool) => {
        const updatedContainer = { ...runOn.container, Dockerfile: dockerfile };
        props.onRunOnChanged({ runningInContainer: runOn.runningInContainer, executionLocation: runOn.executionLocation, container: updatedContainer });
    };
    const onContainerReset = (runOn: RunOnServerOrWorkerPool) => {
        props.onRunOnChanged({ ...runOn });
    };
    return (<>
            <ExpandableFormSection errorKey="Octopus.Action.Container.Image|Octopus.Action.Container.FeedId|Octopus.Action.Container.GitUrl|Octopus.Action.Container.Dockerfile" isExpandedByDefault={props.expandedByDefault} title="Container Image" summary={CommonSummaryHelper.actionContainerSummary(runOn.container, props.feeds, runOn, !feedViewPermissionGranted)} help={`Choose to run directly on ${isRunOnBuiltInWorker(runOn) ? "the Octopus Server" : "a worker"} or inside a container`} contextualHelp={runOn.runningInContainer && runOn.container.Image !== null && <ExecutionContainerImageContextualHelp />} fillCardWidth={CardFill.FillRight}>
                <ExecutionContainerImageSelector runOn={runOn} feeds={props.feeds} refreshFeeds={props.loadFeeds} onFeedChange={(feedId) => {
            if (!!feedId) {
                onFeedChanged(feedId, runOn);
            }
        }} onImageNameChange={(imageName) => onImageNameChanged(imageName, runOn)} onContainerGitUrlChange={(gitUrl) => onContainerGitUrlChanged(gitUrl, runOn)} onContainerDockerfileChange={(dockerfile) => onContainerDockerfileChanged(dockerfile, runOn)} resetContainer={(updatedRunOn) => onContainerReset(updatedRunOn)} onStepExecutionOptionChange={(option) => {
            if (option === StepExecutionOption.RunDirectlyOnWorker) {
                props.onRunOnChanged({ ...runOn, runningInContainer: false, container: generateDefaultActionContainer() });
            }
            else {
                const updatedContainer = option === StepExecutionOption.RunContainerFromGitUrlOnWorker
                    ? { Image: null, FeedId: null, GitUrl: runOn.container.GitUrl, Dockerfile: null }
                    : option === StepExecutionOption.RunContainerFromInlineSourceOnWorker
                        ? { Image: null, FeedId: null, GitUrl: null, Dockerfile: runOn.container.Dockerfile }
                        : { Image: runOn.container.Image, FeedId: runOn.container.FeedId, GitUrl: null, Dockerfile: null };
                props.onRunOnChanged({ ...runOn, runningInContainer: true, container: updatedContainer });
            }
        }} imageNameError={props.imageNameError} containerImageRecommendation={props.containerImageRecommendation} doBusyTask={props.doBusyTask} disableInlineExecutionContainers={props.disableInlineExecutionContainers}/>
            </ExpandableFormSection>
        </>);
};
