import { ProcessType } from "@octopusdeploy/octopus-server-client";
import { noOp } from "@octopusdeploy/utilities";
import * as React from "react";
import DataLoader from "~/components/DataLoader";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { PageAction } from "~/components/PageActions/PageActions";
import { ProcessPaperLayout } from "../CustomPaperLayouts/ProcessPaperLayout";
interface ProcessContextFormPageProps<TData> {
    title: string;
    load(): Promise<TData>;
    renderWhenLoaded(data: TData): React.ReactNode;
    processType: ProcessType;
}
export function ProcessContextFormPage<TData>() {
    const DataLoaderInternal = DataLoader<TData>();
    return class LoadInner extends React.Component<ProcessContextFormPageProps<TData>> {
        render() {
            return (<DataLoaderInternal load={this.props.load} renderWhenLoaded={this.props.renderWhenLoaded} renderAlternate={({ errors, busy }) => (<ProcessPaperLayout processType={this.props.processType} title={this.props.title} errors={errors} busy={busy} pageActions={getPlaceholderActionList(this.props.processType)} overflowActions={[OverflowMenuItems.disabledItem("loading...", "")]}/>)}/>);
        }
    };
}
export const getPlaceholderActionList = (processType: ProcessType): PageAction[] => {
    // UX: We display some placeholder actions to aid with transitions between our loading and layout.
    const placeholderAddButton: PageAction = { type: "button", buttonType: "secondary", label: "Add Step", onClick: noOp, disabled: true };
    if (processType === ProcessType.Runbook) {
        const placeholderRunButton: PageAction = { type: "button", buttonType: "secondary", label: "Run...", onClick: noOp, disabled: true };
        return [placeholderRunButton, placeholderAddButton];
    }
    else {
        return [placeholderAddButton];
    }
};
