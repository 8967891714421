import type { ActionProperties, DeploymentActionResource, DeploymentStepResource, IProcessResource, ProcessType, StepPackageInputs } from "@octopusdeploy/octopus-server-client";
import { PackageRequirement, RunCondition, RunConditionForAction, StartTrigger } from "@octopusdeploy/octopus-server-client";
import type { Dictionary } from "lodash";
import { cloneDeep, forEach, groupBy, keyBy, omit, takeRightWhile } from "lodash";
import type { ActionType } from "typesafe-actions";
import { createAction, getType } from "typesafe-actions";
import type { ActionPlugin } from "~/components/Actions/pluginRegistry";
import { reduceReducers } from "~/utils/Reducers/reduceConstantReducers";
import { generateDefaultActionContainer, isRunOnServerOrWorkerPool } from "../Common/CommonProcessHelpers";
import { generateGuid } from "../generation";
import type { ActionsState, MergeModelState, MetaActionProperties, MetaStepProperties, ProcessContextModelState, ProcessState, RunOn, RunOnBuiltInWorker, RunOnDeploymentTarget, RunOnWorkerPool, SelectorType, StepsState, StoredAction, StoredModelState, StoredStep, } from "../types";
import { ExecutionLocation } from "../types";
import * as processModelSelectors from "./processModelSelectors";
import * as updaters from "./updaters";
const getModel = (state: ProcessContextModelState): Readonly<StoredModelState> => {
    return state.model;
};
const getCleanModel = (state: ProcessContextModelState) => {
    return state.cleanModel;
};
const hasValidProcess = (state: ProcessContextModelState): boolean => {
    return !!state.model.process;
};
const getProcessesForMerge = (state: ProcessContextModelState) => {
    return () => {
        return {
            cleanModelProcess: processModelSelectors.getProcessResource(state.cleanModel)(),
            modelProcess: processModelSelectors.getProcessResource(state.model)(),
        };
    };
};
const getProcessType = (state: ProcessContextModelState) => {
    return () => {
        return state.processType;
    };
};
export const getSelectors = (state: ProcessContextModelState): ProcessStateSelectors => {
    return {
        getProcessResource: processModelSelectors.getProcessResource(state.model),
        getStepResource: processModelSelectors.getStepResource(state.model),
        getStepProperty: processModelSelectors.getStepProperty(state.model),
        getStepById: processModelSelectors.getStepById(state.model),
        tryGetStepById: processModelSelectors.tryGetStepById(state.model),
        tryGetCleanStepById: processModelSelectors.tryGetCleanStepById(state.cleanModel),
        tryGetStepByName: processModelSelectors.tryGetStepByName(state.model),
        getActionProperty: processModelSelectors.getActionProperty(state.model),
        getActionById: processModelSelectors.getActionById(state.model),
        tryGetCleanActionById: processModelSelectors.tryGetCleanActionById(state.cleanModel),
        tryGetActionById: processModelSelectors.tryGetActionById(state.model),
        tryGetActionByName: processModelSelectors.tryGetActionByName(state.model),
        getChildActions: processModelSelectors.getChildActions(state.model),
        getAllSteps: processModelSelectors.getAllSteps(state.model),
        getAllActions: processModelSelectors.getAllActions(state.model),
        getStoredProcess: processModelSelectors.getStoredProcess(state.model),
        hasChildActions: processModelSelectors.hasChildActions(state.model),
        isFirstStep: processModelSelectors.isFirstStep(state.model),
        isFirstChildAction: processModelSelectors.isFirstChildAction(state.model),
        isChildAction: processModelSelectors.isChildAction(state.model),
        isPackageAction: processModelSelectors.isPackageAction(state.model),
        hasSteps: processModelSelectors.hasSteps(state.model),
        hasUnsavedSteps: processModelSelectors.hasUnsavedSteps(state),
        hasStepsInCleanModel: processModelSelectors.hasSteps(state.cleanModel),
        hasValidProcess: () => hasValidProcess(state),
        getCleanModel: () => getCleanModel(state),
        getModel: () => getModel(state),
        shouldShowPackageRequirementOptionForStep: processModelSelectors.shouldShowPackageRequirementOptionForStep(state.model),
        shouldShowPackageRequirementOptionForAction: processModelSelectors.shouldShowPackageRequirementOptionForAction(state.model),
        actionHasFeatures: processModelSelectors.actionHasFeatures(state.model),
        getActionPlugin: processModelSelectors.getActionPlugin(state.model),
        getCleanActionPlugin: processModelSelectors.getCleanActionPlugin(state.cleanModel),
        getStepNumber: processModelSelectors.getStepNumber(state.model),
        canActionHaveChildren: processModelSelectors.canActionHaveChildren(state.model),
        isStepDisabled: processModelSelectors.isStepDisabled(state.model),
        getActionNumber: processModelSelectors.getActionNumber(state.model),
        getAddChildStepUrl: processModelSelectors.getAddChildStepUrl(state.model),
        isActionDisabled: processModelSelectors.isActionDisabled(state.model),
        getStepRightAfterPackageAcquisition: processModelSelectors.getStepRightAfterPackageAcquisition(state.model),
        isRollingStep: processModelSelectors.isRollingStep(state.model),
        isPackageStep: processModelSelectors.isPackageStep(state.model),
        canStepHaveChildren: processModelSelectors.canStepHaveChildren(state.model),
        canActionBeChild: processModelSelectors.canActionBeChild(state.model),
        canStepBeChild: processModelSelectors.canStepBeChild(state.model),
        getStepDetailsUrl: processModelSelectors.getStepDetailsUrl(state.model),
        hasAction: processModelSelectors.hasAction(state.model),
        hasStep: processModelSelectors.hasStep(state.model),
        getStepByIndex: processModelSelectors.getStepByIndex(state.model),
        getActionParentStep: processModelSelectors.getActionParentStep(state.model),
        getParentStep: processModelSelectors.getParentStep(state.model),
        getActionResource: processModelSelectors.getActionResource(state.model),
        getProcessesForMerge: getProcessesForMerge(state),
        getProcessType: getProcessType(state),
        isOnlyChildAction: processModelSelectors.isOnlyChildAction(state.model),
        getOptionalStoredProcess: processModelSelectors.getOptionalStoredProcess(state.model),
        isNewAction: processModelSelectors.isNewAction(state),
        initiallyHadSteps: processModelSelectors.initiallyHadSteps(state),
        isProcessMerged: processModelSelectors.isProcessMerged(state),
        isMerging: processModelSelectors.isMerging(state),
        isMergeDialogClosed: processModelSelectors.isMergeDialogClosed(state),
        canActionRunOnWorker: processModelSelectors.canActionRunOnWorker(processModelSelectors.getActionPlugin(state.model)),
        shouldShowRunTrigger: processModelSelectors.shouldShowRunTrigger(state.model),
        isStepPackageAction: processModelSelectors.isStepPackageAction(state.model),
        isFromStepPackage: processModelSelectors.isFromStepPackage(state.model),
        canBeRetried: processModelSelectors.canBeRetried(processModelSelectors.getActionPlugin(state.model)),
        getStepBySlug: processModelSelectors.getStepBySlug(state.model),
        canUseExecutionTimeouts: processModelSelectors.canUseExecutionTimeouts(processModelSelectors.getActionPlugin(state.model)),
    };
};
const STEPS_INITIAL_STATE: StepsState = {
    allIds: [],
    byId: {},
};
const ACTIONS_INITIAL_STATE: ActionsState = {
    allIds: [],
    byId: {},
};
const RESOURCE_INITIAL_STATE: ProcessState = null;
export const EMPTY_STORED_MODEL_STATE: StoredModelState = {
    actions: ACTIONS_INITIAL_STATE,
    steps: STEPS_INITIAL_STATE,
    process: RESOURCE_INITIAL_STATE,
};
export const INITIAL_MERGED_MODEL_STATE: MergeModelState = {
    processMerged: false,
    dialogClosed: false,
    staged: EMPTY_STORED_MODEL_STATE,
    server: EMPTY_STORED_MODEL_STATE,
};
export const getProcessContextModelInitialState = (processType: ProcessType): ProcessContextModelState => ({
    cleanModel: EMPTY_STORED_MODEL_STATE,
    model: EMPTY_STORED_MODEL_STATE,
    mergeModel: INITIAL_MERGED_MODEL_STATE,
    processType,
});
export function getStoredModelStateFromProcess(process: IProcessResource, actionPlugins: ActionPlugin[]): StoredModelState {
    const { Steps, ...resource } = process;
    const pluginLookup: Record<string, Array<ActionPlugin | undefined>> = groupBy(actionPlugins, (x) => x.actionType);
    const state = Steps.reduce<StoredModelState>((prev: StoredModelState, current) => {
        const storedActions = keyBy(current.Actions.map((x) => {
            const plugins = pluginLookup[x.ActionType];
            if (!plugins || plugins.length === 0) {
                throw Error(`No action plugins have been provided for action ${x.Id}.`);
            }
            const pluginsByVersion: Record<string, ActionPlugin | undefined> = keyBy(plugins, (p) => p?.version || "");
            const plugin = pluginsByVersion[x.StepPackageVersion || ""];
            if (!plugin) {
                throw Error(`The action plugin for action ${x.Id} was not provided.`);
            }
            return { ...x, ParentId: current.Id, plugin };
        }), (x) => x.Id);
        const { Actions: resourceActions, ...rest } = current;
        return {
            ...prev,
            steps: {
                byId: {
                    ...prev.steps.byId,
                    [current.Id]: { ...rest, ActionIds: resourceActions.map((x) => x.Id) },
                },
                allIds: [...prev.steps.allIds, current.Id],
            },
            actions: {
                byId: {
                    ...prev.actions.byId,
                    ...storedActions,
                },
                allIds: [...prev.actions.allIds, ...Object.keys(storedActions)],
            },
        };
    }, EMPTY_STORED_MODEL_STATE);
    return { ...state, process: resource };
}
const updateModelState = (state: StoredModelState, callback: (prev: StoredModelState) => StoredModelState): StoredModelState => {
    return {
        ...state,
        ...callback(state),
    };
};
const stepsReducer: React.Reducer<ProcessContextModelState, ProcessActions> = (state, action) => {
    switch (action.type) {
        case getType(actions.addStep): {
            return addStep(state, action.payload.action, action.payload.step);
        }
        case getType(actions.addChildAction): {
            return addChildAction(state, action.payload.stepId, action.payload.action);
        }
        case getType(actions.removeAction): {
            return removeAction(state, action.payload.stepId, action.payload.actionId);
        }
        case getType(actions.removeStep): {
            return removeStep(state, action.payload.stepId);
        }
        case getType(actions.removeAllSteps): {
            return removeAllSteps(state);
        }
        case getType(actions.setStepProperties): {
            const { stepId, properties } = action.payload;
            return setStepProperties(state, stepId, properties);
        }
        case getType(actions.setStepMetaProperties): {
            const { stepId, callback } = action.payload;
            return setStepMetaProperties(state, stepId, callback);
        }
        case getType(actions.resetPackageRequirementAfterPackageAcquisitionStep): {
            return resetPackageRequirementAfterPackageAcquisitionStep(state);
        }
        case getType(actions.reorderChildActions): {
            const { stepId, orderedActions } = action.payload;
            return reorderChildActions(state, stepId, orderedActions);
        }
        case getType(actions.reorderSteps): {
            const { orderedSteps } = action.payload;
            return reorderSteps(state, orderedSteps);
        }
        case getType(actions.setActionInputs): {
            const { actionId, inputs } = action.payload;
            return setActionInputs(state, actionId, inputs);
        }
        case getType(actions.setMigratedActionProperties): {
            const { actionId, inputs, plugin, stepPackageVersion } = action.payload;
            return setMigratedActionProperties(state, actionId, inputs, plugin, stepPackageVersion);
        }
        case getType(actions.setActionProperties): {
            const { actionId, properties } = action.payload;
            return setActionProperties(state, actionId, properties);
        }
        case getType(actions.removeActionProperties): {
            const { actionId, propertyKeys } = action.payload;
            return removeActionProperties(state, actionId, propertyKeys);
        }
        case getType(actions.setActionMetaProperties): {
            const { actionId, callback } = action.payload;
            return setActionMetaPropertiesForModel(state, actionId, callback);
        }
        case getType(actions.enableAction): {
            return enableActionForModel(state, action.payload.actionId);
        }
        case getType(actions.disableAction): {
            return disableActionForModel(state, action.payload.actionId);
        }
        case getType(actions.disableStep): {
            return disableStep(state, action.payload.stepId);
        }
        case getType(actions.enableStep): {
            return enableStep(state, action.payload.stepId);
        }
        case getType(actions.moveActionOutOfStep): {
            return moveActionOutOfStep(state, action.payload.actionId);
        }
        case getType(actions.moveActionIntoStep): {
            const { actionId, stepId } = action.payload;
            return moveActionIntoStep(state, actionId, stepId);
        }
        case getType(actions.runOnChanged): {
            const { actionId, runOn, stepId } = action.payload;
            return runOnChanged(state, actionId, runOn, stepId);
        }
    }
    return state;
};
function addStep(state: Readonly<ProcessContextModelState>, newAction: StoredAction, newStep: StoredStep): ProcessContextModelState {
    return {
        ...state,
        model: updateModelState(state.model, (prev) => ({
            ...prev,
            steps: {
                ...prev.steps,
                byId: updaters.add(prev.steps.byId, newStep.Id, { ...newStep, Name: newAction.Name }),
                allIds: [...prev.steps.allIds, newStep.Id],
            },
            actions: {
                ...prev.actions,
                byId: updaters.add(prev.actions.byId, newAction.Id, { ...newAction, ParentId: newStep.Id }),
                allIds: [...prev.actions.allIds, newAction.Id],
            },
        })),
    };
}
function addChildAction(state: Readonly<ProcessContextModelState>, stepId: string, newAction: StoredAction): ProcessContextModelState {
    return {
        ...state,
        model: {
            ...state.model,
            steps: {
                ...state.model.steps,
                byId: updaters.patch(state.model.steps.byId, stepId, (prev) => ({
                    ...prev,
                    ActionIds: [...prev.ActionIds, newAction.Id],
                })),
            },
            actions: {
                ...state.model.actions,
                byId: updaters.add(state.model.actions.byId, newAction.Id, { ...newAction, ParentId: stepId }),
                allIds: [...state.model.actions.allIds, newAction.Id],
            },
        },
    };
}
function removeAction(state: Readonly<ProcessContextModelState>, stepId: string, actionId: string): ProcessContextModelState {
    const childActionIds = state.model.steps.byId[stepId]?.ActionIds ?? [];
    return {
        ...state,
        model: {
            ...state.model,
            steps: {
                ...state.model.steps,
                byId: childActionIds.length <= 1
                    ? updaters.remove(state.model.steps.byId, stepId)
                    : updaters.patch(state.model.steps.byId, stepId, (prev) => ({
                        ...prev,
                        ActionIds: prev.ActionIds.filter((x) => x !== actionId),
                    })),
                allIds: childActionIds.length <= 1 ? state.model.steps.allIds.filter((x) => x !== stepId) : state.model.steps.allIds,
            },
            actions: {
                ...state.model.actions,
                byId: updaters.remove(state.model.actions.byId, actionId),
                allIds: state.model.actions.allIds.filter((x) => x !== actionId),
            },
        },
    };
}
function removeStep(state: Readonly<ProcessContextModelState>, stepId: string): ProcessContextModelState {
    const childActionIds = state.model.steps.byId[stepId]?.ActionIds ?? [];
    const childActionLookup = keyBy(childActionIds);
    return {
        ...state,
        model: {
            ...state.model,
            steps: {
                ...state.model.steps,
                byId: updaters.remove(state.model.steps.byId, stepId),
                allIds: state.model.steps.allIds.filter((x) => x !== stepId),
            },
            actions: {
                ...state.model.actions,
                byId: omit(state.model.actions.byId, childActionIds),
                //byId: updaters.removeAll(state.model.steps.byId, childActionIds),
                allIds: state.model.actions.allIds.filter((x) => !childActionLookup[x]),
            },
        },
    };
}
function removeAllSteps(state: Readonly<ProcessContextModelState>): ProcessContextModelState {
    return {
        ...state,
        model: EMPTY_STORED_MODEL_STATE,
    };
}
function setStepProperties(state: Readonly<ProcessContextModelState>, stepId: string, properties: Partial<ActionProperties>): ProcessContextModelState {
    return {
        ...state,
        model: {
            ...state.model,
            steps: updaters.patchById(state.model.steps, stepId, (prev) => ({ Properties: { ...prev.Properties, ...properties } })),
        },
    };
}
function setStepMetaProperties(state: Readonly<ProcessContextModelState>, stepId: string, callback: (prev: MetaStepProperties) => MetaStepProperties): ProcessContextModelState {
    return {
        ...state,
        model: {
            ...state.model,
            steps: updaters.patchById(state.model.steps, stepId, (prev: StoredStep) => ({ ...prev, ...callback(prev) })),
        },
    };
}
function resetPackageRequirementAfterPackageAcquisitionStep(state: Readonly<ProcessContextModelState>): ProcessContextModelState {
    const selectors = getSelectors(state);
    const stepRightAfterPackageAcquisition = selectors.getStepRightAfterPackageAcquisition();
    if (!stepRightAfterPackageAcquisition)
        return state;
    const stepIdsToReset = takeRightWhile(state.model.steps.allIds, (id) => id != stepRightAfterPackageAcquisition.Id);
    if (selectors.isPackageStep(stepRightAfterPackageAcquisition.Id))
        stepIdsToReset.push(stepRightAfterPackageAcquisition.Id);
    // Step package steps have special requirements for package acquisition
    const stepIdsWithoutStepPackageSteps = stepIdsToReset.filter((id) => !selectors.isFromStepPackage(id));
    let steps = { ...state.model.steps };
    forEach(stepIdsWithoutStepPackageSteps, (stepId) => {
        steps = updaters.patchById(steps, stepId, (prev: StoredStep) => ({
            ...prev,
            PackageRequirement: PackageRequirement.LetOctopusDecide,
        }));
    });
    return {
        ...state,
        model: {
            ...state.model,
            steps,
        },
    };
}
function reorderChildActions(state: Readonly<ProcessContextModelState>, stepId: string, orderedActions: string[]): ProcessContextModelState {
    const step = state.model.steps.byId[stepId];
    const stepActionLookup = keyBy(step.ActionIds);
    return step.ActionIds.length !== orderedActions.length || !orderedActions.every((x) => !!stepActionLookup[x])
        ? state
        : {
            ...state,
            model: {
                ...state.model,
                steps: updaters.patchById(state.model.steps, stepId, (prev) => ({
                    ...prev,
                    ActionIds: [...orderedActions],
                })),
            },
        };
}
function reorderSteps(state: Readonly<ProcessContextModelState>, orderedSteps: string[]): ProcessContextModelState {
    return orderedSteps.length !== state.model.steps.allIds.length || !orderedSteps.every((x) => !!state.model.steps.byId[x])
        ? state
        : {
            ...state,
            model: {
                ...state.model,
                steps: {
                    ...state.model.steps,
                    allIds: [...orderedSteps],
                },
            },
        };
}
function setActionInputs(state: Readonly<ProcessContextModelState>, actionId: string, inputs: StepPackageInputs) {
    return {
        ...state,
        model: {
            ...state.model,
            actions: updaters.patchById(state.model.actions, actionId, (prev) => ({ Inputs: inputs })),
        },
    };
}
function setMigratedActionProperties(state: Readonly<ProcessContextModelState>, actionId: string, inputs: StepPackageInputs, plugin: ActionPlugin, stepPackageVersion: string) {
    return {
        ...state,
        model: {
            ...state.model,
            actions: updaters.patchById(state.model.actions, actionId, (prev) => ({ Inputs: inputs, StepPackageVersion: stepPackageVersion, plugin })),
        },
    };
}
function setActionProperties(state: Readonly<ProcessContextModelState>, actionId: string, properties: Partial<ActionProperties>) {
    return {
        ...state,
        model: {
            ...state.model,
            actions: updaters.patchById(state.model.actions, actionId, (prev) => ({ Properties: { ...prev.Properties, ...properties } })),
        },
    };
}
function removeActionProperties(state: Readonly<ProcessContextModelState>, actionId: string, propertyKeys: string[]): ProcessContextModelState {
    return {
        ...state,
        model: {
            ...state.model,
            actions: updaters.patchById(state.model.actions, actionId, (prev) => ({ Properties: omit(prev.Properties, propertyKeys) })),
        },
    };
}
function setActionMetaPropertiesForModel(state: Readonly<ProcessContextModelState>, actionId: string, callback: (prev: MetaActionProperties) => MetaActionProperties): ProcessContextModelState {
    const nextActionState = setActionMetaProperties(state.model.actions, actionId, callback);
    const parentStep = processModelSelectors.getParentStep(state.model)(actionId);
    const nextName = nextActionState.byId[actionId].Name;
    const prevName = state.model.actions.byId[actionId].Name;
    const shouldUpdateStepName = !processModelSelectors.isRollingStep(state.model)(parentStep.Id) && nextName !== prevName;
    return {
        ...state,
        model: {
            ...state.model,
            actions: nextActionState,
            steps: !shouldUpdateStepName
                ? state.model.steps
                : updaters.patchById(state.model.steps, parentStep.Id, (prev: StoredStep) => ({
                    ...prev,
                    Name: nextName,
                })),
        },
    };
}
function enableActionForModel(state: Readonly<ProcessContextModelState>, actionId: string): ProcessContextModelState {
    return {
        ...state,
        model: {
            ...state.model,
            actions: enableAction(state.model.actions, actionId),
        },
    };
}
function disableActionForModel(state: Readonly<ProcessContextModelState>, actionId: string): ProcessContextModelState {
    return {
        ...state,
        model: {
            ...state.model,
            actions: disableAction(state.model.actions, actionId),
        },
    };
}
function disableStep(state: Readonly<ProcessContextModelState>, stepId: string): ProcessContextModelState {
    const childActions = state.model.steps.byId[stepId]?.ActionIds ?? [];
    return {
        ...state,
        model: {
            ...state.model,
            actions: childActions.reduce(disableAction, state.model.actions),
        },
    };
}
function enableStep(state: Readonly<ProcessContextModelState>, stepId: string): ProcessContextModelState {
    const childActions = state.model.steps.byId[stepId]?.ActionIds ?? [];
    return {
        ...state,
        model: {
            ...state.model,
            actions: childActions.reduce(enableAction, state.model.actions),
        },
    };
}
function moveActionOutOfStep(state: Readonly<ProcessContextModelState>, actionId: string): ProcessContextModelState {
    //TODO: Some duplication between other actions here.
    const targetAction = state.model.actions.byId[actionId];
    const previousStep = state.model.steps.byId[targetAction.ParentId];
    //Don't do anything if we have a single action step. This should never happen, however this is a nice safeguard.
    if (previousStep.ActionIds.length <= 1) {
        return state;
    }
    const remainingSiblings = previousStep.ActionIds.filter((x) => x !== actionId);
    const updatedStep = {
        ...previousStep,
        ActionIds: remainingSiblings,
        ...(remainingSiblings.length === 1 ? { Name: state.model.actions.byId[remainingSiblings[0]].Name } : {}),
    };
    //TODO: Name lookup should probably be stored in state instead of calculating this each time.
    const names = keyBy(Object.values(state.model.steps.byId), (x) => x.Name);
    const { condition, expression } = determineStepCondition(targetAction, previousStep);
    const newStep: StoredStep = {
        Id: generateGuid(),
        Links: {},
        ...getStepDefaults(),
        ActionIds: [targetAction.Id],
        Name: nextUniqueName(targetAction.Name, names),
        Condition: condition,
        Properties: {
            ...(condition === RunCondition.Variable ? { "Octopus.Step.ConditionVariableExpression": expression } : {}),
            ...(previousStep.Properties["Octopus.Action.TargetRoles"] ? { "Octopus.Action.TargetRoles": previousStep.Properties["Octopus.Action.TargetRoles"] } : {}),
        },
    };
    const updatedStoredAction: StoredAction = { ...targetAction, ParentId: newStep.Id };
    return {
        ...state,
        model: {
            ...state.model,
            steps: {
                byId: {
                    ...state.model.steps.byId,
                    [updatedStep.Id]: updatedStep,
                    [newStep.Id]: newStep,
                },
                allIds: updaters.addAfter(state.model.steps.allIds, updatedStep.Id, newStep.Id),
            },
            actions: {
                ...state.model.actions,
                byId: {
                    ...state.model.actions.byId,
                    [targetAction.Id]: updatedStoredAction,
                },
            },
        },
    };
}
function moveActionIntoStep(state: Readonly<ProcessContextModelState>, actionId: string, stepId: string): ProcessContextModelState {
    const targetAction = state.model.actions.byId[actionId];
    const targetStep = state.model.steps.byId[stepId];
    const previousStep = state.model.steps.byId[targetAction.ParentId];
    const previousSiblings = previousStep.ActionIds.filter((x) => x !== targetAction.Id);
    return {
        ...state,
        model: {
            ...state.model,
            steps: {
                byId: {
                    ...(previousSiblings.length === 0
                        ? updaters.remove(state.model.steps.byId, previousStep.Id)
                        : updaters.patch(state.model.steps.byId, previousStep.Id, (prev) => ({
                            ...prev,
                            ActionIds: previousSiblings,
                        }))),
                    [targetStep.Id]: {
                        ...targetStep,
                        ActionIds: [...targetStep.ActionIds, targetAction.Id],
                    },
                },
                allIds: previousSiblings.length === 0 ? state.model.steps.allIds.filter((x) => x !== previousStep.Id) : state.model.steps.allIds,
            },
            actions: updaters.patchById(state.model.actions, targetAction.Id, (prev) => {
                const result: typeof prev = {
                    ...prev,
                    ParentId: targetStep.Id,
                    Condition: mapRunConditionForChildAction(previousStep.Condition),
                    Properties: {
                        ...prev.Properties,
                        ...(previousStep.Condition === RunCondition.Variable ? { "Octopus.Action.ConditionVariableExpression": previousStep.Properties["Octopus.Step.ConditionVariableExpression"] } : {}),
                    },
                };
                return result;
            }),
        },
    };
}
function runOnChanged(state: Readonly<ProcessContextModelState>, actionId: string, runOn: RunOnDeploymentTarget | RunOnBuiltInWorker | RunOnWorkerPool, stepId: string): ProcessContextModelState {
    return {
        ...state,
        model: {
            ...state.model,
            actions: updaters.patchById(state.model.actions, actionId, (prev) => {
                const result: typeof prev = {
                    ...prev,
                    //If we aren't running on the server or worker pool then we will need to clear the worker pool and workerpool variable etc. to avoid the server defaulting to the wrong thing.
                    ...(isRunOnServerOrWorkerPool(runOn)
                        ? { Container: runOn.container }
                        : {
                            WorkerPoolId: null,
                            WorkerPoolVariable: null,
                            Container: generateDefaultActionContainer(),
                        }),
                    Properties: {
                        ...prev.Properties,
                        "Octopus.Action.RunOnServer": runOn.executionLocation === ExecutionLocation.DeploymentTarget ? "false" : "true",
                    },
                };
                return result;
            }),
            steps: updaters.patchById(state.model.steps, stepId, (prev: StoredStep) => {
                return runOn.executionLocation === ExecutionLocation.OctopusServer || runOn.executionLocation === ExecutionLocation.WorkerPool
                    ? {
                        ...prev,
                        Properties: {
                            ...prev.Properties,
                            "Octopus.Action.TargetRoles": null,
                        },
                    }
                    : prev;
            }),
        },
    };
}
export function determineStepCondition(action: DeploymentActionResource, previousStep: StoredStep) {
    if (action.Condition === RunConditionForAction.Success && previousStep.Condition === RunCondition.Variable) {
        return {
            condition: RunCondition.Variable,
            expression: previousStep.Properties["Octopus.Step.ConditionVariableExpression"],
        };
    }
    return {
        condition: mapChildRunConditionForStep(action.Condition),
        expression: action.Properties["Octopus.Action.ConditionVariableExpression"],
    };
}
function disableAction(state: ActionsState, actionId: string) {
    return setActionMetaProperties(state, actionId, (prev) => ({ ...prev, IsDisabled: true }));
}
function enableAction(state: ActionsState, actionId: string) {
    return setActionMetaProperties(state, actionId, (prev) => ({ ...prev, IsDisabled: false }));
}
function setActionMetaProperties(state: ActionsState, actionId: string, callback: (prev: MetaActionProperties) => MetaActionProperties): ActionsState {
    return updaters.patchById(state, actionId, (prev: StoredAction) => ({
        ...prev,
        ...callback(prev),
    }));
}
const setProcess = (state: Readonly<StoredModelState>, process: IProcessResource): StoredModelState => {
    if (!process) {
        return state;
    }
    const { Steps, ...storedProcess } = process;
    return {
        ...state,
        process: storedProcess,
    };
};
const processReducer: React.Reducer<ProcessContextModelState, ProcessActions> = (state, action) => {
    switch (action.type) {
        case getType(actions.setProcess): {
            return {
                ...state,
                model: setProcess(state.model, action.payload.process),
                mergeModel: INITIAL_MERGED_MODEL_STATE,
            };
        }
        case getType(actions.conflictDetected): {
            return {
                ...state,
                mergeModel: {
                    ...state.mergeModel,
                    processMerged: false,
                    dialogClosed: false,
                    staged: getStoredModelStateFromProcess(action.payload.stagedProcess, action.payload.allPlugins),
                    server: getStoredModelStateFromProcess(action.payload.serverProcess, action.payload.allPlugins),
                },
            };
        }
        case getType(actions.mergedProcess): {
            if (!state.mergeModel.staged.process) {
                return state;
            }
            return {
                ...state,
                model: state.mergeModel.staged,
                mergeModel: {
                    ...INITIAL_MERGED_MODEL_STATE,
                    processMerged: true,
                },
            };
        }
        case getType(actions.discardedChanges): {
            if (!state.mergeModel.server.process) {
                return state;
            }
            return {
                ...state,
                model: state.mergeModel.server,
                mergeModel: INITIAL_MERGED_MODEL_STATE,
            };
        }
        case getType(actions.acceptedClientChanges): {
            if (!state.mergeModel.server.process) {
                return state;
            }
            return {
                ...state,
                model: {
                    ...state.model,
                    process: state.mergeModel.server.process,
                },
                mergeModel: INITIAL_MERGED_MODEL_STATE,
            };
        }
        case getType(actions.mergeDialogClosed): {
            return {
                ...state,
                mergeModel: {
                    ...state.mergeModel,
                    processMerged: true,
                },
            };
        }
    }
    return state;
};
export type ActionCreators = typeof actions;
export type ProcessActions = ActionType<ActionCreators>;
const normalizedReducer: React.Reducer<ProcessContextModelState, ProcessActions> = (state, action) => {
    switch (action.type) {
        case getType(actions.setProcess): {
            const { process: payloadProcess, updateCleanModel } = action.payload;
            const model = getStoredModelStateFromProcess(payloadProcess, action.payload.allPlugins);
            return {
                ...state,
                model,
                cleanModel: updateCleanModel ? cloneDeep(model) : state.cleanModel,
            };
        }
    }
    return state;
};
export const processContextModelStateReducer: React.Reducer<ProcessContextModelState, ProcessActions> = reduceReducers(normalizedReducer, processReducer, stepsReducer);
export const actions = {
    // Process
    setProcess: createAction("SET:PROCESS", (process: IProcessResource, updateCleanModel: boolean, allPlugins: ActionPlugin[]) => ({ process, updateCleanModel, allPlugins }))(),
    // Process Merge (conflicts)
    conflictDetected: createAction("PROCESS:CONFLICT:DETECTED", (serverProcess: IProcessResource, stagedProcess: IProcessResource, allPlugins: ActionPlugin[]) => ({ serverProcess, stagedProcess, allPlugins }))(),
    mergeDialogClosed: createAction("MERGE:DIALOG:CLOSED")(),
    mergedProcess: createAction("PROCESS:MERGED", (process: IProcessResource) => ({ process }))(),
    discardedChanges: createAction("PROCESS:MERGE:DISCARDED")(),
    acceptedClientChanges: createAction("MERGE:ACCEPT:CLIENT:CHANGES")(),
    // Step
    addChildAction: createAction("ADD:CHILD:ACTION", (stepId: string, action: StoredAction) => ({ stepId, action }))(),
    addStep: createAction("ADD:STEP", (step: StoredStep, action: StoredAction) => ({ step, action }))(),
    setStepProperties: createAction("SET:STEP:PROPERTIES", (stepId: string, properties: Partial<ActionProperties>) => ({ stepId, properties }))(),
    setStepMetaProperties: createAction("SET:STEP:META:PROPERTIES", (stepId: string, callback: (prev: MetaStepProperties) => MetaStepProperties) => ({ stepId, callback }))(),
    removeAllSteps: createAction("REMOVE:ALL:STEPS")(),
    disableStep: createAction("DISABLE:STEP", (stepId: string) => ({ stepId }))(),
    enableStep: createAction("ENABLE:STEP", (stepId: string) => ({ stepId }))(),
    reorderSteps: createAction("REORDER:STEPS", (orderedSteps: string[]) => ({ orderedSteps }))(),
    resetPackageRequirementAfterPackageAcquisitionStep: createAction("RESET:PACKAGE:REQUIREMENT:AFTER:PACKAGE:Acquisition:STEP")(),
    // Actions
    removeAction: createAction("REMOVE:ACTION", (stepId: string, actionId: string) => ({ stepId, actionId }))(),
    removeStep: createAction("REMOVE:STEP", (stepId: string) => ({ stepId }))(),
    setActionInputs: createAction("SET:ACTION:INPUTS", (actionId: string, inputs: StepPackageInputs) => ({ actionId, inputs }))(),
    setMigratedActionProperties: createAction("SET:ACTION:MIGRATED:PROPERTIES", (actionId: string, inputs: StepPackageInputs, plugin: ActionPlugin, stepPackageVersion: string) => ({ actionId, inputs, plugin, stepPackageVersion }))(),
    setActionProperties: createAction("SET:ACTION:PROPERTIES", (actionId: string, properties: Partial<ActionProperties>) => ({ actionId, properties }))(),
    removeActionProperties: createAction("REMOVE:ACTION:PROPERTIES", (actionId: string, propertyKeys: string[]) => ({ actionId, propertyKeys }))(),
    setActionMetaProperties: createAction("SET:ACTION:META:PROPERTIES", (actionId: string, callback: (prev: MetaActionProperties) => MetaActionProperties) => ({ actionId, callback }))(),
    disableAction: createAction("DISABLE:ACTION", (actionId: string) => ({ actionId }))(),
    enableAction: createAction("ENABLE:ACTION", (actionId: string) => ({ actionId }))(),
    moveActionIntoStep: createAction("ACTION:MOVEINTO:STEP", (actionId: string, stepId: string) => ({ actionId, stepId }))(),
    moveActionOutOfStep: createAction("ACTION:MOVEOUT:STEP", (actionId: string) => ({ actionId }))(),
    reorderChildActions: createAction("STEP:ACTION:REORDER", (stepId: string, orderedActions: string[]) => ({ stepId, orderedActions }))(),
    runOnChanged: createAction("ACTION:RUNON:CHANGED", (actionId: string, stepId: string, runOn: RunOn) => ({ actionId, stepId, runOn }))(),
};
export type ProcessModelSelectorsType = SelectorType<typeof processModelSelectors>;
export interface ProcessStateSelectors extends ProcessModelSelectorsType {
    hasValidProcess: () => boolean;
    getCleanModel: () => Readonly<StoredModelState>;
    getModel: () => Readonly<StoredModelState>;
    getProcessesForMerge: ReturnType<typeof getProcessesForMerge>;
    getProcessType: ReturnType<typeof getProcessType>;
    hasStepsInCleanModel: () => boolean;
}
export function nextUniqueName<TModel>(name: string, values: Dictionary<TModel>) {
    let nextName = name;
    let i = 1;
    // eslint-disable-next-line no-constant-condition
    while (true) {
        if (!values[nextName]) {
            break;
        }
        nextName = nextName + " " + i;
        i = i++;
    }
    return nextName;
}
export function getStepDefaults(isStepPackageStep: boolean = false) {
    return {
        Condition: RunCondition.Success,
        StartTrigger: StartTrigger.StartAfterPrevious,
        PackageRequirement: isStepPackageStep ? PackageRequirement.AfterPackageAcquisition : PackageRequirement.LetOctopusDecide,
    };
}
export function createDefaultStepResource(action: () => DeploymentActionResource, isStepPackageStep: boolean): DeploymentStepResource {
    const id = generateGuid();
    return {
        Id: id,
        Name: "",
        Properties: {},
        ...getStepDefaults(isStepPackageStep),
        Actions: [action()],
        Links: {},
    };
}
export function createDefaultActionResource(actionType: string, version: string | undefined): DeploymentActionResource {
    return {
        Id: generateGuid(),
        ActionType: actionType,
        Name: "",
        Notes: null,
        Environments: [],
        ExcludedEnvironments: [],
        Channels: [],
        TenantTags: [],
        Properties: {},
        Packages: [],
        IsDisabled: false,
        WorkerPoolId: "",
        WorkerPoolVariable: "",
        Container: {
            Image: null,
            FeedId: null,
        },
        CanBeUsedForProjectVersioning: false,
        IsRequired: false,
        Links: {},
        ...(version && { StepPackageVersion: version }),
    };
}
export function mapChildRunConditionForStep(condition: RunConditionForAction | undefined): RunCondition {
    switch (condition) {
        case RunConditionForAction.Success:
            return RunCondition.Success;
        case RunConditionForAction.Variable:
            return RunCondition.Variable;
    }
    return RunCondition.Success;
}
export function mapRunConditionForChildAction(condition: RunCondition | undefined): RunConditionForAction {
    switch (condition) {
        case RunCondition.Always:
            return RunConditionForAction.Success;
        case RunCondition.Success:
            return RunConditionForAction.Success;
        case RunCondition.Variable:
            return RunConditionForAction.Variable;
        case RunCondition.Failure:
            return RunConditionForAction.Success;
    }
    return RunConditionForAction.Success;
}
