/* eslint-disable @typescript-eslint/init-declarations */
import { css } from "@emotion/css";
import { Callout, Divider } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens/src/generated/globals";
import { Permission, type CreateDeploymentFreezeCommand, type DeploymentFreezeDetailEnvironment, type DeploymentFreezeDetailProject, type GetDeploymentFreezeDetailBffResponse, type ProjectSummaryResource } from "@octopusdeploy/octopus-server-client";
import type { DeploymentFreezeDetailStatus } from "@octopusdeploy/octopus-server-client/src/resources/deploymentFreezes/getDeploymentFreezeDetailBffResponse";
import type { ModifyDeploymentFreezeCommand } from "@octopusdeploy/octopus-server-client/src/resources/deploymentFreezes/modifyDeploymentFreezeCommand";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import { DeploymentFreezeScopeTable } from "app/areas/configuration/components/DeploymentFreezes/DeploymentFreezeScopeTable";
import type { Moment } from "moment";
import moment from "moment";
import pluralize from "pluralize";
import React from "react";
import { FreezeDateTimePicker } from "~/areas/configuration/components/DeploymentFreezes/FreezeDateTimePicker";
import ConnectFreezeProjectsDialog from "~/areas/configuration/components/DeploymentFreezes/ProjectScopeConnectDialog/ConnectFreezeProjectsDialog";
import rollForward from "~/areas/projects/components/Releases/Deployments/NowOrLater/rollFoward";
import { repository } from "~/clientInstance";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import FormBaseComponent from "~/components/FormBaseComponent";
import type { FormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import FormPaperLayout from "~/components/FormPaperLayout";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { ExpandableFormSection, ExpansionButtons, FormSectionHeading, Summary, required } from "~/components/form/index";
import Text from "~/primitiveComponents/form/Text/Text";
import DateFormatter from "~/utils/DateFormatter/index";
interface CreateDeploymentFreezeProps {
    create: true;
    deploymentFreezeId?: string;
}
interface EditDeploymentFreezeProps {
    create: false;
    deploymentFreezeId: string;
}
type DeploymentFreezeProps = CreateDeploymentFreezeProps | EditDeploymentFreezeProps;
export const EditDeploymentFreeze = (props: DeploymentFreezeProps) => {
    return <EditDeploymentFreezeInternalPage {...props}/>;
};
interface EditDeploymentFreezeState extends FormBaseComponentState<DeploymentFreezeModel> {
    isLoaded: boolean;
    redirectTo: LinkHref;
    currentPageIndex: number;
    projectSummaries: ProjectSummaryResource[];
}
export type DeploymentFreezeModel = FreezeDisplayProps;
interface FreezeDisplayProps {
    id: string;
    name: string;
    startDate: Moment;
    endDate: Moment;
    status: DeploymentFreezeDetailStatus;
    userTimezone: string;
    userUtcOffset: number;
    scope: DeploymentFreezeDetailProject[];
}
const currentTime = new Date();
const scheduledTime = rollForward(moment(currentTime), 10);
const expiryTime = rollForward(scheduledTime, 30);
const defaultFreezeModel: DeploymentFreezeModel = {
    id: "",
    name: "",
    startDate: scheduledTime,
    endDate: expiryTime,
    status: undefined,
    userTimezone: moment(currentTime).format("[GMT] Z"),
    userUtcOffset: moment(currentTime).utcOffset(),
    scope: [],
};
export type EditDeploymentFreezeInternalProps = DeploymentFreezeProps;
class EditDeploymentFreezeInternalPage extends FormBaseComponent<EditDeploymentFreezeInternalProps, EditDeploymentFreezeState, DeploymentFreezeModel> {
    constructor(props: EditDeploymentFreezeInternalProps) {
        super(props);
        this.state = {
            isLoaded: false,
            model: defaultFreezeModel,
            cleanModel: defaultFreezeModel,
            redirectTo: "",
            projectSummaries: [],
            currentPageIndex: 0,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            let model: DeploymentFreezeModel;
            if (this.props.deploymentFreezeId) {
                const response = await repository.DeploymentFreezes.getDetailBff(this.props.deploymentFreezeId);
                model = this.buildModel(response);
            }
            else {
                model = defaultFreezeModel;
            }
            this.setState({
                isLoaded: true,
                model: model,
                cleanModel: model,
            });
        });
    }
    genericValidationMessage = "Validation failed. Please check the errors messages below.";
    handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const { id, name, startDate, endDate, scope } = this.state.model;
            const projectEnvironmentScope: {
                [id: string]: string[];
            } = {};
            const projectsWithoutEnvironments: string[] = [];
            scope.forEach((detail) => {
                if (detail.Environments.length === 0) {
                    projectsWithoutEnvironments.push(detail.Name);
                }
                projectEnvironmentScope[detail.Id] = detail.Environments.map((e) => e.Id);
            });
            if (Object.keys(projectEnvironmentScope).length === 0) {
                this.setValidationErrors(this.genericValidationMessage, { Scope: "Please assign at least 1 project to this freeze" });
                return;
            }
            if (projectsWithoutEnvironments.length > 0) {
                const projects = projectsWithoutEnvironments.length < 20 ? projectsWithoutEnvironments.join(", ") : `${projectsWithoutEnvironments.slice(0, 19).join(", ")} and ${projectsWithoutEnvironments.length - 20} more`;
                this.setValidationErrors(this.genericValidationMessage, { Scope: `Please select at least 1 environment for the following ${pluralize("project", projectsWithoutEnvironments.length)}: ${projects}` });
                return;
            }
            if (this.props.create) {
                const createCommand: CreateDeploymentFreezeCommand = {
                    Id: id,
                    Name: name,
                    Start: startDate,
                    End: endDate,
                    ProjectEnvironmentScope: projectEnvironmentScope,
                };
                const response = await repository.DeploymentFreezes.create(createCommand);
                this.setState({
                    model: defaultFreezeModel,
                    cleanModel: defaultFreezeModel,
                    redirectTo: links.deploymentFreezesEditPage.generateUrl({ deploymentFreezeId: response.Id }),
                });
            }
            else {
                const modifyCommand: ModifyDeploymentFreezeCommand = {
                    Id: id,
                    Name: name,
                    Start: startDate,
                    End: endDate,
                    ProjectEnvironmentScope: projectEnvironmentScope,
                };
                const response = await repository.DeploymentFreezes.modify(modifyCommand);
                const updatedFreeze = await repository.DeploymentFreezes.getDetailBff(response.Id);
                this.setState({
                    model: this.buildModel(updatedFreeze),
                    cleanModel: this.buildModel(updatedFreeze),
                });
            }
        });
    };
    handleDeploymentFreezeDelete = async () => {
        await this.doBusyTask(async () => {
            await repository.DeploymentFreezes.delete(this.state.model.id);
            this.setState({ redirectTo: links.deploymentFreezesListPage.generateUrl() });
        });
        return true;
    };
    buildModel(response: GetDeploymentFreezeDetailBffResponse): DeploymentFreezeModel {
        const model = {
            id: response.Id,
            name: response.Name,
            startDate: moment(response.Start),
            endDate: moment(response.End),
            status: response.Status,
            userTimezone: moment(currentTime).format("[GMT] Z"),
            userUtcOffset: moment(currentTime).utcOffset(),
            scope: response.Project,
        };
        return model;
    }
    setProjectSummariesState(projectSummaries: ProjectSummaryResource[]) {
        this.setState({ projectSummaries });
    }
    onConnected = (model: DeploymentFreezeModel, numberOfProjectsConnected: number) => {
        this.setState({ model });
    };
    onRemove = (project: DeploymentFreezeDetailProject) => {
        const updatedScope = this.state.model.scope.filter((scope) => scope.Id !== project.Id);
        this.setState({ model: { ...this.state.model, scope: updatedScope } });
    };
    onEnvironmentUpdate = (project: DeploymentFreezeDetailProject, selectedEnvironments: DeploymentFreezeDetailEnvironment[]) => {
        const updatedScope = this.state.model.scope.map((scope) => {
            if (scope.Id === project.Id) {
                return { ...scope, Environments: selectedEnvironments };
            }
            return scope;
        });
        this.setState({ model: { ...this.state.model, scope: updatedScope } });
        return Promise.resolve(true);
    };
    renderCallout() {
        const { startDate, endDate, status } = this.state.model;
        switch (status) {
            case "Active":
                return (<Callout title="This deployment freeze is currently active" type={"success"}>
                        Deployments to scoped projects and environments are frozen until {endDate.format("hh:mm")} on {endDate.format("Do MMMM YYYY")}
                    </Callout>);
            case "Scheduled":
                return (<Callout title="This deployment freeze is scheduled" type={"information"}>
                        Deployments to the scoped projects and environments will be frozen from {startDate.format("hh:mm")} on {startDate.format("Do MMMM YYYY")}
                    </Callout>);
            case "Expired":
                return (<Callout title="This deployment freeze has expired" type={"warning"}>
                        Deployments to the scoped projects and environments resumed at {endDate.format("hh:mm")} on {endDate.format("Do MMMM YYYY")}
                    </Callout>);
            default:
                return null;
        }
    }
    getOverflowActions() {
        const overflowActions = [];
        if (!this.props.create) {
            overflowActions.push(OverflowMenuItems.deleteItem("Remove", `Are you sure you want to delete '${this.state.model.name}'?`, () => this.handleDeploymentFreezeDelete(), <div>
                        <p>Do you wish to continue?</p>
                    </div>, { permission: Permission.DeploymentFreezeAdminister }));
        }
        return overflowActions;
    }
    buildPickerSummary = () => Summary.summary(`This deployment freeze is scheduled to start on ${DateFormatter.dateToLongFormat(this.state.model.startDate)}`);
    buildNameSummary = () => (this.state.model.name.length > 0 ? Summary.summary(this.state.model.name) : Summary.placeholder("No deployment freeze name provided"));
    buildProjectScopeSummary = () => (this.state.model.scope.length > 0 ? `${pluralize("project", this.state.model.scope.length, true)} assigned` : "Assign projects to this deployment freeze.");
    getTitle = () => (this.props.create ? "New Deployment Freeze" : this.state.model.name);
    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true}/>;
        }
        return (<React.Fragment>
                {this.state.isLoaded && (<FormPaperLayout breadcrumbTitle={"Deployment Freezes"} breadcrumbPath={links.deploymentFreezesListPage.generateUrl()} title={this.getTitle()} busy={this.state.busy} errors={this.errors} onSaveClick={this.handleSaveClick} model={this.state.model} cleanModel={this.state.cleanModel} overFlowActions={this.getOverflowActions()} savePermission={{ permission: Permission.DeploymentFreezeAdminister }}>
                        {this.renderCallout()}

                        <ExpansionButtons containerKey="FreezeEdit" errors={this.errors?.fieldErrors} expandAllOnMount={this.props.create}/>
                        <ExpandableFormSection key="name" errorKey="name" title="Name" summary={this.buildNameSummary()} help={helpText.name.help} containerKey="FreezeEdit" isExpandedByDefault={true}>
                            <Text value={this.state.model.name} label="Deployment Freeze Name" validate={required("Please provide a name for this deployment freeze")} onChange={(value) => {
                    this.setState({ model: { ...this.state.model, name: value } });
                }}/>
                        </ExpandableFormSection>
                        <ExpandableFormSection key="schedule" errorKey="schedule" title="Schedule" summary={this.buildPickerSummary()} help={helpText.schedule.help} containerKey="FreezeEdit" isExpandedByDefault={true}>
                            <FreezeDateTimePicker updateScheduledDatesOnModel={(start, end) => this.setState({ model: { ...this.state.model, startDate: start, endDate: end } })} initialStartDate={this.state.model.startDate} initialEndDate={this.state.model.endDate}/>
                        </ExpandableFormSection>
                        <FormSectionHeading title="Scope" key="scope"/>
                        <div>
                            <div className={styles.connectProjectsRow}>
                                <span>{this.buildProjectScopeSummary()}</span>
                                <OpenDialogButton label="Assign Projects">
                                    <ConnectFreezeProjectsDialog deploymentFreeze={this.state.model} onConnected={(freeze: DeploymentFreezeModel, numberOfProjectsConnected: number) => this.onConnected(freeze, numberOfProjectsConnected)} alreadyConnectedProjectsIds={this.state.model.scope.map((s) => s.Id)}/>
                                </OpenDialogButton>
                            </div>
                            {this.state.model.scope.length > 0 && (<React.Fragment>
                                    <Divider />
                                    <DeploymentFreezeScopeTable key="scopeTable" projectDetails={this.state.model.scope} onEnvironmentUpdate={this.onEnvironmentUpdate} onRemove={this.onRemove}/>
                                </React.Fragment>)}
                        </div>
                    </FormPaperLayout>)}
            </React.Fragment>);
    }
    static displayName = "EditDeploymentFreezeInternalPage";
}
const helpText = {
    name: {
        help: "Provide a name for this deployment freeze.",
    },
    schedule: {
        help: "Specify the schedule for this deployment freeze.",
    },
};
const styles = {
    connectProjectsRow: css({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: space[16],
    }),
};
