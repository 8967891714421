import { IconButton, StarOffIcon, StarOnIcon } from "@octopusdeploy/design-system-components";
import React, { useState } from "react";
export function useOptimisticIsFavourite(isFavourite: boolean, setIsFavourite: (isFavourite: boolean) => Promise<boolean>) {
    const [optimisticIsFavourite, setOptimisticIsFavourite] = useState(isFavourite);
    const optimisticSetIsFavourite = async (nextIsFavourite: boolean) => {
        const prevIsFavourite = isFavourite;
        setOptimisticIsFavourite(nextIsFavourite);
        let success = false;
        try {
            success = await setIsFavourite(nextIsFavourite);
            if (!success) {
                setOptimisticIsFavourite(prevIsFavourite);
            }
        }
        catch (e) {
            setOptimisticIsFavourite(prevIsFavourite);
            throw e;
        }
        return success;
    };
    const isPendingUpdate = isFavourite !== optimisticIsFavourite;
    return { optimisticIsFavourite, optimisticSetIsFavourite, isPendingUpdate };
}
interface ProjectFavouriteIconProps {
    isFavourite: boolean;
    setIsFavourite: (isFavourite: boolean) => Promise<boolean>;
    isDisabled: boolean;
}
export function ProjectFavouriteIconButton({ isFavourite, setIsFavourite, isDisabled }: ProjectFavouriteIconProps) {
    return <IconButton disabled={isDisabled} customIcon={isFavourite ? <StarOnIcon /> : <StarOffIcon />} onClick={() => setIsFavourite(!isFavourite)}/>;
}
interface OptimisticProjectFavouriteIconProps {
    isFavourite: boolean;
    setIsFavourite: (isFavourite: boolean) => Promise<boolean>;
}
export function OptimisticProjectFavouriteIconButton({ isFavourite, setIsFavourite }: OptimisticProjectFavouriteIconProps) {
    const { optimisticIsFavourite, optimisticSetIsFavourite, isPendingUpdate } = useOptimisticIsFavourite(isFavourite, setIsFavourite);
    return <ProjectFavouriteIconButton isFavourite={optimisticIsFavourite} setIsFavourite={optimisticSetIsFavourite} isDisabled={isPendingUpdate}/>;
}
