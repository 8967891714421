enum ActionKeys {
    // noinspection SpellCheckingInspection
    FORM_MOUNTED = "FORM_MOUNTED",
    FORM_DIRTYCHANGED = "FORM_DIRTYCHANGED",
    OTHER_ACTION = "__any_other_action_type__"
}
interface IOnSaveClickAction {
    type: ActionKeys.FORM_MOUNTED;
    saveLabel?: string;
    onSaveClick?: () => Promise<void>;
}
interface IOtherAction {
    type: ActionKeys.OTHER_ACTION;
}
interface IDirtyChangedAction {
    type: ActionKeys.FORM_DIRTYCHANGED;
    dirty: boolean;
}
type ActionTypes = IOnSaveClickAction | IOtherAction | IDirtyChangedAction;
export function createFormMountedAction(onSaveClick: (() => Promise<void>) | undefined, saveLabel: string | undefined): IOnSaveClickAction {
    return { type: ActionKeys.FORM_MOUNTED, onSaveClick, saveLabel };
}
export function createFormDirtyChangedAction(dirty: boolean): IDirtyChangedAction {
    return { type: ActionKeys.FORM_DIRTYCHANGED, dirty };
}
export interface FormReducerState {
    onSaveClick?: () => Promise<void>;
    saveLabel?: string;
    dirty: boolean;
}
const formReducer = (state: FormReducerState = { onSaveClick: undefined, saveLabel: undefined, dirty: false }, action: ActionTypes): FormReducerState => {
    switch (action.type) {
        case ActionKeys.FORM_MOUNTED:
            return { ...state, onSaveClick: action.onSaveClick, saveLabel: action.saveLabel };
        case ActionKeys.FORM_DIRTYCHANGED:
            return state.dirty === action.dirty ? state : { ...state, dirty: action.dirty };
        default:
            return state;
    }
};
export default formReducer;
