import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { Action, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import { useProjectContext } from "~/areas/projects/context";
import InternalLink from "~/components/Navigation/InternalLink";
import { PermissionCheck } from "~/components/PermissionCheck";
import { useIsPageHeaderVNextEnabled } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
export type CreateReleaseButtonProps = {
    spaceId: string;
    projectId: string;
    projectSlug: string;
    disabled?: boolean;
};
export const CreateReleaseButton: React.FC<CreateReleaseButtonProps> = ({ spaceId, projectId, projectSlug, disabled }) => {
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectId);
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabled();
    return (<PermissionCheck permission={Permission.ReleaseCreate} project={projectId} tenant="*">
            <div>
                {/* Note: We can't just use a NavigationButton here, because once you click it, it renders a redirect and disappears :) */}
                <InternalLink to={links.createReleasePage.generateUrl({ spaceId, projectSlug })} onClick={() => dispatchAction("Create a release", { resource: "Create Release", action: Action.Add })}>
                    <ActionButton type={isPageHeaderVNextEnabled ? ActionButtonType.Secondary : ActionButtonType.CreateRelease} label="Create Release" disabled={disabled}/>
                </InternalLink>
            </div>
        </PermissionCheck>);
};
CreateReleaseButton.displayName = "CreateReleaseButton"
export const ContextualCreateReleaseButton: React.FC<Omit<CreateReleaseButtonProps, "projectId">> = (props) => {
    const project = useProjectContext();
    return <CreateReleaseButton projectId={project.state.model.Id} {...props}/>;
};
ContextualCreateReleaseButton.displayName = "ContextualCreateReleaseButton"
export default ContextualCreateReleaseButton;
