import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import CopyValueToClipboard from "~/components/CopyToClipboardButton/CopyValueToClipboard";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Note } from "~/components/form";
type OtherIssuerServiceAccountDetailsProps = {
    serviceAccountId: string;
};
export function OtherIssuerServiceAccountDetails({ serviceAccountId }: OtherIssuerServiceAccountDetailsProps) {
    const styles = {
        serviceAccountIdContent: css({
            display: "flex",
            gap: space[1],
            alignItems: "center",
        }),
    };
    const isOidcIdentitiesCustomAudienceEnabled = isFeatureToggleEnabled("OidcIdentitiesCustomAudienceFeatureToggle");
    const note = isOidcIdentitiesCustomAudienceEnabled ? (<>
            Copy the Service Account Id above and set it as the audience of the token exchange request. If a custom audience is not configured also set this as the <code>aud</code> property of the OIDC token.{" "}
        </>) : (<>Copy the Service Account Id above and set it as the audience of the token exchange request and OIDC token. </>);
    return (<>
            <h4>Other Issuers</h4>
            <div className={styles.serviceAccountIdContent}>
                <div>Service Account Id:</div>
                <CopyValueToClipboard value={serviceAccountId}/>
            </div>
            <Note>
                {note}
                <ExternalLink href="ServiceAccountOidcIdentitiesOtherIssuers">Learn more about configuring OIDC identities for other issuers.</ExternalLink>
            </Note>
        </>);
}
