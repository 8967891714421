/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { NavigationButton, NavigationButtonType } from "@octopusdeploy/design-system-components";
import type { ResourceCollection, RunbookResource } from "@octopusdeploy/octopus-server-client";
import { HasRunbooksInGit, Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { ProjectGitHubAppAuthCheck } from "~/areas/library/components/GitConnections/GitHubAppAuthCheck";
import { SampleProjectTourContext } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
import type { SampleProjectTourContextProps } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
import { SampleProjectTourStep } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTourStep";
import { ProjectPageLayout } from "~/areas/projects/components/ProjectPageLayout";
import type { ProjectContextProps } from "~/areas/projects/context";
import { useProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import MarkdownDescription from "~/components/MarkdownDescription";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { PrimaryPageAction } from "~/components/PageActions/PageActions";
import PagingList from "~/components/PagingList";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { useRequiredContext } from "~/hooks/index";
import ListItem from "~/primitiveComponents/dataDisplay/ListItem";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import type { SpaceAwareNavigation } from "../../../../components/Navigation/SpaceAwareNavigation/SpaceAwareNavigation";
import { ProjectStatus } from "../ProjectStatus/ProjectStatus";
import AddRunbook from "./AddRunbook";
import RunbooksOnboarding from "./RunbooksOnboarding";
interface RunbooksLayoutState extends DataBaseComponentState {
    runbooks: ResourceCollection<RunbookResource>;
}
class RunbooksLayoutList extends PagingList<RunbookResource> {
}
interface RunbooksLayoutProps {
    projectContext: ProjectContextProps;
    onboardingContext: SampleProjectTourContextProps;
    navigation: SpaceAwareNavigation;
}
class RunbooksLayoutInternal extends DataBaseComponent<RunbooksLayoutProps, RunbooksLayoutState> {
    constructor(props: RunbooksLayoutProps) {
        super(props);
        this.state = {
            runbooks: null!,
        };
    }
    componentDidMount() {
        this.reload(() => this.props.onboardingContext.continueSampleProjectTour());
    }
    reload(onReloadDone?: () => void) {
        // noinspection JSIgnoredPromiseFromCall
        this.doBusyTask(async () => {
            const project = this.props.projectContext.state.model;
            const runbooks = await repository.Projects.getRunbooks(project, { skip: 0, take: 30 });
            this.setState({
                runbooks,
            });
            if (onReloadDone)
                onReloadDone();
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad(this.props.projectContext.state.model.IsVersionControlled) });
    }
    render() {
        const project = this.props.projectContext.state.model;
        const addRunbookPageAction: PrimaryPageAction = {
            type: "dialog",
            label: "Add Runbook",
            hasPermissions: isAllowed({ permission: Permission.RunbookEdit, project: project.Id, wildcard: true }),
            renderDialog: ({ isOpen }) => (<Dialog open={isOpen}>
                    <AddRunbook projectId={project.Id} onProcessCreated={async (id) => {
                    this.props.navigation.navigate(links.runbookRootRedirect.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: id }));
                }}/>
                </Dialog>),
        };
        return (<ProjectPageLayout busy={this.state.busy} errors={this.errors} title="Runbooks" breadcrumbTitle={this.props.projectContext.state.model.Name} primaryAction={addRunbookPageAction} statusSection={<ProjectStatus doBusyTask={this.doBusyTask}/>}>
                {HasRunbooksInGit(project.PersistenceSettings) && <ProjectGitHubAppAuthCheck project={project} permission={Permission.RunbookView}/>}
                {this.state.runbooks && this.state.runbooks.Items.length === 0 && <RunbooksOnboarding />}
                {this.state.runbooks && this.state.runbooks.Items.length > 0 && (<RunbooksLayoutList initialData={this.state.runbooks} onRow={(item) => this.buildRow(item)} onRowRedirectUrl={(runbook: RunbookResource) => links.runbookRootRedirect.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id })} onRowAccessibleName={(runbook: RunbookResource) => `${runbook.Name}`} onFilter={this.filter} filterSearchEnabled={true} apiSearchParams={["partialName"]} filterHintText="Filter by name..." listWrapper={(children: React.ReactNode) => <SampleProjectTourStep stepId={"Runbooks"}>{children}</SampleProjectTourStep>}/>)}
            </ProjectPageLayout>);
    }
    private filter(filter: string, resource: RunbookResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }
    private clonePermission(): PermissionCheckProps {
        const project = this.props.projectContext.state.model;
        return {
            permission: Permission.RunbookEdit,
            projectGroup: project && project.ProjectGroupId,
            wildcard: true,
        };
    }
    private buildRow(runbook: RunbookResource) {
        const project = this.props.projectContext.state.model;
        const runbookDescription = runbook.Description && <MarkdownDescription markup={runbook.Description}/>;
        const CloneDialog = () => <AddRunbook projectId={project.Id} onProcessCreated={async (id) => this.reload()} cloneId={runbook.Id}/>;
        const overFlowActions: Array<MenuItem | MenuItem[]> = [];
        overFlowActions.push(OverflowMenuItems.dialogItem("Clone", <CloneDialog />, this.clonePermission()));
        overFlowActions.push([
            OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ projects: [project.Id], documentTypes: ["Runbooks"], regardingAny: [runbook.Id] }), {
                permission: Permission.EventView,
                wildcard: true,
            }),
        ]);
        const runAction = isAllowed({ permission: Permission.RunbookRunCreate, project: project.Id, projectGroup: project.ProjectGroupId, wildcard: true }) ? (<NavigationButton label="Run..." href={links.runbookRunNowPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id })} type={NavigationButtonType.Secondary}/>) : null;
        return (<ListItem overflowMenuItems={overFlowActions} secondaryAction={runAction!}>
                <ListTitle>{runbook.Name}</ListTitle>
                {runbookDescription}
            </ListItem>);
    }
    static displayName = "RunbooksLayoutInternal";
}
export const RunbooksPage: React.FC = () => {
    const navigation = useSpaceAwareNavigation();
    const projectContext = useProjectContext();
    const onboardingContext = useRequiredContext(SampleProjectTourContext);
    return <RunbooksLayoutInternal projectContext={projectContext} onboardingContext={onboardingContext} navigation={navigation}/>;
};
RunbooksPage.displayName = "RunbooksPage"
