import type { BranchSpecifier, ProjectResource, ProjectSummary, ProjectSettingsMetadata } from "@octopusdeploy/octopus-server-client";
import { HasVariablesInGit, Permission, ShouldUseDefaultBranch } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import { compact } from "lodash";
import * as React from "react";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import type { NavItem } from "~/components/NavigationSidebarLayout/index";
import { Navigation } from "~/components/NavigationSidebarLayout/index";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import { IsAllowedToSeeDeploymentsOverview } from "../ProjectsRoutes/ProjectToOverviewRedirect";
import { GitProjectLink, GitProjectLinkWithErrorStatus, IndentedProjectLink } from "./GitProjectLink";
export function ProjectLinks(projectSlug: string, branch: BranchSpecifier, project: Readonly<ProjectResource>, projectSummary: ProjectSummary, hasConnectionError: boolean, projectMetadata: ProjectSettingsMetadata[]) {
    const projectId = project.Id;
    const spaceId = project.SpaceId;
    const navigationLinks: Array<NavItem | null> = [];
    const hasNoProcessOrRunbooks = projectSummary.HasDeploymentProcess === false && projectSummary.HasRunbooks === false;
    const deploymentsSubLinks: Array<NavItem | null> = [];
    deploymentsSubLinks.push(NavigationSubItem("Overview", links.deploymentsPage.generateUrl({ spaceId, projectSlug }), true, {
        permission: Permission.ReleaseView,
        project: projectId,
        tenant: "*",
    }));
    deploymentsSubLinks.push(GitNavigationItem("Process", links.deploymentProcessPage.generateUrl({ spaceId, projectSlug }), ShouldUseDefaultBranch(branch) ? links.deploymentProcessPage.generateUrl({ spaceId, projectSlug }) : links.branchDeploymentProcessPage.generateUrl({ spaceId, projectSlug, branchName: branch }), undefined, {
        permission: Permission.ProcessView,
        project: projectId,
        tenant: "*",
    }));
    deploymentsSubLinks.push(NavigationSubItem("Channels", links.channelsPage.generateUrl({ spaceId, projectSlug }), undefined, {
        permission: Permission.ProcessView,
        project: projectId,
        tenant: "*",
    }));
    deploymentsSubLinks.push(NavigationSubItem("Releases", links.releasesPage.generateUrl({ spaceId, projectSlug }), undefined, {
        permission: Permission.ReleaseView,
        project: projectId,
        tenant: "*",
    }));
    deploymentsSubLinks.push(NavigationSubItem("Triggers", links.deploymentTriggersPage.generateUrl({ spaceId, projectSlug }), undefined, {
        permission: Permission.TriggerView,
        project: projectId,
    }));
    deploymentsSubLinks.push(GitNavigationItem("Settings", links.deploymentProcessSettingsPage.generateUrl({ spaceId, projectSlug }), ShouldUseDefaultBranch(branch) ? links.deploymentProcessSettingsPage.generateUrl({ spaceId, projectSlug }) : links.branchDeploymentProcessSettingsPage.generateUrl({ spaceId, projectSlug, branchName: branch })));
    if (IsAllowedToSeeDeploymentsOverview(projectId) && deploymentsSubLinks.length > 0) {
        navigationLinks.push(Navigation.navGroup("Deployments", links.deploymentsPage.generateUrl({ spaceId, projectSlug }), compact(deploymentsSubLinks), undefined, hasNoProcessOrRunbooks || projectSummary.HasDeploymentProcess)); // Strip any that failed permission checks.
    }
    const operationsSubLinks: Array<NavItem | null> = [];
    operationsSubLinks.push(NavigationSubItem("Overview", links.projectOperationsPage.generateUrl({ spaceId, projectSlug }), true, {
        permission: Permission.RunbookView,
        project: projectId,
        wildcard: true,
    }));
    operationsSubLinks.push(NavigationSubItem("Runbooks", links.projectRunbooksPage.generateUrl({ spaceId, projectSlug }), undefined, {
        permission: Permission.RunbookView,
        project: projectId,
        wildcard: true,
    }));
    operationsSubLinks.push(NavigationSubItem("Triggers", links.projectRunbookTriggersPage.generateUrl({ spaceId, projectSlug }), undefined, {
        permission: Permission.TriggerView,
        project: projectId,
    }));
    navigationLinks.push(Navigation.navGroup("Operations", links.projectOperationsPage.generateUrl({ spaceId, projectSlug }), compact(operationsSubLinks), // Strip any that failed permission checks.
    {
        permission: Permission.RunbookView,
        project: projectId,
        wildcard: true,
    }, hasNoProcessOrRunbooks || projectSummary.HasRunbooks));
    const variableSubLinks: Array<NavItem | null> = [];
    const variableLinksPermissions = {
        permission: Permission.VariableView,
        project: projectId,
        wildcard: true,
    };
    variableSubLinks.push(GitVariablesNavigationItem("Project", links.variablesPage.generateUrl({ spaceId, projectSlug }), ShouldUseDefaultBranch(branch) ? links.variablesPage.generateUrl({ spaceId, projectSlug }) : links.branchVariablesPage.generateUrl({ spaceId, projectSlug, branchName: branch }), true, variableLinksPermissions));
    variableSubLinks.push(NavigationSubItem("Tenant", links.projectTenantProjectTemplatesPage.generateUrl({ spaceId, projectSlug })));
    variableSubLinks.push(NavigationSubItem("Library Sets", links.libraryVariablesPage.generateUrl({ spaceId, projectSlug })));
    variableSubLinks.push(GitVariablesNavigationItem("All", links.allVariablesPage.generateUrl({ spaceId, projectSlug }), ShouldUseDefaultBranch(branch) ? links.allVariablesPage.generateUrl({ spaceId, projectSlug }) : links.branchAllVariablesPage.generateUrl({ spaceId, projectSlug, branchName: branch }), undefined, variableLinksPermissions));
    variableSubLinks.push(GitVariablesNavigationItem("Preview", links.variablesPreviewPage.generateUrl({ spaceId, projectSlug }), ShouldUseDefaultBranch(branch) ? links.variablesPreviewPage.generateUrl({ spaceId, projectSlug }) : links.branchVariablesPreviewPage.generateUrl({ spaceId, projectSlug, branchName: branch }), undefined, variableLinksPermissions));
    navigationLinks.push(Navigation.navGroup("Variables", ShouldUseDefaultBranch(branch) || !HasVariablesInGit(project.PersistenceSettings) ? links.variablesPage.generateUrl({ spaceId, projectSlug }) : links.branchVariablesPage.generateUrl({ spaceId, projectSlug, branchName: branch }), compact(variableSubLinks), // Strip any that failed permission checks.
    variableLinksPermissions));
    navigationLinks.push(Navigation.navItem("Tenants", links.projectTenantsPage.generateUrl({ spaceId, projectSlug }), undefined, {
        permission: Permission.TenantView,
        project: projectId,
        projectGroup: "*",
        tenant: "*",
    }));
    navigationLinks.push(Navigation.navItem("Tasks", links.projectTasksPage.generateUrl({ spaceId, projectSlug }), undefined, {
        permission: Permission.TaskView,
        project: projectId,
        projectGroup: "*",
        tenant: "*",
    }));
    const insightsSubLinks = [
        NavigationSubItem("Overview", links.projectInsightsOverviewPage.generateUrl({ spaceId, projectSlug }), true),
        NavigationSubItem("Deployment frequency", links.projectInsightsFrequencyPage.generateUrl({ spaceId, projectSlug })),
        NavigationSubItem("Deployment lead time", links.projectInsightsLeadTimePage.generateUrl({ spaceId, projectSlug })),
        NavigationSubItem("Deployment failure rate", links.projectInsightsFailureRatePage.generateUrl({ spaceId, projectSlug })),
        NavigationSubItem("Mean time to recovery", links.projectInsightsTimeToRecoveryPage.generateUrl({ spaceId, projectSlug })),
        NavigationSubItem("Deployment duration", links.projectInsightsDeploymentDurationPage.generateUrl({ spaceId, projectSlug })),
    ];
    navigationLinks.push(Navigation.navGroup("Insights", links.projectInsightsOverviewPage.generateUrl({ spaceId, projectSlug }), compact(insightsSubLinks), {
        permission: Permission.ProjectView,
        project: projectId,
        projectGroup: "*",
        tenant: "*",
    }, false));
    const settingSubLinks: Array<NavItem | null> = [];
    settingSubLinks.push(NavigationSubItem("General", links.projectSettingsPage.generateUrl({ spaceId, projectSlug }), true));
    settingSubLinks.push(GitNavigationItemWithErrorStatus("Version Control", links.projectVersionControlSettingsPage.generateUrl({ spaceId, projectSlug }), links.projectVersionControlSettingsPage.generateUrl({ spaceId, projectSlug })));
    if (projectMetadata && projectMetadata.length > 0) {
        settingSubLinks.push(NavigationSubItem("ITSM Providers", links.projectITSMProvidersControlSettingsPage.generateUrl({ spaceId, projectSlug })));
    }
    if (isFeatureToggleEnabled("DynamicEnvironmentsFeatureToggle")) {
        settingSubLinks.push(NavigationSubItem(<React.Fragment>Dynamic Environments</React.Fragment>, links.projectDynamicEnvironmentSettingsPage.generateUrl({ spaceId, projectSlug })));
    }
    navigationLinks.push(Navigation.navGroup("Settings", links.projectSettingsPage.generateUrl({ spaceId, projectSlug }), compact(settingSubLinks), {
        permission: Permission.ProjectView,
        project: projectId,
        projectGroup: "*",
        tenant: "*",
    }, true));
    return compact(navigationLinks);
    // Helpers
    function GitVariablesNavigationItem(label: string | JSX.Element, dbLink: LinkHref, gitLink: LinkHref, exact?: boolean, permission?: PermissionCheckProps) {
        const labelElement = HasVariablesInGit(project.PersistenceSettings) ? <GitProjectLink label={label}/> : <IndentedProjectLink label={label}/>;
        const link = HasVariablesInGit(project.PersistenceSettings) ? gitLink : dbLink;
        return Navigation.navItem(labelElement, link, exact, permission);
    }
    function GitNavigationItem(label: string | JSX.Element, dbLink: LinkHref, gitLink: LinkHref, exact?: boolean, permission?: PermissionCheckProps) {
        const labelElement = project.IsVersionControlled ? <GitProjectLink label={label}/> : <IndentedProjectLink label={label}/>;
        const link = project.IsVersionControlled ? gitLink : dbLink;
        return Navigation.navItem(labelElement, link, exact, permission);
    }
    function GitNavigationItemWithErrorStatus(label: string | JSX.Element, dbLink: LinkHref, gitLink: LinkHref, exact?: boolean, permission?: PermissionCheckProps) {
        const labelElement = project.IsVersionControlled ? <GitProjectLinkWithErrorStatus label={label} hasError={hasConnectionError}/> : <IndentedProjectLink label={label}/>;
        const link = project.IsVersionControlled ? gitLink : dbLink;
        return Navigation.navItem(labelElement, link, exact, permission);
    }
    function NavigationSubItem(label: string | JSX.Element, dbLink: LinkHref, exact?: boolean, permission?: PermissionCheckProps) {
        return Navigation.navItem(<IndentedProjectLink label={label}/>, dbLink, exact, permission);
    }
}
