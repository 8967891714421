/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { AzureCloudServiceEndpointResource, AzureWebAppEndpointResource, CommunicationStyle, DeploymentTargetResource, EndpointResource, EnvironmentResource, KubernetesTentacleEndpointResource, KubernetesEndpointResource, ListeningTentacleEndpointResource, MachineResource, OfflineDropEndpointResource, PollingTentacleEndpointResource, SshEndpointResource, TenantResource, WorkerMachineResource, WorkerPoolResource, } from "@octopusdeploy/octopus-server-client";
import { EndpointsHelper, isDeploymentTarget, isStepPackageEndpointResource, isWorkerMachine, OfflineDropDestinationType, WorkerPoolType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { DisabledMachineIcon } from "~/areas/infrastructure/components/MachineHealthStatusIcons/DisabledMachineIcon";
import { MachineHealthStatusIcon } from "~/areas/infrastructure/components/MachineHealthStatusIcons/MachineHealthStatusIcon";
import type { EndpointRegistration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { BuiltInEndpointLogo, createStepPackageEndpointLogo } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import Chip from "~/components/Chips/Chip";
import { EnvironmentChip, environmentChipList, RoleChip, TenantChip, tenantChipList, WorkerPoolChip, workerPoolChipList } from "~/components/Chips/index";
import Tag from "~/components/Tag";
import { withTheme } from "~/components/Theme";
import type { TagIndex } from "~/components/tenantTagsets";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon/index";
import styles from "./style.module.less";
interface MachineRowProps {
    machine: MachineResource;
    tenants?: TenantResource[];
    tagIndex?: TagIndex;
    environments?: EnvironmentResource[];
    workerPools?: WorkerPoolResource[];
    needsUpgrading?: boolean;
    registrations: EndpointRegistration[];
}
interface MachineRowState {
    showAllChips: boolean;
    tenants: TenantResource[];
    tagIndex: TagIndex;
    environments: EnvironmentResource[];
    workerPools: WorkerPoolResource[];
}
const noEndpointSummary = (): React.ReactNode => null;
const endpointSummaries: {
    [index in CommunicationStyle]: (resource: EndpointResource) => React.ReactNode;
} = {
    TentaclePassive: ((endpoint: ListeningTentacleEndpointResource) => endpoint.Uri) as (endpoint: EndpointResource) => React.ReactNode,
    TentacleActive: ((endpoint: PollingTentacleEndpointResource) => endpoint.Uri) as (endpoint: EndpointResource) => React.ReactNode,
    AzureWebApp: ((endpoint: AzureWebAppEndpointResource) => {
        const slotName = endpoint.WebAppSlotName ? "/" + endpoint.WebAppSlotName : "";
        return endpoint.WebAppName + slotName;
    }) as (endpoint: EndpointResource) => React.ReactNode,
    None: noEndpointSummary,
    AzureCloudService: ((endpoint: AzureCloudServiceEndpointResource) => endpoint.CloudServiceName) as (endpoint: EndpointResource) => React.ReactNode,
    OfflineDrop: ((endpoint: OfflineDropEndpointResource) => {
        return endpoint.Destination.DestinationType === OfflineDropDestinationType.FileSystem ? endpoint.Destination.DropFolderPath : "Octopus Artifact";
    }) as (endpoint: EndpointResource) => React.ReactNode,
    Ssh: ((endpoint: SshEndpointResource) => endpoint.Uri) as (endpoint: EndpointResource) => React.ReactNode,
    Kubernetes: ((endpoint: KubernetesEndpointResource) => endpoint.ClusterUrl) as (endpoint: EndpointResource) => React.ReactNode,
    KubernetesTentacle: ((endpoint: KubernetesTentacleEndpointResource) => endpoint.TentacleEndpointConfiguration.Uri) as (endpoint: EndpointResource) => React.ReactNode,
    AzureServiceFabricCluster: noEndpointSummary,
    StepPackage: noEndpointSummary,
};
class MachineRow extends BaseComponent<MachineRowProps, MachineRowState> {
    private machineChipsDisplayThreshold = 3; //Show 3 chips max, then "show all" link.
    constructor(props: MachineRowProps) {
        super(props);
        this.state = {
            showAllChips: false,
            tenants: props.tenants || [],
            tagIndex: props.tagIndex || {},
            environments: props.environments || [],
            workerPools: props.workerPools || [],
        };
    }
    render() {
        const machine = this.props.machine;
        const RegistrationLogo = isStepPackageEndpointResource(machine.Endpoint) ? createStepPackageEndpointLogo(machine.Endpoint) : BuiltInEndpointLogo;
        return (<div className={styles.machineRow} key={`${machine.Id}-${this.state.showAllChips.toString()}`}>
                <RegistrationLogo machine={machine}/>
                <div className={styles.machineNameContainer}>
                    <div className={styles.machineName}>{machine.Name}</div>
                    <div className={styles.machineType}>{EndpointsHelper.getFriendlyName(machine.Endpoint.CommunicationStyle)}</div>
                    <div className={styles.machineSummary}>{this.getSummaryForEndpoint(machine.Endpoint)}</div>
                </div>
                <div className={styles.healthStatusIconContainer}>{machine.IsDisabled ? <DisabledMachineIcon /> : <MachineHealthStatusIcon healthStatus={machine.HealthStatus}/>}</div>
                <div className={styles.chipContainer}>{this.renderMachineChips(machine)}</div>
                {this.props.needsUpgrading ? (<div className={styles.upgradeAvailable}>
                        <span>Upgrade available</span>
                        <ThirdPartyIcon iconType={ThirdPartyIconType.Update}/>
                    </div>) : ("")}
            </div>);
    }
    private renderMachineChips(machine: MachineResource) {
        if (isWorkerMachine(machine)) {
            return this.renderWorkerChips(machine);
        }
        else if (isDeploymentTarget(machine)) {
            return this.renderDeploymentTargetResourceChips(machine);
        }
    }
    private renderWorkerChips(worker: WorkerMachineResource) {
        const chipThreshold = this.machineChipsDisplayThreshold;
        const includeShowHideControl = this.state.workerPools && worker.WorkerPoolIds.length >= chipThreshold;
        const chipsToDisplay = [];
        if (this.state.workerPools) {
            let environmentsChipsList: React.ReactElement | React.ReactElement[];
            if (worker.WorkerPoolIds.length >= chipThreshold && !this.state.showAllChips) {
                environmentsChipsList = <WorkerPoolChip workerPoolType={WorkerPoolType.Static} workerPoolName={`${worker.WorkerPoolIds.length.toLocaleString()} workerPools`} key="workerPoolsChipsList"/>;
            }
            else {
                environmentsChipsList = this.renderWorkerPoolsList(worker.WorkerPoolIds);
            }
            chipsToDisplay.push(environmentsChipsList);
        }
        // Make this appear more like a ternary link, with the additional click-area of a secondary button to improve usability.
        const labelProps = {
            fontWeight: "inherit",
            fontSize: "0.8125rem",
        };
        if (includeShowHideControl) {
            return (<div className={styles.chipContainer}>
                    {chipsToDisplay}
                    {/*This ActionButton can't be a Ternary type, or you get a nested-link warning because Ternary = anchor tag, and all list rows are already anchors.*/}
                    <ActionButton type={ActionButtonType.Ternary} label={this.state.showAllChips ? "Show summary" : "Show all"} labelProps={labelProps} onClick={(e: React.MouseEvent<Element, MouseEvent>) => this.setState({ showAllChips: !this.state.showAllChips })}/>
                </div>);
        }
        return chipsToDisplay;
    }
    private renderDeploymentTargetResourceChips(deploymentTarget: DeploymentTargetResource) {
        const chipThreshold = this.machineChipsDisplayThreshold;
        const tags = deploymentTarget.TenantTags.map((name) => this.state.tagIndex[name]);
        const tagCount = tags.length;
        const includeShowHideControl = (this.state.environments && deploymentTarget.EnvironmentIds.length >= chipThreshold) || deploymentTarget.Roles.length >= chipThreshold || deploymentTarget.TenantIds.length >= chipThreshold || tagCount >= chipThreshold;
        const chipsToDisplay = [];
        if (this.state.environments) {
            let environmentsChipsList: React.ReactElement | React.ReactElement[];
            if (deploymentTarget.EnvironmentIds.length >= chipThreshold && !this.state.showAllChips) {
                environmentsChipsList = <EnvironmentChip environmentName={`${deploymentTarget.EnvironmentIds.length.toLocaleString()} environments`} key="environmentsChipsList"/>;
            }
            else {
                environmentsChipsList = this.renderEnvironmentsList(deploymentTarget.EnvironmentIds);
            }
            chipsToDisplay.push(environmentsChipsList);
        }
        let roleChipsList: React.ReactElement | React.ReactElement[];
        // eslint-disable-next-line:prefer-conditional-expression
        if (deploymentTarget.Roles.length >= chipThreshold && !this.state.showAllChips) {
            roleChipsList = <RoleChip role={`${deploymentTarget.Roles.length.toLocaleString()} roles`} key="roleChipsList"/>;
        }
        else {
            roleChipsList = this.renderRolesList(deploymentTarget.Roles);
        }
        chipsToDisplay.push(roleChipsList);
        let tenantChipsList: React.ReactElement | React.ReactElement[];
        if (deploymentTarget.TenantIds.length >= chipThreshold && !this.state.showAllChips) {
            tenantChipsList = <TenantChip tenantName={`${deploymentTarget.TenantIds.length.toLocaleString()} tenants`} key="tenantChipsList"/>;
        }
        else {
            tenantChipsList = this.renderTenantsList(deploymentTarget.TenantIds);
        }
        chipsToDisplay.push(tenantChipsList);
        let tenantTagChipsList: React.ReactElement | React.ReactElement[];
        if (tagCount >= chipThreshold && !this.state.showAllChips) {
            const tagsList = deploymentTarget.TenantTags.map((tt) => {
                const tagResource = this.state.tagIndex[tt];
                return <Tag key={tt} tagName={tagResource.Name} description={tagResource.Description} tagColor={tagResource.Color} small={true}/>;
            });
            tenantTagChipsList = withTheme((theme) => (<Chip labelColor={theme.primaryText} backgroundColor={theme.secondaryBackground} key="tenantTagChipsList">
                    {`${tagCount.toLocaleString()} tags`} {tagsList}
                </Chip>));
        }
        else {
            tenantTagChipsList = this.renderTenantTagsList(deploymentTarget.TenantTags);
        }
        chipsToDisplay.push(tenantTagChipsList);
        // Make this appear more like a ternary link, with the additional click-area of a secondary button to improve usability.
        const labelProps = {
            fontWeight: "inherit",
            fontSize: "0.8125rem",
        };
        if (includeShowHideControl) {
            return (<div className={styles.chipContainer}>
                    {chipsToDisplay}
                    {/*This ActionButton can't be a Ternary type, or you get a nested-link warning because Ternary = anchor tag, and all list rows are already anchors.*/}
                    <ActionButton type={ActionButtonType.Ternary} label={this.state.showAllChips ? "Show summary" : "Show all"} labelProps={labelProps} onClick={(e: React.MouseEvent<Element, MouseEvent>) => this.setState({ showAllChips: !this.state.showAllChips })}/>
                </div>);
        }
        return chipsToDisplay;
    }
    private renderEnvironmentsList(environmentIds: string[]) {
        return environmentChipList(this.state.environments, environmentIds);
    }
    private renderWorkerPoolsList(workerPoolIds: string[]) {
        return workerPoolChipList(this.state.workerPools, workerPoolIds);
    }
    private renderRolesList(roles: string[], maxRolesToShow?: number) {
        if (maxRolesToShow) {
            return roles.slice(0, maxRolesToShow).map((r) => <RoleChip role={r} key={"role-" + r}/>);
        }
        return roles.map((r) => <RoleChip role={r} key={"role-" + r}/>);
    }
    private renderTenantsList(tenantIds: string[]) {
        return tenantChipList(this.state.tenants, tenantIds);
    }
    private renderTenantTagsList(tenantTags: string[]) {
        const tags = tenantTags.map((name) => this.state.tagIndex[name]);
        return tags.map((tag) => <Tag tagName={tag.Name} description={tag.Description} tagColor={tag.Color} key={tag.Name}/>);
    }
    private getSummaryForEndpoint(endpoint: EndpointResource) {
        const summary = endpointSummaries[endpoint.CommunicationStyle];
        if (summary) {
            return summary(endpoint);
        }
        return null;
    }
    static displayName = "MachineRow";
}
export default MachineRow;
