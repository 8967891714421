import React from "react";
import Card from "~/components/Card";
import { WelcomeDialogSignIcon } from "../WelcomeDialogSignIcon";
import type { WelcomeDialogCardProps } from "./WelcomeDialogCardProps";
import { cardStyles } from "./styles";
export function SampleProjectCard({ busy, onClick }: WelcomeDialogCardProps) {
    return (<Card link={!busy && (<a href="#" onClick={onClick} className={cardStyles.link}>
                        Create sample project
                    </a>)} logo={<div className={cardStyles.logo(!busy)}>
                    <WelcomeDialogSignIcon />
                </div>} header={<div className={cardStyles.header}>Sample project</div>} content={"Explore a sample project to learn about key Octopus features."} className={cardStyles.card} contentClassName={cardStyles.content} leftAlign={true}/>);
}
