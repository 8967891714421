import type { TriggerResource } from "@octopusdeploy/octopus-server-client";
import { TriggerActionType } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle/index";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import PaperLayout from "~/components/PaperLayout/index";
import { SkeletonLoadingLayout } from "../SkeletonLoadingLayout/SkeletonLoadingLayout";
interface ProjectTriggerRedirectState extends DataBaseComponentState {
    redirectTo: LinkHref;
}
interface ProjectTriggerRedirectProps {
    spaceId: string;
    triggerId: string;
    isPageHeaderVNextEnabled: boolean;
}
export class ProjectTriggerRedirect extends DataBaseComponent<ProjectTriggerRedirectProps, ProjectTriggerRedirectState> {
    constructor(props: ProjectTriggerRedirectProps) {
        super(props);
        this.state = {
            redirectTo: "",
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const projectTrigger = await repository.ProjectTriggers.get(this.props.triggerId);
            const redirectTo = this.getRedirectTo(projectTrigger);
            this.setState({
                redirectTo,
            });
        });
    }
    getRedirectTo(projectTrigger: TriggerResource) {
        const projectId = projectTrigger.ProjectId;
        switch (projectTrigger.Action.ActionType) {
            case TriggerActionType.AutoDeploy:
                return links.editDeploymentTriggerPage.generateUrl({ spaceId: projectTrigger.SpaceId, projectSlug: projectId, triggerId: projectTrigger.Id });
            case TriggerActionType.DeployLatestRelease:
            case TriggerActionType.DeployNewRelease:
                return links.editDeploymentScheduledTriggerPage.generateUrl({ spaceId: projectTrigger.SpaceId, projectSlug: projectId, triggerId: projectTrigger.Id });
            case TriggerActionType.RunRunbook:
                return links.projectRunbookEditScheduledTriggerPage.generateUrl({ spaceId: projectTrigger.SpaceId, projectSlug: projectId, triggerId: projectTrigger.Id });
            case TriggerActionType.CreateRelease:
                return links.editReleaseCreationFeedTriggerPage.generateUrl({ spaceId: projectTrigger.SpaceId, projectSlug: projectId, triggerId: projectTrigger.Id });
        }
    }
    render() {
        const redirectTo = this.state.redirectTo;
        if (!redirectTo) {
            return this.props.isPageHeaderVNextEnabled ? (<main>
                    <SkeletonLoadingLayout errors={this.errors}/>
                </main>) : (<main>
                    <AreaTitle link={links.projectsPage.generateUrl({ spaceId: this.props.spaceId })} title="Projects"/>
                    <PaperLayout busy={this.state.busy} fullWidth={true} errors={this.errors}/>
                </main>);
        }
        return <InternalRedirect push={false} to={redirectTo}/>;
    }
    static displayName = "ProjectTriggerRedirect";
}
