import type { IconButtonIconType, TooltipContent } from "@octopusdeploy/design-system-components";
import * as React from "react";
import Dialog from "~/components/Dialog/Dialog";
import { OpenDialogConnect } from "~/components/Dialog/OpenDialogConnect";
import type { OpenDialogProps } from "~/components/Dialog/OpenDialogConnect";
import dialogIdGenerator from "~/components/Dialog/dialogIdGenerator";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip";
import styles from "./style.module.less";
interface OpenDialogIconButtonProps {
    toolTipContent: TooltipContent;
    icon: IconButtonIconType;
    wideDialog?: boolean;
    disabled?: boolean;
}
class OpenDialogIconButtonInternal extends React.Component<OpenDialogIconButtonProps & OpenDialogProps, never> {
    private uniqueId: string;
    constructor(props: OpenDialogIconButtonProps & OpenDialogProps) {
        super(props);
        this.uniqueId = dialogIdGenerator.next();
    }
    render() {
        const isOpen = this.props.openDialogs[this.uniqueId];
        return (<div className={styles.buttonDialogContainer}>
                <IconButtonWithTooltip icon={this.props.icon} disabled={this.props.disabled} toolTipContent={this.props.toolTipContent} onClick={() => this.props.openDialog(this.uniqueId)}/>
                <Dialog open={isOpen} wide={this.props.wideDialog}>
                    {this.props.children}
                </Dialog>
            </div>);
    }
    static displayName = "OpenDialogIconButtonInternal";
}
const OpenDialogIconButton = OpenDialogConnect.to(OpenDialogIconButtonInternal);
export default OpenDialogIconButton;
