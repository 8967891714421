import type { Active, DragEndEvent } from "@dnd-kit/core";
import { TouchSensor, DragOverlay, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import { useMemo, useState } from "react";
import * as React from "react";
import { v4 } from "uuid";
import SortableItem from "./SortableItem";
interface SortableItemModel {
    Name: string;
}
interface SortableListProps<TItemType> {
    items: TItemType[];
    onOrderChanged(sortedItems: TItemType[]): void;
}
function SortableList<TItemType extends SortableItemModel>(props: SortableListProps<TItemType>) {
    const items = useMemo(() => props.items.map((x) => ({ id: v4(), value: x })), [props.items]);
    const [active, setActive] = useState<Active | null>(null);
    const activeItem = useMemo(() => items.find((item) => item.id === active?.id), [active, items]);
    const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
    }), useSensor(TouchSensor));
    const itemMoved = ({ active, over }: DragEndEvent) => {
        if (over && active.id !== over.id) {
            const activeIndex = items.findIndex(({ id }) => id === active.id);
            const overIndex = items.findIndex(({ id }) => id === over.id);
            props.onOrderChanged(arrayMove(items, activeIndex, overIndex).map((x) => x.value));
        }
        setActive(null);
    };
    return (<DndContext sensors={sensors} onDragStart={({ active }) => setActive(active)} onDragEnd={itemMoved} onDragCancel={() => setActive(null)}>
            <SortableContext items={items} strategy={verticalListSortingStrategy}>
                <ol className={styles.sortableList}>
                    {items.map((item) => (<SortableItem key={item.id} id={item.id} name={item.value.Name}/>))}
                </ol>
            </SortableContext>
            <DragOverlay dropAnimation={null} className={styles.dragOverlay}>
                {activeItem ? <SortableItem key={activeItem.id} id={activeItem.id} name={activeItem.value.Name}/> : null}
            </DragOverlay>
        </DndContext>);
}
const styles = {
    sortableList: css({
        display: "flex",
        flexDirection: "column",
        gap: space[16],
        listStyle: "none",
        touchAction: "none",
    }),
    dragOverlay: css({
        opacity: "0.8",
    }),
};
export default SortableList;
export { SortableItemModel };
