/* eslint-disable @typescript-eslint/no-explicit-any */
import { css, cx } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import cardStyles from "./styles.module.less";
interface ActionTemplateCardListProps {
    children: React.ReactNode;
}
const ActionTemplateCardList = (props: ActionTemplateCardListProps) => {
    // Used to toggle step tile and legacy template styles
    const isImproveKubernetesStepSelectionFeatureEnabled = isFeatureToggleEnabled("ImproveKubernetesStepSelectionFeatureToggle");
    return (<ol className={cx({
            [cardStyles.templates]: !isImproveKubernetesStepSelectionFeatureEnabled,
            [stepTileStyles]: isImproveKubernetesStepSelectionFeatureEnabled,
            [fixedWidthColumns]: isImproveKubernetesStepSelectionFeatureEnabled && React.Children.count(props.children) < 3,
        })}>
            {props.children}
        </ol>);
};
export default ActionTemplateCardList;
const stepTileStyles = css({
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(255px, 1fr))",
    gap: space["24"],
    ["@media (min-width: 1675px)"]: {
        gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))",
    },
});
const fixedWidthColumns = css({
    "&&": {
        gridTemplateColumns: "repeat(auto-fit, minmax(240px, 348px))",
    },
});
