import { css } from "@emotion/css";
import { EmptyStateDashboardIllustration, EmptyStateDashboardNoPermissionIllustration, NavigationButton, NavigationButtonType } from "@octopusdeploy/design-system-components";
import { text } from "@octopusdeploy/design-system-tokens";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import { Level1EmptyStateLayout } from "~/components/GettingStarted/Level1EmptyStateLayout";
import ExternalVideoLink from "~/components/Navigation/ExternalLink/ExternalVideoLink";
import { PermissionCheck } from "~/components/PermissionCheck";
import GettingStartedButton from "~/components/ProjectBasedActivation/GettingStartedButton";
interface OnboardingProject {
    Name: string;
    Slug: string;
}
interface DashboardOnboardingLayoutProps {
    spaceId: string;
    canContinueOnboarding: boolean;
    onboardingProject?: OnboardingProject;
}
function DashboardOnboardingLayout({ spaceId, canContinueOnboarding, onboardingProject }: DashboardOnboardingLayoutProps) {
    const onboardingTitle = isOnboardingHalfDone(canContinueOnboarding, onboardingProject) ? "Continue your project setup" : "Deploy your first application";
    const onboardingIntro = isOnboardingHalfDone(canContinueOnboarding, onboardingProject) ? (<span>
            <strong>{onboardingProject.Name}</strong> is almost setup
        </span>) : ("We'll guide you along the process of adding your first project through to releasing your first deployment.");
    const learnMoreLink = (<ExternalVideoLink href="OnboardingGettingStartedVideo" className={learnMoreLinkStyles}>
            Watch the video (3 mins)
        </ExternalVideoLink>);
    const onboardingActions = isOnboardingHalfDone(canContinueOnboarding, onboardingProject)
        ? [<NavigationButton label="Continue" href={links.deploymentsPage.generateUrl({ spaceId, projectSlug: onboardingProject.Slug }, { newlyCreatedProject: true })} type={NavigationButtonType.Primary}/>]
        : [<GettingStartedButton />, learnMoreLink];
    return (<PermissionCheck permission={Permission.ProjectCreate} alternate={<NoActionDashboard />}>
            <Level1EmptyStateLayout title={onboardingTitle} intro={onboardingIntro} actionButtonsAndLinks={onboardingActions} image={<EmptyStateDashboardIllustration />} analyticLocation={"Onboarding dashboard"}/>
        </PermissionCheck>);
}
function isOnboardingHalfDone(canContinueOnboarding: boolean, onboardingProject: OnboardingProject | undefined): onboardingProject is OnboardingProject {
    return canContinueOnboarding && onboardingProject !== undefined;
}
function NoActionDashboard() {
    return (<Level1EmptyStateLayout title={"Permissions"} intro={"No projects have been setup yet. Your current permissions don\u2019t allow you to create projects. If you require more information or believe this is in error please contact your administrator. "} image={<EmptyStateDashboardNoPermissionIllustration />}/>);
}
const learnMoreLinkStyles = css({
    font: text.link.default.medium,
});
export default DashboardOnboardingLayout;
