import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import * as React from "react";
import { DialogLayoutConnect } from "~/components/Dialog/DialogLayoutConnect";
import type { DialogLayoutDispatchProps, DialogLayoutCommonProps } from "~/components/DialogLayout/DialogLayout";
import { DialogLayout } from "~/components/DialogLayout/DialogLayout";
import FormComponent from "~/components/FormComponent/FormComponent";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import styles from "./style.module.less";
interface QuestionnaireDialogLayoutProps extends DialogLayoutCommonProps {
    additionalPrimaryActions?: React.ReactNode;
    saveButtonDisabled?: boolean;
    savePermission?: PermissionCheckProps;
    saveButtonLabel?: string;
    busyButtonLabel?: string;
    cancelButtonLabel?: string;
    hideSave?: boolean;
    onSaveClick(): Promise<boolean>;
    onCancelClick?(): boolean;
}
class QuestionnaireDialogLayoutInternal extends React.Component<QuestionnaireDialogLayoutProps & DialogLayoutDispatchProps> {
    static defaultProps: Partial<QuestionnaireDialogLayoutProps> = {
        hideSave: false,
    };
    saveClick = async () => {
        const result = await this.props.onSaveClick();
        if (result) {
            this.props.close();
        }
    };
    cancelClick = () => {
        if (!this.props.onCancelClick || this.props.onCancelClick() === true) {
            // Auto close the dialog if no onCancelClick method is provided, or it returns true
            this.props.close();
        }
    };
    submitForm = async () => {
        if (!this.isSaveButtonDisabled()) {
            await this.saveClick();
        }
    };
    render() {
        const { children, ...other } = this.props;
        const save = this.props.hideSave ? null : (<ActionButton key="Save" type={ActionButtonType.Save} label={this.props.saveButtonLabel || "Save"} busyLabel={this.props.busyButtonLabel || "Saving..."} onClick={this.saveClick} disabled={this.isSaveButtonDisabled()}/>);
        const cancel = <ActionButton key="Cancel" label={this.props.cancelButtonLabel || "Cancel"} disabled={this.props.busy} onClick={this.cancelClick}/>;
        return (<DialogLayout actions={[this.props.additionalPrimaryActions, cancel, save]} {...other} closeDialog={this.props.close}>
                <FormComponent onFormSubmit={this.submitForm} className={styles.dialogWrapper}>
                    {children}
                </FormComponent>
            </DialogLayout>);
    }
    private isSaveButtonDisabled(): boolean {
        const disabledDueToPermission = !!this.props.savePermission ? !isAllowed(this.props.savePermission) : false;
        return !!(this.props.saveButtonDisabled || disabledDueToPermission || this.props.busy);
    }
    static displayName = "QuestionnaireDialogLayoutInternal";
}
const QuestionnaireDialogLayout = DialogLayoutConnect.to<QuestionnaireDialogLayoutProps>(QuestionnaireDialogLayoutInternal);
QuestionnaireDialogLayout.displayName = "QuestionnaireDialogLayout";
export default QuestionnaireDialogLayout;
