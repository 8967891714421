import { type AccountResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import { repository } from "~/clientInstance";
import FormPaperLayout from "~/components/FormPaperLayout";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import InfrastructureLayout from "../InfrastructureLayout";
import type { AccountEditPaperProps, AccountEditModel, AccountEditState } from "./AccountEditBase";
import AccountEditBase from "./AccountEditBase";
abstract class AccountEditBasePaper<TAccountResource extends AccountResource, TModel extends AccountEditModel> extends AccountEditBase<TAccountResource, AccountEditState<TAccountResource, TModel>, TModel> {
    constructor(props: AccountEditPaperProps<TAccountResource>) {
        super(props);
        this.state = this.initialState();
    }
    // We redirect to another page via an `<InternalRedirect />` instead of any particular action here
    onSuccess? = undefined;
    onTestDone(success: boolean) {
        this.setState({
            showTestDialog: false,
        });
    }
    render() {
        const accountData = this.state.accountData;
        const isNewAccount = accountData === undefined;
        const savePermission = this.savePermission();
        const title = accountData?.account.Name ?? "Create Account";
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const breadcrumbPath = links.infrastructureAccountsPage.generateUrl({ spaceId: accountData?.account.SpaceId ?? repository.spaceId! });
        return (<InfrastructureLayout {...this.props}>
                <FormPaperLayout title={title} breadcrumbTitle={"Accounts"} breadcrumbPath={breadcrumbPath} saveText="Account details changed" busy={this.state.busy} errors={this.errors} model={this.state.model} cleanModel={this.state.cleanModel} savePermission={savePermission} onSaveClick={() => this.handleSaveClick(false)} pageActions={isAllowed(savePermission) ? this.getPageActions() : undefined} expandAllOnMount={isNewAccount} containerKey={"AccountEdit"} overFlowActions={this.getOverFlowActions(accountData?.account)}>
                    {!this.state.showTestDialog && this.state.accountData && <InternalRedirect to={links.editInfrastructureAccountPage.generateUrl({ spaceId: this.state.accountData.account.SpaceId, accountId: this.state.accountData.account.Id })}/>}
                    {this.innerRender()}
                </FormPaperLayout>
            </InfrastructureLayout>);
    }
}
export default AccountEditBasePaper;
