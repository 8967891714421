import type { ResourceCollection, ProxyResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import type { PageAction, PrimaryPageAction } from "~/components/PageActions/PageActions";
import List from "~/components/PagingList";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import InfrastructureLayout from "../InfrastructureLayout";
import { InfrastructureLayoutBusy } from "../InfrastructureLayout/InfrastructureLayout";
class ProxyList extends List<ProxyResource> {
}
interface ProxiesPageProps {
    spaceId: string;
}
interface ProxiesLayoutInnerProps extends ProxiesPageProps {
    initialData: InitialData;
}
interface InitialData {
    proxiesResponse: ResourceCollection<ProxyResource>;
}
const Title = "Machine Proxies";
const ProxiesLayoutFormPage = FormPage<InitialData>();
export function ProxiesPage({ spaceId }: ProxiesPageProps) {
    return (<ProxiesLayoutFormPage title={Title} load={async () => {
            return { proxiesResponse: await repository.Proxies.list() };
        }} renderWhenLoaded={(data) => <ProxiesLayoutInner initialData={data} spaceId={spaceId}/>} renderAlternate={(args) => <InfrastructureLayoutBusy title={Title} {...args}/>}/>);
}
class ProxiesLayoutInner extends DataBaseComponent<ProxiesLayoutInnerProps, DataBaseComponentState> {
    constructor(props: ProxiesLayoutInnerProps) {
        super(props);
        this.state = {};
    }
    render() {
        const addProxyPageAction: PrimaryPageAction = {
            type: "navigate",
            label: "Add Proxy",
            path: links.createProxyPage.generateUrl({ spaceId: this.props.spaceId }),
            hasPermissions: isAllowed({
                permission: Permission.ProxyCreate,
            }),
        };
        const modifyWebProxyPageAction: PageAction = {
            type: "navigate",
            label: "Modify Web Request Proxy",
            path: links.webRequestProxySettingsPage.generateUrl(),
            buttonType: "secondary",
            hasPermissions: isAllowed({
                permission: Permission.ProxyCreate,
            }),
            extraContext: "Modify the proxy used by the Octopus Server for web requests",
        };
        return (<InfrastructureLayout {...this.props}>
                <PaperLayout title={Title} primaryAction={addProxyPageAction} pageActions={[modifyWebProxyPageAction]}>
                    <ProxyList initialData={this.props.initialData.proxiesResponse} onRow={(item: ProxyResource) => this.buildProxyRow(item)} onRowRedirectUrl={(item: ProxyResource) => links.editProxyPage.generateUrl({ spaceId: this.props.spaceId, proxyId: item.Id })} onFilter={this.Filter} filterSearchEnabled={true} apiSearchParams={["partialName"]} filterHintText="Filter by name..."/>
                </PaperLayout>
            </InfrastructureLayout>);
    }
    private Filter(filter: string, resource: ProxyResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }
    private buildProxyRow(proxy: ProxyResource) {
        return <ListTitle>{proxy.Name}</ListTitle>;
    }
    static displayName = "ProxiesLayoutInner";
}
