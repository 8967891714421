import type { ProjectStatusResponse } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import React, { useState } from "react";
import { useParams } from "react-router";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { client, repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import useLocalStorage from "~/hooks/useLocalStorage";
import { CloseButton } from "./CloseButton";
import { ProjectStatusItem } from "./ProjectStatusItem";
import type { ProjectStatusPopover } from "./ProjectStatusPopover";
import { ConfirmTargetDiscoverySetup, GetTargetDiscoverySetupKey } from "./ProjectStatusUtils";
import styles from "./style.module.less";
interface ProjectStatusProps {
    doBusyTask: DoBusyTask;
    numberOfSteps?: number;
}
export type TaskState = "Current" | "Pending" | "Done";
export const ProjectStatus = ({ doBusyTask, numberOfSteps }: ProjectStatusProps): JSX.Element | null => {
    const dispatchAction = useAnalyticActionDispatch();
    const { spaceId, projectSlug } = useParams<{
        spaceId: string;
        projectSlug: string;
    }>();
    const [dismissed, setDismissed] = useLocalStorage(`Octopus.Project.${spaceId}.${projectSlug}.Status.Dismissed`, false);
    const [hasLoadedStatus, setHasLoadedStatus] = React.useState<boolean>(false);
    const [projectStatus, setProjectStatus] = React.useState<ProjectStatusResponse | undefined>();
    const [isIntendedAsVcsProject, setIsIntendedAsVcsProject] = React.useState(false);
    const [isVersionControlled, setIsVersionControlled] = useState(false);
    const [userConfiguredTargetDiscoverySetup] = useLocalStorage<boolean>(GetTargetDiscoverySetupKey(spaceId, projectSlug), false);
    const hasPermissionToCreateMachines = hasPermission(Permission.MachineCreate);
    const hasPermissionToViewMachines = hasPermission(Permission.MachineView);
    const hasMachinePermissions = hasPermissionToViewMachines && hasPermissionToCreateMachines;
    const statusItems = getStatusItems(spaceId, projectSlug, projectStatus, hasPermissionToCreateMachines, hasPermissionToViewMachines, isIntendedAsVcsProject, isVersionControlled);
    const getProjectStatus = React.useCallback(async (projectSlug: string, spaceId: string) => {
        const projectStatus = await repository.Projects.getProjectStatus(projectSlug, spaceId);
        try {
            const projectIntents = await repository.Projects.getProjectIntents(projectSlug, spaceId);
            setIsIntendedAsVcsProject(projectIntents?.IsVcsProject === true);
            // eslint-disable-next-line no-empty
        }
        catch (e: unknown) { }
        setProjectStatus(projectStatus);
        const runbookOnly = !projectStatus.HasSteps && projectStatus.HasRunbooks;
        if ((projectStatus.HasBeenSuccessfullyDeployed || runbookOnly) && !dismissed) {
            setDismissed(true);
        }
        setHasLoadedStatus(true);
        setIsVersionControlled(projectStatus.IsVersionControlled);
    }, [dismissed, setDismissed]);
    const currentStep = getCurrentStep(projectStatus, isIntendedAsVcsProject, hasMachinePermissions, userConfiguredTargetDiscoverySetup);
    const activeItem = statusItems.findIndex((item) => item.status === currentStep);
    const closeProjectStatus = () => {
        setDismissed(true);
        dispatchAction("Project Status Bar Dismissed", { action: Action.Toggle, resource: "Project Status Bar" });
    };
    React.useEffect(() => {
        if (!spaceId || !projectSlug)
            return;
        return client.subscribe((event) => {
            if (event.type === "EnvironmentCreated" || event.type === "DeploymentProcessModified" || event.type === "ReleaseProgressionModified") {
                getProjectStatus(projectSlug, spaceId);
            }
            else if (event.type === "ProjectModified") {
                setIsVersionControlled(event.project.IsVersionControlled);
            }
        });
    }, [getProjectStatus, projectSlug, spaceId]);
    useDoBusyTaskEffect(doBusyTask, async () => {
        if (!spaceId || !projectSlug)
            return;
        await getProjectStatus(projectSlug, spaceId);
    }, [numberOfSteps]);
    // To prevent a flash of the status bar when it should be dismissed,
    // render nothing until we have fetched the status data
    if (!hasLoadedStatus || dismissed || projectStatus?.HasBeenSuccessfullyDeployed)
        return null;
    if (!spaceId || !projectSlug)
        return null;
    return (<div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.title}>Project status</div>
                {statusItems.map((item, index) => (<ProjectStatusItem name={item.label} state={getItemState(index, activeItem)} href={item.href} key={item.label} showIndicator={item.showIndicator} popover={item.popover} eventName={item.eventName} eventAction={item.eventAction}/>))}
                <CloseButton onClick={closeProjectStatus}/>
            </div>
        </div>);
};
function getItemState(index: number, activeItem: number): TaskState {
    if (index < activeItem)
        return "Done";
    if (index > activeItem)
        return "Pending";
    return "Current";
}
interface StatusItemEventAction {
    action: Action;
    resource: string;
}
interface StatusItem {
    status: string;
    label: string;
    href?: LinkHref;
    showIndicator?: boolean;
    popover?: ProjectStatusPopover;
    eventName: string;
    eventAction: StatusItemEventAction;
}
function getStatusItems(spaceId: string, projectSlug: string, projectStatus: ProjectStatusResponse | undefined, hasPermissionToCreateMachines: boolean, hasPermissionToViewMachines: boolean, isIntendedForVcs: boolean = false, isVersionControlled: boolean = false): StatusItem[] {
    const statusItems: StatusItem[] = [];
    if (!projectStatus) {
        return statusItems;
    }
    const projectStatusItem = getStatusItem("project", "Project", links.projectsPage.generateUrl({ spaceId }), { action: Action.Add, resource: "Project" });
    const environmentStatusItem = getStatusItem("environment", "Environments", links.infrastructureEnvironmentsPage.generateUrl({ spaceId }), { action: Action.Add, resource: "Environment" });
    const vcsStatusItem = getStatusItem("vcs", "Version Control", links.projectVersionControlSettingsPage.generateUrl({ spaceId, projectSlug }), { action: Action.Configure, resource: "Project" });
    const deploymentProcessStatusItem = getStatusItem("process", "Deployment process", links.deploymentProcessPage.generateUrl({ spaceId, projectSlug }), { action: Action.Add, resource: "Deployment Process" });
    const targetStatusItem = {
        ...getStatusItem("target", "Deployment target", hasPermissionToCreateMachines ? links.newDeploymentTargetPage.generateUrl({ spaceId }) : hasPermissionToViewMachines ? links.deploymentTargetsPage.generateUrl({ spaceId }) : undefined, {
            action: Action.Add,
            resource: "Deployment target",
        }),
        showIndicator: true,
        popover: getTargetPopover(spaceId, projectSlug, hasPermissionToCreateMachines, hasPermissionToViewMachines, projectStatus.CloudTargetDiscoveryEnabled, projectStatus.UnassociatedTargetRoles),
    };
    const releaseAndDeployStatusItem = getStatusItem("deployment", "Release and deploy", links.createReleasePage.generateUrl({ spaceId, projectSlug }), { action: Action.Add, resource: "Release" });
    statusItems.push(projectStatusItem);
    if (!projectStatus.HasEnvironments && projectStatus.HasSteps) {
        statusItems.push(deploymentProcessStatusItem);
    }
    statusItems.push(environmentStatusItem);
    if (isIntendedForVcs) {
        // Show if: no deployment process AND not version controlled
        // no deployment process AND version controlled
        // has deployment process AND version controlled
        // Hide if: has deployment process AND not version controlled
        if (!projectStatus.HasSteps || isVersionControlled) {
            statusItems.push(vcsStatusItem);
        }
    }
    if (projectStatus.HasEnvironments || !projectStatus.HasSteps) {
        statusItems.push(deploymentProcessStatusItem);
    }
    if (projectStatus.HasSteps && (projectStatus.HasStepsThatRequireTargets || projectStatus.CloudTargetDiscoveryEnabled)) {
        statusItems.push(targetStatusItem);
    }
    statusItems.push(releaseAndDeployStatusItem);
    return statusItems;
}
function getStatusItem(status: string, label: string, href: LinkHref | undefined, eventAction: StatusItemEventAction): StatusItem {
    return {
        status,
        label,
        href,
        eventName: "Click On Project Status Bar",
        eventAction,
    };
}
function getTargetPopover(spaceId: string, projectSlug: string, hasPermissionToCreateMachines: boolean | undefined, hasPermissionToViewMachines: boolean | undefined, cloudTargetDiscoveryEnabled: boolean, unassociatedTargetRoles: string[]): ProjectStatusPopover | undefined {
    if (!hasPermissionToCreateMachines || !hasPermissionToViewMachines) {
        return { popoverType: "InsufficientPermissions" };
    }
    if (cloudTargetDiscoveryEnabled) {
        if (unassociatedTargetRoles.length) {
            return {
                popoverType: "TargetDiscovery",
                onCustomAction: () => ConfirmTargetDiscoverySetup(spaceId, projectSlug),
            };
        }
        return { popoverType: "TargetDiscoveryMissingTargetRole" };
    }
    if (unassociatedTargetRoles.length) {
        return {
            popoverType: "CanAddDeploymentTargets",
            unassociatedTargetRoles: unassociatedTargetRoles,
        };
    }
    return undefined;
}
function getCurrentStep(projectStatus: ProjectStatusResponse | undefined, isIntendedAsVcsProject: boolean, hasMachinePermissions: boolean, userConfiguredTargetDiscoverySetup: boolean) {
    let currentStep = "initial";
    if (!projectStatus) {
        return currentStep;
    }
    const { HasBeenSuccessfullyDeployed, IsVersionControlled, HasSteps, HasStepsThatRequireTargets, UnassociatedTargetRoles, CloudTargetDiscoveryEnabled, HasEnvironments } = projectStatus;
    if (HasBeenSuccessfullyDeployed) {
        currentStep = "dismissed";
    }
    else if (isIntendedAsVcsProject && !IsVersionControlled && !HasSteps) {
        currentStep = "vcs";
    }
    else if (HasSteps && HasEnvironments && CloudTargetDiscoveryEnabled && !userConfiguredTargetDiscoverySetup) {
        currentStep = "target";
    }
    else if (HasSteps && HasEnvironments && HasStepsThatRequireTargets && (UnassociatedTargetRoles.length || !hasMachinePermissions) && !userConfiguredTargetDiscoverySetup) {
        currentStep = "target";
    }
    else if (HasSteps && HasEnvironments) {
        currentStep = "deployment";
    }
    else if (HasEnvironments) {
        currentStep = "process";
    }
    else {
        currentStep = "environment";
    }
    return currentStep;
}
