/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { UserResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { compact } from "lodash";
import type { PropsWithChildren } from "react";
import * as React from "react";
import { session, repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import BaseComponent from "~/components/BaseComponent";
import Gravatar from "~/components/Gravatar/Gravatar";
import NavigationSidebarLayout, { Navigation } from "~/components/NavigationSidebarLayout";
import { PageHeaderPrimary } from "~/components/PageHeaderPrimary";
import { PortalPageLayout } from "~/components/PortalPageLayout/PortalPageLayout";
import { useIsPageHeaderVNextEnabledOutsideOfProjects } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
interface UserProfileLayoutState {
    user: UserResource;
}
interface UserProfileLayoutInternalProps {
    isPageHeaderVNextEnabled: boolean;
}
class UserProfileLayoutInternal extends BaseComponent<UserProfileLayoutInternalProps, UserProfileLayoutState> {
    constructor(props: UserProfileLayoutInternalProps) {
        super(props);
        this.state = {
            user: null!,
        };
    }
    currentUserId(): string {
        return session && session.currentUser ? session.currentUser.Id : null!;
    }
    async componentDidMount() {
        const user = this.currentUserId() ? await repository.Users.get(this.currentUserId()) : null;
        this.setState({ user: user! });
    }
    render() {
        const navLinks = compact([
            Navigation.navItem("My Profile", links.currentUserDetailsPage.generateUrl()),
            Navigation.navItem("My Logins", links.currentUserLoginsPage.generateUrl()),
            Navigation.navItem("My API Keys", links.currentUserApiKeysPage.generateUrl()),
            Navigation.navItem("My Recent Activity", links.currentUserActivityPage.generateUrl(), null!, { permission: Permission.EventView, wildcard: true }),
        ]);
        if (this.props.isPageHeaderVNextEnabled) {
            return (<main>
                    <PortalPageLayout navItems={navLinks} content={this.props.children} header={<PageHeaderPrimary title={"My Profile"}/>}/>
                </main>);
        }
        return (<main>
                <AreaTitle link={links.currentUserRootRedirect.generateUrl()} title={"My Profile"}/>
                <NavigationSidebarLayout image={this.state.user ? <Gravatar user={this.state.user} size={200}/> : <div />} navLinks={navLinks} content={this.props.children}/>
            </main>);
    }
    static displayName = "UserProfileLayoutInternal";
}
export function UserProfileLayout({ children }: PropsWithChildren<{}>) {
    const isPageHeaderVNextEnabledOutsideOfProjects = useIsPageHeaderVNextEnabledOutsideOfProjects();
    return <UserProfileLayoutInternal isPageHeaderVNextEnabled={isPageHeaderVNextEnabledOutsideOfProjects}>{children}</UserProfileLayoutInternal>;
}
