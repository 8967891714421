/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import * as React from "react";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import type { FormProps, PrimarySaveActionOptions, PrimarySavePageAction } from "~/components/FormPaperLayout/Form";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import type { PageAction, PrimaryPageAction } from "~/components/PageActions/PageActions";
import type { PaperLayoutProps } from "../PaperLayout/PaperLayout";
import PaperLayout from "../PaperLayout/PaperLayout";
import { Form } from "./Form";
import styles from "./style.module.less";
interface LayoutHeaderProps {
    showBreadcrumbBackIcon?: boolean;
}
export type FormPaperLayoutProps = FormProps & Omit<PaperLayoutProps, "primaryAction" | "pageActions"> & PrimarySaveActionOptions & {
    expandAllOnMount?: boolean;
    hideExpandAll?: boolean;
    customPrimaryAction?: PrimaryPageAction | ((props: PrimarySavePageAction) => PrimaryPageAction); // This allows you to override the primary save button that you get by default.
    overFlowActions?: Array<MenuItem | MenuItem[]>;
    hideSectionControls?: boolean;
    hidePrimaryAction?: boolean;
    //Although we have PageActions from PaperLayoutProps, we want to include it here as we specifically don't
    //have a discriminated union for FormPaperLayout so we don't need to check the props to determine whether it
    //supports page actions.
    pageActions?: PageAction[];
    containerKey?: string;
};
type Props = FormPaperLayoutProps & LayoutHeaderProps;
class FormPaperLayout extends BaseComponent<Props> {
    public static defaultProps: Partial<Props> = {
        expandAllOnMount: false,
    };
    getPrimaryAction = (getDefaultPrimaryAction: (options: PrimarySaveActionOptions) => PrimarySavePageAction, isFormDisabled: boolean, save: (isNavigationConfirmation: boolean) => Promise<boolean>): PrimaryPageAction | undefined => {
        if (this.props.hideSectionControls || this.props.hidePrimaryAction) {
            return;
        }
        const defaultPrimaryAction = getDefaultPrimaryAction({
            saveButtonLabel: this.props.saveButtonLabel,
            saveButtonBusyLabel: this.props.saveButtonBusyLabel,
        });
        if (!this.props.customPrimaryAction) {
            return defaultPrimaryAction;
        }
        if (typeof this.props.customPrimaryAction === "function") {
            return this.props.customPrimaryAction(defaultPrimaryAction);
        }
        return this.props.customPrimaryAction;
    };
    render() {
        const { children, model, errors, ...rest } = this.props;
        return (<Form model={model} cleanModel={rest.cleanModel} saveText={rest.saveText} savePermission={rest.savePermission} disableDirtyFormChecking={rest.disableDirtyFormChecking} disableKeyboardFormSubmission={rest.disableKeyboardFormSubmission} forceDisableFormSaveButton={rest.forceDisableFormSaveButton} devToolsDirtyTrackingKey={rest.devToolsDirtyTrackingKey} devToolsDirtyTrackingDisabled={rest.devToolsDirtyTrackingDisabled} onSaveClick={rest.onSaveClick} confirmNavigateSaveLabel={rest.confirmNavigateSaveLabel}>
                {({ createSaveAction, FormContent, isDisabled, save }) => (<PaperLayout {...rest} primaryAction={this.getPrimaryAction(createSaveAction, isDisabled, save)} pageActions={this.props.hideSectionControls ? undefined : this.props.pageActions} overflowActions={this.props.hideSectionControls ? undefined : this.props.overFlowActions} errors={this.props.errors}>
                        <FormContent expandAllOnMount={this.props.expandAllOnMount} hideExpandAll={this.props.hideExpandAll} containerKey={this.props.containerKey}>
                            <div className={styles.formContainer}>{children}</div>
                        </FormContent>
                    </PaperLayout>)}
            </Form>);
    }
    static displayName = "FormPaperLayout";
}
export default FormPaperLayout;
