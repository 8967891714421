import { css } from "@emotion/css";
import { fontSize, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import { Permission, Repository, type UserResource } from "@octopusdeploy/octopus-server-client";
import type { ServiceAccountOidcIdentityResource } from "@octopusdeploy/octopus-server-client/src/repositories/serviceAccountOidcIdentitiesRepository";
import * as React from "react";
import { useState } from "react";
import { repository } from "~/clientInstance";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { RemoveItemsList } from "~/components/RemoveItemsList/RemoveItemsList";
import { CreateServiceAccountOidcIdentityDialog } from "./CreateServiceAccountOidcIdentityDialog";
import { DeleteServiceAccountOidcIdentityDialog } from "./DeleteServiceAccountOidcIdentityDialog";
import { EditServiceAccountOidcIdentityDialog } from "./EditServiceAccountOidcIdentityDialog";
import { GitHubActionsLoginExample } from "./GitHubActionsLoginExample";
import { IssuerTypeLogo } from "./IssuerTypeLogo";
import { OtherIssuerServiceAccountDetails } from "./OtherIssuerServiceAccountDetails";
import { getIssuerType } from "./getIssuerType";
interface ServiceAccountOidcIdentitiesProps {
    serviceAccount: UserResource;
    doBusyTask: DoBusyTask;
}
class ServiceAccountOidcIdentityList extends RemoveItemsList<ServiceAccountOidcIdentityResource> {
}
export default function ServiceAccountOidcIdentities({ serviceAccount, doBusyTask }: ServiceAccountOidcIdentitiesProps) {
    const [serverUrl, setServerUrl] = useState<string | undefined>(undefined);
    const [serviceAccountId, setServiceAccountId] = useState<string | undefined>(undefined);
    const [oidcIdentities, setOidcIdentities] = useState<ServiceAccountOidcIdentityResource[] | undefined>(undefined);
    const [oidcIdentityToEdit, setOidcIdentityToEdit] = useState<ServiceAccountOidcIdentityResource | undefined>(undefined);
    const [oidcIdentityToDelete, setOidcIdentityToDelete] = useState<ServiceAccountOidcIdentityResource | undefined>(undefined);
    const refresh = useDoBusyTaskEffect(doBusyTask, async () => {
        await refreshOidcIdentities();
    }, [serviceAccount]);
    async function refreshOidcIdentities() {
        const response = await repository.ServiceAccountOidcIdentities.getOidcIdentities({
            serviceAccountId: serviceAccount.Id,
            skip: 0,
            take: Repository.takeAll,
        });
        setServerUrl(response.ServerUrl);
        setServiceAccountId(response.ExternalId);
        setOidcIdentities(response.OidcIdentities);
    }
    async function onOidcIdentityDelete(identity: ServiceAccountOidcIdentityResource) {
        await repository.ServiceAccountOidcIdentities.delete({
            id: identity.Id,
            serviceAccountId: identity.ServiceAccountId,
        });
        setOidcIdentityToDelete(undefined);
        await refresh();
    }
    async function onOidcIdentityDeleteCanceled() {
        setOidcIdentityToDelete(undefined);
    }
    async function onOidcIdentityEdit(identity: ServiceAccountOidcIdentityResource) {
        await repository.ServiceAccountOidcIdentities.modify({
            id: identity.Id,
            serviceAccountId: identity.ServiceAccountId,
            name: identity.Name,
            issuer: identity.Issuer,
            subject: identity.Subject,
            audience: identity.Audience === null ? undefined : identity.Audience,
        });
        setOidcIdentityToEdit(undefined);
        await refresh();
    }
    function onOidcIdentityEditCancel() {
        setOidcIdentityToEdit(undefined);
    }
    async function onOidcIdentityCreate(identity: ServiceAccountOidcIdentityResource) {
        await repository.ServiceAccountOidcIdentities.create({
            serviceAccountId: identity.ServiceAccountId,
            name: identity.Name,
            issuer: identity.Issuer,
            subject: identity.Subject,
            audience: identity.Audience === null ? undefined : identity.Audience,
        });
        await refresh();
    }
    const styles = {
        noOidcIdentities: css({
            color: themeTokens.color.text.secondary,
            fontSize: fontSize["medium"],
        }),
        identityContainer: css({
            display: "flex",
            gap: space[16],
            alignItems: "center",
        }),
        identityLogoContainer: css({
            width: space[48],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }),
        identityContent: css({
            display: "flex",
            flexDirection: "column",
            gap: space[4],
        }),
        identityName: css({
            fontSize: "1rem",
            overflowWrap: "anywhere",
        }),
        identityProperty: css({
            display: "flex",
            gap: space[4],
            fontSize: fontSize["medium"],
        }),
        identityPropertyTitle: css({
            minWidth: space[72],
        }),
        identityPropertyValue: css({
            overflowWrap: "anywhere",
        }),
    };
    const listActions = [
        <OpenDialogButton key="newOidcIdentity" label="New OIDC Identity" title="New OIDC Identity" accessibleName="New OIDC Identity" permission={{ permission: Permission.UserEdit }} renderDialog={(renderProps) => <CreateServiceAccountOidcIdentityDialog externalId={serviceAccountId ?? ""} open={renderProps.open} serviceAccount={serviceAccount} onSave={onOidcIdentityCreate} onCancel={renderProps.closeDialog}/>}/>,
    ];
    const canEditUser = isAllowed({ permission: Permission.UserEdit });
    return (<>
            {oidcIdentities && (<ServiceAccountOidcIdentityList removeButtonAccessibleName={(identity) => {
                return `removeOidcIdentity-${identity.Id}`;
            }} listActions={listActions} data={oidcIdentities} empty={<div className={styles.noOidcIdentities}>There aren't currently any OIDC identities configured.</div>} onRow={(identity) => (<div key={identity.Id} className={styles.identityContainer}>
                            <div className={styles.identityLogoContainer}>
                                <IssuerTypeLogo small issuerType={getIssuerType(identity) ?? "Other"}/>
                            </div>
                            <div className={styles.identityContent}>
                                <div className={styles.identityName}>{identity.Name}</div>
                                <div className={styles.identityProperty}>
                                    <span className={styles.identityPropertyTitle}>Issuer:</span>
                                    <span className={styles.identityPropertyValue}>{identity.Issuer}</span>
                                </div>
                                <div className={styles.identityProperty}>
                                    <span className={styles.identityPropertyTitle}>Subject:</span>
                                    <span className={styles.identityPropertyValue}>{identity.Subject}</span>
                                </div>
                                {identity.Audience && (<div className={styles.identityProperty}>
                                        <span className={styles.identityPropertyTitle}>Audience:</span>
                                        <span className={styles.identityPropertyValue}>{identity.Audience}</span>
                                    </div>)}
                            </div>
                        </div>)} onRowTouch={canEditUser
                ? (identity) => {
                    setOidcIdentityToEdit(identity);
                }
                : undefined} onRemoveRow={canEditUser
                ? (identity) => {
                    setOidcIdentityToDelete(identity);
                }
                : undefined}/>)}
            {serverUrl && serviceAccountId && oidcIdentities && oidcIdentities.filter((i) => getIssuerType(i) === "GitHubActions").length > 0 && <GitHubActionsLoginExample serverUrl={serverUrl} serviceAccountId={serviceAccountId}/>}
            {serviceAccountId && oidcIdentities && oidcIdentities.filter((i) => getIssuerType(i) === "Other").length > 0 && <OtherIssuerServiceAccountDetails serviceAccountId={serviceAccountId}/>}

            {oidcIdentityToDelete && <DeleteServiceAccountOidcIdentityDialog oidcIdentity={oidcIdentityToDelete} onDelete={onOidcIdentityDelete} onCancel={onOidcIdentityDeleteCanceled}/>}
            {serviceAccountId && oidcIdentityToEdit && <EditServiceAccountOidcIdentityDialog externalId={serviceAccountId} oidcIdentity={oidcIdentityToEdit} onSave={onOidcIdentityEdit} onCancel={onOidcIdentityEditCancel}/>}
        </>);
}
