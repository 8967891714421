import { ActionHandlerCategory } from "@octopusdeploy/octopus-server-client";
import React from "react";
import AuditStreamCategoryIconHelper from "~/areas/configuration/components/AuditLayout/AuditStream/AuditStreamCategoryIconHelper";
import { AuditStreamType } from "~/areas/configuration/components/AuditLayout/AuditStream/AuditStreamType";
import { DynamicEnvironmentProjectStateIcon } from "~/areas/infrastructure/components/DynamicEnvironmentIcons/DynamicEnvironmentProjectStateIcon";
import { DynamicEnvironmentStateIcon } from "~/areas/infrastructure/components/DynamicEnvironmentIcons/DynamicEnvironmentStateIcon";
import { getDynamicEnvironmentProjectStateDescription } from "~/areas/infrastructure/components/DynamicEnvironmentIcons/getDynamicEnvironmentProjectStateDescription";
import { getDynamicEnvironmentStateDescription } from "~/areas/infrastructure/components/DynamicEnvironmentIcons/getDynamicEnvironmentStateDescription";
import { DisabledMachineIcon } from "~/areas/infrastructure/components/MachineHealthStatusIcons/DisabledMachineIcon";
import { HasWarningsMachineIcon } from "~/areas/infrastructure/components/MachineHealthStatusIcons/HasWarningsMachineIcon";
import { HealthyMachineIcon } from "~/areas/infrastructure/components/MachineHealthStatusIcons/HealthyMachineIcon";
import { UnavailableMachineIcon } from "~/areas/infrastructure/components/MachineHealthStatusIcons/UnavailableMachineIcon";
import { UnhealthyMachineIcon } from "~/areas/infrastructure/components/MachineHealthStatusIcons/UnhealthyMachineIcon";
import { UnknownMachineIcon } from "~/areas/infrastructure/components/MachineHealthStatusIcons/UnknownMachineIcon";
import { CloudConnectionConfiguredIcon } from "~/areas/projects/components/CloudConnection/CloudConnectionConfiguredIcon";
import { GreenTickIcon } from "~/areas/projects/components/ProjectStatus/GreenTickIcon";
import { RightArrowIcon } from "~/areas/projects/components/ProjectStatus/RightArrowIcon";
import ActionTemplateCategoryIconHelper from "~/components/ActionTemplates/ActionTemplateCategoryIconHelper";
import { ActiveStatusIcon } from "~/components/ActiveStatusIcon/ActiveStatusIcon";
import type { EmbeddedImageDescription, VariantDescription } from "~/components/DevTools/Images/EmbeddedImageDescription";
const dynamicEnvironmentStateIconsDescription: EmbeddedImageDescription = {
    componentName: "DynamicEnvironmentStateIcon",
    variants: [
        { variantDescription: getDynamicEnvironmentStateDescription("Active"), content: <DynamicEnvironmentStateIcon state="Active"/> },
        { variantDescription: getDynamicEnvironmentStateDescription("Deprovisioned"), content: <DynamicEnvironmentStateIcon state="Deprovisioned"/> },
        { variantDescription: getDynamicEnvironmentStateDescription("Deprovisioning"), content: <DynamicEnvironmentStateIcon state="Deprovisioning"/> },
        { variantDescription: getDynamicEnvironmentStateDescription("DeprovisioningFailed"), content: <DynamicEnvironmentStateIcon state="DeprovisioningFailed"/> },
    ],
};
const dynamicEnvironmentProjectStateIconsDescription: EmbeddedImageDescription = {
    componentName: "DynamicEnvironmentProjectStateIcon",
    variants: [
        { variantDescription: getDynamicEnvironmentProjectStateDescription("Provisioned"), content: <DynamicEnvironmentProjectStateIcon state="Provisioned"/> },
        { variantDescription: getDynamicEnvironmentProjectStateDescription("Provisioning"), content: <DynamicEnvironmentProjectStateIcon state="Provisioning"/> },
        { variantDescription: getDynamicEnvironmentProjectStateDescription("ProvisioningFailed"), content: <DynamicEnvironmentProjectStateIcon state="ProvisioningFailed"/> },
        { variantDescription: getDynamicEnvironmentProjectStateDescription("Deprovisioned"), content: <DynamicEnvironmentProjectStateIcon state="Deprovisioned"/> },
        { variantDescription: getDynamicEnvironmentProjectStateDescription("Deprovisioning"), content: <DynamicEnvironmentProjectStateIcon state="Deprovisioning"/> },
        { variantDescription: getDynamicEnvironmentProjectStateDescription("DeprovisioningFailed"), content: <DynamicEnvironmentProjectStateIcon state="DeprovisioningFailed"/> },
    ],
};
const auditStreamCategoryIconDescription: EmbeddedImageDescription = {
    componentName: "AuditStreamCategoryIconHelper.getCategorySvgElement",
    variants: [
        { variantDescription: `${AuditStreamType.Splunk} - isActive: true`, content: AuditStreamCategoryIconHelper.getCategorySvgElement(AuditStreamType.Splunk, true) },
        { variantDescription: `${AuditStreamType.Splunk} - isActive: false`, content: AuditStreamCategoryIconHelper.getCategorySvgElement(AuditStreamType.Splunk, false) },
        { variantDescription: `${AuditStreamType.SumoLogic} - isActive: true`, content: AuditStreamCategoryIconHelper.getCategorySvgElement(AuditStreamType.SumoLogic, true) },
        { variantDescription: `${AuditStreamType.SumoLogic} - isActive: false`, content: AuditStreamCategoryIconHelper.getCategorySvgElement(AuditStreamType.SumoLogic, false) },
    ],
};
const machineHealthStatusIcons: EmbeddedImageDescription[] = [
    { componentName: "DisabledMachineIcon", content: <DisabledMachineIcon /> },
    { componentName: "HasWarningsMachineIcon", content: <HasWarningsMachineIcon /> },
    { componentName: "HealthyMachineIcon", content: <HealthyMachineIcon /> },
    { componentName: "UnavailableMachineIcon", content: <UnavailableMachineIcon /> },
    { componentName: "UnhealthyMachineIcon", content: <UnhealthyMachineIcon /> },
    { componentName: "UnknownMachineIcon", content: <UnknownMachineIcon /> },
];
const cloudConnectionConfigurationIcon: EmbeddedImageDescription = {
    componentName: "CloudConnectionConfiguredIcon",
    content: <CloudConnectionConfiguredIcon />,
};
const projectStatusIcons: EmbeddedImageDescription[] = [
    { componentName: "GreenTickIcon", content: <GreenTickIcon /> },
    { componentName: "RightArrowIcon", content: <RightArrowIcon /> },
];
function getActionTemplateCategoryIcon(): EmbeddedImageDescription {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const actionHandlerCategories: ActionHandlerCategory[] = Object.keys(ActionHandlerCategory) as ActionHandlerCategory[];
    const combinations = actionHandlerCategories.flatMap((category) => [true, false].map((isActive) => ({ category, isActive })));
    const variants: VariantDescription[] = combinations
        .map((combination) => ({
        variantDescription: `${combination.category} - isActive: ${combination.isActive}`,
        content: ActionTemplateCategoryIconHelper.getCategorySvgElement(combination.category, combination.isActive),
    }))
        .filter((v) => v.content !== null); // categories with no icons return null
    return {
        componentName: "ActionTemplateCategoryIconHelper.getCategorySvgElement",
        variants,
    };
}
const activeStatusIcon: EmbeddedImageDescription = {
    componentName: "ActiveStatusIcon",
    variants: [
        { variantDescription: "active", content: <ActiveStatusIcon active={true}/> },
        { variantDescription: "false", content: <ActiveStatusIcon active={false}/> },
    ],
};
// This represents anything using the <svg> tag in a react component
export const embeddedSvgDescriptions: EmbeddedImageDescription[] = [
    auditStreamCategoryIconDescription,
    dynamicEnvironmentStateIconsDescription,
    dynamicEnvironmentProjectStateIconsDescription,
    ...machineHealthStatusIcons,
    cloudConnectionConfigurationIcon,
    ...projectStatusIcons,
    getActionTemplateCategoryIcon(),
    activeStatusIcon,
];
