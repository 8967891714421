import { RadioButton, RadioButtonGroup, Switch } from "@octopusdeploy/design-system-components";
import type { EnvironmentResource, TenantResource, TenantTargetingStrategy, ToggleResource } from "@octopusdeploy/octopus-server-client";
import { TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import React, { useState } from "react";
import { useProjectContext } from "~/areas/projects/context/index";
import { EnvironmentChip } from "~/components/Chips/index";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import { TenantMultiSelect } from "~/components/MultiSelect/TenantMultiSelect";
import Tag from "~/components/Tag/Tag";
import { withTheme } from "~/components/Theme";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import EnvironmentSelect from "~/components/form/EnvironmentSelect/EnvironmentSelect";
import ExpandableFormSection from "~/components/form/Sections/ExpandableFormSection";
import { Summary } from "~/components/form/index";
import NumberPicker from "~/primitiveComponents/form/Number/NumberPicker";
interface TogglePropsShared {
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    onSave(toggle: ToggleResource): Promise<boolean>;
}
interface EditToggleProps extends TogglePropsShared {
    toggle: ToggleResource;
    isNew: false;
}
interface CreateToggleProps extends TogglePropsShared {
    isNew: true;
    toggle?: never;
}
interface EditToggleState {
    environmentId: string | undefined;
    isEnabled: boolean;
    tenantTargetingStrategy: TenantTargetingStrategy;
    tenantIds: string[];
    rolloutPercentage?: number;
    segments: string[];
}
const SegmentMultiSelect = MultiSelect<SelectItem>();
export default ({ toggle, environments, tenants, onSave, isNew }: EditToggleProps | CreateToggleProps) => {
    const projectContext = useProjectContext();
    const project = projectContext.state && projectContext.state.model;
    const [state, setState] = useState<EditToggleState>({
        environmentId: toggle ? toggle.DeploymentEnvironmentId : undefined,
        isEnabled: toggle ? toggle.IsEnabled : false,
        tenantTargetingStrategy: toggle ? toggle.TenantTargetingStrategy : "All",
        tenantIds: toggle ? toggle.TenantIds : [],
        rolloutPercentage: toggle ? toggle.RolloutPercentage : undefined,
        segments: toggle ? toggle.Segments : [],
    });
    const onSaveClick = async () => {
        if (!state.environmentId) {
            return false;
        }
        const toggleStrategy = state.tenantTargetingStrategy === "All" ? "EntireEnvironment" : "Targeted";
        return onSave({
            DeploymentEnvironmentId: state.environmentId,
            IsEnabled: state.isEnabled,
            ToggleStrategy: toggleStrategy,
            TenantTargetingStrategy: state.tenantTargetingStrategy,
            TenantIds: state.tenantIds,
            RolloutPercentage: state.rolloutPercentage,
            Segments: state.segments,
        });
    };
    const onChangeTenantTargetingStrategy = (strategy: TenantTargetingStrategy) => {
        if (strategy === state.tenantTargetingStrategy)
            return;
        setState((prevState) => ({ ...prevState, tenantTargetingStrategy: strategy, tenantIds: [], rolloutPercentage: undefined }));
    };
    const environmentSummary = () => {
        if (!state.environmentId)
            return Summary.placeholder("Please select an environment");
        const selectedEnvironment = environments.find((e) => e.Id === state.environmentId);
        if (!selectedEnvironment)
            throw new Error(`Could not find environment matching ID: ${state.environmentId}`);
        return Summary.summary(<EnvironmentChip environmentName={selectedEnvironment.Name}/>);
    };
    const tenantSummary = () => {
        switch (state.tenantTargetingStrategy) {
            case "All":
                return Summary.default("All tenants");
            case "SpecificTenants":
                return Summary.summary("Some tenants");
            case "PercentageOfTenants":
                return Summary.summary(`${state.rolloutPercentage}% of tenants`);
        }
    };
    const segmentSummary = () => {
        if (!state.segments) {
            return Summary.default("No segments specified");
        }
        return Summary.summary(<span>{state.segments.map((s) => withTheme((theme) => <Tag tagName={s} description={s} tagColor={theme.info}/>))}</span>);
    };
    const segmentChip = (r: SelectItem, onRequestDelete: () => void) => {
        return withTheme((theme) => <Tag tagName={r.Name} description={r.Name} tagColor={theme.info}/>);
    };
    const onSegmentsChange = (segments: string[]) => {
        setState((prevState) => ({ ...prevState, segments }));
    };
    return (<OkDialogLayout title={isNew ? "Add Toggle" : "Edit Toggle"} cancelButtonLabel="Cancel" errors={null} onOkClick={onSaveClick}>
            <React.Fragment>
                <Switch value={state.isEnabled} onChange={(value) => setState((prevState) => ({
            ...prevState,
            isEnabled: value,
        }))}/>
                <ExpandableFormSection title="Environment" summary={environmentSummary()} errorKey="Environment">
                    <EnvironmentSelect label={"Environment"} environments={environments} value={state.environmentId} onChange={(selected) => {
            setState((prevState) => ({ ...prevState, environmentId: selected }));
        }}/>
                </ExpandableFormSection>
                {
        /* If the project is tenanted, allow targeting specific tenants */
        project.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted && (<ExpandableFormSection title="Tenants" summary={tenantSummary()} errorKey={"tenantTargetingStrategy"}>
                            <RadioButtonGroup value={state.tenantTargetingStrategy} onChange={(x) => onChangeTenantTargetingStrategy(x)}>
                                <RadioButton label={"All"} value={"All"}/>
                                <RadioButton label={"Selected Tenants"} value={"SpecificTenants"}/>
                                <RadioButton label={"% of Tenants"} value={"PercentageOfTenants"}/>
                            </RadioButtonGroup>
                            {state.tenantTargetingStrategy === "SpecificTenants" && <TenantMultiSelect label="Tenants" items={tenants} value={state.tenantIds} onChange={(x) => setState((prevState) => ({ ...prevState, tenantIds: x }))}/>}
                            {state.tenantTargetingStrategy === "PercentageOfTenants" && (<NumberPicker label="Percentage" min={1} max={100} value={state.rolloutPercentage} onChange={(x) => setState((prevState) => ({ ...prevState, rolloutPercentage: x }))}/>)}
                        </ExpandableFormSection>)}
                <ExpandableFormSection title="Segments" summary={segmentSummary()} errorKey="segments">
                    <SegmentMultiSelect label="Segments" value={state.segments} items={[]} renderChip={segmentChip} onChange={onSegmentsChange} onNew={(value) => onSegmentsChange(state.segments.concat(value))}/>
                </ExpandableFormSection>
            </React.Fragment>
        </OkDialogLayout>);
};
