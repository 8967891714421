import { Callout } from "@octopusdeploy/design-system-components";
import type { GitHubAppConnectionResource } from "@octopusdeploy/octopus-server-client";
import { GitHubConnectionStatus, Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React, { useState } from "react";
import { Action } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import DataBaseComponent, { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import DeleteDialog from "~/components/Dialog/DeleteDialog";
import FormPaperLayout from "~/components/FormPaperLayout";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { PageAction } from "~/components/PageActions/PageActions";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { FormSectionHeading, UnstructuredFormSection } from "~/components/form";
import StringHelper from "~/utils/StringHelper";
import LibraryLayout from "../LibraryLayout";
import { GitConnectionAnalyticView } from "./Analytics/GitConnectionAnalyticView";
import { useNotifyGitConnectionEvent } from "./Analytics/useNotifyGitConnectionEvent";
import { useTrackedGitConnectionEvent } from "./Analytics/useTrackedGitConnectionEvent";
import { GitHubAppAuthCheck } from "./GitHubAppAuthCheck";
import { GitHubAppNewRepositorySelector } from "./GitHubAppNewRepositorySelector";
import GitHubAppRepositoryList, { GitHubAppUnknownRepositoryList } from "./GitHubAppRepositoryList";
import { GitHubFooterMessage } from "./GitHubFooterMessage";
import { GitHubInstallationLogo } from "./GitHubInstallationLogo";
interface GitHubConnectionPageInternalProps extends GitHubConnectionPageProps {
    doBusyTask: DoBusyTask;
    busy?: Promise<unknown> | boolean;
    errors?: Errors;
}
function GitHubConnectionPageInternal({ spaceId, connectionId, doBusyTask, busy, errors }: GitHubConnectionPageInternalProps) {
    const [editing, setEditing] = useState<boolean>(false);
    const [connection, setConnection] = useState<GitHubAppConnectionResource | undefined>(undefined);
    const [deleted, setDeleted] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const [selectedRepositories, setSelectedRepositories] = useState<string[]>([]);
    const [cleanSelectedRepositories, setCleanSelectedRepositories] = useState<string[]>([]);
    const trackAction = useTrackedGitConnectionEvent();
    const recordAction = useNotifyGitConnectionEvent();
    const onDeleteClick = () => {
        setOpenDelete(true);
    };
    const refresh = useDoBusyTaskEffect(doBusyTask, async () => {
        const connection = await repository.GitHubApp.getConnection(connectionId);
        setConnection(connection);
        const initialSelectedRepositories = connection.Repositories.map((r) => r.RepositoryId);
        initialSelectedRepositories.push(...connection.UnknownRepositories.map((r) => r.RepositoryId));
        initialSelectedRepositories.sort();
        setSelectedRepositories(initialSelectedRepositories);
        setCleanSelectedRepositories(initialSelectedRepositories);
    }, [connectionId]);
    function setRepositorySelected(selected: boolean, repositoryId: string) {
        if (selected) {
            setSelectedRepositories((prev) => [...prev, repositoryId].sort());
        }
        else {
            setSelectedRepositories((prev) => prev.filter((id) => id !== repositoryId).sort());
        }
    }
    async function save() {
        await doBusyTask(async () => {
            await trackAction("Save GitHub connection", Action.Save, async () => {
                await repository.GitHubApp.modifyConnection(connectionId, { RepositoryIds: selectedRepositories });
                setEditing(false);
                await refresh();
            });
        });
    }
    async function onRefreshTokenClick() {
        await doBusyTask(async () => {
            await repository.GitHubApp.refreshConnection(connectionId);
            await refresh();
        });
    }
    function toggleEditing() {
        setEditing(!editing);
        setSelectedRepositories(cleanSelectedRepositories);
        if (editing) {
            recordAction("Edit GitHub connection");
        }
        else {
            recordAction("Cancel editing GitHub connection");
        }
    }
    const handleDeleteConfirm = async () => {
        if (!connection) {
            // If there is some issue with the connection (can't be found in the GitHub app,
            // installation has errors, etc) the get connection endpoint will error and we
            // won't have a connection at this point. We still want to be abel to delete it
            // to allow the user to re-configure it, so just try deleting using the connection
            // id.
            await repository.GitHubApp.deleteConnection(connectionId);
        }
        else {
            await repository.GitHubApp.deleteConnection(connection.Id);
        }
        setConnection(undefined);
        setDeleted(true);
        return true;
    };
    const editButton: PageAction = {
        type: "button",
        buttonType: "secondary",
        hasPermissions: isAllowed({ permission: Permission.GitCredentialEdit }),
        label: editing ? "Cancel" : "Edit",
        onClick: toggleEditing,
    };
    const overFlowActions = [OverflowMenuItems.item("Disconnect", onDeleteClick, { permission: Permission.GitCredentialEdit }), OverflowMenuItems.item("Refresh", onRefreshTokenClick, { permission: Permission.GitCredentialView })];
    return (<LibraryLayout spaceId={spaceId}>
            <GitConnectionAnalyticView name="View GitHub connection page"/>
            <DisconnectGitHubConnectionDialog open={openDelete} onResetRegistration={() => handleDeleteConfirm()} onClose={() => setOpenDelete(false)}/>
            <FormPaperLayout model={{ selected: selectedRepositories }} cleanModel={{ selected: cleanSelectedRepositories }} pageActions={[editButton]} onSaveClick={save} forceDisableFormSaveButton={selectedRepositories?.length === 0} errors={errors} title={connection?.Installation ? connection.Installation.AccountLogin : StringHelper.ellipsis} titleLogo={<GitHubInstallationLogo installation={connection?.Installation}/>} breadcrumbTitle={"GitHub Connections"} breadcrumbPath={links.gitConnectionsPage.generateUrl({ spaceId })} busy={busy} savePermission={{ permission: Permission.GitCredentialEdit }} overFlowActions={overFlowActions}>
                {deleted && <InternalRedirect to={links.gitConnectionsPage.generateUrl({ spaceId })}/>}
                <GitHubAppAuthCheck actionDescription="add repositories to this connection"/>
                {connection && (<>
                        <GitHubConnectionStatusCheck connection={connection}/>
                        {connection.Status === GitHubConnectionStatus.Connected && (<>
                                {selectedRepositories?.length === 0 && <InvalidGitHubConnectionRepositories />}
                                <FormSectionHeading title="Connected Repositories"/>
                                <UnstructuredFormSection stretchContent>
                                    <GitHubAppRepositoryList disableInteraction={!editing} repositories={connection.Repositories} selectedRepositories={selectedRepositories} setRepositorySelected={setRepositorySelected}/>
                                </UnstructuredFormSection>

                                {connection.UnknownRepositories.length > 0 && (<>
                                        <FormSectionHeading title={"Missing Repositories"}/>
                                        <UnstructuredFormSection stretchContent>
                                            <GitHubAppUnknownRepositoryList disableInteraction={!editing} repositories={connection.UnknownRepositories} selectedRepositories={selectedRepositories} setRepositorySelected={setRepositorySelected}/>
                                        </UnstructuredFormSection>
                                    </>)}
                                {editing && (<>
                                        <FormSectionHeading title="Add Repositories"/>
                                        <UnstructuredFormSection stretchContent>
                                            <GitHubAppNewRepositorySelector installation={connection.Installation} startingSelectedRepositories={cleanSelectedRepositories} selectedRepositories={selectedRepositories} setRepositorySelected={setRepositorySelected}/>
                                        </UnstructuredFormSection>
                                    </>)}
                            </>)}
                    </>)}
            </FormPaperLayout>
        </LibraryLayout>);
}
interface GitHubConnectionPageProps {
    spaceId: string;
    connectionId: string;
}
export class GitHubConnectionPage extends DataBaseComponent<GitHubConnectionPageProps> {
    constructor(props: GitHubConnectionPageProps) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.clearErrors();
    }
    render() {
        return <GitHubConnectionPageInternal spaceId={this.props.spaceId} connectionId={this.props.connectionId} doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors}/>;
    }
    static displayName = "GitHubConnectionPage";
}
interface DisconnectGitHubConnectionDialogProps {
    open: boolean;
    onClose(): void;
    onResetRegistration(): void;
}
function DisconnectGitHubConnectionDialog({ open, onClose, onResetRegistration }: DisconnectGitHubConnectionDialogProps) {
    function onCloseClick() {
        onClose();
    }
    return <DeleteDialog open={open} onClose={onCloseClick} title="Are you sure you want to disconnect the GitHub Connection?" deleteButtonLabel="Disconnect" onDeleteClick={onResetRegistration} renderContent={() => <></>}/>;
}
interface GitHubAppInstallationDisplayProps {
    connection: GitHubAppConnectionResource;
}
export function GitHubConnectionStatusCheck({ connection }: GitHubAppInstallationDisplayProps) {
    const installationName = connection?.Installation ? connection.Installation.AccountLogin : connection?.Id;
    switch (connection.Status) {
        case GitHubConnectionStatus.Disconnected:
            return <GitHubFooterMessage title={`The GitHub App installation '${installationName}' could not be found.`} message="If the app has been removed and reinstalled, you will need to re-create this connection."/>;
        case GitHubConnectionStatus.Missing:
            return <GitHubFooterMessage title={`The GitHub App connection '${installationName}' could not be found.`} message="You will need to delete and re-create this connection."/>;
        case GitHubConnectionStatus.Suspended:
            return <GitHubFooterMessage title={`The GitHub App connection '${installationName}' has been suspended.`} message="The GitHub installation has been suspended. If this is unexpected please contact your GitHub administrators."/>;
        default:
            return null;
    }
}
export function InvalidGitHubConnectionRepositories() {
    return (<Callout title={"No repositories selected"} type={"information"} canClose={false}>
            Please select at least one repository or disconnect this account.
        </Callout>);
}
