import * as React from "react";
import ReleaseChangesDetail from "~/areas/projects/components/Releases/ReleaseChanges/ReleaseChangesDetail";
import OnboardingPage from "~/components/GettingStarted/OnboardingPage";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Section } from "../../../../../components/Section/Section";
interface ChangesProps {
    changesMarkdown: string;
}
function Changes(props: ChangesProps) {
    return (<Section>
            <div>
                {/\S/.test(props.changesMarkdown) ? (<ReleaseChangesDetail changesMarkdown={props.changesMarkdown}/>) : (<OnboardingPage title="Configure changes for your deployments" intro={<div>Deployment changes provide a summary of changes across all releases since the previous deployment</div>} learnMore={<ExternalLink href="DeploymentNotes">Learn more</ExternalLink>}/>)}
            </div>
        </Section>);
}
export default Changes;
