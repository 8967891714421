/* eslint-disable no-eq-null */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { css } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import { OctopusError } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { CodeEditorElement, CodeEditorLanguage } from "~/components/CodeEditor/CodeEditor";
import { CodeEditor } from "~/components/CodeEditor/CodeEditor";
import type { Errors } from "~/components/DataBaseComponent/index";
import type { RenderProps } from "~/components/Dialog/CustomDialog";
import { CustomDialog } from "~/components/Dialog/CustomDialog";
import { CustomDialogActions, CustomFlexDialogContent, CustomSaveDialogActions, CustomSaveDialogTitleBar } from "~/components/DialogLayout/Custom";
import CustomSaveDialogLayout from "~/components/DialogLayout/Custom/CustomSaveDialogLayout";
import DisplayDiff from "~/components/DisplayDiff/index";
interface SourceCodeDialogProps extends RenderProps {
    title?: string;
    value: string;
    language: CodeEditorLanguage;
    autocomplete: Array<{
        display: string;
        code: string;
    }>;
    description?: React.ReactNode;
    validate?(value: string): Promise<OctopusError> | OctopusError | Error | null;
    onSave(value: string): void;
    confirmationOptions?: SourceCodeDialogConfirmationOptions;
}
type SourceCodeDialogConfirmationOptions = {
    headerCallout?: React.ReactNode;
};
const confirmContainerStyles = css({
    paddingLeft: "1rem",
    paddingRight: "1rem",
    display: "flex",
    flexDirection: "column",
});
const codeEditorWrapper = css({
    padding: "1em",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
});
const diffContainerStyles = css({
    background: themeTokens.color.background.secondary.default,
    flexGrow: 1,
});
const SourceCodeDialog = (props: SourceCodeDialogProps) => {
    const [errors, setErrors] = React.useState<OctopusError | Error | null>(null);
    const [sourceCode, setSourceCode] = React.useState<string>(props.value);
    const [confirmationVisible, setConfirmationVisible] = React.useState<boolean>(false);
    const initialSourceCode = props.value;
    React.useEffect(() => {
        if (props.open) {
            setErrors(null);
            setSourceCode(props.value);
            setConfirmationVisible(false);
        }
    }, [props.value, props.open]);
    const editorRef = React.useRef<CodeEditorElement>(null);
    const hasChanges = initialSourceCode !== sourceCode;
    const save = async () => {
        if (props.validate) {
            const validationErrors = await props.validate(sourceCode);
            if (validationErrors) {
                setErrors(validationErrors);
                return false;
            }
        }
        props.onSave(sourceCode);
        return true;
    };
    const onFocusedEditorEscapePressed = () => {
        editorRef?.current?.blur();
    };
    const convertErrors = (): Errors | undefined => {
        if (!errors) {
            return;
        }
        if (OctopusError.isOctopusError(errors) && errors.Errors) {
            return {
                errors: errors.Errors.map((x: string) => x.toString()) ?? [],
                message: errors.ErrorMessage,
                fieldErrors: {},
                details: {},
            };
        }
        return {
            errors: [],
            message: errors.message,
            fieldErrors: {},
            details: {},
        };
    };
    const title = confirmationVisible ? "Review and confirm your changes" : props.title ?? "Edit Source Code";
    return (<CustomDialog open={props.open} close={props.close} render={(customDialogRenderProps) => (<CustomSaveDialogLayout 
        //frame={LargeDialogFrame}
        {...customDialogRenderProps} renderTitle={() => <CustomSaveDialogTitleBar title={title}/>} {...(errors ? { errors: convertErrors() } : {})} onSaveClick={() => save()} renderActions={(renderProps) => (<CustomDialogActions actions={<>
                                    {/* When the confirmation is required and there are changes, but it's not visible */}
                                    {!confirmationVisible && props.confirmationOptions && hasChanges && (<CustomSaveDialogActions saveButtonLabel="Next" close={(wasSaved) => (wasSaved ? setConfirmationVisible(true) : renderProps.close())} onSaveClick={() => Promise.resolve(true)} savePermission={renderProps.savePermission}/>)}

                                    {/* When the confirmation is required and there are changes and its visible */}
                                    {confirmationVisible && props.confirmationOptions && (<CustomSaveDialogActions cancelButtonLabel={"Previous"} saveButtonLabel="Confirm" onSaveClick={renderProps.onSaveClick} savePermission={renderProps.savePermission} close={(wasSaved) => (wasSaved ? renderProps.close() : setConfirmationVisible(false))}/>)}

                                    {/* When the confirmation is not visible and it's not required or there are changes */}
                                    {!confirmationVisible && (!props.confirmationOptions || !hasChanges) && (<CustomSaveDialogActions saveButtonLabel="Done" close={renderProps.close} onSaveClick={renderProps.onSaveClick} savePermission={renderProps.savePermission}/>)}
                                </>}/>)} renderContent={(renderProps) => (<>
                            {!confirmationVisible && (<>
                                    {props.description}
                                    <CustomFlexDialogContent>
                                        <div className={codeEditorWrapper}>
                                            <CodeEditor ref={editorRef} value={sourceCode} language={props.language} allowFullScreen={false} onChange={(value) => {
                        setSourceCode(value);
                    }} autoComplete={props.autocomplete} autoFocus={props.value == null || props.value === undefined || props.value.length === 0} onEscPressed={() => onFocusedEditorEscapePressed()} showToolbar={true} showCopyButton={true} fullHeight={true}/>
                                        </div>
                                    </CustomFlexDialogContent>
                                </>)}
                            {confirmationVisible && props.confirmationOptions && (<CustomFlexDialogContent>
                                    <div className={confirmContainerStyles}>
                                        {props.confirmationOptions.headerCallout}
                                        <div className={diffContainerStyles}>
                                            <DisplayDiff oldValue={initialSourceCode} newValue={sourceCode} splitView leftTitle="Original" rightTitle="Changed"/>
                                        </div>
                                    </div>
                                </CustomFlexDialogContent>)}
                        </>)}/>)}/>);
};
export default SourceCodeDialog;
