/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButtonType, Switch } from "@octopusdeploy/design-system-components";
import type { EnvironmentResource, FeatureToggleResource, TenantResource, ToggleResource } from "@octopusdeploy/octopus-server-client";
import { type LinkHref, links } from "@octopusdeploy/portal-routes";
import _, { cloneDeep } from "lodash";
import * as React from "react";
import EditToggleDialog from "~/areas/projects/components/FeatureToggles/EditToggleDialog";
import ToggleScope from "~/areas/projects/components/FeatureToggles/ToggleScope";
import { ProjectFormPageLayout } from "~/areas/projects/components/ProjectFormPageLayout";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/withProjectContext";
import { withProjectContext } from "~/areas/projects/context/withProjectContext";
import { repository } from "~/clientInstance";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import DialogOpener from "~/components/Dialog/DialogOpener";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent/index";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { RemoveItemsList } from "~/components/RemoveItemsList/RemoveItemsList";
import { ExpandableFormSection, FormSection, required, Summary } from "~/components/form";
import SlugSummary from "~/primitiveComponents/form/Slugs/SlugSummary";
import Text from "~/primitiveComponents/form/Text/Text";
interface FeatureTogglePageSharedProps {
    spaceId: string;
}
interface FeatureTogglePageEditProps extends FeatureTogglePageSharedProps {
    create: false;
    featureToggleSlug: string;
}
interface FeatureTogglePageCreateProps extends FeatureTogglePageSharedProps {
    create: true;
}
type FeatureTogglePageInternalProps = (FeatureTogglePageEditProps | FeatureTogglePageCreateProps) & WithProjectContextInjectedProps;
type NewFeatureToggleResource = Omit<FeatureToggleResource, "Id" | "SpaceId" | "Slug" | "Links">;
type Model = FeatureToggleResource | NewFeatureToggleResource;
interface FeatureTogglePageState extends OptionalFormBaseComponentState<Model> {
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    model: Model;
    cleanModel: Model;
    redirectTo?: LinkHref;
    toggleEdit?: ToggleResource;
    toggleEditIdx?: number;
}
class ToggleList extends RemoveItemsList<ToggleResource> {
}
class FeatureTogglePageInternal extends DataBaseComponent<FeatureTogglePageInternalProps, FeatureTogglePageState> {
    constructor(props: FeatureTogglePageInternalProps) {
        super(props);
        this.state = {
            environments: [],
            tenants: [],
            model: null!,
            cleanModel: null!,
        };
    }
    async componentDidMount() {
        const { model: project } = this.props.projectContext.state;
        await this.doBusyTask(async () => {
            const getEnvironmentsResponse = await repository.Projects.getAvailableEnvironmentsForProject(project.Id);
            const tenants = await repository.Tenants.all({ projectId: project.Id });
            this.setState({ environments: getEnvironmentsResponse.Environments, tenants });
        });
        if (this.props.create) {
            const newModel: NewFeatureToggleResource = {
                ProjectId: project.Id,
                Name: "",
                Toggles: [],
            };
            this.setState({ model: newModel, cleanModel: cloneDeep(newModel) });
            return;
        }
        const model = await repository.FeatureToggles.get(project, this.props.featureToggleSlug);
        this.setState({ model: model, cleanModel: cloneDeep(model) });
    }
    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true}/>;
        }
        const project = this.props.projectContext.state.model;
        const overflowActions: MenuItem[] = [OverflowMenuItems.deleteItemDefault("feature toggle", this.deleteToggle)];
        const editToggleDialog = (<DialogOpener open={!!this.state.toggleEdit} onClose={() => this.setState({ toggleEdit: undefined, toggleEditIdx: undefined })} wideDialog={true}>
                <EditToggleDialog onSave={this.saveToggle} environments={this.state.environments} tenants={this.state.tenants} toggle={this.state.toggleEdit!} isNew={false}/>
            </DialogOpener>);
        return (<ProjectFormPageLayout breadcrumbTitle={"Feature Toggles"} breadcrumbPath={links.createFeatureTogglePage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug })} title={"Feature Toggle"} busy={this.state.busy} errors={this.errors} onSaveClick={this.handleSaveClick} model={this.state.model} cleanModel={this.state.cleanModel} overFlowActions={overflowActions}>
                {this.state.model && (<div>
                        {editToggleDialog}
                        <ExpandableFormSection errorKey="Name" title="Name" summary={this.nameSummary()}>
                            <Text value={this.state.model.Name} label={"Feature Name"} validate={required("Please provide a name for the feature")} onChange={(value) => {
                    this.setState({ model: { ...this.state.model, Name: value } });
                }}/>
                        </ExpandableFormSection>
                        <FormSection title="Toggles" includeBorder={true}>
                            <OpenDialogButton label="Add Toggle" type={ActionButtonType.Secondary} wideDialog={true}>
                                <EditToggleDialog onSave={this.addToggle} environments={this.state.environments} tenants={this.state.tenants} isNew={true}/>
                            </OpenDialogButton>
                            <ToggleList data={this.state.model.Toggles} onRow={(toggle, idx) => this.toggleRow(toggle, idx)} onRowTouch={(t) => this.setState({ toggleEdit: t, toggleEditIdx: this.state.model.Toggles.indexOf(t) })}/>
                        </FormSection>
                    </div>)}
            </ProjectFormPageLayout>);
    }
    nameSummary() {
        return this.state.model && this.state.model.Name ? Summary.summary(<SlugSummary slug={this.state.model.Name}/>) : Summary.placeholder("Please enter a name for the feature");
    }
    toggleRow = (toggle: ToggleResource, idx: number) => {
        const environment = _.find(this.state.environments, (e) => e.Id === toggle.DeploymentEnvironmentId);
        if (!environment) {
            throw new Error("Could not find environment");
        }
        const tenants = _.filter(this.state.tenants, (t) => toggle.TenantIds.indexOf(t.Id) !== -1);
        const handleClick = (e: React.MouseEvent<HTMLSpanElement>) => e.stopPropagation();
        return (<div>
                <span onClick={handleClick}>
                    <Switch value={toggle.IsEnabled} onChange={(value) => this.setToggle(value, idx)}/>
                </span>
                <ToggleScope toggle={toggle} environmentName={environment.Name} tenants={tenants}/>
            </div>);
    };
    setToggle = (value: boolean, idx: number) => {
        const model = this.state.model;
        const toggles = [...model.Toggles];
        const updatedToggle = { ...toggles[idx], IsEnabled: value };
        toggles[idx] = updatedToggle;
        this.setState({ model: { ...model, Toggles: toggles } });
    };
    handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const project = this.props.projectContext.state.model;
            if (this.props.create) {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                const newFeatureToggle = this.state.model as NewFeatureToggleResource;
                const createResponse = await repository.FeatureToggles.create(project, {
                    ...newFeatureToggle,
                    Toggles: newFeatureToggle.Toggles.map((t) => ({ ...t, TenantIds: t.TenantIds })),
                });
                // If we have created a new feature then redirect so the url is correct
                const redirectTo = links.editFeatureTogglePage.generateUrl({ spaceId: createResponse.SpaceId, projectSlug: this.props.projectContext.state.model.Slug, featureToggleSlug: createResponse.Slug });
                this.setState({ redirectTo });
                return;
            }
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const existingModel = this.state.model as FeatureToggleResource;
            const updateResponse = await repository.FeatureToggles.modify(project, {
                ...existingModel,
                Toggles: existingModel.Toggles.map((t) => ({ ...t, TenantIds: t.TenantIds })),
            });
            this.setState({
                model: updateResponse,
                cleanModel: cloneDeep(updateResponse),
            });
        });
    };
    addToggle = async (toggle: ToggleResource) => {
        const model = this.state.model;
        model.Toggles.push(toggle);
        this.setState({
            model,
        });
        return true;
    };
    saveToggle = async (toggle: ToggleResource) => {
        const toggles = _.cloneDeep(this.state.model.Toggles);
        if (this.state.toggleEditIdx !== undefined) {
            toggles[this.state.toggleEditIdx] = toggle;
        }
        else {
            toggles.push(toggle);
        }
        const model = { ...this.state.model, Toggles: toggles };
        this.setState({
            model,
        });
        return true;
    };
    deleteToggle = async (): Promise<boolean> => {
        if (!this.isExistingToggle(this.state.model))
            return false;
        const project = this.props.projectContext.state.model;
        await repository.FeatureToggles.delete(project, this.state.model.Id);
        this.setState({ redirectTo: links.featureTogglesPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }) });
        return true;
    };
    isExistingToggle(toggle: FeatureToggleResource | NewFeatureToggleResource): toggle is FeatureToggleResource {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return (toggle as FeatureToggleResource).Id !== undefined;
    }
    static displayName = "FeatureTogglePageInternal";
}
export const FeatureTogglePage = withProjectContext(FeatureTogglePageInternal);
