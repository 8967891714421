import type { InsightsReportResource } from "@octopusdeploy/octopus-server-client";
import type { PageRouteDefinition, QueryParamValues, QueryParamValuesSetter, UnknownQueryParam } from "@octopusdeploy/portal-routes";
import type { ReactElement } from "react";
import type { PageIdentity } from "~/routing/pageRegistrations/PageIdentity";
import type { PageRegistration } from "~/routing/pageRegistrations/PageRegistration";
export interface InsightsPageRegistration<RouteParams, QueryParams extends UnknownQueryParam[]> extends PageRegistration<RouteParams, QueryParams> {
    render: (parameters: RouteParams, queryParams: QueryParamValues<QueryParams>, setQueryParams: QueryParamValuesSetter<QueryParamValues<QueryParams>>) => ReactElement;
}
export function createInsightsPageRegistration<RouteParams, QueryParams extends UnknownQueryParam[]>(route: PageRouteDefinition<RouteParams, QueryParams>, render: (parameters: RouteParams, queryParams: QueryParamValues<QueryParams>, setQueryParams: QueryParamValuesSetter<QueryParamValues<QueryParams>>) => ReactElement, pageIdentity: PageIdentity): InsightsPageRegistration<RouteParams, QueryParams> {
    return {
        route,
        render,
        pageIdentity,
        verticalNavigationPageArea: "Insights",
        searchKeywords: [],
    };
}
export interface InsightsReportPageRegistration<RouteParams, QueryParams extends UnknownQueryParam[]> extends PageRegistration<RouteParams, QueryParams> {
    render: (report: InsightsReportResource, refreshReport: () => Promise<void>, parameters: RouteParams, queryParams: QueryParamValues<QueryParams>, setQueryParams: QueryParamValuesSetter<QueryParamValues<QueryParams>>) => ReactElement;
}
export function createInsightsReportPageRegistration<RouteParams, QueryParams extends UnknownQueryParam[]>(route: PageRouteDefinition<RouteParams, QueryParams>, render: (report: InsightsReportResource, refreshReport: () => Promise<void>, parameters: RouteParams, queryParams: QueryParamValues<QueryParams>, setQueryParams: QueryParamValuesSetter<QueryParamValues<QueryParams>>) => ReactElement, pageIdentity: PageIdentity): InsightsReportPageRegistration<RouteParams, QueryParams> {
    return {
        route,
        render,
        pageIdentity,
        verticalNavigationPageArea: "Insights",
        searchKeywords: [],
    };
}
