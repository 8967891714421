import React from "react";
import type { FormPaperLayoutProps } from "~/components/FormPaperLayout/FormPaperLayout";
import FormPaperLayout from "~/components/FormPaperLayout/FormPaperLayout";
import { ProjectPageTitleAccessory } from "./ProjectPageTitleAccessory";
type FormPaperLayoutWithBranchSelectorProps = React.PropsWithChildren<FormPaperLayoutProps>;
export function ProjectFormPageLayout({ children, ...rest }: FormPaperLayoutWithBranchSelectorProps) {
    return (<FormPaperLayout titleAccessory={<ProjectPageTitleAccessory />} {...rest}>
            {children}
        </FormPaperLayout>);
}
