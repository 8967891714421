/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import DialogContent from "@material-ui/core/DialogContent";
import { ActionButton, ActionButtonType, Callout } from "@octopusdeploy/design-system-components";
import { useEffect } from "react";
import * as React from "react";
import Dialog from "~/components/Dialog/Dialog";
interface Props {
    message: React.ReactNode;
    onContinueNavigating(): void;
    onCancelNavigation(): void;
    onSaveClick(): Promise<void>;
    saveLabel?: string;
}
function ConfirmNavigate({ message, onContinueNavigating, onCancelNavigation, onSaveClick, saveLabel = "Save changes" }: Props) {
    const [show, setShow] = React.useState(true);
    const [success, setSuccess] = React.useState(true);
    const handleSaveClick = React.useCallback(() => {
        setShow(false);
        onSaveClick();
    }, [onSaveClick]);
    const continueNavigating = React.useCallback(() => {
        setShow(false);
        onContinueNavigating();
    }, [onContinueNavigating]);
    const cancelNavigation = React.useCallback(() => {
        setShow(false);
        onCancelNavigation();
    }, [onCancelNavigation]);
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                cancelNavigation();
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => document.removeEventListener("keydown", handleKeyDown);
    }, [cancelNavigation]);
    const actions = [
        <ActionButton key="SaveChanges" label={saveLabel} type={ActionButtonType.Secondary} onClick={handleSaveClick}/>,
        <ActionButton key="discard" label="Discard changes" type={ActionButtonType.Secondary} onClick={continueNavigating}/>,
        <ActionButton key="cancel" label="Cancel" type={ActionButtonType.Primary} keyboardFocused={true} onClick={cancelNavigation}/>,
    ];
    return (<Dialog title="Unsaved Changes" actions={actions} open={show}>
            <DialogContent>
                {message}
                {!success && (<Callout title="Errors found" type={"danger"}>
                        Please cancel this dialog to review the errors (or discard your changes).
                    </Callout>)}
            </DialogContent>
        </Dialog>);
}
export default ConfirmNavigate;
