/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { MenuItem } from "@material-ui/core";
import Select from "@material-ui/core/Select/Select";
import { Callout, IconButton, NavigationButton, NavigationButtonType, Switch, Tooltip } from "@octopusdeploy/design-system-components";
import type { ActivityElement, GitRefResource, TaskDetailsResource } from "@octopusdeploy/octopus-server-client";
import { ActivityLogEntryCategory, ActivityStatus } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import { concat, flatten, without } from "lodash";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import ActionList from "~/components/ActionList";
import { Section } from "~/components/Section/Section";
import type { UniqueActivityElement } from "~/components/TaskLogLines/TaskLogBlock";
import TaskLogBlock from "~/components/TaskLogLines/TaskLogBlock";
import { Popover } from "~/primitiveComponents/dataDisplay/Popover/Popover";
import TaskProgress from "../TaskProgress";
import styles from "./style.module.less";
interface TaskLogComponentProps {
    details: TaskDetailsResource;
    activityElements: UniqueActivityElement[];
    verbose: boolean;
    tail: boolean;
    initialExpandedId?: string;
    onFetchRange(element: ActivityElement, start: number, end: number): void;
    isFetchDisabled: boolean;
    setVerbose(value: boolean): void;
    stepsCorrelationIds?: {
        [key: string]: string;
    };
    gitRef?: GitRefResource;
}
type TaskLogProps = TaskLogComponentProps & RouteComponentProps<any>;
interface TaskDetailState {
    expandedIds: string[];
    expandMode: ExpandMode;
    showTimestamps: boolean;
    showPopover: boolean;
    reloadCount?: number;
    settingsButtonElement?: Element;
}
enum ExpandMode {
    All = "All",
    Interesting = "Interesting",
    Errors = "Errors",
    None = "None",
    Custom = "Custom"
}
const timingsLocalStorageKey = "Octopus.TaskLog.ShowTimings";
//eslint-disable-next-line react/no-unsafe
class TaskLog extends React.Component<TaskLogProps, TaskDetailState> {
    reloadCount = 0;
    constructor(props: TaskLogProps) {
        super(props);
        // noinspection TsLint
        this.state = {
            showPopover: false,
            showTimestamps: localStorage.getItem(timingsLocalStorageKey) == "true" ?? false,
            expandedIds: this.getInterestingIds(),
            expandMode: ExpandMode.Interesting,
        };
    }
    componentDidMount() {
        this.reloadCount++;
        if (this.props.initialExpandedId) {
            this.updateStateWithInitialExpandedId(this.props);
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps: TaskLogProps) {
        this.reloadCount++;
        if (this.props.initialExpandedId !== nextProps.initialExpandedId) {
            this.updateStateWithInitialExpandedId(nextProps);
        }
    }
    updateStateWithInitialExpandedId(props: TaskLogProps) {
        if (props.initialExpandedId) {
            this.setState({
                expandedIds: this.getParents(props.initialExpandedId),
                expandMode: ExpandMode.Custom,
                reloadCount: this.reloadCount,
            });
        }
    }
    getParents(id: string) {
        const seperator = "/";
        const firstSection = id.indexOf(seperator);
        const ids = [id];
        if (firstSection === -1) {
            // No first section... weird..
            return ids;
        }
        let parentSection = id.lastIndexOf(seperator);
        while (parentSection !== -1) {
            const parentId = id.substring(0, parentSection);
            parentSection = parentId.lastIndexOf(seperator);
            ids.push(parentId);
        }
        return ids;
    }
    setExpanded = (id: string, expanded: boolean) => {
        this.setState((prevState) => {
            const expandedIds = expanded ? concat(prevState.expandedIds, id) : without(prevState.expandedIds, id);
            return { expandedIds, expandMode: ExpandMode.Custom, focusId: null, showTimestamps: prevState.showTimestamps, showPopover: prevState.showPopover };
        });
    };
    getElementIdsMatching(predicate: (element: UniqueActivityElement) => boolean) {
        return flatten(this.props.activityElements.map((e) => this.getElementsMatchingRecursive(e, predicate))).map((e) => e.uniqueId);
    }
    getElementsMatchingRecursive(element: UniqueActivityElement, predicate: (element: UniqueActivityElement) => boolean): UniqueActivityElement[] {
        const ids = flatten(element.Children.map((l) => this.getElementsMatchingRecursive(l as UniqueActivityElement, predicate)));
        if (ids.length > 0 || predicate(element)) {
            ids.push(element);
        }
        return ids;
    }
    expand(expandMode: ExpandMode) {
        this.setState({ expandedIds: this.getIdsToExpand(expandMode), expandMode });
    }
    getIdsToExpand(mode: ExpandMode) {
        switch (mode) {
            case ExpandMode.All:
                return this.getElementIdsMatching((e) => true);
            case ExpandMode.Interesting:
                return this.getInterestingIds();
            case ExpandMode.Errors:
                return this.getElementIdsMatching((e) => e.LogElements.filter((l) => l.Category === ActivityLogEntryCategory.Error || l.Category === ActivityLogEntryCategory.Fatal).length > 0);
            case ExpandMode.None:
                return [];
            default:
                return this.state.expandedIds;
        }
    }
    getInterestingIds() {
        return this.getElementIdsMatching((e) => e.Status === ActivityStatus.Pending ||
            e.Status === ActivityStatus.Running ||
            e.Status === ActivityStatus.Canceled ||
            e.LogElements.filter((l) => l.Category === ActivityLogEntryCategory.Error || l.Category === ActivityLogEntryCategory.Fatal).length > 0);
    }
    renderActivityLogs = (element: UniqueActivityElement) => {
        const focusId = this.state.reloadCount === this.reloadCount ? this.props.initialExpandedId : null;
        return (<TaskLogBlock key={element.uniqueId} element={element} taskState={this.props.details.Task.State} collapsible={true} expandedIds={this.state.expandedIds} focusId={focusId!} showRunTime={true} showTimestamps={this.state.showTimestamps} setExpanded={(id, expanded) => this.setExpanded(id, expanded)} onFetchRange={this.props.onFetchRange} isFetchDisabled={this.props.isFetchDisabled} stepsCorrelationIds={this.props.stepsCorrelationIds} gitRef={this.props.gitRef} parentDuration={0}/>);
    };
    render() {
        const details = this.props.details;
        const hasWritten = details.ActivityLogs.filter((l) => l.LogElements.length > 0 || l.Children.length > 0).length > 0;
        const toggleTimings = () => {
            const value = !this.state.showTimestamps;
            localStorage.setItem(timingsLocalStorageKey, value ? "true" : "");
            this.setState({
                showTimestamps: value,
            });
        };
        const toggleSettingsPopover = (e: React.MouseEvent) => {
            e.preventDefault();
            this.setState({
                showPopover: !this.state.showPopover,
                settingsButtonElement: e.currentTarget,
            });
        };
        return (<div>
                <Section bodyClassName={styles.taskLogHeading}>
                    <div>
                        <TaskProgress details={details}/>
                    </div>
                    <div className={styles.filterActionContainer}>
                        <div className={styles.filters}>
                            <div className={styles.filter}>
                                <Tooltip content="Expand">
                                    <Select value={this.state.expandMode} onChange={(event) => this.expand(event.target.value as ExpandMode)} label="Expand">
                                        {[ExpandMode.All, ExpandMode.Interesting, ExpandMode.Errors, ExpandMode.None, ExpandMode.Custom].map((m) => (<MenuItem key={m} value={m}>
                                                {m}
                                            </MenuItem>))}
                                    </Select>
                                </Tooltip>
                            </div>
                            <div className={styles.filter}>
                                <Tooltip content="Level of detail to show in logs">
                                    <Select value={this.props.verbose.toString()} onChange={(event) => this.props.setVerbose(event.target.value === "true")} label="Log level">
                                        <MenuItem value={"false"}>Info</MenuItem>
                                        <MenuItem value={"true"}>Verbose</MenuItem>
                                    </Select>
                                </Tooltip>
                            </div>
                        </div>
                        <div className={cn(styles.taskActions)}>
                            <ActionList actions={[
                this.props.details.PhysicalLogSize < 10 * 1024 * 1024 ? (<NavigationButton type={NavigationButtonType.Ternary} icon={<em className={cn("fa-solid", `fa-file-lines`)} aria-hidden="true"/>} href={links.taskRawLogPage.generateUrl({ taskId: details.Task.Id })} label="Raw Log"/>) : (<Tooltip content="This is a large log file and may cause your browser to freeze or crash. Please download the file instead.">
                                            <NavigationButton type={NavigationButtonType.Ternary} disabled={true} icon={<em className={cn("fa-solid", `fa-file-lines`)} aria-hidden="true"/>} href={links.taskRawLogPage.generateUrl({ taskId: details.Task.Id })} label="Raw Log"/>
                                        </Tooltip>),
                <NavigationButton type={NavigationButtonType.Ternary} icon={<em className={cn("fa-solid", `fa-download`)} aria-hidden="true"/>} href={details.Links["Raw"]} label="Download" external={true}/>,
                <IconButton icon="Settings" onClick={toggleSettingsPopover} accessibleName={`Settings`}/>,
            ]}/>

                            <Popover open={this.state.showPopover} anchorEl={this.state.settingsButtonElement || null} onClose={() => this.setState({
                showPopover: !this.state.showPopover,
                settingsButtonElement: undefined,
            })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }} transformOrigin={{ horizontal: "right", vertical: "top" }}>
                                <div className={styles.settingsPopover}>
                                    <div className={styles.settingsSwitch}>
                                        <div>Show log timestamps and categories</div>
                                        <Switch value={this.state.showTimestamps} onChange={(value) => toggleTimings()}/>
                                    </div>
                                </div>
                            </Popover>
                        </div>
                    </div>
                </Section>
                <Section>
                    {hasWritten ? (<div>{this.props.activityElements.map(this.renderActivityLogs)}</div>) : (<Callout type={"information"} title="No Logs">
                            The task has not written any information to the log yet.
                        </Callout>)}
                </Section>
            </div>);
    }
    static displayName = "TaskLog";
}
export default withRouter(TaskLog);
