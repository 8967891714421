import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { links } from "@octopusdeploy/portal-routes";
import { useState } from "react";
import * as React from "react";
import type { StepEditorEvent } from "~/analytics/Analytics";
import { Action } from "~/analytics/Analytics";
import MaxParallelism from "~/areas/projects/components/Process/Common/MaxParallelism";
import styles from "~/areas/projects/components/Process/Common/style.module.less";
import type { RunOn } from "~/areas/projects/components/Process/types";
import { ExecutionLocation } from "~/areas/projects/components/Process/types";
import { repository } from "~/clientInstance";
import { RoleChip } from "~/components/Chips/index";
import { TargetTagsContextualHelp } from "~/components/ContextualHelp/ContextualHelpSnippets";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { TargetTagMultiSelect } from "~/components/MultiSelect/TargetTagMultiSelect";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import InternalLink from "~/components/Navigation/InternalLink";
import { ExpandableFormSection, Note, Summary } from "~/components/form/index";
import ParseHelper from "~/utils/ParseHelper/index";
export type TargetRolesFormSectionProps = {
    projectId: string;
    projectSlug: string;
    expandedByDefault: boolean;
    title?: string;
    availableRoles: string[];
    targetRoles: string | undefined;
    onTargetRolesChanged: (roles: string[]) => void;
    runOn: RunOn;
    errorMessage: string | undefined;
    maxParallelism: string;
    onMaxParallelismChanged: (max: string) => void;
    analyticsStepEditorDispatch: (name: string, event: Omit<StepEditorEvent, "stepTemplate">) => void;
    isKubernetesStep: boolean;
};
const RollingDeploymentSummary = (props: {
    showWindowSize: boolean;
    maxParallelism: string;
}) => props.showWindowSize ? (<span>
            This is a rolling deployments step that will run on{" "}
            <strong>
                {props.maxParallelism} target{props.maxParallelism === "1" ? "" : "s"}
            </strong>{" "}
            at once.
        </span>) : (<span>This step will be run on all deployment targets in parallel.</span>);
const RunOnRolesHelpOld = (props: {
    executionLocation: ExecutionLocation;
}) => (<span>
        Target tags are used to select the deployment targets this step will execute {props.executionLocation === ExecutionLocation.DeploymentTarget ? " on" : " on behalf of"}. Deployment targets are configured in{" "}
        {/*eslint-disable-next-line @typescript-eslint/no-non-null-assertion*/}
        <InternalLink to={links.infrastructureRootRedirect.generateUrl({ spaceId: repository.spaceId! })} openInSelf={false}>
            Infrastructure
        </InternalLink>
        .
    </span>);
const RunOnRolesHelp = (props: {
    isKubernetesStep: boolean;
}) => {
    return props.isKubernetesStep ? <span>Use target tags to select the Kubernetes clusters this step will deploy to.</span> : <span>Use target tags to select the deployment targets this step will deploy to.</span>;
};
type SuggestedTargetRolesProps = Pick<TargetRolesFormSectionProps, "targetRoles" | "onTargetRolesChanged" | "analyticsStepEditorDispatch"> & {
    projectSlug: string;
    isKubernetesStep: boolean;
};
const SuggestedTargetRoles = (props: SuggestedTargetRolesProps) => {
    const { targetRoles, onTargetRolesChanged, analyticsStepEditorDispatch, projectSlug, isKubernetesStep } = props;
    const suggestedRoles = getSuggestedTargetTags(projectSlug, isKubernetesStep);
    const onSelectSuggestedRole = (e: React.MouseEvent<HTMLAnchorElement>, targetName: string) => {
        e.preventDefault();
        const prevTargets = ParseHelper.parseCSV(targetRoles || "");
        if (prevTargets.includes(targetName)) {
            return;
        }
        analyticsStepEditorDispatch?.("Select Suggested Target", { action: Action.Select, resource: "Target Role", value: targetName });
        onTargetRolesChanged(prevTargets.concat(targetName));
    };
    return (<ul className={styles.suggestedTargetRoles}>
            <li key={suggestedRoles}>
                <a href="#" onClick={(e) => onSelectSuggestedRole(e, suggestedRoles)}>
                    {suggestedRoles}
                </a>
            </li>
        </ul>);
};
export const TargetRolesFormSection = (props: TargetRolesFormSectionProps) => {
    const { projectId, projectSlug, title, expandedByDefault, targetRoles, runOn, onTargetRolesChanged, errorMessage, availableRoles, maxParallelism, onMaxParallelismChanged, isKubernetesStep } = props;
    const [showWindowSize, setShowWindowSize] = useState<boolean>(maxParallelism ? maxParallelism.length > 0 : false);
    // used to make sure the rolling deployment section is expanded when it's shown (irrespective of expandedByDefault)
    const [togglingWindowSize, setTogglingWindowSize] = useState(false);
    const isClearerTargetRoleConfigurationFeatureToggleEnabled = isFeatureToggleEnabled("ClearerTargetRoleConfigurationFeatureToggle");
    const roleSummary = () => {
        if (!targetRoles) {
            return Summary.placeholder("No target tags selected");
        }
        const list = ParseHelper.parseCSV(targetRoles);
        const roleChips = list.map((r) => <RoleChip role={r} key={`role-${r}`} showContextualHelp/>);
        return Summary.summary(roleChips);
    };
    return (<>
            <div>
                <ExpandableFormSection isExpandedByDefault={expandedByDefault} summary={roleSummary()} title={title || "Target Tags"} errorKey="Octopus.Action.TargetRoles" help={isClearerTargetRoleConfigurationFeatureToggleEnabled ? <RunOnRolesHelp isKubernetesStep={props.isKubernetesStep}/> : <RunOnRolesHelpOld executionLocation={runOn.executionLocation}/>} contextualHelp={<TargetTagsContextualHelp />}>
                    <Note>
                        Learn more about <ExternalLink href="TargetRoles">target tags</ExternalLink>.
                    </Note>
                    <TargetTagMultiSelect onChange={onTargetRolesChanged} value={ParseHelper.parseCSV(targetRoles || "")} label={runOn.executionLocation === ExecutionLocation.DeploymentTarget ? `Runs on targets with tags (type to add new)` : `On behalf of target tags (type to add new)`} validate={(roles) => (roles.length === 0 ? "Please enter one or more tags" : "")} error={errorMessage} items={availableRoles} canAdd={true} empty={isClearerTargetRoleConfigurationFeatureToggleEnabled && (<div className={styles.targetRolesEmpty}>
                                    Create {targetRoles ? "another" : "your first"} target tag, for example: {<SuggestedTargetRoles {...props} projectSlug={projectSlug} isKubernetesStep={isKubernetesStep}/>}.{" "}
                                </div>)} accessibleName={"Target tags selector"} analyticsArea="Deployment Process Editor"/>
                    {isClearerTargetRoleConfigurationFeatureToggleEnabled ? <Note>You are required to have at least one target tag.</Note> : <Note>This step will run on all deployment targets with these tags.</Note>}
                    {!showWindowSize && (<>
                            <ActionButton label="Configure a Rolling Deployment" type={ActionButtonType.Ternary} onClick={() => {
                setShowWindowSize(true);
                setTogglingWindowSize(true);
                onMaxParallelismChanged("1");
            }}/>
                        </>)}
                    {showWindowSize && (<>
                            <ActionButton label="Deploy to all Deployment Targets in parallel" type={ActionButtonType.Ternary} onClick={() => {
                setShowWindowSize(false);
                onMaxParallelismChanged("");
            }}/>
                        </>)}
                </ExpandableFormSection>
            </div>
            {showWindowSize && (<div>
                    <ExpandableFormSection isExpandedByDefault={expandedByDefault || togglingWindowSize} summary={Summary.summary(<RollingDeploymentSummary showWindowSize={showWindowSize} maxParallelism={maxParallelism}/>)} title="Rolling Deployment" errorKey="Octopus.Action.RollingDeployment" help="Select the rolling window Octopus should use for this step. ">
                        <Note>
                            Learn more about <ExternalLink href="rolling-deployments">Rolling Deployments</ExternalLink>
                        </Note>
                        <MaxParallelism key="maxparallelism" projectId={projectId} value={maxParallelism} onChange={(x) => onMaxParallelismChanged(x)}/>
                    </ExpandableFormSection>
                </div>)}
        </>);
};
export const getSuggestedTargetTags = (projectSlug: string, isKubernetesStep: boolean) => {
    return `${projectSlug}-${isKubernetesStep ? "cluster" : "target"}`;
};
