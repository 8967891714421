import { CodeIcon } from "@octopusdeploy/design-system-components";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { Permission, ProcessType } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { Action, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import { ProjectGitHubAppAuthCheck } from "~/areas/library/components/GitConnections/GitHubAppAuthCheck";
import { useProjectContext } from "~/areas/projects/context/index";
import type { DoBusyTask } from "~/components/DataBaseComponent";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import type { FormPaperLayoutProps } from "~/components/FormPaperLayout";
import type { PageAction } from "~/components/PageActions/PageActions";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { useIsPageHeaderVNextEnabled } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import StringHelper from "~/utils/StringHelper";
import { LastPublishedChip } from "../../Runbooks/LastPublishedChip";
import RunbooksFormPaperLayout from "../../Runbooks/Layouts/RunbooksFormPaperLayout";
import PublishButton, { usePublishedRunbookSnapshotSetup } from "../../Runbooks/PublishButton";
import ContextualRunNowButton from "../../Runbooks/RunNowButton";
import { useRunbookContext, useOptionalRunbookContext } from "../../Runbooks/RunbookContext";
import { ContextAddStepButton } from "../../Steps";
import { useDispatchSwitchToCodeEditor } from "../CodeEditor/ProcessCodeEditorAnalytics";
import { isProcessCodeEditorEnabled } from "../CodeEditor/isProcessCodeEditorEnabled";
import { useProcessContext } from "../Contexts/ProcessContext";
import { ProjectContextFormPaperLayout } from "./ProjectContextFormPaperLayout";
type ProcessContextFormPaperLayoutPropsInternal = {
    doBusyTask: DoBusyTask;
    onCreateBranch?: (newBranchName: string) => Promise<void>;
    hideAddStepButton?: boolean;
};
type ProcessContextFormPaperLayoutProps = FormPaperLayoutProps & ProcessContextFormPaperLayoutPropsInternal & RouteComponentProps<{
    projectSlug: string;
}>;
const ProcessContextFormPaperLayout: React.FC<ProcessContextFormPaperLayoutProps> = ({ children, ...rest }) => {
    const processContext = useProcessContext();
    const type = processContext.selectors.getProcessType();
    const hasSteps = processContext.selectors.hasSteps();
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    const runbookContext = useOptionalRunbookContext();
    const runbookName = runbookContext?.state.runbook?.Name ?? StringHelper.ellipsis;
    if (type === ProcessType.Runbook) {
        return (<RunbooksProcessEditorFormPaperLayout title={runbookName} breadcrumbTitle="Runbooks" hasSteps={hasSteps} project={project} disableAnimations={true} {...rest}>
                {children}
            </RunbooksProcessEditorFormPaperLayout>);
    }
    else {
        return (<DeploymentsProcessEditorFormPaperLayout title={"Process Editor"} breadcrumbTitle="Process" project={project} disableAnimations={true} {...rest}>
                {children}
            </DeploymentsProcessEditorFormPaperLayout>);
    }
};
ProcessContextFormPaperLayout.displayName = "ProcessContextFormPaperLayout"
export const EnhancedProcessContextFormPaperLayout = withRouter(ProcessContextFormPaperLayout);
const DeploymentsProcessEditorFormPaperLayout: React.FC<FormPaperLayoutProps & {
    project: ProjectResource;
    hideAddStepButton?: boolean;
    onCreateBranch?: (newBranchName: string) => Promise<void>;
}> = ({ project, ...props }) => {
    const deploymentProcessLink = links.deploymentProcessPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug });
    const { title = "Process Editor", breadcrumbPath = deploymentProcessLink, breadcrumbTitle = "Process", fullWidth = true, pageActions = [], ...rest } = props;
    const processContext = useProcessContext();
    const projectContext = useProjectContext();
    const hasSteps: boolean = processContext.selectors.hasStepsInCleanModel();
    const hasUnsavedSteps = processContext.selectors.hasUnsavedSteps();
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabled();
    const isCodeEditorEnabled = isProcessCodeEditorEnabled(project, processContext.selectors.getProcessType());
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectContext.state.model.Id);
    const dispatchSwitchToCodeEditor = useDispatchSwitchToCodeEditor();
    const isImprovedKubernetesStepSelectionEnabled = isFeatureToggleEnabled("ImproveKubernetesStepSelectionFeatureToggle");
    if (isCodeEditorEnabled) {
        const switchToCodeViewButton: PageAction = {
            type: "navigate",
            buttonType: "secondary",
            label: "Code",
            extraContext: "Switch to the code editor to edit the OCL for your deployment process directly",
            icon: <CodeIcon />,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            path: links.branchDeploymentProcessCodePage.generateUrl({ spaceId: projectContext.state.model.SpaceId, projectSlug: projectContext.state.model.Slug, branchName: projectContext.state.gitRef!.CanonicalName }),
            onClick: () => dispatchSwitchToCodeEditor(),
            hasPermissions: isAllowed({ permission: Permission.ProcessView, project: project.Id, tenant: "*" }),
        };
        pageActions.push(switchToCodeViewButton);
    }
    const createReleaseIsAllowed = isPageHeaderVNextEnabled && (hasSteps || project?.IsVersionControlled);
    const createReleaseButton: PageAction = {
        type: "navigate",
        buttonType: "secondary",
        label: "Create Release",
        path: links.createReleasePage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }),
        onClick: () => dispatchAction("Create a release", { resource: "Create Release", action: Action.Add }),
        hasPermissions: isAllowed({ permission: Permission.ReleaseCreate, project: project.Id, tenant: "*" }),
        disabled: !createReleaseIsAllowed,
        titleAltText: createReleaseIsAllowed ? undefined : "Please add steps before creating a release",
    };
    pageActions.push(createReleaseButton);
    if (!isImprovedKubernetesStepSelectionEnabled || props.hideAddStepButton === false) {
        const addStepButton: PageAction = {
            type: "custom",
            content: <ContextAddStepButton />,
            key: "Add Step",
            hasPermissions: isAllowed({ permission: Permission.ProcessEdit, project: project.Id, wildcard: true }),
        };
        pageActions.push(addStepButton);
    }
    return (<ProjectContextFormPaperLayout title={title} pageActions={[...pageActions]} breadcrumbsItems={[{ label: breadcrumbTitle, pageUrl: deploymentProcessLink }]} fullWidth={fullWidth} hidePrimaryAction={isImprovedKubernetesStepSelectionEnabled && !hasUnsavedSteps} {...rest}>
            <ProjectGitHubAppAuthCheck project={project} permission={Permission.ProcessEdit}/>
            {props.children}
        </ProjectContextFormPaperLayout>);
};
DeploymentsProcessEditorFormPaperLayout.displayName = "DeploymentsProcessEditorFormPaperLayout"
const RunbooksProcessEditorFormPaperLayout: React.FC<ProcessContextFormPaperLayoutProps & {
    project: ProjectResource;
    hideAddStepButton?: boolean;
    hasSteps: boolean;
    doBusyTask: DoBusyTask;
}> = ({ project, hasSteps, doBusyTask, pageActions: consumerPageActions = [], ...props }) => {
    const runbooksContext = useRunbookContext();
    const processContext = useProcessContext();
    const runbook = runbooksContext.state.runbook;
    const hasUnsavedSteps = processContext.selectors.hasUnsavedSteps();
    if (!runbook) {
        throw Error("runbook value should exist");
    }
    const publishedRunbookAndTemplate = usePublishedRunbookSnapshotSetup(doBusyTask, runbook);
    const publishedRunbookSnapshot = publishedRunbookAndTemplate?.publishedRunbookSnapshot;
    const runbookRunTemplate = publishedRunbookAndTemplate?.runbookRunTemplate;
    const isRunbookRunTemplateModified = runbookRunTemplate && (runbookRunTemplate.IsRunbookProcessModified || runbookRunTemplate.IsVariableSetModified || runbookRunTemplate.IsLibraryVariableSetModified);
    const titleChip = publishedRunbookSnapshot && <LastPublishedChip project={project} publishedRunbookSnapshot={publishedRunbookSnapshot} isRunbookRunTemplateModified={isRunbookRunTemplateModified}/>;
    const isImprovedKubernetesStepSelectionEnabled = isFeatureToggleEnabled("ImproveKubernetesStepSelectionFeatureToggle");
    const pageActions: PageAction[] = [
        ...consumerPageActions,
        {
            type: "custom",
            content: <PublishButton publishedRunbookSnapshot={publishedRunbookSnapshot} isRunbookRunTemplateModified={isRunbookRunTemplateModified}/>,
            key: "Publish",
            hasPermissions: isAllowed({ permission: Permission.RunbookEdit, project: project.Id, wildcard: true }),
        },
        { type: "custom", content: <ContextualRunNowButton spaceId={runbook.SpaceId} isDisabled={!hasSteps}/>, key: "Run Now", hasPermissions: isAllowed({ permission: Permission.RunbookRunCreate, project: project.Id, wildcard: true }) },
    ];
    if (!isImprovedKubernetesStepSelectionEnabled || props.hideAddStepButton === false) {
        const addStepButton: PageAction = { type: "custom", content: <ContextAddStepButton />, key: "Add Step", hasPermissions: isAllowed({ permission: Permission.RunbookEdit, project: project.Id, wildcard: true }) };
        pageActions.push(addStepButton);
    }
    return (<RunbooksFormPaperLayout title={runbooksContext.state.runbook?.Name} titleChip={titleChip} breadcrumbTitle={"Runbooks"} breadcrumbPath={links.projectRunbooksPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug })} breadcrumbsItems={[{ label: "Runbooks", pageUrl: links.projectRunbooksPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }) }]} pageActions={pageActions} hidePrimaryAction={isImprovedKubernetesStepSelectionEnabled && !hasUnsavedSteps} {...props}>
            {props.children}
        </RunbooksFormPaperLayout>);
};
RunbooksProcessEditorFormPaperLayout.displayName = "RunbooksProcessEditorFormPaperLayout"
