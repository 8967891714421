import { logger } from "@octopusdeploy/logging";
import * as React from "react";
import { useProjectContext } from "~/areas/projects/context";
import Tasks from "~/areas/tasks/components/Tasks/Tasks";
import PaperLayout from "~/components/PaperLayout";
import { ProjectStatus } from "../ProjectStatus/ProjectStatus";
const ProjectTasks: React.FC = () => {
    const projectContext = useProjectContext();
    const project = projectContext.state && projectContext.state.model;
    if (!project) {
        logger.error("Failed to find project from context. This should not happen.");
        return null;
    }
    return (<Tasks restrictToProjectId={project.Id} renderLayout={({ busy, doBusyTask, errors, hasLoadedOnce, children }) => {
            return (<PaperLayout title={"Tasks"} breadcrumbTitle={project.Name} busy={busy} enableLessIntrusiveLoadingIndicator={hasLoadedOnce} errors={errors} statusSection={<ProjectStatus doBusyTask={doBusyTask}/>} fullWidth={true}>
                        {children}
                    </PaperLayout>);
        }}/>);
};
ProjectTasks.displayName = "ProjectTasks"
export default ProjectTasks;
