/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { ActionTemplateResource, CommunityActionTemplateResource } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import Note from "~/primitiveComponents/form/Note/Note";
interface CommunityTemplateInstallationProps {
    spaceId: string;
    communityTemplateId: string;
    detailsLink: LinkHref;
    title: string;
    saveDone(actionTemplate: ActionTemplateResource): void;
    onCancelClick?: () => void;
}
interface CommunityTemplateInstallationState extends DataBaseComponentState {
    communityTemplate?: CommunityActionTemplateResource;
}
class CommunityTemplateInstallation extends DataBaseComponent<CommunityTemplateInstallationProps, CommunityTemplateInstallationState> {
    constructor(props: CommunityTemplateInstallationProps) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        return this.doBusyTask(async () => {
            const communityTemplate = await repository.CommunityActionTemplates.get(this.props.communityTemplateId);
            this.setState({ communityTemplate });
        });
    }
    async save() {
        return this.doBusyTask(async () => {
            const actionTemplate = await repository.CommunityActionTemplates.install(this.state.communityTemplate!);
            this.props.saveDone(actionTemplate);
        });
    }
    render() {
        const body = this.state.communityTemplate && (<div>
                <h4>
                    <InternalLink to={this.props.detailsLink}>{this.state.communityTemplate.Name}</InternalLink>
                </h4>
                <Note>
                    This is a community contributed step that will be installed into your <InternalLink to={links.stepTemplatesPage.generateUrl({ spaceId: this.props.spaceId })}>step templates</InternalLink> library. Learn more about{" "}
                    <ExternalLink href="CommunityContributedStepTemplates">community steps</ExternalLink>.
                </Note>
            </div>);
        return (<SaveDialogLayout title={this.props.title} busy={this.state.busy} errors={this.errors} onSaveClick={() => this.save()} onCancelClick={this.props.onCancelClick}>
                {body}
            </SaveDialogLayout>);
    }
    static displayName = "CommunityTemplateInstallation";
}
export default CommunityTemplateInstallation;
