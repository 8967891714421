import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import PopoverHelp from "~/primitiveComponents/dataDisplay/PopoverHelp/PopoverHelp";
const bulletListStyle = css({
    listStylePosition: "outside",
    listStyleType: "disc",
    paddingLeft: space[24],
});
export const KubernetesAgentStorageContextualHelp = () => (<PopoverHelp trigger="click" placement={"right-start"} absolutePosition={false}>
        <p>
            <strong>Agent storage</strong>
        </p>
        <p>
            The Kubernetes agent uses NFS by default, but you can provide any Storage Class that supports the <code>ReadWriteMany</code> access mode.
        </p>
        <p>
            Consider using your own Storage Class for scenarios where:
            <ul className={bulletListStyle}>
                <li>You remove or restart cluster nodes often</li>
                <li>Privileged containers aren't allowed</li>
            </ul>
        </p>
        <ExternalLink href={"KubernetesAgentStorage"}>Docs</ExternalLink>
    </PopoverHelp>);
