import { css } from "@emotion/css";
import { Callout } from "@octopusdeploy/design-system-components";
import type { DeploymentTargetResource, EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import { MachineModelHealthStatus, ScriptingLanguage } from "@octopusdeploy/octopus-server-client";
import { noOp } from "@octopusdeploy/utilities";
import * as React from "react";
import { useEffect, useState } from "react";
import { AgentTargetDiscoveryProgress } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/AgentTargetDiscoveryProgress";
import type { KubernetesAgentConfigurationResource } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/KubernetesAgentConfigurationDialog";
import { createHelmCommand } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/KubernetesAgentHelmCommand";
import { contentContainerStyles } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/Styles";
import { repository } from "~/clientInstance";
import { CodeEditor, type Language, type TextFormat } from "~/components/CodeEditor/CodeEditor";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import TimeFromNowLabel from "~/components/TimeLabels/TimeFromNowLabel";
import Note from "~/primitiveComponents/form/Note/Note";
type AgentInstallationPageProps = {
    model: KubernetesAgentConfigurationResource;
    environments: EnvironmentResource[];
    onDeploymentTargetFound: (target: DeploymentTargetResource) => void;
    hasTokenExpired: boolean;
    accessTokenExpiry: moment.Moment | undefined;
};
interface TargetHealthPollingComponentProps {
    name: string;
    isFound: boolean;
    healthStatus: MachineModelHealthStatus;
}
const GetMachineByName = async (name: string) => await repository.Machines.list({ name });
export function AgentInstallationPage(props: AgentInstallationPageProps) {
    const [hideTargetSearch, setHideTargetSearch] = useState(true);
    const [isFound, setIsFound] = useState(false);
    const [agentHealth, setAgentHealth] = useState(MachineModelHealthStatus.Unknown);
    useEffect(() => {
        // Set a timeout to show the component after 2000 milliseconds (2 seconds)
        const timeoutId = setTimeout(() => {
            setHideTargetSearch(false);
        }, 2000);
        // Clean up the timeout when the component unmounts
        return () => clearTimeout(timeoutId);
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            const machines = await GetMachineByName(props.model.Name);
            return machines.Items.find((m) => m.Name === props.model.Name);
        };
        const pollingInterval = 2000; // Two seconds
        const intervalId = setInterval(async () => {
            const target = await fetchData();
            // TODO: Handle not finding it at all - extra timeout?
            //Target has been found
            if (target !== null && target !== undefined) {
                if (!isFound) {
                    setIsFound(true);
                    props.onDeploymentTargetFound(target);
                }
                // Only stop polling once we have the machine health
                if (target.HealthStatus !== MachineModelHealthStatus.Unknown) {
                    clearInterval(intervalId);
                    setAgentHealth(target.HealthStatus);
                }
            }
        }, pollingInterval);
        return () => clearInterval(intervalId);
    }, [isFound, props, props.model.Name]);
    const [selectedLanguage, setSelectedLanguage] = useState<ScriptingLanguage | Language | TextFormat>(ScriptingLanguage.Bash);
    const helmCommand = createHelmCommand(selectedLanguage, props.model, props.environments);
    const tokenExpiredAndHaveGivenUp = !isFound && props.hasTokenExpired;
    const expiryTimeInfo = !isFound && props.accessTokenExpiry && (<>
            Your token expires{" "}
            <b>
                <TimeFromNowLabel time={props.accessTokenExpiry.toISOString()}/>
            </b>
            .
        </>);
    const helmCommandLineCount = helmCommand.split(/\r\n|\r|\n/).length;
    //adjust the height of the code editor based on the number of lines in the helm command
    const codeEditorContainer = css({
        // 1.25rem per line (line height) and roughly 3rem of padding/margins/scrollbars
        minHeight: `calc((${helmCommandLineCount} * 1.25rem) + 3rem)`,
    });
    return (<div className={contentContainerStyles}>
            {!tokenExpiredAndHaveGivenUp && (<>
                    <p>We've generated a custom Helm command for you which includes a short-lived bearer token for Octopus Server. {expiryTimeInfo}</p>
                    <p>Copy and run this Helm command in a terminal connected to your Kubernetes cluster.</p>
                </>)}
            {tokenExpiredAndHaveGivenUp && (<Callout title={"Your bearer token has expired"} type={"danger"}>
                    <p>Regenerate the token and copy the updated Helm command.</p>
                </Callout>)}
            <CodeEditor value={helmCommand} readOnly lineWrapping={false} showCopyButton showLineNumbers={false} language={selectedLanguage} allowFullScreen containerClassName={codeEditorContainer} settingsOverride={{ theme: "default", wordWrap: false }} onChange={noOp} scriptingLanguageSelectorOptions={{
            supportedLanguages: [ScriptingLanguage.Bash, ScriptingLanguage.PowerShell],
            onScriptingLanguageChanged: (syntax: ScriptingLanguage | Language | TextFormat) => setSelectedLanguage(syntax),
        }}/>
            <Note>
                Use of the Kubernetes agent is subject to our <ExternalLink href={"https://octopus.com/company/legal"}>Customer Agreement</ExternalLink> with you.
            </Note>
            {!hideTargetSearch && !tokenExpiredAndHaveGivenUp && <AgentTargetDiscoveryProgress name={props.model.Name} isFound={isFound} healthStatus={agentHealth}/>}
        </div>);
}
