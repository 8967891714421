import type { GitHubAppInstallation } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { CreateGitHubAppConnectionCommand } from "@octopusdeploy/octopus-server-client/src/repositories/gitHubAppRepository";
import { links } from "@octopusdeploy/portal-routes";
import React, { useState } from "react";
import { Action } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import DataBaseComponent, { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import FormPaperLayout from "~/components/FormPaperLayout";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import LibraryLayout from "../LibraryLayout";
import { GitConnectionAnalyticView } from "./Analytics/GitConnectionAnalyticView";
import { useTrackedGitConnectionEvent } from "./Analytics/useTrackedGitConnectionEvent";
import { GitHubAppAuthCheck } from "./GitHubAppAuthCheck";
import { GitHubAppNewRepositorySelector } from "./GitHubAppNewRepositorySelector";
import { GitHubInstallationLogo } from "./GitHubInstallationLogo";
interface AddGitHubConnectionPageInternalProps extends AddGitHubConnectionPageProps {
    doBusyTask: DoBusyTask;
    busy?: Promise<unknown> | boolean;
    errors?: Errors;
}
function AddGitHubConnectionPageInternal({ spaceId, installationId, doBusyTask, busy, errors }: AddGitHubConnectionPageInternalProps) {
    const [installation, setInstallation] = useState<GitHubAppInstallation | undefined>(undefined);
    const [selectedRepositories, setSelectedRepositories] = useState<string[]>([]);
    const trackAction = useTrackedGitConnectionEvent();
    const navigation = useSpaceAwareNavigation();
    useDoBusyTaskEffect(doBusyTask, async () => {
        await getInstallation();
    }, []);
    async function getInstallation() {
        const installations = await repository.GitHubApp.getInstallations(false);
        const matchingInstallations = installations.Installations.filter((i) => i.InstallationId.match(installationId));
        if (matchingInstallations.length === 0) {
            setInstallation(undefined);
        }
        setInstallation(matchingInstallations[0]);
    }
    function setRepositorySelected(selected: boolean, repositoryId: string) {
        if (selected) {
            setSelectedRepositories((prev) => [...prev, repositoryId]);
        }
        else {
            setSelectedRepositories((prev) => prev.filter((id) => id !== repositoryId));
        }
    }
    async function save() {
        await doBusyTask(async () => {
            const command: CreateGitHubAppConnectionCommand = {
                InstallationId: installationId,
                RepositoryIds: selectedRepositories,
            };
            await trackAction("Create new GitHub connection", Action.Add, async () => {
                const response = await repository.GitHubApp.addConnection(command);
                navigation.navigate(links.editGitHubConnectionPage.generateUrl({ spaceId, connectionId: response.ConnectionId }));
            });
        });
    }
    return (<LibraryLayout spaceId={spaceId}>
            <GitConnectionAnalyticView name="View create GitHub connection repository selection page"/>
            <FormPaperLayout model={{}} onSaveClick={save} saveButtonLabel="Connect" errors={errors} title={installation ? `Connect ${installation.AccountLogin}` : "Connect GitHub Account"} titleLogo={<GitHubInstallationLogo installation={installation}/>} breadcrumbTitle={"GitHub Connections"} breadcrumbPath={links.gitConnectionsPage.generateUrl({ spaceId })} busy={busy} savePermission={{ permission: Permission.GitCredentialEdit }} disableDirtyFormChecking={true} forceDisableFormSaveButton={selectedRepositories.length === 0}>
                <GitHubAppAuthCheck actionDescription={"view repositories"}/>
                {installation && <GitHubAppNewRepositorySelector installation={installation} selectedRepositories={selectedRepositories} setRepositorySelected={setRepositorySelected}/>}
            </FormPaperLayout>
        </LibraryLayout>);
}
interface AddGitHubConnectionPageProps {
    spaceId: string;
    installationId: string;
}
export class AddGitHubConnectionPage extends DataBaseComponent<AddGitHubConnectionPageProps> {
    constructor(props: AddGitHubConnectionPageProps) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.clearErrors();
    }
    render() {
        return <AddGitHubConnectionPageInternal spaceId={this.props.spaceId} installationId={this.props.installationId} doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors}/>;
    }
    static displayName = "AddGitHubConnectionPage";
}
