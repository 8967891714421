import { OctoLink } from "@octopusdeploy/utilities";
import outdatedBrowserRework from "outdated-browser-rework";
outdatedBrowserRework({
    browserSupport: {
        Edge: 80, // Edge Chromium starts here. Edge Legacy currently stops at 42.17134 (and is unsupported by Octopus due to its similar issues to IE).
        IE: false,
    },
    requireChromeOnAndroid: false,
    isUnknownBrowserOK: true, // Let's be open to any new browsers (and blacklist only when necessary).
    messages: {
        en: {
            outOfDate: "Your browser is out of date!",
            unsupported: "Your browser is not supported!",
            update: {
                web: "Update your browser to view this website correctly. ",
                googlePlay: "Please install Chrome from Google Play",
                appStore: "Please update iOS from the Settings App",
            },
            url: OctoLink.Create("SupportedBrowsers"),
            callToAction: "See supported browsers",
            close: "Close",
        },
    },
});
import "./outdated-browser.css";
