/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CircularProgress } from "@octopusdeploy/design-system-components";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import configurationSelectors from "~/areas/configuration/reducers/selectors";
import BusyFromPromise from "~/components/BusyFromPromise/BusyFromPromise";
import { toggleDrawer, isDrawerOpen } from "~/components/ContextualHelpLayout/reducers";
import InternalLink from "~/components/Navigation/InternalLink";
import type { MenuItem, OverflowMenuNavLink } from "~/components/OverflowMenu/OverflowMenu";
import type { PageAction, PrimaryPageAction } from "~/components/PageActions/PageActions";
import { PageActions } from "~/components/PageActions/PageActions";
import Breadcrumb from "~/primitiveComponents/navigation/Breadcrumb";
import styles from "./style.module.less";
interface SharedSectionTitleProps {
    title: React.ReactNode;
    titlePath?: LinkHref;
    className?: string;
    titleLogo?: React.ReactNode;
    breadcrumbTitle?: string; // If specified, this text will display above the title.
    breadcrumbPath?: LinkHref; // If specified, this will link your breadcrumb title.
    breadcrumbOverflow?: OverflowMenuNavLink[]; // If specified, this will create a Dropdown button with ternary theme and the specified overflow items
    breadcrumbChip?: JSX.Element; // If specified, this will put the chip next to the  breadcrumb title.
    busy?: Promise<any> | boolean;
    enableLessIntrusiveLoadingIndicator?: boolean;
    hideHelpIcon?: boolean;
    titleAccessory?: React.ReactNode; //If specified, this will be displayed below the section control/actions.
}
interface SectionTitleWithLegacySectionControlProps extends SharedSectionTitleProps {
    /**
     * @deprecated This property is deprecated and will soon be removed.
     * Please use pageActions and overflowActions props.
     */
    sectionControl?: React.ReactNode;
}
interface SectionTitleWithActionsProps extends SharedSectionTitleProps {
    primaryAction?: PrimaryPageAction;
    pageActions?: PageAction[];
    overflowActions?: Array<MenuItem | MenuItem[]>;
}
type SectionTitleProps = SectionTitleWithLegacySectionControlProps | SectionTitleWithActionsProps;
type SectionTitlePropsInternal = SectionTitleProps & {
    isDrawerOpen: boolean;
    isHelpSidebarEnabled: boolean;
    toggleDrawer: () => void;
};
function isSectionTitleWithSectionControlProps(props: SectionTitleProps): props is SectionTitleWithLegacySectionControlProps {
    return "sectionControl" in props && props.sectionControl !== undefined;
}
class SectionTitleInternal extends React.Component<SectionTitlePropsInternal, never> {
    render() {
        const props = this.props;
        return (<div className={cn(styles.container, props.className)}>
                <div className={styles.titleContainer}>
                    <div className={styles.breadcrumbContainer}>
                        <Breadcrumb title={props.breadcrumbTitle} path={props.breadcrumbPath} overflow={props.breadcrumbOverflow}/>
                        {props.breadcrumbChip}
                    </div>
                    <div className={styles.title}>
                        {props.titleLogo && <div className={styles.logo}>{props.titleLogo}</div>}
                        <h2 className={styles.header}>
                            {props.titlePath ? (<InternalLink to={props.titlePath} className={styles.pageTitleLink}>
                                    {props.title}
                                </InternalLink>) : (<span>{props.title}</span>)}
                        </h2>
                        {/* We show a less intrusive refresh indicator for screens that have auto-refresh implemented (because the main horizontal loading bar is annoying). */}
                        {props.enableLessIntrusiveLoadingIndicator && (<div className={styles.refreshIndicator}>
                                <BusyFromPromise promise={props.busy}>
                                    {(busy: boolean) => {
                    return busy && props.enableLessIntrusiveLoadingIndicator ? (<div className={styles.refreshContainer}>
                                                <CircularProgress size={"small"}/>
                                            </div>) : (null!);
                }}
                                </BusyFromPromise>
                            </div>)}
                    </div>
                </div>
                {props.titleAccessory}
                {isSectionTitleWithSectionControlProps(props) ? props.sectionControl : <PageActions actions={props.pageActions} overflowActions={props.overflowActions} primaryAction={props.primaryAction}/>}
            </div>);
    }
    static displayName = "SectionTitleInternal";
}
const SectionTitle = (props: SectionTitleProps) => {
    const dispatch = useDispatch();
    const drawerOpen = useSelector(isDrawerOpen);
    const helpSidebarEnabled = useSelector(configurationSelectors.createFeatureEnabledSelector((t) => t.isHelpSidebarEnabled));
    const dispatchToggleDrawer = React.useCallback(() => dispatch(toggleDrawer()), [dispatch]);
    return <SectionTitleInternal isDrawerOpen={drawerOpen} isHelpSidebarEnabled={helpSidebarEnabled} toggleDrawer={dispatchToggleDrawer} {...props}/>;
};
export default SectionTitle;
