import type { GitRefResource } from "@octopusdeploy/octopus-server-client";
import { ActivityStatus, Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { useParams } from "react-router";
import { Action, ActionStatus, useAnalyticActionDispatch } from "~/analytics/Analytics";
import InternalLink from "~/components/Navigation/InternalLink/index";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import type { UniqueActivityElement } from "~/components/TaskLogLines/TaskLogBlock";
interface StepEditorLinkProps {
    element: UniqueActivityElement;
    stepsCorrelationIds?: {
        [key: string]: string;
    };
    branch?: GitRefResource;
    className?: string;
}
export const StepEditorLink = (props: StepEditorLinkProps) => {
    const dispatchAction = useAnalyticActionDispatch();
    const { element, className, stepsCorrelationIds, branch } = props;
    const { spaceId, projectSlug, projectId } = useParams<{
        spaceId?: string;
        projectSlug?: string;
        projectId?: string;
    }>();
    if (!spaceId || !projectSlug) {
        return null;
    }
    if (!stepsCorrelationIds || !stepsCorrelationIds?.[element.Id]) {
        return null;
    }
    const permissions: Permission.ProcessEdit | Permission.ProcessView | false = isAllowed({
        permission: Permission.ProcessEdit,
        project: projectId,
    })
        ? Permission.ProcessEdit
        : isAllowed({
            permission: Permission.ProcessView,
            project: projectId,
        })
            ? Permission.ProcessView
            : false;
    if (!permissions) {
        return null;
    }
    const stepLink = branch
        ? links.branchDeploymentProcessStepByStepSlugRedirect.generateUrl({ spaceId, projectSlug, branchName: branch.CanonicalName, stepSlug: stepsCorrelationIds[element.Id] })
        : links.deploymentProcessStepByStepSlugRedirect.generateUrl({ spaceId, projectSlug, stepSlug: stepsCorrelationIds[element.Id] });
    const actionStatus = element.Status === ActivityStatus.Success ? ActionStatus.Success : element.Status === ActivityStatus.Failed ? ActionStatus.Failed : element.Status === ActivityStatus.SuccessWithWarning ? ActionStatus.SuccessWithWarning : undefined;
    return (<InternalLink className={className} to={stepLink} onClick={() => dispatchAction("Click Edit Step Link", { resource: "Deployment Process", action: Action.View, isCaCenabled: props.branch === undefined ? "False" : "True", status: actionStatus })}>
            {permissions === Permission.ProcessEdit ? "Edit step" : "View step"}
        </InternalLink>);
};
