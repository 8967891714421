/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { links } from "@octopusdeploy/portal-routes";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle/index";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import PaperLayout from "~/components/PaperLayout";
import { useIsPageHeaderVNextEnabled } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import { SkeletonLoadingLayout } from "~/components/SkeletonLoadingLayout/SkeletonLoadingLayout";
import { BuiltInTask } from "../Task/Task";
interface InterruptionToProjectRedirectState extends DataBaseComponentState {
    redirectTo: LinkHref;
}
interface InterruptionToProjectRedirectProps {
    spaceId?: string;
    interruptionId: string;
}
interface InterruptionToProjectRedirectInternalProps extends InterruptionToProjectRedirectProps {
    isPageHeaderVNextEnabled: boolean;
}
class InterruptionToProjectRedirectInternal extends DataBaseComponent<InterruptionToProjectRedirectInternalProps, InterruptionToProjectRedirectState> {
    constructor(props: InterruptionToProjectRedirectInternalProps) {
        super(props);
        this.state = {
            redirectTo: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const interruptionId = this.props.interruptionId;
            const interruption = await repository.Interruptions.get(interruptionId);
            const task = await repository.Tasks.get(interruption.TaskId);
            if (task.Name === BuiltInTask.Deploy && task.Arguments.DeploymentId) {
                this.setState({ redirectTo: links.deploymentToProjectTaskRedirect.generateUrl({ spaceId: task.SpaceId!, deploymentId: task.Arguments.DeploymentId }) });
                return;
            }
            if (task.Name === BuiltInTask.RunbookRun && task.Arguments.RunbookRunId) {
                this.setState({ redirectTo: links.runbookRunRedirect.generateUrl({ spaceId: task.SpaceId!, runbookRunId: task.Arguments.RunbookRunId }) });
                return;
            }
        });
    }
    render() {
        const redirectTo = this.state.redirectTo;
        if (!redirectTo) {
            return this.props.isPageHeaderVNextEnabled ? (<main>
                    <SkeletonLoadingLayout errors={this.errors}/>
                </main>) : (<main>
                    <AreaTitle link={this.props.spaceId ? links.projectsPage.generateUrl({ spaceId: this.props.spaceId }) : undefined} title="Projects"/>
                    <PaperLayout busy={this.state.busy} fullWidth={true} errors={this.errors}/>
                </main>);
        }
        return <InternalRedirect push={false} to={redirectTo}/>;
    }
    static displayName = "InterruptionToProjectRedirectInternal";
}
export function InterruptionToProjectRedirect(props: InterruptionToProjectRedirectProps) {
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabled();
    return <InterruptionToProjectRedirectInternal {...props} isPageHeaderVNextEnabled={isPageHeaderVNextEnabled}/>;
}
