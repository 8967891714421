import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import CannotCommitCallout from "~/areas/projects/components/VersionControl/CannotCommitCallout";
import { useProjectContext } from "~/areas/projects/context/index";
export function GitCallouts() {
    const projectContext = useProjectContext();
    return (<div className={calloutsStyles}>
            <CannotCommitCallout gitRef={projectContext.state.gitRef}/>
        </div>);
}
const calloutsStyles = css({
    display: "flex",
    flexDirection: "column",
    gap: space["8"],
});
