import { insightsCadenceParam, links, projectInsightsChannelIdParam, projectInsightsEnvironmentIdParam, projectInsightsTenantIdParam, useQueryStringParams } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import React, { useEffect } from "react";
import { useAnalyticSimpleActionDispatch } from "~/analytics/Analytics";
import { InsightsTooltip } from "~/areas/insights/components/ChartTooltips/InsightsTooltip/InsightsTooltip";
import LineChart from "~/areas/insights/components/Charts/LineChart";
import { InsightsChartCard } from "~/areas/insights/components/InsightsChartCard";
import { TrendIndicator } from "~/areas/insights/components/Reports/TrendIndicator/TrendIndicator";
import { getChartAxisDateFormatter } from "~/areas/insights/dataTransformation/dateHelpers";
import { getChartReadyInsightsData, getYAxisUnitOfTimeKeys } from "~/areas/insights/dataTransformation/getChartReadyInsightsData";
import { getEvenlySpacedTickArray, getYAxisDataMax } from "~/areas/insights/dataTransformation/getYAxisDataMax";
import { formatDurationInWords } from "~/areas/insights/dataTransformation/stringHelpers";
import { insightsCadenceLookup, insightsCadenceXAxisTickInterval } from "~/areas/insights/insightsCadence";
import InternalLink from "~/components/Navigation/InternalLink";
import { useIsPageHeaderVNextEnabled } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import type { ProjectInsightsPageProps } from "../ProjectInsightsDataLoader/ProjectInsightsDataLoader";
import { ProjectInsightsDataLoader } from "../ProjectInsightsDataLoader/ProjectInsightsDataLoader";
import styles from "./ProjectInsightsOverview.module.less";
type ProjectInsightsDetailsInnerProps = ProjectInsightsPageProps;
const ProjectInsightsDetailsInner = ({ bffResponse, project, busy, filter }: ProjectInsightsDetailsInnerProps) => {
    const [insightFilters] = useQueryStringParams([projectInsightsChannelIdParam, projectInsightsEnvironmentIdParam, insightsCadenceParam, projectInsightsTenantIdParam]);
    const { cadence } = filter;
    const chartData = getChartReadyInsightsData(bffResponse.Series);
    const { LeadTimeKey, MeanTimeToRecoveryKey } = getYAxisUnitOfTimeKeys(chartData);
    const dispatchAction = useAnalyticSimpleActionDispatch();
    useEffect(() => {
        dispatchAction("View Project Insights Overview");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const currentGranularity = insightsCadenceLookup[cadence].granularity;
    const dateFormatter = getChartAxisDateFormatter(currentGranularity);
    const commonChartProps = {
        xAxisDataKey: "StartOfInterval",
        xAxisType: "category",
        yAxisType: "number",
        xTickFormatter: dateFormatter,
        showLegend: false,
        xAxisTickInterval: insightsCadenceXAxisTickInterval[cadence],
    } as const;
    const frequencyYAxisMax = getYAxisDataMax(chartData, "NumberOfSuccessfulDeployments");
    const leadTimeYAxisMax = getYAxisDataMax(chartData, `LeadTime.mean.${LeadTimeKey}`);
    const timeToRecoveryYAxisMax = getYAxisDataMax(chartData, `MeanTimeToRecovery.mean.${MeanTimeToRecoveryKey}`);
    const { SuccessfulDeploymentDurationKey, DeploymentQueuedDurationKey } = getYAxisUnitOfTimeKeys(chartData);
    const maxRunDurationYAxisValue = getYAxisDataMax(chartData, `SuccessfulDeploymentDuration.mean.${SuccessfulDeploymentDurationKey}`);
    const maxQueueDurationYAxisValue = getYAxisDataMax(chartData, `DeploymentQueuedDuration.mean.${DeploymentQueuedDurationKey}`);
    return (<div className={styles.content}>
            <h2 className={styles.groupHeading}>Tempo</h2>
            <div className={cn(styles.twoColumnGrid, styles.tempoGrid)}>
                <div>
                    <div className={styles.titleContainer}>
                        <InternalLink to={links.projectInsightsFrequencyPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }, insightFilters)}>
                            <h3 className={styles.title}>Deployment frequency</h3>
                        </InternalLink>
                        <TrendIndicator trend={bffResponse.DeploymentFrequency.OverallTrend} trendKey="DeploymentFrequency"/>
                    </div>
                    <InsightsChartCard>
                        <LineChart loading={Boolean(busy)} data={chartData} dataKey="NumberOfSuccessfulDeployments" yAxisLabel="No. of deployments" tooltip={<InsightsTooltip dataKey="NumberOfSuccessfulDeployments" valueFormatter={(v) => `${v.toLocaleString()} deployment${v === 1 ? "" : "s"}`} cadence={cadence}/>} yAxisDomain={[0, frequencyYAxisMax]} yAxisTickArray={getEvenlySpacedTickArray(frequencyYAxisMax)} {...commonChartProps}/>
                    </InsightsChartCard>
                </div>
                <div>
                    <div className={styles.titleContainer}>
                        <InternalLink to={links.projectInsightsLeadTimePage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }, insightFilters)}>
                            <h3 className={styles.title}>Deployment lead time</h3>
                        </InternalLink>
                        <TrendIndicator trend={bffResponse.LeadTime.OverallTrend} trendKey="LeadTime"/>
                    </div>
                    <InsightsChartCard>
                        <LineChart loading={Boolean(busy)} data={chartData} dataKey={`LeadTime.mean.${LeadTimeKey}`} yAxisLabel={`Avg. no. of ${LeadTimeKey.toLowerCase()}`} tooltip={<InsightsTooltip dataKey="LeadTime.mean.duration" valueFormatter={formatDurationInWords} cadence={cadence}/>} yAxisDomain={[0, leadTimeYAxisMax]} yAxisTickArray={getEvenlySpacedTickArray(leadTimeYAxisMax)} {...commonChartProps}/>
                    </InsightsChartCard>
                </div>
            </div>
            <h2 className={styles.groupHeading}>Stability</h2>
            <div className={cn(styles.twoColumnGrid, styles.tempoGrid)}>
                <div>
                    <div className={styles.titleContainer}>
                        <InternalLink to={links.projectInsightsFailureRatePage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }, insightFilters)}>
                            <h3 className={styles.title}>Deployment failure rate</h3>
                        </InternalLink>
                        <TrendIndicator trend={bffResponse.ChangeFailureRate.OverallTrend} trendKey="ChangeFailureRate"/>
                    </div>
                    <InsightsChartCard>
                        <LineChart loading={Boolean(busy)} data={chartData} dataKey="DeploymentFailureRate.ComputedFailureRate" yAxisLabel="Failure rate (%)" yAxisDomain={[0, 100]} yAxisTickArray={getEvenlySpacedTickArray(100)} tooltip={<InsightsTooltip dataKey="DeploymentFailureRate.ComputedFailureRate" valueFormatter={(v) => `${v}%`} cadence={cadence}/>} {...commonChartProps}/>
                    </InsightsChartCard>
                </div>
                <div>
                    <div className={styles.titleContainer}>
                        <InternalLink to={links.projectInsightsTimeToRecoveryPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }, insightFilters)}>
                            <h3 className={styles.title}>Mean time to recovery</h3>
                        </InternalLink>
                        <TrendIndicator trend={bffResponse.MeanTimeToRecovery.OverallTrend} trendKey="MeanTimeToRecovery"/>
                    </div>
                    <InsightsChartCard>
                        <LineChart loading={Boolean(busy)} data={chartData} dataKey={`MeanTimeToRecovery.mean.${MeanTimeToRecoveryKey}`} yAxisLabel={`Avg. no. of ${MeanTimeToRecoveryKey.toLowerCase()}`} tooltip={<InsightsTooltip dataKey="MeanTimeToRecovery.mean.duration" valueFormatter={formatDurationInWords} cadence={cadence}/>} yAxisDomain={[0, timeToRecoveryYAxisMax]} yAxisTickArray={getEvenlySpacedTickArray(timeToRecoveryYAxisMax)} {...commonChartProps}/>
                    </InsightsChartCard>
                </div>
            </div>
            <h2 className={styles.groupHeading}>Duration</h2>
            <div className={styles.twoColumnGrid}>
                <div>
                    <div className={styles.titleContainer}>
                        <InternalLink to={links.projectInsightsDeploymentDurationPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }, insightFilters)}>
                            <h3 className={styles.title}>Successful deployment duration</h3>
                        </InternalLink>
                    </div>
                    <InsightsChartCard>
                        <LineChart loading={Boolean(busy)} data={chartData} dataKey={`SuccessfulDeploymentDuration.mean.${SuccessfulDeploymentDurationKey}`} yAxisLabel={`Avg. no. of ${SuccessfulDeploymentDurationKey.toLowerCase()}`} yAxisDomain={[0, maxRunDurationYAxisValue]} yAxisTickArray={getEvenlySpacedTickArray(maxRunDurationYAxisValue)} tooltip={<InsightsTooltip dataKey="SuccessfulDeploymentDuration.mean.duration" valueFormatter={formatDurationInWords} cadence={cadence}/>} {...commonChartProps}/>
                    </InsightsChartCard>
                </div>
                <div>
                    <div className={styles.titleContainer}>
                        <InternalLink to={links.projectInsightsDeploymentDurationPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }, insightFilters)}>
                            <h3 className={styles.title}>Deployment queued duration</h3>
                        </InternalLink>
                    </div>
                    <InsightsChartCard>
                        <LineChart loading={Boolean(busy)} data={chartData} dataKey={`DeploymentQueuedDuration.mean.${DeploymentQueuedDurationKey}`} yAxisLabel={`Avg. no. of ${DeploymentQueuedDurationKey.toLowerCase()}`} yAxisDomain={[0, maxQueueDurationYAxisValue]} yAxisTickArray={getEvenlySpacedTickArray(maxQueueDurationYAxisValue)} tooltip={<InsightsTooltip dataKey="DeploymentQueuedDuration.mean.duration" valueFormatter={formatDurationInWords} cadence={cadence}/>} {...commonChartProps}/>
                    </InsightsChartCard>
                </div>
            </div>
        </div>);
};
export function ProjectInsightsOverviewPage() {
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabled();
    return <ProjectInsightsDataLoader title={isPageHeaderVNextEnabled ? "Insights" : "Overview"}>{(props) => <ProjectInsightsDetailsInner {...props}/>}</ProjectInsightsDataLoader>;
}
