/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import type { TabProps } from "@material-ui/core/Tab";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles } from "@material-ui/core/styles";
import { useIsUrlActive } from "@octopusdeploy/design-system-components";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import type { ReactNode } from "react";
import * as React from "react";
import { withRouter } from "react-router-dom";
import type { RouteComponentProps } from "react-router-dom";
import { useProjectContext } from "~/areas/projects/context";
import { isUrl } from "~/components/Navigation/InternalLink/isUrl";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { useUrlResolver } from "~/components/Navigation/useUrlResolver";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import type { OctopusTheme } from "~/components/Theme";
import { useOctopusTheme, withTheme } from "~/components/Theme";
import { useRunbookContext } from "../Runbooks/RunbookContext";
type NavTabProps = TabProps;
interface PropsWithOctopusTheme {
    octopusTheme: OctopusTheme;
}
const useMuiStyles = makeStyles(() => ({
    root: (props: PropsWithOctopusTheme) => ({ color: props.octopusTheme.primaryText }),
    indicator: (props: PropsWithOctopusTheme) => ({ backgroundColor: props.octopusTheme.primary }),
}));
const useTabStyles = makeStyles(() => ({
    tabsContainer: (props: PropsWithOctopusTheme) => ({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: props.octopusTheme.paper0,
        borderTopStyle: "solid",
        borderTopWidth: "0.0625rem",
        borderTopColor: props.octopusTheme.dividerLight,
        borderBottomStyle: "solid",
        borderBottomWidth: "0.0625rem",
        borderBottomColor: props.octopusTheme.dividerLight,
    }),
    rootTab: {
        minWidth: "7.5rem", // Needed for DrawerWrapperLayout.
        textTransform: "none",
        fontWeight: "normal",
        letterSpacing: "0",
        fontSize: "0.9125rem",
    },
}));
const useNavTabStyles = makeStyles(() => ({
    root: (props: PropsWithOctopusTheme) => ({
        "&:hover": {
            opacity: 1,
            color: props.octopusTheme.primaryDark,
            transition: "backgroundColor 10s",
        },
    }),
}));
const NavTab: React.FC<NavTabProps> = (props) => {
    const octopusTheme = useOctopusTheme();
    const tabStyles = useNavTabStyles({ octopusTheme });
    return <Tab classes={tabStyles} {...props}/>;
};
NavTab.displayName = "NavTab"
const projectHasTaskViewPermission = (projectId: string) => {
    return isAllowed({
        permission: Permission.TaskView,
        project: projectId,
        wildcard: true,
    });
};
const renderProcessLabelWithWarning = () => {
    return withTheme((theme) => (<div>
            Process&nbsp;
            <em className={"fa-solid fa-exclamation-triangle"} style={{ color: theme.errorText }}/>
        </div>));
};
interface NavigationTab {
    label: ReactNode;
    link: LinkHref;
    disabled?: boolean;
}
function getNavigationTabs(tabs: Array<NavigationTab | undefined>): NavigationTab[] {
    return tabs.filter(isNavigationTab);
}
function isNavigationTab(tab: NavigationTab | undefined): tab is NavigationTab {
    return Boolean(tab);
}
const RunbooksNavigationTabsInternal: React.FC<RouteComponentProps<{
    projectSlug: string;
    spaceId: string;
    runbookId: string;
}>> = (props) => {
    const octopusTheme = useOctopusTheme();
    const isUrlActive = useIsUrlActive();
    const urlResolver = useUrlResolver();
    const navigation = useSpaceAwareNavigation();
    const classes = useMuiStyles({ octopusTheme });
    const tabStyles = useTabStyles({ octopusTheme });
    const runbookContext = useRunbookContext();
    const projectContext = useProjectContext();
    const runbook = runbookContext.state.runbook;
    const spaceId = props.match.params.spaceId;
    const projectSlug = props.match.params.projectSlug;
    const runbookId = props.match.params.runbookId;
    const tabs = getNavigationTabs([
        { label: "Overview", link: links.projectRunbookOverviewPage.generateUrl({ spaceId, projectSlug, runbookId }) },
        projectHasTaskViewPermission(projectContext.state.model.Id) ? { label: "Runs", link: links.projectRunbookRunsListPage.generateUrl({ spaceId, projectSlug, runbookId }) } : undefined,
        runbook
            ? { label: projectContext.state.gitVariablesHasError ? renderProcessLabelWithWarning() : "Process", link: links.projectRunbookProcessListPage.generateUrl({ spaceId, projectSlug, runbookId, processId: runbook.RunbookProcessId }) }
            : undefined,
        { label: "Settings", link: links.projectRunbookSettingsPage.generateUrl({ spaceId, projectSlug, runbookId }) },
    ]);
    // The Tabs element gets very grumpy (logs console errors) if its 'value' property doesn't match the 'link' of one of the NavTabs, so if we can't find a match, default
    // to the whatever the first tab is. This only happens during the initial renders - later on we get the correct value and so always finds a match.
    const tabThatMatchesUrl = tabs.find((x) => isUrlActive(x.link, "if path partially matches")) || tabs[0];
    const urlOfSelectedTab = tabThatMatchesUrl.link;
    return (<div className={tabStyles.tabsContainer}>
            <Tabs value={isUrl(urlOfSelectedTab) ? urlResolver.resolve(urlOfSelectedTab) : urlOfSelectedTab} classes={classes} onChange={(_, val) => {
            navigation.navigate(val);
        }} variant="scrollable" scrollButtons="off">
                {tabs.map((x) => {
            const link = isUrl(x.link) ? urlResolver.resolve(x.link) : x.link;
            return <NavTab key={link} label={x.label} value={link} disabled={x.disabled} className={tabStyles.rootTab}/>;
        })}
            </Tabs>
        </div>);
};
RunbooksNavigationTabsInternal.displayName = "RunbooksNavigationTabsInternal"
const RunbooksNavigationTabs = withRouter(RunbooksNavigationTabsInternal);
export default RunbooksNavigationTabs;
