import { ProcessType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useProjectContext } from "../../context/ProjectContext";
import { ProcessPaperLayout } from "./CustomPaperLayouts/ProcessPaperLayout";
import ProcessStepsLayoutLoader from "./ProcessStepsLayoutLoader";
export function DeploymentProcessStepsPage() {
    const projectContext = useProjectContext();
    if (!projectContext.state.model) {
        return <ProcessPaperLayout processType={ProcessType.Deployment} busy={true}/>;
    }
    return <ProcessStepsLayoutLoader processId={projectContext.state.model.DeploymentProcessId} processType={ProcessType.Deployment}/>;
}
