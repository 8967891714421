/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-eq-null */
import type { ProjectGroupResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { cloneDeep } from "lodash";
import * as React from "react";
import { ProjectPageLayout } from "~/areas/projects/components/ProjectPageLayout";
import { repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import { ContextualHelpLayout } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent";
import { Form } from "~/components/FormPaperLayout/Form";
import Markdown from "~/components/Markdown";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { useIsPageHeaderVNextEnabledOutsideOfProjects } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { ExpandableFormSection, required, Summary, Text } from "~/components/form";
import MarkdownEditor from "~/components/form/MarkdownEditor/MarkdownEditor";
import StringHelper from "~/utils/StringHelper";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import styles from "./style.module.less";
interface ProjectGroupPageInternalProps extends ProjectGroupPageProps {
    isPageHeaderVNextEnabled: boolean;
}
interface ProjectGroupPageState extends OptionalFormBaseComponentState<ProjectGroupResource> {
    deleted: boolean;
    newId: string;
}
class ProjectGroupPageInternal extends FormBaseComponent<ProjectGroupPageInternalProps, ProjectGroupPageState, ProjectGroupResource> {
    constructor(props: ProjectGroupPageInternalProps) {
        super(props);
        this.state = {
            deleted: false,
            newId: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const projectGroupPromise = repository.ProjectGroups.get(this.props.projectGroupId);
            const projectGroup = await projectGroupPromise;
            this.setState({
                model: projectGroup,
                cleanModel: cloneDeep(projectGroup),
            });
        });
    }
    descriptionSummary() {
        return this.state.model!.Description ? Summary.summary(<Markdown markup={this.state.model!.Description}/>) : Summary.placeholder("No project group description provided");
    }
    render() {
        const title = this.props.create ? "Create project group" : this.state.model ? this.state.model.Name : StringHelper.ellipsis;
        const overflowActions: Array<MenuItem | MenuItem[]> = [];
        if (!this.props.create && this.state.model) {
            overflowActions.push(OverflowMenuItems.deleteItemDefault("project group", this.handleDeleteConfirm, { permission: Permission.ProjectGroupDelete, projectGroup: "*" }));
            overflowActions.push([
                OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ regardingAny: [this.state.model.Id] }), {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ]);
        }
        const saveText: string = this.state.newId ? "Project group created" : "Project group details updated";
        const projectsPageUrl = links.projectsPage.generateUrl({ spaceId: this.props.spaceId });
        return (<main>
                {!this.props.isPageHeaderVNextEnabled && <AreaTitle link={projectsPageUrl} title={"Projects"}/>}
                <ContextualHelpLayout>
                    <div className={styles.container}>
                        <Form saveText={saveText} savePermission={{ permission: this.props.create ? Permission.ProjectGroupCreate : Permission.ProjectGroupEdit, projectGroup: "*" }} onSaveClick={this.handleSaveClick} model={this.state.model} cleanModel={this.state.cleanModel}>
                            {({ FormContent, createSaveAction }) => (<ProjectPageLayout title={title} breadcrumbsItems={[{ label: "Projects", pageUrl: projectsPageUrl }]} showBreadcrumbBackIcon={true} busy={this.state.busy} errors={this.errors} primaryAction={createSaveAction({})} overflowActions={overflowActions}>
                                    <FormContent expandAllOnMount={this.props.create}>
                                        {this.state.deleted && <InternalRedirect to={projectsPageUrl}/>}
                                        {this.state.newId && <InternalRedirect to={links.editProjectGroupPage.generateUrl({ spaceId: this.props.spaceId, projectGroupId: this.state.newId })}/>}
                                        {this.state.model && (<TransitionAnimation>
                                                <ExpandableFormSection errorKey="Name" title="Name" summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your project group")} help="A short, memorable, unique name for this project group.">
                                                    <Text value={this.state.model.Name} onChange={(Name) => this.setModelState({ Name })} label="Name" validate={required("Please enter a project group name")} autoFocus={true}/>
                                                </ExpandableFormSection>

                                                <ExpandableFormSection errorKey="description" title="Description" summary={this.descriptionSummary()} help="Enter a description for your project group.">
                                                    <MarkdownEditor value={this.state.model.Description} label="Project group description" onChange={(Description) => this.setModelState({ Description })}/>
                                                </ExpandableFormSection>
                                            </TransitionAnimation>)}
                                    </FormContent>
                                </ProjectPageLayout>)}
                        </Form>
                    </div>
                </ContextualHelpLayout>
            </main>);
    }
    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const isNew = this.state.model!.Id == null;
            const result = await repository.ProjectGroups.save(this.state.model!);
            this.setState({
                model: result,
                cleanModel: cloneDeep(result),
                newId: isNew ? result.Id : null!,
            });
        });
    };
    private handleDeleteConfirm = async () => {
        const result = await repository.ProjectGroups.del(this.state.model!);
        this.setState((state) => {
            return {
                model: null,
                cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                deleted: true,
            };
        });
        return true;
    };
    static displayName = "ProjectGroupPageInternal";
}
interface ProjectGroupPageProps {
    spaceId: string;
    projectGroupId: string;
    create?: boolean;
}
export function ProjectGroupPage(props: ProjectGroupPageProps) {
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabledOutsideOfProjects();
    return <ProjectGroupPageInternal {...props} isPageHeaderVNextEnabled={isPageHeaderVNextEnabled}/>;
}
