/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { KubernetesKustomizeProperties } from "@octopusdeploy/legacy-action-properties";
import type { KubernetesServerSideApplyProperties } from "@octopusdeploy/legacy-action-properties/dist/src/KubernetesServerSideApplyProperties";
import { ActionExecutionLocation, GetNamedPackageReferences, InitialisePrimaryGitDependency } from "@octopusdeploy/octopus-server-client";
import ExpandableFormSection from "app/components/form/Sections/ExpandableFormSection";
import * as _ from "lodash";
import type { PropsWithChildren } from "react";
import * as React from "react";
import { useOptionalProcessContext } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import { useFeedsFromContext, useRefreshFeedsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import { useGitCredentialsFromContext, useRefreshGitCredentialsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessGitCredentialsContextProvider";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { useOptionalProjectContext } from "~/areas/projects/context/index";
import type { ActionSummaryProps } from "~/components/Actions/actionSummaryProps";
import type { ActionWithDirtyState, ActionWithFeeds, ActionWithGitRepositorySource } from "~/components/Actions/commonActionHelpers";
import { getKubernetesTargetDiscoveryCloudProviders } from "~/components/Actions/kubernetes/getKubernetesTargetDiscoveryCloudProviders";
import { kubernetesMixedExecutionLocationConfig, KubernetesStepDefaultEditSection, KubernetesStepTopEditSection } from "~/components/Actions/kubernetes/kubernetesMixedExecutionLocationConfig";
import KubernetesStatusCheckComponent, { InitialStatusCheckWithTimeoutProperties } from "~/components/Actions/kubernetes/kubernetesStatusCheckComponent";
import { ServerSideApplyFormSection } from "~/components/Actions/kubernetes/serverSideApplyFormSection";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import pluginRegistry from "~/components/Actions/pluginRegistry";
import type { ScriptPackageProperties } from "~/components/Actions/script/ScriptPackageReferenceDialog";
import BaseComponent from "~/components/BaseComponent/index";
import { GitRepositorySourceSelector, isProjectRepositoryGitSourceSupported } from "~/components/GitRepositorySource/GitRepositorySourceSelector";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import { FormSectionHeading, Note, required, Summary } from "~/components/form/index";
import PopoverHelp from "~/primitiveComponents/dataDisplay/PopoverHelp/PopoverHelp";
import { toBoolString } from "~/utils/fieldConverters";
import { ContainerImageReferenceList } from "../packageReferences";
interface KubernetesKustomizeActionState {
    serverNames: string[];
}
export interface KubernetesKustomizeActionProperties extends KubernetesKustomizeProperties, KubernetesServerSideApplyProperties {
}
type KubernetesKustomizeActionProps = ActionEditProps<KubernetesKustomizeActionProperties, ScriptPackageProperties>;
type KubernetesKustomizeActionInternalProps = KubernetesKustomizeActionProps & ActionWithFeeds & ActionWithGitRepositorySource & ActionWithDirtyState;
const overlayDirectoryHelp = (<PopoverHelp trigger="click" placement={"right-start"} absolutePosition={false}>
        <p>
            This field only requires the directory path without the file name. When you execute Kustomize, it will automatically look for the <code>kustomization.yaml</code> file in the directory provided.
        </p>
        <p>We recommend using Octopus variables in your directory path to dynamically populate overlays for environments and tenants.</p>
        <p>Keep in mind that directory paths are case-sensitive.</p>
        <ExternalLink href="kustomize-path">Octopus Docs</ExternalLink>
    </PopoverHelp>);
class KubernetesKustomizeActionSummary extends BaseComponent<ActionSummaryProps, never> {
    constructor(props: never) {
        super(props);
    }
    render() {
        return <div>Deploy using Kustomize</div>;
    }
    static displayName = "KubernetesKustomizeActionSummary";
}
export class KubernetesKustomizeActionInternal extends BaseComponent<KubernetesKustomizeActionInternalProps, KubernetesKustomizeActionState> {
    constructor(props: KubernetesKustomizeActionInternalProps) {
        super(props);
        this.state = {
            serverNames: [],
        };
    }
    componentDidMount() {
        this.props.setProperties({ ["Octopus.Action.Script.ScriptSource"]: "GitRepository" });
        if (!this.props.properties["Octopus.Action.GitRepository.Source"]) {
            if (isProjectRepositoryGitSourceSupported(this.props.project, this.props.processType)) {
                this.props.setProperties({ ["Octopus.Action.GitRepository.Source"]: "Project" });
            }
            else {
                this.props.setProperties({ ["Octopus.Action.GitRepository.Source"]: "External" });
                this.props.setGitDependencies?.(InitialisePrimaryGitDependency(this.props.gitDependencies), false);
            }
        }
        if (this.props.properties["Octopus.Action.Kubernetes.ServerSideApply.Enabled"] === undefined) {
            this.setDefaultServerSideApply();
        }
    }
    private setDefaultServerSideApply() {
        this.props.setProperties({ ["Octopus.Action.Kubernetes.ServerSideApply.Enabled"]: "True" });
        this.props.setProperties({ ["Octopus.Action.Kubernetes.ServerSideApply.ForceConflicts"]: "True" });
    }
    onKustomizeOverlayDirectoryChanged = (val: string) => {
        this.props.setProperties({ ["Octopus.Action.Kubernetes.Kustomize.OverlayPath"]: val });
    };
    getKustomizeOverlayDirectorySummary = () => {
        const propertyValue = this.props.properties["Octopus.Action.Kubernetes.Kustomize.OverlayPath"];
        if (!propertyValue) {
            return Summary.placeholder("Not provided");
        }
        return Summary.summary(`${this.props.properties["Octopus.Action.Kubernetes.Kustomize.OverlayPath"]}`);
    };
    onVariableSubstituteFilesChanged = (val: string) => {
        this.props.setProperties({ ["Octopus.Action.SubstituteInFiles.TargetFiles"]: val });
    };
    getVariableSubsituteFilesSummary = () => {
        const propertyValue = this.props.properties["Octopus.Action.SubstituteInFiles.TargetFiles"];
        if (!propertyValue) {
            return Summary.placeholder("Not provided");
        }
        return Summary.summary(`${this.props.properties["Octopus.Action.SubstituteInFiles.TargetFiles"]}`);
    };
    packageVariableNames = (): string[] => _.flatten(GetNamedPackageReferences(this.props.packages).map((pkg) => [
        `Octopus.Action.Package[${pkg.Name}].PackageId`,
        `Octopus.Action.Package[${pkg.Name}].FeedId`,
        `Octopus.Action.Package[${pkg.Name}].PackageVersion`,
        `Octopus.Action.Package[${pkg.Name}].Path`,
    ]));
    packageReferenceSummary = () => {
        const namedPackageReferences = GetNamedPackageReferences(this.props.packages);
        if (namedPackageReferences.length === 0) {
            return Summary.placeholder("No additional packages referenced");
        }
        return Summary.summary(`${namedPackageReferences.length} package references`);
    };
    render() {
        const packageReferences = GetNamedPackageReferences(this.props.packages);
        return (<div>
                <FormSectionHeading title="Configuration"/>
                <GitRepositorySourceSelector properties={this.props.properties} gitCredentials={this.props.gitCredentials} refreshGitCredentials={this.props.refreshGitCredentials} project={this.props.project} processType={this.props.processType} gitDependencies={this.props.gitDependencies} expandedByDefault={this.props.expandedByDefault} getFieldError={this.props.getFieldError} setProperties={this.props.setProperties} setGitDependencies={this.props.setGitDependencies} localNames={this.props.localNames} enableFilePathFilters={false}/>
                <ExpandableFormSection title="Kustomization file directory" isExpandedByDefault={this.props.expandedByDefault} errorKey="Octopus.Action.Kubernetes.Kustomize.OverlayPath" summary={this.getKustomizeOverlayDirectorySummary()} help="Provide the path to the directory where your kustomization.yaml file is located" contextualHelp={overlayDirectoryHelp}>
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.Kubernetes.Kustomize.OverlayPath"]} onChange={(filePaths) => this.onKustomizeOverlayDirectoryChanged(filePaths)} label="Kustomization file directory" error={this.props.getFieldError("Octopus.Action.Kubernetes.Kustomize.OverlayPath")} validate={required("Overlay path can not be empty")} accessibleName="Overlay file directory"/>
                    <Note>
                        If your Kustomize repository uses overlays, consider using the Octopus.Environment.Name variable e.g. <code>overlays/#{"{Octopus.Environment.Name}"}</code> to indicate that you have a subdirectory for each environment.
                    </Note>
                </ExpandableFormSection>
                <ExpandableFormSection title="Substitute Variables in Files" isExpandedByDefault={this.props.expandedByDefault} errorKey="Octopus.Action.SubstituteInFiles.TargetFiles" summary={this.getVariableSubsituteFilesSummary()} help="Configure files to perform variable substitution on.">
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.SubstituteInFiles.TargetFiles"]} onChange={(filePaths) => this.onVariableSubstituteFilesChanged(filePaths)} label="Target Files" error={this.props.getFieldError("Octopus.Action.SubstituteInFiles.TargetFiles")} accessibleName={"Variable Substitute path filters"} multiline={true}/>
                    <Note>
                        A newline-separated list of files to transform, relative to the root of the git repository. This field supports glob expression syntax e.g. <code>**/*.env</code>.<br />
                        See our <ExternalLink href="glob-patterns-cheat-sheet">glob expression cheat sheet</ExternalLink> for more info.
                    </Note>
                </ExpandableFormSection>
                <FormSectionHeading title="Additional Configuration Options"/>
                <KubernetesStatusCheckComponent jobsSupported={true} timeoutSupported={true} statusCheckSupported={true} showLegacyWait={false} properties={this.props.properties} packages={this.props.packages} plugin={this.props.plugin} errors={this.props.errors} busy={this.props.busy} expandedByDefault={this.props.expandedByDefault} getFieldError={this.props.getFieldError} setProperties={this.props.setProperties} setPackages={this.props.setPackages} doBusyTask={this.props.doBusyTask}/>
                <ExpandableFormSection title={"Referenced Packages"} isExpandedByDefault={this.props.expandedByDefault} errorKey="Octopus.Action.Script.Packages" summary={this.packageReferenceSummary()} help={"Add packages to be referenced by your scripts at execution-time"}>
                    <Note>
                        Learn more about <ExternalLink href="ScriptStepPackageReferences">package references</ExternalLink>.
                    </Note>
                    <ContainerImageReferenceList packageReferences={packageReferences} {...this.props}/>
                </ExpandableFormSection>
                <ServerSideApplyFormSection enabled={this.props.properties["Octopus.Action.Kubernetes.ServerSideApply.Enabled"] === "True"} forceConflicts={this.props.properties["Octopus.Action.Kubernetes.ServerSideApply.ForceConflicts"] === "True"} onEnabledChange={(value) => this.props.setProperties({ ["Octopus.Action.Kubernetes.ServerSideApply.Enabled"]: toBoolString(value) })} onForceConflictsChange={(value) => this.props.setProperties({ ["Octopus.Action.Kubernetes.ServerSideApply.ForceConflicts"]: toBoolString(value) })}></ServerSideApplyFormSection>
            </div>);
    }
    static displayName = "KubernetesKustomizeActionInternal";
}
export function KubernetesKustomizeAction(props: PropsWithChildren<KubernetesKustomizeActionProps>) {
    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();
    const gitCredentials = useGitCredentialsFromContext();
    const refreshGitCredentials = useRefreshGitCredentialsFromContext();
    const projectContext = useOptionalProjectContext();
    const processContext = useOptionalProcessContext();
    return (<KubernetesKustomizeActionInternal {...props} feeds={feeds} refreshFeeds={refreshFeeds} project={projectContext?.state.model} processType={processContext?.selectors.getProcessType()} gitCredentials={gitCredentials} refreshGitCredentials={refreshGitCredentials} modelDirty={!_.isEqual(processContext?.state.model, processContext?.state.cleanModel)}/>);
}
pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.Kubernetes.Kustomize",
    summary: (properties, targetRolesAsCSV) => <KubernetesKustomizeActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV}/>,
    editSections: {
        top: (props: KubernetesKustomizeActionProps) => (<KubernetesStepTopEditSection>
                <KubernetesKustomizeAction {...props}/>
            </KubernetesStepTopEditSection>),
        default: (props: KubernetesKustomizeActionProps) => (<KubernetesStepDefaultEditSection>
                <KubernetesKustomizeAction {...props}/>
            </KubernetesStepDefaultEditSection>),
    },
    canRunOnWorker: true,
    canRunInContainer: true,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Required,
    hasPackages: (action) => false,
    targetDiscoveryCloudConnectionProviders: getKubernetesTargetDiscoveryCloudProviders,
    getInitialProperties: () => InitialStatusCheckWithTimeoutProperties,
    canUseExecutionTimeouts: false,
    mixedExecutionLocations: kubernetesMixedExecutionLocationConfig,
    disableInlineExecutionContainers: true,
});
