/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { DashboardRenderMode } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { isEqual, sortBy } from "lodash";
import MobileDetect from "mobile-detect";
import * as React from "react";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { TemporaryAllProjectsConsolidationPage } from "~/areas/dashboard/DashboardOverview/TemporaryAllProjectsConsolidationPage";
import type { DeploymentOverviewFilters, DataCube } from "~/areas/projects/components/DashboardDataSource/DataCube";
import { DimensionTypes } from "~/areas/projects/components/DashboardDataSource/DataCube";
import SpaceDashboardDataSource from "~/areas/projects/components/DashboardDataSource/SpaceDashboardDataSource";
import ProjectDashboard from "~/areas/projects/components/ProjectDashboard/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/index";
import { FullWidthPageLayout } from "~/components/FullWidthPageLayout/FullWidthPageLayout";
import { InternalRedirect } from "~/components/Navigation/InternalRedirect/index";
import type { PageAction } from "~/components/PageActions/PageActions";
import DashboardOnboardingLayout from "~/components/ProjectBasedActivation/DashboardOnboardingLayout";
import type { IQuery } from "~/components/QueryStringFilters/QueryStringFilters";
import { QueryStringFilters } from "~/components/QueryStringFilters/QueryStringFilters";
import { useIsPageHeaderVNextEnabledOutsideOfProjects } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import { useIsVerticalNavigationEnabled } from "~/components/RootRoutes/useIsVerticalNavigationEnabled";
import Section from "~/components/Section";
import { Select } from "~/components/form";
import ComponentRow from "../../../components/ComponentRow/index";
import FilterSearchBox from "../../../components/FilterSearchBox/index";
import DashboardLimitSummary from "./DashboardLimitSummary";
import styles from "./style.module.less";
interface DashboardOverviewFilter {
    projectGroupId: string;
    projectName: string;
}
interface DashboardOverviewState {
    filter: DashboardOverviewFilter;
    matchCount: number;
    onboardingProject?: ProjectResource;
    redirectTo?: string;
    isDashboardLoaded: boolean;
}
const defaultFilter: DashboardOverviewFilter = {
    projectName: "",
    projectGroupId: "",
};
interface DashboardOverviewQuery extends IQuery {
    projectGroupId?: string;
    projectName?: string;
}
interface DashboardOverviewProps {
    spaceId: string;
}
interface DashboardOverviewInternalProps extends DashboardOverviewProps {
    dispatchAction: AnalyticActionDispatcher;
    isPageHeaderVNextEnabled: boolean;
}
export type RenderDashboardProps = DataBaseComponentState & {
    projectLimit?: number;
    cube?: DataCube;
    totalItems?: number;
    isFiltered?: boolean;
};
export const dashboardRefreshIntervalInMs = 6000;
const DashboardQueryStringFilters = QueryStringFilters.For<DashboardOverviewFilter, DashboardOverviewQuery>();
const DashboardDispatcher = ({ dispatchAction }: {
    dispatchAction: AnalyticActionDispatcher;
}) => {
    const [dispatched, setDispatched] = React.useState(false);
    if (!dispatched) {
        dispatchAction("Dashboard Viewed", { action: Action.View, resource: "Dashboard" });
        setDispatched(true);
    }
    return <></>;
};
class DashboardOverviewPageInternal extends DataBaseComponent<DashboardOverviewInternalProps, DashboardOverviewState> {
    constructor(props: DashboardOverviewInternalProps) {
        super(props);
        this.state = {
            filter: defaultFilter,
            matchCount: 0,
            onboardingProject: undefined,
            redirectTo: undefined,
            isDashboardLoaded: false,
        };
    }
    render() {
        if (this.state.redirectTo !== undefined) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }
        return (<>
                <DashboardQueryStringFilters filter={this.state.filter} getQuery={getQueryFromFilters} getFilter={getFilter} onFilterChange={(filter) => this.setState({ filter })}/>
                <SpaceDashboardDataSource render={this.renderDataSource} doBusyTask={this.doBusyTask} onDashboardLoad={() => this.onDashboardLoad()}/>
            </>);
    }
    private onDashboardLoad() {
        this.setState({ isDashboardLoaded: true });
    }
    private showDashboard(dashboardData: RenderDashboardProps) {
        const cube = dashboardData.cube;
        const groups = sortBy(cube!.projectGroupIndex, (g) => g.Name.toLowerCase()).map((g) => ({ value: g.Id, text: g.Name }));
        const hasFilter = !isEqual(defaultFilter, this.state.filter);
        // Disable autoFocus filtering for mobile (Android has issues and is annoying users).
        const md = new MobileDetect(window.navigator.userAgent);
        const autoFocus = md.isPhoneSized() ? false : true;
        return (<Section className={this.props.isPageHeaderVNextEnabled && styles.dashboardContainerVNext}>
                <DashboardDispatcher dispatchAction={this.props.dispatchAction}/>
                <div className={styles.filterHeaderContainer} key="A" role="search">
                    <div className={styles.filterFieldContainer}>
                        <ComponentRow className={styles.filter}>
                            {groups.length > 1 && (<div className={styles.filterField}>
                                    <Select placeholder="Filter by project group" items={groups} onChange={this.handleGroupChange} value={this.state.filter.projectGroupId} allowClear={true}/>
                                </div>)}
                            <div className={styles.filterField}>
                                <FilterSearchBox placeholder="Filter by project name" inputClassName={styles.filterInput} value={this.state.filter.projectName} onChange={this.handleNameChange} autoFocus={autoFocus} fullWidth={true}/>
                            </div>
                            <DashboardLimitSummary matchCount={this.state.matchCount} projectLimit={dashboardData.projectLimit ?? null} hasFilter={hasFilter} totalProjects={Object.keys(dashboardData.cube!.projectIndex).length}/>
                        </ComponentRow>
                    </div>
                </div>
                <ProjectDashboard spaceId={this.props.spaceId} key="B" cube={dashboardData.cube!} filters={this.createDashboardFilters()} maximumRows={dashboardData.projectLimit} showDeploymentCounts={true} onProjectCountChanged={this.handleProjectCountChange} dashboardRenderMode={DashboardRenderMode.VirtualizeColumns}/>
            </Section>);
    }
    private renderDataSource = (dataSource: RenderDashboardProps) => {
        const configureAction: PageAction = { type: "navigate", label: "Configure", path: links.dashboardConfigurationPage.generateUrl({ spaceId: this.props.spaceId }), buttonType: "secondary" };
        if (this.state.isDashboardLoaded) {
            const totalProjects = Object.keys(dataSource.cube!.projectIndex).length;
            const hasCompletedDeployments = dataSource.cube!.deployments.find((d) => d.IsCompleted) !== undefined;
            const canContinueOnboarding = totalProjects === 1 && !hasCompletedDeployments;
            if (hasReachedMinimumThresholdForHidingOnboardingOnDashboard(dataSource) || canContinueOnboarding) {
                const project = dataSource.cube!.projectIndex[Object.keys(dataSource.cube!.projectIndex)[0]];
                return <DashboardOnboardingLayout spaceId={this.props.spaceId} canContinueOnboarding={canContinueOnboarding} onboardingProject={project}/>;
            }
        }
        return (<FullWidthPageLayout areaTitleLink={links.dashboardPage.generateUrl({ spaceId: this.props.spaceId })} areaTitle="Dashboard" pageActions={[configureAction]} busy={this.state.isDashboardLoaded ? false : this.state.busy} errors={this.errors}>
                {this.renderMainContent(dataSource)}
            </FullWidthPageLayout>);
    };
    private renderMainContent = (dataSource: RenderDashboardProps) => {
        if (!this.state.isDashboardLoaded)
            return null;
        return this.showDashboard(dataSource);
    };
    private handleGroupChange = (projectGroupId: string | undefined) => {
        this.setState((prev) => ({ filter: { ...prev.filter, projectGroupId } }));
    };
    private handleNameChange = (projectName: string) => {
        this.setState((prev) => ({ filter: { ...prev.filter, projectName } }));
    };
    private createDashboardFilters: () => DeploymentOverviewFilters = () => {
        const filter = this.state.filter;
        const groupFilters = filter.projectGroupId !== "" ? { [filter.projectGroupId]: true } : null!;
        const projectNameFilters = filter.projectName !== "" ? { [filter.projectName]: true } : null!;
        return {
            rowDimension: DimensionTypes.Project,
            columnDimension: DimensionTypes.Environment,
            groupBy: DimensionTypes.ProjectGroup,
            [DimensionTypes.ProjectGroup]: groupFilters,
            [DimensionTypes.ProjectName]: projectNameFilters,
        };
    };
    private handleProjectCountChange = (matchCount: number) => {
        // don't re-render if count is the same. Easier to check
        // here than shouldComponentUpdate
        if (matchCount !== this.state.matchCount) {
            this.setState({ matchCount });
        }
    };
    static displayName = "DashboardOverviewPageInternal";
}
function getQueryFromFilters(filter: DashboardOverviewFilter): DashboardOverviewQuery {
    return {
        projectGroupId: filter.projectGroupId,
        projectName: filter.projectName,
    };
}
function getFilter(query: DashboardOverviewQuery): DashboardOverviewFilter {
    return {
        projectGroupId: query.projectGroupId || "",
        projectName: query.projectName || "",
    };
}
export const DashboardOverviewPage = ({ spaceId }: DashboardOverviewProps) => {
    const dispatchAction = useAnalyticActionDispatch();
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabledOutsideOfProjects();
    const isVerticalNavigationEnabled = useIsVerticalNavigationEnabled();
    if (isVerticalNavigationEnabled) {
        return <TemporaryAllProjectsConsolidationPage spaceId={spaceId}/>;
    }
    return <DashboardOverviewPageInternal spaceId={spaceId} dispatchAction={dispatchAction} isPageHeaderVNextEnabled={isPageHeaderVNextEnabled}/>;
};
function hasReachedMinimumThresholdForHidingOnboardingOnDashboard(dataSourceState: RenderDashboardProps): boolean {
    return dataSourceState.cube ? Object.keys(dataSourceState.cube.projectIndex).length === 0 : false;
}
